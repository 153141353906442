import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  // faFilePdf,
  // faExternalLinkAlt,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SPECIALITY_OPTIONS, userTypes } from '../../../metadata'
import Config from '../../../config'
import axios from 'axios'

export const timeSpentColumns = ({
  provider_availabilities,
  getProviderInsightsDownload,
}) => {
  return [
    {
      Header: 'Provider Name',
      accessor: (obj) => obj?.first_name + ' ' + obj?.last_name,
      //   Cell: ({ row }) => {
      //     let patientData = row?.original
      //     return (
      //       <Link
      //         onClick={() => {
      //           navigatetoPatientProfile(row?.original?.uid)
      //           onPatientNavLinkClicked('profile')
      //         }}
      //         to={{
      //           pathname: '/patient/profile',
      //           state: 'info',
      //           key: 2,
      //         }}
      //         className={Styles.link}
      //       >
      //         {patientData?.firstName || ''} {patientData?.lastName || ''}
      //       </Link>
      //     )
      //   },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Provider Type',
      accessor: 'user_type',
      width: 200,
    },
    // {
    //   Header: 'ID',
    //   accessor: 'firebase_id',
    //   width: 300,
    // },
    // {
    //   Header: 'Capacity',
    //   accessor: (obj) => {
    //     let time_minutes = provider_availabilities.find(
    //       (x) => x.provider_id === obj.firebase_id,
    //     )
    //     let careplan = obj.careplan || []
    //     let consult_time = careplan.reduce(
    //       (a, b) =>
    //         a +
    //         (moment
    //           .unix(
    //             (b.appointment_end_date || b.appointment_data?.visit_end_time) /
    //               1000,
    //           )
    //           .diff(
    //             moment.unix(
    //               (b.appointment_date || b.appointment_data?.visit_start_time) /
    //                 1000,
    //             ),
    //             'minute',
    //           ) || 0),
    //       0,
    //     )
    //     let capacity_percent = Math.round(
    //       (consult_time / time_minutes?.time) * 100,
    //     )
    //     // return time_minutes?.time === 0 ? '-' : time_minutes?.time
    //     return isFinite(capacity_percent) ? capacity_percent + '%' : '-'
    //   },
    //   width: 200,
    //   sort: true,
    // },
    // {
    //   Header: 'Number of Patients',
    //   accessor: (obj) => {
    //     let careplan = obj.careplan || []

    //     //GET UNIQUE List
    //     function getUniqueListBy(arr, key) {
    //       return [...new Map(arr.map((item) => [item[key], item])).values()]
    //     }
    //     const unique_patients = getUniqueListBy(careplan, 'patient_id') || []

    //     return unique_patients.length === 0 ? '-' : unique_patients.length
    //   },
    //   width: 200,
    //   sort: true,
    // },
    // {
    //   Header: 'Available Time',
    //   accessor: (obj) => {
    //     let time_minutes =
    //       provider_availabilities.find(
    //         (x) => x.provider_id === obj.firebase_id,
    //       ) || {}
    //     return time_minutes?.time === 0 ? '-' : time_minutes?.time
    //     // return isFinite(capacity_percent) ? capacity_percent + '%' : '-'
    //   },
    //   width: 200,
    //   sort: true,
    // },
    {
      Header: 'Visit Consult Time',
      accessor: (obj) => {
        // Add recorded time if not present then scheduled add time appointment time
        let careplan = obj.careplan || []
        // console.log('-----------------------------')
        let consult_time = careplan.reduce((acc, currVal) => {
          // if (currVal?.appointment_data?.follow_up?.no_show)
          //   console.log('No Show', currVal)
          // if (
          //   currVal?.doctor_id === 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2' ||
          //   currVal?.doctor_id === 'b3mjQcBbWvM5wl2ebAVvBysrRqh1'
          // )
          //   console.log('appnt', currVal?.doctor_id, currVal)
          let x = moment
            .unix(
              (currVal.appointment_end_date ||
                currVal.appointment_data?.visit_end_time) / 1000,
            )
            .diff(
              moment.unix(
                (currVal.appointment_date ||
                  currVal.appointment_data?.visit_start_time) / 1000,
              ),
              'minute',
            )

          // if (
          //   currVal?.doctor_id === 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2' ||
          //   currVal?.doctor_id === 'b3mjQcBbWvM5wl2ebAVvBysrRqh1'
          // )
          //   console.log('diff', currVal?.doctor_id, x)
          if (
            (currVal.appointment_end_date ||
              currVal.appointment_data?.visit_end_time) &&
            (currVal.appointment_date ||
              currVal.appointment_data?.visit_start_time)
          ) {
            // console.log('acc1', currVal?.doctor_id, acc + (x || 0))
            return acc + (x || 0)
          } else if (
            currVal.appointment_data?.start &&
            currVal.appointment_data?.end &&
            currVal?.appointment_data?.follow_up?.no_show !== true // Need to check this else it will add no show appointments also
          ) {
            let time = moment
              .unix(currVal?.appointment_data?.end / 1000)
              .diff(
                moment.unix(currVal?.appointment_data?.start / 1000),
                'minute',
              )
            // console.log('Incorrect data', currVal?.doctor_id, time)
            // console.log('acc2', currVal?.doctor_id, acc + time)
            return acc + time
          }
          // console.log('acc3', currVal?.doctor_id, acc)
          return acc
        }, 0)
        // console.log('XXXXXXXXXXXXXXXXXXXXXXXX')
        return consult_time === 0 ? '0' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'No Show Time',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        let no_show_count = 0
        let no_show_time = careplan.reduce(
          (acc, currVal) =>
            acc +
            (currVal?.appointment_data?.follow_up?.no_show === true ? 15 : 0),
          0,
        )
        // return `${no_show_time}(${no_show_count})` || 'N/A'
        return no_show_time || 'N/A'
      },
      width: 200,
    },
    {
      Header: 'Review of Records',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        let consult_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.pre_review_time) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Documentation Time',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        let consult_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.after_visit) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'RPM Time',
      accessor: (obj) => {
        let monitoring_log = obj.monitoring_log || []
        let consult_time = monitoring_log.reduce(
          (a, b) => a + (Math.floor(Number(b?.time) / 60000) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'CCM Time',
      accessor: 'ccm_time',
      width: 200,
      sort: true,
    },
    {
      Header: 'MTM Time',
      accessor: 'mtm_time',
      width: 200,
      sort: true,
    },
    {
      Header: 'Task Time',
      accessor: (obj) => {
        let support_tickets = obj.support_tickets || []
        let consult_time = support_tickets.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Communications Time',
      accessor: (obj) => {
        let communications = obj.communications || []
        let consult_time = communications.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Total Time Spent',
      accessor: (obj) => {
        let communications = obj.communications || []
        let communications_time = communications.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let support_tickets = obj.support_tickets || []
        let support_tickets_time = support_tickets.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let monitoring_log = obj.monitoring_log || []
        let monitoring_log_time = monitoring_log.reduce(
          (a, b) => a + (Math.floor(Number(b?.time) / 60000) || 0),
          0,
        )
        let careplan = obj.careplan || []
        let careplan_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.after_visit) || 0),
          0,
        )
        let records_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.pre_review_time) || 0),
          0,
        )
        let consult_time = careplan.reduce((acc, currVal) => {
          // if (currVal?.appointment_data?.follow_up?.no_show)
          //   console.log('No Show', currVal)
          // if (
          //   currVal?.doctor_id === 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2' ||
          //   currVal?.doctor_id === 'b3mjQcBbWvM5wl2ebAVvBysrRqh1'
          // )
          //   console.log('appnt', currVal?.doctor_id, currVal)
          let x = moment
            .unix(
              (currVal.appointment_end_date ||
                currVal.appointment_data?.visit_end_time) / 1000,
            )
            .diff(
              moment.unix(
                (currVal.appointment_date ||
                  currVal.appointment_data?.visit_start_time) / 1000,
              ),
              'minute',
            )

          // if (
          //   currVal?.doctor_id === 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2' ||
          //   currVal?.doctor_id === 'b3mjQcBbWvM5wl2ebAVvBysrRqh1'
          // )
          //   console.log('diff', currVal?.doctor_id, x)
          if (
            (currVal.appointment_end_date ||
              currVal.appointment_data?.visit_end_time) &&
            (currVal.appointment_date ||
              currVal.appointment_data?.visit_start_time)
          ) {
            // console.log('acc1', currVal?.doctor_id, acc + (x || 0))
            return acc + (x || 0)
          } else if (
            currVal.appointment_data?.start &&
            currVal.appointment_data?.end &&
            currVal?.appointment_data?.follow_up?.no_show !== true // Need to check this else it will add no show appointments also
          ) {
            let time = moment
              .unix(currVal?.appointment_data?.end / 1000)
              .diff(
                moment.unix(currVal?.appointment_data?.start / 1000),
                'minute',
              )
            // console.log('Incorrect data', currVal?.doctor_id, time)
            // console.log('acc2', currVal?.doctor_id, acc + time)
            return acc + time
          }
          // console.log('acc3', currVal?.doctor_id, acc)
          return acc
        }, 0)
        let total_time =
          consult_time +
          records_time +
          careplan_time +
          monitoring_log_time +
          support_tickets_time +
          communications_time
        return total_time === 0 ? '-' : total_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Total Careplan Notes Completed',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        let completed_appointments = careplan.filter(
          (appointment) =>
            appointment?.appointment_data?.follow_up?.no_show !== true,
        )
        return completed_appointments.length || 0
        // return careplan.length === 0 ? '0' : careplan.length
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Amount Collected',
      accessor: (obj) => {
        console.log('E->', obj)
        let copay_amount = obj.copay_amount || []
        let copay_sum = copay_amount.reduce(
          (a, b) => a + (Number(b?.charge) || 0),
          0,
        )

        let stripe_amount = obj.stripe_amount || []
        let stripe_sum = stripe_amount.reduce(
          (a, b) => a + (Number(b?.charge) || 0),
          0,
        )
        let total_sum = Number(copay_sum + stripe_sum) / 100
        return '$' + total_sum
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Invoice Hours',
      accessor: (obj) => {
        let communications = obj.communications || []
        let communications_time = communications.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let support_tickets = obj.support_tickets || []
        let support_tickets_time = support_tickets.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let monitoring_log = obj.monitoring_log || []
        let monitoring_log_time = monitoring_log.reduce(
          (a, b) => a + (Math.floor(Number(b?.time) / 60000) || 0),
          0,
        )
        let careplan = obj.careplan || []
        let careplan_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.after_visit) || 0),
          0,
        )
        let records_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.pre_review_time) || 0),
          0,
        )
        let consult_time = careplan.reduce((acc, currVal) => {
          // if (currVal?.appointment_data?.follow_up?.no_show)
          //   console.log('No Show', currVal)
          // if (
          //   currVal?.doctor_id === 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2' ||
          //   currVal?.doctor_id === 'b3mjQcBbWvM5wl2ebAVvBysrRqh1'
          // )
          //   console.log('appnt', currVal?.doctor_id, currVal)
          let x = moment
            .unix(
              (currVal.appointment_end_date ||
                currVal.appointment_data?.visit_end_time) / 1000,
            )
            .diff(
              moment.unix(
                (currVal.appointment_date ||
                  currVal.appointment_data?.visit_start_time) / 1000,
              ),
              'minute',
            )

          // if (
          //   currVal?.doctor_id === 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2' ||
          //   currVal?.doctor_id === 'b3mjQcBbWvM5wl2ebAVvBysrRqh1'
          // )
          //   console.log('diff', currVal?.doctor_id, x)
          if (
            (currVal.appointment_end_date ||
              currVal.appointment_data?.visit_end_time) &&
            (currVal.appointment_date ||
              currVal.appointment_data?.visit_start_time)
          ) {
            // console.log('acc1', currVal?.doctor_id, acc + (x || 0))
            return acc + (x || 0)
          } else if (
            currVal.appointment_data?.start &&
            currVal.appointment_data?.end &&
            currVal?.appointment_data?.follow_up?.no_show !== true // Need to check this else it will add no show appointments also
          ) {
            let time = moment
              .unix(currVal?.appointment_data?.end / 1000)
              .diff(
                moment.unix(currVal?.appointment_data?.start / 1000),
                'minute',
              )
            // console.log('Incorrect data', currVal?.doctor_id, time)
            // console.log('acc2', currVal?.doctor_id, acc + time)
            return acc + time
          }
          // console.log('acc3', currVal?.doctor_id, acc)
          return acc
        }, 0)
        // if (obj?.firebase_id === 'b3mjQcBbWvM5wl2ebAVvBysrRqh1') {
        //   console.log(
        //     'ct',
        //     consult_time,
        //     'rt',
        //     records_time,
        //     'cart',
        //     careplan_time,
        //     'mlt',
        //     monitoring_log_time,
        //     'st',
        //     support_tickets_time,
        //     'comt',
        //     communications_time,
        //   )
        // }
        let total_time =
          consult_time +
          records_time +
          careplan_time +
          monitoring_log_time +
          support_tickets_time +
          communications_time
        total_time = Number(total_time) || 0
        // console.log('Total time in IH', total_time)
        let hours = Math.floor(total_time / 60).toString() || 0
        let hour_minutes = (total_time / 60).toString().split('.')[1] || 0
        // console.log('Total time in IH1', total_time, hours, hour_minutes)
        let invoice_hours = parseFloat(hours + '.' + hour_minutes).toFixed(1)
        return invoice_hours === 0 ? '-' : invoice_hours
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Revenue Report',
      // accessor: (obj) => obj?.first_name + ' ' + obj?.last_name,
      Cell: ({ row }) => {
        let providerId = row?.original?.firebase_id
        return (
          <div>
            <FontAwesomeIcon
              onClick={() => {
                getProviderInsightsDownload(providerId)
              }}
              icon={faDownload}
              style={{
                color: 'green',
                fontSize: 20,
                marginLeft: 10,
                cursor: 'pointer',
              }}
            />
          </div>
        )
      },
      width: 250,
      // style: {
      //   position: 'sticky',
      //   left: '0px',
      //   zIndex: 10,
      //   backgroundColor: '#F3F3F3',
      // },
    },
  ]
}
