// orders page in the side nav bar clinical portal
import {
  faCapsules,
  faClipboardList,
  faFlask,
  faLaptopMedical,
  faMedkit,
  faReceipt,
  faTrash,
  faUserFriends,
  faPrescriptionBottleAlt,
  faPlusCircle,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import Fuse from 'fuse.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hl7parser from 'hl7parser'
import moment from 'moment'
import React, { Component, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import DateTimePicker from 'react-datetime-picker'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import ORDER_IMG from '../../assets/images/order_receipt.png'
import Header from '../../components/Header'
import Config from '../../config'
import {
  addMongoUser,
  addPatient,
  addPatientLP,
  addPatientList,
  addRpmPatient,
  addVirgilE3,
  fetchPatientCalculateProgressData,
  fetchPatientCarePlanData,
  fetchPatientConditions,
  fetchPatientCoreDate,
  fetchPatientLabs,
  fetchPatientMedications,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  getUnreadMessages,
  resetTimer,
  setPatientCoreData,
  startTimer,
  stopTimer,
  updateDoctorMongoUser,
  updatePatientData,
  updateSideNavBar,
  userLoggedIn,
} from '../../redux/actions'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles1 from './styles/Roster.module.scss'
import Styles from './styles/orders.module.scss'
// let hl7parser = require("hl7parser");
import { renderInfusionAppointmentsMasterTableColumns } from './Columns/infusionAppointmentsMasterTableColumns'
import { renderPatientAssistanceMasterTableColumns } from './Columns/patientAssistanceMasterTableColumns.js'
import Axios from 'axios'
import Handlebars from 'handlebars'
import Table from '../../components/Table'
import htmlModule1 from '../../helpers/PdfTemplates/ProviderReferralPhysician-new.txt'
import htmlModule3 from '../../helpers/PdfTemplates/ProviderReferralImaging1.txt'
import htmlModule2 from '../../helpers/PdfTemplates/ProviderReferralInfusion.txt'
import pafTemplate from '../../helpers/PdfTemplates/PatientAssistanceTemplate.txt'
import { renderOrdersMasterTableColumns } from './Columns/ordersMasterTableColumns'
import Econsults from './Econsults'
import Referrals from './Referrals'
import RpmOrders from './RpmOrders'
import metadata from '../../metadata'
import { connectSortBy } from 'react-instantsearch-dom'
const _ = require('lodash')

const axios = require('axios')

const PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'
let windowHeight = window.innerHeight
let windowWidth = window.innerWidth

const customPatientAssignModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '75%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

// const PROVIDERS = [
//   {
//     label: 'Alison Shmerling',
//     value: '4015227895',
//     clinicianId: 'iazlt523',
//     clinicianPwd: 'iazlt523',
//   },
//   {
//     label: 'Heather Berens',
//     value: '4016693586',
//     clinicianId: 'qkbmv888',
//     clinicianPwd: 'qkbmv888',
//   },
// ]

// const UNITS = [
//   { label: 'Bag', value: 'Bag' },
//   { label: 'Bar', value: 'Bar' },
//   { label: 'Capsules', value: 'Capsules' },
//   { label: 'Canister', value: 'Canister' },
//   { label: 'Cartridge', value: 'Cartridge' },
//   { label: 'Pills', value: 'Pills' },
//   { label: 'Strip', value: 'Strip' },
//   { label: 'Swab', value: 'Swab' },
//   { label: 'Syringe', value: 'Syringe' },
//   { label: 'Tube', value: 'Tube' },
//   { label: 'Vial', value: 'Vial' },
// ]

// const PHARMACIES = [
//   {
//     label: 'Bannockburn Pharmacy[6798 PYLE RD, BETHESDA (301)644-2418]',
//     value: '1',
//   },
//   {
//     label:
//       'Shollenberger Pharmacy[2002 S. MCDOWELL BLVD EXT, PETALUMA (707)984-5571]',
//     value: '2',
//   },
// ]

const customDrugModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '50%',
    height: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: 800,
    height: 800,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '95%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#20A89233',
    primary: '#20A892',
  },
})

class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      selectedTab: props?.location?.state ? props?.location?.state : 'reports',
      patientList: [],
      selectedPatient: props?.location?.patient_id
        ? props?.location?.patient_id
        : null,
      patientData: null,
      patientInsurance: null,
      modalIsOpen: false,
      labType: null,
      orderType: null,
      billType: { label: 'Patient Pays', value: 'Patient' },
      labLocation: null,
      labPriority: { label: 'Routine', value: 'Routine' },
      collectionDate: new Date() || null,
      // BUG: Why is this breaking the page, it goes white
      // collectionDate: new Date().setHours(8, 0, 0) || null,
      showIcd: false,
      labComments: '',
      labInstructions: '',
      addCpt: [],
      addIcd10: [],
      labOrderTabLoading: false,
      availableLabLocations: null,
      labSearchString: '',
      labSearchResults: null,
      selectedLabTests: [],
      icdSearchModalOpen: false,
      icdCodeSearchString: '',
      icdDescSearchString: '',
      icdSearchResults: null,
      clickedOrderable: null,
      clinicalReportsFromChange: null,
      modalClinicalReport: false,
      patientModalLoading: false,
      patientLabOrders: null,
      showPatientAssignModal: false,
      selectedPatientForAssigningClinicalReport: null,
      encodedReportString: '',
      showReportModal: false,
      sessionId: null,
      clinicalReportTabLoading: true,
      observationData: null,
      showObservationModal: false,
      //MASTER TABLE DATA
      allLabsBackend: [],
      allLabsChange: [],
      allPrescriptions: [],
      allReferrals: [],
      pdfData: '',
      showModal: false,
      showRequisitionModal: false,
      modalRequisition: null,
      appointmentEventId: props?.location?.orders_appointment_event_id
        ? props?.location?.orders_appointment_event_id
        : null,
      fromCarePlanNote: props?.location?.orders_appointment_event_id
        ? true
        : false,
      noteIcds: props?.location?.note_icds ? props?.location?.note_icds : [],
      referralType: props?.location?.referral_type
        ? props?.location?.referral_type
        : null,
      infusionOrders: [],
      patientAssistanceData: [],
      showReferringToModal: false,
      showSearchModal: false,

      //
      referringTo: null,
      referringToPhone: null,
      referringToFax: '+1',
      fuse: null,
      facilityType: {
        label: 'DME',
        value: 'DME_DATABASE',
      },
      searchName: '',
      currentlyViewingOrderData: null,
      showReferringToPAFModal: false,
      showPAFModal: false,
    }
    this.getPatientCoreData = this.getPatientCoreData.bind(this)
    this.getAvailableLabLocations = this.getAvailableLabLocations.bind(this)
    this.searchLabTestCodes = this.searchLabTestCodes.bind(this)
    this.onLabTestAdded = this.onLabTestAdded.bind(this)
    this.resetLabFields = this.resetLabFields.bind(this)
    this.onPatientSelected = this.onPatientSelected.bind(this)
    this.getDownloadableClinicalRecords =
      this.getDownloadableClinicalRecords.bind(this)
    this.onViewClinicalReportClicked =
      this.onViewClinicalReportClicked.bind(this)
    this.getAllLabOrders = this.getAllLabOrders.bind(this)
    this.getAllLabOrdersFromChange = this.getAllLabOrdersFromChange.bind(this)
    this.getAllPrescriptions = this.getAllPrescriptions.bind(this)
    this.getAllReferrals = this.getAllReferrals.bind(this)
    this.onViewOrderPressed = this.onViewOrderPressed.bind(this)
    this.navigatetoPatientProfile = this.navigatetoPatientProfile.bind(this)
    this.onPatientNavLinkClicked = this.onPatientNavLinkClicked.bind(this)
    this.viewPAFPdfOrder = this.viewPAFPdfOrder.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.patientList) {
      let list = []
      Object.values(props.patientList).forEach((colorCodedPatients) => {
        if (colorCodedPatients.length > 0) {
          colorCodedPatients.forEach((patient) => {
            patient.label = patient.firstName + ' ' + patient.lastName
            patient.value = patient.uid
            list.push(patient)
          })
        }
      })

      return { patientList: list }
    }
  }

  componentDidMount() {
    let { mongoUser, loggedIn } = this.props
    if (!loggedIn || !mongoUser) {
      this.props.history.push('/login')
      return
    }

    if (
      this?.props?.location?.state === 'labs' ||
      this?.props?.location?.state === 'erx' ||
      this?.props?.location?.state === 'referrals'
    ) {
      this.onPatientSelected(
        this.state.patientList.find(
          (data) => data.uid === this?.props?.location?.patient_id,
        ),
      )
    }

    this.getAvailableLabLocations()
    this.setState({ clinicalReportTabLoading: true })
    // Commenting this line as the route might be deprecated(cant find it in the backend)
    // this.getAllLabOrders()
    this.getAllLabOrdersFromChange()
    // Commenting this line as the route might be deprecated(cant find it in the backend)
    // this.getAllPrescriptions()
    this.getAllReferrals()
    this.getInfusionOrders()
    this.getPAF()
  }

  getInfusionOrders() {
    let { token } = this.props
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}infusion/appointments/list`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    }).then((res) => {
      let infusionOrders = res.data.data
      this.setState({ infusionOrders })
    })
  }

  getPAF() {
    let { token } = this.props
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}patient/paf`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    }).then((res) => {
      if (!res.data.success) return
      let patientAssistanceData = res.data.data
      this.setState({ patientAssistanceData: patientAssistanceData })
    })
  }

  onLabTestAdded(lab) {
    let temp = [...this.state.selectedLabTests]
    temp.push(lab)
    this.setState({
      selectedLabTests: temp,
      labSearchString: '',
      labSearchResults: null,
    })
  }

  getAvailableLabLocations() {
    let { token, mongoUser } = this.props
    this.setState({ labOrderTabLoading: true })
    let url = Config.CHANGE_BACKEND_URL + 'change/organization/labs'
    console.log('URL:::::::::: ', url)
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token },
    })
      .then(({ data }) => {
        console.log('got labs info', data)
        this.setState({ sessionId: data.RESULT?.sessionid })
        console.log('sessionId is set', data.RESULT?.sessionid)
        if (!data?.RESULT?.OBJECT) {
          //something went wrong
          console.log('no results returend')
          toast.error('Something went wrong when getting lab details')
        } else {
          let labs = []
          let content = data.RESULT.OBJECT
          if (Array.isArray(content)) {
            content.forEach((x) => labs.push(getLabsInfo(x)))
          } else {
            labs.push(getLabsInfo(content))
          }

          console.log('labs are', labs)

          this.setState({
            availableLabLocations: labs,
            labOrderTabLoading: false,
          })
        }

        if (!mongoUser?.change?.clinician_id) {
          console.log('missing some things') // Why did the prev engineer write this?
          // toast.error('You cannot place lab or Rx orders')
          this.setState({ clinicalReportTabLoading: false })
          this.getDownloadableClinicalRecords()
        } else {
          this.getDownloadableClinicalRecords()
        }
      })
      .catch((err) => {
        console.log('error when getting labs', err)
        this.setState({
          labOrderTabLoading: false,
          clinicalReportTabLoading: false,
        })
        toast.error('Something went wrong when getting lab details')
        // this.setState({labOrderTabLoading: false})
      })

    const getLabsInfo = (content) => {
      let labname = content.lab_name.replace('Denver', ' ')
      labname = labname.replace(' - West Region', ' ')
      content.label = labname
      content.value = content.lab

      return content
    }
  }

  searchLabTestCodes() {
    let { labLocation, labSearchString } = this.state
    if (!labLocation) {
      return
    }

    this.setState({ labOrderTabLoading: true })

    let { token } = this.props
    let url = Config.CHANGE_BACKEND_URL + 'change/labs?q=' + labSearchString
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, lab_id: labLocation.lab },
    })
      .then(({ data }) => {
        if (data?.RESULT?.OBJECT) {
          let labs = [...data.RESULT.OBJECT]
          this.setState({ labSearchResults: labs })
        }
        this.setState({ labOrderTabLoading: false })
      })
      .catch((err) => {
        console.log('error when getting lab search results', err)
        toast.error('Something went wrong when searching for lab tests')
        this.setState({ labOrderTabLoading: false })
      })
  }

  getAllLabOrders() {
    let { token } = this.props
    this.setState({ clinicalReportTabLoading: true })
    const url = `${Config.BACKEND_URL}all-lab-orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(({ data }) => {
        console.log('got lab orders:', data)
        this.setState({ allLabsBackend: data })
      })
      .catch((err) => {
        this.setState({ clinicalReportTabLoading: false })
        console.log('error when getting lab orders1', err)
        // toast.error('Something went wrong')
      })
  }

  getAllLabOrdersFromChange() {
    let { token } = this.props
    this.setState({ clinicalReportTabLoading: true })
    const url = `${Config.CHANGE_BACKEND_URL}change/orders`

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(({ data }) => {
        // console.log('change data', data)
        if (data?.RESULT?.OBJECT) {
          let orders = []
          let content = data.RESULT.OBJECT
          let ordersMap = {}
          if (Array.isArray(content)) {
            content.forEach((x) => getChangeLabsInfo(x, ordersMap))
          } else {
            getChangeLabsInfo(content, ordersMap)
          }
          // console.log(ordersMap)
          this.setState({ allLabsChange: ordersMap })
        } else {
          this.setState({ clinicalReportTabLoading: false })

          toast.error('Could not get lab orders from change')
        }
      })
      .catch((err) => {
        this.setState({ clinicalReportTabLoading: false })

        console.log('error when getting lab orders2', err)
        // toast.error('Something went wrong')
      })
    const getChangeLabsInfo = (content, map) => {
      map[content.order] = {
        status: content.order_status,
        providerName: `${content.ref_cg_fname} ${content.ref_cg_lname}`,
      }
      return
    }
  }

  // BUG: This route is dose'nt exist anymore
  getAllPrescriptions() {
    let { token } = this.props
    this.setState({ clinicalReportTabLoading: true })
    const url = `${Config.BACKEND_URL}all-erx-orders`
    console.log('The token is:', token)
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(({ data }) => {
        this.setState({ allPrescriptions: data })
      })
      .catch((err) => {
        this.setState({ clinicalReportTabLoading: false })
        console.log('error when getting lab orders3', err)
        // toast.error('Something went wrong')
      })

    // let { token } = this.props
    // this.setState({ clinicalReportTabLoading: true })
    // const url = `${Config.CHANGE_BACKEND_URL}change/prescriptions`

    // axios({
    //   method: 'get',
    //   url,
    //   headers: {
    //     Authorization: `JWT ${token}`,
    //   },
    // })
    //   .then(({ data }) => {
    //     console.log('prescriptions:', data)
    //     if (data?.RESULT?.OBJECT) {
    //       let prescriptions = []
    //       let content = data.RESULT.OBJECT
    //       if (Array.isArray(content)) {
    //         content.forEach((x) => {
    //           prescriptions.push(getPrescriptionInfo(x))
    //         })
    //       } else {
    //         prescriptions.push(getPrescriptionInfo(content))
    //       }
    //       this.setState({allPrescriptions:prescriptions })
    //     } else {
    //       toast.error('No prescriptions found for the patient')
    //     }
    //   })
    //   .catch((err) => {
    //     console.log('error when getting patient prescriptions', err)
    //     // toast.error('Could not fetch the data')
    //   })
    //   const getPrescriptionInfo = (content) => {
    //     return {
    //       drugName: content['drug_name'],
    //       providerName: content['prescriber_name'],
    //       creationDate: content['creation_date'],
    //       transmissionDate:
    //         typeof content['transmission_date'] === 'string'
    //           ? content['transmission_date']
    //           : '',
    //       status: content['transmittal_status'],
    //     }
    //   }
  }

  getAllReferrals() {
    let { token, adminId, mongoUser } = this.props
    this.setState({ clinicalReportTabLoading: true })
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `referrals`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
      },
    }

    console.log('config log', config)
    axios(config)
      .then(({ data }) => {
        this.setState({ clinicalReportTabLoading: false })
        if (mongoUser.authorization_level !== 'external_doctor')
          this.setState({ allReferrals: data })
        else this.setState({ allReferrals: [] })
      })
      .catch((err) => {
        this.setState({ clinicalReportTabLoading: false })
        console.log('Error getting all referrals1', err)
        toast.error('Could not get all orders. Please try Again')
      })
  }

  onViewOrderPressed(order_data) {
    // console.log('Order Data', order_data)
    let { token, adminId } = this.props
    let data = order_data
      data.if_physician_referral = ' '
      data.if_durable_medical_referral = ' '
      data.if_infusion_referral = ' '
      data.if_lab_referral = ' '
      data.if_imaging_referral = ' '
    if (order_data.referral_type === 'physician')
      data.if_physician_referral = 'X'
    else if (order_data.referral_type === 'medical')
      data.if_durable_medical_referral = 'X'
    else if (order_data.referral_type === 'speciality')
      data.if_infusion_referral = 'X'
    // TODO: Find what condition this is active
    // else if (order_data.referral_type === 'imaging')
    //   data.if_lab_referral = 'X'
    else if (order_data.referral_type === 'imaging')
      data.if_imaging_referral = 'X'
    else {
      toast.error('Order Type is not set!')
      console.log('Order data is not set!')
    }

    data.order_date = moment(data?.created_at).format('MM/DD/YYYY')
    data.order_type = data?.referral_type
    data.fluoroscopy = data?.fluoroscopy?.value || 'N/A'
    data.body_sites = data?.body_site?.map((x) => x?.id)?.join(', ')
    data.contrast = data.contrast
    data.nuclear_medicine = data?.nuclear_medicine?.value || 'N/A'
    data.literality = data.literality
    data.imaging_study = data.imaging_study
    data.if_claustrophobic = data?.if_claustrophobic ? 'Yes' : 'No'
    data.if_oral = data.if_oral ? 'X' : ' '
    data.if_iv = data.if_iv ? 'X' : ' '
    data.if_anesthesia = data.if_anesthesia ? 'X' : ' '
    data.if_perform_as_ordered = data.if_perform_as_ordered ? 'X' : ' '
    data.if_ok_to_be_alered = data.if_ok_to_be_alered ? 'X' : ' ' // this should have been alerted
    data.imagingMed = data.imagingMed
    data.conditions_data = data.condiions_data
    data.practice_name = 'Aila Health'
    data.practice_address =
      '1999 Harrison Street, 18th Floor\nOakland, California 94612'
    data.practice_phone = '415-704-9601'
    data.practice_fax = '7205009759'
    data.practice_email = 'billing@ailahealth.com'
    data.provider_name =
      data.doctor_data?.first_name + ' ' + data.doctor_data?.last_name
    data.provider_credential =
      data.doctor_data?.degree === 'Medical Doctor'
        ? 'MD'
        : data.doctor_data?.degree === 'Doctor of Osteopathic Medicine'
        ? 'DO'
        : ''
    data.provider_npi = data.doctor_data?.npi?.number
    data.provider_speciality = data.doctor_data?.speciality
    // console.log('data1', data)

    // console.log(data)

    if (data?.order_type === 'Labs') {
      let ordersFromChange = this.state.allLabsChange || {}
      let changeOrder = ordersFromChange[data.order_id]
      console.log('lab orders from change')
      this.setState({ clinicalReportTabLoading: true })
      if (
        changeOrder &&
        changeOrder.status &&
        (changeOrder.status === 'F' ||
          changeOrder.status === 'T' ||
          changeOrder.status === 'NA' ||
          changeOrder.status === 'P')
      ) {
        //requisition is available
        const url = `${Config.CHANGE_BACKEND_URL}change/orders/pdf`
        axios({
          method: 'get',
          url,
          headers: {
            Authorization: `JWT ${token}`,
            'order-id': data.order_id,
          },
        })
          .then(({ data }) => {
            console.log('requisition data', data)
            if (data?.RESULT?.OBJECT) {
              let content = data.RESULT.OBJECT
              let body = content.body
              // let temp = `data:base64${body}`
              this.setState({
                modalRequisition: body,
                showRequisitionModal: true,
              })
              this.setState({ clinicalReportTabLoading: false })

              // setModalRequisition(body)
              // setShowRequisitionModal(true)
            } else {
              toast.error('Could not get requisition for order')
              this.setState({ clinicalReportTabLoading: false })
            }
          })
          .catch((err) => {
            console.log('error when getting lab orders4', err)
            toast.error('Something went wrong')
          })
      } else {
        this.setState({ clinicalReportTabLoading: false })
        toast.error('Requisition not available')
      }
    }

    this.setState({ currentlyViewingOrderData: data })

    if (data?.referral_type === 'physician') {
      Axios(htmlModule1).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        console.log(bodyHtml)
        this.setState({ pdfData: bodyHtml })
        this.setState({ showModal: true })
      })
    } else if (data?.referral_type === 'medical') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        this.setState({ pdfData: bodyHtml })
        this.setState({ showModal: true })
      })
    } else if (data?.referral_type === 'priorAuth') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        this.setState({ pdfData: bodyHtml })
        this.setState({ showModal: true })
      })
    } else if (data?.referral_type === 'imaging') {
      Axios(htmlModule3).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        this.setState({ pdfData: bodyHtml })
        this.setState({ showModal: true })
      })
    } else if (data?.referral_type === 'speciality') {
      Axios(htmlModule2).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(data)
        this.setState({ pdfData: bodyHtml })
        this.setState({ showModal: true })
      })
    }
  }

  searchIcdCodes() {
    let { icdCodeSearchString, icdDescSearchString } = this.state
    if (!icdCodeSearchString && !icdDescSearchString) {
      return
    }

    this.setState({ labOrderTabLoading: true })

    let { token } = this.props

    let url = Config.CHANGE_BACKEND_URL + `change/icd-10`
    let queryParams = {
      icd_code: icdCodeSearchString || '',
      icd_code_desc: icdDescSearchString || '',
    }

    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      params: queryParams,
    })
      .then(({ data }) => {
        if (data?.RESULT?.OBJECT) {
          let icdList = [...data.RESULT.OBJECT]
          this.setState({ icdSearchResults: icdList })
        }
        this.setState({ labOrderTabLoading: false })
      })
      .catch((err) => {
        console.log('error when getting icd search results', err)
        toast.error('Something went wrong when searching for icd codes')
        this.setState({ labOrderTabLoading: false })
      })
  }

  getPatientCoreData(uid) {
    let { token } = this.props
    this.setState({ loading: true })
    let url = Config.BACKEND_URL + 'users?fields=axle,firebase_id'
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: uid },
    })
      .then(({ data }) => {
        if (!data || !data.axle || !data.axle.id || !data.axle.address_id) {
          toast.error('Some information missing in patient profile')
          setTimeout(
            () => toast.error('Cannot place at home lab order right now'),
            300,
          )
        }
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when getting user details', err)
        toast.error('Something went wrong when fetching user details')
        this.setState({ loading: false })
      })
  }

  fetchPatientInformation(uid) {
    let { token } = this.props
    if (!uid) return
    this.setState({ labOrderTabLoading: true })
    let url =
      Config.BACKEND_URL +
      'users?fields=dob,height,weight,gender,language,ethnicity,change,firebase_id'

    let url2 = Config.BACKEND_URL + 'patients/insurances'

    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: uid },
    })
      .then((result) => {
        const data = result.data
        console.log('got patient profile data', data)
        this.setState({ patientData: data })
        this.setState({ labOrderTabLoading: false })

        // if (!data?.change?.person)
        // toast.error('Cannot place order for this patient')
      })
      .catch((err) => {
        console.log('error in fetching patient info: ', err)
      })

    axios({
      method: 'get',
      url: url2,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: uid },
    })
      .then((result) => {
        const data = result.data
        console.log('got patient insurance data', data)
        this.setState({ patientInsurance: { insurance: data } })
        this.setState({ labOrderTabLoading: false })
      })
      .catch((err) => {
        console.log('error in fetching patient insurance: ', err)
      })
  }

  getConditionsData(uid) {
    let { token } = this.props
    let url = Config.BACKEND_URL + 'user/conditions'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: uid },
    })
      .then((response) => {
        console.log('got conditions data')
        self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })
  }

  getSymptomsData(uid) {
    let { token } = this.props
    let url = Config.BACKEND_URL + 'user/symptoms'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: uid },
    })
      .then((response) => {
        console.log('got symptoms data')
        self.setState({ symptomsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting symptoms data', err)
        toast.error('Something went wrong')
      })
  }

  onLabSave() {
    let {
      selectedLabTests,
      selectedPatient,
      labLocation,
      labInstructions,
      labComments,
      labPriority,
      billType,
      collectionDate,
      addIcd10,
    } = this.state
    let { adminId, token } = this.props

    if (!selectedLabTests || selectedLabTests.length === 0) {
      toast.error('Please search and add labs to order')
      return
    }

    let tests = selectedLabTests.map((x) => {
      return {
        name: x.description,
        orderable: x.orderable,
        diagnosis: x?.diagnosis || null,
      }
    })

    let toSave = {
      patient_id: selectedPatient.value,
      doctor_id: adminId,
      lab: labLocation,
      instructions: labInstructions,
      comments: labComments,
      priority: labPriority.value,
      payment_type: billType.value,
      paid:
        billType.value === 'Patient' ||
        billType.value === 'Aila' ||
        billType.value === 'Insurance',
      tests: tests,
      collection_date_time: collectionDate,
      icd_codes: addIcd10,
    }

    let { showVideoModal, appointmentData } = this.props
    if (showVideoModal && appointmentData) {
      //the lab is being ordered during the visit. Link it with the event id
      toSave['appointment_event_id'] = appointmentData['event_id']
    } else {
      toSave['appointment_event_id'] = this.state.appointmentEventId
    }
    toSave['ordered_from_careplan_notes'] = this.state.fromCarePlanNote

    console.log('The TO SAVE object is', toSave)

    this.setState({ labOrderTabLoading: true })

    let url = Config.BACKEND_URL + 'patients/labs/orders'
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: toSave,
    })
      .then(() => {
        this.setState({ labOrderTabLoading: false })
        this.resetLabFields()
        toast.success('Lab order created successfully')
      })
      .catch((err) => {
        console.log('error when posting labs', err)
        toast.error('Something went wrong')
      })
  }

  async getMultipleClinicalDocuments(reportArray) {
    let reportDetailArray = []
    await Promise.all(
      reportArray.map((reportObj) =>
        axios({
          method: 'get',
          url: `${Config.CHANGE_BACKEND_URL}change/reports/documents`,
          headers: {
            Authorization: `JWT ${this.props.token}`,
            change_session_id: this.state.sessionId,
            clinical_report_id: reportObj?.clinicalreport,
          },
        })
          .then(({ data }) => {
            // console.log('clinical report data in getMultipleClinical', data)
            if (data?.RESULT?.OBJECT) {
              let reports = []
              let reportIds = []
              let content = data.RESULT.OBJECT
              if (Array.isArray(content)) {
                content.forEach((x) => {
                  if (x.mime_type === 'HL7') {
                    // reportIds.push(x.clinicalreport)
                    let message = hl7parser.create(x?.body_text)
                    let patientName =
                      message.get('PID.5.2').toString() +
                      ' ' +
                      message.get('PID.5.1').toString()
                    let testName = message.get('OBR.4.2').toString()
                    let observationValue = message.get('OBX.5.1').toString()
                    let refRange = message.get('OBX.7.1').toString()
                    let units = message.get('OBX.6.1').toString()
                    let flag = message.get('OBX.8.1').toString()
                    let orderingProvider =
                      message.get('ORC.12.2').toString() +
                      ' ' +
                      message.get('ORC.12.3').toString()
                    let comments = message.get('NTE.3.1').toString()
                    let orderDate = message.get('ORC.9.1').toString()
                    let pdf = ''
                    message.forEach((value) => {
                      if (value.toString().substring(0, 3) === 'OBX') {
                        let tempString = 'MSH||\r' + value._text
                        let tempMessage = hl7parser
                          .create(tempString)
                          .get('OBX.5')
                          .toString()
                          .split('^')
                        if (tempMessage[2] === 'PDF') {
                          pdf = tempMessage[4]
                        }
                      }
                    })
                    let testResults = []
                    let testTempNumber = null
                    let testTempName = null
                    let observationNumber = null
                    let mainComments = ''
                    message.forEach((value) => {
                      let testObj = {}
                      let testName = ''
                      if (value.toString().substring(0, 3) === 'OBR') {
                        let tempString = 'MSH||\r' + value._text
                        let tempMessage = hl7parser.create(tempString)
                        testTempNumber = tempMessage.get('OBR.1').toString()
                        testTempName = tempMessage
                          .get('OBR.4')
                          .toString()
                          .split('^')[1]
                        console.log(
                          'TESTING::: ',
                          tempMessage.get('OBR.4').toString().split('^')[1],
                          testTempNumber,
                        )
                        testObj.testName = tempMessage
                          .get('OBR.4')
                          .toString()
                          .split('^')[1]
                        testName = tempMessage
                          .get('OBR.4')
                          .toString()
                          .split('^')[1]
                        testObj.testNumber = testTempNumber
                      }
                      if (value.toString().substring(0, 3) === 'OBX') {
                        let tempString = 'MSH||\r' + value._text
                        let tempMessage = hl7parser.create(tempString)
                        observationNumber = tempMessage.get('OBX.1').toString()
                        testObj.testNumber = testTempNumber
                        testObj.testName = testTempName
                        testObj.observationNumber = observationNumber
                        testObj.observationName = tempMessage
                          .get('OBX.3.1')
                          .toString()
                          .split('^')[1]
                        testObj.observationValue = tempMessage
                          .get('OBX.5.1')
                          .toString()
                        testObj.refRange = tempMessage.get('OBX.7.1').toString()
                        testObj.units = tempMessage.get('OBX.6.1').toString()
                        testObj.flag = tempMessage.get('OBX.8.1').toString()
                      }
                      if (value.toString().substring(0, 3) === 'NTE') {
                        let tempString = 'MSH||\r' + value._text
                        testObj.comments = hl7parser
                          .create(tempString)
                          .get('NTE.3.1')
                          .toString()
                        testObj.testNumber = testTempNumber
                        testObj.testName = testTempName
                        testObj.observationNumber = observationNumber
                        if (testTempNumber === null)
                          mainComments = mainComments + ' ' + testObj.comments
                      }
                      testResults.push(testObj)
                    })
                    let mappedResultData = _(testResults)
                      .groupBy('testNumber')
                      .map((value, key) => {
                        // console.log('TESTING MAP: ', value, key)
                        let obvResult = _(value)
                          .groupBy('observationNumber')
                          .map((obvValue, obvKey) => {
                            // console.log('TESTING OBV: ', obvValue, obvKey, obvValue.find(obj=> obj.observationName))
                            let obvResRow = obvValue.find(
                              (obj) => obj.observationValue,
                            )
                            if (
                              obvValue.find((obj) => obj.observationName)
                                ?.observationName
                            )
                              return {
                                flag:
                                  obvValue.find((obj) => obj.flag)?.flag ||
                                  null,
                                observationName:
                                  obvValue.find((obj) => obj.observationName)
                                    ?.observationName || null,
                                observationNumber:
                                  obvValue.find((obj) => obj.observationNumber)
                                    ?.observationNumber || null,
                                observationValue:
                                  obvValue.find((obj) => obj.observationValue)
                                    ?.observationValue || null,
                                refRange:
                                  obvValue.find((obj) => obj.refRange)
                                    ?.refRange || null,
                                testName:
                                  obvValue.find((obj) => obj.testName)
                                    ?.testName || null,
                                testNumber:
                                  obvValue.find((obj) => obj.testNumber)
                                    ?.testNumber || null,
                                units:
                                  obvValue.find((obj) => obj.units)?.units ||
                                  null,
                                comments: obvValue
                                  .map((obj) => {
                                    if (obj?.comments?.length)
                                      return obj?.comments
                                  })
                                  .join(' '),
                              }
                          })
                          .value()
                        if (
                          value.find((obj) => obj.testName)?.testName &&
                          !value
                            .find((obj) => obj.testName)
                            ?.testName?.includes('PDF')
                        )
                          return {
                            testName:
                              value.find((obj) => obj.testName)?.testName ||
                              null,
                            observations: _.compact(obvResult),
                          }
                      })
                      .value()

                    // testResults.forEach((obj, index) => {
                    //   if (obj.testNumber) {
                    //     mainTest[testNumber] =
                    //     tempTestName = obj.testName
                    //   }
                    //   else if(tempTestName) {

                    //   }
                    // })
                    // console.log('testing:', testResults)
                    console.log('testing loadash:', mappedResultData)
                    let tempReport = {
                      ...x,
                      patientName,
                      testName,
                      observationValue,
                      refRange,
                      units,
                      flag,
                      orderingProvider,
                      comments,
                      pdf,
                      mappedResultData: _.compact(mappedResultData),
                      mainComments,
                      orderDate: moment(orderDate, 'YYYYMMDDHHmmss').format(
                        'YYYY-MM-DD, HH:mm',
                      ),
                    }
                    reportDetailArray.push(tempReport)
                  } else {
                    console.log('NOT HL7', x)
                  }
                })
              } else {
                // console.log('NOT HL7', x)
                // reports.push(content)
                // reportIds.push(content.clinicalreport)
              }
              // this.getClinicalReportsFromAila(reports, reportIds)
              // this.setState({clinicalReportsFromChange: reports})
            } else {
              console.log('Could not get clinical reports from change')
              // toast.error('Could not get clinical reports from change')
            }
          })
          .catch((err) => {
            console.log('error when getting multiple orders1', err)
            toast.error('Something went wrong')
          }),
      ),
    )
    return reportDetailArray
  }

  convertHL7 = (data) => {
    console.log(
      '*****************************************************************************************',
    )
    console.log('In function convertHL7')
    console.log(
      '*****************************************************************************************',
    )
    let reports = []
    let reportIds = []
    let reportDetailArray = []
    // if (data?.RESULT?.OBJECT) {
    // let content = data.RESULT.OBJECT
    let content = data
    if (Array.isArray(content)) {
      console.log('The length of the content array is: ', content.length)
      content.forEach((x) => {
        if (x.mime_type === 'HL7') {
          // reportIds.push(x.clinicalreport)
          let message = hl7parser.create(x?.body_text)
          let patientName =
            message.get('PID.5.2').toString() +
            ' ' +
            message.get('PID.5.1').toString()
          console.log('patientName: ', patientName)
          let testName = message.get('OBR.4.2').toString()
          let observationValue = message.get('OBX.5.1').toString()
          let refRange = message.get('OBX.7.1').toString()
          let units = message.get('OBX.6.1').toString()
          let flag = message.get('OBX.8.1').toString()
          let orderingProvider =
            message.get('ORC.12.2').toString() +
            ' ' +
            message.get('ORC.12.3').toString()
          let comments = message.get('NTE.3.1').toString()
          let orderDate = message.get('ORC.9.1').toString()
          let pdf = ''
          message.forEach((value) => {
            if (value.toString().substring(0, 3) === 'OBX') {
              let tempString = 'MSH||\r' + value._text
              let tempMessage = hl7parser
                .create(tempString)
                .get('OBX.5')
                .toString()
                .split('^')
              if (tempMessage[2] === 'PDF') {
                pdf = tempMessage[4]
              }
            }
          })
          let testResults = []
          let testTempNumber = null
          let testTempName = null
          let observationNumber = null
          let mainComments = ''
          message.forEach((value) => {
            let testObj = {}
            let testName = ''
            if (value.toString().substring(0, 3) === 'OBR') {
              let tempString = 'MSH||\r' + value._text
              let tempMessage = hl7parser.create(tempString)
              testTempNumber = tempMessage.get('OBR.1').toString()
              testTempName = tempMessage.get('OBR.4').toString().split('^')[1]
              console.log(
                'TESTING::: ',
                tempMessage.get('OBR.4').toString().split('^')[1],
                testTempNumber,
              )
              testObj.testName = tempMessage
                .get('OBR.4')
                .toString()
                .split('^')[1]
              testName = tempMessage.get('OBR.4').toString().split('^')[1]
              testObj.testNumber = testTempNumber
            }
            if (value.toString().substring(0, 3) === 'OBX') {
              let tempString = 'MSH||\r' + value._text
              let tempMessage = hl7parser.create(tempString)
              observationNumber = tempMessage.get('OBX.1').toString()
              testObj.testNumber = testTempNumber
              testObj.testName = testTempName
              testObj.observationNumber = observationNumber
              testObj.observationName = tempMessage
                .get('OBX.3.1')
                .toString()
                .split('^')[1]
              testObj.observationValue = tempMessage.get('OBX.5.1').toString()
              testObj.refRange = tempMessage.get('OBX.7.1').toString()
              testObj.units = tempMessage.get('OBX.6.1').toString()
              testObj.flag = tempMessage.get('OBX.8.1').toString()
            }
            if (value.toString().substring(0, 3) === 'NTE') {
              let tempString = 'MSH||\r' + value._text
              testObj.comments = hl7parser
                .create(tempString)
                .get('NTE.3.1')
                .toString()
              testObj.testNumber = testTempNumber
              testObj.testName = testTempName
              testObj.observationNumber = observationNumber
              if (testTempNumber === null)
                mainComments = mainComments + ' ' + testObj.comments
            }
            testResults.push(testObj)
          })
          let mappedResultData = _(testResults)
            .groupBy('testNumber')
            .map((value, key) => {
              // console.log('TESTING MAP: ', value, key)
              let obvResult = _(value)
                .groupBy('observationNumber')
                .map((obvValue, obvKey) => {
                  // console.log('TESTING OBV: ', obvValue, obvKey, obvValue.find(obj=> obj.observationName))
                  let obvResRow = obvValue.find((obj) => obj.observationValue)
                  if (
                    obvValue.find((obj) => obj.observationName)?.observationName
                  )
                    return {
                      flag: obvValue.find((obj) => obj.flag)?.flag || null,
                      observationName:
                        obvValue.find((obj) => obj.observationName)
                          ?.observationName || null,
                      observationNumber:
                        obvValue.find((obj) => obj.observationNumber)
                          ?.observationNumber || null,
                      observationValue:
                        obvValue.find((obj) => obj.observationValue)
                          ?.observationValue || null,
                      refRange:
                        obvValue.find((obj) => obj.refRange)?.refRange || null,
                      testName:
                        obvValue.find((obj) => obj.testName)?.testName || null,
                      testNumber:
                        obvValue.find((obj) => obj.testNumber)?.testNumber ||
                        null,
                      units: obvValue.find((obj) => obj.units)?.units || null,
                      comments: obvValue
                        .map((obj) => {
                          if (obj?.comments?.length) return obj?.comments
                        })
                        .join(' '),
                    }
                })
                .value()
              if (
                value.find((obj) => obj.testName)?.testName &&
                !value.find((obj) => obj.testName)?.testName?.includes('PDF')
              )
                return {
                  testName: value.find((obj) => obj.testName)?.testName || null,
                  observations: _.compact(obvResult),
                }
            })
            .value()

          // testResults.forEach((obj, index) => {
          //   if (obj.testNumber) {
          //     mainTest[testNumber] =
          //     tempTestName = obj.testName
          //   }
          //   else if(tempTestName) {

          //   }
          // })
          // console.log('testing:', testResults)
          console.log('testing loadash:', mappedResultData)
          let tempReport = {
            ...x,
            patientName,
            testName,
            observationValue,
            refRange,
            units,
            flag,
            orderingProvider,
            comments,
            pdf,
            mappedResultData: _.compact(mappedResultData),
            mainComments,
            orderDate: moment(orderDate, 'YYYYMMDDHHmmss').format(
              'YYYY-MM-DD, HH:mm',
            ),
          }
          reportDetailArray.push(tempReport)
        } else {
          console.log('NOT HL7', data)
        }
      })
    } else {
      console.log('input NOT an Array')
      // reports.push(content)
      // reportIds.push(content.clinicalreport)
    }
    // this.getClinicalReportsFromAila(reports, reportIds)
    // this.setState({clinicalReportsFromChange: reports})
    // } else {
    //   console.log('Could not get clinical reports from change')
    //   // toast.error('Could not get clinical reports from change')
    // }
    console.log('TESTING RESULT: ', reportDetailArray)
    return reportDetailArray
  }

  async getDownloadableClinicalRecords() {
    // console.log('TESTING: Down')
    this.setState({ clinicalReportTabLoading: true })
    const url = `${Config.BACKEND_URL}v1/clinical-reports/get-status`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${this.props.token}`,
      },
    })
      .then(async ({ data }) => {
        if (data.length === 0) {
          this.setState({ clinicalReportTabLoading: false })
          console.log(-1)
          return
        }
        console.log(0)
        console.log('TESTING: ', Object.keys(data[0]))
        let count = 0
        // let recordInfo = data?.map((x) => {
        //   return this.convertHL7(x)
        // })
        // Original code
        let recordInfo = await this.convertHL7(data)
        console.log(1)
        recordInfo = recordInfo?.map((x) => {
          x.uniqueId = count
          count = count + 1
          return x
        })
        console.log(2)
        console.log('HL7: ', recordInfo)
        console.log(3)
        // console.log('HL7: ', recordInfo, data)
        this.setState({
          clinicalReportsFromChange: recordInfo,
          clinicalReportTabLoading: false,
        })
        console.log(4)
      })
      .catch((err) => {
        console.log('error when getting multiple orders2', err)
        toast.error('Something went wrong')
      })
    /*
    const url = `${Config.CHANGE_BACKEND_URL}change/reports/downloadable`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        change_session_id: this.state.sessionId,
      },
    })
      .then(async ({ data }) => {
        let recordInfo = null
        if (data?.RESULT?.OBJECT?.length) {
          recordInfo = await this.getMultipleClinicalDocuments(
            data?.RESULT?.OBJECT,
          )
        }
        let count = 0
        recordInfo = recordInfo?.map((x) => {
          x.uniqueId = count
          count = count + 1
          return x
        })
        console.log('HL7: ', recordInfo, data?.RESULT?.OBJECT)
        this.setState({
          clinicalReportsFromChange: recordInfo,
          clinicalReportTabLoading: false,
        })
      })
      .catch((err) => {
        this.setState({ clinicalReportTabLoading: false })
        console.log('error when getting downloadable orders', err)
        toast.error('Something went wrong')
      })
    */
  }

  // Looks like this function is deprecated
  // getClinicalReportsFromAila(changeReports, changeReportIds) {
  //   this.setState({ clinicalReportTabLoading: true })
  //   const url = `${Config.BACKEND_URL}labs/reports/orderOrReport`
  //   axios({
  //     method: 'post',
  //     url,
  //     headers: {
  //       Authorization: `JWT ${this.props.token}`,
  //     },
  //     data: {
  //       change_clinical_report_ids: changeReportIds,
  //     },
  //   })
  //     .then(({ data }) => {
  //       console.log('clinical reports by report ids', data)
  //       this.setState({ clinicalReportTabLoading: false })
  //       if (!data || Object.keys(data).length === 0) return

  //       let temp = changeReports.filter((x) => !data[x.clinicalreport])
  //       this.setState({ clinicalReportsFromChange: temp })
  //     })
  //     .catch((err) => {
  //       console.log('error when getting lab orders5', err)
  //       toast.error('Something went wrong')
  //     })
  // }

  getLabOrdersForPatient(uid) {
    this.setState({ patientModalLoading: true })
    const url = `${Config.BACKEND_URL}patients/labs/orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        x_firebase_id: uid,
      },
    })
      .then(({ data }) => {
        console.log('patient lab data', data)
        this.setState({ patientModalLoading: false, patientLabOrders: data })
      })
      .catch((err) => {
        console.log('error when getting lab orders6', err)
        toast.error('Something went wrong')
      })
  }

  getClinicalReportDocuments(clinicalReport) {
    return new Promise((resolve, reject) => {
      const url = `${Config.CHANGE_BACKEND_URL}change/reports/documents`
      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${this.props.token}`,
          clinical_report_id: clinicalReport.clinicalreport,
        },
      })
        .then(({ data }) => {
          console.log('clinical document data', data)
          if (data?.RESULT?.OBJECT) {
            let content = data.RESULT.OBJECT
            resolve(content)
          } else {
            toast.error('Could not get clinical reports from change')
            reject()
          }
        })
        .catch((err) => {
          console.log('error when getting lab orders7', err)
          toast.error('Something went wrong')
          reject()
        })
    })
  }

  onModalLabOrderClicked(labOrder) {
    this.setState({ patientModalLoading: true })

    this.getClinicalReportDocuments(this.state.modalClinicalReport)
      .then((documents) => {
        this.saveClinicalReportInOurSystem(
          labOrder,
          documents,
          this.state.modalClinicalReport,
        )
      })
      .catch((err) => {
        console.log(
          'something went wrong when getting clicnical report documents',
          err,
        )
      })
  }

  saveClinicalReportInOurSystem(labOrder, documents, clinicalReport) {
    let toSave = {
      change_clinical_report_id: clinicalReport.clinicalreport,
      lab_order_id: labOrder._id,
      clinical_report: clinicalReport,
      result: documents,
    }

    const url = `${Config.BACKEND_URL}labs/reports`
    axios({
      method: 'post',
      url,
      headers: {
        Authorization: `JWT ${this.props.token}`,
      },
      data: toSave,
    })
      .then(({ data }) => {
        console.log('saved report in the backend')
        toast.success(
          'Successfully associated clinical report with patient lab order',
        )
        this.setState({
          patientModalLoading: false,
          showPatientAssignModal: false,
        })
        this.getDownloadableClinicalRecords()
      })
      .catch((err) => {
        console.log('error when getting lab orders8', err)
        toast.error('Something went wrong')
      })
  }

  resetLabFields() {
    this.setState({
      loading: false,
      patientList: [],
      selectedPatient: null,
      patientData: null,
      modalIsOpen: false,
      labType: null,
      orderType: null,
      billType: null,
      labLocation: null,
      labPriority: null,
      collectionDate: null,
      showIcd: false,
      labComments: '',
      labInstructions: '',
      addCpt: [],
      addIcd10: [],
      conditionsData: [],
      symptomsData: [],
    })
  }

  onPatientSelected(val) {
    let { token, adminId } = this.props
    this.setState({ selectedPatient: val })
    this.fetchPatientInformation(val.value) //contains uid
    this.getConditionsData(val.value)
    this.getSymptomsData(val.value)
    this.props.fetchPatientCarePlanData({
      patient: val,
      token: token,
      adminId: adminId,
      patientId: val.value,
    })
    this.props.fetchPatientConditions(val.value, token)
    this.props.fetchPatientMedications(val.value, token)
    this.props.fetchPatientLabs(val.value, token)
    this.props.fetchPatientCoreDate(val.value, token)
  }

  onLabSearchStringChange(text) {
    this.setState({ labSearchString: text })
  }

  onNextPressed() {
    let {
      selectedPatient,
      labType,
      billType,
      labLocation,
      labPriority,
      collectionDate,
    } = this.state
    if (
      !selectedPatient ||
      // !labType ||
      !billType ||
      !labLocation ||
      !labPriority ||
      !collectionDate
    ) {
      toast.error('Please fill all the fields before placing order')
      return
    }

    this.setState({ showIcd: true })
  }

  onPatientSelectedForReport(val) {
    this.getLabOrdersForPatient(val.value)
    this.setState({ selectedPatientForAssigningClinicalReport: val })
  }

  onViewClinicalReportClicked(clinicalReport) {
    this.setState({ loading: true })
    if (clinicalReport.pdf) {
      this.setState({
        showReportModal: true,
        loading: true,
        encodedReportString: clinicalReport.pdf,
      })
      return
    }
    const url = `${Config.CHANGE_BACKEND_URL}change/reports/documents`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        clinical_report_id: clinicalReport.clinicalreport,
      },
    })
      .then(({ data }) => {
        console.log('clinical document data', data)
        this.setState({ loading: false })
        if (data?.RESULT?.OBJECT) {
          let docs = []
          let content = data.RESULT.OBJECT
          if (Array.isArray(content)) {
            content.forEach((x) => {
              if (x.mime_type === 'pdf')
                this.setState({ encodedReportString: x.body_text })
            })
          } else {
            if (content.mime_type === 'pdf')
              this.setState({ encodedReportString: content.body_text })
          }
          this.setState({ showReportModal: true })
        } else {
          toast.error('Could not get clinical reports from change')
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders0', err)
        toast.error('Something went wrong')
      })
  }

  renderObservation = () => {
    const { observationData } = this.state
    return (
      <div style={{ padding: 20 }}>
        <p
          className={GlobalStyles.closeModalBtn}
          onClick={() => this.setState({ showObservationModal: false })}
        >
          X
        </p>
        {observationData?.map((obv) => (
          <div style={{ paddingBottom: 20 }}>
            <p style={{ padding: 20, color: 'black', fontWeight: 'bolder' }}>
              {obv?.testName}
            </p>
            {this.renderObservationTable(obv?.observations)}
          </div>
        ))}
      </div>
    )
  }

  renderObservationTable(obvData) {
    // console.log('CALLED OBV RENDER: ', data)
    let data = obvData.filter((obv) => !obv?.observationValue?.includes('DNR'))
    let tableHeader = (
      <div className={Styles.row}>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Observation Name
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Result
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Reference Range
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          UOM
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Flag
        </p>
        <p className={Styles.headerText} style={{ width: '40%' }}>
          Comments
        </p>
      </div>
    )

    let tableRows = data
      ? data.map((x) => (
          <div className={Styles.row}>
            <p
              className={Styles.entryText}
              style={{
                width: '20%',
              }}
            >
              {x?.observationName}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x?.observationValue}
            </p>
            <p
              className={Styles.entryText}
              style={{
                width: '10%',
              }}
            >
              {x?.refRange}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x.units}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x.flag}
            </p>
            <p
              className={Styles.entryText}
              style={{
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                textAlign: 'start',
                width: '40%',
              }}
            >
              {x.comments}
            </p>
          </div>
        ))
      : []
    return (
      <div
        style={{
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {tableHeader}
        {tableRows}
      </div>
    )
  }

  renderReferringToModal = () => {
    let { referringToFax, referringToPhone, referringTo } = this.state

    let adminAccess = true

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.setState({ showReferringToModal: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Referring To
        </h4>

        {/* <div
          className={Styles.inputDiv}
          style={{
            display: adminAccess ? 'flex' : 'none',
          }}
        >
        </div> */}
        <div
          className={Styles.inputDiv}
          style={{
            display: adminAccess ? 'flex' : 'none',
          }}
        >
          <p
            className={Styles.inputQuestion}
            style={{
              width: '100px',
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            Name
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Referring To'}
              className={Styles.textInput}
              type="text"
              value={referringTo}
              onChange={(e) => {
                let text = e.target.value
                this.setState({ referringTo: text })
              }}
            />
          </div>
          <FontAwesomeIcon
            onClick={() => {
              this.setState({ showSearchModal: true })
              this.setState({ searchName: '' })
            }}
            icon={faSearch}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </div>

        <div
          className={Styles.inputDiv}
          style={{ display: adminAccess ? 'flex' : 'none' }}
        >
          <p
            className={Styles.inputQuestion}
            style={{
              width: '100px',
            }}
          >
            Phone Number
          </p>
          <div
            style={{
              width: '30%',
              margin: '0px 20px',
            }}
          >
            <input
              placeholder={'Phone Number'}
              className={Styles.textInput}
              type="text"
              value={referringToPhone}
              onChange={(e) => {
                let text = e.target.value
                this.setState({ referringToPhone: text })
              }}
            />
          </div>

          <p
            className={Styles.inputQuestion}

            // style={{
            //   alignSelf: 'center',
            //   color: Constants.primaryTheme,
            //   fontWeight: 'bold',
            // }}
          >
            Fax Number
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Fax Number'}
              className={Styles.textInput}
              type="text"
              value={referringToFax}
              onChange={(e) => {
                let text = e.target.value
                this.setState({ referringToFax: text })
              }}
            />
          </div>
        </div>
        <Button
          onClick={() => {
            this.setState({ showReferringToModal: false })
            // setSaveReferralData({
            //   ...saveReferralData,
            //   fax_number: faxNumber,
            //   phone: phoneNumber,
            //   referring_to: referringTo,
            // })
            // console.log('button1')

            // saveReferralToMongo({
            //   ...saveReferralData,
            //   fax_number: faxNumber,
            //   phone: phoneNumber,
            //   referring_to: referringTo,
            // })

            // Axios(pdfTemplate).then((res) => {
            //   let templateHtml = Handlebars.compile(res.data.toString())
            //   let bodyHtml = templateHtml({
            //     ...saveReferralData,
            //     fax_number: faxNumber,
            //     phone: phoneNumber,
            //     referring_to: referringTo,
            //   })
            //   setPdfData(bodyHtml)
            //   setPdfTemplate(templateHtml)
            // })
            // try {
            //   let saveHtmlPdfData = pdfTemplate(saveReferralData)
            //   console.log('button3')
            //   setPdfData(saveHtmlPdfData)
            // } catch (error) {
            //   console.error(error)
            // }

            let { currentlyViewingOrderData } = this.state
            let data = currentlyViewingOrderData
            data = {
              ...data,
              referring_to: this.state.referringTo,
              phone: this.state.referringToPhone,
              fax_number: this.state.referringToFax,
            }

            console.log('data:', data)

            if (data?.referral_type === 'physician') {
              Axios(htmlModule3).then((res) => {
                let templateHtml = Handlebars.compile(res.data.toString())
                let bodyHtml = templateHtml(data)
                console.log(bodyHtml)
                this.setState({ pdfData: bodyHtml })
                this.setState({ showModal: true })
              })
            } else if (data?.referral_type === 'medical') {
              Axios(htmlModule3).then((res) => {
                let templateHtml = Handlebars.compile(res.data.toString())
                let bodyHtml = templateHtml(data)
                this.setState({ pdfData: bodyHtml })
                this.setState({ showModal: true })
              })
            } else if (data?.referral_type === 'priorAuth') {
              Axios(htmlModule3).then((res) => {
                let templateHtml = Handlebars.compile(res.data.toString())
                let bodyHtml = templateHtml(data)
                this.setState({ pdfData: bodyHtml })
                this.setState({ showModal: true })
              })
            } else if (data?.referral_type === 'imaging') {
              Axios(htmlModule3).then((res) => {
                let templateHtml = Handlebars.compile(res.data.toString())
                let bodyHtml = templateHtml(data)
                this.setState({ pdfData: bodyHtml })
                this.setState({ showModal: true })
              })
            } else if (data?.referral_type === 'speciality') {
              Axios(htmlModule2).then((res) => {
                let templateHtml = Handlebars.compile(res.data.toString())
                let bodyHtml = templateHtml(data)
                this.setState({ pdfData: bodyHtml })
                this.setState({ showModal: true })
              })
            }

            console.log('Button clicked') // Add this line to check if the function is being called
            // setShowModal(true)
          }}
          className={GlobalStyles.button}
          style={{
            width: 100,
            marginTop: 10,
          }}
          variant="primary"
        >
          Select
        </Button>
      </div>
    )
  }

  renderSearchList = () => {
    let { fuse, facilityType, searchName } = this.state
    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: [
        'facility_name',
        'email',
        'phone',
        'address',
        'city',
        'state',
        'zipcode',
      ],
    }

    let tempArray = facilityType ? metadata[facilityType?.value] : []
    fuse = new Fuse(tempArray, options)

    let uniqueList = []

    if (searchName.length !== 0) {
      const results = fuse.search(searchName)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else {
      uniqueList = tempArray
    }

    const renderList = (facilityData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.contentBackground}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            backgroundColor: Constants.contentBackground,
            borderRadius: Constants.borderRadius,
            padding: '1% 2%',
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {facilityType?.label === 'Imaging' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.type || ''} ${facilityData?.email || ''}`}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.address || ''} ${
                    facilityData?.city || ''
                  } \n ${facilityData?.state || ''} ${
                    facilityData?.zipcode || ''
                  }`}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.operation_hours || ''} \n ${
                    facilityData?.medications_infused || ''
                  } `}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {` ${facilityData?.referral_link || ''}`}
                </h6>
              </div>
            ) : facilityType?.label === 'Infusion' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.type || ''}
                  <br />
                  {facilityData?.email || ''}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.address || ''}
                  {facilityData?.city || ''} {facilityData?.state || ''}{' '}
                  {facilityData?.zipcode || ''}
                  <br />
                  {` ${facilityData?.operation_hours || ''}`}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  Medications Infused: {facilityData?.medications_infused || ''}
                  <br />
                  Accepted Insurances : {facilityData?.accepted_insurance || ''}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {facilityData?.referral_link || ''}
                </h6>
              </div>
            ) : facilityType?.label === 'DME' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.email || ''}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.address || ''}
                  {facilityData?.city || ''} {facilityData?.state || ''}{' '}
                  {facilityData?.zipcode || ''}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {facilityData?.referral_link || ''}
                </h6>
              </div>
            ) : (
              <div></div>
            )}

            <Button
              className={GlobalStyles.button}
              //   disabled={loading}
              onClick={() => {
                console.log(facilityData)
                this.setState({ referringTo: facilityData?.facility_name })
                this.setState({ referringToPhone: facilityData?.phone })
                this.setState({ referringToFax: facilityData?.fax })
                this.setState({ showSearchModal: false })
                this.setState({ searchName: '' })
              }}
              style={{
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
            >
              Select
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Facility
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '2%',
            // padding: '1% 5%',
          }}
        >
          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Type</p>
            <div style={{ width: '50%', margin: '0px 20px' }}>
              <Select
                options={[
                  { label: 'Infusion', value: 'INFUSION_DATABASE' },
                  { label: 'Imaging', value: 'IMAGING_DATABASE' },
                  { label: 'DME', value: 'DME_DATABASE' },
                ]}
                value={facilityType}
                autoBlur={true}
                onChange={(val) => {
                  this.setState({ facilityType: val })
                  console.log(metadata[val?.value])
                }}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Search</p>
            <div style={{ width: '50%', margin: '0px 20px' }}>
              <input
                placeholder="Search Name"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={searchName}
                onChange={(e) => {
                  this.setState({ searchName: e.target.value })
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {uniqueList?.length !== 0 ? (
            uniqueList?.map((facility, index) => renderList(facility, index))
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search again
            </p>
          )}
        </div>
      </div>
    )
  }

  updateReferralStatusInMongo = (orderId, transmissionStatus) => {
    console.log('updating data in database')
    axios({
      method: 'put',
      url: Config.BACKEND_URL + `referrals/update`,
      data: {
        order_id: orderId,
        transmission_status: transmissionStatus,
      },
    })
      .then((response) => {
        console.log('Updated data in database: ', response.data)
        // TODO: Check if correctly updated and stuff
      })
      .catch((err) => {
        console.log('Error when updating data in database', err)
      })
  }

  onSendPressed = () => {
    console.log('sending fax')
    this.setState({ showModal: false })

    let {
      pdfData,
      referringTo,
      referringToPhone,
      referringToFax,
      currentlyViewingOrderData,
    } = this.state

    // let saveReferralData = {}
    // let selectedProvider = {}
    let faxNumber = referringToFax
    // let referralType = null

    // let doctor_details = saveReferralData?._id
    //   ? saveReferralData?.provider_name +
    //     ' ' +
    //     saveReferralData?.provider_specialty
    //   : selectedProvider?.provider?.first_name +
    //     ' ' +
    //     selectedProvider?.provider?.last_name +
    //     `${
    //       selectedProvider?.provider?.speciality
    //         ? ', ' + selectedProvider?.provider?.speciality
    //         : ''
    //     }`

    let result = {
      // patient_id: this.props?.patientId,
      patient_id: this.state.currentlyViewingOrderData?.patient_id,
      sSenderEmail: 'pcadmin@ailahealth.com',
      sToFaxNumber: faxNumber,
      sFileName_1: 'PatientReferral.pdf',
      htmlContent: pdfData,
      sCoverPage: 'Standard',
      sCPFromName: this.state.currentlyViewingOrderData?.provider_name,
      sCPToName: referringTo,
      sCPOrganization: 'Aila Health',
      sCPSubject: this.state.currentlyViewingOrderData?.referral_type,
      // sCPSubject: saveReferralData?._id
      //   ? saveReferralData?.referral_type
      //   : referralType?.label,
    }
    console.log('x_firebase_id', this.props?.adminId)
    console.log('Result:', result)

    let config = {
      method: 'post',
      url: Config.CHANGE_BACKEND_URL + `srfax/referral-outbox`,

      headers: {
        Authorization: `JWT ${this.props.token}`,
        x_firebase_id: this.props?.adminId,
      },
      data: result,
    }
    axios(config)
      .then(({ data }) => {
        console.log('SR-FAX SENT : ', data)
        // SAVE TO MONGODB
        this.updateReferralStatusInMongo(currentlyViewingOrderData._id, data) // Need to define this func
        if (data.Status !== 'Failed') {
          toast.success('Fax queued successfully')
          // updateReferralinMongo()
        } else {
          toast.warning(`Could not send fax : ${data.Result}`)
        }
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not send Fax')
      })
  }

  renderOrdersMasterTable() {
    let { token, adminId } = this.props
    if (this.state.clinicalReportTabLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            minHeight: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }
    return (
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'column',
          width: '100%',
          marginTop: 20,
        }}
      >
        <Table
          columns={renderOrdersMasterTableColumns(
            this.navigatetoPatientProfile,
            this.onPatientNavLinkClicked,
            this.onViewOrderPressed,
            this.onViewClinicalReportClicked,
          )}
          data={this.state.allPrescriptions // BUG: allPrescriptions array is always empty
            .concat(this.state.allLabsBackend) // BUG: allLabsBackend array is always empty
            .concat(this.state.allReferrals)}
        />

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showRequisitionModal}
          onRequestClose={() => this.setState({ showRequisitionModal: false })}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${this.state.modalRequisition}`}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showReferringToModal}
          onRequestClose={() => this.setState({ showReferringToModal: false })}
          // style={{
          //   content: {
          //     zIndex: 10000,
          //     width: '1000px', // Set your desired width
          //     height: '300px', // Set your desired height
          //     margin: 'auto',
          //   },
          // }}
          style={{
            overlay: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.44)',
              zIndex: 20,
            },
            content: {
              zIndex: 20,
              width: '1000px',
              height: '300px',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
          contentLabel="Modal"
        >
          {this.renderReferringToModal()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showSearchModal}
          onRequestClose={() => this.setState({ showSearchModal: false })}
          style={{
            overlay: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.44)',
              zIndex: 30,
            },
            content: {
              zIndex: 30,
              width: '95%',
              height: '90%',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
          contentLabel="Modal"
        >
          {this.renderSearchList()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showModal}
          onRequestClose={() => this.setState({ showModal: false })}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <h3>Referral Document</h3>
          <Button
            onClick={() => this.setState({ showReferringToModal: true })}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 240,
              top: 10,
            }}
            variant="primary"
          >
            Edit To
          </Button>
          <Button
            onClick={() => {
              let postData = {
                htmlContent: this.state.pdfData,
              }
              let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
              //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
              console.log('PDF BACKEND URL', url)
              axios({
                method: 'post',
                headers: {
                  Authorization: 'JWT ' + token,
                  x_firebase_id: adminId,
                },
                url: url,
                data: postData,
              })
                .then(({ data }) => {
                  // Insert a link that allows the user to download the PDF file
                  console.log('Got response back')
                  var link = document.createElement('a')
                  link.innerHTML = 'Download PDF file'
                  link.download = `Patient Referral.pdf`
                  link.href =
                    'data:application/octet-stream;base64,' + data.result
                  // document.body.appendChild(link);
                  link.click()
                  console.log('downloaded')
                  toast.success('pdf downloaded')
                })
                .catch((err) => {
                  console.log(
                    'O1. error when getting saving provider goals data',
                    err,
                  )
                  toast.error('Could not download Notes, please try again')
                })
            }}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 120,
              top: 10,
            }}
            variant="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => this.onSendPressed()}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 5,
              top: 10,
            }}
            variant="primary"
          >
            Submit
          </Button>
          <iframe
            srcDoc={this.state.pdfData}
            title="Provider Referral"
            style={{ height: '100%', width: '100%' }}
          />
        </Modal>
        {/* <Modal
          ariaHideApp={false}
          isOpen={this.state.showReportModal}
          onRequestClose={() => this.setState({ showReportModal: false })}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            src={`data:application/pdf;base64,${this.state.encodedReportString}`}
            title="report"
            style={{ height: '100%', width: '100%' }}
          />
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showObservationModal}
          onRequestClose={() => this.setState({ showObservationModal: false })}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          {this.renderObservation()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showPatientAssignModal}
          onRequestClose={() =>
            this.setState({ showPatientAssignModal: false })
          }
          style={customPatientAssignModalStyle}
          contentLabel="Modal"
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
              height: '100%',
            }}
          >
            <div style={{ width: '50%' }}>
              <Select
                placeholder={'Select patient...'}
                options={this.state.patientList}
                value={this.state.selectedPatientForAssigningClinicalReport}
                isMulti={false}
                isSearchable={true}
                onChange={(val) => this.onPatientSelectedForReport(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>

            {this.state.patientModalLoading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: 200,
                  justifyContent: 'center',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            ) : modalLabOrdersElements && modalLabOrdersElements.length > 0 ? (
              <div style={{ marginTop: 20 }}>
                <div className={Styles.row}>
                  <p className={Styles.headerText} style={{ width: '60%' }}>
                    Test(s)
                  </p>
                  <p className={Styles.headerText} style={{ width: '40%' }}>
                    Date
                  </p>
                </div>
                {modalLabOrdersElements}
              </div>
            ) : null}
          </div>
        </Modal> */}

        {/* <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        > */}
        {/* </div> */}
      </div>
    )
  }

  renderInfusionTable() {
    return (
      <Table
        columns={renderInfusionAppointmentsMasterTableColumns()}
        data={this.state.infusionOrders}
      />
    )
  }

  viewPAFPdfOrder(order) {
    console.log('Order info', order)

    let data = { ...order }
    data.patient_gender = order.patient_gender_at_birth
    data.patient_ssn = order.patient_ssn || order.patient_ssn_last_4 || 'No SSN'
    data.patient_address = order.shipping_address_line1
    data.provider_name = order.provider_name || order.providerData?.first_name
    data.provider_phone = order.provider_phone || order.providerData?.phone
    data.provider_fax = order.provider_fax || order.providerData?.fax
    data.provider_signature = order.providerData?.sign_image
    data.provider_credential = order.providerData?.credential

    data.ack_a = order.agreement1 ? 'X' : ' '
    data.ack_b = order.agreement2 ? 'X' : ' '
    data.ack_c = order.agreement3 ? 'X' : ' '
    data.ack_d = order.agreement4 ? 'X' : ' '
    data.ack_e = order.agreement5 ? 'X' : ' '

    Axios(pafTemplate).then((res) => {
      let templateHtml = Handlebars.compile(res.data.toString())
      let bodyHtml = templateHtml(data)
      this.setState({ pdfData: bodyHtml })
      this.setState({ showPAFModal: true })
    })
  }

  renderPatientAssistance() {
    let { token, adminId } = this.props

    return (
      <>
        <Table
          columns={renderPatientAssistanceMasterTableColumns({
            viewPAFPdfOrder: this.viewPAFPdfOrder,
          })}
          data={this.state.patientAssistanceData}
        />

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showReferringToPAFModal}
          onRequestClose={() =>
            this.setState({ showReferringToPAFModal: false })
          }
          // style={{
          //   content: {
          //     zIndex: 10000,
          //     width: '1000px', // Set your desired width
          //     height: '300px', // Set your desired height
          //     margin: 'auto',
          //   },
          // }}
          style={{
            overlay: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.44)',
              zIndex: 20,
            },
            content: {
              zIndex: 20,
              width: '1000px',
              height: '300px',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
          contentLabel="Modal"
        >
          {this.renderReferringToModal()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showPAFModal}
          onRequestClose={() => this.setState({ showPAFModal: false })}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <h3>Patient Assistance Document</h3>
          <Button
            onClick={() => this.setState({ showReferringToPAFModal: true })}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 240,
              top: 10,
            }}
            variant="primary"
          >
            Edit To
          </Button>
          <Button
            onClick={() => {
              let postData = {
                htmlContent: this.state.pdfData,
              }
              let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
              //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
              console.log('PDF BACKEND URL', url)
              axios({
                method: 'post',
                headers: {
                  Authorization: 'JWT ' + token,
                  x_firebase_id: adminId,
                },
                url: url,
                data: postData,
              })
                .then(({ data }) => {
                  // Insert a link that allows the user to download the PDF file
                  console.log('Got response back')
                  var link = document.createElement('a')
                  link.innerHTML = 'Download PDF file'
                  link.download = `Patient Assistance Form.pdf`
                  link.href =
                    'data:application/octet-stream;base64,' + data.result
                  // document.body.appendChild(link);
                  link.click()
                  console.log('downloaded')
                  toast.success('pdf downloaded')
                })
                .catch((err) => {
                  console.log(
                    'O1. error when getting saving provider goals data',
                    err,
                  )
                  toast.error('Could not download Notes, please try again')
                })
            }}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 120,
              top: 10,
            }}
            variant="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => this.onSendPressed()}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 5,
              top: 10,
            }}
            variant="primary"
          >
            Submit
          </Button>
          <iframe
            srcDoc={this.state.pdfData}
            title="Provider Referral"
            style={{ height: '100%', width: '100%' }}
          />
        </Modal>
      </>
    )
  }

  updatePatientAssignedTo = (val, docId) => {
    console.log('val', docId, val._id)
    axios({
      method: 'post',
      url: `${Config.BACKEND_URL}v1/clinical-reports/update-status`,
      headers: {
        updated_by: this.props.mongoUser?.firebase_id,
        Authorization: 'JWT ' + this.props.token,
      },
      data: {
        id: docId,
        assigned_to: val._id,
      },
    }).then(({ data }) => {
      let { clinicalReportsFromChange } = this.state
      for (let i = 0; i < clinicalReportsFromChange.length; i++) {
        if (clinicalReportsFromChange[i].id === docId) {
          clinicalReportsFromChange[i].assigned_to = val._id
          break
        }
      }
    })
  }

  dropDownSelection(docId) {
    let { clinicalReportsFromChange } = this.state
    for (let i = 0; i < clinicalReportsFromChange.length; i++) {
      if (clinicalReportsFromChange[i].id === docId) {
        if (clinicalReportsFromChange[i].assigned_to) {
          return clinicalReportsFromChange[i].assigned_to
        } else {
          return null
        }
      }
    }
  }
  renderSelectPatientName1(docId) {
    // if (this.state.showIcd) return null
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '5px 5px',
          zIndex: 999,
          position: 'absolute',
        }}
      >
        {/* <p
          style={{
            alignSelf: 'center',
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: 180,
          }}
        >
          Patient name
        </p>
         <div style={{ width: '70px', zIndex: 999, position: 'relative' }}>
        <div
          style={{
            zIndex: 999,
            position: 'relative',
          }}
        >
         */}
        <Select
          placeholder={'Patient'}
          isSearchable={true}
          options={this.state.patientList}
          value={this.dropDownSelection(docId)}
          // onChange={this.onPatientSelected.bind(this)}
          onChange={(e) => this.updatePatientAssignedTo(e, docId)}
          theme={(theme) => selectTheme(theme)}
        />
        {/* </div> */}
      </div>
    )
  }

  // newTableHeader = () => {
  //   return (
  //     <div className={Styles1.contentRow}>
  //       <h6 className={Styles1.mtableHeader}>Patient Name</h6>
  //       <h6 className={Styles1.smtableHeader}>Date</h6>
  //       <h6 className={Styles1.mtableHeader}>Test Name</h6>
  //       <h6 className={Styles1.xtableHeader}>Comments</h6>
  //       <h6 className={Styles1.smtableHeader}>Ordering Provider</h6>
  //       <h6 className={Styles1.smtableHeader}>Status</h6>
  //       <h6 className={Styles1.smtableHeader}>Assigned to</h6>
  //     </div>
  //   )
  // }

  // newTableContentRow = () => {
  //   const { clinicalReportsFromChange } = this.state
  //   let tableContentElement = []
  //   let xCounter = 0
  //   tableContentElement =
  //     clinicalReportsFromChange.length !== 0 &&
  //     clinicalReportsFromChange.map((patient) => {
  //       // Code
  //       xCounter++
  //       return (
  //         // <div key={patient.firebase_id} className={Styles.contentRow}>
  //         <div key={xCounter} className={Styles1.contentRow}>
  //           {/* <h7 className={Styles.mtableData}>
  //             <Link
  //               onClick={() => {
  //                 navigatetoPatientProfile(patient.firebase_id)
  //                 onPatientNavLinkClicked('profile')
  //               }}
  //               to={{
  //                 pathname: '/patient/profile',
  //                 state: 'info',
  //                 key: 2,
  //               }}
  //               className={Styles.link}
  //             >
  //               {patient?.firstName || ''} {patient?.lastName || ''}
  //             </Link>
  //           </h7> */}
  //           <h7 className={Styles1.smtableData}>{patient.patientName}</h7>
  //           <h7 className={Styles1.mtableData}>{patient.orderDate}</h7>
  //           <h7 className={Styles1.xtableData}>
  //             {
  //               <p
  //                 onClick={() => {
  //                   this.setState({
  //                     observationData: patient.mappedResultData,
  //                     showObservationModal: true,
  //                   })
  //                 }}
  //                 className={Styles1.entryText}
  //                 style={{
  //                   // width: '30%',
  //                   color: 'blue',
  //                   textDecoration: 'underline',
  //                   cursor: 'pointer',
  //                   textAlign: 'start',
  //                   padding: '0px 5px',
  //                 }}
  //               >
  //                 {patient?.mappedResultData
  //                   ?.map((obj) => obj?.testName)
  //                   ?.join(', ')}
  //               </p>
  //             }
  //           </h7>
  //           <h7 className={Styles1.smtableData}>{patient.mainComments}</h7>
  //           <h7 className={Styles1.smtableData}>{patient.orderingProvider}</h7>
  //           <h7 className={Styles1.mtableData}>
  //             {
  //               <p
  //                 onClick={() => this.onViewClinicalReportClicked(patient)}
  //                 className={Styles1.entryText}
  //                 style={{
  //                   // width: '10%',
  //                   color: 'blue',
  //                   textDecoration: 'underline',
  //                   cursor: 'pointer',
  //                 }}
  //               >
  //                 View
  //               </p>
  //             }
  //           </h7>
  //           <h7 className={Styles1.smtableData}>
  //             {
  //               <p className={Styles1.entryText}>
  //                 {this.renderSelectPatientName1(patient._id)}
  //               </p>
  //             }
  //           </h7>
  //         </div>
  //       )
  //     })
  //   return tableContentElement
  // }

  renderClinicalReportsContent() {
    console.log('rendering clinical: ', this.state.clinicalReportsFromChange)
    if (this.state.clinicalReportTabLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            minHeight: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    let { clinicalReportsFromChange, patientLabOrders } = this.state

    // NEW
    let newTableHeader = (
      <div className={Styles1.contentRow}>
        <h6 className={Styles1.mtableHeader}>Patient Name</h6>
        <h6 className={Styles1.smtableHeader}>Date</h6>
        <h6 className={Styles1.mtableHeader}>Test Name</h6>
        <h6 className={Styles1.xtableHeader}>Comments</h6>
        <h6 className={Styles1.smtableHeader}>Ordering Provider</h6>
        <h6 className={Styles1.smtableHeader}>Status</h6>
        <h6 className={Styles1.smtableHeader}>Assigned to</h6>
      </div>
    )

    let newTableContentRow = clinicalReportsFromChange
      ? clinicalReportsFromChange.map((eachPatientClinicalReport) => (
          <div
            key={eachPatientClinicalReport._id}
            className={Styles1.contentRow}
          >
            {/* <h7 className={Styles.mtableData}>
                <Link
                  onClick={() => {
                    navigatetoPatientProfile(patient.firebase_id)
                    onPatientNavLinkClicked('profile')
                  }}
                  to={{
                    pathname: '/patient/profile',
                    state: 'info',
                    key: 2,
                  }}
                  className={Styles.link}
                >
                  {patient?.firstName || ''} {patient?.lastName || ''}
                </Link>
              </h7> */}
            <div className={Styles1.smtableData}>
              {eachPatientClinicalReport.patientName}
            </div>
            <div className={Styles1.mtableData}>
              {eachPatientClinicalReport.orderDate}
            </div>
            <div className={Styles1.xtableData}>
              {
                <p
                  onClick={() => {
                    this.setState({
                      observationData:
                        eachPatientClinicalReport.mappedResultData,
                      showObservationModal: true,
                    })
                  }}
                  className={Styles.entryText}
                  style={{
                    //   width: '30%',
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    textAlign: 'start',
                    padding: '0px 5px',
                  }}
                >
                  {eachPatientClinicalReport?.mappedResultData
                    ?.map((obj) => obj?.testName)
                    ?.join(', ')}
                </p>
              }
            </div>
            <div className={Styles1.smtableData}>
              {eachPatientClinicalReport.mainComments}
            </div>
            <div className={Styles1.smtableData}>
              {eachPatientClinicalReport.orderingProvider}
            </div>
            <div className={Styles1.mtableData}>
              {
                <p
                  onClick={() =>
                    this.onViewClinicalReportClicked(eachPatientClinicalReport)
                  }
                  className={Styles.entryText}
                  style={{
                    //   // width: '10%',
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  View
                </p>
              }
            </div>
            <div className={Styles1.smtableData} style={{ zIndex: 999 }}>
              {
                <div className={Styles.entryText} style={{ zIndex: 999 }}>
                  {/* {this.renderSelectPatientName1(patient._id)} */}
                  {/* <Select
                    placeholder={'Patient'}
                    isSearchable={true}
                    options={this.state.patientList}
                    value={this.dropDownSelection(patient._id)}
                    // onChange={this.onPatientSelected.bind(this)}
                    onChange={(e) =>
                      this.updatePatientAssignedTo(e, patient._id)
                    }
                    theme={(theme) => selectTheme(theme)}
                  /> */}
                  <Select
                    options={this.state.patientList}
                    // className={Styles.addSelectInput}
                    autoBlur={true}
                    // isMulti
                    onChange={(val) => {
                      this.updatePatientAssignedTo(
                        val,
                        eachPatientClinicalReport._id,
                      )
                    }}
                    value={this.dropDownSelection(
                      eachPatientClinicalReport._id,
                    )}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              }
            </div>
          </div>
        ))
      : []

    // Below two variables are deprecated

    // let tableHeader = (
    //   <div className={Styles.row}>
    //     <p className={Styles.headerText} style={{ width: '10%' }}>
    //       Patient Name
    //     </p>
    //     <p className={Styles.headerText} style={{ width: '10%' }}>
    //       Date
    //     </p>
    //     <p className={Styles.headerText} style={{ width: '30%' }}>
    //       Test Name
    //     </p>
    //     <p className={Styles.headerText} style={{ width: '20%' }}>
    //       Comments
    //     </p>
    //     <p className={Styles.headerText} style={{ width: '10%' }}>
    //       Ordering Provider
    //     </p>
    //     <p className={Styles.headerText} style={{ width: '10%' }}>
    //       Status
    //     </p>
    //     <p className={Styles.headerText} style={{ width: '10%' }}>
    //       Assigned to
    //     </p>
    //     {/* <p>Assigned to</p> */}
    //   </div>
    // )

    // let tableRows = clinicalReportsFromChange
    //   ? clinicalReportsFromChange.map((x) => (
    //       <div className={Styles.row}>
    //         <p
    //           onClick={() => {
    //             this.setState({
    //               modalClinicalReport: x,
    //               showPatientAssignModal: true,
    //             })
    //           }}
    //           className={Styles.entryText}
    //           style={{
    //             width: '10%',
    //             color: 'blue',
    //             textDecoration: 'underline',
    //             cursor: 'pointer',
    //           }}
    //         >
    //           {`${x.patientName}`}
    //         </p>
    //         <p className={Styles.entryText} style={{ width: '10%' }}>
    //           {x.orderDate}
    //         </p>
    //         <p
    //           onClick={() => {
    //             this.setState({
    //               observationData: x.mappedResultData,
    //               showObservationModal: true,
    //             })
    //           }}
    //           className={Styles.entryText}
    //           style={{
    //             width: '30%',
    //             color: 'blue',
    //             textDecoration: 'underline',
    //             cursor: 'pointer',
    //             textAlign: 'start',
    //             padding: '0px 5px',
    //           }}
    //         >
    //           {x?.mappedResultData?.map((obj) => obj?.testName)?.join(', ')}
    //         </p>
    //         <p
    //           className={Styles.entryText}
    //           style={{ width: '20%', textAlign: 'start', padding: '0px 5px' }}
    //         >
    //           {x.mainComments}
    //         </p>
    //         <p className={Styles.entryText} style={{ width: '10%' }}>
    //           {x.orderingProvider}
    //         </p>
    //         <p
    //           onClick={() => this.onViewClinicalReportClicked(x)}
    //           className={Styles.entryText}
    //           style={{
    //             width: '10%',
    //             color: 'blue',
    //             textDecoration: 'underline',
    //             cursor: 'pointer',
    //           }}
    //         >
    //           View
    //         </p>
    //         <p className={Styles.entryText} style={{ width: '10%' }}>
    //           {this.renderSelectPatientName1(x.slno)}
    //         </p>
    //       </div>
    //     ))
    //   : []

    let tableHeader = (
      <div className={Styles.row}>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Patient Name
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Date
        </p>
        <p className={Styles.headerText} style={{ width: '30%' }}>
          Test Name
        </p>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Comments
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Ordering Provider
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Status
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Assigned to
        </p>
        {/* <p>Assigned to</p> */}
      </div>
    )

    let tableRows = clinicalReportsFromChange
      ? clinicalReportsFromChange.map((x) => (
          <div className={Styles.row}>
            <p
              onClick={() => {
                this.setState({
                  modalClinicalReport: x,
                  showPatientAssignModal: true,
                })
              }}
              className={Styles.entryText}
              style={{
                width: '10%',
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {`${x.patientName}`}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x.orderDate}
            </p>
            <p
              onClick={() => {
                this.setState({
                  observationData: x.mappedResultData,
                  showObservationModal: true,
                })
              }}
              className={Styles.entryText}
              style={{
                width: '30%',
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
                textAlign: 'start',
                padding: '0px 5px',
              }}
            >
              {x?.mappedResultData?.map((obj) => obj?.testName)?.join(', ')}
            </p>
            <p
              className={Styles.entryText}
              style={{ width: '20%', textAlign: 'start', padding: '0px 5px' }}
            >
              {x.mainComments}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x.orderingProvider}
            </p>
            <p
              onClick={() => this.onViewClinicalReportClicked(x)}
              className={Styles.entryText}
              style={{
                width: '10%',
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              View
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {this.renderSelectPatientName1(x.slno)}
            </p>
          </div>
        ))
      : []

    let modalLabOrdersElements = []
    if (patientLabOrders) {
      patientLabOrders.forEach((x) => {
        let tests = x.tests
        if (!x.result)
          modalLabOrdersElements.push(
            <div
              onClick={() => this.onModalLabOrderClicked(x)}
              style={{ cursor: 'pointer' }}
              className={Styles.row}
            >
              <p className={Styles.entryText} style={{ width: '60%' }}>
                {tests.map((x) => x.name)}
              </p>
              <p className={Styles.entryText} style={{ width: '40%' }}>
                {moment(x.created_at).format('YYYY-MM-DD HH:mm')}
              </p>
            </div>,
          )
      })
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 20,
        }}
      >
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showReportModal}
          onRequestClose={() => this.setState({ showReportModal: false })}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            src={`data:application/pdf;base64,${this.state.encodedReportString}`}
            title="report"
            style={{ height: '100%', width: '100%' }}
          />
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showObservationModal}
          onRequestClose={() => this.setState({ showObservationModal: false })}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          {this.renderObservation()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showPatientAssignModal}
          onRequestClose={() =>
            this.setState({ showPatientAssignModal: false })
          }
          style={customPatientAssignModalStyle}
          contentLabel="Modal"
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
              height: '100%',
            }}
          >
            <div style={{ width: '50%' }}>
              <Select
                placeholder={'Select patient...'}
                options={this.state.patientList}
                value={this.state.selectedPatientForAssigningClinicalReport}
                isMulti={false}
                isSearchable={true}
                onChange={(val) => this.onPatientSelectedForReport(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>

            {this.state.patientModalLoading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: 200,
                  justifyContent: 'center',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            ) : modalLabOrdersElements && modalLabOrdersElements.length > 0 ? (
              <div style={{ marginTop: 20 }}>
                <div className={Styles.row}>
                  <p className={Styles.headerText} style={{ width: '60%' }}>
                    Test(s)
                  </p>
                  <p className={Styles.headerText} style={{ width: '40%' }}>
                    Date
                  </p>
                </div>
                {modalLabOrdersElements}
              </div>
            ) : null}
          </div>
        </Modal>

        {/* <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {tableHeader}
          {tableRows}
        </div> */}
        <div
          style={{
            minWidth: '100%',
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center',
          }}
        >
          {newTableHeader}
          {newTableContentRow}
        </div>
      </div>
    )
  }

  navigatetoPatientProfile(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  renderBasicPatientInfo() {
    let { patientData, selectedPatient } = this.state
    if (!patientData) return null
    let heightInInches = patientData.height

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px 10px',
          backgroundColor: 'white',
          borderRadius: Constants.borderRadius,
          width: '100%',
        }}
      >
        <img
          src={selectedPatient.profilePictureURL || PLACEHOLDER_IMAGE}
          style={{ height: 100, width: 100, borderRadius: '50%' }}
        />
        <Link
          onClick={() => {
            this.navigatetoPatientProfile(patientData.firebase_id)
            this.onPatientNavLinkClicked('profile')
          }}
          to={{
            pathname: '/patient/profile',
            state: 'info',
            key: 2,
          }}
        >
          <p
            style={{
              color: Constants.primaryTheme,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {selectedPatient.firstName} {selectedPatient.lastName}
          </p>
        </Link>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Age:</p>
          <p className={Styles.infoValue}>
            {patientData.dob &&
              moment().diff(moment(patientData.dob, 'DD/MM/YYYY'), 'years')}
          </p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>DOB:</p>
          <p className={Styles.infoValue}>
            {patientData.dob &&
              moment(patientData.dob, 'DD/MM/YYYY').format('MM/DD/YYYY')}
          </p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Gender:</p>
          <p className={Styles.infoValue}>{patientData.gender}</p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Height:</p>
          {heightInInches && heightInInches > 0 && (
            <p className={Styles.infoValue}>
              {Math.floor(heightInInches / 12)} ft {heightInInches % 12} in
            </p>
          )}
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Weight:</p>
          <p className={Styles.infoValue}>{patientData.weight} lbs</p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Ethnicity:</p>
          <p className={Styles.infoValue}>{patientData.ethnicity}</p>
        </div>
        <div className={Styles.infoRow}>
          <p className={Styles.infoKey}>Language:</p>
          <p className={Styles.infoValue}>{patientData.language}</p>
        </div>
      </div>
    )
  }

  renderPatientMedicalData() {
    function removeComma(text) {
      return text.replace(',', '')
    }
    let { conditionsData, symptomsData, patientData } = this.state
    let conditionsElement =
      conditionsData &&
      conditionsData.map((condn, index) => (
        <span
          key={index.toString()}
          style={{ textAlign: 'left', padding: '2px 4px' }}
        >
          {removeComma(condn.symptom)}
          {index !== conditionsData.length - 1 && ','}
        </span>
      ))
    let symptomsElement =
      symptomsData &&
      symptomsData.map((symp, index) => (
        <span
          key={index.toString()}
          style={{ textAlign: 'left', padding: '2px 4px' }}
        >
          {removeComma(symp.symptom)}
          {index !== symptomsData.length - 1 && ','}
        </span>
      ))
    let allergiesElement =
      patientData?.allergies &&
      patientData?.allergies.length &&
      patientData?.allergies.map((allergy, index) => (
        <span
          key={index.toString()}
          style={{ textAlign: 'left', padding: '2px 4px' }}
        >
          {allergy}
          {index !== patientData.allergies.length - 1 && ','}
        </span>
      ))
    let foodSensitivity = patientData?.food_sensitivities?.foods
    let sensitivityElement =
      foodSensitivity &&
      foodSensitivity.length &&
      foodSensitivity.map((food, index) => (
        <span
          key={index.toString()}
          style={{ textAlign: 'left', padding: '2px 4px' }}
        >
          {food}
          {index !== patientData.allergies.length - 1 && ','}
        </span>
      ))
    let unDiagnosed =
      patientData?.pre_existing?.undiagnosed_conditions === true ||
      patientData?.pre_existing?.undiagnosed_conditions === false
        ? patientData?.pre_existing?.undiagnosed_conditions
        : ''
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px 10px',
          backgroundColor: 'white',
          borderRadius: Constants.borderRadius,
          width: '100%',
          height: '100%',
          marginTop: 20,
        }}
      >
        <div style={{ marginBottom: 6, width: '100%' }}>
          <p style={{ color: Constants.primaryTheme, fontWeight: 'bold' }}>
            Symptoms
          </p>
          <p style={{ fontWeight: 'normal' }}>{symptomsElement}</p>
        </div>

        <div
          style={{
            marginBottom: 6,
            width: '100%',
            marginTop: 10,
            borderTopWidth: 1,
            borderTopColor: Constants.primaryTheme,
            borderTopStyle: 'solid',
          }}
        >
          <p
            style={{
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginTop: 10,
            }}
          >
            Conditions
          </p>
          <p style={{ fontWeight: 'normal' }}>{conditionsElement}</p>
        </div>

        <div
          style={{
            marginBottom: 6,
            width: '100%',
            marginTop: 10,
            borderTopWidth: 1,
            borderTopColor: Constants.primaryTheme,
            borderTopStyle: 'solid',
          }}
        >
          <p
            style={{
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginTop: 10,
            }}
          >
            Undiagnosed
          </p>
          <p style={{ fontWeight: 'normal' }}>{String(unDiagnosed)}</p>
        </div>

        <div
          style={{
            marginBottom: 6,
            width: '100%',
            marginTop: 10,
            borderTopWidth: 1,
            borderTopColor: Constants.primaryTheme,
            borderTopStyle: 'solid',
          }}
        >
          <p
            style={{
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginTop: 10,
            }}
          >
            Allergies
          </p>
          <p style={{ fontWeight: 'normal' }}>{allergiesElement}</p>
        </div>

        <div
          style={{
            marginBottom: 6,
            width: '100%',
            marginTop: 10,
            borderTopWidth: 1,
            borderTopColor: Constants.primaryTheme,
            borderTopStyle: 'solid',
          }}
        >
          <p
            style={{
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginTop: 10,
            }}
          >
            Sensitivities
          </p>
          <p style={{ fontWeight: 'normal' }}>{sensitivityElement}</p>
        </div>
      </div>
    )
  }

  onTabSelected(index) {
    let tab
    switch (index) {
      case 1:
        tab = 'reports'
        break
      case 2:
        tab = 'erx'
        break
      case 3:
        tab = 'labs'
        break
      case 4:
        tab = 'referrals'
        break
      case 5:
        tab = 'rpmorders'
        break
      case 6:
        tab = 'e_consult'
        break
      case 7:
        tab = 'results'
        break
      case 8:
        tab = 'infusion_appointments'
        break
      case 9:
        tab = 'patient_assistance'
        break
      default:
        tab = ''
    }

    this.setState({ selectedTab: tab })
  }

  // Main switch case
  renderTabContent() {
    console.log('->', this.state.selectedTab)
    switch (this.state.selectedTab) {
      case 'reports':
        // console.log('EEEhhh')
        return this.renderOrdersMasterTable()
      case 'results': // Lab Results
        return this.renderClinicalReportsContent()
      case 'erx':
        return this.renderErxTab()
      case 'labs':
        return this.renderLabsTab()
      case 'referrals':
        return this.renderReferralsTab()
      case 'rpmorders':
        return this.renderRpmOrdersTab()
      case 'e_consult':
        return this.renderEConsultTab()
      case 'infusion_appointments':
        // console.log('Test')
        return this.renderInfusionTable()
      case 'patient_assistance':
        return this.renderPatientAssistance()
      default:
        return null
    }
  }

  renderSelectPatientName() {
    if (this.state.showIcd) return null
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 10,
          padding: '20px 20px',
        }}
      >
        <p
          style={{
            alignSelf: 'center',
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: 140,
          }}
        >
          Patient name
        </p>
        <div style={{ width: '80%' }}>
          <Select
            placeholder={'Select patient'}
            isSearchable={true}
            options={this.state.patientList}
            value={this.state.selectedPatient}
            onChange={this.onPatientSelected.bind(this)}
            theme={(theme) => selectTheme(theme)}
          />
        </div>
      </div>
    )
  }

  // renderOrdersList() {
  //   let orderList = [
  //     {
  //       orderNo: '100536',
  //       orderDate: '07/08/2021',
  //       labType: 'Home',
  //       location: 'New york city central',
  //       collectionDate: '06/20/2021 9:00 AM',
  //       patientName: 'Rory Stanton',
  //       status: 'Not Completed',
  //     },
  //     {
  //       orderNo: '340230',
  //       orderDate: '07/06/2021',
  //       labType: 'Specialty',
  //       location: 'Miami Florida',
  //       collectionDate: '07/20/2021 9:00 AM',
  //       patientName: 'Naren N',
  //       status: 'View Results',
  //     },
  //   ]
  //   let displayEvents = orderList.map((order, i) => (
  //     <div
  //       key={i.toString()}
  //       className={Styles.inviteRow}
  //       style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
  //     >
  //       <p
  //         className={Styles.inviteListEntry}
  //         style={{
  //           width: '10%',
  //           color: '#3769C9',
  //           textDecoration: 'underline',
  //           cursor: 'pointer',
  //         }}
  //         onClick={() => this.setState({ modalIsOpen: true })}
  //       >
  //         {order.orderNo}
  //       </p>
  //       <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
  //         {order.orderDate}
  //       </p>
  //       <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
  //         {order.labType}
  //       </p>
  //       <p className={Styles.inviteListEntry} style={{ width: '20%' }}>
  //         {order.location}
  //       </p>
  //       <p className={Styles.inviteListEntry} style={{ width: '20%' }}>
  //         {order.collectionDate}
  //       </p>
  //       <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
  //         {order.patientName}
  //       </p>
  //       <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
  //         {order.status}
  //       </p>
  //     </div>
  //   ))
  //   return (
  //     <div className={Styles.listWrapper} style={{ marginTop: 20 }}>
  //       <div className={Styles.inviteRow}>
  //         <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
  //           Order Number
  //         </p>
  //         <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
  //           Order Date
  //         </p>
  //         <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
  //           Lab Type
  //         </p>
  //         <p className={Styles.inviteListHeader} style={{ width: '20%' }}>
  //           Location
  //         </p>
  //         <p className={Styles.inviteListHeader} style={{ width: '20%' }}>
  //           Collection Date/Time
  //         </p>
  //         <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
  //           Patient Name
  //         </p>
  //         <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
  //           Status
  //         </p>
  //       </div>

  //       {displayEvents}
  //     </div>
  //   )
  // }

  renderOrdersModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.modalIsOpen}
        onRequestClose={() =>
          this.setState({
            modalIsOpen: false,
          })
        }
        style={customModalStyles}
        contentLabel="Modal"
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() =>
              this.setState({
                modalIsOpen: false,
              })
            }
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            {'Orders'}
          </h4>
          <img
            src={ORDER_IMG}
            style={{ width: '750', height: '350', marginTop: 80 }}
          />
          <Button
            onClick={() =>
              this.setState({
                modalIsOpen: false,
              })
            }
            className={GlobalStyles.button}
            style={{ width: 200, position: 'absolute', bottom: 20 }}
            variant="primary"
          >
            Order
          </Button>
        </div>
      </Modal>
    )
  }

  // Looks like this is not being used
  // renderOrdersTab() {
  //   return (
  //     <div
  //       style={{
  //         width: '100%',
  //         margin: 10,
  //         display: 'flex',
  //         flexDirection: 'row',
  //         justifyContent: 'center',
  //       }}
  //     >
  //       {this.renderOrdersList()}
  //       {this.renderOrdersModal()}
  //     </div>
  //   )
  // }

  renderPatientInfo() {
    let { selectedPatient } = this.state
    if (!selectedPatient) return null
    if (this.state.showIcd) return null
    return (
      <div style={{ width: '80%', marginBottom: 10, marginTop: 20 }}>
        <div className={Styles.patientContentBackground}>
          {/* <div className={Styles.patientDataRow}>
            <p className={Styles.patientDataRowKey}>Patient ID</p>
            <span>:</span>
            <p className={Styles.patientDataRowValue}>{selectedPatient?.uid}</p>
          </div> */}

          <div className={Styles.patientDataRow}>
            <p className={Styles.patientDataRowKey}>First Name</p>
            <span>:</span>
            <p className={Styles.patientDataRowValue}>
              {selectedPatient?.firstName}
            </p>
          </div>

          <div className={Styles.patientDataRow}>
            <p className={Styles.patientDataRowKey}>Last Name</p>
            <span>:</span>
            <p className={Styles.patientDataRowValue}>
              {selectedPatient?.lastName}
            </p>
          </div>

          <div className={Styles.patientDataRow}>
            <p className={Styles.patientDataRowKey}>Address</p>
            <span>:</span>
            <p className={Styles.patientDataRowValue}>
              {selectedPatient?.streetAddress}
            </p>
          </div>

          <div className={Styles.patientDataRow}>
            <p className={Styles.patientDataRowKey}>Zipcode</p>
            <span>:</span>
            <p className={Styles.patientDataRowValue}>
              {selectedPatient?.zipcode}
            </p>
          </div>

          <div className={Styles.patientDataRow}>
            <p className={Styles.patientDataRowKey}>Phone number</p>
            <span>:</span>
            <p className={Styles.patientDataRowValue}>
              {selectedPatient?.phoneNumber}
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderLabOptions() {
    if (this.state.showIcd) return null
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '50px 0px',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 50,
            width: '100%',
          }}
        >
          {/* <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            Lab Type
          </p>
          <div style={{ width: 200, margin: '0px 20px' }}>
            <Select
              style={{ width: 100 }}
              options={[
                { label: 'At Home', value: 'Home' },
                { label: 'At Lab', value: 'Lab' },
                { label: 'Specialty', value: 'Specialty' },
              ]}
              value={this.state.labType}
              autoBlur={true}
              onChange={(val) => this.setState({ labType: val })}
              theme={(theme) => selectTheme(theme)}
            />
          </div> */}
          {/*<p style={{ alignSelf: 'center', color: Constants.primaryTheme, fontWeight: 'bold' }}>Order Type</p>*/}
          {/*<div style={{ width: 200, margin: '0px 20px' }}>*/}
          {/*  <Select*/}
          {/*    style={{width: 100}}*/}
          {/*    options={[*/}
          {/*      {label: '1st Order type', value: '1st Order type'},*/}
          {/*      {label: '2nd Order type', value: '2nd Order type'},*/}
          {/*    ]}*/}
          {/*    value={this.state.orderType}*/}
          {/*    autoBlur={true}*/}
          {/*    onChange={(val) => this.setState({orderType:  val})}*/}
          {/*    theme={(theme) => selectTheme(theme)}*/}
          {/*  />*/}
          {/*</div>*/}
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            Bill Type
          </p>
          <div style={{ width: 200, margin: '0px 20px' }}>
            <Select
              style={{ width: 100 }}
              options={[
                // { label: 'Aila Pays', value: 'Aila' },
                { label: 'Patient Pays', value: 'Patient' },
                // { label: 'Insurance Pays', value: 'Insurance' },
              ]}
              value={this.state.billType}
              autoBlur={true}
              onChange={(val) => this.setState({ billType: val })}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 50,
            width: '100%',
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            Lab Location
          </p>
          <div style={{ width: 200, margin: '0px 20px' }}>
            <Select
              style={{ width: 100 }}
              options={this.state.availableLabLocations}
              value={this.state.labLocation}
              autoBlur={true}
              onChange={(val) => this.setState({ labLocation: val })}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 50,
            width: '100%',
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            Priority
          </p>
          <div style={{ width: 200, margin: '0px 20px' }}>
            <Select
              style={{ width: 100 }}
              options={[
                { label: 'Routine', value: 'Routine' },
                { label: 'STAT', value: 'STAT' },
              ]}
              value={this.state.labPriority}
              autoBlur={true}
              onChange={(val) => this.setState({ labPriority: val })}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 50,
            width: '100%',
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            Collection Date/Time
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: 250,
              margin: '0px 20px',
            }}
          >
            <DateTimePicker
              onChange={(val) => {
                console.log('collection date', val)
                this.setState({ collectionDate: val })
              }}
              value={this.state.collectionDate}
            />
          </div>
        </div>

        <Button
          onClick={this.onNextPressed.bind(this)}
          className={GlobalStyles.button}
          style={{ width: 100, marginTop: 60, alignSelf: 'flex-end' }}
          variant="primary"
        >
          Next
        </Button>
      </div>
    )
  }

  renderIcdSearchModal() {
    const {
      icdSearchModalOpen,
      modalLoading,
      icdCodeSearchString,
      icdDescSearchString,
      icdSearchResults,
      clickedOrderable,
      selectedLabTests,
    } = this.state

    const closeIcdModal = () => {
      this.setState({
        icdSearchModalOpen: false,
        icdCodeSearchString: '',
        icdDescSearchString: '',
        icdSearchResults: null,
      })
    }

    const renderIcdList = () => {
      if (!icdSearchResults || icdSearchResults.length === 0) {
        return (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <p>No results</p>
          </div>
        )
      }

      let elements = icdSearchResults.map((x) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #d0d0d0',
            padding: '8px 2px',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                let tempSelectedTests = [...selectedLabTests]
                let indexValue = tempSelectedTests.findIndex(
                  (test) => test.orderable === clickedOrderable,
                )
                tempSelectedTests[indexValue]['diagnosis'] =
                  tempSelectedTests[indexValue]?.['diagnosis'] || []
                tempSelectedTests[indexValue]['diagnosis'].push({
                  icd_code: x.icd_10_cm_code,
                  icd_desc: x.description,
                })
                this.setState({
                  selectedLabTests: tempSelectedTests,
                  clickedOrderable: null,
                  icdSearchResults: [],
                  icdCodeSearchString: '',
                  icdDescSearchString: '',
                })
                closeIcdModal()
              }}
              className={GlobalStyles.button}
              style={{ width: 100 }}
              variant="primary"
            >
              Select
            </Button>
          </div>
          <p style={{ width: '20%', textAlign: 'center' }}>
            {x.icd_10_cm_code}
          </p>
          <p style={{ width: '50%', textAlign: 'center' }}>{x.description}</p>
        </div>
      ))

      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            overflowY: 'scroll',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderBottom: '1px solid #d0d0d0',
              padding: 10,
            }}
          >
            <p style={{ width: '30%' }}></p>
            <p
              style={{
                width: '20%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'gray',
              }}
            >
              ICD Code
            </p>
            <p
              style={{
                width: '50%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'gray',
              }}
            >
              Description
            </p>
          </div>

          {elements}
        </div>
      )
    }

    const renderPatientNotesIcd = () => {
      let { noteIcds } = this.state
      let conditionsElement =
        noteIcds &&
        noteIcds.map(
          (condn, index) => (
            // condn?.code?.coding?.[0]?.display && !condn?.userEntered ?
            <div
              className={Styles.contentRow}
              key={index}
              style={{ justifyContent: 'space-between' }}
            >
              <p
                key={index.toString()}
                style={{
                  textAlign: 'left',
                  padding: '2px 10px',
                  fontSize: '16px',
                }}
              >
                {condn?.icd_10?.icd10_code} : {condn?.icd_10?.title}
              </p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={() => {
                    console.log('add to icd', condn)
                    let tempSelectedTests = [...selectedLabTests]
                    let indexValue = tempSelectedTests.findIndex(
                      (test) => test.orderable === clickedOrderable,
                    )
                    tempSelectedTests[indexValue]['diagnosis'] =
                      tempSelectedTests[indexValue]?.['diagnosis'] || []
                    tempSelectedTests[indexValue]['diagnosis'].push({
                      icd_code: condn?.icd_10?.icd10_code,
                      icd_desc: condn?.icd_10?.title,
                    })
                    this.setState({
                      selectedLabTests: tempSelectedTests,
                      clickedOrderable: null,
                      // icdSearchResults: [],
                      // icdCodeSearchString: '',
                      // icdDescSearchString: '',
                    })
                    closeIcdModal()
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ color: Constants.primaryTheme, fontSize: 20 }}
                  />
                </div>
              </div>
            </div>
          ),
          // : null,
        )

      return (
        <div style={{ width: '100%' }}>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesRegularText}>
              List of Selected ICD-10 for the patient
            </p>
          </div>
          <div className={Styles.modalNotesRow}>
            <div
              className={Styles.contentBackground}
              style={{ height: 150, overflowY: 'scroll', width: '100%' }}
            >
              {conditionsElement}
            </div>
          </div>
        </div>
      )
    }

    return (
      <Modal
        ariaHideApp={false}
        isOpen={icdSearchModalOpen}
        onRequestClose={() => closeIcdModal()}
        style={customDrugModalStyles}
        contentLabel="Modal"
      >
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              minHeight: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <p className={Styles.closeModalBtn} onClick={() => closeIcdModal()}>
              X
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 10px',
                width: '80%',
                justifyContent: 'space-between',
              }}
            >
              <input
                style={{ width: '40%' }}
                placeholder={'search icd code'}
                className={Styles.labTextInput}
                type="textInput"
                value={icdCodeSearchString}
                onChange={(e) =>
                  this.setState({ icdCodeSearchString: e.target.value })
                }
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.searchIcdCodes()
                  }
                }}
              />
              <input
                style={{ width: '40%' }}
                placeholder={'search icd desc'}
                className={Styles.labTextInput}
                type="textInput"
                value={icdDescSearchString}
                onChange={(e) =>
                  this.setState({ icdDescSearchString: e.target.value })
                }
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.searchIcdCodes()
                  }
                }}
              />
            </div>

            {renderPatientNotesIcd()}

            {renderIcdList()}
          </div>
        )}
      </Modal>
    )
  }

  renderLabSendModal() {}

  renderLabTests() {
    let { labSearchResults, labSearchString } = this.state
    if (!this.state.showIcd) return null

    // const Hit = (props, type) => {
    //   let { hit } = props
    //   // let checked = handleChecked(q, hit.symptom)
    //   const handleClick = () => {
    //     if (type === 'cpt') {
    //       this.setState((prevState) => ({
    //         addCpt: [
    //           ...prevState.addCpt,
    //           { code: hit.code, display: hit.display },
    //         ],
    //       }))
    //     } else {
    //       this.setState((prevState) => ({
    //         addIcd10: [
    //           ...prevState.addIcd10,
    //           { code: hit.code, display: hit.display },
    //         ],
    //       }))
    //     }
    //   }
    //   return (
    //     <>
    //       <button
    //         onClick={(e) => handleClick(e)}
    //         style={{ border: 'none', outline: 'none', display: 'block' }}
    //       >
    //         {hit.code}: {hit.display}
    //       </button>
    //       <hr />
    //     </>
    //   )
    // }

    return (
      <div>
        <div>
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            {'Test(s)'}
          </p>
          <div style={{ marginTop: 10, marginBottom: 20, width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 10px',
                width: '80%',
              }}
            >
              <input
                placeholder={'search labs'}
                className={Styles.labTextInput}
                type="textInput"
                value={this.state.labSearchString}
                onChange={(e) => this.onLabSearchStringChange(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.searchLabTestCodes()
                  }
                }}
              />
            </div>

            {labSearchString &&
            labSearchString.length > 0 &&
            labSearchResults &&
            labSearchResults.length > 0 ? (
              labSearchResults.map((lab) => {
                return (
                  <div
                    onClick={() => this.onLabTestAdded(lab)}
                    style={{
                      margin: 4,
                      borderBottom: '1px solid #d0d0d0',
                      padding: '4px 10px',
                      cursor: 'pointer',
                    }}
                  >
                    <p>
                      {lab?.order_code}: {lab.description}
                    </p>
                  </div>
                )
              })
            ) : (
              <div
                style={{
                  height: 100,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ color: 'gray' }}>enter text to search for labs</p>
              </div>
            )}

            {this.state.selectedLabTests.length !== 0 && (
              <div className={Styles.modalNotesCol}>
                <p
                  className={Styles.modalNotesKey}
                  style={{ marginTop: 30, color: Constants.primaryTheme }}
                >
                  Selected Lab Tests
                </p>
                <div
                  className={Styles.modalValueValue}
                  style={{ height: 300, overflow: 'auto', width: '50%' }}
                >
                  {this.state.selectedLabTests.map((lab, index) => (
                    <div
                      style={{
                        justifyContent: 'space-between',
                        borderTop: '1px solid #D3D3D3',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '4px 10px',
                      }}
                    >
                      <div>
                        <p>
                          {index + 1}. {lab.orderable}: {lab.description}
                        </p>
                        {lab?.diagnosis?.length && (
                          <ul>
                            {lab.diagnosis.map((diag) => (
                              <li>
                                {diag.icd_code}: {diag.icd_desc}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => {
                          let arr = this.state.selectedLabTests.filter(
                            (data) => data.orderable !== lab.orderable,
                          )
                          this.setState({ selectedLabTests: arr })
                        }}
                      />
                      <Button
                        onClick={() =>
                          this.setState({
                            icdSearchModalOpen: true,
                            clickedOrderable: lab.orderable,
                          })
                        }
                        className={GlobalStyles.button}
                        style={{ width: 150, marginLeft: 40, height: 50 }}
                        variant="primary"
                      >
                        Add icd-10
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            alignSelf: 'center',
            margin: '50px 0px',
            borderTopWidth: 1,
            borderTopColor: Constants.primaryTheme,
            borderTopStyle: 'solid',
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              padding: '30px 0px',
            }}
          >
            Lab Instructions
          </p>
          <input
            placeholder={'Enter instructions....'}
            className={Styles.textInput}
            type="text"
            value={this.state.labInstructions}
            onChange={(e) => {
              let text = e.target.value
              this.setState({ labInstructions: text })
            }}
          />
        </div>

        <div
          style={{
            alignSelf: 'center',
            margin: '50px 0px',
            borderTopWidth: 1,
            borderTopColor: Constants.primaryTheme,
            borderTopStyle: 'solid',
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              padding: '30px 0px',
            }}
          >
            Order Comments
          </p>
          <input
            placeholder={'Enter comments....'}
            className={Styles.textInput}
            type="text"
            value={this.state.labComments}
            onChange={(e) => {
              let text = e.target.value
              this.setState({ labComments: text })
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() =>
              this.setState({
                showIcd: false,
              })
            }
            className={GlobalStyles.button}
            style={{ width: 100, position: 'relative', margin: 20 }}
            variant="primary"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              this.setState({ showLabSendModal: true })
              this.onLabSave()
              // this.setState({
              //   selectedTab: 'orders',
              // })
            }}
            className={GlobalStyles.button}
            style={{ width: 100, position: 'relative', margin: 20 }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  renderReferralsTab() {
    let { labOrderTabLoading } = this.state
    if (labOrderTabLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            minHeight: '100vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          width: '100%',
          margin: 20,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <h6
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          Referrals
        </h6>

        {this.renderSelectPatientName()}
        {this.renderPatientInfo()}

        {/* <div className={Styles.horizontalLine} /> */}
        <Referrals
          patientId={this.state.selectedPatient?.value}
          token={this.props.token}
          providerId={this.props.adminId}
          mongoUser={this.props.mongoUser}
          patient={{
            ...this.state.selectedPatient,
            ...this.state.patientData,
            ...this.state.patientInsurance,
          }}
          referralType={this.state.referralType}
          patientIcdCodes={this.state.noteIcds}
          appointmentEventId={this.state.appointmentEventId}
          appointmentData={this.props.appointmentData}
          showVideoModal={this.props.showVideoModal}
          availableLabLocations={this.state.availableLabLocations}
          referral_id={this?.props?.location?.referral_id || false}
          task_description={this.props?.location?.task_description || ''}
        />
      </div>
    )
  }

  renderRpmOrdersTab() {
    let { labOrderTabLoading } = this.state
    if (labOrderTabLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            minHeight: '100vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          width: '100%',
          margin: 20,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <h6
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          RPM Orders
        </h6>
        {this.renderSelectPatientName()}
        {this.renderPatientInfo()}

        {/* <div className={Styles.horizontalLine} /> */}
        <RpmOrders
          patientId={this.state.selectedPatient?.value}
          token={this.props.token}
          providerId={this.props.adminId}
          mongoUser={this.props.mongoUser}
          patient={{
            ...this.state.selectedPatient,
            ...this.state.patientData,
            ...this.state.patientInsurance,
          }}
          appointmentData={this.props.appointmentData}
          showVideoModal={this.props.showVideoModal}
          availableLabLocations={this.state.availableLabLocations}
        />
      </div>
    )
  }

  renderEConsultTab() {
    let { labOrderTabLoading } = this.state
    if (labOrderTabLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            minHeight: '100vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          width: '100%',
          margin: 20,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <h6
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          E-Consult
        </h6>
        {this.renderSelectPatientName()}
        {this.renderPatientInfo()}

        <Econsults
          patientId={this.state.selectedPatient?.value}
          token={this.props.token}
          providerId={this.props.adminId}
          mongoUser={this.props.mongoUser}
          patient={{
            ...this.state.selectedPatient,
            ...this.state.patientData,
            ...this.state.patientInsurance,
          }}
          appointmentData={this.props.appointmentData}
          showVideoModal={this.props.showVideoModal}
          availableLabLocations={this.state.availableLabLocations}
        />
      </div>
    )
  }
  renderLabsTab() {
    let { labOrderTabLoading } = this.state
    if (labOrderTabLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            minHeight: '100vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          width: '100%',
          margin: 20,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <h6
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          Order Labs
        </h6>
        {this.renderSelectPatientName()}
        {this.renderPatientInfo()}
        {this.renderLabOptions()}
        {this.renderLabTests()}
        {this.renderIcdSearchModal()}
        {this.renderLabSendModal()}
      </div>
    )
  }

  renderErxTab() {
    return (
      <div
        style={{
          width: '100%',
          margin: 20,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <h6
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            marginBottom: 10,
          }}
        >
          Order Medications
        </h6>
        {this.renderSelectPatientName()}
        {this.renderPatientInfo()}

        <div className={Styles.horizontalLine} />
        <ERXTab
          patientId={this.state.selectedPatient?.value}
          token={this.props.token}
          providerId={this.props.adminId}
          mongoUser={this.props.mongoUser}
          patient={{
            ...this.state.selectedPatient,
            ...this.state.patientData,
          }}
          appointmentData={this.props.appointmentData}
          showVideoModal={this.props.showVideoModal}
        />
      </div>
    )
  }

  renderMainContent() {
    const findTabIndex = () => {
      switch (this.state.selectedTab) {
        case 'reports':
          return 1
        case 'erx':
          return 2
        case 'labs':
          return 3
        case 'referrals':
          return 4
        case 'rpmorders':
          return 5
        case 'e_consult':
          return 6
        case 'results':
          return 7
        case 'infusion_appointments':
          return 8
        case 'patient_assistance':
          return 9
        default:
          return 1
      }
    }
    return (
      <div className={Styles.mainContentWrapper} style={{ width: '100%' }}>
        <Tabs
          onTabSelected={this.onTabSelected.bind(this)}
          tabIndex={findTabIndex()}
        />
        {this.renderTabContent()}
      </div>
    )
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{ overflowX: 'hidden', paddingBottom: '100px' }}
      >
        <Header header={'Orders'} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: 10,
          }}
        >
          {this.state.selectedTab !== 'reports' &&
            this.state.selectedPatient && (
              <div
                className={Styles.patientInfoWrapper}
                style={{ display: 'flex', overflow: 'hidden' }}
              >
                {this.renderBasicPatientInfo()}
                {this.renderPatientMedicalData()}
              </div>
            )}
          {this.renderMainContent()}
        </div>
      </div>
    )
  }
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.tabIndex || 1)

  function getTooltip(label) {
    return (
      <ReactTooltip
        id={label}
        textColor="black"
        backgroundColor={Constants.primaryThemeMedium}
        effect="solid"
      >
        <p style={{ color: 'white', fontSize: 18 }}>{label}</p>
      </ReactTooltip>
    )
  }

  function getTab(name, index, icon, fontAwesome) {
    return (
      <div
        data-tip
        data-for={name}
        onClick={() => {
          console.log('Index:', index)
          setActiveTab(index)
          props.onTabSelected(index)
        }}
        className={Styles.rowIcon}
        style={{
          backgroundColor:
            activeTab === index
              ? Constants.primaryTheme
              : Constants.primaryThemeDark,
        }}
      >
        {fontAwesome ? (
          <FontAwesomeIcon
            icon={icon}
            style={{ color: 'white', fontSize: 30 }}
          />
        ) : (
          <img src={icon} style={{ height: '75%', width: '75%' }} />
        )}

        {getTooltip(name)}
      </div>
    )
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        padding: '20px 20px',
      }}
    >
      {getTab('Clinical Reports', 1, faClipboardList, true)}
      {getTab('eRX', 2, faCapsules, true)}
      {getTab('Labs', 3, faFlask, true)}
      {getTab('Referrals', 4, faUserFriends, true)}
      {getTab('RPM Orders', 5, faMedkit, true)}
      {getTab('E-Consult', 6, faLaptopMedical, true)}
      {getTab('Lab Results', 7, faReceipt, true)}
      {getTab('Infusion Orders', 8, faPrescriptionBottleAlt, true)}
      {getTab('Patient Assistance', 9, faReceipt, true)}
    </div>
  )
}

const ERXTab = (props) => {
  const [loading, setLoading] = useState(false)
  // TODO: Edit this before prod push
  // BUG:
  const [prescriber, setPrescriber] = useState({
    label: 'Tarun Ashok',
    value: '3TpdKMa9tVS3cTM1q4eddrN0G933',
    data: {
      firebase_id: '3TpdKMa9tVS3cTM1q4eddrN0G933',
      email: 'fohafih790@searpen.com',
      first_name: 'Tarun',
      last_name: 'Ashok',
      gender: 'Male',
      user_type: 'Administrator',
      languages: ['English'],
      cohorts: [],
      created_at: '2023-09-11T10:43:19.321Z',
      virgil_registered: true,
      inactive: true,
      supervisor: true,
      accessData: '',
      attest: true,
      caqh: 'TESTCAQH',
      cds: '',
      dea: '',
      fcvs: '',
      groupNpi: '',
      licence: {
        front: '',
        front_type: '',
        back: '',
        back_type: '',
      },
      medicaidId: '',
      medicareId: '',
      npi: '',
      ptan: '',
      tax: null,
      ssn: '1234567890',
      o_auth_creds: {
        access_token:
          'ya29.a0AfB_byCVQKi9L6xZ801zR7kioXUeT_4JuCaoj6MjWKzdRIBQGENZhQmirbV6xeldhN1qM8fpPFi-2CCXjF-3CdXA6BtzN8JhDeqbhr0ZNi12x_wmldMekWJb_qAChps9WW7wLXspZoMeNjM3nZ3m-mN9i-f5kTLoCPbdaCgYKAdESARMSFQHGX2MijasKGarGjFseJ9UXElKv6g0171',
        scope: 'https://www.googleapis.com/auth/calendar',
        token_type: 'Bearer',
        expiry_date: 1708435085874,
        refresh_token:
          '1//06gTP_Al7LEaLCgYIARAAGAYSNwF-L9IryAqxMV5I4lh4TmhFUybbvN8hBUZCc7dtmLtvhPZ1xBgs_iKWNxi-f7p7YDmEvD0bPVE',
      },
      o_auth_access_token:
        'ya29.a0AfB_byCVQKi9L6xZ801zR7kioXUeT_4JuCaoj6MjWKzdRIBQGENZhQmirbV6xeldhN1qM8fpPFi-2CCXjF-3CdXA6BtzN8JhDeqbhr0ZNi12x_wmldMekWJb_qAChps9WW7wLXspZoMeNjM3nZ3m-mN9i-f5kTLoCPbdaCgYKAdESARMSFQHGX2MijasKGarGjFseJ9UXElKv6g0171',
      o_auth_refresh_token:
        '1//06gTP_Al7LEaLCgYIARAAGAYSNwF-L9IryAqxMV5I4lh4TmhFUybbvN8hBUZCc7dtmLtvhPZ1xBgs_iKWNxi-f7p7YDmEvD0bPVE',
      fcm_token:
        'fITXKWDcj_P42WNGM47E0x:APA91bEGRY1tcFbbodbg50xAwu5mXT-JBJVBDmYe2TlNq3RXWH08CmyXYkmB9ZzOZrvTxGd78ltP4slqHOLeXRM9qlsobj0cP43L84MF5NnYkwWW17JkzitrlooMQ3xbDtM-Vf1ouhLy',
      credential: 'MD',
      insurances: [],
      speciality: 'Dietitian',
      taxonomy: '1234567890',
      authorization_level: 'doctor',
      change: {
        caregiver_id: '',
        clinician_id: 'ailahealth_support',
        clinician_pwd: 'Tarun@1999',
      },
    },
  })
  const [erxUrl, setErxUrl] = useState('')
  const [instructions, setInstructions] = useState('')
  const [comments, setComments] = useState('')
  const [icdCodes, setIcdCodes] = useState([])
  const [searchIcdDesc, setSeachIcdDesc] = useState('')
  const [searchIcdCode, setSearchIcdCode] = useState('')
  const [icdSeachResult, setIcdSeachResult] = useState('')
  const [quantity, setQuantity] = useState('')
  const [supply, setSupply] = useState('')
  const [refills, setRefills] = useState('')
  const [units, setUnits] = useState('')
  const [date, setDate] = useState(null)
  // const [pharmacy, setPharmacy] = useState(null) // This is not being used
  const [daw, setDaw] = useState(false)
  const [fav, setFav] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalUrl, setModalUrl] = useState('')
  const [drugSearchString, setDrugSearchString] = useState('')
  const [drugModalOpen, setDrugModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [drugsList, setDrugsList] = useState([])
  const [selectedDrug, setSelectedDrug] = useState(null)
  const [providerOptionsData, setProviderOptionsData] = useState([])
  const [rxForm, setRxForm] = useState(false)
  const [preAuthReq, setPreAuthReq] = useState(false)
  const [measurementUnit, setMeasurementUnit] = useState(null)

  useEffect(() => {
    getAllProviderData()
  }, [])

  const getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: props.mongoUser?.firebase_id,
        Authorization: 'JWT ' + props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        console.log('Pdata: ', data)
        let providerOptionList = []
        console.log('data', data)
        // let allProviderData = data.filter(doc => {
        //   if(doc?.change?.caregiver_id && props.mongoUser?.firebase_id)
        //     return true
        // }).reduce((data, doc) => {
        //   data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
        //   data[doc.user_type || 'Other'].push({
        //     label: doc?.first_name + ' ' + doc?.last_name + (doc?.speciality ? ` (${doc?.speciality})` : ''),
        //     value: doc?.firebase_id,
        //     data: doc
        //   })
        //   return data
        // }, Object.create(null))
        // Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
        //   providerOptionList.push({
        //     label: userType,
        //     options: doctorArray,
        //   })
        // })
        providerOptionList = data
          ?.filter((doc) => {
            if (
              doc?.change?.caregiver_id &&
              // TODO: Write a better and more optimized route for this
              // Looks like it reads all the data and then just picks the record that matches the
              // current logged in provider
              props.mongoUser?.firebase_id === doc.firebase_id
            )
              return true
          })
          .map((obj) => {
            // TODO: Revert this
            // setPrescriber({
            //   label: obj.first_name + ' ' + obj.last_name,
            //   value: obj?.firebase_id,
            //   data: obj,
            // })

            setPrescriber({
              label: 'Tarun Ashok',
              value: '3TpdKMa9tVS3cTM1q4eddrN0G933',
              data: {
                firebase_id: '3TpdKMa9tVS3cTM1q4eddrN0G933',
                email: 'fohafih790@searpen.com',
                first_name: 'Tarun',
                last_name: 'Ashok',
                gender: 'Male',
                user_type: 'Administrator',
                languages: ['English'],
                cohorts: [],
                created_at: '2023-09-11T10:43:19.321Z',
                virgil_registered: true,
                inactive: true,
                supervisor: true,
                accessData: '',
                attest: true,
                caqh: 'TESTCAQH',
                cds: '',
                dea: '',
                fcvs: '',
                groupNpi: '',
                licence: {
                  front: '',
                  front_type: '',
                  back: '',
                  back_type: '',
                },
                medicaidId: '',
                medicareId: '',
                npi: '',
                ptan: '',
                tax: null,
                ssn: '1234567890',
                o_auth_creds: {
                  access_token:
                    'ya29.a0AfB_byCVQKi9L6xZ801zR7kioXUeT_4JuCaoj6MjWKzdRIBQGENZhQmirbV6xeldhN1qM8fpPFi-2CCXjF-3CdXA6BtzN8JhDeqbhr0ZNi12x_wmldMekWJb_qAChps9WW7wLXspZoMeNjM3nZ3m-mN9i-f5kTLoCPbdaCgYKAdESARMSFQHGX2MijasKGarGjFseJ9UXElKv6g0171',
                  scope: 'https://www.googleapis.com/auth/calendar',
                  token_type: 'Bearer',
                  expiry_date: 1708435085874,
                  refresh_token:
                    '1//06gTP_Al7LEaLCgYIARAAGAYSNwF-L9IryAqxMV5I4lh4TmhFUybbvN8hBUZCc7dtmLtvhPZ1xBgs_iKWNxi-f7p7YDmEvD0bPVE',
                },
                o_auth_access_token:
                  'ya29.a0AfB_byCVQKi9L6xZ801zR7kioXUeT_4JuCaoj6MjWKzdRIBQGENZhQmirbV6xeldhN1qM8fpPFi-2CCXjF-3CdXA6BtzN8JhDeqbhr0ZNi12x_wmldMekWJb_qAChps9WW7wLXspZoMeNjM3nZ3m-mN9i-f5kTLoCPbdaCgYKAdESARMSFQHGX2MijasKGarGjFseJ9UXElKv6g0171',
                o_auth_refresh_token:
                  '1//06gTP_Al7LEaLCgYIARAAGAYSNwF-L9IryAqxMV5I4lh4TmhFUybbvN8hBUZCc7dtmLtvhPZ1xBgs_iKWNxi-f7p7YDmEvD0bPVE',
                fcm_token:
                  'fITXKWDcj_P42WNGM47E0x:APA91bEGRY1tcFbbodbg50xAwu5mXT-JBJVBDmYe2TlNq3RXWH08CmyXYkmB9ZzOZrvTxGd78ltP4slqHOLeXRM9qlsobj0cP43L84MF5NnYkwWW17JkzitrlooMQ3xbDtM-Vf1ouhLy',
                credential: 'MD',
                insurances: [],
                speciality: 'Dietitian',
                taxonomy: '1234567890',
                authorization_level: 'doctor',
                change: {
                  caregiver_id: '',
                  clinician_id: 'ailahealth_support',
                  clinician_pwd: 'Tarun@1999',
                },
              },
            })
            return {
              label: obj.first_name + ' ' + obj.last_name,
              value: obj?.change?.firebase_id,
              data: obj,
            }
          })
        console.log('Setting provider options data', providerOptionList)
        setProviderOptionsData(providerOptionList)
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
    // .then(({ data }) => {
    //   let providerOptionList = data
    //     ?.filter((doc) => {
    //       if (doc?.change?.caregiver_id && doc.firebase_id === props.adminId) return true
    //     })
    //     .map((obj) => {
    //       return {
    //         label: obj.first_name + ' ' + obj.last_name,
    //         value: obj?.change?.caregiver_id,
    //       }
    //     })
    //   setProviderOptionsData(providerOptionList)
    // })
    // .catch((err) => {
    //   console.log('err while loading provider data: ', err)
    // })
  }

  const onDrugSearchPressed = () => {
    if (!drugSearchString || drugSearchString.length === 0) {
      toast.error('Enter drug name to search')
      return
    }

    setModalLoading(true)
    let url =
      Config.CHANGE_BACKEND_URL + 'change/drugs/search?q=' + drugSearchString
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + props.token },
    })
      .then(({ data }) => {
        if (data?.RESULT?.OBJECT) {
          let drugs = []
          let content = data.RESULT.OBJECT
          console.log('content')
          if (Array.isArray(content)) {
            console.log('its a array')
            content.forEach((x) => drugs.push(getDrugInfo(x)))
          } else {
            console.log('its a object')
            drugs.push(getDrugInfo(content))
          }
          setDrugsList(drugs)
        } else {
          toast.error('No results found')
        }
        setModalLoading(false)
      })
      .catch((err) => {
        console.log('error when searching for drug', err)
        toast.error('Something went wrong. Please try again later.')
      })
  }

  const onModalCloseRequested = () => {
    setModalIsOpen(false)
    //if the physician placed the order in clinician, we need to store that in our system for tracking
    if (
      selectedDrug &&
      prescriber &&
      instructions &&
      measurementUnit &&
      props.patientId &&
      props.providerId
    ) {
      if (window.confirm('Did you place the order for this patient?'))
        saveDrugInOurSystem()
    }
  }

  const saveDrugInOurSystem = () => {
    setLoading(true)
    let url = Config.BACKEND_URL + 'patients/erx/orders'

    let data = {
      doctor_id: props.providerId,
      patient_id: props.patientId,
      drug: selectedDrug,
      instructions: instructions,
      measurement_unit: measurementUnit,
      comments: comments,
      quantity: quantity,
      days_of_supply: supply,
      refills: refills,
      daw: daw,
      pharmacy: props.patient?.change?.pharmacy?.name,
      icd_code: icdCodes,
      prescriber: props.mongoUser
        ? `${props.mongoUser.first_name} ${props.mongoUser.last_name}`
        : null,
      patient_name: `${props?.patient?.firstName} ${props?.patient?.lastName}`,
      doctor_type: `${props.mongoUser.user_type}`,
      rx_start_form: rxForm,
      need_prior_auth: preAuthReq,
    }

    let { showVideoModal, appointmentData } = props
    if (showVideoModal && appointmentData) {
      //the rx is being ordered during the visit. Link it with the event id
      data['appointment_event_id'] = appointmentData['event_id']
    }
    console.log('saved: ', data)
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + props.token },
      data: data,
    })
      .then(() => {
        console.log('saved rx order')
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when saving in our system', err)
        toast.error('Error when saving information')
        toast.error('Contact support')
      })
  }

  const getDrugInfo = (content) => {
    return {
      name: content['name'][1],
      doseForm: content['dose_form'],
      id: content['id'],
      dea_code: content['deacode'],
    }
  }

  const onDrugSelected = (drug) => {
    setModalLoading(true)
    let url = Config.CHANGE_BACKEND_URL + 'change/drugs/validate?id=' + drug.id
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + props.token },
    })
      .then(({ data }) => {
        if (data?.RESULT?.OBJECT?.id) {
          //its a valid drug
          setSelectedDrug(drug)
          setModalLoading(false)
          setDrugModalOpen(false)
          toast.success('The selected medication is valid')
        } else {
          toast.error('The selected medication is not valid')
          setModalLoading(false)
        }
      })
      .catch((err) => {
        console.log('error when checking validity of drug', err)
        toast.error('Could not verify validity of medication')
      })
  }

  const addIcdCode = (hit) => {
    let temp = [{ code: hit.code, display: hit.display }]
    setIcdCodes(temp)
  }
  const addSearchedIcdCode = (codeData) => {
    let temp = [
      {
        code: codeData.icd_10_cm_code,
        display: codeData.description,
      },
    ]
    setIcdCodes(temp)
  }

  const onNextClicked = () => {
    if (
      !props.patientId ||
      !prescriber ||
      !instructions ||
      !measurementUnit ||
      instructions.length === 0
    ) {
      toast.error('Please fill all the fields')
      return
    }

    if (!selectedDrug || !selectedDrug.id) {
      toast.error('Please search and select a medication')
      return
    }

    let url = `${Config.CHANGE_IFRAME_URL}?userid=${
      prescriber?.data?.change?.clinician_id || Config.CHANGE_USER_ID
    }&PW=${
      prescriber?.data?.change?.clinician_pwd || Config.CHANGE_PWD
    }&hdnBusiness=${
      Config.CHANGE_ORG_ID
    }&apiLogin=true&target=jsp/lab/person/PatientLookup.jsp&FromOrder=false&actionCommand=Search&FromRx=true&loadPatient=false&link=false`
    url =
      url +
      `&searchaccountId=${props.patientId}&sig=${instructions}&drugFdbId=${selectedDrug.id}`

    //&drugFdbId=${'154760'}
    if (prescriber)
      url = url + `&prescriberId=${prescriber?.data?.change?.caregiver_id}`

    if (supply && supply.length > 0) url = url + `&daySupply=${supply}`

    if (quantity && quantity.length > 0) url = url + `&quantity=${quantity}`

    if (refills && refills.length > 0) url = url + `&refill=${refills}`

    if (icdCodes && icdCodes.length > 0)
      url = url + `&icdCode=${icdCodes[0].code}`

    if (comments && comments.length > 0) url = url + `&comments=${comments}`

    url = url + `&daw=${daw ? 'y' : 'n'}`

    window.open(encodeURI(url), '_blank')
    setErxUrl(encodeURI(url))
    saveDrugInOurSystem()
    console.log('the url is', encodeURI(url))
    axios({
      method: 'post',
      url: Config.BACKEND_URL + 'rx-log',
      headers: { Authorization: 'JWT ' + props.token },
      data: {
        iframe_url: url,
        encoded_url: encodeURI(url),
        doctor_id: prescriber?.data?.firebase_id,
        patient_id: props.patientId,
        created_at: new Date(),
      },
    })
      .then(() => {
        console.log('rx url saved')
      })
      .catch((err) => {
        console.log('error when saving rx url', err)
      })
    saveDrugInOurSystem()
    // setModalUrl(encodeURI(url))
    // setModalIsOpen(true)
  }

  const getIcdSearchResults = () => {
    console.log('searching for icd')
    axios({
      method: 'get',
      url:
        Config.CHANGE_BACKEND_URL +
        'change/icd-10?' +
        `icd_code_desc=${searchIcdDesc || ''}` +
        `&icd_code=${searchIcdCode || ''}`,
      headers: {
        Authorization: 'JWT ' + props.token,
        x_firebase_id: props.providerId,
      },
    })
      .then(({ data }) => {
        console.log('search icd: ', data)
        let result = data?.RESULT?.OBJECT
        if (result) {
          setIcdSeachResult(Array.isArray(result) ? result : [result])
        }
      })
      .catch((err) => console.log('error when searching icd 10 code: ', err))
  }

  const Hit = (props, type) => {
    let { hit } = props
    // let checked = handleChecked(q, hit.symptom)
    const handleClick = () => addIcdCode(hit)

    return (
      <>
        <button
          onClick={(e) => handleClick(e)}
          style={{ border: 'none', outline: 'none', display: 'block' }}
        >
          {hit.code}: {hit.display}
        </button>
        <hr />
      </>
    )
  }

  const renderIcdCodes = () => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', padding: '30px 20px' }}
      >
        <p
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: 140,
          }}
        >
          {'ICD-10 Code'}
        </p>
        <div style={{ width: '80%' }}>
          <div className={Styles.modalNotesRow}>
            <input
              placeholder={'Enter ICD-10 Code Number'}
              className={Styles.modalInputStyle}
              style={{ marginRight: 5 }}
              type="text"
              value={searchIcdCode}
              onChange={(e) => {
                setSearchIcdCode(e.target.value)
                getIcdSearchResults()
              }}
            />
            <input
              placeholder={'Description'}
              className={Styles.modalInputStyle}
              type="text"
              value={searchIcdDesc}
              onChange={(e) => {
                getIcdSearchResults()
                setSeachIcdDesc(e.target.value)
              }}
            />
          </div>

          {icdSeachResult && icdSeachResult.length !== 0 ? (
            <div
              className={Styles.modalNotesRow}
              style={{
                overflow: 'auto',
                height: 150,
                // backgroundColor: '#F1F1F1',
                marginTop: '0px',
              }}
            >
              <ul class="list-group" style={{ width: '100%' }}>
                {icdSeachResult?.map((codeData) => (
                  <li
                    class="list-group-item list-group-item"
                    style={{ cursor: 'pointer', fontSize: '80%' }}
                    onClick={() => {
                      setSearchIcdCode('')
                      setSeachIcdDesc('')
                      setIcdSeachResult('')
                      addSearchedIcdCode(codeData)
                    }}
                  >
                    {codeData.icd_10_cm_code}: {codeData.description}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {icdCodes.length !== 0 && (
            <div>
              <p style={{ marginTop: 30, color: Constants.primaryTheme }}>
                Selected ICD-10 Code
              </p>
              <div style={{ height: 160, overflow: 'auto', width: '50%' }}>
                {icdCodes.map((icd, index) => (
                  <div
                    style={{
                      justifyContent: 'space-between',
                      borderTop: '1px solid #D3D3D3',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '4px 10px',
                    }}
                  >
                    <p>
                      {index + 1}. {icd.code}: {icd.display}
                    </p>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className={Styles.deleteIcon}
                      onClick={() => {
                        let arr = icdCodes.filter(
                          (data) => data.code !== icd.code,
                        )
                        setIcdCodes(arr)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderRestOfTheFields = () => {
    return (
      <div style={{ padding: '30px 10px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginRight: 20,
            }}
          >
            Drug
          </p>
          <p style={{ alignSelf: 'center', marginRight: 20 }}>
            {selectedDrug && selectedDrug.name}
          </p>
          <Button
            onClick={() => setDrugModalOpen(true)}
            className={GlobalStyles.button}
            style={{ width: 100, marginLeft: 40 }}
            variant="primary"
          >
            Search
          </Button>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginRight: 20,
            }}
          >
            Instructions
          </p>
          <input
            placeholder={'enter instructions for patient'}
            className={Styles.erxInstructionField}
            type="text"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 140,
            }}
          >
            Drug Measure Unit
          </p>
          <div style={{ width: 300 }}>
            <Select
              placeholder={'Select drug measure unit'}
              isSearchable={true}
              options={metadata.MEASUREMENT_OPTIONS}
              value={measurementUnit}
              onChange={setMeasurementUnit}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <p
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
                marginRight: 20,
              }}
            >
              Quantity
            </p>
            <input
              placeholder={'enter quantity'}
              className={Styles.erxInputFields}
              type="number"
              value={quantity}
              onChange={(e) => {
                let text = e.target.value
                setQuantity(text)
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <p
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
                marginRight: 20,
              }}
            >
              Days Supply
            </p>
            <input
              placeholder={'enter supply days'}
              className={Styles.erxInputFields}
              type="number"
              value={supply}
              onChange={(e) => {
                let text = e.target.value
                setSupply(text)
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <p
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
                marginRight: 20,
              }}
            >
              Refills
            </p>
            <input
              placeholder={'enter refills'}
              className={Styles.erxInputFields}
              type="number"
              value={refills}
              onChange={(e) => {
                let text = e.target.value
                setRefills(text)
              }}
            />
          </div>

          {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 20}}>*/}
          {/*  <p style={{ alignSelf: 'center', color: Constants.primaryTheme, fontWeight: 'bold', marginRight: 20}}>Unit of Measure</p>*/}
          {/*  <div style={{width: 200}}>*/}
          {/*    <Select*/}
          {/*      placeholder={'select unit'}*/}
          {/*      isSearchable={true}*/}
          {/*      options={UNITS}*/}
          {/*      value={units}*/}
          {/*      onChange={setUnits}*/}
          {/*      theme={(theme) => selectTheme(theme)}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <label
              class={Styles.checkContainer}
              style={{
                width: '30%',
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Daw
              <input
                type="checkbox"
                checked={daw}
                onChange={() => setDaw(!daw)}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <label
              class={Styles.checkContainer}
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Save as Favorite
              <input
                type="checkbox"
                checked={fav}
                onChange={() => setFav(!fav)}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginRight: 20,
            }}
          >
            Notes to Pharmacist
          </p>
          <input
            placeholder={'enter notes to pharmacist'}
            className={Styles.erxInstructionField}
            type="text"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>

        {/*<div style={{ display: 'flex', flexDirection: 'row', margin: 20}}>*/}
        {/*  <p style={{ alignSelf: 'center', color: Constants.primaryTheme, fontWeight: 'bold', marginRight: 20 }}>Effective Date</p>*/}
        {/*  <div style={{ display: 'flex', flexDirection: 'row', width: 250, margin: '0px 20px' }}>*/}
        {/*    <DateTimePicker*/}
        {/*      disableClock={true}*/}
        {/*      onChange={setDate}*/}
        {/*      value={date}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 20,
            marginTop: 30,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginRight: 20,
            }}
          >
            Pharmacy
          </p>
          <p>{props.patient?.change?.pharmacy?.name[1]}</p>
          {/*<div style={{width: '80%'}}>*/}
          {/*  <Select*/}
          {/*    placeholder={'select pharmacy'}*/}
          {/*    isSearchable={true}*/}
          {/*    options={PHARMACIES}*/}
          {/*    value={pharmacy}*/}
          {/*    onChange={setPharmacy}*/}
          {/*    theme={(theme) => selectTheme(theme)}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>

        <div className={Styles.inputDiv}>
          <div
            style={{
              width: '60%',
              margin: '0px 20px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <label
              class={Styles.checkContainer}
              style={{
                width: '30%',
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Patient RX Assistance/Start form
              <input
                type="checkbox"
                checked={rxForm}
                onClick={() => {
                  setRxForm(!rxForm)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
            {/* <label
              class={Styles.checkContainer}
              style={{
                width: '30%',
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Needs Prior Authorization Request
              <input
                type="checkbox"
                checked={preAuthReq}
                onClick={() => {
                  setPreAuthReq(!preAuthReq)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label> */}
            <label
              class={Styles.checkContainer}
              style={{
                width: '30%',
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Needs Prior Authorization Request
              <input
                type="checkbox"
                checked={preAuthReq}
                onClick={() => {
                  setPreAuthReq(!preAuthReq)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>
        {erxUrl ? (
          <a
            href={erxUrl}
            target="_blank"
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginLeft: 20,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Place the order
          </a>
        ) : null}
      </div>
    )
  }

  const renderChangeIframeModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={onModalCloseRequested}
        style={customChangeModalStyles}
        contentLabel="Modal"
      >
        <iframe
          src={modalUrl}
          title="erx"
          style={{ height: '100%', width: '100%' }}
        />
      </Modal>
    )
  }

  const renderDrugsList = () => {
    if (!drugSearchString || drugSearchString.length === 0) {
      return
    }

    if (!drugsList || drugsList.length === 0) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>no results</p>
        </div>
      )
    }

    let elements = drugsList.map((x) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid #d0d0d0',
          padding: '8px 2px',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            width: '30%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => onDrugSelected(x)}
            className={GlobalStyles.button}
            style={{ width: 100 }}
            variant="primary"
          >
            Select
          </Button>
        </div>
        <p style={{ width: '50%', textAlign: 'center' }}>{x.name}</p>
        <p style={{ width: '20%', textAlign: 'center' }}>{x.doseForm}</p>
      </div>
    ))

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'scroll',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #d0d0d0',
            padding: 10,
          }}
        >
          <p style={{ width: '30%' }}></p>
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Name
          </p>
          <p
            style={{
              width: '20%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Dose Form
          </p>
        </div>

        {elements}
      </div>
    )
  }

  const renderDrugsSearchModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={drugModalOpen}
        onRequestClose={() => setDrugModalOpen(false)}
        style={customDrugModalStyles}
        contentLabel="Modal"
      >
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              minHeight: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <p
              className={Styles.closeModalBtn}
              onClick={() => setDrugModalOpen(false)}
            >
              X
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 10px',
                width: '80%',
              }}
            >
              <input
                placeholder={'search drugs'}
                className={Styles.labTextInput}
                type="textInput"
                value={drugSearchString}
                onChange={(e) => setDrugSearchString(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onDrugSearchPressed()
                  }
                }}
              />
            </div>

            {renderDrugsList()}
          </div>
        )}
      </Modal>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          minHeight: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    )
  } else {
    return (
      <div style={{ width: '100%', padding: '30px 0px', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
            paddingBottom: 40,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 140,
            }}
          >
            Prescriber
          </p>
          <div style={{ width: 300 }}>
            <Select
              placeholder={'Select prescriber'}
              isSearchable={true}
              options={providerOptionsData}
              getOptionValue={(option) => option.label}
              value={prescriber}
              onChange={setPrescriber}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.horizontalLine} />

        {renderIcdCodes()}

        <div className={Styles.horizontalLine} />

        {renderRestOfTheFields()}

        {renderChangeIframeModal()}

        {renderDrugsSearchModal()}

        <Button
          onClick={onNextClicked}
          className={GlobalStyles.button}
          style={{ width: 100, position: 'absolute', right: 20 }}
          variant="primary"
        >
          Next
        </Button>
      </div>
    )
  }
}

const isNumber = (text) => {
  if (typeof text != 'string') return false // we only process strings!
  return (
    !isNaN(text) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(text))
  ) // ...and ensure strings of whitespace fail
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    adminId: state.userReducer.adminId,
    showVideoModal: state.genericReducer.showVideoModal,
    appointmentData: state.appointmentReducer.appointmentData,
  }
}

const mapDispatchToProps = {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  getUnreadMessages,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  fetchPatientCarePlanData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
