import React, { Component } from 'react'
import { Overlay } from 'react-bootstrap'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Draggable from 'react-draggable'
import { connect } from 'react-redux'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import './App.scss'
import NotesModal from './pages/Appointments/NotesModal'
// import Modal from 'react-modal'
import firebase from './services/firebase.js'

import SideNav from './components/SideNav.js'
import VideoModal from './components/VideoModal'
import Config from './config.js'
import StripeCancel from './mobilePages/stripe/Cancel'
import StripeCheckoutReturn from './mobilePages/stripe/CheckoutReturn'
import StripeSuccess from './mobilePages/stripe/Success'
import MyAccount from './pages/Account/Account.js'
import ProviderAvailability from './pages/Account/ProviderAvailability'
import Credentialing from './pages/Account/Credentialing'
import Invoices from './pages/Account/Invoices'
import Tutorials from './pages/Account/Tutorials'
import Admin from './pages/Admin/Admin'
import CircleVideo from './pages/Admin/CircleVideo'
import Appointments from './pages/Appointments/Appointments.js'
import InfusionDemo from './pages/Appointments/InfusionDemo'
import VideoComponent from './pages/Appointments/Video'
import CCFMClientSignup from './pages/Authentication/CCFMClientSignup'
import CCFMParticipantSignup from './pages/Authentication/CCFMParticipantSignup'
import Login from './pages/Authentication/Login'
import Signup from './pages/Authentication/Signup'
import TeamSignUp from './pages/Authentication/TeamSignUp'
import ContentList from './pages/Content/List.js'
import Faxes from './pages/Faxes/Faxes'
import ProviderHome from './pages/Home/Home'
import MessagesList from './pages/Messages/Messages.js'
import NotFoundPage from './pages/NotFoundPage.js'
import Notifications from './pages/Notifications/Notifications'
import NylasCallback from './pages/NylasCallback'
import NylasThankyouPage from './pages/NylasThankyouUrl'
import Orders from './pages/Orders/Orders'
import AilaWebSignup from './pages/PatientWeb/PatientSignup/AilaWebSignup'
import BodySystems from './pages/Patients/BodySystems/BodySystems.js'
import PatientInsights from './pages/Patients/Insights.js'
import PatientList from './pages/Patients/List.js'
import PatientProfileNew from './pages/Patients/Profile/Profile'
import PatientProgressNew from './pages/Patients/Progress/Progress'
import CCFMTOS from './pages/Policies/CCFM_TOS'
import AilaDashboard from './pages/Population/AilaDashboard'
import PopDashboard from './pages/Population/Dashboard'
import PopDashboard1 from './pages/Population/Dashboard1'
import Survey from './pages/Population/Survey'
import RosterTable from './pages/Roster/RosterTable'
import Support from './pages/Support'
import ClinicalSupport from './pages/Support/ClinicalSupport'
import AilaCalendarAppointment from './pages/wix/AilaCalendarAppointment'
import AilaDownloadApp from './pages/wix/AilaDownloadApp'
import AilaInputPatientDetails from './pages/wix/AilaInputPatientDetails'
import AilaPremiumPopup from './pages/wix/AilaPremiumPopup'
import AilaWebCareteam from './pages/wix/AilaWebCarteam'
import AilaWebCircles from './pages/wix/AilaWebCircles'
import AilaWebConsentPolicies from './pages/wix/AilaWebConsentPolicies'
import AilaWebForgotPassword from './pages/wix/AilaWebForgotPassword'
import AilaWebIntro from './pages/wix/AilaWebIntro'
import AilaWebLogin from './pages/wix/AilaWebLogin'
import AilaWebPayment from './pages/wix/AilaWebPayment'
import AilaWebQuestionnaire from './pages/wix/AilaWebQuestionnaire'
import AilaWebQuestions from './pages/wix/AilaWebQuestions'
// import PatientAssistanceForm from './pages/Questionnaire/PatientAssistanceForm.js'
import PatientAssistanceForm from './pages/Questionnaire/PatientAssistanceForm1.js'
import LongCovid from './pages/wix/LongCovid'
import ProviderReferralForm from './pages/wix/ProviderReferralForm'
import RpmConsentForm from './pages/wix/RpmConsentForm'
import WixGeneral from './pages/wix/SignUp'

//LONG COVID FLOW
import AilaLongCovid from './pages/LongCovidFlow/AilaLongCovid'
import LongCovidSignup from './pages/LongCovidFlow/LongCovidSignup'
// import LongcovidDashboard from './pages/LongCovidFlow/LongcovidDashboard'
import LongcovidIntake from './pages/LongCovidFlow/LongcovidIntake'

//PATIENT APP - WEB  VIEW FILES
import Communities from './pages/PatientWeb/Communities/Communities'
import Community from './pages/PatientWeb/Communities/Community'
import PatientHome from './pages/PatientWeb/Home'
import PatientAccount from './pages/PatientWeb/PatientAccount/PatientAccount'
import PatientCardFunctions from './pages/PatientWeb/PatientAccount/PatientCardActions'
import PatientSubscription from './pages/PatientWeb/PatientAccount/PatientSubscription'
import PatientAppointments from './pages/PatientWeb/PatientAppointments/PatientAppointments'
import PatientMessages from './pages/PatientWeb/PatientMessages/PatientMessages'
import MyCare from './pages/PatientWeb/PatientMyCare/MyCare'
import HealthCoachPatientList from './pages/Patients/HealthCoachPatientList'

// Product Analytics
import ProductAnalytics from './pages/Analytics/ProductAnalytics'
// import ProductAnalytics from './pages/Analytics/ProductAnalytics1'

import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TimeSlotsList from './components/appointment/TimeSlotsList'
import NotificationToast from './components/notifications/NotificationToast'
import StripeCardDetails from './mobilePages/stripe/CardDetails'
import MobileSubsciption from './mobilePages/stripe/MobileSubscription'
import ResourcePayment from './mobilePages/stripe/ResourcePayment'
import SubscriptionAppointment from './mobilePages/stripe/SubscriptionAppointment'
import SuccessAppointment from './mobilePages/stripe/SuccessAppointment'
import SuccessResource from './mobilePages/stripe/SuccessResource'
import AilaOnboardingQuestions from './pages/wix/AilaOnboardingQuestions'
import {
  addFilledData,
  addSparseData,
  getUnreadMessages,
  setAppointmentNotesData,
  stopTimer,
  toggleNotesModal,
  toggleVideoModal,
} from './redux/actions'
// import PageRpm from './pages/Rpm/PageRpm'
import PageRpm from './pages/Rpm/RpmPage1'
const axios = require('axios')

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null
const branch = require('branch-sdk')

const customVideoModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 200,
    height: 200,
    position: 'absolute',
    top: 10,
    right: 10,
  },
}

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      navCollapsed: false,
      // showVideoModal: false,
    }
    this.buttonRef = React.createRef()
    this.hideVideoModal = this.hideVideoModal.bind(this)
    this.hideNotesModal = this.hideNotesModal.bind(this)
    this.showNotesModal = this.showNotesModal.bind(this)
    this.saveAppointmentEndTime = this.saveAppointmentEndTime.bind(this)
  }

  componentDidMount() {
    localStorage.clear()

    this.pushNotificationListener()

    // console.log('LOCALSTORAGE: ', localStorage)

    branch.init(Config.BRANCH_DEEPLINK_KEY, function (err, data) {
      console.log(err, data)
      const params = data?.data_parsed

      if (err) {
        console.error('Error from Branch: ' + err)
        return
      }

      // params will never be null if error is null

      if (params['+non_branch_link']) {
        const nonBranchUrl = params['+non_branch_link']
        console.log('a non branch link was opened', nonBranchUrl)
        // Route non-Branch URL if appropriate.
        return
      }

      if (!params['+clicked_branch_link']) {
        // Indicates initialization success and some other conditions.
        // No link was opened.
        return
      }

      // A Branch link was opened.
      // Route link based on data in params, e.g.

      // Get title and url for route
      console.log('handling branch link', params['~referring_link'], params)

      // if (params && params.afmc) {
      //   setUserAffiliateCode(params.afmc)
      //   convertUserToVisitorInLeadDyno(params.afmc)
      // }

      // if (params && params.referral_code) {
      //   console.log('referral ack called:::')
      //   AsyncStorage.setItem('referralCode', params.referral_code)
      // }

      // if (params?.screen) {
      //   let screen = params.screen
      //   console.log('contains screen', screen)
      //   setDeeplinkScreen(screen)
      //   setDeepLinkData(params)
      // }

      // if (params?.event_id) {
      //   console.log('Event id: ', params?.event_id)
      //   setDeepLinkData(params.event_id)
      // }

      if (params?.$marketing_title) {
        localStorage.setItem('marketing_title', params?.$marketing_title)
      }

      if (params?.ccfm) {
        console.log('ccfm deeplink')
        localStorage.setItem('ccfm', 'yes')
      }

      if (params?.dac) {
        console.log('dac pilot deeplink')
        localStorage.setItem('dac', 'yes')
      }

      if (params?.rapilot) {
        console.log('ra pilot deeplink')
        localStorage.setItem('rapilot', 'yes')
      }
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loggedIn) return { loggedIn: true }
    return { loggedIn: false }
  }

  pushNotificationListener() {
    const routeName = (type) => {
      switch (type) {
        case 'CircleList':
          return {
            pathname: '/admin',
            state: 'events',
            key: 2,
          }

        case 'Appointments':
          return {
            pathname: '/appointments',
          }

        default:
          return {
            pathname: '/notifications',
          }
      }
    }

    messaging &&
      messaging.onMessage((payload) => {
        console.log('payload: ', payload, this.state.loggedIn)
        this.props.getUnreadMessages(
          this.props.mongoUser.firebase_id,
          this.props.token,
          this.props.mongoUser,
        )
        toast(
          <NotificationToast
            title={payload?.notification?.title}
            body={payload?.notification?.body}
            route={routeName(payload?.data?.notification_type)}
          />,
        )
      })
  }

  hideVideoModal() {
    this.props.setAppointmentNotesData(null)
    this.props.toggleVideoModal(false)
    this.saveAppointmentEndTime()
    this.hideNotesModal()
    // this.setState({ showVideoModal: false })
  }

  hideNotesModal() {
    this.props.toggleNotesModal(false)
    // this.setState({ showVideoModal: false })
  }

  showNotesModal() {
    this.props.toggleNotesModal(true)
    // this.setState({ showVideoModal: false })
  }

  saveAppointmentEndTime() {
    const { token, appointmentData } = this.props
    if (appointmentData.event_id)
      axios({
        method: 'put',
        url: `${Config.BACKEND_URL}appointment`,
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          event_id: appointmentData.event_id,
          visit_end_time: moment().valueOf(),
        },
      })
        .then(() => {
          console.log(
            'updated visit end time',
            appointmentData.event_id,
            moment().valueOf(),
          )
        })
        .catch((err) => {
          console.log('error when saving visit end time', err)
        })
  }

  // renderVideoModalWithoutMove() {
  //   console.log('rendering video modal')
  //   return (
  //     <Modal
  //       onRequestClose={() => this.hideVideoModal()}
  //       ariaHideApp={false}
  //       isOpen={this.props.showVideoModal}
  //       style={customVideoModalStyle}
  //       contentLabel="Modal"
  //     >
  //       <div style={{ backgroundColor: 'red', width: '100%', height: '100%' }}>
  //         <p>hey there</p>
  //       </div>
  //     </Modal>
  //   )
  // }
  renderButtonWithOverlay() {
    return (
      <>
        <div
          style={{
            height: 1,
            width: 1,
            position: 'fixed',
            right: 10,
            top: 0,
            visibility: 'hidden',
          }}
          ref={this.buttonRef}
        />

        <Overlay
          target={this.buttonRef.current}
          show={this.props.showVideoModal || this.props.openNotesModal}
          placement="bottom"
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                height: 175,
                width: 450,
                color: 'white',
                borderRadius: 3,
                ...props.style,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: 20,
              }}
            >
              {this.props.showVideoModal ? (
                <VideoModal
                  endCall={this.hideVideoModal}
                  showNotesModal={this.showNotesModal}
                />
              ) : null}
              <NotesModal
                modalIsOpen={this.props.openNotesModal}
                hideNotesModal={this.hideNotesModal}
                getUserCarePlan={() => console.log('notes from video')}
                patientId={this.props?.doctorPatientData?.patient_id}
                carePlanNotesData={this.props.appointmentData}
                comingFrom={'visit_video'}
                selectedPatient={this.props?.doctorPatientData}
              />
            </div>
          )}
        </Overlay>
      </>
    )
  }

  render() {
    return (
      <Router>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {this.state.loggedIn && (
            <div
              style={{
                width: this.state.navCollapsed ? 80 : 270,
                zIndex: this.props.hideSideBar ? -1 : 1,
              }}
            >
              <SideNav
                onNavCollapse={(value) =>
                  this.setState({ navCollapsed: value })
                }
              />
            </div>
          )}

          {this.renderButtonWithOverlay()}

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <div style={{ width: '100%', backgroundColor: '#e8e8e8' }}>
            <ToastContainer autoClose={3400} pauseOnHover={false} />

            <Switch>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route path="/login" exact component={Login} />
              <Route path="/signup/provider" exact component={Signup} />
              <Route path="/signup/team-member" exact component={TeamSignUp} />

              {/* Patient Assistance Form */}
              <Route
                path="/questionnaire/paf/:id"
                exact
                component={PatientAssistanceForm}
              />


              <Route
                path="/signup/ccfm/participant"
                exact
                component={CCFMParticipantSignup}
              />
              <Route
                path="/signup/ccfm/client"
                exact
                component={CCFMClientSignup}
              />
              <Route path="/provider/home" exact component={ProviderHome} />

              <Route path="/admin" exact component={Admin} />

              {/* Analytics page for the product */}
              <Route
                path="/analytics/product-analytics"
                exact
                component={ProductAnalytics}
              />

              {/* Remote Monitoring */}
              <Route path="/rpm/remote-monitoring" exact component={PageRpm} />

              <Route path="/admin/circle" exact component={CircleVideo} />
              {/* Wix pages */}
              <Route path="/wix/general" exact component={WixGeneral} />
              <Route path="/wix/long-covid" exact component={LongCovid} />
              <Route path="/wix/rpm-consent" exact component={RpmConsentForm} />
              <Route
                path="/wix/aila-questionnaire-intro"
                exact
                component={AilaWebIntro}
              />
              <Route
                path="/wix/aila-questionnaire/:diseaseName"
                exact
                component={AilaWebQuestionnaire}
              />
              <Route
                path="/wix/aila-web-circle/:circle"
                exact
                component={AilaWebCircles}
              />
              <Route
                path="/wix/aila-web-login"
                exact
                component={AilaWebLogin}
              />
              <Route path="/signup/patient" exact component={AilaWebSignup} />
              <Route
                path="/wix/aila-web-signup"
                exact
                component={AilaWebSignup}
              />
              <Route
                path="/wix/aila-download"
                exact
                component={AilaDownloadApp}
              />
              <Route
                path="/wix/aila/careteam"
                exact
                component={AilaWebCareteam}
              />
              <Route
                path="/wix/aila/program"
                exact
                component={AilaWebPayment}
              />
              <Route
                path="/wix/aila/consent-policies"
                exact
                component={AilaWebConsentPolicies}
              />
              <Route
                path="/wix/aila-web-questions"
                exact
                component={AilaOnboardingQuestions}
              />
              <Route
                path="/wix/aila-questionnaire"
                exact
                component={AilaWebQuestions}
              />

              {/* LONG COVID FLOW ROUTES*/}
              <Route
                path="/signup/long-covid"
                exact
                component={LongCovidSignup}
              />
              <Route
                path="/long-covid/questionnaire"
                exact
                component={AilaLongCovid}
              />
              <Route
                path="/long-covid/previsit"
                exact
                component={LongcovidIntake}
              />
              {/* <Route
                path="/long-covid/dashboard"
                exact
                component={LongcovidDashboard}
              /> */}

              {/* <Route
                path="/wix/aila/appointment-scheduler"
                exact
                component={AilaWebAppointmentScheduler}
              /> */}
              <Route
                path="/wix/aila/appointment-scheduler"
                exact
                component={AilaCalendarAppointment}
              />
              <Route
                path="/wix/aila/payment"
                exact
                component={AilaPremiumPopup}
              />
              <Route
                path="/wix/aila/patient-details"
                exact
                component={AilaInputPatientDetails}
              />
              <Route
                path="/wix/aila/forgot-password"
                exact
                component={AilaWebForgotPassword}
              />
              <Route
                path="/wix/aila/provider-referral"
                exact
                component={ProviderReferralForm}
              />

              {/*policy links*/}
              <Route path="/ccfm/tos" exact component={CCFMTOS} />



              {/* patient links */}

              <Route path="/patient/home" exact component={PatientHome} />
              <Route path="/patient/account" exact component={PatientAccount} />
              <Route
                path="/patient/subscription"
                exact
                component={PatientSubscription}
              />

              <Route
                path="/patient/appointments"
                exact
                component={PatientAppointments}
              />

              <Route path="/patient/mycare" exact component={MyCare} />

              <Route path="/patient/community" exact component={Communities} />
              <Route
                path="/patient/community/:community"
                exact
                component={Community}
              />

              <Route path="/patient/circle" exact component={CircleVideo} />

              <Route
                path="/patient/messages"
                exact
                component={PatientMessages}
              />

              {this.props.mongoUser?.user_type === 'Health Coach' ? (
                <Route
                  path="/patient/list"
                  exact
                  component={HealthCoachPatientList}
                />
              ) : (
                <Route path="/patient/list" exact component={PatientList} />
              )}
              <Route
                path="/patient/insights"
                exact
                component={PatientInsights}
              />
              <Route
                path="/patient/profile"
                exact
                component={PatientProfileNew}
              />

              <Route
                path="/patient/mycards"
                exact
                component={PatientCardFunctions}
              />

              {/* <Route path="/patient/profile-new"  exact component={PatientProfileNew} /> */}
              <Route
                path="/patient/progress"
                exact
                component={PatientProgressNew}
              />
              {/* <Route path="/patient/progress-new"  exact component={PatientProgressNew} /> */}
              <Route
                path="/patient/body-systems"
                exact
                component={BodySystems}
              />
              {/* <Route path="/patient/careteam"  exact component={PatientCareTeam} /> */}
              <Route
                path="/patient/careplan"
                exact
                component={PatientProfileNew}
                key="2"
              />

              {/* <Route path="/appointments" exact component={InfusionDemo} /> */}
              <Route path="/appointments" exact component={Appointments} />
              {/* <Route path="/roster-patient" exact component={PatientRoster} /> */}
              <Route path="/roster-patient" exact component={RosterTable} />

              <Route
                path="/clinical-support"
                exact
                component={ClinicalSupport}
              />
              <Route
                path="/appointments/video"
                exact
                component={VideoComponent}
              />
              <Route path="/notifications" exact component={Notifications} />
              <Route path="/faxes" exact component={Faxes} />
              <Route path="/orders" exact component={Orders} />
              <Route path="/messages" exact component={MessagesList} />
              <Route path="/account" exact component={MyAccount} />
              <Route
                path="/account/availability"
                exact
                component={ProviderAvailability}
              />
              <Route
                path="/account/credentialing"
                exact
                component={Credentialing}
              />
              <Route path="/account/invoices" exact component={Invoices} />
              <Route path="/account/tutorials" exact component={Tutorials} />

              <Route
                path="/wellness/dashboard"
                exact
                component={PopDashboard}
              />
              <Route
                path="/population/dashboard-1"
                exact
                component={PopDashboard1}
              />
              <Route
                path="/population/dashboard"
                exact
                component={AilaDashboard}
              />
              <Route path="/wellness/survey" exact component={Survey} />
              <Route path="/content/library" exact component={ContentList} />
              <Route path="/support" exact component={Support} />
              <Route path="/nylas/callback" exact component={NylasCallback} />
              <Route
                path="/nylas/thankyou"
                exact
                component={NylasThankyouPage}
              />
              <Route path="/stripe/success" exact component={StripeSuccess} />
              <Route path="/stripe/cancel" exact component={StripeCancel} />
              <Route
                path="/stripe/subscription"
                exact
                component={MobileSubsciption}
              />
              <Route path="/timeslot" exact component={TimeSlotsList} />
              <Route
                path="/stripe/subscription-new"
                exact
                component={MobileSubsciption}
              />
              {/* <Route
                path="/stripe/membership"
                exact
                component={StripeMembershipNew}
              /> */}
              <Route
                path="/stripe/subscription-mobile"
                exact
                component={MobileSubsciption}
              />
              <Route
                path="/stripe/resource-payment"
                exact
                component={ResourcePayment}
              />
              <Route
                path="/stripe/appointment-subscription"
                exact
                component={SubscriptionAppointment}
              />
              <Route
                path="/stripe/appointment-success"
                exact
                component={SuccessAppointment}
              />
              <Route
                path="/stripe/resource-success"
                exact
                component={SuccessResource}
              />
              <Route
                path="/stripe/card-details"
                exact
                component={StripeCardDetails}
              />
              <Route
                path="/stripe/checkout/return"
                exact
                component={StripeCheckoutReturn}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  hideSideBar: state.genericReducer.hideSideBar,
  showVideoModal: state.genericReducer.showVideoModal,
  openNotesModal: state.genericReducer.openNotesModal,
  doctorPatientData: state.appointmentReducer.patientData,
  appointmentData: state.appointmentReducer.appointmentData,
  token: state.authReducer.token,
  mongoUser: state.userReducer.mongoUser,
})

const mapDispatchToProps = {
  addFilledData,
  addSparseData,
  stopTimer,
  toggleVideoModal,
  toggleNotesModal,
  setAppointmentNotesData,
  getUnreadMessages,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
