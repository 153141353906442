import {
  faCheckCircle,
  faEye,
  faMinusCircle,
  faShare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Handlebars from 'handlebars'
import moment from 'moment'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import 'react-chat-elements/dist/main.css'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Header from '../../components/Header'
import Config from '../../config'
import {
  addMongoUser,
  addPatient,
  addPatientLP,
  addPatientList,
  addRpmPatient,
  addVirgilE3,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientCoreDate,
  fetchPatientLabs,
  fetchPatientMedications,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  getUnreadMessages,
  resetTimer,
  setPatientCoreData,
  startTimer,
  stopTimer,
  updateDoctorMongoUser,
  updatePatientData,
  updateSideNavBar,
  userLoggedIn,
} from '../../redux/actions'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Faxes.module.scss'
// Template for Fax cover sheet
import faxCoverSheet from '../../helpers/PdfTemplates/FaxHippaCoverSheet.txt'
// import jsPDF from 'jspdf'
import PDFMerger from 'pdf-merger-js'
// import fs from 'fs'
// import { merge } from 'lodash'
import { Readable } from 'stream'

const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const sendFaxModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '40vw',
    height: '60vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class Faxes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDoc: false,
      modalLoading: false,
      testPDF: '',
      faxList: [],
      outboxList: [],
      showInbox: true,
      selectedTab: 'Inbox',
      sendModal: false,
      modalSchedule: new Date(),
      modalError: false,
      modalFileName: '',
      modalSenderEmail: '',
      modalReceiverFax: '',
      modalReceiverPhone: '',
      modalReceiverName: '',
      patientListOptions: [],
      // Initially, no file is selected
      selectedFile: null,
      forwardFax: false,
      superUser: false,
      loading: false,
      faxCoverSheetAsHtml: '',
    }
    this.getInbox = this.getInbox.bind(this)
    this.getOutbox = this.getOutbox.bind(this)
    this.onModalHide = this.onModalHide.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.onSendPressed = this.onSendPressed.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getInbox()
    this.preparePatientList()
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const list = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientListOptions: list })
  }

  getPatientList() {
    // this.setState({loading: true})
    const { token, adminId, firebaseUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ? '?access=admin' : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        self.setState({ loading: false })
        if (response.status === 200) {
          const { data } = response
          let total = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })
          self.props.addPatientList(data)
          self.preparePatientList(data)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
        self.setState({ loading: false })
      })
  }

  //GET ALL SRFAX INBOX FAXES
  getInbox(archive) {
    const { token, mongoUser } = this.props
    const { selectedTab } = this.state
    this.setState({ loading: true })
    console.log(
      'REQUEST',
      `${Config.BACKEND_URL}srfax/inbox`,
      token,
      mongoUser.firebase_id,
    )
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `srfax/inbox`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    if (archive === true) {
      config.params = {
        archive: true,
      }
    }
    axios(config)
      .then(({ data }) => {
        this.setState(
          {
            faxList: data,
          },
          () => this.setState({ loading: false }),
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        this.setState({ loading: false })
        toast.error('Could not fetch Faxes')
      })
  }

  // getInbox(archive) {
  //   const { token, mongoUser } = this.props
  //   const { selectedTab } = this.state
  //   this.setState({ loading: true })
  //   console.log(
  //     'REQUEST',
  //     `${Config.CHANGE_BACKEND_URL}srfax/inbox`,
  //     token,
  //     mongoUser.firebase_id,
  //   )
  //   let config = {
  //     method: 'get',
  //     url: Config.CHANGE_BACKEND_URL + `srfax/inbox`,
  //     headers: {
  //       Authorization: `JWT ${token}`,
  //       x_firebase_id: mongoUser.firebase_id,
  //     },
  //   }
  //   axios(config)
  //     .then(({ data }) => {
  //       console.log('INBOX:::', data)
  //       selectedTab === 'Inbox'
  //         ? this.setState(
  //             {
  //               faxList: data.filter(
  //                 (item) =>
  //                   !item.hasOwnProperty('archive') || item?.archive === false,
  //               ),
  //             },
  //             () => this.setState({ loading: false }),
  //           )
  //         : this.setState(
  //             {
  //               faxList: data.filter((item) => item.archive === true),
  //             },
  //             () => this.setState({ loading: false }),
  //           )

  //       // if (archive) {
  //       //   this.setState({ faxList: data }, () =>
  //       //     this.setState({ loading: false }),
  //       //   )
  //       // } else {
  //       //   this.setState(
  //       //     {
  //       //       faxList: data.filter(
  //       //         (item) =>
  //       //           !item.hasOwnProperty('archive') || item?.archive === false,
  //       //       ),
  //       //     },
  //       //     () => this.setState({ loading: false }),
  //       //   )
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log('Error getting Faxes for the Patient ', err)
  //       this.setState({ loading: false })
  //       toast.error('Could not fetch Faxes')
  //     })
  // }

  //GET ALL SRFAX OUTBOX FAXES
  getOutbox() {
    const { token, mongoUser } = this.props

    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.CHANGE_BACKEND_URL + `srfax/outbox`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log('OUTBOX::::', data.Result)
        this.setState({ outboxList: data.Result }, () =>
          this.setState({ loading: false }),
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        this.setState({ loading: false })
        toast.error('Could not fetch Faxes')
      })
  }

  renderFax = (FileName, direction) => {
    const { token, mongoUser } = this.props

    this.setState({ modalLoading: true })
    let config = {
      method: 'get',
      url:
        Config.CHANGE_BACKEND_URL +
        `srfax/retrieve-fax?sFaxFileName=${FileName}&sDirection=${direction}`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log(data.Result)
        this.setState(
          {
            testPDF: data.Result,
            selectedFile: data.Result,
            modalFileName: FileName,
          },
          () => {
            this.setState({ modalLoading: false })
          },
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes content for the Patient ', err)
        toast.error('Could not fetch Faxes')
      })
  }

  renderFaxFromBackend = (FileName) => {
    const { token, mongoUser } = this.props
    this.setState({ modalLoading: true })
    console.log(
      'Searching for file: ',
      FileName,
      Config.BACKEND_URL + `srfax/file?filename=${FileName}`,
    )
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `srfax/file`,
      params: {
        filename: FileName,
      },
      // headers: {
      //   Authorization: `JWT ${token}`,
      //   x_firebase_id: mongoUser.firebase_id,
      // },
    }
    axios(config)
      .then(({ data }) => {
        console.log('Got fax data:', data)
        if (data?.success) {
          data = data.data

          this.setState(
            {
              testPDF: data.pdf,
              selectedFile: data.FileName,
              modalFileName: FileName,
            },
            () => {
              this.setState({ modalLoading: false })
            },
          )
        }
      })
      .catch((err) => {
        console.log('Error getting Faxes content for the Patient ', err)
        toast.error('Could not fetch Faxes')
      })
  }

  onModalHide() {
    this.setState({
      openDoc: false,
      sendModal: false,
      forwardFax: false,
      modalSchedule: new Date(),
      modalError: false,
      testPDF: '',
      modalFileName: '',
      modalSenderEmail: '',
      modalReceiverFax: '',
      // Initially, no file is selected
      selectedFile: null,
    })
  }
  // On file select (from the pop up)
  onFileChange(event) {
    // Update the state
    // console.log('->', event.target.files)
    if (this.state.modalReceiverFax.length === 0) {
      toast.error(
        'Please enter receivers fax number, before uploading the file',
      )
      return
    }
    // if (this.state.modalReceiverName.length === 0) {
    //   toast.error('Please enter receivers name, before uploading the file')
    //   return
    // }
    // if (this.state.modalReceiverPhone.length === 0) {
    //   toast.error(
    //     'Please enter receivers phone number, before uploading the file',
    //   )
    //   return
    // }
    if (event.target.files[0]) {
      //   console.log(event.target.files[0])
      this.setState({ modalFileName: event.target.files[0].name })

      const ReadCoverSheet = (file) =>
        new Promise((resolve, reject) => {
          axios(file).then(async (res) => {
            try {
              // console.log(res.data.toString())
              let templateHtml = await Handlebars.compile(res.data.toString())
              let bodyHtml = await templateHtml({
                practice_name: 'Aila Health',
                practice_fax_no: '7205009759',
                practice_ph_no: '(415) 704-9601',
                senders_name: this.state.modalReceiverName,
                date_of_fax: moment().format('MM/DD/YYYY'),
                senders_ph_no: this.state.modalReceiverPhone,
                senders_fax: this.state.modalReceiverFax,
              })
              // console.log(bodyHtml)
              this.setState({ pdfCover: bodyHtml })
              resolve(bodyHtml)
            } catch (err) {
              reject(err)
            }
          })
        })

      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })

      toBase64(event.target.files[0])
        .then((data) => {
          ReadCoverSheet(faxCoverSheet)
            .then((faxCoverData) => {
              this.setState({ faxCoverSheetAsHtml: faxCoverData })
            })
            .catch((err) =>
              console.log('Error while reading cover sheet in', err),
            )
          // let result = data.substring(data.indexOf(', ') + 1)
          let result = data.split(',')[1]
          //   console.log(result)
          this.setState({ selectedFile: result })
        })
        .catch((err) => console.log('Error while uploading the file', err))
    } else {
      this.setState({ modalFileName: '', selectedFile: null })
    }
  }

  generatePdfWithCoverPage(pdfData) {
    return new Promise((resolve, reject) => {
      let postData = {
        htmlContent: pdfData,
      }
      let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
      //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
      axios({
        method: 'post',
        headers: {
          Authorization: 'JWT ' + this.props?.token,
          x_firebase_id: this.props?.patientId,
        },
        url: url,
        data: postData,
      })
        .then(async ({ data }) => {
          // console.log('pdfCoverSheet', data.result)
          // console.log('pdfContent', this.state.selectedFile)
          // Only works with pdf files
          var mergedPdfBuffer = null
          if (this.state.modalFileName.endsWith('.pdf')) {
            try {
              // console.log('This is a pdf, adding a cover sheet')
              let pdfMerger = new PDFMerger()
              let pdfBuffer = Buffer.from(data.result, 'base64')
              let pdfBuffer1 = new Readable()
              pdfBuffer1.push(data.result)
              await pdfMerger.add(pdfBuffer)

              let pdfBuffer3 = Buffer.from(this.state.selectedFile, 'base64')
              await pdfMerger.add(pdfBuffer3)

              console.log('pdfMerger', pdfMerger)
              // await pdfMerger.save('merged.pdf', function (err) {
              //   if (err) console.log(err)
              //   else console.log('Merged pdf saved')
              // }) //save under given name and reset the internal document

              // Export the merged PDF as a nodejs Buffer
              mergedPdfBuffer = await pdfMerger.saveAsBuffer()
              console.log('mergedPdfBuffer', mergedPdfBuffer)
              console.log('Encoded:', mergedPdfBuffer.toString('base64'))
              this.setState({ selectedFile: mergedPdfBuffer })
              // fs.writeSync(
              //   'merged1.pdf',
              //   mergedPdfBuffer,
              // )

              // // Insert a link that allows the user to download the PDF file
              // var link = document.createElement('a')
              // link.innerHTML = 'Download PDF file'
              // link.download = `${this.state.modalFileName} ${moment().format(
              //   'MM/DD/YYYY HH:MM:SS',
              // )}.pdf`
              // // link.href = 'data:application/octet-stream;base64,' + data.result
              // // link.href =
              // //   'data:application/octet-stream;base64,' + this.state.selectedFile
              // // link.href =
              // //   'data:application/octet-stream;base64,' + mergedPdfBuffer
              // link.href =
              //   // 'data:application/pdf' +
              //   window.URL.createObjectURL(new Blob([mergedPdfBuffer]))
              // // document.body.appendChild(link);
              // link.click()
              // // resolve(mergedPdfBuffer)
              // // resolve(new Blob([mergedPdfBuffer]))

              let bufferredPdf = Buffer.from(mergedPdfBuffer)
              console.log('bufferredPdf', bufferredPdf)
              const pdfBase64 = window.btoa(
                String.fromCharCode(...mergedPdfBuffer),
              )
              console.log('pdfBase64', pdfBase64)

              // resolve(mergedPdfBuffer.toString('base64'))
              resolve(pdfBase64)
              // resolve(new TextDecoder().decode(mergedPdfBuffer))
            } catch (err) {
              console.log('Error when merging pdf', err)
            }
          } else {
            // TODO: make a call to the backend tell files other than pdf are sent in FAX
          }
        })
        .catch((err) => {
          console.log('F1. error when getting saving provider goals data', err)
          toast.error('Could not download Notes, please try again')
          reject(err)
        })
    })
  }

  onSendPressed() {
    const { token, mongoUser } = this.props
    const { faxCoverSheetAsHtml } = this.state
    let faxNumber = this.state.modalReceiverFax.replaceAll('-', '')

    if (faxNumber?.length < 10) {
      // console.log(faxNumber)
      toast.error('Please enter correct fax number')
      return
    }

    // console.log('RESULT:::', result)

    this.generatePdfWithCoverPage(faxCoverSheetAsHtml)
      .then((pdfData) => {
        console.log('pdfData', typeof pdfData, pdfData)
        let result = {
          sSenderEmail: this.state.modalSenderEmail,
          sToFaxNumber: '1' + faxNumber,
          sFileName_1: this.state.modalFileName,
          sFileContent_1: pdfData,
        }
        let config = {
          method: 'post',
          url: !this.state.forwardFax
            ? Config.CHANGE_BACKEND_URL + `srfax/outbox`
            : Config.CHANGE_BACKEND_URL +
              `srfax/forward?sDirection=${this.state.showInbox}`,
          headers: {
            Authorization: `JWT ${token}`,
            x_firebase_id: mongoUser.firebase_id,
          },
          data: result,
        }
        axios(config)
          .then(({ data }) => {
            console.log('SRFAX SEND RES::::', data)
            toast.success('Fax queued successfully')
            this.getInbox()
            this.getOutbox()
            this.onModalHide()
          })
          .catch((err) => {
            console.log('Error getting Faxes for the Patient ', err)
            toast.error('Could not send Fax')
          })
      })
      .catch((err) => {
        console.log('Error when converting to base64', err)
      })
  }

  sendFaxModal() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '90%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => this.onModalHide()}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            {this.state.forwardFax ? `Forward Fax` : `Send Fax`}
          </h4>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Sender Email: </p>
            <input
              placeholder={'Please enter senders email address'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalSenderEmail}
              onChange={(e) =>
                this.setState({ modalSenderEmail: e.target.value })
              }
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Recipient Fax: </p>
            <input
              placeholder={'Please enter recipient fax number'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalReceiverFax}
              onChange={(e) =>
                this.setState({ modalReceiverFax: e.target.value })
              }
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Recipient Name: </p>
            <input
              placeholder={'Please enter recipient name'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalReceiverName}
              onChange={(e) =>
                this.setState({ modalReceiverName: e.target.value })
              }
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Recipient Phone: </p>
            <input
              placeholder={'Please enter recipient phone number'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalReceiverPhone}
              onChange={(e) =>
                this.setState({ modalReceiverPhone: e.target.value })
              }
            />
          </div>

          {/* <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Schedule Date : </p>
            <DateTimePicker
              onChange={(val) => {
                this.setState({ modalSchedule: val, modalError: false })
              }}
              value={this.state.modalSchedule}
              className={Styles.addManualSelectInput}
            />
          </div> */}
          {!this.state.forwardFax && (
            <div className={Styles.addManualRow}>
              <p className={Styles.addManualQuestion}>Select file: </p>
              <input
                placeholder="Select File to be Faxed"
                className={Styles.addManualSelectInput}
                type="file"
                onChange={this.onFileChange}
              />
            </div>
          )}

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>File Selected: </p>
            <input
              disabled
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalFileName}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => this.onSendPressed()}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200, position: 'absolute', bottom: 20 }}
            variant="primary"
          >
            Send Fax
          </Button>
        </div>
      </div>
    )
  }

  renderDocuments(showInbox, selectedTab) {
    const { token, mongoUser } = this.props
    const faxes = showInbox ? this.state.faxList : this.state.outboxList
    // const faxes =
    //   selectedTab === 'Inbox'
    //     ? this.state.faxList.filter(
    //         (item) =>
    //           !item.hasOwnProperty('archive') || item?.archive === false,
    //       )
    //     : selectedTab === 'Outbox'
    //     ? this.state.outboxList
    //     : this.state.faxList.filter((item) => item.archive === true)

    const elements = faxes.map((fax, index) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 0px',
        }}
      >
        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {index + 1}
        </p>
        <p
          style={{
            width: '17.5%',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {this.state.showInbox
            ? moment(fax.Date).format('lll')
            : moment(fax.DateSent).format('lll')}
        </p>

        {!this.state.showInbox && (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.SenderEmail}
          </p>
        )}

        {this.state.showInbox ? (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.CallerID} / {fax.RemoteID}
          </p>
        ) : (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.ToFaxNumber}
          </p>
        )}

        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {fax.Pages}
        </p>

        {/* Is this Status? */}
        <p
          style={{
            width: '10%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {this.state.showInbox ? fax.ReceiveStatus : fax.SentStatus}
        </p>

        {/* View Fax */}
        <FontAwesomeIcon
          onClick={() => {
            this.setState({ openDoc: true })
            if (fax.pdf)
              this.setState({
                testPDF: fax.pdf,
                selectedFile: fax,
                modalFileName: fax.FileName,
              })
            else this.renderFaxFromBackend(fax.FileName)
            // else this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faEye}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        />

        {/* Forward Fax */}
        <FontAwesomeIcon
          onClick={() => {
            this.setState({ forwardFax: true, sendModal: true })
            this.renderFaxFromBackend(fax.FileName)
            // this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faShare}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        />
        {this.state.showInbox && (
          <div style={{ width: '17.5%' }}>
            {/* lab, prior auth, e-consult, patient request, pathology, imaging, prescriptions, referral, health history, other */}
            <Select
              placeholder="select fax type"
              inputId="faxtype"
              /*-Procedures, -Orders, -Referrals, -Infusions, -Prior Authorization, -Lab Result,
                -Imaging Result, -Pathology Report, -Prescription, -Authorization Letter,
                -Doctor’s Note, -Disability Form, -Infusion Consent , -RPM Consent,
                -Patient Assistance Program, -Invoice, -Patient Request, -Chart Note, Other */
              options={[
                { label: 'Lab', value: 'Lab' },
                { label: 'Prior Authorization', value: 'Prior Authorization' },
                { label: 'E-consult', value: 'E-consult' },
                { label: 'Patient request', value: 'Patient request' },
                { label: 'Pathology', value: 'Pathology' },
                { label: 'Imaging', value: 'Imaging' },
                { label: 'Prescriptions', value: 'Prescriptions' },
                { label: 'Referral', value: 'Referral' },
                { label: 'Health history', value: 'Health history' },
                { label: 'Procedures', value: 'Procedures' },
                { label: 'Orders', value: 'Orders' },
                { label: 'Infusions', value: 'Infusions' },
                {
                  label: 'Authorization letter',
                  value: 'Authorization letter',
                },
                { label: 'Doctor’s note', value: 'Doctor’s note' },
                { label: 'Disability form', value: 'Disability form' },
                { label: 'Infusion consent', value: 'Infusion consent' },
                { label: 'RPM consent', value: 'RPM consent' },
                {
                  label: 'Patient assistance program',
                  value: 'Patient assistance program',
                },
                { label: 'Invoice', value: 'Invoice' },
                { label: 'Chart note', value: 'Chart note' },
                { label: 'Other', value: 'Other' },
              ]}
              value={
                fax.faxType ? { label: fax.faxType, value: fax.faxType } : []
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let config = {
                  method: 'put',
                  url: Config.BACKEND_URL + `srfax/archive?fax_id=${fax._id}`,
                  headers: {
                    Authorization: `JWT ${token}`,
                    x_firebase_id: mongoUser.firebase_id,
                  },
                  data: {
                    faxType: val.value,
                    // fax_id: fax._id,
                  },
                }
                axios(config)
                  .then(({ data }) => {
                    let tempFaxList = [...this.state.faxList]
                    tempFaxList[index].faxType = val.value
                    this.setState({ faxList: tempFaxList }, () =>
                      this.setState({ loading: false }),
                    )
                    if (val.value === 'Referral') {
                      window.open(
                        'https://portal.ailahealth.com/wix/aila/provider-referral',
                        '_blank',
                      )
                    }
                  })
                  .catch((err) => {
                    console.log('Error getting Faxes for the Patient ', err)
                    toast.error('Problem while assigning the fax')
                  })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )}

        {this.state.showInbox && (
          <FontAwesomeIcon
            onClick={() => {
              let config = {
                method: 'put',
                url: Config.BACKEND_URL + `srfax/archive?fax_id=${fax._id}`,
                headers: {
                  Authorization: `JWT ${token}`,
                  x_firebase_id: mongoUser.firebase_id,
                },
                data: {
                  archive: !fax.archive,
                  // fax_id: fax._id,
                },
              }
              axios(config)
                .then(({ data }) => {
                  let tempFaxList = [...this.state.faxList]
                  tempFaxList[index].archive = !tempFaxList[index]?.archive

                  selectedTab === 'Inbox'
                    ? this.setState(
                        {
                          faxList: tempFaxList.filter(
                            (item) =>
                              !item.hasOwnProperty('archive') ||
                              item?.archive === false,
                          ),
                        },
                        () => this.setState({ loading: false }),
                        toast.success('Fax moved to Archive'),
                      )
                    : this.setState(
                        {
                          faxList: tempFaxList.filter(
                            (item) => item.archive === true,
                          ),
                        },
                        () => this.setState({ loading: false }),
                        toast.success('Fax unarchived'),
                      )

                  // this.setState(
                  //   { faxList: tempFaxList },
                  //   () => this.setState({ loading: false }),
                  //   toast.success('Fax moved to Archive'),
                  // )
                })
                .catch((err) => {
                  console.log('Error getting Faxes for the Patient ', err)
                  toast.error('Problem while assigning the fax')
                })
            }}
            icon={selectedTab === 'Inbox' ? faCheckCircle : faMinusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              width: '10%',
              cursor: 'pointer',
            }}
          />
        )}

        {this.state.showInbox && (
          <div style={{ width: '17.5%' }}>
            <Select
              placeholder="select patient"
              inputId="name"
              options={this.state.patientListOptions}
              value={
                fax.patient_id
                  ? this.state.patientListOptions.find(
                      (patient) => patient.value === fax.patient_id,
                    )
                  : []
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let config = {
                  method: 'put',
                  url: Config.BACKEND_URL + `srfax/patient`,
                  headers: {
                    Authorization: `JWT ${token}`,
                    x_firebase_id: mongoUser.firebase_id,
                  },
                  data: {
                    patient_id: val.value,
                    fax_id: fax._id,
                  },
                }
                axios(config)
                  .then(({ data }) => {
                    let tempFaxList = [...this.state.faxList]
                    tempFaxList[index].patient_id = val.value
                    this.setState({ faxList: tempFaxList }, () =>
                      this.setState({ loading: false }),
                    )
                  })
                  .catch((err) => {
                    console.log('Error getting Faxes for the Patient ', err)
                    toast.error('Problem while assigning the fax')
                  })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )}
      </div>
    ))
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: '1px solid gray',
          }}
        >
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Sl No.
          </h5>
          <h5
            style={{
              width: '17.5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            {this.state.showInbox ? `Imported Date` : `Date Sent`}
          </h5>
          {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Sender Email
            </h5>
          )}
          {this.state.showInbox ? (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              From FAX / Remote ID
            </h5>
          ) : (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Recipient Fax
            </h5>
          )}
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Pages
          </h5>
          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Status
          </h5>

          {/* {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Reciever Fax
            </h5>
          )} */}

          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            View
          </h5>
          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Forward
          </h5>
          {this.state.showInbox && (
            <h5
              style={{
                width: '17.5%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Fax Type
            </h5>
          )}
          {this.state.showInbox && (
            <h5
              style={{
                width: '10%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              {selectedTab === 'Inbox' ? 'Archive' : 'Unarchive'}
              {/* Archive */}
            </h5>
          )}
          {this.state.showInbox && (
            <h5
              style={{
                width: '17.5%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Assign
            </h5>
          )}
        </div>

        {elements}
      </div>
    )
  }

  render() {
    const { mongoUser, firebaseUser } = this.props
    let { superUser } = this.state
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px',
        }}
      >
        <Header header={'Faxes'} />

        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: 300,
              height: 40,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '2vw',
            }}
          >
            <div
              onClick={() =>
                this.setState({ showInbox: true, selectedTab: 'Inbox' }, () =>
                  this.getInbox(false),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Inbox'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color: this.state.selectedTab === 'Inbox' ? 'white' : 'black',
                }}
              >
                Inbox
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({ showInbox: false, selectedTab: 'Outbox' }, () =>
                  this.getOutbox(),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Outbox'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Outbox' ? 'white' : 'black',
                }}
              >
                Outbox
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({ showInbox: true, selectedTab: 'Archive' }, () =>
                  this.getInbox(true),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Archive'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Archive' ? 'white' : 'black',
                }}
              >
                Archive
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', position: 'absolute', right: 0 }}>
            {firebaseUser?.superUser && (
              <Button
                onClick={() => {
                  this.setState({ superUser: !superUser, loading: true }, () =>
                    this.getPatientList(),
                  )
                }}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{
                  width: 200,
                  marginRight: '2vw',
                  right: 0,
                  position: 'relative',
                }}
                variant="primary"
              >
                {superUser ? 'Super User' : 'Provider'}
              </Button>
            )}
            <Button
              onClick={() => this.setState({ sendModal: true })}
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{
                width: 200,
                marginRight: '2vw',
                right: 0,
                position: 'relative',
              }}
              variant="primary"
            >
              Send
            </Button>
          </div>
        </div>

        {this.state.loading ? (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        ) : (
          <div
            className={GlobalStyles.contentWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh',
              overflow: 'scroll',
              // width: '100%',
            }}
          >
            {this.state.showInbox ? (
              this.state.faxList.length !== 0 ? (
                this.renderDocuments(
                  this.state.showInbox,
                  this.state.selectedTab,
                )
              ) : (
                <p
                  style={{ width: '100%', textAlign: 'center', marginTop: 20 }}
                >
                  No imported documents
                </p>
              )
            ) : this.state.outboxList.length !== 0 ? (
              this.renderDocuments(this.state.showInbox, this.state.selectedTab)
            ) : (
              <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                No imported documents
              </p>
            )}
          </div>
        )}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.openDoc}
          onRequestClose={() => this.onModalHide()}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          {this.state.modalLoading ? (
            <div className={GlobalStyles.container} style={{ height: '100vh' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '100%',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            <iframe
              style={{ height: '100%', width: '100%' }}
              src={`data:application/pdf;base64,${this.state.testPDF}`}
            />
          )}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.sendModal}
          onRequestClose={() => this.onModalHide()}
          style={sendFaxModalStyles}
          contentLabel="Modal"
        >
          {this.state.modalLoading ? (
            <div className={GlobalStyles.container} style={{ height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '100%',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            this.sendFaxModal()
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,

    patient: state.patientReducer.patient,

    token: state.authReducer.token,

    patientList: state.patientReducer.patientList,

    mongoUser: state.userReducer.mongoUser,

    adminId: state.userReducer.adminId,

    patientCore: state.patientReducer.patientCore,

    unreadNotifications: state.userReducer.unreadNotifications,

    allNotifications: state.userReducer.allNotifications,

    firebaseUser: state.userReducer.firebaseUser,
  }
}

const mapDispatchToProps = {
  getUnreadMessages,
  addPatientLP,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(Faxes)
