import { combineReducers } from 'redux'
import actionTypes from './actionTypes.js'

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_AUTH_TOKEN: {
      state = { ...state, token: action.payload }
      return state
    }
    case actionTypes.ADD_E3: {
      state = { ...state, e3: action.payload }
      return state
    }
    default:
      return state
  }
}

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGGED_IN: {
      state = { ...state, loggedIn: action.payload }
      return state
    }
    case actionTypes.FROM_WEB_FLOW: {
      state = { ...state, fromWebFlow: action.payload }
      return state
    }
    case actionTypes.ADD_FIREBASE_AUTH_USER: {
      state = { ...state, firebaseAuthUser: action.payload }
      return state
    }
    case actionTypes.ADD_FIREBASE_USER: {
      state = { ...state, firebaseUser: action.payload }

      return state
    }
    case actionTypes.ADD_MONGO_USER: {
      state = { ...state, mongoUser: action.payload }
      return state
    }
    case actionTypes.ADD_PROVIDER_DATA: {
      state = { ...state, providerData: action.payload }
      return state
    }
    case actionTypes.ADD_TEAM_MONGO_USER: {
      state = { ...state, teamMongoUser: action.payload }
      return state
    }
    case actionTypes.UPDATE_SIDENAV_RENDER: {
      state = { ...state, render: action.payload }
      return state
    }
    case actionTypes.ADD_DOCTOR_INVITES: {
      state = { ...state, doctorInvites: action.payload }
      return state
    }
    case actionTypes.ADD_USER_TYPE: {
      state = { ...state, userType: action.payload }
      return state
    }
    case actionTypes.ADD_ADMIN_ID: {
      state = { ...state, adminId: action.payload }
      return state
    }
    case actionTypes.GET_UNREAD_NOTIFICATION: {
      state = { ...state, unreadNotifications: action.payload }
      return state
    }
    case actionTypes.GET_ALL_NOTIFICATION: {
      state = { ...state, allNotifications: action.payload }
      return state
    }
    default:
      return state
  }
}

const patientReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_PATIENT: {
      state = {
        ...state,
        patient: action.payload.patient,
        color: action.payload.color,
      }
      return state
    }
    case actionTypes.ADD_PATIENT_CORE_DATA: {
      state = { ...state, patientCore: action.payload }
      return state
    }
    case actionTypes.SET_PATIENT_CARETEAM: {
      state = { ...state, patientCareTeam: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_MEDICATIONS: {
      state = { ...state, medications: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_CONDITIONS: {
      state = { ...state, conditions: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_GOALS: {
      state = { ...state, goals: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_PROGRESS_DATA: {
      state = { ...state, patientProgress: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_LANDING_PAGE: {
      state = { ...state, lp: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_WELLNESS_SCREENING_DATA: {
      state = { ...state, patientWellnessScreening: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_CALCULATED_PROGRESS_DATA: {
      state = { ...state, patientCalculatedProgress: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_LIST: {
      state = { ...state, patientList: action.payload }
      return state
    }
    case actionTypes.UPDATE_TIME: {
      state = { ...state, timer: action.payload }
      return state
    }
    case actionTypes.ADD_RPM_PATIENT_PROFILE: {
      state = { ...state, rpmPatientId: action.payload }
      return state
    }
    case actionTypes.ADD_PATIENT_LABS: {
      state = { ...state, labs: action.payload }
      return state
    }
    case actionTypes.ADD_CAREPLAN_DATA: {
      state = { ...state, patientCarePlanData: action.payload }
      return state
    }
    case actionTypes.ADD_RPM_ORDERS: {
      state = { ...state, rpmOrders: action.payload }
      return state
    }
    default:
      return state
  }
}

const popReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_FILLED_DATA:
      state = { ...state, filledData: action.payload }
      return state
    case actionTypes.ADD_SPARSE_DATA:
      state = { ...state, sparseData: action.payload }
      return state
    case actionTypes.ADD_CCFM_PATIENT_DATA:
      state = { ...state, patientData: action.payload }
      return state
    default:
      return state
  }
}

const appointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_APPOINTMENT_DATA:
      state = {
        ...state,
        appointmentData: action.appointmentData,
        patientData: action.patientData,
      }
      return state
    case actionTypes.APPOINTMENT_NOTES_DATA:
      state = { ...state, appointmentNotesData: action.payload }
      return state
    default:
      return state
  }
}

const genericReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_NAVIGATION_OBJECT:
      state = { ...state, navigation: action.payload }
      return state
    case actionTypes.TOGGLE_SIDE_BAR:
      console.log("state", state, action)
      state = { ...state, hideSideBar: action.payload }
      return state
    case actionTypes.TOGGLE_NOTES_MODAL:
      state = { ...state, openNotesModal: action.payload }
      return state
    case actionTypes.TOGGLE_VIDEO_MODAL:
      state = { ...state, showVideoModal: action.payload, videoTime: action.videoTime }
      return state
    case actionTypes.TOGGLE_PATIENT_VIDEO_CALL:
      state = { ...state, showPatientVideoCall: action.payload }
      return state
    case actionTypes.ADD_TWILIO_TOKEN:
      state = { ...state, twilioToken: action.payload }
      return state
    default:
      return state
  }
}

const healthReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CARDIOVASCULAR_DATA:
      state = { ...state, healthData: action.payload }
      return state
    default:
      return state
  }
}

export default combineReducers({
  authReducer,
  userReducer,
  patientReducer,
  popReducer,
  genericReducer,
  healthReducer,
  appointmentReducer,
})
