import moment from 'moment'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-date-picker'
import FancyField from 'react-fancy-field'
import Modal from 'react-modal'
import Slider from 'react-rangeslider'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import zipcodes from 'zipcodes'
import BrainIcon from '../../assets/images/brain_icon.png'
import CardioIcon from '../../assets/images/cardio_icon.png'
import Image3 from '../../assets/images/covidresources.png'
import EndocrineIcon from '../../assets/images/endocrine_icon.png'
import GeneralHealthIcon from '../../assets/images/general_health_icon.png'
import JointIcon from '../../assets/images/joint_icon.png'
import Image1 from '../../assets/images/longhauler.png'
import LungIcon from '../../assets/images/lung_icon.png'
import MentalHealthIcon from '../../assets/images/mental_health_icon.png'
import Image2 from '../../assets/images/postcovidteam.png'
import StomachIcon from '../../assets/images/stomach_icon.png'
import Footer from '../../components/Footer'
import Progress_bar from '../../components/ProgressBar'
import Config from '../../config'
import values from '../../values'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/AilaLongCovid.module.scss'

import { connect } from 'react-redux'
import {
  addFirebaseAuthUser,
  addFirebaseUser,
  addPatient,
  fetchPatientCoreDate,
} from '../../redux/actions'

import { firebase } from '../../services/firebase'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Hits,
  InstantSearch,
  RefinementList,
  SearchBox,
} from 'react-instantsearch-dom'
import 'react-rangeslider/lib/index.css'
import './rangeSlider.css'

// import DatePicker from '../../components/DatePicker'

const axios = require('axios')

// const searchClient = (
//   Config.ALGOLIA_APP_ID,
//   // Config.ALGOLIA_API_KEY,
// )

const condnClient = {
  // search(requests) {
  //   if (requests[0].params.query === '') {
  //     return []
  //   }
  //   return searchClient.search(requests)
  // },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '55%',
    height: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
}

const horizontalLabels = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
}

const urlSocial = 'https://www.longcovid.io/'
const socialTitle = `I just completed this symptom checker to help me get support for my Long Covid symptoms.`

class AilaLongCovid extends Component {
  constructor() {
    super()
    this.state = {
      selectedQuestion: 0,
      progressPercent: 0,
      showIntro: true,
      showThankyou: false,
      underAge: true,
      covidHistory: [
        '',
        '',
        [
          { name: 'PCR', isChecked: false },
          { name: 'Antigen', isChecked: false },
          { name: 'Antibody', isChecked: false },
          { name: 'T-Cell test', isChecked: false },
          {
            name: 'I dont know what type of test I took',
            isChecked: false,
          },
        ],
        '',
        { mm: null, ww: null, dd: null },
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        [
          { name: 'General Medicine/ Primary care provider', isChecked: false },
          { name: 'Cardiologist', isChecked: false },
          { name: 'Pulmonologist', isChecked: false },
          { name: 'Endocrinologist', isChecked: false },
          { name: 'Neurologist', isChecked: false },
          { name: 'Infectious Disease Specialist', isChecked: false },
          { name: 'Allergist/Immunologist', isChecked: false },
          { name: 'Gastroenterologist/Hepatologist', isChecked: false },
          { name: 'Hematologist', isChecked: false },
          { name: 'Rheumatologist', isChecked: false },
          { name: 'Nephrologist', isChecked: false },
          { name: 'Urologist', isChecked: false },
          { name: 'Dermatologist', isChecked: false },
          { name: 'Ophthalmologist', isChecked: false },
          { name: 'Ear Nose and Throat Specialist', isChecked: false },
          { name: 'Dentist', isChecked: false },
          // { name: 'Physiatrist', isChecked: false },
          { name: 'Physical Therapist', isChecked: false },
          { name: 'Occupational Therapist', isChecked: false },
          { name: 'Speech Pathologist', isChecked: false },
          { name: 'Sleep Specialist', isChecked: false },
          { name: 'Radiologist', isChecked: false },
          { name: 'Psychiatrist', isChecked: false },
          { name: 'Psychologist', isChecked: false },
          { name: 'General Surgeon', isChecked: false },
        ],
        '',
        '',
        '',
      ],
      headNeuro: [
        [
          {
            name: 'Dental Issues (e.g. broken or cracked teeth)',
            isChecked: false,
          },
          // { name: 'Ear ache', isChecked: false },
          // { name: 'Eye pain', isChecked: false },
          { name: 'Migraines', isChecked: false },
          { name: 'Recurrent headaches', isChecked: false },
          { name: 'Sinus pain', isChecked: false },
          // { name: 'Sores in Mouth', isChecked: false },
          // { name: 'Vision problems', isChecked: false },
          { name: 'Brain Fog', isChecked: false },
          { name: 'Convulsions', isChecked: false },
          {
            name: 'Difficulty with speaking or using the wrong word',
            isChecked: false,
          },
          { name: 'Impaired memory', isChecked: false },
          { name: 'Shaking/Tremor', isChecked: false },
          { name: 'Tingling/Pins and needles feeling', isChecked: false },
          // { name: 'Tremor', isChecked: false },
          { name: 'Trouble Concentrating', isChecked: false },
          { name: 'Seizures', isChecked: false },
          { name: 'Weakness of arms', isChecked: false },
          { name: 'Weakness of legs', isChecked: false },
          { name: 'Numbness', isChecked: false },
          { name: 'Trouble standing', isChecked: false },
          { name: 'Trouble walking', isChecked: false },
          { name: 'Dysautonomia, or “POTS”', isChecked: false },
          { name: 'Lightheadedness', isChecked: false },
          { name: 'Dizziness ', isChecked: false },
          { name: 'Vertigo', isChecked: false },
          { name: 'Confusion', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      earThroat: [
        [
          {
            name: 'Sore throat',
            isChecked: false,
          },
          { name: 'Sinus pain/congestion', isChecked: false },
          { name: 'Nose bleeds', isChecked: false },
          { name: 'Earaches', isChecked: false },
          { name: 'Hearing loss', isChecked: false },
          { name: 'Tinnitus (ringing in ears)', isChecked: false },
          { name: 'Eye pain', isChecked: false },
          { name: 'Vision loss', isChecked: false },
          { name: 'Mouth sores', isChecked: false },
          { name: 'Dental issues', isChecked: false },
          {
            name: 'Difficulty swallowing',
            isChecked: false,
          },
          { name: 'Hoarse voice', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      cardioVascular: [
        [
          { name: 'High blood pressure', isChecked: false },
          { name: 'Low blood pressure', isChecked: false },
          {
            name: 'Palpitations (heart “fluttering” or skipping beats)',
            isChecked: false,
          },
          { name: 'Fast heart rate', isChecked: false },
          { name: 'Slow heart rate', isChecked: false },
          { name: 'Irregular Heart Rhythm', isChecked: false },
          { name: 'Chest pain or tightness', isChecked: false },
          { name: 'Passing out', isChecked: false },
          {
            name: 'Pain/ tightness in the legs when walking',
            isChecked: false,
          },
          { name: 'New or worsened varicose veins', isChecked: false },
          { name: 'Change in color of hands/ feet', isChecked: false },
          { name: 'Swelling of the legs/ feet', isChecked: false },
          {
            name: 'Shortness of breath when laying down flat',
            isChecked: false,
          },
          { name: 'Elevated cholesterol', isChecked: false },
          // { name: 'Tightness of chest', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],

      respiRatory: [
        [
          { name: 'Shortness of breath', isChecked: false },
          { name: 'Pain in chest when taking a deep breath', isChecked: false },
          { name: 'Cough', isChecked: false },
          { name: 'Coughing up blood', isChecked: false },
          { name: 'Wheezing', isChecked: false },
          {
            name: 'Stopping breathing/ gasping for air when sleeping',
            isChecked: false,
          },
          { name: 'Snoring', isChecked: false },
          // { name: 'Difficulty breathing', isChecked: false },
          { name: 'Low oxygen levels/saturation at rest', isChecked: false },
          {
            name: 'Low oxygen levels/saturation with movement',
            isChecked: false,
          },
          // { name: 'Lung pain', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      stomachDigestive: [
        [
          // { name: 'Acid reflux', isChecked: false },
          { name: 'Abdominal pain', isChecked: false },
          { name: 'Change in stool', isChecked: false },
          {
            name: 'Jaundice (whites of eyes, skin look yellow)',
            isChecked: false,
          },
          { name: 'Bloating', isChecked: false },
          // { name: 'Burning in the throat', isChecked: false },
          { name: 'Burning pain in stomach', isChecked: false },
          { name: 'Constipation', isChecked: false },
          { name: 'Cramping', isChecked: false },
          { name: 'Diarrhea', isChecked: false },
          { name: 'Food allergies', isChecked: false },
          { name: 'Gas', isChecked: false },
          { name: 'Heartburn', isChecked: false },
          { name: 'Loss of appetite', isChecked: false },
          { name: 'Nausea', isChecked: false },
          { name: 'Stabbing pain in stomach', isChecked: false },
          { name: 'Stomach pain', isChecked: false },
          { name: 'Upper stomach pain', isChecked: false },
          { name: 'Vomiting', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      endoCrine: [
        [
          { name: 'Changes in menstrual period cycles', isChecked: false },
          { name: 'Feeling cold easily', isChecked: false },
          { name: 'Feeling hot and sweaty', isChecked: false },
          { name: 'High blood sugar', isChecked: false },
          { name: 'Low blood sugar', isChecked: false },
          // { name: 'Lower than average body temperature', isChecked: false },
          { name: 'Thinning hair', isChecked: false },
          { name: 'Weight gain', isChecked: false },
          { name: 'Weight loss', isChecked: false },
          { name: 'Infertility', isChecked: false },
          { name: 'Diabetes diagnosis', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      jointPain: [
        [
          { name: 'Body Pain (all over)', isChecked: false },
          {
            name: 'Hypermobility (Are you extremely flexible?)',
            isChecked: false,
          },
          { name: 'Inflexibility', isChecked: false },
          { name: 'Back pain', isChecked: false },
          { name: 'Joint Pain', isChecked: false },
          { name: 'Muscle aches/ tenderness', isChecked: false },
          { name: 'Muscle weakness', isChecked: false },
          { name: 'Nerve pain', isChecked: false },
          { name: 'Numbness and tingling', isChecked: false },
          { name: 'Paralysis', isChecked: false },
          { name: 'Stiffness', isChecked: false },
          { name: 'Swelling in the joints', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      mentalHealth: [
        [
          { name: 'Anxiety', isChecked: false },
          { name: 'Depression', isChecked: false },
          { name: 'Hallucinations', isChecked: false },
          { name: 'Mood swings', isChecked: false },
          // { name: 'Nervousness', isChecked: false },
          { name: 'Psychosis', isChecked: false },
          { name: 'Personality changes', isChecked: false },
          { name: 'PTSD (Post traumatic stress)', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      generalHealth: [
        [
          { name: 'Chills', isChecked: false },
          { name: 'Cold hands or feet', isChecked: false },
          { name: 'Dizziness on standing (lightheadedness)', isChecked: false },
          { name: 'Excessive Thirst', isChecked: false },
          { name: 'Fatigue', isChecked: false },
          { name: 'Fever', isChecked: false },
          { name: 'Feeling like you’re going to faint', isChecked: false },
          { name: 'Itchy skin', isChecked: false },
          { name: 'Loss of smell', isChecked: false },
          { name: 'Loss of taste', isChecked: false },
          // { name: 'Low energy', isChecked: false },
          // { name: 'Low-grade fever', isChecked: false },
          { name: 'Rash', isChecked: false },
          // { name: 'Recurrent fever', isChecked: false },
          { name: 'Restless Sleep/ Insomnia', isChecked: false },
          // { name: 'Sore throat', isChecked: false },
          { name: 'Malaise (feeling like you have the flu)', isChecked: false },
          { name: 'Other', isChecked: false },
        ],
        [],
        '',
        '',
      ],
      generalAbility: ['', '', '', '', '', '', ''],
      yourInfo: ['', '', new Date(), '', '', '', ''],
      questionset: {
        0: [
          'Did you have Covid-19?',

          'Did you receive a Covid test?',

          'What type of test did you receive?',

          'When did your initial Covid symptoms start?',
          'How long were you sick with the initial Covid infection?',

          'Were you hospitalized as a result of a Covid-19 infection?',
          'How would you rate the severity of your initial Covid infection?',
        ],
        1: [
          'Did you recover from your initial Covid infection?',
          'Did symptoms return or did you develop new persistent symptoms after your initial Covid infection?',
          'Please rate the overall severity of your Long Covid symptoms on a scale of 1-10?',
          'Were you hospitalized as a result of persistent Long Covid Symptoms?',
          'If you recovered from your initial infection, how long after did you start experiencing long COVID symptoms?',
        ],
        2: [
          'Which of the following care providers have you seen to manage your Long Covid symptoms?',
          'Have you been diagnosed with long COVID or PASC (Post-Acute Sequelae of COVID-19) by a healthcare provider?',
          'Have you received the Covid-19 vaccine?',
          'Are any of your family members also experiencing long Covid symptoms?',
        ],
        3: [
          'Which of the following head and neurological symptoms are you experiencing?',
          'Please add any head and neurological symptoms you are experiencing here:',
          'Please rate the overall severity of your head and neurological symptoms on a scale of 1-10?',
          'Please add any additional notes about your head and neurological symptoms here.',
        ],
        4: [
          'Which of the following ear, nose and throat symptoms are you experiencing?',
          'Please add any ear, nose and throat symptoms you are experiencing here:',
          'Please rate the overall severity of your ear, nose and throat symptoms on a scale of 1-10?',
          'Please add any additional notes about your ear, nose and throat symptoms here.',
        ],
        5: [
          'Which of the following cardiovascular symptoms are you experiencing?',
          'Please add any other cardiovascular symptoms you are experiencing here:',
          'Please rate the overall severity of your cardiovascular symptoms on a scale of 1-10? ',
          'Please add any additional notes about your cardiovascular symptoms here.',
        ],
        6: [
          'Which of the following respiratory symptoms are you experiencing?',
          'Please add any other respiratory symptoms you are experiencing here:',
          'Please rate the overall severity of your respiratory symptoms on a scale of 1-10?',
          'Please add any additional notes about your respiratory symptoms here.',
        ],
        7: [
          'Which of the following stomach and digestive symptoms are you experiencing?',
          'Please add any stomach and digestive symptoms you are experiencing here:',
          'Please rate the overall severity of your stomach and digestive symptoms on a scale of 1-10?',
          'Please add any additional notes about your stomach and digestive symptoms here.',
        ],
        8: [
          'Which of the following endocrine symptoms are you experiencing?',
          'Please add any other endocrine symptoms you are experiencing here:',
          'Please rate the overall severity of your endocrine symptoms on a scale of 1-10? ',
          'Please add any additional notes about your endocrine symptoms here.',
        ],
        9: [
          'Which of the following joint and muscular pain symptoms are you experiencing?',
          'Please add any joint and muscular pain symptoms you are experiencing here:',
          'Please rate the overall severity of your joint and muscular pain symptoms on a scale of 1-10?',
          'Please add any additional notes about your joint and muscular pain symptoms here.',
        ],
        10: [
          'Which of the following mental health symptoms are you experiencing?',
          'Please add any other mental health symptoms you are experiencing here:',
          'Please rate the overall severity of your mental health symptoms on a scale of 1-10?',
          'Please add any additional notes about your mental health symptoms here.',
        ],
        11: [
          'Which of the following others symptoms are you experiencing?',
          'Please add any other symptoms you are experiencing here:',
          'Please rate the overall severity of your other symptoms on a scale of 1-10?',
          'Please add any additional notes about your other symptoms here.',
        ],
        12: [
          'Have you been able to keep the same job you had before you got sick?',
          'Have you had to reduce your hours at work since you got sick?',
          'Are you physically able to manage your household chores and responsibilities independently?',
          'Have you applied for disability?',
          'Have you experienced financial difficulties as a result of your illness',
          'Overall, what was your quality of life before Long Covid?',
          'Overall, what was your quality of life after having Long Covid?',
        ],
        13: [
          'What is your name?',
          'Date of Birth:',
          'Zip Code:',
          'What gender were you at birth:',
          'Please indicate your ethnicity:',
          'Are you living with any other chronic conditions?',
        ],
      },

      successorUrl: '',
      error: false,
      errorString: '',
      neuroSymptoms: [],
      earSymptoms: [],
      cardioSymptoms: [],
      respiSymptoms: [],
      stomachSymptoms: [],
      endocrineSymptoms: [],
      jointSymptoms: [],
      mentalSymptoms: [],
      generalSymptoms: [],
      infoSymptoms: [],
      earlySymp: [],
      mainSymp: [],
      medications: [],
      suplements: [],
      searchQuery: {
        neuro: '',
        cardio: '',
        respiro: '',
        stomo: '',
        endo: '',
        joint: '',
        mental: '',
        general: '',
        info: '',
        early: '',
        main: '',
        medi: '',
        sup: '',
      },
      showHelplineModal: false,
      stateResidence: 'undefined',
      counter: 0,
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.onNextPressed = this.onNextPressed.bind(this)
    this.onSubmitPressed = this.onSubmitPressed.bind(this)
    this.storeUsertoMongo = this.storeUsertoMongo.bind(this)
    this.getCounterValue = this.getCounterValue.bind(this)
    this.saveDetailsToFirebase = this.saveDetailsToFirebase.bind(this)
    this.onPreviousPressed = this.onPreviousPressed.bind(this)
    this.getAnswerOptions = this.getAnswerOptions.bind(this)
  }

  componentDidMount() {
    // if (!this.props.patient) {
    //   this.props.history.push('/signup/long-covid')
    //   return
    // }
    // console.log('inside questionnaire', this.props.patient)
    window.scrollTo(0, 0)
    this.getCounterValue()

    // if(!this.state.careteam)
    //   this.getUserCareteam()
  }

  onPreviousPressed() {
    let { covidHistory, selectedQuestion } = this.state
    this.setState({
      searchQuery: {
        neuro: '',
        cardio: '',
        respiro: '',
        stomo: '',
        endo: '',
        joint: '',
        mental: '',
        general: '',
        info: '',
        early: '',
        main: '',
        medi: '',
        sup: '',
      },
    })
    if (selectedQuestion === 10)
      this.setState({
        selectedQuestion: selectedQuestion - 1,
        showHelplineModal: true,
      })
    if (this.state.selectedQuestion <= 0) this.setState({ selectedQuestion: 0 })
    else {
      // selectedQuestion === 3
      //   ? covidHistory[7] === 'Yes, symptoms returned' ||
      //     covidHistory[7] === 'Yes, I developed new symptoms'
      //     ? this.setState({
      //         selectedQuestion: selectedQuestion - 1,
      //       })
      //     : this.setState({
      //         selectedQuestion: selectedQuestion - 2,
      //       })
      //   :
      this.setState({ selectedQuestion: this.state.selectedQuestion - 1 })
    }
    window.scrollTo(0, 0)
  }

  onNextPressed() {
    let { covidHistory, selectedQuestion } = this.state
    this.setState({
      searchQuery: {
        neuro: '',
        cardio: '',
        respiro: '',
        stomo: '',
        endo: '',
        joint: '',
        mental: '',
        general: '',
        info: '',
        early: '',
        main: '',
        medi: '',
        sup: '',
      },
    })
    if (selectedQuestion === 9)
      this.setState({
        selectedQuestion: selectedQuestion + 1,
        showHelplineModal: true,
      })
    if (selectedQuestion >= Object.keys(this.state.questionset).length)
      this.setState({
        selectedQuestion: Object.keys(this.state.questionset).length,
      })
    else {
      // selectedQuestion === 1
      //   ? covidHistory[7] === 'Yes, symptoms returned' ||
      //     covidHistory[7] === 'Yes, I developed new symptoms'
      //     ? this.setState({
      //         selectedQuestion: selectedQuestion + 1,
      //       })
      //     : this.setState({
      //         selectedQuestion: selectedQuestion + 2,
      //       })
      //   :
      this.setState({
        selectedQuestion: selectedQuestion + 1,
      })
    }
    window.scrollTo(0, 0)
  }

  getCounterValue = () => {
    const { token, patient } = this.props

    let url = Config.BACKEND_URL + 'longcovid/questionnaire'
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: `JWT ${token}` },
    })
      .then((res) => {
        // console.log(res.data, res.data.length)
        this.setState({ counter: res.data.length })
      })
      .catch((err) => {
        console.log('error when posting patient web quess', err)
      })
  }

  storeUsertoMongo = (firebaseID, token, longCovidData) => {
    let { yourInfo } = this.state
    let data = {
      firebase_id: firebaseID,
      gender: yourInfo[4],
      dob: yourInfo[2],
      ethnicity: yourInfo[5],
    }
    let config = {
      method: 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: firebaseID },
      url: `${Config.BACKEND_URL}users`,
      data: data,
    }
    axios(config)
      .then((resp) => {
        console.log('saved mongo data')
        this.props.fetchPatientCoreDate(firebaseID, token)
      })
      .catch((err) => {
        console.log('error when saving data', err)
      })

    let url = Config.BACKEND_URL + 'longcovid/questionnaire'
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: `JWT ${token}` },
      data: longCovidData,
    })
      .then(() => {
        console.log('saved questionnaire')
      })
      .catch((err) => {
        console.log('error when posting patient web quess', err)
      })
  }

  saveDetailsToFirebase = (user_uid, token, longCovidData) => {
    let { patient } = this.props
    let { yourInfo } = this.state
    let ccfmDeeplink = localStorage.getItem('ccfm')
    let dacPilotDeeplink = localStorage.getItem('dac')
    let raPilotDeeplink = localStorage.getItem('rapilot')

    let pincode =
      yourInfo[3] && yourInfo[3].length > 0 ? Number(yourInfo[3].trim()) : ''
    let zipCodeData = zipcodes.lookup(pincode)
    this.setState({ stateResidence: zipCodeData?.state || 'undefined' })

    let toSaveObject = {
      email: patient.email.trim(),
      firstName: yourInfo[0].trim(),
      lastName: yourInfo[1].trim(),
      uid: user_uid,
      lastSignedIn: new Date(),
      platform: 'web',
      signup_platform: 'web',
      flow_type: 'long-covid',
      zipcode:
        yourInfo[3] && yourInfo[3].length > 0 ? Number(yourInfo[3].trim()) : '',
      zipCodeData: zipCodeData || null,
    }

    if (ccfmDeeplink === 'yes') {
      toSaveObject.ccfm = true
      toSaveObject.userType = 'ccfm-patient'
    }

    if (dacPilotDeeplink === 'yes') {
      toSaveObject.dac = true
      toSaveObject.userType = 'dac-patient'
    }

    if (raPilotDeeplink === 'yes') {
      toSaveObject.rapilot = true
      toSaveObject.userType = 'ra-pilot-patient'
    }

    firebase
      .firestore()
      .collection('users')
      .doc(user_uid)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          //user data is already present in firestore, do not save again
          firebase
            .firestore()
            .collection('users')
            .doc(user_uid)
            .update(toSaveObject)
            .then(() => {
              console.log('updated user last signed in ')
              this.storeUsertoMongo(user_uid, token, longCovidData)
            })
            .catch((err) =>
              console.log('error when updating user last signed in', err),
            )
          // return
        } else {
          firebase
            .firestore()
            .collection('users')
            .doc(user_uid)
            .set(toSaveObject)
            .then(() => {
              console.log('Saved user to firestore')
              this.storeUsertoMongo(user_uid, token, longCovidData)
            })
            .catch((err) => {
              console.log(
                'somethign went wrong when saving user to firestore',
                err,
              )
            })
        }
      })
  }

  onSubmitPressed() {
    const { token, patient } = this.props

    let {
      // long covid questionnaire
      questionset,
      selectedQuestion,

      //ALL THE ADDED CONDITIONS
      neuroSymptoms,
      earSymptoms,
      cardioSymptoms,
      respiSymptoms,
      stomachSymptoms,
      endocrineSymptoms,
      jointSymptoms,
      mentalSymptoms,
      generalSymptoms,
      infoSymptoms,

      // ALL ANSWERS TO DIFFERENT SECTIONS
      covidHistory,
      headNeuro,
      earThroat,
      cardioVascular,
      respiRatory,
      stomachDigestive,
      endoCrine,
      jointPain,
      mentalHealth,
      generalHealth,
      generalAbility,
      yourInfo,
    } = this.state
    let temp1 = []
    let temp2 = []
    let temp3 = []

    //check under age
    let now = moment()
    let dobMoment = moment(yourInfo[2])
    if (now.diff(dobMoment, 'years') < 18) {
      console.log('underage')
      this.setState({ underAge: true }, () =>
        this.setState({ showThankyou: true }),
      )
    } else {
      this.setState({ underAge: false, showThankyou: true })
    }

    let covidTestType = []
    let care_providers = []
    let head_neuro_symptoms = []
    let ear_throat_symptoms = []
    let cardio_symptoms = []
    let respiratory_symptoms = []
    let stomach_symptoms = []
    let endocrine_symptoms = []
    let joint_symptoms = []
    let mental_symptoms = []
    let general_symptoms = []

    covidHistory[2].forEach((val) => {
      if (val.isChecked === true) covidTestType.push(val.name)
    })
    covidHistory[12].forEach((val) => {
      if (val.isChecked === true) care_providers.push(val.name)
    })

    headNeuro[0].forEach((val) => {
      if (val.isChecked === true) head_neuro_symptoms.push(val.name)
    })
    earThroat[0].forEach((val) => {
      if (val.isChecked === true) ear_throat_symptoms.push(val.name)
    })
    cardioVascular[0].forEach((val) => {
      if (val.isChecked === true) cardio_symptoms.push(val.name)
    })
    respiRatory[0].forEach((val) => {
      if (val.isChecked === true) respiratory_symptoms.push(val.name)
    })
    stomachDigestive[0].forEach((val) => {
      if (val.isChecked === true) stomach_symptoms.push(val.name)
    })
    endoCrine[0].forEach((val) => {
      if (val.isChecked === true) endocrine_symptoms.push(val.name)
    })
    jointPain[0].forEach((val) => {
      if (val.isChecked === true) joint_symptoms.push(val.name)
    })
    mentalHealth[0].forEach((val) => {
      if (val.isChecked === true) mental_symptoms.push(val.name)
    })
    generalHealth[0].forEach((val) => {
      if (val.isChecked === true) general_symptoms.push(val.name)
    })

    let data = {
      patient_id: patient?.uid,
      patientInfo: {
        conditions: infoSymptoms,
      },
      covidHistory: {
        covid: covidHistory[0],
        covid_test: covidHistory[1],
        covid_test_type: covidTestType,
        symptoms_start: covidHistory[3],
        duration_months: covidHistory[4].mm || 0,
        duration_weeks: covidHistory[4].ww || 0,
        duration_days: covidHistory[4].dd || 0,
        hospitalized: covidHistory[5],
        initial_severity: covidHistory[6],
        recover: covidHistory[7],
        persistent_symptoms: covidHistory[8],
        severity: covidHistory[9],
        hospitalized_symptoms: covidHistory[10],
        recover_long: covidHistory[11],
        care_providers: care_providers,
        pasc_diagnosis: covidHistory[13],
        covid_vaccine: covidHistory[14],
        covid_family: covidHistory[15],
      },
      headNeuro: {
        symptoms: head_neuro_symptoms,
        added_symptoms: neuroSymptoms,
        severity: headNeuro[2],
        notes: headNeuro[3],
      },
      earThroat: {
        symptoms: ear_throat_symptoms,
        added_symptoms: earSymptoms,
        severity: earThroat[2],
        notes: earThroat[3],
      },
      cardioVascular: {
        symptoms: cardio_symptoms,
        added_symptoms: cardioSymptoms,
        severity: cardioVascular[2],
        notes: cardioVascular[3],
      },
      respiRatory: {
        symptoms: respiratory_symptoms,
        added_symptoms: respiSymptoms,
        severity: respiRatory[2],
        notes: respiRatory[3],
      },
      stomachDigestive: {
        symptoms: stomach_symptoms,
        added_symptoms: stomachSymptoms,
        severity: stomachDigestive[2],
        notes: stomachDigestive[3],
      },
      endoCrine: {
        symptoms: endocrine_symptoms,
        added_symptoms: endocrineSymptoms,
        severity: endoCrine[2],
        notes: endoCrine[3],
      },
      jointPain: {
        symptoms: joint_symptoms,
        added_symptoms: jointSymptoms,
        severity: jointPain[2],
        notes: jointPain[3],
      },
      mentalHealth: {
        symptoms: mental_symptoms,
        added_symptoms: mentalSymptoms,
        severity: mentalHealth[2],
        notes: mentalHealth[3],
      },
      generalHealth: {
        symptoms: general_symptoms,
        added_symptoms: generalSymptoms,
        severity: generalHealth[2],
        notes: generalHealth[3],
      },
      generalAbility: {
        same_job: generalAbility[0],
        work_hours: generalAbility[1],
        household: generalAbility[2],
        disability: generalAbility[3],
        financial_difficulty: generalAbility[4],
        before_covid: generalAbility[5],
        after_covid: generalAbility[6],
      },
    }

    // console.log('Mongo long covid questionnaire saved::', data)

    this.saveDetailsToFirebase(patient.uid, token, data)

    this.setState({
      selectedQuestion: 0,
      progressPercent: 0,
      neuroSymptoms: [],
      earSymptoms: [],
      cardioSymptoms: [],
      respiSymptoms: [],
      stomachSymptoms: [],
      endocrineSymptoms: [],
      jointSymptoms: [],
      mentalSymptoms: [],
      generalSymptoms: [],
      infoSymptoms: [],
      earlySymp: [],
      mainSymp: [],
      medications: [],
      suplements: [],
      searchQuery: {
        neuro: '',
        cardio: '',
        respiro: '',
        stomo: '',
        endo: '',
        joint: '',
        mental: '',
        general: '',
        info: '',
        early: '',
        main: '',
        medi: '',
        sup: '',
      },
      showHelplineModal: false,
      showappointment: false,
    })
  }

  renderScheduleModal() {
    let { yourInfo } = this.state
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '25px',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
            margin: 'auto',
          }}
        >
          Aila Health Provides Comprehensive Multi-Specialty Care for Covid
          Longhaulers
          <br />
          <br />
          Click next to schedule an appointment with our care team
          <br />
        </p>

        <div
          style={{
            width: '50%',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.setState({ showappointment: false })
              this.props.history.push('/wix/aila/careteam', {
                stateResidence: this.state.stateResidence,
                flowType: 'LongCovid',
              })

              // this.props.history.push('/wix/aila/program', {
              //   programType: this.state.stateResidence,
              //   fName: yourInfo[0],
              //   lName: yourInfo[1],
              //   flowType: 'LongCovid',
              // })
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            Next
          </Button>
        </div>
        {/* </div> */}
      </div>
    )
  }

  renderHelplineModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '25px',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          Mental Health Resources Are Available
          <br />
        </p>

        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            width: '100%',
            overflow: 'hidden',
            paddingLeft: '5%',
            paddingRight: '5%',
          }}
        >
          <p
            style={{
              fontSize: '18px',
              // color: values.primaryThemeDark,
              textAlign: 'center',
              // fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              position: 'relative',
            }}
          >
            <br />
            Living with Long Covid symptoms and adapting to the changes brought
            on by a chronic illness can be challenging physically and
            emotionally.
            <br />
            <br />
            If you are experiencing thoughts of suicide, help is available. For
            immediate 24/7 support: Call the National Suicide Prevention
            Lifeline at 800-273-8255. Text NAMI to 741-741
          </p>
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // marginTop: '4vh',
            justifyContent: 'center',
            position: 'relative',
          }}
        > */}
        <div
          style={{
            width: '50%',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              // console.log('Payment modal closed !')
              window.scrollTo(0, 0)

              this.setState({ showHelplineModal: false })
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            Next
          </Button>
        </div>
        {/* </div> */}
      </div>
    )
  }

  getAnswerOptions(index) {
    let {
      questionset,
      selectedQuestion,
      covidHistory,
      headNeuro,
      earThroat,
      cardioVascular,
      respiRatory,
      stomachDigestive,
      endoCrine,
      jointPain,
      mentalHealth,
      generalHealth,
      generalAbility,
      yourInfo,
    } = this.state

    // to handle the Selection of symptoms, cronic, earlySymptoms, main symptoms, drugname and suplements from algolia
    const handleHitClick = (hit, q) => {
      if (q === 3) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.neuroSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ neuroSymptoms: arr })
        } else {
          this.setState({
            neuroSymptoms: [
              ...this.state.neuroSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 4) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.earSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ earSymptoms: arr })
        } else {
          this.setState({
            earSymptoms: [
              ...this.state.earSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 5) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.cardioSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ cardioSymptoms: arr })
        } else {
          this.setState({
            cardioSymptoms: [
              ...this.state.cardioSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 6) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.respiSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ respiSymptoms: arr })
        } else {
          this.setState({
            respiSymptoms: [
              ...this.state.respiSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 7) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.stomachSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ stomachSymptoms: arr })
        } else {
          this.setState({
            stomachSymptoms: [
              ...this.state.stomachSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 8) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.endocrineSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ endocrineSymptoms: arr })
        } else {
          this.setState({
            endocrineSymptoms: [
              ...this.state.endocrineSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 9) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.jointSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ jointSymptoms: arr })
        } else {
          this.setState({
            jointSymptoms: [
              ...this.state.jointSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 10) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.mentalSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ mentalSymptoms: arr })
        } else {
          this.setState({
            mentalSymptoms: [
              ...this.state.mentalSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 11) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.generalSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ generalSymptoms: arr })
        } else {
          this.setState({
            generalSymptoms: [
              ...this.state.generalSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 13) {
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.infoSymptoms.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ infoSymptoms: arr })
        } else {
          this.setState({
            infoSymptoms: [
              ...this.state.infoSymptoms,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
    }

    // to handle the list of selected conditions from algolia
    const handleChecked = (q, name) => {
      if (q === 3) {
        return this.state.neuroSymptoms.some((data) => data.name === name)
      }
      if (q === 4) {
        return this.state.earSymptoms.some((data) => data.name === name)
      }
      if (q === 5) {
        return this.state.cardioSymptoms.some((data) => data.name === name)
      }
      if (q === 6) {
        return this.state.respiSymptoms.some((data) => data.name === name)
      }
      if (q === 7) {
        return this.state.stomachSymptoms.some((data) => data.name === name)
      }
      if (q === 8) {
        return this.state.endocrineSymptoms.some((data) => data.name === name)
      }
      if (q === 9) {
        return this.state.jointSymptoms.some((data) => data.name === name)
      }
      if (q === 10) {
        return this.state.mentalSymptoms.some((data) => data.name === name)
      }
      if (q === 11) {
        return this.state.generalSymptoms.some((data) => data.name === name)
      }
      if (q === 13) {
        return this.state.infoSymptoms.some((data) => data.name === name)
      }
    }

    // to display the list of results in algolia searchbox
    const Hit = (props, q, type) => {
      let { hit } = props
      let checked = handleChecked(q, hit.symptom)
      return (
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => {
            handleHitClick(hit, q)
          }}
        >
          {hit.symptom}
        </div>
      )
    }

    if (this.state.selectedQuestion === 0) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No', 'Not Sure'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[0] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[0] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 1:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {[
                'Yes, it was Positive',
                'Yes, it was Negative',
                'No, I was not tested',
              ].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[1] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[1] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {covidHistory[2].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = covidHistory[2]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...covidHistory]
                          temp[2] = tempq
                          this.setState({ covidHistory: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )
        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: 10,
                marginLeft: 0,
                width: '30%',
                fontSize: '20px',
              }}
            >
              <DatePicker
                minDate={new Date('09/01/2019')}
                // format={'MMM-dd-y'}
                onChange={(date) => {
                  let tempQ = [...covidHistory]
                  tempQ[3] = date
                  this.setState({ covidHistory: tempQ })
                  // console.log(date)
                }}
                value={covidHistory[3]}
              />
            </Form.Group>
          )
        case 4:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '20px' }}
            >
              {/* <input
                placeholder={'Months'}
                type="number"
                id={questionset[selectedQuestion][index] + 'months'}
                name={questionset[selectedQuestion][index] + 'months'}
                value={covidHistory[4].mm}
                onChange={(e) => {
                  let tempQ = [...covidHistory]
                  tempQ[4].mm = e.target.value
                  this.setState({ covidHistory: tempQ })
                }}
              />{' '}
              Months &emsp; */}
              <input
                placeholder={'Weeks'}
                type="number"
                id={questionset[selectedQuestion][index] + 'weeks'}
                name={questionset[selectedQuestion][index] + 'weeks'}
                value={covidHistory[4].ww}
                onChange={(e) => {
                  let tempQ = [...covidHistory]
                  tempQ[4].ww = e.target.value
                  this.setState({ covidHistory: tempQ })
                }}
              />{' '}
              Weeks &emsp;
              <input
                placeholder={'Days'}
                type="number"
                id={questionset[selectedQuestion][index] + 'days'}
                name={questionset[selectedQuestion][index] + 'days'}
                value={covidHistory[4].dd}
                onChange={(e) => {
                  let tempQ = [...covidHistory]
                  tempQ[4].dd = e.target.value
                  this.setState({ covidHistory: tempQ })
                }}
              />{' '}
              Days{'   '}
            </Form.Group>
          )

        case 5:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[5] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[5] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 6:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={covidHistory[6]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...covidHistory]
                  tempQ[6] = val
                  this.setState({ covidHistory: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )
      }
    }

    if (this.state.selectedQuestion === 1) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No, I still have symptoms'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[7] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[7] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 1:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {[
                'Yes, symptoms returned',
                'Yes, I developed new symptoms',
                'No',
              ].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[8] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[8] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )
        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={covidHistory[9]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...covidHistory]
                  tempQ[9] = val
                  this.setState({ covidHistory: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[10] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[10] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 4:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['<1 month', '1-3 months', '3-6 months', '>6 months', 'N/A'].map(
                (type) => (
                  <Form.Check
                    type={'radio'}
                    label={type}
                    value={type}
                    onClick={(val) => {
                      let tempQ = [...covidHistory]
                      tempQ[11] = val.target.value
                      this.setState({ covidHistory: tempQ })
                    }}
                    style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                    checked={covidHistory[11] === type}
                    classes={Styles.radioButton}
                  />
                ),
              )}
            </Form.Group>
          )
      }
    }

    if (this.state.selectedQuestion === 2) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {covidHistory[12].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = covidHistory[12]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...covidHistory]
                          temp[12] = tempq
                          this.setState({ covidHistory: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[13] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[13] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )
        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[14] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[14] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )
        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...covidHistory]
                    tempQ[15] = val.target.value
                    this.setState({ covidHistory: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={covidHistory[15] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 3) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {headNeuro[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = headNeuro[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...headNeuro]
                          temp[0] = tempq
                          this.setState({ headNeuro: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="neuroSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        neuro: query,
                      },
                    }))
                  }
                >
                  <SearchBox
                    translations={{
                      placeholder: 'Search conditions',
                    }}
                  />

                  {this.state.searchQuery.neuro &&
                    this.state.searchQuery.neuro.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'neuroSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.neuroSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.neuroSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={headNeuro[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...headNeuro]
                  tempQ[2] = val
                  this.setState({ headNeuro: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={headNeuro[3]}
                onChange={(e) => {
                  let tempQ = [...headNeuro]
                  tempQ[3] = e.target.value
                  this.setState({ headNeuro: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 4) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {earThroat[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = earThroat[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...earThroat]
                          temp[0] = tempq
                          this.setState({ earThroat: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="earSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        neuro: query,
                      },
                    }))
                  }
                >
                  <SearchBox
                    translations={{
                      placeholder: 'Search conditions',
                    }}
                  />

                  {this.state.searchQuery.neuro &&
                    this.state.searchQuery.neuro.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'earSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.earSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.earSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={earThroat[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...earThroat]
                  tempQ[2] = val
                  this.setState({ earThroat: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={earThroat[3]}
                onChange={(e) => {
                  let tempQ = [...earThroat]
                  tempQ[3] = e.target.value
                  this.setState({ earThroat: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 5) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {cardioVascular[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = cardioVascular[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...cardioVascular]
                          temp[0] = tempq
                          this.setState({ cardioVascular: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="cardioSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        cardio: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.cardio &&
                    this.state.searchQuery.cardio.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'cardioSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.cardioSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.cardioSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={cardioVascular[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...cardioVascular]
                  tempQ[2] = val
                  this.setState({ cardioVascular: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={cardioVascular[3]}
                onChange={(e) => {
                  let tempQ = [...cardioVascular]
                  tempQ[3] = e.target.value
                  this.setState({ cardioVascular: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 6) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {respiRatory[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = respiRatory[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...respiRatory]
                          temp[0] = tempq
                          this.setState({ respiRatory: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="respiSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        respiro: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.respiro &&
                    this.state.searchQuery.respiro.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'respiSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.respiSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.respiSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={respiRatory[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...respiRatory]
                  tempQ[2] = val
                  this.setState({ respiRatory: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={respiRatory[3]}
                onChange={(e) => {
                  let tempQ = [...respiRatory]
                  tempQ[3] = e.target.value
                  this.setState({ respiRatory: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 7) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {stomachDigestive[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = stomachDigestive[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...stomachDigestive]
                          temp[0] = tempq
                          this.setState({ stomachDigestive: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="stomachSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        stomo: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.stomo &&
                    this.state.searchQuery.stomo.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'stomachSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.stomachSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.stomachSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={stomachDigestive[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...stomachDigestive]
                  tempQ[2] = val
                  this.setState({ stomachDigestive: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={stomachDigestive[3]}
                onChange={(e) => {
                  let tempQ = [...stomachDigestive]
                  tempQ[3] = e.target.value
                  this.setState({ stomachDigestive: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 8) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {endoCrine[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = endoCrine[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...endoCrine]
                          temp[0] = tempq
                          this.setState({ endoCrine: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="endocrineSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        endo: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.endo &&
                    this.state.searchQuery.endo.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'endocrineSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.endocrineSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.endocrineSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={endoCrine[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...endoCrine]
                  tempQ[2] = val
                  this.setState({ endoCrine: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={endoCrine[3]}
                onChange={(e) => {
                  let tempQ = [...endoCrine]
                  tempQ[3] = e.target.value
                  this.setState({ endoCrine: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 9) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {jointPain[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = jointPain[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...jointPain]
                          temp[0] = tempq
                          this.setState({ jointPain: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="jointSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        joint: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.joint &&
                    this.state.searchQuery.joint.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'jointSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.jointSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.jointSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={jointPain[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...jointPain]
                  tempQ[2] = val
                  this.setState({ jointPain: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={jointPain[3]}
                onChange={(e) => {
                  let tempQ = [...jointPain]
                  tempQ[3] = e.target.value
                  this.setState({ jointPain: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 10) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {mentalHealth[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = mentalHealth[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...mentalHealth]
                          temp[0] = tempq
                          this.setState({ mentalHealth: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="mentalSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        mental: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.mental &&
                    this.state.searchQuery.mental.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'mentalSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.mentalSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.mentalSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={mentalHealth[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...mentalHealth]
                  tempQ[2] = val
                  this.setState({ mentalHealth: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={mentalHealth[3]}
                onChange={(e) => {
                  let tempQ = [...mentalHealth]
                  tempQ[3] = e.target.value
                  this.setState({ mentalHealth: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 11) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{ margin: '2vh', textAlign: 'left', fontSize: '25px' }}
            >
              {generalHealth[0].map((type) => {
                return (
                  <>
                    <label class={Styles.checkContainer}>
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = generalHealth[0]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          let temp = [...generalHealth]
                          temp[0] = tempq
                          this.setState({ generalHealth: temp })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </Form.Group>
          )

        case 1:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="generalSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        general: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.general &&
                    this.state.searchQuery.general.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'generalSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.generalSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.generalSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={generalHealth[2]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...generalHealth]
                  tempQ[2] = val
                  this.setState({ generalHealth: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Least severe
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Most severe
                </p>
              </div>
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <textarea
                placeholder="Enter your notes..."
                cols="20"
                rows="4"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                }}
                value={generalHealth[3]}
                onChange={(e) => {
                  let tempQ = [...generalHealth]
                  tempQ[3] = e.target.value
                  this.setState({ generalHealth: tempQ })
                }}
              ></textarea>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 12) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No', 'N/A'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...generalAbility]
                    tempQ[0] = val.target.value
                    this.setState({ generalAbility: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={generalAbility[0] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 1:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No', 'N/A'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...generalAbility]
                    tempQ[1] = val.target.value
                    this.setState({ generalAbility: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={generalAbility[1] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No', 'N/A'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...generalAbility]
                    tempQ[2] = val.target.value
                    this.setState({ generalAbility: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={generalAbility[2] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )
        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No', 'N/A'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...generalAbility]
                    tempQ[3] = val.target.value
                    this.setState({ generalAbility: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={generalAbility[3] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )
        case 4:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Yes', 'No'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...generalAbility]
                    tempQ[4] = val.target.value
                    this.setState({ generalAbility: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={generalAbility[4] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 5:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={generalAbility[5]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...generalAbility]
                  tempQ[5] = val
                  this.setState({ generalAbility: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Lowest quality
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Very high quality
                </p>
              </div>
            </Form.Group>
          )

        case 6:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              <Slider
                min={0}
                max={10}
                step={1}
                labels={horizontalLabels}
                value={generalAbility[6]}
                orientation="horizontal"
                onChange={(val) => {
                  let tempQ = [...generalAbility]
                  tempQ[6] = val
                  this.setState({ generalAbility: tempQ })
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginLeft: -45,
                  }}
                >
                  Lowest quality
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: values.primaryThemeDark,
                    width: 120,
                    textAlign: 'center',
                    marginRight: -50,
                  }}
                >
                  Very high quality
                </p>
              </div>
            </Form.Group>
          )
      }
    }

    if (selectedQuestion === 13) {
      switch (index) {
        case 0:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              className={Styles.textInputForm}
              // style={{
              //   margin: 10,
              //   marginLeft: 0,
              //   width: '50%',
              //   fontSize: '20px',
              // }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // width: '50%',
                  // margin: '10px auto',
                  justifyContent: 'center',
                }}
              >
                <div style={{ margin: '20px', textAlign: 'left' }}>
                  <FancyField
                    value={yourInfo[0]}
                    label="First Name"
                    name="fnameInput"
                    onChange={(val) => {
                      let tempQ = [...yourInfo]
                      tempQ[0] = val
                      this.setState({ yourInfo: tempQ })
                    }}
                    placeholder="First Name"
                  />
                </div>

                <div style={{ margin: '20px', textAlign: 'left' }}>
                  <FancyField
                    value={yourInfo[1]}
                    label="Last Name"
                    name="lnameInput"
                    onChange={(val) => {
                      let tempQ = [...yourInfo]
                      tempQ[1] = val
                      this.setState({ yourInfo: tempQ })
                    }}
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </Form.Group>
          )

        case 1:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: 10,
                marginLeft: 0,
                width: '30%',
                fontSize: '20px',
              }}
            >
              <DatePicker
                // format={'MMM-dd-y'}
                onChange={(date) => {
                  let tempQ = [...yourInfo]
                  tempQ[2] = date
                  this.setState({ yourInfo: tempQ })

                  //check under age
                  let now = moment()
                  let dobMoment = moment(tempQ)
                  if (now.diff(dobMoment, 'years') < 18) {
                    console.log('underage')
                    this.setState({ underAge: true })
                  } else {
                    this.setState({ underAge: false })
                  }
                  // console.log(date)
                }}
                value={yourInfo[2]}
              />
            </Form.Group>
          )

        case 2:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              className={Styles.textInputForm}

              // style={{
              //   margin: 10,
              //   marginLeft: 0,
              //   width: '30%',
              //   fontSize: '20px',
              // }}
            >
              <FancyField
                value={yourInfo[3]}
                label="Zip Code"
                required={false}
                name="zip"
                onChange={(val) => {
                  let tempQ = [...yourInfo]
                  tempQ[3] = val
                  this.setState({ yourInfo: tempQ })
                  // console.log(val)
                }}
                placeholder="Zip Code"
              />
            </Form.Group>
          )

        case 3:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {['Male', 'Female', 'Non-Binary'].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...yourInfo]
                    tempQ[4] = val.target.value
                    this.setState({ yourInfo: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={yourInfo[4] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 4:
          return (
            <Form.Group
              controlId={questionset[selectedQuestion][index]}
              style={{
                margin: '2vh',
                textAlign: 'left',
                fontSize: '25px',
              }}
            >
              {[
                'American Indian or Alaska Native',
                'Asian',
                'Black or African American',
                'Hispanic or Latino',
                'Native Hawaiian or Other Pacific Islander',
                'White/Caucasian',
                'Multiracial or Biracial',
                'Hawaiian or Pacific Islander',
                'Two or more races',
                'Do not wish to answer',
                'Other',
              ].map((type) => (
                <Form.Check
                  type={'radio'}
                  label={type}
                  value={type}
                  onClick={(val) => {
                    let tempQ = [...yourInfo]
                    tempQ[5] = val.target.value
                    this.setState({ yourInfo: tempQ })
                  }}
                  style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                  checked={yourInfo[5] === type}
                  classes={Styles.radioButton}
                />
              ))}
            </Form.Group>
          )

        case 5:
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group
                controlId="infoSymptoms"
                style={{
                  margin: '2vh',
                  textAlign: 'left',
                  fontSize: '25px',
                  width: '40%',
                }}
              >
                {/* <Form.Group controlId="cronic condn" style={{margin: 10, marginLeft: 20, width: '40%'}}> */}
                <InstantSearch
                  searchClient={condnClient}
                  indexName={'CONDITIONS'}
                  onSearchStateChange={({ query }) =>
                    this.setState((prev) => ({
                      searchQuery: {
                        ...prev.searchQuery,
                        info: query,
                      },
                    }))
                  }
                >
                  <SearchBox />
                  {this.state.searchQuery.info &&
                    this.state.searchQuery.info.length !== 0 && (
                      <div
                        style={{ overflow: 'auto', height: 180, marginTop: 20 }}
                      >
                        <RefinementList limit={5} />
                        <Hits
                          hitComponent={(props) =>
                            Hit(props, selectedQuestion, 'infoSymptoms')
                          }
                        />
                      </div>
                    )}
                </InstantSearch>
              </Form.Group>
              {this.state.infoSymptoms.length !== 0 && (
                <div className={Styles.searchAdded}>
                  Added:
                  <br />{' '}
                  {this.state.infoSymptoms.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <p>
                          {index + 1}. {data.name + ' '}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() =>
                            handleHitClick(
                              { symptom: data.name },
                              selectedQuestion,
                            )
                          }
                        />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
      }
    }
  }
  renderMainContent = () => {
    let {
      selectedQuestion,
      questionset,
      covidHistory,
      headNeuro,
      earThroat,
      cardioVascular,
      respiRatory,
      stomachDigestive,
      endoCrine,
      jointPain,
      mentalHealth,
      generalHealth,
    } = this.state
    let question = this.state.questionset[this.state.selectedQuestion]
    let toReturn = []

    question.forEach((ques, index) => {
      toReturn.push(
        <div
          style={{
            width: '70%',
            alignSelf: 'center',
            marginTop: '4vh',
            justifyContent: 'center',
            // overflow: 'hidden',
            display:
              selectedQuestion === 0 && index === 2
                ? covidHistory[1] === 'Yes, it was Positive' ||
                  covidHistory[1] === 'Yes, it was Negative'
                  ? 'block'
                  : 'none'
                : // : selectedQuestion === 1 && index === 1
                // ? covidHistory[6] === 'Yes'
                //   ? 'block'
                //   : 'none'
                // : selectedQuestion === 1 && index === 2
                // ? covidHistory[7] === 'Yes, symptoms returned' ||
                //   covidHistory[7] === 'Yes, I developed new symptoms'
                //   ? 'block'
                //   : 'none'
                selectedQuestion === 1 && index === 3
                ? covidHistory[8] === 'Yes, symptoms returned' ||
                  covidHistory[8] === 'Yes, I developed new symptoms'
                  ? 'block'
                  : 'none'
                : selectedQuestion === 3 && index === 1
                ? headNeuro[0][headNeuro[0].length - 1].isChecked === true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 4 && index === 1
                ? earThroat[0][earThroat[0].length - 1].isChecked === true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 5 && index === 1
                ? cardioVascular[0][cardioVascular[0].length - 1].isChecked ===
                  true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 6 && index === 1
                ? respiRatory[0][respiRatory[0].length - 1].isChecked === true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 7 && index === 1
                ? stomachDigestive[0][stomachDigestive[0].length - 1]
                    .isChecked === true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 8 && index === 1
                ? endoCrine[0][endoCrine[0].length - 1].isChecked === true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 9 && index === 1
                ? jointPain[0][jointPain[0].length - 1].isChecked === true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 10 && index === 1
                ? mentalHealth[0][mentalHealth[0].length - 1].isChecked === true
                  ? 'block'
                  : 'none'
                : selectedQuestion === 11 && index === 1
                ? generalHealth[0][generalHealth[0].length - 1].isChecked ===
                  true
                  ? 'block'
                  : 'none'
                : 'block',
          }}
        >
          <p
            style={{
              fontSize: '25px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'left',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
            }}
          >
            {ques}
          </p>
          {this.getAnswerOptions(index)}
        </div>,
      )
    })

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: '50vh',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        {toReturn}
      </div>
    )

    // return (
    //   <div
    //     style={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       width: '100%',
    //       minHeight: '50vh',
    //       justifyContent: 'center',
    //       textAlign: 'center',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: '70%',
    //         alignSelf: 'center',
    //         marginTop: '4vh',
    //         justifyContent: 'center',
    //         overflow: 'hidden',
    //       }}
    //     >
    //       <p
    //         style={{
    //           fontSize: '25px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: this.state.selectedQuestion === 9 ? 'center' : 'left',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //         }}
    //       >
    //         {questionSet[this.state.selectedQuestion]}
    //       </p>
    //       {/* )} */}
    //       {this.getAnswerOptions()}
    //     </div>
    //   </div>
    // )
  }
  render() {
    let { selectedQuestion, showIntro, showThankyou, yourInfo } = this.state
    let pcal =
      ((this.state.selectedQuestion + 1) /
        Object.keys(this.state.questionset).length) *
      100

    if (showIntro) {
      return (
        <div
          className={GlobalStyles.container}
          style={{
            overflow: 'hidden',
            margin: '0 auto',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              marginTop: '10%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className={Styles.subContainer}>
              <div
                style={{
                  width: '80%',
                  alignSelf: 'center',
                  marginTop: '5vh',
                }}
              >
                <p className={Styles.heading}>
                  We are going to ask some questions about your Covid History
                  and symptoms. This will take approximately 5 minutes to
                  complete.
                  <br />
                  <br />
                </p>
                <p
                  className={Styles.heading}
                  style={{
                    fontSize: '28px',
                  }}
                >
                  Please take breaks if you need.
                  <br />
                </p>
              </div>

              <Button
                onClick={() => {
                  this.setState({ showIntro: false })
                }}
                className={GlobalStyles.button}
                style={{
                  marginTop: '5vh',
                  width: '15vw',
                  height: '6vh',
                  alignSelf: 'center',
                }}
                variant="primary"
              >
                Next
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      )
    }

    if (showThankyou) {
      return (
        <div
          className={GlobalStyles.container}
          style={{
            overflow: 'hidden',
            margin: '0 auto',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {this.state.showappointment && (
            <Modal
              onRequestClose={() => this.setState({ showappointment: false })}
              ariaHideApp={false}
              isOpen={this.state.showappointment}
              className={Styles.modalContent}
              // style={paymentModalStyles}

              style={{
                overlay: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.44)',
                },
              }}
              contentLabel="Modal"
            >
              {this.renderScheduleModal()}
            </Modal>
          )}
          <div
            style={{
              marginTop: '2%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className={Styles.subContainer}>
              <div
                style={{
                  width: '80%',
                  alignSelf: 'center',
                  marginTop: '5vh',
                }}
              >
                <p className={Styles.heading}>
                  Thank you
                  <br />
                  <br />
                </p>
                <p
                  className={Styles.heading}
                  style={{
                    fontSize: '20px',
                  }}
                >
                  Your PDF of this assessment has been emailed to you. You can
                  share this with your care team.
                  <br />
                  {this.state.counter} people have taken this assessement. Share
                  this tool on social media to increase awareness and support
                  for fellow longhaulers.
                  <br />
                </p>

                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                >
                  <FacebookShareButton
                    url="https://www.facebook.com/sharer/sharer.php?u=longcovid.io"
                    // title={socialTitle}
                    quote={socialTitle}
                    hashtag={'#Cronicillness'}
                  >
                    <FacebookIcon size={50} style={{ margin: 10 }} round />
                  </FacebookShareButton>

                  <TwitterShareButton url="longcovid.io" title={socialTitle}>
                    <TwitterIcon size={50} style={{ margin: 10 }} round />
                  </TwitterShareButton>

                  {/* <PinterestShareButton
                    description={socialTitle}
                    media="https://longcovid.io/"
                  >
                    <PinterestIcon size={50} style={{ margin: 10 }} round />
                  </PinterestShareButton> */}

                  <WhatsappShareButton url={urlSocial} title={socialTitle}>
                    <WhatsappIcon size={50} style={{ margin: 10 }} round />
                  </WhatsappShareButton>

                  <LinkedinShareButton
                    url="https://www.linkedin.com/sharing/share-offsite/?url=longcovid.io"
                    title={socialTitle}
                  >
                    <LinkedinIcon size={50} style={{ margin: 10 }} round />
                  </LinkedinShareButton>

                  <EmailShareButton
                    url="longcovid.io"
                    subject={'Support the Long Covid community!'}
                    body={socialTitle}
                    openShareDialogOnClick={true}
                    onClick={() => {}}
                    // title={socialTitle}
                  >
                    <EmailIcon size={50} style={{ margin: 10 }} round />
                  </EmailShareButton>
                </div>

                {!this.state.underAge && (
                  <>
                    <p
                      className={Styles.heading}
                      style={{
                        fontSize: '20px',
                      }}
                    >
                      If you want to connect with other longhaulers, get
                      resources on Long Covid or be seen by a virtual Long Covid
                      Specialty care team, click below
                      <br />
                    </p>

                    <div
                      className={Styles.displayImage}
                      // style={{
                      //   marginTop: '5%',
                      //   display: 'flex',
                      //   flexDirection: 'row',
                      //   justifyContent: 'space-evenly',
                      //   alignItems: 'center',
                      // }}
                    >
                      <img
                        alt="Aila Health"
                        src={Image1}
                        style={{
                          // width: '15vh',
                          height: '30vh',
                          // alignSelf: 'flex-start',
                          margin: '1%',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          window.open(
                            'https://www.longcovid.io/longhauler-community',
                          )
                        }
                      />
                      <img
                        alt="Aila Health"
                        src={Image2}
                        style={{
                          // width: '15vh',
                          height: '30vh',
                          // alignSelf: 'flex-start',
                          margin: '1%',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.setState({ showappointment: true })
                        }}
                      />
                      <img
                        alt="Aila Health"
                        src={Image3}
                        style={{
                          // width: '15vh',
                          height: '30vh',
                          // alignSelf: 'flex-start',
                          margin: '1%',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          window.open(
                            'https://www.longcovid.io/longcovidclinic',
                          )
                        }
                        // window.open(
                        //   'https://www.longcovid.io/longhauler-community',
                        // )
                      />
                    </div>
                  </>
                )}

                {/* <div
                  style={{
                    width: '100%',
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={() => {
                      this.props.history.push('/wix/aila/careteam', {
                        stateResidence: this.state.stateResidence,
                        fName: yourInfo[0],
                        lName: yourInfo[1],
                      })
                    }}
                    className={GlobalStyles.button}
                    style={{
                      fontWeight: 'bold',
                      margin: '1vh',
                      width: 200,
                    }}
                    variant="primary"
                  >
                    Continue
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )
    }

    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '2vh',
            marginRight: '2vw',
            justifyContent: 'flex-end',
          }}
        >
          {selectedQuestion === 3 ? (
            <img
              alt="Aila Health"
              src={BrainIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 4 ? (
            <img
              alt="Aila Health"
              src={BrainIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 5 ? (
            <img
              alt="Aila Health"
              src={CardioIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 6 ? (
            <img
              alt="Aila Health"
              src={LungIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 7 ? (
            <img
              alt="Aila Health"
              src={StomachIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 8 ? (
            <img
              alt="Aila Health"
              src={EndocrineIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 9 ? (
            <img
              alt="Aila Health"
              src={JointIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 10 ? (
            <img
              alt="Aila Health"
              src={MentalHealthIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 11 ? (
            <img
              alt="Aila Health"
              src={GeneralHealthIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : selectedQuestion === 12 ? (
            <img
              alt="Aila Health"
              src={GeneralHealthIcon}
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            />
          ) : (
            <div
              style={{
                width: '15vh',
                height: '15vh',
                alignSelf: 'flex-start',
              }}
            ></div>
          )}
        </div>

        <p
          className={Styles.subTopics}
          // style={{
          //   fontSize: '30px',
          //   color: values.primaryThemeDark,
          //   textAlign: 'center',
          //   fontWeight: 'bold',
          //   fontFamily: 'Avenir',
          //   wordWrap: 'break-word',
          //   marginTop: '-7vh',
          // }}
        >
          {selectedQuestion < 3
            ? 'Covid History'
            : selectedQuestion === 3
            ? 'Neurological Symptoms'
            : selectedQuestion === 4
            ? 'Ear, Nose and Throat Symptoms'
            : selectedQuestion === 5
            ? 'Cardiovascular Symptoms'
            : selectedQuestion === 6
            ? 'Respiratory Symptoms'
            : selectedQuestion === 7
            ? 'Gastrointestinal Symptoms'
            : selectedQuestion === 8
            ? 'Endocrine and Hormonal Symptoms'
            : selectedQuestion === 9
            ? 'Joint and Muscle Pain Symptoms'
            : selectedQuestion === 10
            ? 'Mental Health Symptoms'
            : selectedQuestion === 11
            ? 'General Symptoms'
            : selectedQuestion === 12
            ? 'General Ability'
            : selectedQuestion === 13
            ? 'Your information'
            : 'Questionnaires'}
        </p>

        {this.renderMainContent()}

        {this.state.showHelplineModal && (
          <Modal
            ariaHideApp={false}
            isOpen={this.state.showHelplineModal}
            className={Styles.modalContent}
            // style={paymentModalStyles}

            style={{
              overlay: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.44)',
              },
            }}
            contentLabel="Modal"
          >
            {this.renderHelplineModal()}
          </Modal>
        )}

        <p
          style={{
            marginTop: 20,
            fontSize: 15,
            color: 'red',
            visibility: this.state.error ? 'visible' : 'hidden',
            textAlign: 'center',
          }}
        >
          {this.state.errorString}
        </p>

        <div
          className={Styles.progressDiv}
          // style={{
          //   width: '80%',
          //   alignSelf: 'self-start',
          //   marginRight: '5vh',
          //   marginLeft: '10vh',
          // }}
        >
          <Progress_bar
            bgcolor={values.primaryThemeMedium}
            progress={pcal}
            height={20}
          />
        </div>

        <div
          style={{
            flex: 1,
            alignSelf: 'self-end',
            marginRight: '5vh',
          }}
        >
          <Button
            onClick={() => this.onPreviousPressed()}
            className={Styles.button2}
            style={{
              margin: '1vh',
              width: 200,
              color: '#20A892',
              fontWeight: 'bold',
            }}
            variant="primary"
          >
            {'Previous'}
          </Button>

          <Button
            onClick={() => {
              this.state.selectedQuestion ===
              Object.keys(this.state.questionset).length -
                (this.props?.location?.state?.logged_in ? 2 : 1) // already existing user skip your info screen
                ? this.onSubmitPressed()
                : this.onNextPressed()
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            {this.state.selectedQuestion ===
            Object.keys(this.state.questionset).length - 1
              ? 'Submit'
              : 'Next'}
          </Button>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fetchPatientCoreDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(AilaLongCovid)
