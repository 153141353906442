import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/PracticeInfo.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import * as Metadata from '../../metadata.js'
import { ToastContainer, toast } from 'react-toastify'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import moment from 'moment'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faPlusCircle,
  faPaperclip,
  faCheckCircle,
  faCalendarPlus,
  faEdit,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Draggable from 'react-draggable'
import Select from 'react-select'
import cloneDeep from 'lodash/cloneDeep'
import TaskModal from '../Patients/Careplan/TaskModal'
import {
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
} from '../../redux/actions'
import ReactTooltip from 'react-tooltip'
import firebase from '../../services/firebase'
import Fuse from 'fuse.js'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'

const axios = require('axios')

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const insuranceAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="icdclear" onClick={onClick}>
      ClearAll
    </button>
  )
}

class PracticeInfo extends Component {
  constructor(props) {
    super(props)
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      appointmentData: null,
      patientId: this.props?.patientId || this.props?.patient?.uid,
      communicationType: null,
      //PRACTICE INFO - PROFILE
      practice_id: Config.PRACTICE_ID,
      practiceName: '',
      address1: '',
      address2: '',
      city: '',
      state: null,
      addressComponents: null,
      postalCode: '',
      phoneNumber: '',
      faxNumber: '',
      taxEntity: '',
      groupNpi: '',
      serviceStates: null,
      brandingColor: '',
      instagramLink: '',
      facebookLink: '',
      twitterLink: '',
      youtubeLink: '',
      //LOGO UPLOAD
      logoUploaded: false,
      imageUploaded: false,
      imageLocation: '',
      image: null,
      //ROLES & PERMISSIONS
      // BUG: WTF is this?
      rolesPermissions: [
        {
          first_name: 'test_ailahealth3',
          last_name: 'Tina1',
          email: 'test@ailahealth.com',
          user_type: 'Administrator',
        },
        {
          first_name: 'test_ailahealth3',
          last_name: 'Tina2',
          email: 'test2@ailahealth.com',
          user_type: 'Care Coordinator',
        },
        {
          first_name: 'test_ailahealth3',
          last_name: 'Tina6',
          email: 'test3@ailahealth.com',
          user_type: 'Physician (MD/DO)',
        },
        {
          first_name: 'test_ailahealth3',
          last_name: 'Tina5',
          email: 'test4@ailahealth.com',
          user_type: 'Administrator',
        },
      ],
      rolesModalOpen: false,
      roleFistName: '',
      roleLastName: '',
      roleEmail: '',
      supervisor: false,
      role: null,
      roleIndex: null,
      selectedUser: null,
      //BILLING & PAYOUTS
      billingPayouts: {},
      //POLICIES
      policies: {},
      //ACCEPTED INSURANCE
      acceptedInsurance: [
        {
          isp: '11234',
          name: ['isp', 'Aetna California'],
        },
        {
          isp: '89562',
          name: ['isp', 'Blue Sheild Colorado'],
        },
      ],
      insuranceModalOpen: false,
      searchName: '',
      searchCity: '',
      searchAddress: '',
      searchState: '',
      searchList: [],

      //APPOINTMENT PRICING
      productPricing: [],
      productPricingModal: false,
      selectedProduct: null,
      visitIndex: null,
      visitName: '',
      visitBill: '',
      visitLength: null,
      visitType: null,
      visitSpecialty: null,
      visitCpt: '',
      visitModifier: '',
      visitDescription: '',

      //EMAIL FIELDS
      fromEmail: 'admin-donotreply@ailahealth.com',
      ccEmail: '',
      audience: null,
      toEmails: null,
      emailAddress: '',
      cohortSelected: null,
      template: null,
      subject: '',
      message: '',
      //PHONE FIELDS
      phoneType: null,
      phonePatient: null,
      phoneName: '',

      phoneRequest: null,
      phoneMessage: '',
      providerMap: null,
      patientList: [],
      completePatientList: [],
      providersList: [],
      cohortOptions: [],
      fileAttached: null,
      scheduleDate: new Date(),

      //VIEW OR CREATE COMMUNICATIONS
      allCommunications: [],
      viewCommunications: false,
      searchName: '',
      sortValue: true,
      searchEmail: '',

      //TASK MODAL
      modalAddTaskIsOpen: false,

      // Facility data
      facilityName: '',
      selectedFacilityState: null,
      allFacilities: [],
      facilitiesModalOpen: false,
      facilityAddress: '',
      facilityAddress2: '',
      facilityStateObject: null,
      facilityNpi: '',
      facilityZip: '',
    }
    this.fuse = null
    this.imageUploadRef = React.createRef()
    this.fileRef = React.createRef()
    this.getPracticeInfo = this.getPracticeInfo.bind(this)
    this.getAllProviders = this.getAllProviders.bind(this)
    this.getPatientList = this.getPatientList.bind(this)
    this.hideTaskModal = this.hideTaskModal.bind(this)
    this.getUserTask = this.getUserTask(this)
    this.saveUserType = this.saveUserType.bind(this)
    this.createProvider = this.createProvider.bind(this)
    this.saveProvider = this.saveProvider.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    if (this.props.mongoUser) {
      let cohorts = this.props?.mongoUser?.cohorts || []
      let cohortObjects = []
      cohorts.forEach((cohort) =>
        cohortObjects.push({ label: cohort, value: cohort }),
      )
      this.setState({ cohortOptions: cohortObjects })
    }
    this.getPatientList()
    this.getAllProviders()
    this.getPracticeInfo()
    this.getFacilitiesInfo()
  }

  async getFacilitiesInfo() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}facilities`
    this.setState({ loading: true })

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        // getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        console.log('Data', data)
        if (data.success) {
          let allFacilities = data.data
          this.setState({ allFacilities })
        }
      })
      .catch((err) => {
        console.log('error when getting facilities information', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  async getPracticeInfo() {
    const { token, adminId, mongoUser } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}practice-info?id=${Config.PRACTICE_ID}`
    this.setState({ loading: true })

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        // getallproviders: true,
        // if_external_doctor
      },
      url,
    })
      .then(async ({ data }) => {
        // console.log('PRACTICE-INFO DATA:::', data)

        if (data && mongoUser.authorization_level !== 'external_doctor')
          this.setState({
            //PRACTICE INFO - PROFILE
            // practice_id: data?._id,
            practiceName: data?.practice_name,
            address1: data?.address1,
            address2: data?.address2,
            city: data?.city,
            state: data?.state,
            addressComponents: data?.addressComponents,
            postalCode: data?.postal_code,
            phoneNumber: data?.phone_number,
            faxNumber: data?.fax_number,
            taxEntity: data?.taxEntity,
            groupNpi: data?.groupNpi,
            serviceStates: data?.serviceStates,
            brandingColor: data?.branding_color,
            instagramLink: data?.instagram_link,
            facebookLink: data?.facebook_link,
            twitterLink: data?.twitter_link,
            youtubeLink: data?.youtube_link,
            //LOGO UPLOAD
            image: data?.image,
            //ROLES & PERMISSIONS
            rolesPermissions: data?.roles_permissions,
            //BILLING & PAYOUTS
            billingPayouts: data?.billing_payouts,
            //ACCEPTED INSURANCE
            acceptedInsurance: data?.accepted_insurance,
            policies: data?.policies,
            productPricing: data?.product_pricing || [],
          })
        this.setState({ loading: false })

        // let docObj = {}
        // if (data) {
        //   data.forEach((x) => {
        //     docObj[x.firebase_id] = x
        //   })
        //   this.setState({
        //     providerMap: docObj,
        //   })
        // }
      })
      .catch((err) => {
        console.log('error when getting all communications', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }
  async getAllProviders() {
    const { token, adminId, mongoUser } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        // console.log(data)
        let providersList = []
        data &&
          data.forEach((item) => {
            providersList.push({
              label: item?.first_name + ' ' + item?.last_name,
              value: item.email,
              ...item,
            })
          })

        if (mongoUser.authorization_level !== 'external_doctor')
          this.setState({ providersList: providersList })
        else this.setState({ providersList: [] })
        // console.log(providersList)
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  saveProvider(user) {
    let self = this
    const { token, adminId } = this.props
    let url = Config.BACKEND_URL + 'providers'
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: user,
    })
      .then(({ data }) => {
        console.log('successfully added doctor to backend')
        toast.success('Created new Role')
        this.getAllProviders()
        this.closeAllModals()
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
        toast.error('Something went wrong')
      })
  }
  createProvider() {
    const { roleFistName, roleLastName, roleEmail, role, supervisor } =
      this.state
    let self = this
    let pwd = 'AilaHealth2022'
    firebase
      .auth()
      .createUserWithEmailAndPassword(roleEmail, pwd)
      .then((result) => {
        let user = result.user
        let uid = user.uid

        let mongoData = {
          firebase_id: user.uid,
          email: roleEmail,
          first_name: roleFistName,
          last_name: roleLastName,
          user_type: role,
          supervisor: supervisor,
          languages: ['English'],
          cohorts: [],
        }

        // TODO: update this to snake case
        // WARNING: updating this can break a lot of things
        let userToPut = { userType: 'admin', uid: uid }
        // let userToPut = { user_type: 'admin', uid: uid }
        firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .set(userToPut)
          .then(() => {
            console.log('successfully created new Provider')
            self.saveProvider(mongoData)
          })
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code
        if (errorCode === 'auth/email-already-in-use') {
          toast.error('Email address already in use')
          // self.setState({
          //   error: true,
          //   errorString: 'Email address already in use',
          //   loading: false,
          // })
        } else {
          toast.error('Something went wrong')
        }
      })
  }
  saveUserType(user) {
    const self = this
    const { token } = this.props
    const url = `${Config.BACKEND_URL}providers`
    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: user,
    })
      .then(({ data }) => {
        console.log('successfully edited user in backend')
        this.getAllProviders()
        this.closeAllModals()
        // self.props.addMongoUser(user)
        // toast.success('Successfully updated profile')
        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        // self.setState({ loading: false, saving: false, imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }
  closeAllModals() {
    this.props.toggleSidebar(false)
    this.setState({
      rolesModalOpen: false,
      facilitiesModalOpen: false,
      roleFistName: '',
      roleLastName: '',
      roleEmail: '',
      role: null,
      supervisor: false,
      roleIndex: null,
      selectedUser: null,
      insuranceModalOpen: false,
      productPricingModal: false,
      selectedProduct: null,
      visitIndex: null,
      visitName: '',
      visitBill: '',
      visitLength: null,
      visitType: null,
      visitSpecialty: null,
      visitCpt: '',
      visitModifier: '',
      visitDescription: '',

      searchName: '',
      searchCity: '',
      searchAddress: '',
      searchState: '',
      searchList: [],
    })
  }

  onSavePressed() {
    if (this.state.imageUploaded) {
      this.saveImageToFirebase()
    } else {
      this.saveMongoData()
    }
  }

  saveMongoData(downloadURL) {
    const self = this
    const {
      practiceName,
      address1,
      address2,
      city,
      state,
      addressComponents,
      postalCode,
      phoneNumber,
      faxNumber,
      taxEntity,
      groupNpi,
      serviceStates,
      brandingColor,
      instagramLink,
      facebookLink,
      twitterLink,
      youtubeLink,
      image,
      rolesPermissions,
      billingPayouts,
      policies,
      acceptedInsurance,
      productPricing,
      practice_id,
    } = this.state
    const { token } = this.props
    const url = Config.PRACTICE_ID
      ? `${Config.BACKEND_URL}practice-info?id=${Config.PRACTICE_ID}`
      : `${Config.BACKEND_URL}practice-info`
    let toReturn = {
      //PRACTICE INFO - PROFILE
      practice_name: practiceName,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      addressComponents: addressComponents,
      postal_code: postalCode,
      phone_number: phoneNumber,
      fax_number: faxNumber,
      taxEntity: taxEntity,
      groupNpi: groupNpi,
      serviceStates: serviceStates,
      branding_color: brandingColor,
      instagram_link: instagramLink,
      facebook_link: facebookLink,
      twitter_link: twitterLink,
      youtube_link: youtubeLink,
      //LOGO UPLOAD
      image: downloadURL ? downloadURL : image,
      //ROLES & PERMISSIONS
      roles_permissions: rolesPermissions,
      //BILLING & PAYOUTS
      billing_payouts: billingPayouts,
      //ACCEPTED INSURANCE
      accepted_insurance: acceptedInsurance,
      //POLICIES
      policies: policies,
      //PRODUCT PRICING
      product_pricing: productPricing,
    }

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: toReturn,
    })
      .then(({ data }) => {
        console.log('successfully saved practice info in backend')
        // self.props.addMongoUser(user)
        toast.success('Successfully updated practice info')
        if (self.state.imageUploaded) toast.success('Updated Logo')
        self.setState({ loading: false, saving: false, imageUploaded: false })
        this.getPracticeInfo()
      })
      .catch((err) => {
        console.log('error when saving practice info', err)
        this.getPracticeInfo()
      })
  }

  async onSearch() {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const { searchName, searchAddress, searchCity, searchState } = this.state
    if (searchName?.length === 0 || !searchState) {
      console.log('Insurance : No NAME and STATE search')
      toast.warning('Please specify Name and State of Insurance')
      return
    }
    // setLoading(true)
    try {
      let url = Config.CHANGE_BACKEND_URL
      var config = {
        method: 'get',
        url: `${url}change/search-insurance?city=${searchCity}&address_1=${searchAddress}&name=${searchName}&state=${searchState?.abbreviation}`,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: adminId,
        },
      }

      axios(config)
        .then(({ data }) => {
          if (data?.RESULT?.hasOwnProperty('OBJECT')) {
            Array.isArray(data?.RESULT?.OBJECT)
              ? this.setState({ searchList: data.RESULT.OBJECT })
              : this.setState({ searchList: [data.RESULT.OBJECT] })
          } else if (data?.hasOwnProperty('ERROR')) {
            toast.warning(
              'The search criteria you entered found too many matching records. Make your search criteria more specific.',
            )
          }
        })
        .catch(function (error) {
          // setLoading(false)
          // setPharmacyList([])
          toast.error('Could not fetch Insurances, please try again later')
          console.log('error - search insurance', error)
        })
    } catch (error) {
      console.log('error - search insurance', error)
      toast.error('Could not fetch Insurances, please try again later')
    }
  }

  getPatientList() {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ||
      mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '?access=admin'
        : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          let patientList = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })

          total &&
            total.forEach((item) => {
              patientList.push({
                label: item?.firstName + ' ' + item?.lastName,
                value: item?.email,
                firebase_id: item?.uid,
                ...item,
              })
            })
          self.setState({
            loading: false,
            // patientListData: data || {},
            patientList: data ? patientList : [], // default
            completePatientList: patientList,
          })
          self.props.addPatientList(data)
          //   if (self.props.rpmPatientId) {
          //     self.navigateToRpmPatientProfile(data)
          //   }
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }

  hideTaskModal() {
    this.setState({ modalAddTaskIsOpen: false })
  }

  getUserTask() {
    const { token, adminId, patient } = this.props
    let url = Config.BACKEND_URL + 'support-request'
    axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: adminId,
        Authorization: `JWT ${token}`,
        clinical_support: true,
        patient_id: patient?.uid,
      },
    })
      .then(({ data }) => {
        this.setState({ taskList: data })
      })
      .catch((err) => console.log('err while fetching support list: ', err))
  }

  saveImageToFirebase() {
    const self = this
    const { adminId, token } = this.props
    if (this.state.imageUploaded) {
      const file = this.state.image
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef
        .child(
          `practiceInfo/${this.state.practiceName}_${adminId}/logo/${file.name}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.saveMongoData()
          // self.onSavePressed()
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // self.setState({
            //   image: downloadURL,
            //   imageUploaded: false,
            //   imageLocation: '',
            // })
            console.log(downloadURL)
            self.saveMongoData(downloadURL)
            // self.onSavePressed(downloadURL)
          })
        },
      )
    }
  }
  onImageChange(e) {
    const file = e.target.files[0]
    if (window.confirm(`Are you sure you want to upload ${file.name}?`)) {
      // this.setState({imageUploaded: true, imageLocation: })
      const reader = new FileReader()
      const self = this
      reader.onload = function (e) {
        const image = e.target.result
        if (self.state.logoUploaded) {
          console.log('confirmed upload - logo')

          self.setState(
            {
              imageLocation: image,
              imageUploaded: true,
              image: file,
            },
            () => {
              self.setState({ logoUploaded: false })
              // self.saveImageToFirebase()
            },
          )
        }
      }
      reader.readAsDataURL(file) // convert to base64 string
    } else {
      console.log('ignored')
    }
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    let self = this

    // Update the state
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])

      this.setState({ loading: true })

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      let storageUrl = `emailTemplates/${mongoUser.firebase_id}/${
        '_' + file.name
      }`
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef.child(storageUrl).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.setState({ loading: false })
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // console.log(downloadURL)
            let temp = { ...self.state.fileAttached }
            temp.downloadURL = downloadURL
            temp.fileName = file.name
            self.setState({ fileAttached: temp, loading: false })
          })
        },
      )
    }
  }

  getAppointmentData() {
    let { token, patient, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'providers/patients/appointments'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patient.uid,
      },
    })
      .then((response) => {
        self.setState({ appointmentData: response.data })
      })
      .catch((err) => {
        console.log('error when getting appointment information', err)
        toast.error('Something went wrong')
      })
  }

  getCarePlanTemplate() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'careplan-template'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then(({ data }) => {
        if (data?.physical_exam_template?.length) {
          let optionObjects = []
          // data?.physical_exam_template?.forEach(option => data.push({label: option, value: option}))
          optionObjects = data?.physical_exam_template.map((option) => {
            let keyLabel = Object.keys(option)[0].replace(/_/g, ' ')
            return {
              label: keyLabel.charAt(0).toUpperCase() + keyLabel.substring(1),
              value: Object.values(option)[0],
            }
          })
          self.setState({ carePlanTemplate: optionObjects })
        }
      })
      .catch((err) => {
        console.log('error when getting careplan template', err)
      })
  }

  renderProductPricingModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1%',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.closeAllModals()
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Product Pricing
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            padding: '5% 1%',
          }}
        >
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Product Name</p>
            {/* <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>User Name</p> */}
            <input
              placeholder={'Enter visit title'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.visitName}
              onChange={(e) => this.setState({ visitName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Visit Length</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select'}
                isMulti={false}
                options={Metadata.visitLengths}
                value={
                  this.state.visitLength
                    ? {
                        label: this.state.visitLength,
                        value: this.state.visitLength,
                      }
                    : null
                }
                onChange={(val) => this.setState({ visitLength: val.label })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Visit Type</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select'}
                isMulti={false}
                options={[
                  { label: 'New Patient Visit', value: 'New Patient Visit' },
                  {
                    label: 'Established Patient Visit',
                    value: 'Established Patient Visit',
                  },
                ]}
                value={
                  this.state.visitType
                    ? {
                        label: this.state.visitType,
                        value: this.state.visitType,
                      }
                    : null
                }
                onChange={(val) => this.setState({ visitType: val.label })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Specialty</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select'}
                isMulti={false}
                options={Metadata.SPECIALITY_OPTIONS}
                value={
                  this.state.visitSpecialty
                    ? {
                        label: this.state.visitSpecialty,
                        value: this.state.visitSpecialty,
                      }
                    : null
                }
                onChange={(val) => this.setState({ visitSpecialty: val.label })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>CPT</p>
            {/* <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>User Name</p> */}
            <input
              placeholder={'Enter CPT'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.visitCpt}
              onChange={(e) =>
                this.setState({ visitSpecialty: e.target.value })
              }
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Modifier</p>
            {/* <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>User Name</p> */}
            <input
              placeholder={'Enter Modifier'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.visitModifier}
              onChange={(e) => this.setState({ visitModifier: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Description for Bill</p>
            <textarea
              placeholder="Enter Description"
              cols="20"
              rows="4"
              className={Styles.studyEntryInput}
              // style={{
              //   width: '100%',
              //   borderBlockColor: 'lightgray',
              //   borderRadius: '5px',
              //   resize: 'none',
              // }}
              value={this.state.visitDescription}
              onChange={(e) => {
                this.setState({ visitDescription: e.target.value })
              }}
            ></textarea>

            {/* <input
              placeholder={'Enter Description'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.visitDescription}
              onChange={(e) =>
                this.setState({ visitDescription: e.target.value })
              }
            /> */}
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Bill Amount</p>
            {/* <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>User Name</p> */}
            <input
              placeholder={'Enter bill amount'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.visitBill}
              onChange={(e) => this.setState({ visitBill: e.target.value })}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              let temp = [...this.state.productPricing] || []

              if (this.state.selectedProduct) {
                temp[this.state.visitIndex] = {
                  visitName: this.state.visitName,
                  visitBill: this.state.visitBill,
                  visitLength: this.state.visitLength,
                  visitType: this.state.visitType,
                  visitSpecialty: this.state.visitSpecialty,
                  visitCpt: this.state.visitCpt,
                  visitModifier: this.state.visitModifier,
                  visitDescription: this.state.visitDescription,
                }
              } else {
                console.log('add product')
                temp.push({
                  visitName: this.state.visitName,
                  visitBill: this.state.visitBill,
                  visitLength: this.state.visitLength,
                  visitType: this.state.visitType,
                  visitSpecialty: this.state.visitSpecialty,
                  visitCpt: this.state.visitCpt,
                  visitModifier: this.state.visitModifier,
                  visitDescription: this.state.visitDescription,
                })
              }
              console.log('save', temp)
              this.setState({ productPricing: temp })
              this.closeAllModals()

              // this.saveUserType(toSave)
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  renderRolesPermissionsModal() {
    let disabled = this.state.selectedUser?.firebase_id ? true : false
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1%',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.closeAllModals()
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add/ Edit User Profiles
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            padding: '5% 1%',
          }}
        >
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>First Name</p>
            {/* <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>User Name</p> */}
            <input
              placeholder={'Enter first name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.roleFistName}
              onChange={(e) => this.setState({ roleFistName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Last Name</p>
            {/* <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>User Name</p> */}
            <input
              placeholder={'Enter last name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.roleLastName}
              onChange={(e) => this.setState({ roleLastName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>User Email</p>
            <input
              placeholder={'Enter email'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.roleEmail}
              disabled={disabled}
              onChange={(e) => this.setState({ roleEmail: e.target.value })}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Role</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select Role'}
                isMulti={false}
                options={Metadata.userTypes}
                value={
                  this.state.role
                    ? { label: this.state.role, value: this.state.role }
                    : null
                }
                onChange={(val) => this.setState({ role: val.label })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
              Supervising Provider
              <input
                type="checkbox"
                checked={this.state.supervisor}
                onClick={() => {
                  this.setState({ supervisor: !this.state.supervisor })
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              let temp = [...this.state.rolesPermissions]
              let toSave = {}

              if (this.state.selectedUser?.firebase_id) {
                temp[this.state.roleIndex] = {
                  first_name: this.state.roleFistName,
                  last_name: this.state.roleLastName,
                  email: this.state.roleEmail,
                  role: this.state.role,
                  supervisor: this.state.supervisor,
                }
                toSave.firebase_id = this.state.selectedUser?.firebase_id
                toSave.first_name = this.state.roleFistName
                toSave.last_name = this.state.roleLastName
                toSave.user_type = this.state.role
                toSave.supervisor = this.state.supervisor

                this.saveUserType(toSave)
              } else {
                console.log('add profile')
                this.createProvider()
              }
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  renderAddFacilitiesModal() {
    let disabled = this.state.selectedUser?.firebase_id ? true : false
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1%',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.closeAllModals()
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add/ Edit Facilities Profiles
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            padding: '5% 1%',
          }}
        >
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Name</p>
            <input
              placeholder={'Enter name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.facilityName}
              onChange={(e) => this.setState({ facilityName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Address Line 1</p>
            <input
              placeholder={'Enter address line 1'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.facilityAddress}
              onChange={(e) =>
                this.setState({ facilityAddress: e.target.value })
              }
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Address Line 2</p>
            <input
              placeholder={'Enter address line 2'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.facilityAddress2}
              onChange={(e) =>
                this.setState({ facilityAddress2: e.target.value })
              }
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Npi</p>
            <input
              placeholder={'Enter address line 2'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.facilityNpi}
              onChange={(e) => this.setState({ facilityNpi: e.target.value })}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Zip</p>
            <input
              placeholder={'Enter address line 2'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.facilityZip}
              onChange={(e) => this.setState({ facilityZip: e.target.value })}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>State</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select State'}
                isMulti={false}
                options={Metadata.stateResidence}
                value={this.state.facilityStateObject}
                onChange={(val) =>
                  this.setState({ facilityStateObject: val.label })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              toast.error('Error creating/updating facility!')
              const { token, adminId } = this.props
              const { facilityName } = this.state
              axios({
                method: 'put',
                url: `${Config.BACKEND_URL}facilities`,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  Accept: '*/*',
                  Authorization: `JWT ${token}`,
                  x_firebase_id: adminId,
                },
                data: {
                  // id:  TODO: Store this when loading the information
                  name: facilityName,
                },
              })
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  sortListByDate() {
    const { allCommunications, sortValue } = this.state

    if (sortValue) {
      allCommunications.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at),
      )
    } else {
      allCommunications.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      )
    }
    this.setState({ sortValue: !sortValue })

    this.setState({ allCommunications })
  }

  TableHeader(tableType) {
    if (tableType === 'roles_permissions') {
      return (
        <div
          className={Styles.contentRow}
          //   onClick={this.sortListByDate.bind(this)}
          style={{
            cursor: 'pointer',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // color: 'gray',
            // width: '15%',
            // textAlign: 'center',
          }}
        >
          <h6 className={Styles.smtableHeader}>
            Action
            {/* <span>{this.state.sortValue ? `↓` : `↑`}</span> */}
          </h6>
          <h6 className={Styles.mtableHeader}>User Name</h6>
          <h6 className={Styles.mtableHeader}>User Email</h6>
          <h6 className={Styles.xtableHeader}>Role</h6>

          {/* <h6 className={Styles.xtableHeader}>Message</h6> */}
          {/* <h6 className={Styles.mtableHeader}>Attachment</h6> */}
        </div>
      )
    } else if (tableType === 'insurance_plans') {
      return (
        <div
          className={Styles.contentRow}
          //   onClick={this.sortListByDate.bind(this)}
          style={{
            cursor: 'pointer',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // color: 'gray',
            // width: '15%',
            // textAlign: 'center',
          }}
        >
          <h6 className={Styles.mtableHeader}>
            Number
            {/* <span>{this.state.sortValue ? `↓` : `↑`}</span> */}
          </h6>
          <h6 className={Styles.xtableHeader}>Plan Name</h6>
          <h6 className={Styles.mtableHeader}>Action</h6>
        </div>
      )
    } else if (tableType === 'product_pricing') {
      return (
        <div
          className={Styles.contentRow}
          //   onClick={this.sortListByDate.bind(this)}
          style={{
            cursor: 'pointer',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // color: 'gray',
            // width: '15%',
            // textAlign: 'center',
          }}
        >
          <h6 className={Styles.smtableHeader}>
            Action
            {/* <span>{this.state.sortValue ? `↓` : `↑`}</span> */}
          </h6>
          <h6 className={Styles.mtableHeader}>Visit Length</h6>
          <h6 className={Styles.mtableHeader}>Type</h6>
          <h6 className={Styles.xtableHeader}>Description</h6>
          <h6 className={Styles.mtableHeader}>CPT Code</h6>
          <h6 className={Styles.mtableHeader}>Bill Amount</h6>
        </div>
      )
    } else if (tableType === 'facilities') {
      return (
        <div
          className={Styles.contentRow}
          //   onClick={this.sortListByDate.bind(this)}
          style={{
            cursor: 'pointer',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // color: 'gray',
            // width: '15%',
            // textAlign: 'center',
          }}
        >
          <h6 className={Styles.smtableHeader}>Action</h6>
          <h6 className={Styles.mtableHeader}>Facility Name</h6>
          <h6 className={Styles.mtableHeader}>Facility Npi</h6>
          <h6 className={Styles.xtableHeader}>Facility State</h6>
          <h6 className={Styles.xtableHeader}>Facility Zip</h6>
        </div>
      )
    }
  }

  TableContents = (tableType) => {
    let {
      allCommunications,
      communicationType,
      patientList,
      searchEmail,
      searchName,
      acceptedInsurance,
    } = this.state
    let tableContentElement = []
    let tempArray = []
    tempArray =
      allCommunications &&
      (communicationType?.value === 'Email'
        ? allCommunications?.filter(
            (comm) => comm?.communication_type === 'Email',
          )
        : allCommunications?.filter(
            (comm) => comm?.communication_type === 'Phone',
          ))

    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: [
        'to_emails',
        'from_email',
        'email_address',
        'phone_name',
        'phone_number',
      ],
    }

    this.fuse = new Fuse(tempArray, options)

    let uniqueList = []

    if (searchEmail.length !== 0) {
      const results = this.fuse.search(searchEmail)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else if (searchName.length !== 0) {
      const results = this.fuse.search(searchName)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else {
      uniqueList = tempArray
    }

    uniqueList =
      tableType === 'roles_permissions'
        ? // ? this.state.rolesPermissions || []
          this.state.providersList || []
        : tableType === 'insurance_plans'
        ? this.state.acceptedInsurance || []
        : tableType === 'facilities'
        ? this.state.allFacilities || []
        : this.state.productPricing || []
    tableContentElement = uniqueList.map((commData, index) => {
      if (tableType === 'roles_permissions') {
        return (
          <div key={index} className={Styles.contentRow}>
            <h7
              className={Styles.smtableData}
              // style={{ color: 'blue', textDecoration: 'underline' }}
            >
              <div
                className={Styles.externalTableEntry}
                onClick={() => {
                  this.props.toggleSidebar(true)
                  this.setState({
                    rolesModalOpen: true,
                    roleFistName: commData.first_name || '',
                    roleLastName: commData.last_name || '',
                    roleEmail: commData.email,
                    role: commData.user_type,
                    supervisor: commData.supervisor || false,
                    roleIndex: index,
                    selectedUser: commData,
                  })
                }}
                style={{ cursor: 'pointer', width: '5%', margin: 'auto' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            </h7>
            <h7 className={Styles.mtableHeader}>
              {commData.first_name || '' + ' '} {commData.last_name || ''}
            </h7>
            <h7 className={Styles.mtableData}>{commData.email || ''}</h7>
            <h7 className={Styles.xtableData}>
              {commData.user_type}{' '}
              {commData.supervisor && ',Supervising Provider'}
            </h7>
          </div>
        )
      } else if (tableType === 'insurance_plans') {
        let patientPhone = ''
        let patientNum = ''
        if (commData?.phone_type === 'Other') {
          patientPhone = commData?.phone_name
          // + '\n\n' + commData?.phone_number
          patientNum = commData?.phone_number
        } else {
          let patient = patientList?.find(
            (item) => item.firebase_id === commData.patient_id,
          )
          patientPhone = patient?.label
          // + '\n\n' + patient?.phoneNumber
          patientNum = patient?.phoneNumber
        }
        return (
          <div key={index} className={Styles.contentRow}>
            <h7 className={Styles.mtableData}>{commData.isp}</h7>
            <h7 className={Styles.xtableData}>
              {commData?.name[1] || ''}, {commData?.state || ''}
            </h7>
            <h7
              className={Styles.mtableData}
              style={{
                color: 'red',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                let temp = [...acceptedInsurance]
                temp.splice(index, 1)
                this.setState({ acceptedInsurance: temp })
              }}
            >
              Delete
            </h7>
          </div>
        )
      } else if (tableType === 'facilities') {
        return (
          <div key={index} className={Styles.contentRow}>
            <h7
              className={Styles.smtableData}
              // style={{ color: 'blue', textDecoration: 'underline' }}
            >
              <div
                className={Styles.externalTableEntry}
                onClick={() => {
                  let stateObj = Metadata.stateResidence.filter(
                    (obj) => obj.abbreviation === commData.state_code,
                  )
                  console.log('StateObj', stateObj)
                  this.props.toggleSidebar(true)
                  this.setState({
                    facilitiesModalOpen: true,
                    facilityAddress: commData.address,
                    facilityAddress2: commData.address2,
                    facilityName: commData.name,
                    facilityStateObject: stateObj,
                    facilityNpi: commData.npi,
                    facilityZip: commData.zip,
                  })
                }}
                style={{ cursor: 'pointer', width: '5%', margin: 'auto' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            </h7>
            <h7 className={Styles.mtableHeader}>{commData.name || ''}</h7>
            <h7 className={Styles.mtableData}>{commData.npi || ''}</h7>
            <h7 className={Styles.xtableData}>{commData.state}</h7>
            <h7 className={Styles.xtableData}>{commData.zip}</h7>
          </div>
        )
      } else {
        return (
          <div key={index} className={Styles.contentRow}>
            <h7
              className={Styles.smtableData}
              // style={{ color: 'blue', textDecoration: 'underline' }}
            >
              <div
                className={Styles.externalTableEntry}
                onClick={() => {
                  this.props.toggleSidebar(true)
                  this.setState({
                    productPricingModal: true,
                    visitName: commData.visitName,
                    visitBill: commData.visitBill,
                    visitLength: commData.visitLength,
                    visitType: commData.visitType,
                    visitSpecialty: commData.visitSpecialty,
                    visitCpt: commData.visitCpt,
                    visitModifier: commData.visitModifier,
                    visitDescription: commData.visitDescription,
                    visitIndex: index,
                    selectedProduct: commData,
                  })
                }}
                style={{ cursor: 'pointer', width: '5%', margin: 'auto' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            </h7>
            <h7 className={Styles.mtableHeader}>
              {commData?.visitLength || ''}
            </h7>
            <h7 className={Styles.mtableHeader}>
              {commData?.visitSpecialty || ''}
            </h7>
            <h7 className={Styles.xtableData}>{commData?.visitDescription}</h7>
            <h7 className={Styles.mtableHeader}>{commData?.visitCpt || ''}</h7>

            <h7 className={Styles.mtableData}>{commData?.visitBill || ''}</h7>
          </div>
        )
      }
    })
    return tableContentElement
  }

  renderViewCommunications() {
    return (
      <div
        style={{
          width: '100%',
          margin: 10,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={Styles.contentBackground}>
          {this.TableHeader()}
          {this.TableContents()}
        </div>
      </div>
    )
  }

  renderInsurancePlans() {
    return (
      <div
        style={{
          width: '100%',
          margin: 10,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={Styles.contentBackground}>
          {this.TableHeader('insurance_plans')}
          {this.TableContents('insurance_plans')}
        </div>
      </div>
    )
  }

  renderProductPricing() {
    return (
      <div
        style={{
          width: '100%',
          margin: 10,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={Styles.contentBackground}>
          {this.TableHeader('product_pricing')}
          {this.TableContents('product_pricing')}
        </div>
      </div>
    )
  }

  renderPolicies() {
    let { policies } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '60%',
          margin: '0% 3%',
        }}
      >
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>HIPAA Privacy Policy</p>
          <input
            placeholder={'Enter Link'}
            className={Styles.studyEntryInput}
            type="text"
            value={policies?.hipaa_privacy}
            onChange={(e) => {
              let temp = policies
              temp.hipaa_privacy = e.target.value
              this.setState({ policies: temp })
            }}
          />
        </div>
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>Financial Policy</p>
          <input
            placeholder={'Enter Link'}
            className={Styles.studyEntryInput}
            type="text"
            value={policies?.financial}
            onChange={(e) => {
              let temp = policies
              temp.financial = e.target.value
              this.setState({ policies: temp })
            }}
          />
        </div>
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>Cancellation Policy</p>
          <input
            placeholder={'Enter Link'}
            className={Styles.studyEntryInput}
            type="text"
            value={policies?.cancellation}
            onChange={(e) => {
              let temp = policies
              temp.cancellation = e.target.value
              this.setState({ policies: temp })
            }}
          />
        </div>{' '}
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>
            Release of Medical Records Form
          </p>
          <input
            placeholder={'Enter Link'}
            className={Styles.studyEntryInput}
            type="text"
            value={policies?.release_medical_records}
            onChange={(e) => {
              let temp = policies
              temp.release_medical_records = e.target.value
              this.setState({ policies: temp })
            }}
          />
        </div>{' '}
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>
            Telehealth Informed Consent Policy
          </p>
          <input
            placeholder={'Enter Link'}
            className={Styles.studyEntryInput}
            type="text"
            value={policies?.telehealth_consent}
            onChange={(e) => {
              let temp = policies
              temp.telehealth_consent = e.target.value
              this.setState({ policies: temp })
            }}
          />
        </div>{' '}
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>No Surprises Act</p>
          <input
            placeholder={'Enter Link'}
            className={Styles.studyEntryInput}
            type="text"
            value={policies?.no_surprises_act}
            onChange={(e) => {
              let temp = policies
              temp.no_surprises_act = e.target.value
              this.setState({ policies: temp })
            }}
          />
        </div>
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>FAQ Page Link</p>
          <input
            placeholder={'Enter Link'}
            className={Styles.studyEntryInput}
            type="text"
            value={policies?.faq_page}
            onChange={(e) => {
              let temp = policies
              temp.faq_page = e.target.value
              this.setState({ policies: temp })
            }}
          />
        </div>
      </div>
    )
  }
  renderBilling() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
          margin: '0% 3%',
        }}
      >
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>
            Link bank account via stripe
            <br />
            Create connected account
          </p>
          <input
            placeholder={'Enter Account Number'}
            className={Styles.studyEntryInput}
            type="text"
            value={this.state.billingPayouts?.account_number || ''}
            onChange={(e) => {
              let temp = { ...this.state.billingPayouts }
              temp.account_number = e.target.value
              this.setState({ billingPayouts: temp })
            }}
          />
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginRight: 5,
              marginLeft: '-4%',
            }}
          />
        </div>
        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>Billing Phone Number</p>
          <input
            placeholder={'Enter Billing Phone Number'}
            className={Styles.studyEntryInput}
            type="text"
            value={this.state.billingPayouts?.phone_number || ''}
            onChange={(e) => {
              let temp = { ...this.state.billingPayouts }
              temp.phone_number = e.target.value
              this.setState({ billingPayouts: temp })
            }}
          />
        </div>
      </div>
    )
  }
  renderRoles() {
    return (
      <div
        style={{
          width: '100%',
          margin: 10,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={Styles.contentBackground}>
          {this.TableHeader('roles_permissions')}
          {this.TableContents('roles_permissions')}
        </div>
      </div>
    )
  }

  renderProfile() {
    let logo_image
    if (this.state.imageUploaded) logo_image = this.state.imageLocation
    else logo_image = this.state.image || PlaceHolderProfileImage
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '90%',
          margin: '0% 3%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Practice Name</p>
            <input
              placeholder={'Enter Practice Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.practiceName}
              onChange={(e) => this.setState({ practiceName: e.target.value })}
            />
          </div>
          {/*  ADDRESS */}
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Address Line 1</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter Address"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.address1}
                onChange={(e) => {
                  this.setState({ address1: e.target.value })
                }}
              />
              {/* <GooglePlacesAutocomplete
                apiKey={Config.GOOGLE_API_KEY}
                selectProps={{
                  placeholder: this.state.address || 'Select Address',
                  value: this.state.address,
                  onChange: (picked = null) => {
                    //get ADDRESS COMPONENT
                    geocodeByAddress(picked.label)
                      .then((details) => {
                        // 'details' is provided when fetchDetails = true
                        const zip = details[0]?.address_components.find(
                          (addressComponent) =>
                            addressComponent.types.includes('postal_code'),
                        )?.short_name
                        const address = picked.value.description || ''
                        this.setState({ address: address, postalCode: zip })

                        const addressComponents =
                          details && details[0].address_components
                        this.setState({
                          addressComponents: addressComponents,
                        })
                      })
                      .catch((error) => console.error(error))
                  },
                }}
              /> */}
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Address Line 2</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter Address"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.address2}
                onChange={(e) => {
                  this.setState({ address2: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>City</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter City"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.city}
                onChange={(e) => {
                  this.setState({ city: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>State</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder="Select State"
                inputId="primarystate"
                options={Metadata.stateResidence}
                value={
                  this.state.state
                    ? {
                        label: this.state.state,
                        value: this.state.state,
                        abbreviation: this.state.state,
                      }
                    : null
                }
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ state: val.abbreviation })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Zip Code</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter Zip Code"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.postalCode}
                onChange={(e) => {
                  this.setState({ postalCode: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Phone Number</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter Phone Number"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.phoneNumber}
                onChange={(e) => {
                  this.setState({ phoneNumber: e.target.value })
                }}
              />
            </div>
          </div>{' '}
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Fax Number</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter Fax Number"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.faxNumber}
                onChange={(e) => {
                  this.setState({ faxNumber: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Tax Entity</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter Tax Entity"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.taxEntity}
                onChange={(e) => {
                  this.setState({ taxEntity: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Specialty Group NPI</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter NPI"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.groupNpi}
                onChange={(e) => {
                  this.setState({ groupNpi: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>TIN Service States</p>
            <div style={{ width: '60%' }}>
              {/* <div style={{ width: '50%', marginRight: '2%' }}> */}
              <Select
                placeholder="Select State"
                inputId="primarystate"
                options={Metadata.stateResidence}
                value={this.state.serviceStates}
                isMulti={true}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ serviceStates: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <TaskModal
            modalIsOpen={this.state.modalAddTaskIsOpen}
            hideTaskModal={this.hideTaskModal}
            getUserTask={this.getUserTask}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Logo</p>
            <div
              style={{
                width: '60%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src={logo_image}
                style={{ height: 100, width: 100, objectFit: 'cover' }}
                alt="No Image"
              />
              <input
                onChange={(e) => {
                  this.setState({ logoUploaded: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.imageUploadRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.imageUploadRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload/Change Logo
              </p>
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Branding Color</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter Hex Code #"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.brandingColor}
                onChange={(e) => {
                  this.setState({ brandingColor: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Client Portal Login Link</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Generated by us"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                //   value={this.state.brandingColor}
                //   onChange={(e) => {
                //     this.setState({ brandingColor: e.target.value })
                //   }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Instagram</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter link"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.instagramLink}
                onChange={(e) => {
                  this.setState({ instagramLink: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Facebook</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter link"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.facebookLink}
                onChange={(e) => {
                  this.setState({ facebookLink: e.target.value })
                }}
              />
            </div>
          </div>{' '}
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>Twitter</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter link"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.twitterLink}
                onChange={(e) => {
                  this.setState({ twitterLink: e.target.value })
                }}
              />
            </div>
          </div>
          <div className={Styles.studyEntryRow}>
            <p className={Styles.studyEntryKey}>YouTube</p>
            <div style={{ width: '60%' }}>
              <input
                placeholder="Enter link"
                className={Styles.studyEntryInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.youtubeLink}
                onChange={(e) => {
                  this.setState({ youtubeLink: e.target.value })
                }}
              />
            </div>
          </div>
        </div>{' '}
      </div>
    )
  }

  renderFacilities() {
    return (
      <div
        style={{
          width: '100%',
          margin: 10,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={Styles.contentBackground}>
          {this.TableHeader('facilities')}
          {this.TableContents('facilities')}
        </div>
      </div>
    )
  }

  renderSearchList() {
    const {
      searchName,
      searchAddress,
      searchCity,
      searchState,
      searchList,
      acceptedInsurance,
    } = this.state

    const renderList = (insuranceData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.insuranceList}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            backgroundColor: Constants.contentBackground,
            borderRadius: Constants.borderRadius,
            padding: '1% 2%',
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
              }}
            >
              <h6 style={{ color: Constants.primaryThemeDark }}>
                {insuranceData?.name[1]}
                {/* {'\n '}
                  Visit with {insuranceData?.first_name}{' '}
                  {insuranceData?.last_name} */}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.address_1 || ''} ${
                  insuranceData?.city || ''
                }`}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.state || ''} ${insuranceData?.zip || ''} `}
              </h6>
            </div>

            <Button
              className={GlobalStyles.button}
              //   disabled={loading}
              onClick={() => {
                let temp = [...acceptedInsurance]
                temp.push(insuranceData)
                this.setState({ acceptedInsurance: temp })
                this.closeAllModals()
              }}
              style={{
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
            >
              Add
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Insurance
        </h4>
        <div className={Styles.row} style={{ margin: '2% 0%' }}>
          <p className={Styles.rowLabel}>Name</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchName}
              onChange={(e) => {
                this.setState({ searchName: e.target.value })
              }}
            />
          </div>
          {/* <p className={Styles.rowLabel}>Address</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Address"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchAddress}
              onChange={(e) => {
                setSearchAddress(e.target.value)
              }}
            />
          </div> */}
          <p className={Styles.rowLabel}>City</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search City"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchCity}
              onChange={(e) => {
                this.setState({ searchCity: e.target.value })
              }}
            />
          </div>

          <p className={Styles.rowLabel}>State</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <Select
              placeholder="Select State"
              inputId="primarystate"
              options={Metadata.stateResidence}
              value={searchState}
              isMulti={false}
              isSearchable
              autoBlur
              onChange={(val) => {
                this.setState({ searchState: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <FontAwesomeIcon
            onClick={() => {
              //   setActiveInsurance(1)
              console.log(
                'SEARCH PARAMS:',
                searchAddress,
                searchName,
                searchCity,
              )
              this.onSearch()
              //   setShowSearchList(true)
            }}
            icon={faSearch}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </div>

        <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {searchList?.length !== 0 ? (
            searchList?.map((insurance, index) => renderList(insurance, index))
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search by city, name and address
            </p>
          )}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h4
          style={{
            color: Constants.primaryTheme,
            width: '100%',
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          Practice Info
        </h4>

        <div style={{ width: '100%', margin: '2%', position: 'relative' }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'left',
              textDecoration: 'underline',
            }}
          >
            Profile
          </h5>
        </div>

        {this.renderProfile()}
        <div className={Styles.row}>
          <p
            style={{
              borderBottom: '1px solid #d0d0d0',
              width: '90%',
              margin: 'auto',
            }}
          ></p>
        </div>

        {/* Facilities */}
        <div style={{ width: '100%', margin: '2%', position: 'relative' }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'left',
              textDecoration: 'underline',
            }}
          >
            Facilities
          </h5>
          <div style={{ position: 'absolute', top: 0, right: '5%' }}>
            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.setState({ facilitiesModalOpen: true })
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {this.renderFacilities()}
        <div className={Styles.row}>
          <p
            style={{
              borderBottom: '1px solid #d0d0d0',
              width: '90%',
              margin: 'auto',
            }}
          ></p>
        </div>

        <div style={{ width: '100%', margin: '2%', position: 'relative' }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'left',
              textDecoration: 'underline',
            }}
          >
            Roles & Permissions
          </h5>
          <div style={{ position: 'absolute', top: 0, right: '5%' }}>
            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.setState({ rolesModalOpen: true })
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {this.renderRoles()}
        <div className={Styles.row}>
          <p
            style={{
              borderBottom: '1px solid #d0d0d0',
              width: '90%',
              margin: 'auto',
            }}
          ></p>
        </div>

        <div style={{ width: '100%', margin: '2%', position: 'relative' }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'left',
              textDecoration: 'underline',
            }}
          >
            Billing & Payouts
          </h5>
        </div>

        {this.renderBilling()}
        <div className={Styles.row}>
          <p
            style={{
              borderBottom: '1px solid #d0d0d0',
              width: '90%',
              margin: 'auto',
            }}
          ></p>
        </div>

        <div style={{ width: '100%', margin: '2%', position: 'relative' }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'left',
              textDecoration: 'underline',
            }}
          >
            Policies & Forms
          </h5>
        </div>

        {this.renderPolicies()}
        <div className={Styles.row}>
          <p
            style={{
              borderBottom: '1px solid #d0d0d0',
              width: '90%',
              margin: 'auto',
            }}
          ></p>
        </div>

        <div style={{ width: '100%', margin: '2%', position: 'relative' }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'left',
              textDecoration: 'underline',
            }}
          >
            Accepted Insurance Plans
          </h5>
          <div style={{ position: 'absolute', top: 0, right: '5%' }}>
            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.setState({ insuranceModalOpen: true })
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {this.renderInsurancePlans()}
        <div className={Styles.row}>
          <p
            style={{
              borderBottom: '1px solid #d0d0d0',
              width: '90%',
              margin: 'auto',
            }}
          ></p>
        </div>

        <div style={{ width: '100%', margin: '2%', position: 'relative' }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'left',
              textDecoration: 'underline',
            }}
          >
            Product & Appointment Pricing
          </h5>
          <div style={{ position: 'absolute', top: 0, right: '5%' }}>
            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.setState({ productPricingModal: true })
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {this.renderProductPricing()}

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={this.onSavePressed.bind(this)}
            className={GlobalStyles.button}
            disabled={this.state.loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {this.state.loading ? 'Loading....' : 'Save'}
          </Button>
        </div>

        {/* ROLES AND PERMISSIONS MODAL */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.rolesModalOpen}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderRolesPermissionsModal()}
        </Modal>

        {/* FACILITIES MODAL */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.facilitiesModalOpen}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderAddFacilitiesModal()}
        </Modal>

        {/* ACCEPTED INSURANCES SEARCH */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.insuranceModalOpen}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderSearchList()}
        </Modal>
        {/* PRODUCT PRICING */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.productPricingModal}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderProductPricingModal()}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeInfo)
