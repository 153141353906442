import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Config from '../../config'
import {
  addPatientList,
  fetchPatientCarePlanData,
  setAppointmentNotesData,
  toggleSidebar,
} from '../../redux/actions'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/cms.module.scss'
import moment from 'moment'
import addresser from 'addresser'

const axios = require('axios')

export default class FeeSchedule extends Component {
  constructor(props) {
    super(props)
    // let notesData = this.props?.carePlanNotesData
    // let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      fees: [
        {
          // These are the fields
          code: '',
          description: '',
          patientType: '',
          patientTypeObj: null,
          minTime: '',
          maxTime: '',
          units: '',
          insuranceBillRate: '',
          rateWithDiscount: '',
        },
      ],
    }
    // this.parseDate = this.parseDate.bind(this)
    this.saveToBackend = this.saveToBackend.bind(this)
  }

  componentDidMount() {
    console.log('mounted')
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}fees/schedule`,
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((res) => {
      console.log('res', res)
      if (res?.data?.success) {
        let fees = res?.data?.data
        this.setState({ fees })
      } else {
        toast.error(
          res?.data?.message || "Error while fetching patient's data!",
        )
      }
    })
  }

  // parseDate = (date) => {
  //   console.log('got date', date)
  //   if (date === 'Invalid date') {
  //     toast.error("Patient's date of birth is invalid!")
  //     return new Date()
  //   }
  //   return moment(date, 'DD/MM/YYYY').toDate()
  // }

  saveToBackend = async () => {
    let { fees } = this.state

    axios({
      method: 'post',
      url: `${Config.BACKEND_URL}fees/schedule`,
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      data: {
        fees,
      },
    }).then((res) => {
      console.log('res', res)
      if (res?.data?.success) {
        toast.success('Successfully saved!')
      } else {
        toast.error(
          res?.data?.message || "Error while fetching patient's data!",
        )
      }
    })
  }

  render() {
    // if (this.state.loading) {
    //   return (
    //     <div
    //       className={GlobalStyles.container}
    //       style={{ overflow: 'hidden', maxHeight: '100vh' }}
    //     >
    //       <div
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //           minWidth: '100%',
    //           height: '80vh',
    //         }}
    //       >
    //         <div className={GlobalStyles.loader} />
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <h4
            style={{
              color: Constants.primaryTheme,
              width: '100%',
              marginBottom: 10,
              textAlign: 'center',
            }}
          >
            Fee Schedule
          </h4>
        </div>

        {/* Form Elements */}
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Code</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Description</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Patient Type</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Min Time</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Max Time</p>
            </div>

            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Units</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Insurance Bill Rate</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Rate w/ Discount</p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                onClick={() => {
                  let { fees } = this.state
                  fees.push({
                    // These are the fields
                    code: '',
                    description: '',
                    patientType: '',
                    patientTypeObj: null,
                    minTime: '',
                    maxTime: '',
                    units: '',
                    insuranceBillRate: '',
                    rateWithDiscount: '',
                  })
                  this.setState({ fees })
                }}
                icon={faPlusCircle}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 18,
                  // right: 14,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
          {/* Row 16: 2 columns */}
          {this.state.fees.map((diagnosis, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderTop: '2px solid #20A892',
                }}
              >
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Code'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.code}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].code = e.target.value
                      this.setState({ fees })
                    }}
                    on
                    // onBlur={this.saveToBackend(diagnosis)}
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Description'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.description}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].description = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>
                <div
                  style={{ flex: 1, padding: '10px' }}
                  // style={{
                  //   width: '90px',
                  //   display: 'flex',
                  //   flexDirection: 'column',
                  //   alignItems: 'center',
                  //   justifyContent: 'center',
                  // }}
                >
                  <Select
                    placeholder="Select Type"
                    inputId="patientType"
                    options={[
                      { label: 'New', value: 'new' },
                      { label: 'Established', value: 'established' },
                    ]}
                    value={diagnosis.patientTypeObj}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let { fees } = this.state
                      fees[index].patientTypeObj = val
                      fees[index].patientType = val.value
                      this.setState({ fees })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Min Time'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.minTime}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].minTime = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '175px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    placeholder={'Max Time'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.maxTime}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].maxTime = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>

                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Units'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.units}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].units = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Insurance Bill Rate'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.insuranceBillRate}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].insuranceBillRate = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Rate w/ Discount'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.rateWithDiscount}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].rateWithDiscount = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => {
                      let { fees } = this.state
                      fees.push({
                        code: '',
                        description: '',
                        patientType: '',
                        patientTypeObj: null,
                        minTime: '',
                        maxTime: '',
                        units: '',
                        insuranceBillRate: '',
                        rateWithDiscount: '',
                      })
                      this.setState({ fees })
                    }}
                    icon={faPlusCircle}
                    style={{
                      color: Constants.primaryTheme,
                      fontSize: 18,
                      // right: 14,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>

        <Button
          onClick={() => {
            console.log('Clicked on save!')
            this.saveToBackend()
          }}
          className={GlobalStyles.button}
          style={{
            width: 100,
            position: 'relative',
            margin: 20,
            float: 'right',
          }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    )
  }
}
