import React, { useEffect, useState, useMemo, useRef } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Select from 'react-select'
import Config from '../../config'
import Styles from './styles/Roster.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import placeHolder from '../../assets/images/placeholder.png'
import Button from 'react-bootstrap/Button'
import { stateResidence } from '../../metadata'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from 'react-table'
import {
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  addPatientLP,
  toggleSidebar,
  fetchRpmOrders,
} from '../../redux/actions'
import Constants from '../../values'
import PatientInsurance from '../PatientWeb/PatientAccount/PatientInsurance'
import { rosterColumns } from './RosterColumns'
import Table from '../../components/Table'

const insuranceAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

const LIMIT = 5

function RosterTable(props) {
  const history = useHistory()
  const token = useSelector((state) => state.authReducer.token)
  const { adminId, loggedIn, mongoUser, firebaseUser } = useSelector(
    (state) => state.userReducer,
  )
  const { patient, patientList: patientPropList } = useSelector(
    (state) => state.patientReducer,
  )
  const [loading, setLoading] = useState(false)
  const [speciality, setSpeciality] = useState(null)
  const [memberType, setMemberType] = useState(null)
  const [patientType, setPatientType] = useState(null)
  const [patientState, setPatientState] = useState(null)
  const [patientData, setPatientData] = useState([])
  const [allPatientData, setAllPatientData] = useState([])
  const [allProviderList, setAllProviderList] = useState([])
  const [allProviderData, setAllProviderData] = useState([])
  const [communities, setCommunities] = useState([])
  const [tags, setTags] = useState([])
  const [patientList, setPatientList] = useState(patientPropList || [])
  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false)
  const [editInsurance, setEditInsurance] = useState(null)
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(5)
  const [lastId, setLastId] = useState(null)
  const [next, setNext] = useState(false)
  const selectInputRef = useRef()

  useEffect(() => {
    if (!loggedIn) {
      history.push('/login')
      return
    }
    setLoading(true)
    getAllProviderList()
    getCommunities()
    getTags()
    getUserData()
    if (!patientList.length) {
      getPatientList()
    }
  }, [])

  const COLUMNS = [
    {
      Header: 'Patient Name',
      accessor: (obj) => (
        <Link
          onClick={() => {
            navigatetoPatientProfile(obj.firebase_id)
            onPatientNavLinkClicked('profile')
          }}
          to={{
            pathname: '/patient/profile',
            state: 'info',
            key: 2,
          }}
          className={Styles.link}
        >
          {obj?.firstName || ''} {obj?.lastName || ''}
        </Link>
      ),
      width: '25%',
      // obj.firstName && obj.lastName ? obj.firstName + ' ' + obj.lastName : '',
    },
    {
      Header: 'State',
      accessor: 'stateResidence',
      width: '20%',
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      width: '20%',
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: '30%',
    },
    {
      Header: 'Membership',
      accessor: (obj) =>
        obj?.coaching?.active ? obj?.coaching?.plan || 'membership' : 'basic',
      width: '20%',
    },
    {
      Header: 'Member Since',
      accessor: (obj) =>
        obj?.coaching?.active
          ? moment(obj?.coaching?.start).format('ll')
          : moment(obj.created_at).format('ll'),
      width: '20%',
    },
    {
      Header: 'ID Added',
      accessor: (obj) =>
        obj?.id_front_image_url || obj?.id_front_image_url ? 'Yes' : 'No',
      width: '15%',
    },
    {
      Header: 'Payment Details Added',
      accessor: (obj) =>
        obj?.stripe && obj?.stripe?.customer_id ? 'Yes' : 'No',
      width: '15%',
    },
    {
      Header: 'Insurance',
      accessor: (obj) => (
        <>
          {obj?.insurances?.length
            ? obj?.insurances
                .map((insurance) => insurance.insurance_provider)
                .join(',')
            : '-'}

          <br />
          {obj?.insurances?.length
            ? obj?.insurances
                .map((insurance) =>
                  insurance.preference === 1 && insurance.benefits
                    ? 'Copay:$' +
                      insurance.benefits?.copay +
                      ' ' +
                      'Coinsurance:' +
                      insurance.benefits?.coinsurance +
                      '% '
                    : '',
                )
                .join('')
            : ''}
        </>
      ),
      width: '30%',
    },

    {
      Header: 'Program',
      accessor: (obj) => {
        let programType = '-'
        if (obj.dac) programType = 'Dac-Patient'
        if (obj.rapilot) programType = 'Ra-Patient'
        if (obj.ccfm) programType = 'CCFM'
        return programType
      },
      width: '20%',
    },
    {
      Header: 'Providers',
      accessor: 'actionColumn',
      Cell: (obj) => {
        // console.log(allProviderData)
        let assignedProvider = allProviderData
          ?.filter((provider) => {
            return obj?.doctor_patients?.includes(provider.firebase_id)
          })
          .map((option) => ({
            label: option?.first_name + ' ' + option?.last_name,
            value: option?.firebase_id,
          }))
        return (
          <Select
            options={allProviderList}
            // className={Styles.addSelectInput}
            autoBlur={true}
            isMulti
            onChange={(val) => {
              let difference = []
              if (val.length > assignedProvider.length) {
                difference = val.filter((x) => !assignedProvider.includes(x)) // calculates diff
                addDoctor(difference[0].value, obj.firebase_id)
              } else {
                difference = assignedProvider.filter((x) => !val.includes(x)) // calculates diff
                removeDoctor(difference[0].value, obj.firebase_id)
              }
            }}
            value={assignedProvider}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      width: '30%',
    },

    {
      Header: 'Cohort',
      accessor: (obj) => {
        let assignedCommunities = communities?.filter((community) => {
          return community?.users?.[obj.firebase_id]
        })

        return (
          <Select
            options={communities}
            // className={Styles.addSelectInput}
            autoBlur={true}
            isMulti
            onChange={(val) => {
              let difference = []
              if (val.length > assignedCommunities.length) {
                difference = val.filter((x) => !assignedCommunities.includes(x)) // calculates diff
                addCommunity(difference[0]._id, obj.firebase_id)
              } else {
                difference = assignedCommunities.filter((x) => !val.includes(x)) // calculates diff
                removeCommunity(difference[0]._id, obj.firebase_id)
              }
            }}
            getOptionValue={(option) => option._id}
            getOptionLabel={(option) => option.title}
            value={assignedCommunities}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      width: '20%',
    },
  ]

  const columns = useMemo(() => COLUMNS, [])

  const dispatch = useDispatch()

  // const handleScroll = (e) => {
  //   const bottom =
  //     Math.round(e.currentTarget.scrollHeight) -
  //       Math.round(e.currentTarget.scrollTop) ===
  //     Math.round(e.currentTarget.clientHeight)
  //   if (bottom) {
  //     console.log('+++++++++Reached bottom++++++++++')
  //     getUserData()
  //   }
  //   return
  // }

  const getPatientList = () => {
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '?access=admin'
        : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })
          setPatientList(data)
          props.addPatientList(data)
        }
      })
      .catch((error) => {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }

  const getUserData = () => {
    // setLoading(true)
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + 'roster/patient',
      headers: {
        x_firebase_id: adminId,
        Authorization: `JWT ${token}`,
        // record_id: lastId,
      },
      //   limit: limit, skip: skip },
    }
    if (lastId) {
      config.headers.record_id = lastId
    }
    axios(config)
      .then(({ data }) => {
        console.log('recieved Patient Records')
        let last_record = data && data[data.length - 1]
        last_record && setLastId(last_record._id)

        let tempData = data
        // console.log(typeof tempData)
        tempData.forEach((user) => {
          // if (user.firebase_id === 'pPuqrbHhGrSjw6vqLGZ4jd0lkK62')
          // console.log('user: ', user)
          let insurancePlanType = []
          let cashPay = 'Insurance Pay'
          if (user?.insurance_type?.hmo) {
            insurancePlanType.push('HMO')
            // console.log('HMO')
          }
          if (user?.insurance_type?.medi_adv) {
            insurancePlanType.push('Medicare Advantage')
            // console.log('MA')
          }
          if (user?.insurance_type?.workers_comp) {
            insurancePlanType.push('Workers comp')
            // console.log('WC')
          }
          if (user?.insurance_type?.cashpay) {
            cashPay = 'Cash Pay'
            // console.log('CP')
          }
          if (user?.medicare) {
            insurancePlanType.push('Medicare')
            // console.log('MEDICARE')
          }
          if (user?.medicaid) {
            insurancePlanType.push('Medicaid')
            // console.log('MEDICAID')
          }
          insurancePlanType = insurancePlanType.toString()
          // console.log(1)
          user.insurancePlanType = insurancePlanType
          // console.log(2)
          user.cashPay = cashPay
          // console.log(3)
        })
        // console.log('-----------------1')
        setPatientData(tempData)
        // console.log('-----------------2')
        setAllPatientData(tempData)
        // console.log('-----------------3')

        // setPatientData(patientData.concat(data))
        // setAllPatientData(allPatientData.concat(data))
        setLoading(false)

        // console.log(patientData)
        // updateSuggesticData(data)
      })
      .catch((err) => {
        console.log('err when fetching all users data: ', err)
        setLoading(false)
      })
  }

  const getAllProviderList = async () => {
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: adminId,
        Authorization: 'JWT ' + token,
        getallproviders: true,
      },
    }
    axios(config)
      .then(({ data }) => {
        let providerOptionList = []
        let allProviderData = data.reduce((data, doc) => {
          data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
          data[doc.user_type || 'Other'].push({
            label: doc?.first_name + ' ' + doc?.last_name,
            value: doc?.firebase_id,
          })
          return data
        }, Object.create(null))
        Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
          providerOptionList.push({
            label: userType,
            options: doctorArray,
          })
        })
        setAllProviderList(providerOptionList)
        setAllProviderData(data)
      })
      .catch((err) => {
        console.log('err when fetching all provider data: ', err)
      })
  }

  const getCommunities = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'communities',
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
      },
    })
      .then(({ data }) => {
        setCommunities([...data?.otherCommunities, ...data?.userCommunities])
      })
      .catch((err) => console.log('error when getting communtieis', err))
  }

  const getTags = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'tags',
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
      },
    })
      .then(({ data }) => {
        console.log(data)
        setTags(data)
      })
      .catch((err) => console.log('error when getting communtieis', err))
  }

  // const updateSuggesticData = (patientData) => {
  //   let suggest = {}
  //   var promises = []
  //   var mergedSuggest = []
  //   patientData.forEach((user, index) => {
  //     if (user.suggestic && user.suggestic.user_id) {
  //       // to fetch the suggestic data push all the request into promises
  //       promises.push(
  //         axios({
  //           method: 'get',
  //           url: process.env.REACT_APP_BACKEND_URL + 'suggestic/users',
  //           headers: {
  //             sg_user_id: user.suggestic.user_id,
  //             Authorization: 'JWT ' + token,
  //           },
  //         })
  //           .then((res) => {
  //             let sg_user_id = res.config.headers.sg_user_id // sg_user_id not available in the pgm data so fetch it from res headers
  //             suggest[sg_user_id] = {
  //               sg_user_id: sg_user_id,
  //               data: res.data.myProfile.program,
  //             } // indexing to map
  //           })
  //           .catch((err) => console.log('err in suggestic', err)),
  //       )
  //     }
  //   })
  //   Promise.all(promises).then(() => {
  //     // to map the suggestic programs with respc users
  //     patientData.forEach((data, index) => {
  //       mergedSuggest.push({
  //         ...data,
  //         ...(data.suggestic &&
  //           data.suggestic.user_id && {
  //             suggestic_program: suggest[data.suggestic.user_id],
  //           }),
  //       })
  //     })
  //     console.log('suggestic data updated')
  //     // update the merged suggestic program data to the user coach
  //     if (mergedSuggest.length) {
  //       setAllPatientData(mergedSuggest)
  //       setPatientData(mergedSuggest)
  //     }
  //   })
  // }

  // const filterByNameEmail = (query) => {
  //   let filteredData = allPatientData
  //   setLoading(true)

  //   // if (query.length) {
  //   //   filteredData = filteredData.filter((user) => {
  //   //     let patientName = user.firstName + ' ' + user.lastName
  //   //     if (user?.email?.toLowerCase()?.includes(query?.toLowerCase()))
  //   //       return user
  //   //     else if (patientName?.toLowerCase()?.includes(query?.toLowerCase()))
  //   //       return user
  //   //   })
  //   // }
  //   if (patientState?.value) {
  //     filteredData = filteredData.filter((user) => {
  //       let state = null
  //       let { addressComponents, zipCodeData } = user
  //       if (addressComponents && addressComponents.length > 0) {
  //         addressComponents.forEach((addressComponent) => {
  //           let types = addressComponent.types || []
  //           if (types.includes('administrative_area_level_1'))
  //             state = addressComponent.short_name
  //         })
  //       } else {
  //         state = zipCodeData?.state
  //       }
  //       if (
  //         state &&
  //         state?.toLowerCase() === patientState?.abbreviation?.toLowerCase()
  //       )
  //         return user
  //     })
  //   }
  //   if (memberType?.value) {
  //     if ('all' !== memberType?.value) {
  //       filteredData = filteredData.filter((user) => {
  //         let type = user?.coaching?.active
  //           ? user?.coaching?.plan || 'membership'
  //           : 'basic'
  //         if (type === memberType?.value) return user
  //       })
  //     }
  //   }
  //   if (patientType?.value) {
  //     if (patientType?.value === 'new') {
  //       filteredData = filteredData.filter((user) => {
  //         if (
  //           user.appointments.length === 0 ||
  //           !user.appointments.filter(
  //             (appt) =>
  //               allProviderData.find(
  //                 (item) => item.firebase_id === appt.doctor_id,
  //               ).user_type === 'Physician',
  //           ).length
  //         )
  //           return user
  //       })
  //     } else if (patientType?.value === 'established') {
  //       filteredData = filteredData.filter((user) => {
  //         if (user?.appointments.length) {
  //           let temp = user.appointments.filter(
  //             (appt) =>
  //               allProviderData.find(
  //                 (item) => item.firebase_id === appt.doctor_id,
  //               ).user_type === 'Physician',
  //           )
  //           if (temp.length) return user
  //         }
  //       })
  //     }
  //   }
  //   setPatientData(filteredData)
  // }

  // const clearFilter = () => {
  //   setPatientData(allPatientData)
  //   setMemberType(null)
  //   setPatientType(null)
  //   setPatientState(null)
  // }

  const insuranceEdit = (patient) => {
    // console.log(patient)
    props.fetchPatientCoreDate(patient.uid, props.token)
    props.addPatient(patient)
    setEditInsurance(patient?.insurances)
    dispatch(toggleSidebar(true))
    setInsuranceModalOpen(true)
  }

  const addDoctor = (doctorID, patientID) => {
    console.log('add doc: ', doctorID, patientID)
    let config = {
      method: 'post',
      url: Config.BACKEND_URL + 'providers/patients',
      headers: {
        x_firebase_id: adminId,
        Authorization: 'JWT ' + token,
      },
      data: {
        doctor_id: doctorID,
        patient_id: patientID,
      },
    }
    axios(config)
      .then(() => {
        console.log('coach assigned')
        getUserData()
      })
      .catch((err) => console.log('error while assigning the coach: ', err))
  }

  const removeDoctor = (doctorID, patientID) => {
    console.log('remove doc: ', doctorID, patientID)
    let config = {
      method: 'delete',
      url: Config.BACKEND_URL + 'patients/providers',
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: patientID,
        'Content-Type': 'application/json',
      },
      data: {
        doctor_id: doctorID,
        patient_id: patientID,
      },
    }
    axios(config)
      .then(() => {
        console.log('coach removed')
        getUserData()
      })
      .catch((err) => console.log('error while deleting the coach: ', err))
  }

  const addCommunity = (communityID, patientID) => {
    console.log('add COMMUNITY: ', communityID, patientID)
    let config = {
      method: 'post',
      url: Config.BACKEND_URL + 'communities/user',
      headers: {
        Authorization: 'JWT ' + token,
      },
      data: {
        firebase_id: patientID,
        community_id: communityID,
      },
    }
    axios(config)
      .then(() => {
        console.log('COMMUNITY assigned')
        getCommunities()
        getUserData()
      })
      .catch((err) => console.log('error while assigning the COMMUNITY: ', err))
  }

  const removeCommunity = (communityID, patientID) => {
    console.log('DELETE COMMUNITY: ', communityID, patientID)
    let config = {
      method: 'delete',
      url: Config.BACKEND_URL + 'communities/user',
      headers: {
        Authorization: 'JWT ' + token,
        'Content-Type': 'application/json',
      },
      data: {
        firebase_id: patientID,
        community_id: communityID,
      },
    }
    axios(config)
      .then(() => {
        console.log('COMMUNITY DELETED')
        getCommunities()
        getUserData()
      })
      .catch((err) => console.log('error while DEL the COMMUNITY: ', err))
  }

  const addTags = (tagID, patientID) => {
    console.log('add tag: ', tagID, patientID)
    let config = {
      method: 'post',
      url: Config.BACKEND_URL + 'tags/user',
      headers: {
        Authorization: 'JWT ' + token,
      },
      data: {
        firebase_id: patientID,
        tags_id: tagID,
      },
    }
    axios(config)
      .then(() => {
        console.log('Tag assigned')
        getTags()
        getUserData()
      })
      .catch((err) => console.log('error while assigning the Tag: ', err))
  }

  const removeTags = (tagID, patientID) => {
    console.log('DELETE Tag: ', tagID, patientID)
    let config = {
      method: 'delete',
      url: Config.BACKEND_URL + 'tags/user',
      headers: {
        Authorization: 'JWT ' + token,
        'Content-Type': 'application/json',
      },
      data: {
        firebase_id: patientID,
        tags_id: tagID,
      },
    }
    axios(config)
      .then(() => {
        console.log('Tag DELETED')
        getTags()
        getUserData()
      })
      .catch((err) => console.log('error while DEL the Tag: ', err))
  }

  const navigatetoPatientProfile = (p_id) => {
    console.log('testing on click: ', patientList, p_id)
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (props.patientTimer && props.patientTimer !== 0)
              props.resetTimer(patient.uid, adminId, token)
            else props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            props.fetchPatientProgressData(patient.uid, props.token, timeline)
            props.fetchPatientCoreDate(patient.uid, props.token)
            props.fetchRpmOrders(patient, props.token)
            props.fetchPatientWellnessScreening(patient.uid, props.token)
            props.fetchPatientCalculateProgressData(patient.uid, props.token)
            props.fetchPatientConditions(patient.uid, props.token)
            props.fetchPatientMedications(patient.uid, props.token)
            props.fetchPatientLabs(patient.uid, props.token)
            props.addPatient(patient, colorcode)
            props.updateSideNavBar(!props.updateRender)
          }
        })
      }
    })
  }

  const onPatientNavLinkClicked = (lp) => {
    props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  const renderInsuranceModal = () => {
    let primary_insurance = Array.isArray(editInsurance)
      ? editInsurance?.find((ins) => ins.preference === 1) || null
      : editInsurance || null

    let secondary_insurance = Array.isArray(editInsurance)
      ? editInsurance?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            dispatch(toggleSidebar(false))
            setInsuranceModalOpen(false)
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient Insurance
        </h4>
        <PatientInsurance
          parentCallback={() => {
            dispatch(toggleSidebar(false))
            setInsuranceModalOpen(false)
            getUserData()
          }}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          fromProvider={true}
        />
      </div>
    )
  }

  if (loading) {
    return (
      <div className={GlobalStyles.container} style={{ height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      </div>
    )
  }

  // const TableHeader = () => {
  //   return (
  //     <div className={Styles.contentRow}>
  //       <h6 className={Styles.mtableHeader}>Patient Name</h6>
  //       <h6 className={Styles.smtableHeader}>State</h6>
  //       {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
  //       <h6 className={Styles.mtableHeader}>Phone Number</h6>
  //       <h6 className={Styles.xtableHeader}>Email</h6>
  //       <h6 className={Styles.smtableHeader}>Membership</h6>
  //       <h6 className={Styles.smtableHeader}>Member Since</h6>
  //       <h6 className={Styles.smtableHeader}>ID Added</h6>
  //       <h6 className={Styles.smtableHeader}>Payment Details Added</h6>
  //       <h6 className={Styles.mtableHeader}>Insurance</h6>
  //       <h6 className={Styles.mtableHeader}>Program</h6>
  //       <h6 className={Styles.mtableHeader}>Providers</h6>
  //       <h6 className={Styles.mtableHeader}>Communities</h6>
  //       {/* <h6
  // 				className={Styles.mtableHeader}
  // 			>
  // 				Meal Plan
  // 			</h6> */}
  //       <h6 className={Styles.mtableHeader}>Cohort</h6>
  //       <h6 className={Styles.smtableHeader}>Last appointment date</h6>
  //       <h6 className={Styles.smtableHeader}>Next appointment date</h6>
  //       <h6 className={Styles.smtableHeader}>Days since last engagement</h6>
  //       <h6 className={Styles.smtableHeader}>Engagement Rate</h6>
  //     </div>
  //   )
  // }

  // Don't delete the following code as it can be reused
  // const tableContentRow = () => {
  //   let tableContentElement = []
  //   tableContentElement =
  //     patientData.length !== 0 &&
  //     patientData.map((patient) => {
  //       // find state
  //       let state = '-'
  //       let { addressComponents, zipCodeData } = patient
  //       if (addressComponents && addressComponents.length > 0) {
  //         addressComponents.forEach((addressComponent) => {
  //           let types = addressComponent.types || []
  //           if (types.includes('administrative_area_level_1'))
  //             state = addressComponent.short_name
  //         })
  //       } else {
  //         state = zipCodeData?.state
  //       }
  //       // find insurance
  //       let insuranceProviders = patient?.insurances?.length
  //         ? patient?.insurances
  //             .map((insurance) => insurance.insurance_provider)
  //             .join(',')
  //         : '-'
  //       let copayDeductible = patient?.insurances?.length
  //         ? patient?.insurances
  //             .map((insurance) =>
  //               insurance.preference === 1 && insurance.benefits
  //                 ? 'Copay:$' +
  //                   insurance.benefits?.copay +
  //                   ' ' +
  //                   'Coinsurance:' +
  //                   insurance.benefits?.coinsurance +
  //                   '% '
  //                 : '',
  //             )
  //             .join('')
  //         : // patient?.insurances.find((insurance) => insurance.preference === 1)
  //           //     .benefits?.copay +
  //           //   ' ' +
  //           //   patient?.insurances.find((insurance) => insurance.preference === 1)
  //           //     .benefits?.copay
  //           ''
  //       // find program type
  //       let programType = '-'
  //       if (patient.dac) programType = 'Dac-Patient'
  //       if (patient.rapilot) programType = 'Ra-Patient'
  //       if (patient.ccfm) programType = 'CCFM'

  //       // find last logged in
  //       let latestLogin = patient?.login_timestamps[0]?.timestamps?.length
  //         ? patient?.login_timestamps[0]?.timestamps?.slice(-1)[0]
  //         : null
  //       latestLogin = latestLogin
  //         ? moment().diff(latestLogin, 'days')
  //           ? moment().diff(latestLogin, 'days') + ' days ago'
  //           : 'Today'
  //         : '-'

  //       // find engagement rate
  //       let engRate
  //       let referenceDate = moment().subtract(30, 'days').format('MM-DD-YYYY')
  //       let uniqueDateArray =
  //         patient?.login_timestamps[0]?.timestamps &&
  //         patient.login_timestamps[0]?.timestamps?.map((date) =>
  //           moment(date).format('MM-DD-YYYY'),
  //         )
  //       let monthLogin =
  //         uniqueDateArray &&
  //         uniqueDateArray.filter(
  //           (data, index) =>
  //             moment(data).isSameOrAfter(referenceDate) &&
  //             moment(data).isSameOrAfter(
  //               moment(patient.created_at).format('MM-DD-YYYY'),
  //             ) &&
  //             uniqueDateArray.indexOf(data) === index, // filter date to obtain past 30 days and remove duplicate dates
  //         )
  //       let dateDiff =
  //         moment()
  //           .startOf('day')
  //           .diff(moment(patient.created_at).startOf('day'), 'day') + 1
  //       // if the created_at is less than 30 divide by diff
  //       if (dateDiff < 30)
  //         engRate = monthLogin && (monthLogin.length / dateDiff) * 100
  //       else engRate = monthLogin && (monthLogin.length / 30) * 100

  //       let lastAppointment = patient?.appointments?.length
  //         ? patient.appointments.reduce((acc, app) =>
  //             app.start > acc.start && moment().valueOf() - app.start > 0
  //               ? app
  //               : acc,
  //           )
  //         : null
  //       lastAppointment = lastAppointment?.start
  //         ? moment.unix(lastAppointment?.start / 1000).format('MMM DD YYYY')
  //         : '-'

  //       // next appointment date
  //       let nextAppointment = patient?.appointments?.length
  //         ? patient.appointments.filter((appt) =>
  //             moment(appt.start).isAfter(moment()),
  //           )[0]
  //         : null

  //       nextAppointment = nextAppointment?.start
  //         ? moment.unix(nextAppointment?.start / 1000).format('MMM DD YYYY')
  //         : '-'

  //       let idAdded =
  //         patient?.id_front_image_url || patient?.id_front_image_url
  //           ? 'Yes'
  //           : 'No'

  //       let paymentCardAdded =
  //         patient?.stripe && patient?.stripe?.customer_id ? 'Yes' : 'No'

  //       let assignedProvider = allProviderData
  //         ?.filter((provider) => {
  //           return patient?.doctor_patients?.includes(provider.firebase_id)
  //         })
  //         .map((option) => ({
  //           label: option?.first_name + ' ' + option?.last_name,
  //           value: option?.firebase_id,
  //         }))

  //       let assignedCommunities = communities?.filter((community) => {
  //         return community?.users?.[patient.firebase_id]
  //       })

  //       let patientCohort = [...new Set(patient?.cohorts?.flat())]?.join(', ')

  //       return (
  //         <div key={patient.firebase_id} className={Styles.contentRow}>
  //           <h7 className={Styles.mtableData}>
  //             <Link
  //               onClick={() => {
  //                 navigatetoPatientProfile(patient.firebase_id)
  //                 onPatientNavLinkClicked('profile')
  //               }}
  //               to={{
  //                 pathname: '/patient/profile',
  //                 state: 'info',
  //                 key: 2,
  //               }}
  //               className={Styles.link}
  //             >
  //               {patient?.firstName || ''} {patient?.lastName || ''}
  //             </Link>
  //           </h7>
  //           <h7 className={Styles.smtableData}>{state || '-'}</h7>
  //           <h7 className={Styles.mtableData}>{patient.phoneNumber || '-'}</h7>
  //           <h7 className={Styles.xtableData}>{patient.email || '-'}</h7>
  //           <h7 className={Styles.smtableData}>
  //             {patient?.coaching?.active
  //               ? patient?.coaching?.plan || 'membership'
  //               : 'basic'}
  //           </h7>
  //           <h7 className={Styles.smtableData}>
  //             {/* show date of coaching start if active else account creating date */}
  //             {patient?.coaching?.active
  //               ? moment(patient?.coaching?.start).format('ll')
  //               : moment(patient.created_at).format('ll')}
  //           </h7>
  //           <h7 className={Styles.smtableData}>{idAdded}</h7>
  //           <h7 className={Styles.smtableData}>{paymentCardAdded}</h7>
  //           <h7 className={Styles.mtableData}>
  //             {insuranceProviders}
  //             <br />
  //             {copayDeductible}
  //             <p
  //               onClick={() => {
  //                 insuranceEdit(patient)
  //               }}
  //               style={{
  //                 color: Constants.primaryTheme,
  //                 textDecoration: 'underline',
  //               }}
  //             >
  //               Edit
  //             </p>
  //           </h7>
  //           <h7 className={Styles.mtableData}>{programType}</h7>
  //           <h7 className={Styles.mtableData}>
  //             <Select
  //               options={allProviderList}
  //               // className={Styles.addSelectInput}
  //               autoBlur={true}
  //               isMulti
  //               onChange={(val) => {
  //                 let difference = []
  //                 if (val.length > assignedProvider.length) {
  //                   difference = val.filter(
  //                     (x) => !assignedProvider.includes(x),
  //                   ) // calculates diff
  //                   addDoctor(difference[0].value, patient.firebase_id)
  //                 } else {
  //                   difference = assignedProvider.filter(
  //                     (x) => !val.includes(x),
  //                   ) // calculates diff
  //                   removeDoctor(difference[0].value, patient.firebase_id)
  //                 }
  //               }}
  //               value={assignedProvider}
  //               theme={(theme) => ({
  //                 ...theme,
  //                 colors: {
  //                   ...theme.colors,
  //                   primary25: '#20A89233',
  //                   primary: '#20A892',
  //                 },
  //               })}
  //             />
  //           </h7>
  //           <h7 className={Styles.mtableData}>
  //             <Select
  //               options={communities}
  //               // className={Styles.addSelectInput}
  //               autoBlur={true}
  //               isMulti
  //               onChange={(val) => {
  //                 let difference = []
  //                 if (val.length > assignedCommunities.length) {
  //                   difference = val.filter(
  //                     (x) => !assignedCommunities.includes(x),
  //                   ) // calculates diff
  //                   addCommunity(difference[0]._id, patient.firebase_id)
  //                 } else {
  //                   difference = assignedCommunities.filter(
  //                     (x) => !val.includes(x),
  //                   ) // calculates diff
  //                   removeCommunity(difference[0]._id, patient.firebase_id)
  //                 }
  //               }}
  //               getOptionValue={(option) => option._id}
  //               getOptionLabel={(option) => option.title}
  //               value={assignedCommunities}
  //               theme={(theme) => ({
  //                 ...theme,
  //                 colors: {
  //                   ...theme.colors,
  //                   primary25: '#20A89233',
  //                   primary: '#20A892',
  //                 },
  //               })}
  //             />
  //           </h7>
  //           {/* <h7 className={Styles.mtableData}>
  // 					{ patient?.suggestic_program?.data?.name || '-' }
  // 				</h7> */}
  //           <h7 className={Styles.mtableData}>{patientCohort || '-'}</h7>
  //           <h7 className={Styles.smtableData}>{lastAppointment}</h7>
  //           <h7 className={Styles.smtableData}>{nextAppointment}</h7>
  //           <h7 className={Styles.smtableData}>{latestLogin}</h7>
  //           <h7 className={Styles.smtableData}>
  //             {engRate ? `${engRate.toFixed(0)}%` : '-'}
  //           </h7>
  //         </div>
  //       )
  //     })
  //   return tableContentElement
  // }

  // const SearchComponent = () => {
  //   const [searchQueryString, setSearchQueryString] = useState('')

  //   return (
  //     <div className={Styles.row}>
  //       {/* <p className={Styles.rowLabel}>Search</p>
  //       <div style={{ width: '30%' }}>
  //         <input
  //           placeholder="Search name or email"
  //           className={Styles.textInput}
  //           style={{ width: '100%' }}
  //           type="text"
  //           value={searchQueryString}
  //           onChange={(e) => {
  //             setSearchQueryString(e.target.value)
  //           }}
  //         />
  //       </div> */}
  //       <Button
  //         onClick={() => filterByNameEmail(searchQueryString)}
  //         className={GlobalStyles.button}
  //         style={{
  //           width: 100,
  //           marginLeft: 20,
  //         }}
  //         variant="primary"
  //       >
  //         Go
  //       </Button>
  //       <Button
  //         onClick={() => clearFilter()}
  //         className={GlobalStyles.button}
  //         style={{
  //           width: 100,
  //           marginLeft: 20,
  //         }}
  //         variant="primary"
  //       >
  //         Clear
  //       </Button>
  //     </div>
  //   )
  // }

  // const FilterComponent = () => {
  //   return (
  //     <div className={Styles.row}>
  //       <p className={Styles.rowLabel}>Filter</p>
  //       <div style={{ width: '20%', paddingRight: '20px' }}>
  //         <Select
  //           inputId="membertype"
  //           placeholder="Type"
  //           options={[
  //             { label: 'All access', value: 'premium' },
  //             { label: 'Membership', value: 'membership' },
  //             { label: 'Basic', value: 'basic' },
  //             { label: 'All', value: 'all' },
  //           ]}
  //           value={memberType}
  //           isSearchable
  //           autoBlur
  //           onChange={(val) => {
  //             setMemberType(val)
  //             // filterByPatientType(val)
  //           }}
  //           theme={(theme) => ({
  //             ...theme,
  //             colors: {
  //               ...theme.colors,
  //               primary25: '#20A89233',
  //               primary: '#20A892',
  //             },
  //           })}
  //         />
  //       </div>
  //       <div style={{ width: '20%', paddingRight: '20px' }}>
  //         <Select
  //           inputId="patienttype"
  //           placeholder="Patient Type"
  //           options={[
  //             { label: 'New Patient', value: 'new' },
  //             { label: 'Established', value: 'established' },
  //             { label: 'Not Scheduled', value: 'not_scheduled' },
  //             { label: 'Others', value: 'others' },
  //           ]}
  //           // value={patientType}
  //           isSearchable
  //           autoBlur
  //           onChange={(val) => {
  //             setPatientType(val)
  //             // filterByPatientType(val)
  //           }}
  //           theme={(theme) => ({
  //             ...theme,
  //             colors: {
  //               ...theme.colors,
  //               primary25: '#20A89233',
  //               primary: '#20A892',
  //             },
  //           })}
  //         />
  //       </div>
  //       <div style={{ width: '20%' }}>
  //         <Select
  //           inputId="state"
  //           placeholder="State"
  //           options={stateResidence}
  //           value={patientState}
  //           isSearchable
  //           autoBlur
  //           onChange={(val) => {
  //             setPatientState(val)
  //             // filterByPatientState(val)
  //           }}
  //           theme={(theme) => ({
  //             ...theme,
  //             colors: {
  //               ...theme.colors,
  //               primary25: '#20A89233',
  //               primary: '#20A892',
  //             },
  //           })}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  // const tableInstance = useTable({
  //   columns: columns,
  //   data: patientData,
  // })

  // function Table({ columns, data }) {
  //   const {
  //     getTableProps,
  //     getTableBodyProps,
  //     headerGroups,
  //     // rows,
  //     page, // Instead of using 'rows', we'll use page,
  //     // which has only the rows for the active page

  //     // The rest of these things are super handy, too ;)
  //     canPreviousPage,
  //     canNextPage,
  //     pageOptions,
  //     pageCount,
  //     gotoPage,
  //     nextPage,
  //     previousPage,
  //     setPageSize,

  //     state: { pageIndex, pageSize, globalFilter, filters },
  //     setAllFilters,
  //     setFilter,
  //     setGlobalFilter,
  //     prepareRow,
  //   } = useTable(
  //     {
  //       columns,
  //       data,
  //       // initialState: { pageIndex: 0 },
  //       initialState: { hiddenColumns: ['Patient Type'] },
  //     },
  //     useFilters,
  //     useGlobalFilter,
  //     useSortBy,
  //     usePagination,
  //   )

  //   // We don't want to render all 2000 rows for this example, so cap
  //   // it at 20 for this use case
  //   // const firstPageRows = rows.slice(0, 20)

  //   return (
  //     <>
  //       {/* <pre>
  //         <code>
  //           {JSON.stringify(
  //             {
  //               pageIndex,
  //               pageSize,
  //               pageCount,
  //               canNextPage,
  //               canPreviousPage,
  //             },
  //             null,
  //             2,
  //           )}
  //         </code>
  //       </pre> */}

  //       <div className={Styles.row}>
  //         <p className={Styles.rowLabel} style={{ width: 'auto' }}>
  //           Global Search
  //         </p>
  //         <div style={{ width: '30%' }}>
  //           <input
  //             placeholder="Search globally"
  //             className={Styles.textInput}
  //             style={{ width: '100%' }}
  //             type="text"
  //             value={globalFilter}
  //             onChange={(e) => {
  //               setGlobalFilter(e.target.value)
  //             }}
  //           />
  //         </div>

  //         <Button
  //           onClick={() => {
  //             setPatientType(null)
  //             setAllFilters([])
  //             selectInputRef.current.select.clearValue()
  //           }}
  //           className={GlobalStyles.button}
  //           style={{
  //             // width: 100,
  //             marginLeft: 'auto',
  //           }}
  //           variant="primary"
  //         >
  //           Clear Filters
  //         </Button>
  //       </div>
  //       <span>
  //         <div className={Styles.row}>
  //           <p className={Styles.rowLabel} style={{ width: 'auto' }}>
  //             Patient Type
  //           </p>
  //           <div style={{ width: '30%' }}>
  //             <Select
  //               ref={selectInputRef}
  //               inputId="patient-type"
  //               placeholder="Select"
  //               options={[
  //                 { label: 'New Patient', value: 'New Patient' },
  //                 { label: 'Established', value: 'Established' },
  //                 // { label: 'Not Scheduled', value: 'not_scheduled' },
  //                 { label: 'Others (Medicare/Medicaid)', value: 'Others (Medicare/Medicaid)' },
  //               ]}
  //               // value={}
  //               isSearchable
  //               autoBlur
  //               onChange={(val) => {
  //                 val && setFilter('Patient Type', val?.label)
  //                 // setPatientType(val)
  //                 // console.log(filters)
  //               }}
  //               theme={(theme) => ({
  //                 ...theme,
  //                 colors: {
  //                   ...theme.colors,
  //                   primary25: '#20A89233',
  //                   primary: '#20A892',
  //                 },
  //               })}
  //             />
  //           </div>
  //         </div>
  //       </span>

  //       <div style={{ overflow: 'scroll', width: '73vw', margin: 'auto' }}>
  //         <table {...getTableProps()}>
  //           <thead>
  //             {headerGroups.map((headerGroup) => (
  //               <tr {...headerGroup.getHeaderGroupProps()}>
  //                 {headerGroup.headers.map((column) => (
  //                   // Add the sorting props to control sorting. For this example
  //                   // we can add them into the header props
  //                   <th
  //                     {...column.getHeaderProps(
  //                       column.getSortByToggleProps({
  //                         style: {
  //                           width: column.width,
  //                           minWidth: column.width,
  //                           // display: column.id === 'Patient Type' ? 'none' : '',
  //                         },
  //                       }),
  //                     )}
  //                   >
  //                     {column.render('Header')}
  //                     <span>
  //                       {' '}
  //                       {column.canFilter && column.Filter
  //                         ? column.render('Filter')
  //                         : null}
  //                     </span>
  //                     {/* Add a sort direction indicator */}
  //                     <span>
  //                       {column.isSorted
  //                         ? column.isSortedDesc
  //                           ? ' 🔽'
  //                           : ' 🔼'
  //                         : ''}
  //                     </span>
  //                   </th>
  //                 ))}
  //               </tr>
  //             ))}
  //           </thead>
  //           <tbody {...getTableBodyProps()}>
  //             {page.map((row, i) => {
  //               prepareRow(row)
  //               return (
  //                 <tr {...row.getRowProps()}>
  //                   {row.cells.map((cell) => {
  //                     return (
  //                       <td
  //                         {...cell.getCellProps({
  //                           style: {
  //                             width: cell.column.width,
  //                             minWidth: cell.column.width,
  //                             // display:
  //                             //   cell.column.id === 'Patient Type' ? 'none' : '',
  //                           },
  //                         })}
  //                       >
  //                         {cell.render('Cell')}
  //                       </td>
  //                     )
  //                   })}
  //                 </tr>
  //               )
  //             })}
  //           </tbody>
  //         </table>
  //       </div>
  //       <br />
  //       {/* <div>Showing the first 20 results of {rows.length} rows</div> */}
  //       {/*
  //       Pagination can be built however you'd like.
  //       This is just a very basic UI implementation:
  //     */}
  //       <div className="pagination" style={{ flexDirection: 'column' }}>
  //         <div className="pagination" style={{ flexDirection: 'row' }}>
  //           <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
  //             {'<<'}
  //           </button>{' '}
  //           <button onClick={() => previousPage()} disabled={!canPreviousPage}>
  //             {'<'}
  //           </button>{' '}
  //           <button onClick={() => nextPage()} disabled={!canNextPage}>
  //             {'>'}
  //           </button>{' '}
  //           <button
  //             onClick={() => gotoPage(pageCount - 1)}
  //             disabled={!canNextPage}
  //           >
  //             {'>>'}
  //           </button>{' '}
  //         </div>

  //         <br />
  //         <span>
  //           Page{' '}
  //           <strong>
  //             {pageIndex + 1} of {pageOptions.length}
  //           </strong>{' '}
  //         </span>
  //         {/* <span>
  //           | Go to page:{' '}
  //           <input
  //             type="number"
  //             defaultValue={pageIndex + 1}
  //             onChange={(e) => {
  //               const page = e.target.value ? Number(e.target.value) - 1 : 0
  //               gotoPage(page)
  //             }}
  //             style={{ width: '100px' }}
  //           />
  //         </span>{' '} */}
  //         {/* <select
  //           value={pageSize}
  //           onChange={(e) => {
  //             setPageSize(Number(e.target.value))
  //           }}
  //         >
  //           {[10, 20, 30, 40, 50].map((pageSize) => (
  //             <option key={pageSize} value={pageSize}>
  //               Show {pageSize}
  //             </option>
  //           ))}
  //         </select> */}
  //         <div style={{ width: '20%' }}>
  //           <Select
  //             inputId="pageSize"
  //             placeholder="Page Size"
  //             options={[
  //               { label: 10, value: 10 },
  //               { label: 20, value: 20 },
  //               { label: 30, value: 30 },
  //               { label: 40, value: 40 },
  //               { label: 50, value: 50 },
  //             ]}
  //             value={pageSize && { label: pageSize, value: pageSize }}
  //             // isSearchable
  //             autoBlur
  //             onChange={(val) => {
  //               setPageSize(Number(val.value))
  //               // setMemberType(val)
  //               // filterByPatientType(val)
  //             }}
  //             theme={(theme) => ({
  //               ...theme,
  //               colors: {
  //                 ...theme.colors,
  //                 primary25: '#20A89233',
  //                 primary: '#20A892',
  //               },
  //             })}
  //           />
  //         </div>
  //       </div>
  //     </>
  //   )
  // }

  return (
    // <div className={GlobalStyles.container}>
    // {/* <Header header="Patient Roster" /> */}
    <div className={Styles.divider}>
      <h4 style={{ color: Constants.primaryTheme }}>Patient Roster</h4>
      <div className={GlobalStyles.contentWrapper}>
        <Table
          columns={rosterColumns(
            allProviderData,
            allProviderList,
            communities,
            tags,
            addDoctor,
            removeDoctor,
            addCommunity,
            removeCommunity,
            navigatetoPatientProfile,
            onPatientNavLinkClicked,
            insuranceEdit,
            addTags,
            removeTags,
          )}
          data={patientData}
        />
        {/* INSURANCE MODAL */}
        <Modal
          ariaHideApp={false}
          isOpen={insuranceModalOpen}
          onRequestClose={() => {
            dispatch(toggleSidebar(false))
            setInsuranceModalOpen(false)
            getUserData()
          }}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {renderInsuranceModal()}
        </Modal>
      </div>
    </div>
  )
}

const mapStateToProps = (state /* , ownProps */) => ({
  patient: state.patientReducer.patient,
  patientLP: state.patientReducer.lp,
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  e3: state.authReducer.e3,
  patientTimer: state.patientReducer.timer,
  rpmPatientId: state.patientReducer.rpmPatientId,
  adminId: state.userReducer.adminId,
  userType: state.userReducer.userType,
  updateRender: state.userReducer.render,
  firebaseUser: state.userReducer.firebaseUser,
})

const mapDispatchToProps = {
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  addPatientLP,
  fetchRpmOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterTable)
