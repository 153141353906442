import React, { Component } from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css' // Import Tippy styles
import LastChargeTooltip from './lastChargeTooltip'

class LastChargeHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadDetails: false,
    }
  }

  render() {
    return (
      <Tippy
        content={
          this.state.loadDetails ? (
            <LastChargeTooltip
              status={this.props.status}
            />
          ) : null
        }
        placement="right"
        delay={[200, 200]}
        onShow={(instance) => {
          console.log('is->', instance)
          this.setState({ loadDetails: true })
        }}
        onHide={(instance) => {
          console.log('ih->', instance)
          this.setState({ loadDetails: false })
        }}
      >
        <p>{this.props.status?.status || '-'}</p>
      </Tippy>
    )
  }
}

export default LastChargeHeader