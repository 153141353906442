import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import SearchPicker from 'react-date-picker'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import Styles from './styles/Appointments.module.scss'

import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import {
  faTrash,
  faSpinner,
  faMinusCircle,
  faVideo,
  faBinoculars,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  addPatientLP,
  addRpmPatient,
  stopTimer,
  updateSideNavBar,
  addAppointmentData,
  setPatientCoreData,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleSidebar,
} from '../../redux/actions'
import { Link } from 'react-router-dom'
import Header from '../../components/Header.js'
import GlobalStyles from '../styles/global.module.scss'
import Constants from '../../values.js'
import Config from '../../config.js'
import GoogleLogo from '../../assets/images/google.png'
import metadata from '../../metadata'
import MyCalendar from '../../components/appointment/MyCalendar'
import InfusionDemo from './InfusionDemo'
import Fuse from 'fuse.js'
const localizer = momentLocalizer(moment)
const axios = require('axios')
const windowHeight = window.innerHeight

const APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 440,
    height: 450,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const SCHEDULE_APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const SLOT_AVAILABLE_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '80%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const RESCHEDULE_APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const TIME_SLOTS = {
  Mon: [],
  Tue: [],
  Wed: [],
  Thu: [],
  Fri: [],
  Sat: [],
  Sun: [],
}

class Appointments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      modalIsOpen: false,
      events: null,
      cancelledEvents: null,
      modalEvent: null,
      eventsFromNylas: null,
      eventsFromBackend: {},
      eventIdsFromBackend: [],
      ailaEventsSelected: true,
      // For infusion
      infusionSelected: false,
      infusionScheduleModalSelected: false,
      showScheduleModal: false,
      showSlotAvailableModal: false,
      patientList: [],
      patientMap: {},
      providerMap: {},
      selectedSchedulePatient: null,
      selectedScheduleDoctor: null,
      scheduleModalLoading: false,
      patientCareTeam: [],
      careTeamSpeciality: [],
      searchSpeciality: null,
      newPatients: [],
      establishedPatients: [],
      coachingOnlyPatients: [],
      patientType: null,
      reload: false,
      slot_available: {},
      slot_override: {},
      selectedOverRideDate: null,
      axiosConfig: {},
      superUser: false,
      searchDate: new Date(),
      tabSelected: 'aila_events',
      reasonForVisit: '',
      rescheduleModalIsOpen: false,
    }

    this.fuse = null
    this.allCalendars = []
    this.preparePatientList = this.preparePatientList.bind(this)
    this.saveSlotsToBackend = this.saveSlotsToBackend.bind(this)
    this.getEventsFromBackendOnly = this.getEventsFromBackendOnly.bind(this)
    this.onModalDeleteEventPressed = this.onModalDeleteEventPressed.bind(this)
    this.onVideoCallPressed = this.onVideoCallPressed.bind(this)
    this.getPatientCareTeam = this.getPatientCareTeam.bind(this)
    this.getAllProviders = this.getAllProviders.bind(this)
    this.handleSlotAvailability = this.handleSlotAvailability.bind(this)
    this.handleSlotDeletion = this.handleSlotDeletion.bind(this)
    this.renderTimeSlots = this.renderTimeSlots.bind(this)
    this.renderSlots = this.renderSlots.bind(this)
    this.handleDateOverRideChange = this.handleDateOverRideChange.bind(this)
    this.handleOverrideSlotAvailability =
      this.handleOverrideSlotAvailability.bind(this)
    this.handleOverrideTimeSlotDeletion =
      this.handleOverrideTimeSlotDeletion.bind(this)
    this.handleOverrideSlotDeletion = this.handleOverrideSlotDeletion.bind(this)
    this.saveOverRideSlotsToBackend = this.saveOverRideSlotsToBackend.bind(this)
    this.getAppointmentUrl = this.getAppointmentUrl.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.eventsFromBackend &&
      this.state.eventsFromNylas &&
      (!this.state.events || this.state.reload)
    ) {
      const { eventsFromNylas, eventsFromBackend } = this.state
      const ailaEvents = []

      eventsFromNylas.forEach((eventFromNylas) => {
        if (eventsFromBackend[eventFromNylas.id]) {
          ailaEvents.push(eventFromNylas)
          eventsFromBackend[eventFromNylas.id]['added'] = true
        }
      })

      Object.values(eventsFromBackend).forEach((each) => {
        if (each.through_api)
          ailaEvents.push({
            start: new Date(each.start), // because the time stamp is in seconds and we need milliseconds
            end: new Date(each.end),
            title: 'Intro - Aila Appointment',
            id: each.event_id,
            ailaEvent: true,
          })
      })

      console.log('updated', ailaEvents)
      this.setState({
        events: ailaEvents,
        loading: false,
        reload: false,
        eventsFromBackend,
      })
    }
  }

  componentDidMount() {
    const { mongoUser } = this.props

    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getAllProviders()

    if (mongoUser && mongoUser.availability) {
      this.setState({
        slot_available: mongoUser.availability,
        slot_override: mongoUser?.availability_override || {},
      })
    } else {
      this.setState({ slot_available: TIME_SLOTS })
    }
    // this.getEventsFromBackendOnly()
  }

  async getAllProviders() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`
    axios({
      method: 'get',

      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        let docObj = {}
        console.log('getAllProviders')
        if (data) {
          data.forEach((x) => {
            docObj[x.firebase_id] = x
          })
          this.setState(
            {
              providerMap: docObj,
            },
            () => this.preparePatientList(),
          )
        }
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const patientMap = {}
    const list = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        patientMap[patient.uid] = patient
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientMap, patientList: list }, () => {
      console.log('preparePatientList')
      this.getEventsFromBackendOnly()
    })
  }

  async getEventsFromBackendOnly() {
    const { token, mongoUser, firebaseUser } = this.props
    const { patientMap, providerMap, superUser, establishedPatients } =
      this.state
    const url =
      `${Config.BACKEND_URL}providers/appointments` +
      (superUser || mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '?access=admin'
        : '')
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_doctor_id: mongoUser.firebase_id,
        },
      })

      const events = response.data
      const ailaEvents = []
      const eventsMap = {}
      const eventIds = []
      let tempEstablishedPatients = []

      console.log('Appointments: ', events)

      events.forEach((event) => {
        console.log('EVENT:', event)
        if (
          event.delete ||
          event.patient_id === '3qJxvy3hKcQYtF1Lhi0HQiYRQ8j2'
        ) {
          console.log('DELETED:', event)
        }
        eventsMap[event.event_id] = event
        eventIds.push(event.event_id)
        const patientData = patientMap?.[event?.patient_id] || {}
        const providerData = providerMap?.[event?.doctor_id] || {}

        if (patientData && providerData?.user_type === 'Physician')
          tempEstablishedPatients.push({
            label: `${patientData.firstName} ${patientData.lastName}`,
            value: patientData?.uid,
          })
        // if (event.through_api) {

        //// if the appointment_payment_paid does not exist(for old appointments) and appointment_payment_paid === true, for new appointmets

        // if (
        //   event.appointment_payment_paid === true ||
        //   (!event.hasOwnProperty('appointment_payment_paid'))
        // ) {
        ailaEvents.push({
          start: new Date(event.start), // because the time stamp is in seconds and we need milliseconds
          end: new Date(event.end),
          title:
            'Patient Appointment' +
            ': ' +
            `${patientData?.firstName || ''}` +
            (firebaseUser?.superUser ||
            mongoUser?.user_type?.toLowerCase() === 'administrator'
              ? `-${providerData?.first_name || ''}`
              : ''),
          id: event.event_id,
          ailaEvent: true,
          appointmentData: event,
        })
        // }
        // }
      })

      this.setState(
        {
          events: ailaEvents,
          // events: events,
          loading: false,
          reload: false,
          eventsFromBackend: eventsMap,
          eventIdsFromBackend: eventIds,
          establishedPatients: tempEstablishedPatients,
        },
        async () => {
          const url =
            `${Config.BACKEND_URL}providers/cancelled-appointments` +
            (superUser ||
            mongoUser?.user_type?.toLowerCase() === 'administrator'
              ? '?access=admin'
              : '')
          try {
            const response = await axios({
              method: 'get',
              url,
              headers: {
                Authorization: `JWT ${token}`,
                x_doctor_id: mongoUser.firebase_id,
              },
            })

            const events = response.data

            events.forEach((event) => {
              eventsMap[event.event_id] = event
              eventIds.push(event.event_id)
              const patientData = patientMap?.[event?.patient_id] || {}
              const providerData = providerMap?.[event?.doctor_id] || {}

              if (patientData && providerData?.user_type === 'Physician')
                tempEstablishedPatients.push({
                  label: `${patientData.firstName} ${patientData.lastName}`,
                  value: patientData?.uid,
                })
              // if (event.through_api) {

              //// if the appointment_payment_paid does not exist(for old appointments) and appointment_payment_paid === true, for new appointmets

              // if (
              //   event.appointment_payment_paid === true ||
              //   (!event.hasOwnProperty('appointment_payment_paid'))
              // ) {

              // COMMENTING THIS AS RORY DOSENT WANT TO SEE DELETED EVENTS
              // ailaEvents.push({
              //   start: new Date(event.start), // because the time stamp is in seconds and we need milliseconds
              //   end: new Date(event.end),
              //   title:
              //     'Patient Appointment1' +
              //     ': ' +
              //     `${patientData?.firstName || ''}` +
              //     (firebaseUser?.superUser ||
              //     mongoUser?.user_type?.toLowerCase() === 'administrator'
              //       ? `-${providerData?.first_name || ''}`
              //       : ''),
              //   id: event.event_id,
              //   ailaEvent: true,
              //   appointmentData: event,
              // })

              // }
              // }
            })

            this.setState({
              // events: ailaEvents,
              loading: false,
              reload: false,
            })
          } catch (err) {
            console.log('error when getting calendar events from backend1', err)
          }
        },
      )
    } catch (err) {
      console.log('error when getting calendar events from backend', err)
    }
  }

  refreshPage() {
    const { mongoUser } = this.props
    console.log('Refresh')
    this.getEventsFromBackendOnly()
    if (mongoUser && mongoUser.availability) {
      this.setState({ slot_available: mongoUser.availability })
    } else {
      this.setState({ slot_available: TIME_SLOTS })
    }
  }

  handleCalendarCallback = (childData) => {
    if (childData.success) {
      // console.log(childData)
      //in case the provider booked a appointment, we want to get them all again
      this.setState({ loading: true, reload: true, eventsFromNylas: null })
      console.log('Handle calendar callback')
      this.getEventsFromBackendOnly()

      this.setState({
        modalIsOpen: false,
        showScheduleModal: false,
        showSlotAvailableModal: false,
        selectedSchedulePatient: null,
        selectedScheduleDoctor: null,
        patientType: null,
      })
      this.props.toggleSidebar(false)
      toast.success('Appointment scheduled successfully')
    } else {
      toast.error('Appointment was not scheduled successfully')
      // console.log(childData)
    }
  }

  // HELP: WHY IS THE AXIOS CONFIG BEING PASSED TO THE CHILD COMPONENT?
  // Dosent matter if the child component is being used in multiple
  // places, the config should never be sent
  // Such a horrible implementation - TODO: update this later
  getAppointmentUrl = (doctor_id, patient_id) => {
    const { mongoUser } = this.props

    let data = {
      doctor_id: doctor_id,
      patient_id: patient_id,
      symptoms: [],
      through_api: true,
      appointment_payment_paid: true,
      through_provider: true,
      booked_by: mongoUser?.firebase_id,
    }
    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}appointments`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    this.setState({ axiosConfig: config })

    this.setState({ scheduleModalLoading: false })
  }

  saveSlotsToBackend(slotList) {
    const self = this
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}providers`

    let availList = {}

    Object.keys(slotList).forEach((avail) => {
      // avail = Mon, Tue, Wed ...
      availList[avail] = []
      if (slotList?.[avail]?.length) {
        // In day object get the time from the array
        slotList[avail] &&
          slotList[avail].forEach((time) => {
            availList[avail].push({
              start: moment(time?.start)
                .year(moment().year())
                .month(moment().month())
                .date(moment().date())
                .valueOf(),
              end: moment(time?.end)
                .year(moment().year())
                .month(moment().month())
                .date(moment().date())
                .valueOf(),
            })
          })
      }
    })

    mongoUser.availability = availList

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: mongoUser,
    })
      .then(({ data }) => {
        this.props.addMongoUser(mongoUser)
      })
      .catch((err) => {
        console.log('error when adding slots data to backend', err)
      })
  }

  saveOverRideSlotsToBackend(overrideList) {
    const self = this
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}providers`

    mongoUser.availability_override = overrideList

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: mongoUser,
    })
      .then(({ data }) => {
        this.props.addMongoUser(mongoUser)
      })
      .catch((err) => {
        console.log('error when adding override slots data to backend', err)
      })
  }

  getPatientList() {
    this.setState({ loading: true })
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ||
      mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '?access=admin'
        : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })
          self.setState({
            patientListData: data || {},
            patientList: data ? total : [], // default
            completePatientList: total,
          })
          self.props.addPatientList(data)
          self.preparePatientList(data)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
        self.setState({ loading: false })
      })
  }

  // BUG/ ERROR: I think I might have mistakenly edited this, will need to revert this
  // getPatientList() {
  //   this.setState({ loading: true })
  //   const { token, adminId, firebaseUser, mongoUser } = this.props
  //   const self = this
  //   const url = `${Config.BACKEND_URL}infusion/patients?type=true&access=admin`
  //   axios({
  //     method: 'get',
  //     url,
  //     headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
  //   })
  //     .then(function (response) {
  //       // handle success
  //       if (response.status === 200) {
  //         const { data } = response
  //         let total = []
  //         data &&
  //           Object.keys(data).forEach((color) => {
  //             total = total.concat(data[color])
  //           })
  //         self.setState({
  //           patientListData: data || {},
  //           patientList: data ? total : [], // default
  //           completePatientList: total,
  //         })
  //         self.props.addPatientList(data)
  //         console.log('getPatientList')
  //         self.preparePatientList(data)
  //       }
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log('error when getting patient list', error, token)
  //       self.setState({ loading: false })
  //     })
  // }

  async getPatientCareTeam(uid) {
    const { token } = this.props

    // get the care team members
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    const doctorList = []

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: uid,
      },
      url,
    })
      .then(async ({ data }) => {
        if (data) {
          let specialityArray = data
            .filter((item) => item.speciality !== undefined)
            .map((item) => ({
              label: `${item.speciality}`,
              value: `${item.speciality}`,
            }))

          //GET UNIQUE EMAIL IDS - NO MULTIPLE COPIES
          function getUniqueListBy(arr, key) {
            return [...new Map(arr.map((item) => [item[key], item])).values()]
          }

          const uniqueSpecialities = getUniqueListBy(specialityArray, 'label')
          this.setState({ careTeamSpeciality: uniqueSpecialities })

          data.forEach((x) => {
            doctorList.push({
              label: `${x.first_name} ${x.last_name} ${
                x.user_type ? ` (${x.user_type})` : ''
              }`,
              value: x.doctor_id,
              doctor: x,
              ...x,
            })
          })
          this.setState({
            patientCareTeam: doctorList,
            scheduleModalLoading: false,
          })
        }
      })
      .catch((err) => {
        console.log('error when getting the care team for patient', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  onScheduleClicked() {
    this.setState({
      showScheduleModal: true,
    })
    this.props.toggleSidebar(true)
  }

  onInfusionScheduleClicked() {
    console.log('Setting infusion modal to true')
    this.setState({
      infusionScheduleModalSelected: true,
    })
    // this.props.toggleSidebar(true)
  }

  onSlotClicked() {
    this.setState({
      showSlotAvailableModal: true,
    })
    this.props.toggleSidebar(true)
  }

  handleDateOverRideChange(date) {
    // Here date is the date chosen at 00 am local time.
    console.log('handleDateOverRideChange', date, moment(date).valueOf())
    let momentDate = moment(date)
    let newSlot = {
      ...this.state.slot_override,
      // Addition of 9 and 4 hours represents the default time slot used when i.e starts at 9am local to 4pm local
      // This can be edited by the provider
      [momentDate.valueOf()]: [
        {
          start: momentDate.add(9, 'hours').valueOf(),
          end: momentDate.add(4, 'hours').valueOf(),
        },
      ],
    }

    this.setState({
      selectedOverRideDate: date,
      slot_override: newSlot,
    })
    this.saveOverRideSlotsToBackend(newSlot)
  }

  //DELETE EVENT IN GOOGLE CALENDAR AFTER APPOINTMENT SCHEDULED
  deleteEvent(event_id, access_token, event) {
    const { providerMap } = this.state
    var gapi = window.gapi
    let doctor = providerMap[event.doctor_id] || {}
    const CALENDAR_ID = doctor?.google_calendar_id
    gapi.load('client:auth2', () => {
      console.log('loading client')

      gapi.client.load('calendar', 'v3', () => {
        console.log('google calendar signed in')
        try {
          gapi.auth.setToken({
            access_token: access_token?.access_token,
          })
          var request = gapi.client.calendar.events.delete({
            calendarId: CALENDAR_ID,
            eventId: event_id,
          })
          request.execute((event) => {
            console.log('EVENT DELETED:::', event)
          })
        } catch (error) {
          console.log('Event DELETION failed', error)
        }
      })
    })
  }
  async onModalDeleteEventPressed() {
    const { modalEvent, eventsFromBackend } = this.state
    let data = {
      event_id: modalEvent.id,
      deleted_by: {
        firebase_id: this.props.adminId,
        type: 'provider',
        deleted_at: new Date(),
      },
    }
    if (!modalEvent || !modalEvent.id) return
    this.setState({ loading: true, modalIsOpen: false })
    const url = `${Config.BACKEND_URL}appointments`
    try {
      const response = await axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `JWT ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
      const eventToDelete = eventsFromBackend[modalEvent.id] || {}
      console.log('delete event', response.data, eventToDelete)
      if (response?.data?.access_token && eventToDelete?.google_event_id) {
        this.deleteEvent(
          eventToDelete?.google_event_id,
          response?.data?.access_token,
          eventToDelete,
        )
      }
      toast.success('A notification has been sent to the patient')
      console.log('onModalDeleteEventPressed')
      this.getEventsFromBackendOnly()
    } catch (err) {
      console.log('error when deleting calendar event', err)
      this.setState({ loading: false })
      toast.error('Error when deleting calendar event')
    }
  }

  onVideoCallPressed(appointmentData, patientData) {
    this.props.addAppointmentData(appointmentData, patientData)
    this.props.history.push('/appointments/video')
  }

  onSelectEvent(event) {
    const { eventsFromBackend } = this.state
    if (eventsFromBackend && eventsFromBackend[event.id]) {
      // this is as aila event with data in backend. Show the modal
      this.setState({ modalIsOpen: true, modalEvent: event })
    } else {
      window.alert(event.title)
    }
  }

  onModalCloseRequested() {
    //in case the provider booked a appointment, we want to get them all again
    const { mongoUser } = this.props
    // if (mongoUser && mongoUser.nylas) {
    //   // console.log('getting new events')
    //   this.setState({ loading: true, reload: true, eventsFromNylas: null })
    //   const nylasToken = mongoUser.nylas.access_token
    //   this.getEventsFromBackend(nylasToken)
    // }

    this.setState({
      modalIsOpen: false,
      showScheduleModal: false,
      showSlotAvailableModal: false,
      selectedSchedulePatient: null,
      selectedScheduleDoctor: null,
      patientType: null,
      infusionScheduleModalSelected: false,
    })
    this.props.toggleSidebar(false)
  }

  onRescheduleModalClose() {
    this.setState({
      rescheduleModalIsOpen: false,
    })
    this.props.toggleSidebar(false)
  }

  handleSlotAvailability(sday, index, stime, endPoint) {
    let temp = this.state.slot_available
    if (endPoint === 'start') temp[sday][index].start = stime
    else if (endPoint === 'end') temp[sday][index].end = stime
    this.setState({ slot_available: temp })
    this.saveSlotsToBackend(temp)
  }

  handleSlotDeletion(sday, index) {
    let slots = this.state.slot_available
    slots[sday].splice(index, 1)
    this.setState({ slot_available: slots })
    this.saveSlotsToBackend(slots)
  }

  handleOverrideSlotAvailability(sday, index, stime, endPoint) {
    let temp = this.state.slot_override
    if (endPoint === 'start') temp[sday][index].start = stime
    else if (endPoint === 'end') temp[sday][index].end = stime
    this.saveOverRideSlotsToBackend(temp)
    this.setState({ slot_override: temp })
  }

  handleOverrideSlotDeletion(sday, index) {
    let slots = this.state.slot_override
    delete slots[sday]
    this.saveOverRideSlotsToBackend(slots)
    this.setState({ slot_override: slots })
  }

  handleOverrideTimeSlotDeletion(sday, index) {
    let slots = this.state.slot_override
    slots[sday].splice(index, 1)
    this.setState({ slot_override: slots })
    this.saveOverRideSlotsToBackend(slots)
  }

  renderSlots({ slot, sday, handleChange, handleDelete }) {
    let returnSlots = []

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <Button
        onClick={onClick}
        ref={ref}
        className={Styles.dateInputButton}
        variant="primary"
      >
        {value}
      </Button>
    ))

    if (Array.isArray(slot)) {
      if (slot.length === 0) {
        returnSlots.push(
          <div
            key={0}
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              color: 'gray',
            }}
          >
            Unavailable
          </div>,
        )
      } else {
        slot.map((s, index) => {
          returnSlots.push(
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <DatePicker
                wrapperClassName={Styles.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.start}
                timeIntervals={15}
                onChange={(time) => {
                  handleChange(sday, index, time.valueOf(), 'start')
                  // console.log(time)
                }}
                customInput={<CustomDateInput />}
                // locale="sv-sv"
              />
              <DatePicker
                wrapperClassName={Styles.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.end}
                timeIntervals={15}
                onChange={(time) => {
                  handleChange(sday, index, time.valueOf(), 'end')
                  // console.log(time)
                }}
                // locale="sv-sv"
                customInput={<CustomDateInput />}
              />

              <FontAwesomeIcon
                icon={faTrash}
                className={Styles.deleteIcon}
                onClick={() => handleDelete(sday, index)}
              />
            </div>,
          )
        })
      }
    }

    return returnSlots
  }

  renderTimeSlots() {
    const { mongoUser } = this.props
    let slots = this.state.slot_available
    let toReturn = []

    Object.keys(slots).forEach((slot, index) => {
      toReturn.push(
        <div
          key={index}
          style={{
            display: 'flex',
            margin: '15px',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px solid #DEDEDE',
            // borderTop:'1px solid #DEDEDE'
            // border-bottom: 1px solid #DEDEDE;
          }}
        >
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flex: 'auto',
            }}
          >
            <label class={Styles.checkContainer}>
              {slot}
              <input
                type="checkbox"
                checked={slots[slot].length !== 0 ? true : false}
                onClick={() => {
                  slots[slot].length !== 0
                    ? (slots[slot] = [])
                    : slots[slot].push({
                        start: 1635478200000,
                        end: 1635507000000,
                      })
                  this.setState({ slot_available: slots })
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', flex: 'auto' }}
          >
            {this.renderSlots({
              slot: slots[slot],
              sday: slot,
              handleChange: this.handleSlotAvailability,
              handleDelete: this.handleSlotDeletion,
            })}
          </div>

          <FontAwesomeIcon
            icon={faPlus}
            className={Styles.deleteIcon}
            onClick={() => {
              // console.log('ADD ', slot)
              let slots = this.state.slot_available
              slots[slot].push({ start: 1635478200000, end: 1635507000000 })
              this.setState({ slot_available: slots })
              this.saveSlotsToBackend(slots)
            }}
          />
        </div>,
      )
    })

    return toReturn
  }

  renderOverRideTimeSlots() {
    const { mongoUser } = this.props
    let slots = this.state.slot_override
    let toReturn = []

    Object.keys(slots).forEach((epochDate, index) => {
      let tempDateOfSlot = moment.unix(epochDate / 1000)
      if (tempDateOfSlot.isSameOrAfter(moment(), 'day'))
        // hide previous days data
        toReturn.push(
          <div
            style={{
              display: 'flex',
              margin: '15px',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid #DEDEDE',
              // borderTop:'1px solid #DEDEDE'
              // border-bottom: 1px solid #DEDEDE;
            }}
          >
            <p>{epochDate && tempDateOfSlot.format('ll')}</p>
            <div
              style={{ display: 'flex', flexDirection: 'column', flex: 'auto' }}
            >
              {this.renderSlots({
                slot: slots[epochDate],
                sday: epochDate,
                handleChange: this.handleOverrideSlotAvailability,
                handleDelete: this.handleOverrideTimeSlotDeletion,
                type: 'override',
              })}
            </div>
            <FontAwesomeIcon
              icon={faPlus}
              className={Styles.deleteIcon}
              onClick={() => {
                let slots = this.state.slot_override
                slots[epochDate].push({
                  start: moment(tempDateOfSlot).add(8, 'hours').valueOf(),
                  end: moment(tempDateOfSlot).add(9, 'hours').valueOf(),
                })
                this.setState({ slot_override: slots })
              }}
            />
            <FontAwesomeIcon
              icon={faMinusCircle}
              className={Styles.deleteIcon}
              onClick={() => this.handleOverrideSlotDeletion(epochDate, index)}
            />
          </div>,
        )
    })

    return toReturn
  }

  renderSlotAvailableModal() {
    const { mongoUser } = this.props

    let timedifference = new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <Button
        onClick={onClick}
        ref={ref}
        className={GlobalStyles.button}
        // disabled={this.state.modalLoading}
        style={{ width: 200, marginTop: 20, marginBottom: 20 }}
        variant="primary"
      >
        Add a date
      </Button>
    ))

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.onModalCloseRequested()
          }}
        >
          X
        </p>
        <h5 style={{ color: Constants.darkGray, textAlign: 'center' }}>
          {timedifference}
        </h5>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            borderTop: `1px solid ${Constants.darkGray}`,
            width: '100%',
          }}
        >
          <div
            style={{
              borderRight: `1px solid ${Constants.darkGray}`,
              paddingTop: 20,
              width: '50%',
            }}
          >
            <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
              Set your weekly hours
            </h4>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {this.renderTimeSlots()}
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => this.saveSlotsToBackend(mongoUser)}
                className={GlobalStyles.button}
                // disabled={this.state.modalLoading}
                style={{ width: 200, marginTop: 20, marginBottom: 20 }}
                variant="primary"
              >
                Save
              </Button>
            </div> */}
          </div>
          <div style={{ paddingTop: 20, width: '50%' }}>
            <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
              Add calendar exceptions
            </h4>
            <p
              style={{
                color: Constants.darkGray,
                padding: 10,
                textAlign: 'center',
              }}
            >
              Add dates when your availability changes from your weekly hours
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DatePicker
                selected={this.state.selectedOverRideDate}
                onChange={this.handleDateOverRideChange}
                dateFormat="MMM d, yyyy"
                placeholderText="Select a date"
                customInput={<CustomDateInput />}
                minDate={new Date()}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {this.renderOverRideTimeSlots()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRescheduleModal() {
    const {
      scheduleModalLoading,
      patientCareTeam,
      searchSpeciality,
      establishedPatients,
      patientType,
      patientList,
      modalEvent
    } = this.state

    //FILTER PATIENT TYPES

    let temp = establishedPatients
      .filter((item) => item.value !== undefined)
      .map((item) => item)

    //GET UNIQUE EMAIL IDS - NO MULTIPLE COPIES
    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()]
    }
    const establishedArray = getUniqueListBy(temp, 'label')
    let newPatients = patientList.filter(
      (a) =>
        !establishedArray.some(
          (b) => a.label === b.label && a.value === b.value,
        ),
    )

    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: ['speciality'],
      useExtendedSearch: true,
    }

    this.fuse = new Fuse(patientCareTeam, options)

    let uniqueList = []

    if (searchSpeciality) {
      const results = this.fuse.search(`="${searchSpeciality?.label}"`)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else {
      uniqueList = patientCareTeam
    }

    if (scheduleModalLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    // axios config
    let axiosConfig = {
      method: 'patch',
      url: `${Config.BACKEND_URL}appointments`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        'Content-Type': 'application/json',
      },
      data: {
        event_id: this.state.modalEvent?.id,
      },
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onRescheduleModalClose()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Re-Schedule Appointment
        </h4>

        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="Select patient"
            inputId="name"
            options={
              patientType?.value === 'Established Patient'
                ? establishedArray
                : patientType?.value === 'New Patient'
                ? newPatients
                : this.state.patientList
            }
            value={this.state.selectedReschedulePatient}
            isSearchable
            autoBlur
            isDisabled={true}
            disabled={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        {/* {this.state.selectedReschedulePatient &&
          this.state.patientCareTeam &&
          this.state.patientCareTeam.length > 0 && (
            <div style={{ width: '50%', marginTop: 10 }}>
              <Select
                placeholder={'Select care team speciality'}
                inputId="speciality"
                // options={this.state.careTeamSpeciality}
                value={this.state.searchSpeciality}
                isSearchable
                autoBlur
                disabled={true}
                isDisabled={true}
                // onChange={(val) => {
                //   // console.log(val)
                //   this.setState({
                //     searchSpeciality: val,
                //   })
                // }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          )} */}

        {this.state.selectedReschedulePatient && (
          <div style={{ width: '50%', marginTop: 10 }}>
            <Select
              placeholder={'Select care team member'}
              inputId="doctor_name"
              options={uniqueList}
              value={this.state.selectedRescheduleDoctor}
              isSearchable
              autoBlur
              disabled={true}
              isDisabled={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )}

        {this.state.selectedRescheduleDoctor &&
          this.state.selectedReschedulePatient &&
          this.state.axiosConfig && (
            <div
              style={{ marginTop: 10, marginBottom: 20, paddingBottom: '5%' }}
            >
              <MyCalendar
                // key={this.state.selectedRescheduleDoctor.doctor}
                parentCallback={this.handleCalendarCallback}
                doctorDetails={this.state.selectedRescheduleDoctor}
                axiosConfig={axiosConfig}
                patientId={this.state.selectedReschedulePatient.value}
                token={this.props.token}
                fromProvider={true}
                ifReschedule={true}
              />
            </div>
          )}
      </div>
    )
  }

  renderScheduleModal() {
    const {
      scheduleModalLoading,
      patientCareTeam,
      searchSpeciality,
      establishedPatients,
      patientType,
      patientList,
    } = this.state

    //FILTER PATIENT TYPES

    let temp = establishedPatients
      .filter((item) => item.value !== undefined)
      .map((item) => item)

    //GET UNIQUE EMAIL IDS - NO MULTIPLE COPIES
    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()]
    }
    const establishedArray = getUniqueListBy(temp, 'label')
    let newPatients = patientList.filter(
      (a) =>
        !establishedArray.some(
          (b) => a.label === b.label && a.value === b.value,
        ),
    )

    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: ['speciality'],
      useExtendedSearch: true,
    }

    this.fuse = new Fuse(patientCareTeam, options)

    let uniqueList = []

    if (searchSpeciality) {
      const results = this.fuse.search(`="${searchSpeciality?.label}"`)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else {
      uniqueList = patientCareTeam
    }

    if (scheduleModalLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Schedule Appointment
        </h4>

        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="Select patient type"
            inputId="name"
            options={[
              { label: 'New Patient', value: 'New Patient' },
              { label: 'Established Patient', value: 'Established Patient' },
            ]}
            value={this.state.patientType}
            isSearchable
            autoBlur
            onChange={(val) => {
              this.setState({
                patientType: val,
                selectedScheduleDoctor: null,
              })
              // this.getPatientCareTeam(val.value)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="Select patient"
            inputId="name"
            options={
              patientType?.value === 'Established Patient'
                ? establishedArray
                : patientType?.value === 'New Patient'
                ? newPatients
                : this.state.patientList
            }
            value={this.state.selectedSchedulePatient}
            isSearchable
            autoBlur
            onChange={(val) => {
              // console.log(val)
              this.setState({
                selectedSchedulePatient: val,
                selectedScheduleDoctor: null,
                scheduleModalLoading: true,
              })
              this.getPatientCareTeam(val.value)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        {this.state.selectedSchedulePatient &&
          this.state.patientCareTeam &&
          this.state.patientCareTeam.length > 0 && (
            <div style={{ width: '50%', marginTop: 10 }}>
              <Select
                placeholder={'Select care team speciality'}
                inputId="speciality"
                options={this.state.careTeamSpeciality}
                value={this.state.searchSpeciality}
                isSearchable
                autoBlur
                onChange={(val) => {
                  // console.log(val)
                  this.setState({
                    searchSpeciality: val,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          )}

        {this.state.selectedSchedulePatient &&
          this.state.patientCareTeam &&
          this.state.patientCareTeam.length > 0 && (
            <div style={{ width: '50%', marginTop: 10 }}>
              <Select
                placeholder={'Select care team member'}
                inputId="doctor_name"
                options={uniqueList}
                value={this.state.selectedScheduleDoctor}
                isSearchable
                autoBlur
                onChange={(val) => {
                  // console.log(val)
                  this.setState({
                    selectedScheduleDoctor: val,
                    axiosConfig: {},
                    scheduleModalLoading: true,
                  })

                  // What a horrible implementation of this feature,
                  // hopeless i mean hopeless. TODO: Edit this to make it more
                  // easy to make edits to this code, everything is so tightly
                  // coupled
                  this.getAppointmentUrl(
                    val.doctor.firebase_id,
                    this.state.selectedSchedulePatient.value,
                  )
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          )}

        {this.state.selectedSchedulePatient &&
          this.state.patientCareTeam &&
          this.state.patientCareTeam.length > 0 && (
            <div style={{ width: '50%', marginTop: 10, marginBottom: 20 }}>
              <input
                placeholder="Reason for visit"
                // className={
                //   {
                //     border: "1px solid #d0d0d0",
                //     padding: "5px 10px",
                //     border-radius: "4px",
                //     width: "50%"
                //   }
                // }
                style={{
                  width: '100%',
                  border: '1px solid #d0d0d0',
                  padding: '5px 10px',
                  borderRadius: '4px',
                }}
                type="text"
                value={this.state.reasonForVisit}
                onChange={(e) => {
                  this.setState({ reasonForVisit: e.target.value })
                  let { axiosConfig } = this.state
                  axiosConfig.data.reason = e.target.value
                  this.setState({ axiosConfig })
                }}
              />
            </div>
          )}

        {this.state.selectedScheduleDoctor &&
          this.state.selectedSchedulePatient &&
          this.state.axiosConfig && (
            <div
              style={{ marginTop: 10, marginBottom: 20, paddingBottom: '5%' }}
            >
              <MyCalendar
                key={this.state.selectedScheduleDoctor.doctor}
                parentCallback={this.handleCalendarCallback}
                doctorDetails={this.state.selectedScheduleDoctor.doctor}
                axiosConfig={this.state.axiosConfig}
                patientId={this.state.selectedSchedulePatient.value}
                token={this.props.token}
                fromProvider={true}
              />
            </div>
          )}
      </div>
    )
  }

  navigatetoPatientProfile(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        // console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  renderModal() {
    const { modalEvent, patientMap, eventsFromBackend, providerMap } =
      this.state
    if (!modalEvent) return null
    const eventFromBackend = eventsFromBackend[modalEvent.id] || {}
    const patientData = patientMap[eventFromBackend.patient_id] || {}
    const providerData = providerMap[eventFromBackend.doctor_id] || {}
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4
          style={{
            color: Constants.primaryTheme,
            textAlign: 'center',
            paddingTop: 20,
          }}
        >
          {modalEvent && modalEvent.title}
        </h4>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Patient Name</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            <Link
              style={{ color: '#000000', textDecoration: 'underline' }}
              onClick={() => {
                this.navigatetoPatientProfile(patientData.uid)
                this.onPatientNavLinkClicked('profile')
              }}
              to={{
                pathname: '/patient/profile',
                state: 'info',
                key: 2,
              }}
            >
              {`${patientData.firstName} ${patientData.lastName}`}
            </Link>
          </p>
        </div>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Provider Name</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {`${providerData.first_name} ${providerData.last_name}`}
          </p>
        </div>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Date</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {modalEvent && moment(modalEvent.start).format('ddd MMM Do YYYY ')}
          </p>
        </div>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Start Time</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {modalEvent && moment(modalEvent.start).format('hh:mm A')}
          </p>
        </div>
        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>End Time</p>
          <p>:</p>
          <p className={Styles.modalValue}>
            {modalEvent && moment(modalEvent.end).format('hh:mm A')}
          </p>
        </div>

        <div className={Styles.modalRow}>
          <p className={Styles.modalKey}>Reason for visit</p>
          <p>:</p>
          <p className={Styles.modalValue}>{eventFromBackend.reason}</p>
        </div>

        <div className={Styles.modalRow} style={{ justifyContent: 'center' }}>
          <p
            style={{
              color: '#0000FF',
            }}
            onClick={() => {
              this.setState({
                selectedReschedulePatient: {
                  label: `${patientData.firstName} ${patientData.lastName}`,
                  value: eventFromBackend.patient_id,
                },
                selectedRescheduleDoctor: {
                  label: `${providerData.first_name} ${providerData.last_name}`,
                  value: eventFromBackend.doctor_id,
                  ...providerData,
                },
                rescheduleModalIsOpen: true,
              })
            }}
          >
            Reschedule
          </p>
        </div>

        {eventFromBackend?.audio_result?.recorded ? (
          <div className={Styles.modalRow}>
            <p className={Styles.modalKey}>Positive screen for depression</p>
            <p>:</p>
            <p className={Styles.modalValue}>
              {eventFromBackend?.audio_result?.value
                ?.toString()
                .replace(/_/g, ' ')
                .replace(/\b[a-z]/g, function (letter) {
                  return letter.toUpperCase()
                })}
            </p>
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: 40,
            justifyContent: 'space-between',
            width: 200,
          }}
        >
          <div
            style={{
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: Constants.primaryTheme,
              borderRadius: '50%',
            }}
          >
            <FontAwesomeIcon
              onClick={() =>
                this.onVideoCallPressed(eventFromBackend, patientData)
              }
              icon={faVideo}
              style={{ color: 'white', fontSize: 20, cursor: 'pointer' }}
            />
          </div>

          <div
            style={{
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'red',
              borderRadius: '50%',
            }}
          >
            <FontAwesomeIcon
              onClick={() => {
                if (
                  window.confirm('Are you sure you want to delete the event?')
                ) {
                  this.onModalDeleteEventPressed()
                }
              }}
              icon={faMinusCircle}
              style={{ color: 'white', fontSize: 20, cursor: 'pointer' }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: 10,
            justifyContent: 'space-between',
            width: 200,
          }}
        >
          <p style={{ color: 'gray' }}>Start</p>
          <p style={{ color: 'gray' }}>Cancel</p>
        </div>
      </div>
    )
  }

  eventStyleGetter(event) {
    const style = {
      backgroundColor: event?.appointmentData?.delete
        ? '#fe2c54'
        : event?.appointmentData?.follow_up?.no_show
        ? '#ed012e'
        : moment(event?.appointmentData?.start).isBefore(moment())
        ? 'grey'
        : event.ailaEvent
        ? Constants.primaryTheme
        : '#38B0DE',
      color: 'white',
    }
    return {
      style,
    }
  }

  renderContent() {
    const { mongoUser, firebaseUser } = this.props
    let { superUser } = this.state

    let eventsToShow = []
    if (this.state.ailaEventsSelected) eventsToShow = this.state.events || []
    else eventsToShow = this.state.eventsFromNylas || []
    return (
      <div className={GlobalStyles.contentWrapper}>
        {/* {mongoUser.nylas && ( */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {this.state.infusionSelected ? null : (
            <Button
              onClick={() => {
                this.state.infusionSelected
                  ? this.onInfusionScheduleClicked()
                  : this.onScheduleClicked()
              }}
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{ width: 250, marginLeft: 10 }}
              variant="primary"
            >
              Schedule Appointment
            </Button>
          )}

          {/* What is this for? */}
          <div style={{}}>
            {firebaseUser?.superUser && (
              <Button
                onClick={() => {
                  this.setState({ superUser: !superUser }, () =>
                    this.getPatientList(),
                  )
                }}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{
                  width: 150,
                  // marginRight: '5vw',
                  right: 0,
                  // position: 'absolute',
                }}
                variant="primary"
              >
                {superUser ? 'Super User' : 'Provider'}
              </Button>
            )}
            {/* {this.state.infusionSelected ? null : (
              <Button
                onClick={() => this.onSlotClicked()}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{
                  width: 250,
                  marginRight: '2vw',
                  marginLeft: '2vw',
                  right: 0,
                  // position: 'absolute',
                }}
                variant="primary"
              >
                Availability
              </Button>
            )} */}
          </div>
        </div>

        {/* This is the appointment start modal */}
        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.modalIsOpen}
          style={APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderModal()}
        </Modal>

        {/* This is the reschedule modal */}
        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.onRescheduleModalClose()}
          isOpen={this.state.rescheduleModalIsOpen}
          style={RESCHEDULE_APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderRescheduleModal()}
        </Modal>

        {/* This is the appointment schedule modal */}
        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.showScheduleModal}
          style={SCHEDULE_APPOINTMENT_MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderScheduleModal()}
        </Modal>

        {/* This is the slot availability modal */}
        <Modal
          ariaHideApp={true}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.showSlotAvailableModal}
          style={SLOT_AVAILABLE_MODAL_STYLES}
          contentLabel="Slot Modal"
        >
          {this.renderSlotAvailableModal()}
        </Modal>

        <div
          style={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: 300,
              height: 40,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              onClick={() =>
                this.setState({
                  ailaEventsSelected: true,
                  infusionSelected: false,
                  tabSelected: 'aila_events',
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.tabSelected === 'aila_events'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.tabSelected === 'aila_events'
                      ? 'white'
                      : 'black',
                }}
              >
                Aila Events
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({
                  ailaEventsSelected: false,
                  infusionSelected: false,
                  tabSelected: 'all_events',
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.tabSelected === 'all_events'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.tabSelected === 'all_events' ? 'white' : 'black',
                }}
              >
                All Events
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({
                  ailaEventsSelected: false,
                  infusionSelected: true,
                  tabSelected: 'infusion',
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.tabSelected === 'infusion'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.tabSelected === 'infusion' ? 'white' : 'black',
                }}
              >
                Infusion
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 60px',
            marginTop: 20,
            visibility:
              this.state.modalIsOpen ||
              this.state.showScheduleModal ||
              this.state.showSlotAvailableModal
                ? 'hidden'
                : 'visible',
          }}
        >
          {this.state.infusionSelected ? null : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: 300,
                zIndex: 25,
                position: 'sticky',
                margin: '0% auto 2%',
                alignItems: 'flex-start',
              }}
            >
              Search Date &ensp;
              <div
                style={{
                  width: '70%',
                }}
              >
                <SearchPicker
                  value={
                    this.state.searchDate ? this.state.searchDate : new Date()
                  }
                  onChange={(date) => {
                    console.log(date)
                    this.setState({ searchDate: date })
                  }}
                />
              </div>
            </div>
          )}
          {/*  calendar */}
          {this.state.infusionSelected ? (
            this.state.infusionScheduleModalSelected ? null : (
              // This is the infusion scheduling calendar
              <InfusionDemo
                infusionScheduleModalSelected={
                  this.state.infusionScheduleModalSelected
                }
                // TODO: edit this to the infusion events
                onInfusionScheduleClicked={this.onInfusionScheduleClicked}
              />
            )
          ) : (
            <Calendar
              defaultView="day"
              onSelectEvent={this.onSelectEvent.bind(this)}
              localizer={localizer}
              // defaultDate={new Date()}
              //TO SEARCH DATE USE BELOW
              date={this.state.searchDate}
              onNavigate={(date) => {
                this.setState({ searchDate: date })
              }}
              events={eventsToShow}
              startAccessor="start"
              endAccessor="end"
              tooltip={(event) => (
                <p style={{ backgroundColor: 'red' }}>{event.title}</p>
              )}
              eventPropGetter={this.eventStyleGetter.bind(this)}
              style={{ height: 700 }}
            />
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        <Header header="Appointments" />

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  adminId: state.userReducer.adminId,
  patientList: state.patientReducer.patientList,
  firebaseUser: state.userReducer.firebaseUser,
})

const mapDispatchToProps = {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  addAppointmentData,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Appointments)
