import React, { Component } from 'react'
import axios from 'axios'
import addresser from 'addresser'
import Config from '../../../config'

class PatientDetailsTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientId: props.patientId,
      phone: null,
      dob: null,
      eligibility: null,
      state: null,
      insId: null,
      loading: true,
    }
  }

  componentDidMount() {
    // console.log('Mounted')
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}tooltip/get/user-details`,
      // url: `http://localhost:15000/tooltip/get/user-details`,
      params: {
        patientId: this.props.patientId,
      },
    }).then((res) => {
      let userData = res?.data?.data
      if (userData.success === false) {
        this.setState({ phone: 'ERRRO', loading: false })
        console.log('error getting patient details')
        return
      }
      console.log('Got data back for tooltip', userData.data)
      let dob = userData?.dob
      if (dob) {
        dob = dob.split('T')[0]
        if (dob.includes('-')) {
          let iDob = dob.split('-')
          dob = iDob[1] + '/' + iDob[0] + '/' + iDob[2]
        } else if (dob.includes('/')) {
          let iDob = dob.split('/')
          dob = iDob[1] + '/' + iDob[0] + '/' + iDob[2]
        }
      }
      let parsedPatientAddress = null
      let parsedPatientAddress2 = null
      try {
        parsedPatientAddress = addresser.parseAddress(userData.street_address)
        console.log("S1", parsedPatientAddress)
      } catch (err) {
        console.log('ERROR parsing address details: ', err)
      }
      try {
        parsedPatientAddress2 = addresser.parseAddress(userData.street_address2)
        console.log("S2", parsedPatientAddress2)
      } catch (err) {
        console.log('ERROR parsing address2 details: ', err)
      }
      let patientState =
        parsedPatientAddress?.stateAbbreviation ||
        parsedPatientAddress2?.stateAbbreviation ||
        "N/A"
      let officeCopay = userData?.insurance_data?.benefits?.office_visit_copay
      let specialistCopay = userData?.insurance_data?.benefits?.copay
      this.setState({
        phone: userData?.phone_number,
        dob: dob,
        cc: userData?.stripe?.customer_id,
        insurance: userData?.insurance_data?.subscriber_id,
        insId: userData?.insurance_data?.subscriber_id,
        state: patientState,
        pcn: userData?.pcn,
        loading: false,
        officeCopay,
        specialistCopay
      })
    })
  }

  render() {
    if (this.state.loading) return <div>Loading...</div>
    return (
      <div>
        <div>PCN:{this.state.pcn}</div>
        <div>State:{this.state.state}</div>
        <div>PH:{this.state.phone}</div>
        <div>Insurance ID:{this.state.insId}</div>
        <div>DOB:{this.state.dob}</div>
        <div>CC on File:{this.state.cc ? 'Yes' : 'No'}</div>
        <div>Insurance on File:{this.state.insurance ? 'Yes' : 'No'}</div>
        <div>Office Copay:{this.state.officeCopay}</div>
        <div>Specialist Copay :{this.state.specialistCopay}</div>
      </div>
    )
  }
}

export default PatientDetailsTooltip
