import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../../config'
import moment from 'moment'

class ChargeDetailsTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: props.eventId,
      totalTimeSpent: null,
      cpts: null,
      amendedCpts: null,
      icds: null,
      timeSpent: null,
      billedAmount: null,
      allowedAmount: null,
      adjustedAmount: null,
      notCovered: null,
      deductible: null,
      copay: null,
      coinsurance: null,
      amountPaid: null,
    }
  }

  componentDidMount() {
    console.log('Mounted', this.props)
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}tooltip/get/billing-details`,
      // url: `http://localhost:15000/tooltip/get/event-details`,
      params: {
        eventId: this.props.eventId,
        noteId: this.props.noteId
      },
    }).then((res) => {
      if (res.data.success === false) {
        this.setState({ phone: 'ERRRO', loading: false })
        return console.log('error getting patient details')
      }
      console.log('Res', res.data)
      let allBillingData = res.data?.data
      let cpts =
        allBillingData?.cpts?.map((entry) => entry.cpt_code).join(', ') || 'N/A'
      let icd10s =
        allBillingData?.icd10s?.map((entry) => entry.icd10_code).join(', ') ||
        'N/A'
      let amendedCpts =
        allBillingData?.amended_cpts
          ?.map((entry) => entry.cpt_code)
          .join(', ') || 'N/A'
      let timeSpent =
        Number(
          moment
            .unix(allBillingData.appointment_end_date?.[0] / 1000)
            .diff(
              moment.unix(allBillingData.appointment_date?.[0] / 1000),
              'minute',
            ) || 0,
        ) +
        Number(allBillingData?.time_spent?.[0]?.pre_review_time || 0) +
        Number(allBillingData?.time_spent?.[0]?.after_visit || 0) +
        Number(allBillingData?.time_spent?.[0]?.provider_colab || 0) +
        ' minutes'
      this.setState({
        cpts: cpts,
        icds: icd10s,
        amendedCpts: amendedCpts,
        timeSpent: timeSpent,
        billedAmount: res.data?.data?.stripe?.billed,
        loading: false,
      })
    })
  }

  render() {
    if (this.state.loading) return <div>Loading...</div>
    return (
      <div>
        <div>CPT(s):{this.state.cpts}</div>
        <div>Amended CPT(s):{this.state.amendedCpts}</div>
        <div>ICDs (s):{this.state.icds}</div>
        <div>Provider Time Spent:{this.state.timeSpent}</div>
        {/* <div>Billed Amount:{this.state.billedAmount}</div>
        <div>Allowed Amount:{this.state.allowedAmount}</div>
        <div>Adjusted amount:{this.state.adjustedAmount}</div>
        <div>Not Covered/ Discount:{this.state.notCovered}</div>
        <div>Deductible:{this.state.deductible}</div>
        <div>Copay:{this.state.copay}</div>
        <div>Coinsurance: {this.state.coinsurance}</div>
        <div>Amount Paid by Plan:{this.state.amountPaid}</div> */}
      </div>
    )
  }
}

export default ChargeDetailsTooltip
