import React, { useEffect, useState } from 'react'
import Constants from '../../../values.js'
import Styles from './styles/Careplan.module.scss'
import Config from '../../../config'
import GlobalStyles from '../../styles/global.module.scss'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import { useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import ReactTooltip from 'react-tooltip'
import FileViewer from '../../../components/pdf/FileViewer'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Communications from '../../Admin/Communications.js'

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export default function CommunicationsList() {
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)
  const [loading, setLoading] = useState(false)
  const [communicationList, setCommunicationList] = useState([])
  const [appointmentList, setAppointmentList] = useState([])
  const [openDoc, setOpenDoc] = useState(false)
  const [docFile, setDocFile] = useState('')
  const [docType, setDocType] = useState('')
  const [showCommModal, setShoCommModal] = useState(false)

  const getCommunicationsList = () => {
    setLoading(true)
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + 'communications/phone',
      headers: {
        Authorization: `JWT ${token}`,
        patient_id: patient?.patient_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log(data)
        let appData = data
        setLoading(false)
        if (appData.length) {
          appData.sort((a, b) => {
            return a.created_at - b.created_at
          })
          //   console.log('appData: ', appData)
          setCommunicationList(appData?.reverse())
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log('error when fetching appointmentlist: ', err)
      })
  }

  const getAppointmentList = () => {
    setLoading(true)
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + 'patient/appointments',
      headers: {
        Authorization: `JWT ${token}`,
        x_patient_id: patient?.patient_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        let appData = data
        setLoading(false)
        // console.log('appData', appData)
        if (appData.length) {
          appData.sort((a, b) => {
            return a.start - b.start
          })
          //   console.log('appData: ', appData)
          setAppointmentList(appData?.reverse())
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log('error when fetching appointmentlist: ', err)
      })
  }

  useEffect(() => {
    getCommunicationsList()
    getAppointmentList()
  }, [])

  const renderCommunicationList = (communicationData, index) => {
    return (
      <div
        key={index}
        className={Styles.contentRow}
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <h6 style={{ color: Constants.primaryTheme }}>
            {communicationData?.communication_type} {', '}
            {moment(communicationData?.created_at).format(
              'MMM Do YYYY, HH:mm A',
            )}
          </h6>
        </div>

        <div
          className={Styles.contentRow}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderBottom: '0px',
          }}
        >
          {/* <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <h6 style={{ color: Constants.primaryTheme }}>
              {communicationData?.communication_type} <br />
              {moment(communicationData?.created_at).format(
                'MMM Do YYYY, HH:mm A',
              )}
            </h6>
          </div> */}

          {communicationData?.communication_type === 'Email' ? (
            <>
              <div style={{ width: '100%' }}>
                <h6 style={{ color: Constants.primaryTheme }}></h6>
                <p style={{ marginLeft: 20 }}>
                  Subject - {communicationData?.subject}
                </p>
              </div>
              <div style={{ width: '100%' }}>
                <h6 style={{ color: Constants.primaryTheme }}></h6>
                <p
                  data-tip
                  data-for={index + 'message'}
                  style={{
                    marginLeft: 20,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Message - {communicationData?.message}
                </p>
                <ReactTooltip
                  id={index + 'message'}
                  multiline={true}
                  place={'top'}
                  effect={'solid'}
                  clickable={true}
                  className={Styles.tooltip}
                >
                  {communicationData?.message}
                </ReactTooltip>
              </div>
              {communicationData?.file_attached &&
                communicationData?.file_attached?.fileName && (
                  <div style={{ width: '100%' }}>
                    <h6 style={{ color: Constants.primaryTheme }}></h6>
                    <p
                      style={{
                        marginLeft: 20,
                        fontWeight: 'bold',
                        color: Constants.darkGray,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenDoc(true)
                        setDocType(
                          communicationData?.file_attached?.fileName
                            ? communicationData?.file_attached?.fileName
                                .split('.')
                                .pop()
                            : 'pdf',
                        )
                        setDocFile(
                          communicationData?.file_attached?.downloadURL,
                        )
                      }}
                    >
                      View Attachment
                    </p>
                  </div>
                )}
            </>
          ) : (
            <>
              <div style={{ width: '100%' }}>
                <h6 style={{ color: Constants.primaryTheme }}></h6>
                <p style={{ marginLeft: 20 }}>
                  Request Type- {communicationData?.phone_request}
                </p>
              </div>
              <div style={{ width: '100%' }}>
                <h6 style={{ color: Constants.primaryTheme }}></h6>
                <p
                  data-tip
                  data-for={index + 'phone_message'}
                  style={{
                    marginLeft: 20,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Message - {communicationData?.phone_message}
                </p>
                <ReactTooltip
                  id={index + 'phone_message'}
                  multiline={true}
                  place={'top'}
                  effect={'solid'}
                  clickable={true}
                  className={Styles.tooltip}
                >
                  {communicationData?.phone_message}
                </ReactTooltip>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderCommunicationModal = () => {
    return (
      <Communications
        audience={{ label: 'Patient', value: 'Patient' }}
        isAudienceSelectDisabled={true}
        isEmailSelectDisabled={true}
        patientEmail={{ label: patient.email, value: patient.email }}
        parentCallback={() => setShoCommModal(false)}
      />
    )
  }

  return (
    <div style={{ width: '100%', marginTop: 40 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h5 style={{ color: Constants.primaryTheme }}>Communications</h5>
        <FontAwesomeIcon
          onClick={() => setShoCommModal(true)}
          icon={faPlusCircle}
          style={{
            color: Constants.primaryTheme,
            fontSize: 18,
            position: 'absolute',
            right: 5,
            top: 10,
            cursor: 'pointer',
          }}
        />
      </div>
      <div
        className={Styles.contentBackground}
        style={{ height: 500, overflowY: 'scroll' }}
      >
        {communicationList.length !== 0 ? (
          communicationList.map((communicationData, index) =>
            renderCommunicationList(communicationData, index),
          )
        ) : (
          <p style={{ textAlign: 'center', marginTop: 50 }}>
            No Communications
          </p>
        )}
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={openDoc}
        onRequestClose={() => {
          setOpenDoc(false)
          setDocFile('')
          setDocType('')
        }}
        style={customDocModalStyles}
        contentLabel="Modal"
      >
        <FileViewer fileType={docType} fileName={docFile} />
        {/* <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${docFile}`}
          /> */}
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={showCommModal}
        onRequestClose={() => {
          setShoCommModal(false)
        }}
        style={customDocModalStyles}
        contentLabel="Modal"
      >
        {renderCommunicationModal()}
      </Modal>
    </div>
  )
}
