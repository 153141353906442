import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Fuse from 'fuse.js'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-date-picker'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import DocPlaceHolder from '../../assets/images/docPlaceholder.png'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Referrals.module.scss'

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import {
  faSearch,
  faTrash,
  faUserMd,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios'
import Handlebars from 'handlebars'
import Config from '../../config'
import htmlModule1 from '../../helpers/PdfTemplates/ProviderReferralPhysician-new.txt'
import htmlModule3 from '../../helpers/PdfTemplates/ProviderReferralImaging1.txt'
import htmlModule2 from '../../helpers/PdfTemplates/ProviderReferralInfusion.txt'
import metadata, { SPECIALITY_OPTIONS } from '../../metadata.js'
import { remove } from 'lodash'

const axios = require('axios')

const bodySitesConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'body-sites',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigBodySites = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: bodySitesConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const medicationsConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'medications',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigMedications = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: medicationsConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      aila_id: { raw: {} },
      drug_name: { raw: {} },
      object_id: { raw: {} },
    },
  },
}

const iCD10Connector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'icd10-complete',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigICD10 = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: iCD10Connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      object_id: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const ORDER_STATUSES = {
  C: 'Corrected',
  E: 'Entered',
  F: 'Final Reported',
  I: 'Inactive',
  NA: 'Results Received',
  P: 'Partial Reported',
  R: 'Ready to Transmit',
  T: 'Transmitted',
  TX: 'Transmission Error',
  X: 'Error',
}

// const PLACEHOLDER_IMAGE =
//   'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'
// let windowHeight = window.innerHeight
// let windowWidth = window.innerWidth

// const condnClient = {
//   search(requests) {
//     if (requests[0].params.query === '') {
//       return []
//     }
//     return searchClient.search(requests)
//   },
// }

// const eventFire = (el, etype) => {
//   if (el.fireEvent) {
//     el.fireEvent('on' + etype)
//   } else {
//     var evObj = document.createEvent('Events')
//     evObj.initEvent(etype, true, false)
//     el.dispatchEvent(evObj)
//   }
// }

// const ClearQuery = ({ refine }) => {
//   const onClick = (ev) => {
//     refine('')
//     ev.stopPropagation()
//   }
//   return (
//     <div onClick={onClick} style={{ display: 'none' }}>
//       <ConnectedClearAllRefinements />
//     </div>
//   )
// }

// const ClearAllRefinements = ({ refine, items }) => {
//   const onClick = () => refine(items)
//   return (
//     <button id="clearQuery" onClick={onClick}>
//       ClearAll
//     </button>
//   )
// }

// const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
// const ConnectedClearAllRefinements =
//   connectCurrentRefinements(ClearAllRefinements)

// const customPatientAssignModalStyle = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     zIndex: 10,
//   },
//   content: {
//     zIndex: 10,
//     width: '75%',
//     height: '60%',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

// const PROVIDERS = [
//   {
//     label: 'Alison Shmerling',
//     value: '4015227895',
//     clinicianId: 'iazlt523',
//     clinicianPwd: 'iazlt523',
//   },
// ]

// const UNITS = [
//   { label: 'Bag', value: 'Bag' },
//   { label: 'Bar', value: 'Bar' },
//   { label: 'Capsules', value: 'Capsules' },
//   { label: 'Canister', value: 'Canister' },
//   { label: 'Cartridge', value: 'Cartridge' },
//   { label: 'Pills', value: 'Pills' },
//   { label: 'Strip', value: 'Strip' },
//   { label: 'Swab', value: 'Swab' },
//   { label: 'Syringe', value: 'Syringe' },
//   { label: 'Tube', value: 'Tube' },
//   { label: 'Vial', value: 'Vial' },
// ]

// const PHARMACIES = [
//   {
//     label: 'Bannockburn Pharmacy[6798 PYLE RD, BETHESDA (301)644-2418]',
//     value: '1',
//   },
//   {
//     label:
//       'Shollenberger Pharmacy[2002 S. MCDOWELL BLVD EXT, PETALUMA (707)984-5571]',
//     value: '2',
//   },
// ]

// const customDrugModalStyles = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     zIndex: 10,
//   },
//   content: {
//     zIndex: 10,
//     width: '50%',
//     height: '75%',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

// const customModalStyles = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     zIndex: 10,
//   },
//   content: {
//     zIndex: 10,
//     width: 800,
//     height: 800,
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#20A89233',
    primary: '#20A892',
  },
})

const Referrals = (props) => {
  const patientCarePlanData = useSelector(
    (state) => state.patientReducer.patientCarePlanData,
  )
  const patientMedicationsData = useSelector(
    (state) => state.patientReducer.patientMedicationsData,
  )
  const patientConditionsData = useSelector(
    (state) => state.patientReducer.patientConditionsData,
  )

  const patientCore = useSelector((state) => state.patientReducer.patientCore)

  const [loading, setLoading] = useState(false)
  const [referralType, setReferralType] = useState(props.referralType || null)
  const [referringTo, setReferringTo] = useState('')
  const [speciality, setSpeciality] = useState(null)
  const [extraSpecialty, setExtraSpecialty] = useState('')
  const [labLocation, setLabLocation] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [faxNumber, setFaxNumber] = useState('1')
  const [priority, setPriority] = useState({
    label: 'Routine',
    value: 'Routine',
  })
  const [dateInfusion, setDateInfusion] = useState(null)
  const [txAuth, setTxAuth] = useState(true)
  const [dateAuthInfusion, setDateAuthInfusion] = useState(null)
  const [orderType, setOrderType] = useState(null)
  const [medication, setMedication] = useState([])
  const [infusionDrug, setInfusionDrug] = useState(null)
  const [medicationQuery, setMedicationQuery] = useState('')
  const [dosage, setDosage] = useState('')
  const [units, setUnits] = useState('')
  const [frequency, setFrequency] = useState('')
  const [treatments, setTreatments] = useState(null)
  const [otherMeds, setOtherMeds] = useState('')
  const [accessType, setAccessType] = useState(null)
  const [reasonIcd, setReasonIcd] = useState('')
  const [notes, setNotes] = useState('')
  const [includePChart, setIncludePChart] = useState(false)
  const [appointmentEventId, setAppointmentEventId] = useState(
    props?.appointmentEventId || null,
  )
  const [referralId, setReferralId] = useState(props?.referral_id || null)
  const [showNext, setShowNext] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showReferringToModal, setShowReferringToModal] = useState(false)
  const [pdfTemplate, setPdfTemplate] = useState(null)
  const [pdfData, setPdfData] = useState('')
  const [saveReferralData, setSaveReferralData] = useState(null)
  const [icdCodes, setIcdCodes] = useState([])
  const [selectedNote, setSelectedNote] = useState([])
  const [allProviderList, setAllProviderList] = useState([])
  const [providerOptionList, setProviderOptionList] = useState([])

  //MEDS, ALLERGIES AND CONDITIONS DATA
  const [providerMeds, setProviderMeds] = useState([])
  const [providerConds, setProviderConds] = useState([])
  const [providerAllergies, setProviderAllergies] = useState([])
  const [sendAllergies, setSendAllergies] = useState(false)
  const [noKnownAllergies, setNoKnownAllergies] = useState(false)
  const [sendMeds, setSendMeds] = useState(false)
  const [sendConditions, setSendConditions] = useState(false)
  const [sendFamilyHistory, setSendFamilyHistory] = useState(false)

  //LAB ORDERS DATA
  const [sendLabs, setSendLabs] = useState(false)
  const [labOrdersFromBackend, setLabOrdersFromBackend] = useState(null)
  const [labOrdersFromChange, setLabOrdersFromChange] = useState(null)

  //IMAGING NEW FIELDS
  const [imagingStudy, setImagingStudy] = useState(null)
  const [otherImagingStudy, setOtherImagingStudy] = useState(null)
  const [fluoroscopy, setFluoroscopy] = useState(null)
  const [nuclearMedicine, setNuclearMedicine] = useState(null)
  const [bodySite, setBodySite] = useState([])
  const [bodysiteQuery, setBodysiteQuery] = useState(null)
  const [contrast, setContrast] = useState(null)
  const [literality, setLiterality] = useState(null)
  const [claustrophobic, setClaustrophobic] = useState(false)
  const [ifOralSedative, setIfOralSedative] = useState(false)
  const [ifIVSedative, setIfIVSedative] = useState(false)
  const [ifGeneralAnesthesia, setIfGeneralAnesthesia] = useState(false)
  const [ifPerformAsOrdered, setIfPerformAsOrdered] = useState(false)
  const [ifOkToBeAltered, setIfOkToBeAltered] = useState(false)
  const [imagingMed, setImagingMed] = useState([
    {
      name: 'Oral Sedative (Medication given by referring provider',
      isChecked: false,
    },
    { name: `IV Sedation by RAD's RN`, isChecked: false },
    { name: 'General Anesthesia', isChecked: false },
    { name: 'Perform as ordered, DO NOT ALTER', isChecked: false },
    { name: 'OK to be altered per radiologist Discretion', isChecked: true },
  ])

  //INFUSION, IMAGING, DME SEARCH DATABASE
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const [daw, setDaw] = useState(false)
  const [showSearchList, setShowSearchList] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [searchList, setSearchList] = useState([])
  const [facilityType, setFacilityType] = useState({
    label: 'DME',
    value: 'DME_DATABASE',
  })
  const [diagnoses, setDiagnoses] = useState([
    {
      name: 'Rheumatoid Arthritis (RA)',
      isChecked: false,
    },
    {
      name: 'Sickle Cell Disease',
      isChecked: false,
    },
    {
      name: 'Systemic Lupus Erythematosus (SLE)',
      isChecked: false,
    },
    {
      name: 'Eosinophilic Asthma',
      isChecked: false,
    },
    {
      name: 'Ulcerative Colitis',
      isChecked: false,
    },
    {
      name: 'Crohn’s Disease',
      isChecked: false,
    },
    {
      name: 'Psoriatic Arthritis',
      isChecked: false,
    },
    {
      name: 'Plaque Psoriasis',
      isChecked: false,
    },
    {
      name: 'Ankylosing Spondylitis',
      isChecked: false,
    },
    {
      name: 'Ulcerative Colitis',
      isChecked: false,
    },
    {
      name: 'Primary Humoral Immunodeficiency (PI)',
      isChecked: false,
    },
    {
      name: 'Multifocal Motor Neuropathy (MMN)',
      isChecked: false,
    },
    {
      name: 'Iron Deficiency',
      isChecked: false,
    },
    {
      name: 'Iron Deficiency Anemia',
      isChecked: false,
    },
    {
      name: 'Chronic Gout',
      isChecked: false,
    },
    {
      name: 'Severe Asthma (Maintenance Treatment)',
      isChecked: false,
    },
    {
      name: 'Eosinophilic Granulomatosis with Polyangiitis (EGPA)',
      isChecked: false,
    },
    {
      name: 'Hypereosinophilic Syndrome',
      isChecked: false,
    },
    {
      name: 'Multiple Sclerosis',
      isChecked: false,
    },
    {
      name: 'Treatment of Osteoporosis',
      isChecked: false,
    },
    {
      name: 'Prevention of Osteoporosis',
      isChecked: false,
    },
    {
      name: 'Osteoporosis in Men',
      isChecked: false,
    },
    {
      name: 'Glucocorticoid-Induced Osteoporosis',
      isChecked: false,
    },
    {
      name: 'Paget’s Disease of Bone',
      isChecked: false,
    },
    {
      name: 'Granulomatosis w/Polyangitis (GPA)',
      isChecked: false,
    },
    {
      name: 'Microscopic Polyangitis (MPA)',
      isChecked: false,
    },
    {
      name: 'Pemphigus Vulgaris (PV)',
      isChecked: false,
    },
    {
      name: 'Hemochromatosis',
      isChecked: false,
    },
    {
      name: 'Polycythemia Vera',
      isChecked: false,
    },
    {
      name: 'Porphyria Cutanea Tarda',
      isChecked: false,
    },
    {
      name: 'Asthma',
      isChecked: false,
    },
    {
      name: 'Nasal Polyps',
      isChecked: false,
    },
    {
      name: 'Chronic Idiopathic Urticaria (CIU)',
      isChecked: false,
    },
    {
      name: 'COVID-19',
      isChecked: false,
    },
    {
      name: 'Immunization not carried out because of patient allergy to vaccine or component',
      isChecked: false,
    },
    {
      name: 'Immunodeficiency, unspecified',
      isChecked: false,
    },
  ])
  const [preMedications, setPreMedications] = useState([
    {
      name: 'No routine premedications necessary',
      isChecked: false,
    },
    {
      name: 'Acetaminophen (TYLENOL) 650mg PO, Once, 30 minutes prior to infusion',
      isChecked: false,
    },
    {
      name: 'Cetirizine (ZYRTEC) 10mg PO, Once, 30 minutes prior to infusion',
      isChecked: false,
    },
    {
      name: 'Hydrocortisone Sodium Succinate (SOLU-CORTEF) 50mg IVP (NOT ROUTINE; ONLY IF BREAKTHROUGH REACTION) Once PRN, 30 minutes prior to infusion in addition to Acetaminophen and Cetirizine if patient experiences symptoms with Acetaminophen and Cetirizine alone',
      isChecked: false,
    },
    {
      name: 'Methylprednisolone Sodium Succinate (SOLU-MEDROL) 125mg IVP, Once, 30 minutes prior to IV Iron (NOT ROUTINE; RECOMMENDED FOR PATIENTS WITH A HISTORY OF DRUG ALLERGIES, AN ALLERGIC DIATHESIS OR A HISTORY OF INFLAMMATORY ARTHRITIS, WHEREIN BOTH PARENTERAL AND ORAL IRON HAVE BEEN SHOWN TO EXACERBATE SYMPTOMS)',
      isChecked: false,
    },
    {
      name: 'Loratadine (CLARITIN) 10MG PO, Once, 30 minutes prior to Infusion',
      isChecked: false,
    },
    {
      name: 'Diphenhydramine (Benadryl) 50mg PO, Once, 30 minutes prior to infusion',
      isChecked: false,
    },
  ])
  const [selectedProvider, setSelectedProvider] = useState([])

  const [dosageOptions, setDosageOptions] = useState([
    // {
    //   name: '4mg/kg for initial infusion followed by 2mg/kg IV',
    //   isChecked: false,
    // },
    // {
    //   name: '8mg/kg for initial infusion followed by 6mg/kg IV',
    //   isChecked: false,
    // },
  ])

  const [patientIcdCodes, setPatientIcdCodes] = useState(
    props?.patientIcdCodes || [],
  )

  const [adminAccess, setAdminAccess] = useState(false)
  const history = useHistory()

  let fuse = null

  useEffect(() => {
    console.log('PATIENT INFO : ', props?.patient)
    getUserCarePlan()
    getProviderMedications()
    getProviderConditions()
    getAllProviderData()
    getLabOrders()
    getLabOrdersFromChange()
    getProviderAllergies()
    // setDefaultProvider()
    setHeight(
      `${Math.floor(props?.patient?.height / 12)}.${
        props?.patient?.height % 12
      }`,
    )
    setWeight(props?.patient?.weight)
    if (
      props?.mongoUser?.user_type === 'Administrator' // &&
      // props?.task_description // BUG: I need to see why this is being checked
    )
      setAdminAccess(true)
    if (props?.referral_id) {
      console.log('Referrals ID:', props?.referral_id)
      getReferralsData()
    }
  }, [props?.patientId])

  const setDefaultProvider = () => {
    let temp = [
      {
        label: props?.mongoUser?.first_name + ' ' + props?.mongoUser?.last_name,
        value: props?.mongoUser?.firebase_id,
      },
    ]
    setSelectedProvider(temp)
  }

  // const addMedication = (hit) => {
  //   let temp = [{ id: hit.AILA_ID, name: hit.DrugName }]
  //   setMedication(temp)
  // }

  const updateDosageOptions = (doseName) => {
    console.log('Updating dose options', doseName)
    if (doseName == 'ORENCIA') {
      setDosageOptions([
        {
          name: '500mg',
          isChecked: false,
        },
        {
          name: '750mg',
          isChecked: false,
        },
        {
          name: '1000mg',
          isChecked: false,
        },
        {
          name: '10mg/kg (maximum of 1,000mg)',
          isChecked: false,
        },
        {
          name: '50mg SC',
          isChecked: false,
        },
        {
          name: '87.5mg SC',
          isChecked: false,
        },
        {
          name: '125mg SC SC',
          isChecked: false,
        },
      ])
    } else if (doseName == 'RITUXAN') {
      setDosageOptions([
        {
          name: '100mg',
          isChecked: false,
        },
        {
          name: '500mg',
          isChecked: false,
        },
        {
          name: '1000mg',
          isChecked: false,
        },
      ])
    } else if (doseName == 'HERCEPTIN') {
      setDosageOptions([
        {
          name: '4mg/kg for initial infusion followed by 2mg/kg IV',
          isChecked: false,
        },
        {
          name: '8mg/kg for initial infusion followed by 6mg/kg IV',
          isChecked: false,
        },
      ])
    } else {
      console.log('JKSKDJF:SF', doseName)
    }
  }

  const getReferralsData = () => {
    let config = {
      method: 'get',
      url:
        Config.BACKEND_URL +
        `referrals/patient?referral_id=${props?.referral_id}`,
      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
    }

    axios(config)
      .then(({ data }) => {
        console.log('Got referral', data)
        setSaveReferralData(data)
        setReferralType({
          label: data?.referral_type,
          value: data?.referral_type,
        })
        if (allProviderList.length) {
          console.log('XXXX->>>>>>', data?.doctor_id)
          setSelectedProvider({
            provider: allProviderList.find(
              (x) => x.firebase_id === data?.doctor_id,
            ),
          })
        }
        setPriority(data?.priority)
        setSendMeds(data?.selected_meds)
        setSendAllergies(data?.selected_allergies)
        setSendConditions(data?.selected_conditions)
        setSendFamilyHistory(data?.selected_family_history)
        setSendLabs(data?.selected_Labs)
        setIcdCodes([{ code: data?.icd_codes, display: data?.icd_codes }])
        setReasonIcd(data?.reason)
        setNotes(data?.notes)
      })
      .catch((err) => {
        console.log('Error saving referral to backend', err)
        // toast.error('Could not save referral')
      })
  }

  // BUG: With new implementation there will be a bug here
  // props.referral_id is not being passed in
  const updateReferralinMongo = () => {
    if (!props?.referral_id) {
      return
    }
    let data = {
      // ...saveReferralData,
      referring_to: referringTo,
      phone: phoneNumber,
      fax_number: faxNumber,
      referral_sent: true,
    }
    let config = {
      method: 'put',
      url:
        Config.BACKEND_URL +
        `referrals/patient?referral_id=${props?.referral_id}`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
      data: data,
    }

    axios(config)
      .then(({ data }) => {
        console.log('Updated referral in backend')
        history.push('/provider/home')
        setReferralType(null)
        setReferringTo('')
        setSpeciality(null)
        setPhoneNumber('')
        setFaxNumber('1')
        setDateInfusion(null)
        setMedication([])
        setMedicationQuery('')
        setDosage('')
        setFrequency('')
        setOtherMeds('')
        setAccessType(null)
        setReasonIcd('')
        setNotes('')
        setShowNext(false)
        setPdfData('')
        setSaveReferralData(null)
        setIcdCodes([])
      })
      .catch((err) => {
        console.log('Error updating referral to backend', err)
      })
  }

  const saveReferralstoMongo = (saveReferralData) => {
    console.log(saveReferralData)
    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `referrals/patient`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
      data: saveReferralData,
    }

    axios(config)
      .then(({ data }) => {
        console.log('1.Saved referral in backend')
        toast.success('Referral Saved')
        setReferralType(null)
        setReferringTo('')
        setSpeciality(null)
        setPhoneNumber('')
        setFaxNumber('1')
        setDateInfusion(null)
        setMedication([])
        setMedicationQuery('')
        setDosage('')
        setFrequency('')
        setOtherMeds('')
        setAccessType(null)
        setReasonIcd('')
        setNotes('')
        setShowNext(false)
        setPdfData('')
        setSaveReferralData(null)
        setIcdCodes([])
      })
      .catch((err) => {
        console.log('Error saving referral to backend', err)
        // toast.error('Could not save referral')
      })
  }

  const saveReferralToMongo = (saveReferralData) => {
    console.log(saveReferralData)
    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `referrals/patient`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
      data: saveReferralData,
    }

    axios(config)
      .then(({ data }) => {
        console.log('Saved referral in backend')
        toast.success('Referral Placed')
      })
      .catch((err) => {
        console.log('Error saving referral to backend', err)
        // toast.error('Could not save referral')
      })
  }

  const resetDataFields = () => {
    setReferralType(null)
    setReferringTo('')
    setSpeciality(null)
    setPhoneNumber('')
    setFaxNumber('1')
    setDateInfusion(null)
    setMedication([])
    setMedicationQuery('')
    setDosage('')
    setFrequency('')
    setOtherMeds('')
    setAccessType(null)
    setReasonIcd('')
    setNotes('')
    setShowNext(false)
    setPdfData('')
    setSaveReferralData(null)
    setIcdCodes([])
  }

  const onAdminSave = () => {
    console.log('saving referral')
    setShowReferringToModal(true)
  }

  const onSendPressed = () => {
    console.log('sending fax')
    setShowModal(false)

    let doctor_details = saveReferralData?._id
      ? saveReferralData?.provider_name +
        ' ' +
        saveReferralData?.provider_specialty
      : selectedProvider?.provider?.first_name +
        ' ' +
        selectedProvider?.provider?.last_name +
        `${
          selectedProvider?.provider?.speciality
            ? ', ' + selectedProvider?.provider?.speciality
            : ''
        }`

    let result = {
      patient_id: props?.patientId,
      sSenderEmail: props?.mongoUser?.email,
      sToFaxNumber: faxNumber,
      sFileName_1: 'PatientReferral.pdf',
      htmlContent: pdfData,
      sCoverPage: 'Standard',
      sCPFromName: doctor_details,
      sCPToName: referringTo,
      sCPOrganization: 'Aila Health',
      sCPSubject: saveReferralData?._id
        ? saveReferralData?.referral_type
        : referralType?.label,
    }

    let config = {
      method: 'post',
      url: Config.CHANGE_BACKEND_URL + `srfax/referral-outbox`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.providerId,
      },
      data: result,
    }
    axios(config)
      .then(({ data }) => {
        console.log('SR-FAX SENT : ', data)
        if (data.Status !== 'Failed') {
          toast.success('Fax queued successfully')
          //SAVE TO MONGODB
          updateReferralinMongo()
        } else {
          toast.warning(`Could not send fax : ${data.Result}`)
        }
        resetDataFields()
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not send Fax')
      })
  }

  const getProviderAllergies = async () => {
    try {
      const getAllergy = async () => {
        let url =
          Config.BACKEND_URL +
          'fhir/AllergyIntolerance?patient=' +
          props?.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: props?.patientId,
            Authorization: 'JWT ' + props?.token,
          },
        })
      }
      let response = await getAllergy()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let allergies = response?.data || []
        setProviderAllergies(allergies)
      } else {
        setProviderAllergies([])
      }
    } catch (err) {
      console.log('error when getting allergies data', err)
      // toast.error('Something went wrong when getting allergies')
    }
  }

  const getLabOrders = () => {
    setLoading(true)
    const url = `${Config.BACKEND_URL}patients/labs/orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${props?.token}`,
        x_firebase_id: props?.patientId,
      },
    })
      .then(({ data }) => {
        // console.log('data', data)
        setLoading(false)
        setLabOrdersFromBackend(data)
      })
      .catch((err) => {
        setLoading(false)
        console.log('error when getting lab orders', err)
        // toast.error('Something went wrong')
      })
  }

  const getLabsInfo = (content, map) => {
    map[content.order] = {
      status: content.order_status,
      providerName: `${content.ref_cg_fname} ${content.ref_cg_lname}`,
    }
  }

  const getLabOrdersFromChange = () => {
    const url = `${Config.CHANGE_BACKEND_URL}change/patients/orders`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${props?.token}`,
        person: props?.patient?.change?.person,
      },
    })
      .then(({ data }) => {
        // console.log('change data', data)
        if (data?.RESULT?.OBJECT) {
          let orders = []
          let content = data.RESULT.OBJECT
          let ordersMap = {}
          if (Array.isArray(content)) {
            content.forEach((x) => getLabsInfo(x, ordersMap))
          } else {
            getLabsInfo(content, ordersMap)
          }
          setLabOrdersFromChange(ordersMap)
        } else {
          toast.error('Could not get lab orders from change')
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
        // toast.error('Something went wrong')
      })
  }

  const getProviderConditions = async () => {
    try {
      const getCondition = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/Condition?patient=' + props?.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: props?.patientId,
            Authorization: 'JWT ' + props.token,
          },
        })
      }
      let response = await getCondition()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let conditions = response.data || []
        setProviderConds(conditions)
      } else {
        setProviderConds([])
      }
    } catch (err) {
      console.log('error when getting particle conditions data', err)
      // toast.error('Something went wrong when getting external conditions')
    }
  }
  const getProviderMedications = async () => {
    try {
      const getMeds = async () => {
        let url =
          Config.BACKEND_URL +
          'fhir/MedicationRequest?patient=' +
          props?.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: props?.patientId,
            Authorization: 'JWT ' + props.token,
          },
        })
      }
      let response = await getMeds()
      if (response?.data) {
        // console.log('medications::: ', response.data)
        //if there is something in data, then the particle records for the patient has been pulled at least once
        // let meds1 = response.data.active_medications_provider || []
        // let meds2 = response.data.inactive_medications_provider || []
        // let meds = meds1.concat(meds2)
        let meds = response.data
        setProviderMeds(meds)
      } else {
        setProviderMeds([])
      }
    } catch (err) {
      console.log('error when getting meds data', err)
      // toast.error('Something went wrong when getting meds')
    }
  }

  const getUserCarePlan = () => {
    let url = Config.BACKEND_URL + 'careplan'
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + props.token,
        patient_id: props?.patientId,
        x_doctor_id: props?.providerId,
      },
    })
      .then(({ data }) => {
        if (data) {
          // console.log('PATIENT CAREPLAN : ', data)
          let temp = data?.filter((obj) => obj?.note_status !== 'archive') || []
          setSelectedNote(temp.map((obj) => ({ ...obj, isChecked: false })))
        }
      })
      .catch((err) => {
        console.log('error when getting user care plan', err)
      })
  }

  const getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: props.providerId,
        Authorization: 'JWT ' + props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        data && setAllProviderList(data)
        let providerOptionList = []
        let allProviderData = data
          .filter((doc) => doc.user_type !== 'Administrator')
          .reduce((data, doc) => {
            data[doc.user_type || 'Other'] =
              data[doc.user_type || 'Other'] || []
            data[doc.user_type || 'Other'].push({
              label:
                doc?.first_name +
                ' ' +
                doc?.last_name +
                (doc?.speciality ? ` (${doc?.speciality})` : ''),
              value: doc?.firebase_id,
              provider: doc,
            })
            return data
          }, Object.create(null))
        Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
          providerOptionList.push({
            label: userType,
            options: doctorArray,
          })
        })
        setProviderOptionList(providerOptionList)
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  // const onAdminSubmitPressed = () => {
  //   console.log('Admin submit pressed!')
  //   // return
  //   let dateTime = moment().format('MM/DD/YYYY hh:mm A')
  //   let data = {
  //     ...saveReferralData,
  //     referring_to: referringTo,
  //     // phone: phoneNumber,
  //     // fax_number: faxNumber,
  //     date_time: dateTime,
  //   }

  //   // if (
  //   //   adminAccess
  //   //   // &&
  //   //   // (!props?.patientId || !faxNumber || !phoneNumber || !referringTo)
  //   // ) {
  //   //   console.log('There is an error in the src code')
  //   //   toast.error('Please fill all the fields')
  //   //   return
  //   // }

  //   if (!saveReferralData?._id) {
  //     toast.warning('No referral found')
  //   }

  //   console.log('WTF?')
  //   if (referralType?.value === 'physician') {
  //     Axios(htmlModule1).then((res) => {
  //       let templateHtml = Handlebars.compile(res.data.toString())
  //       let bodyHtml = templateHtml(data)
  //       if (!adminAccess) {
  //         saveReferralstoMongo(data)
  //       } else {
  //         setPdfData(bodyHtml)
  //         setShowModal(true)
  //       }
  //     })
  //   } else if (referralType?.value === 'medical') {
  //     Axios(htmlModule3).then((res) => {
  //       let templateHtml = Handlebars.compile(res.data.toString())
  //       let bodyHtml = templateHtml(data)
  //       if (!adminAccess) {
  //         saveReferralstoMongo(data)
  //       } else {
  //         setPdfData(bodyHtml)
  //         setShowModal(true)
  //       }
  //     })
  //   } else if (referralType?.value === 'priorAuth') {
  //     Axios(htmlModule3).then((res) => {
  //       let templateHtml = Handlebars.compile(res.data.toString())
  //       let bodyHtml = templateHtml(data)
  //       if (!adminAccess) {
  //         saveReferralstoMongo(data)
  //       } else {
  //         setPdfData(bodyHtml)
  //         setShowModal(true)
  //       }
  //     })
  //   } else if (referralType?.value === 'imaging') {
  //     console.log('Imaging admin')
  //     Axios(htmlModule3).then((res) => {
  //       let templateHtml = Handlebars.compile(res.data.toString())
  //       let bodyHtml = templateHtml(data)
  //       if (!adminAccess) {
  //         saveReferralstoMongo(data)
  //       } else {
  //         console.log('Imaging admin show modal')
  //         setPdfData(bodyHtml)
  //         setShowModal(true)
  //       }
  //     })
  //   } else if (referralType?.value === 'speciality') {
  //     console.log('I think is for infusion')
  //     // I think this is for infusion
  //     Axios(htmlModule2).then((res) => {
  //       let templateHtml = Handlebars.compile(res.data.toString())
  //       let bodyHtml = templateHtml(data)
  //       console.log('compiled')
  //       if (!adminAccess) {
  //         saveReferralstoMongo(data)
  //       } else {
  //         setPdfData(bodyHtml)
  //         setShowModal(true)
  //       }
  //     })
  //   }
  // }

  const onSubmitPressed = () => {
    let data

    console.log('submit pressed')

    // if (
    //   adminAccess &&
    //   (!props?.patientId ||
    //     !referralType ||
    //     !faxNumber ||
    //     !phoneNumber ||
    //     !priority ||
    //     !referringTo ||
    //     notes.length === 0)
    // ) {
    //   toast.error('Please refer to Task List')
    //   return
    // }

    let primary_insurance = Array.isArray(props?.patient?.insurance)
      ? props?.patient?.insurance?.find((ins) => ins.preference === 1) ||
        props?.patient?.insurance[0]
      : props?.patient?.insurance || null

    let selected_note = saveReferralData?._id
      ? saveReferralData?.selected_note
      : true
    let note_data = []

    let medication_data = ''

    let labs_data = ''

    let allergies_data = ''

    let family_history_data = ''

    let condiions_data = ''
    let tempSelectedNotes = selectedNote.filter(
      (item) => item.isChecked === true,
    )

    if (!saveReferralData?._id && tempSelectedNotes.length) {
      tempSelectedNotes.forEach((selectedNote, index) => {
        let icd_10_data = ''
        let cpt_data = ''

        let doctorInfo =
          allProviderList.find(
            (item) => item.firebase_id === selectedNote?.doctor?.firebase_id,
          ) || null
        let careplan_note = ''
        let assessment_note = ''
        if (selectedNote?.careplan?.length) {
          let tempArray = selectedNote?.careplan || []
          tempArray.forEach((icd, index) => {
            icd_10_data = icd_10_data.concat(
              index + 1,
              '. ',
              icd?.icd_10?.icd10_code ? `${icd?.icd_10?.icd10_code} : ` : '',
              icd?.icd_10?.title ? `${icd?.icd_10?.title} : ` : '',
              icd?.assessment,
              // selectedNote?.careplan?.notes[index],
              ' : ',
              // selectedNote?.careplan?.text[index],
              icd?.note,
              index !== tempArray?.length && '\n',
              // '\n',
            )
            assessment_note = assessment_note.concat(
              icd?.assessment,
              index !== tempArray?.length && '\n',
            )
            careplan_note = careplan_note.concat(
              icd?.note,
              index !== tempArray?.length && '\n',
            )
          })
        }
        if (selectedNote?.careplan?.assessment_note)
          assessment_note = selectedNote?.careplan?.assessment_note
        if (selectedNote?.careplan?.careplan_note)
          careplan_note = selectedNote?.careplan?.careplan_note
        if (selectedNote?.cpt?.length) {
          let tempArray = selectedNote?.cpt || []
          tempArray.forEach(function (cpt, index) {
            cpt_data = cpt_data.concat(
              index + 1,
              '. ',
              cpt.cpt_code,
              ' : ',
              cpt.title,
              index !== tempArray?.length && '\n',
              // '\n',
            )
          })
        }

        note_data.push({
          index: index + 1,
          chief_concern: selectedNote?.chief_concern,
          provider_chief_concern: selectedNote?.provider_chief_concern || '',
          present_illness: selectedNote?.present_illness,
          provider_illness: selectedNote?.provider_illness || '',
          physical_exam: selectedNote?.physical_exam,
          icd_10: icd_10_data,
          cpt: cpt_data,
          doc_name: doctorInfo?.first_name + doctorInfo?.last_name,
          doc_type: doctorInfo?.speciality,
          date_note: selectedNote?.appointment_date
            ? moment(selectedNote?.appointment_date).format('MMM Do YYYY')
            : moment(selectedNote?.created_at, 'YYYY-MM-DD').format(
                'MMM Do YYYY',
              ),
          note_sign: doctorInfo?.sign_image,
        })
      })
    } else if (saveReferralData?._id) {
      note_data = saveReferralData?.note_data
    } else {
      selected_note = false
    }

    if (sendMeds) {
      let array1 =
        (patientMedicationsData &&
          patientMedicationsData['active_medications']) ||
        []
      let array2 =
        (providerMeds && providerMeds['active_medications_provider']) || []

      let array3 =
        (patientMedicationsData &&
          patientMedicationsData['inactive_medications']) ||
        []
      let array4 =
        (providerMeds && providerMeds['inactive_medications_provider']) || []

      let finalArray = array4.concat(array3.concat(array2.concat(array1)))
      // medication_data = finalArray

      finalArray.forEach((x, index) => {
        medication_data = medication_data.concat(
          index + 1,
          '. ',
          x.dose?.quantity
            ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit}) : `
            : `${x.name} : `,

          x.frequency?.quantity
            ? `${x.frequency.quantity} / ${x.frequency.recurring} : `
            : '- : ',
          moment(x.start).format('MMM Do YYYY') + ' : ',
          x.end ? 'Inactive' : 'Active',
          index !== finalArray?.length && '\n',
        )
      })

      // finalArray.forEach((item) => {
      //   if(item.end) {
      //     medication_data.push({...item, status_info : 'Inactive'})
      //   }
      //   else{
      //     medication_data.push({})
      //   }
      // })
    }

    if (sendFamilyHistory) {
      let familyScreening =
        patientCore && patientCore?.screening_family
          ? patientCore?.screening_family?.relations
          : []

      familyScreening.forEach((each, index) => {
        let conditions = []
        if (each.conditions)
          conditions = each.conditions.map(
            (x, index) =>
              `${x} ${index === each.conditions.length - 1 ? '' : ', '}`,
          )
        family_history_data = family_history_data.concat(
          index + 1,
          '. ',
          each.relationship,
          ' : ',
          conditions,
          index !== familyScreening?.length && '\n',
        )
      })
    }

    if (sendConditions) {
      let conditions = patientConditionsData || []
      // filter out user entered data
      conditions = conditions.filter(
        (userCondn) =>
          !providerConds?.some(
            (prodCondn) =>
              prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
          ),
      )
      let newConditions = conditions?.concat(providerConds)

      newConditions.forEach((condn, index) => {
        condiions_data = condiions_data.concat(
          index + 1,
          '. ',
          condn.symptom
            ? condn.symptom
            : condn?.code?.coding?.[0]?.display?.replace(/"/g, ''),
          condn?.code?.coding?.[0]?.display && !condn?.userEntered
            ? ' (Doctor Reported)\n'
            : '',

          index !== newConditions?.length && '\n',
        )
      })
    }
    if (sendAllergies) {
      let allergies =
        patientCore && patientCore.allergies ? patientCore?.allergies : []
      let newAllergies = providerAllergies?.concat(allergies)
      newAllergies.forEach((allergy, index) => {
        allergies_data = allergies_data.concat(
          index + 1,
          '. ',
          allergy?.code?.coding?.[0]?.display ||
            (allergy?.type &&
              (allergy?.name?.length
                ? allergy?.name?.join()
                : allergy?.type)) ||
            allergy,
          index !== newAllergies?.length && '\n',
        )
      })
    }

    if (sendLabs) {
      let labs = labOrdersFromBackend
      labs.forEach((x, index) => {
        let tests = x.tests || []
        let testElements = tests.map((test) => test.name)

        labs_data = labs_data.concat(
          index + 1,
          '. ',

          testElements,
          ' : ',
          x.lab?.label,
          '\n',
          x.paid ? 'Paid' : 'Unpaid',
          ' : ',
          x.payment_type,
          '\n',
          'Created - ',
          moment(x.created_at).format('MM/DD/YYYY'),
          ' : ',
          'Collected at - ',
          moment(x.collection_date_time).format('MM/DD/YYYY h:mmA'),
          '\n',
          getLabStatusText(x),

          index !== labs?.length && '\n',
        )
      })
    }

    if (referralType.value === 'physician') {
      console.log("PHYSICIAN: ",props.mongoUser)
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        specialty:
          speciality && speciality.value === 'Others'
            ? extraSpecialty
            : speciality.label,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,

        //REFERRAL TYPE
        referral_type: referralType.value,
        order_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        if_physician_referral : 'X',
        if_durable_medical_referral : ' ',
        if_infusion_referral : ' ',
        if_lab_referral : ' ',
        if_imaging_referral : ' ',

        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob, 'DD/MM/YYYY').format(
          'MM/DD/YYYY',
        ),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name +
          ' ' +
          selectedProvider?.provider?.last_name +
          ', ' +
          selectedProvider?.provider?.user_type,
        provider_specialty: selectedProvider?.provider?.speciality,
        provider_speciality: selectedProvider?.provider?.speciality,
        provider_npi: selectedProvider?.provider?.npi?.number,

        // Some missing fields
        // body_site: bodySite?.id + ':' + bodySite?.name,
        // if_physician_referral: ' ',
        // if_durable_medical_referral: ' ',
        // if_infusion_referral: ' ',
        // if_lab_referral: ' ',
        // if_imaging_referral: 'X',
        order_date: moment().format('MM/DD/YYYY'),
        // order_type: imagingStudy?.value,
        body_sites: bodySite?.map((x) => x?.id)?.join(', '),
        contrast: contrast?.value,
        literality: literality?.value,
        imaging_study: otherImagingStudy || imagingStudy?.value,
        imaging_med: imagingMed,
        fluoroscopy: fluoroscopy,
        // if_oral: ifOralSedative,
        // if_iv: ifIVSedative,
        // if_anesthesia: ifGeneralAnesthesia,
        // if_perform_as_ordered: ifPerformAsOrdered,
        // if_ok_to_be_alered: ifOkToBeAltered, // RIP SPELLING
        nuclear_medicine: nuclearMedicine?.value || 'N/A',
        fluoroscopy: fluoroscopy?.value || 'N/A',
        if_claustrophobic: claustrophobic ? 'Yes' : 'No',
        conditions_data: patientConditionsData,
        if_oral: ifOralSedative ? 'X' : ' ',
        if_iv: ifIVSedative ? 'X' : ' ',
        if_anesthesia: ifGeneralAnesthesia ? 'X' : ' ',
        if_perform_as_ordered: ifPerformAsOrdered ? 'X' : ' ',
        if_ok_to_be_alered: ifOkToBeAltered ? 'X' : ' ',
        last_exam_date: 'N/A',
        icd10s: icdCodes?.map((x) => x?.code + ':' + x?.display)?.join(', '),

        practice_name: 'Aila Health',
        practice_address:
          '1999 Harrison Street, 18th Floor\nOakland, California 94612',
        practice_phone: '415-704-9601',
        practice_fax: '7205009759',
        practice_email: 'billing@ailahealth.com',

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
        //PRIOR AUTH
        prior_auth: referralType?.value === 'priorAuth' ? true : false,
        date_time: moment().format('MM/DD/YYYY hh:mm A'),
        appointment_event_id: appointmentEventId,
      }
      setSaveReferralData(data)

      setPdfTemplate(htmlModule1)

      if (!adminAccess) {
        saveReferralstoMongo(data)
      } else {
        onAdminSave()
      }

      // Axios(htmlModule1).then((res) => {
      //   let templateHtml = Handlebars.compile(res.data.toString())
      //   let bodyHtml = templateHtml(data)
      //   if (!adminAccess) {
      //     saveReferralstoMongo(data)
      //   } else {
      //     setPdfData(bodyHtml)
      //     setShowModal(true)
      //   }
      // })
    } else if (referralType.value === 'medical') {
      console.log("MEDICAL")
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob, 'DD/MM/YYYY').format(
          'MM/DD/YYYY',
        ),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name +
          ' ' +
          selectedProvider?.provider?.last_name +
          ', ' +
          selectedProvider?.provider?.user_type,
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
        date_time: moment().format('MM/DD/YYYY hh:mm A'),
      }
      setSaveReferralData(data)

      setPdfTemplate(htmlModule3)

      if (!adminAccess) {
        saveReferralstoMongo(data)
      } else {
        onAdminSave()
      }

      // Axios(htmlModule3).then((res) => {
      //   let templateHtml = Handlebars.compile(res.data.toString())
      //   let bodyHtml = templateHtml(data)
      //   if (!adminAccess) {
      //     saveReferralstoMongo(data)
      //   } else {
      //     setPdfData(bodyHtml)
      //     setShowModal(true)
      //   }
      // })
    } else if (referralType.value === 'priorAuth') {
      console.log("PRIOR AUTH")
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob, 'DD/MM/YYYY').format(
          'MM/DD/YYYY',
        ),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name +
          ' ' +
          selectedProvider?.provider?.last_name +
          ', ' +
          selectedProvider?.provider?.user_type,
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
        //PRIOR AUTH
        prior_auth: referralType?.value === 'priorAuth' ? true : false,
        date_time: moment().format('MM/DD/YYYY hh:mm A'),
      }
      setSaveReferralData(data)

      setPdfTemplate(htmlModule3)

      if (!adminAccess) {
        saveReferralstoMongo(data)
      } else {
        onAdminSave()
      }

      // Axios(htmlModule3).then((res) => {
      //   let templateHtml = Handlebars.compile(res.data.toString())
      //   let bodyHtml = templateHtml(data)
      //   if (!adminAccess) {
      //     saveReferralstoMongo(data)
      //   } else {
      //     setPdfData(bodyHtml)
      //     setShowModal(true)
      //   }
      // })
    } else if (referralType.value === 'imaging') {
      console.log('This is referral type imaging1:', referralType.value)
      console.log(
        bodySite,
        contrast,
        literality,
        imagingStudy,
        claustrophobic,
        imagingMed,
      )
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob, 'DD/MM/YYYY').format(
          'MM/DD/YYYY',
        ),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name +
          ' ' +
          selectedProvider?.provider?.last_name +
          ', ' +
          selectedProvider?.provider?.user_type,
        provider_specialty: selectedProvider?.provider?.speciality,
        provider_speciality: selectedProvider?.provider?.speciality,
        provider_npi: selectedProvider?.provider?.npi?.number,

        // Some missing fields
        // body_site: bodySite?.id + ':' + bodySite?.name,
        if_physician_referral: ' ',
        if_durable_medical_referral: ' ',
        if_infusion_referral: ' ',
        if_lab_referral: ' ',
        if_imaging_referral: 'X',
        order_date: moment().format('MM/DD/YYYY'),
        order_type: imagingStudy?.value,
        body_sites: bodySite?.map((x) => x?.id)?.join(', '),
        contrast: contrast?.value,
        literality: literality?.value,
        imaging_study: otherImagingStudy || imagingStudy?.value,
        imaging_med: imagingMed,
        fluoroscopy: fluoroscopy,
        if_oral: ifOralSedative,
        if_iv: ifIVSedative,
        if_anesthesia: ifGeneralAnesthesia,
        if_perform_as_ordered: ifPerformAsOrdered,
        if_ok_to_be_alered: ifOkToBeAltered, // RIP SPELLING
        nuclear_medicine: nuclearMedicine?.value || 'N/A',
        fluoroscopy: fluoroscopy?.value || 'N/A',
        if_claustrophobic: claustrophobic ? 'Yes' : 'No',
        conditions_data: patientConditionsData,
        if_oral: ifOralSedative ? 'X' : ' ',
        if_iv: ifIVSedative ? 'X' : ' ',
        if_anesthesia: ifGeneralAnesthesia ? 'X' : ' ',
        if_perform_as_ordered: ifPerformAsOrdered ? 'X' : ' ',
        if_ok_to_be_alered: ifOkToBeAltered ? 'X' : ' ',
        last_exam_date: 'N/A',
        icd10s: icdCodes?.map((x) => x?.code + ':' + x?.display)?.join(', '),

        practice_name: 'Aila Health',
        practice_address:
          '1999 Harrison Street, 18th Floor\nOakland, California 94612',
        practice_phone: '415-704-9601',
        practice_fax: '7205009759',
        practice_email: 'billing@ailahealth.com',

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        conditions_data: condiions_data,
        medications_data: medication_data,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
        //PRIOR AUTH
        prior_auth: referralType?.value === 'priorAuth' ? true : false,
        date_time: moment().format('MM/DD/YYYY hh:mm A'),
        appointment_event_id: appointmentEventId,
      }
      setSaveReferralData(data)

      setPdfTemplate(htmlModule3)

      if (!adminAccess) {
        saveReferralstoMongo(data)
      } else {
        onAdminSave()
      }

      // Axios(htmlModule3).then((res) => {
      //   let templateHtml = Handlebars.compile(res.data.toString())
      //   let bodyHtml = templateHtml(data)
      //   if (!adminAccess) {
      //     saveReferralstoMongo(data)
      //   } else {
      // setPdfData(bodyHtml)
      // setPdfTemplate(templateHtml)
      // onAdminSave()
      //   }
      // })
    } else if (referralType.value === 'speciality') {
      console.log('This is referral type speciality1:', referralType.value)
      data = {
        referring_to: referringTo,
        phone: phoneNumber,
        fax_number: faxNumber,
        priority: priority && priority.label,
        date_infusion: moment(dateInfusion).format('MM/DD/YYYY'),
        order_type: orderType && orderType.label,
        medication: medication && medication[0]?.DrugName,
        dosage: dosage + ' ' + units,
        frequency:
          'Every' +
          treatments?.everyValue +
          ' ' +
          treatments?.everyUnit?.value +
          ' ' +
          'For' +
          treatments?.forValue +
          treatments?.forUnit?.value +
          ' ' +
          'For' +
          treatments?.number +
          'Treatments',
        access_type: accessType && accessType.label,
        pre_medications: preMedications,
        tx_auth: txAuth,
        tx_auth_date: dateAuthInfusion,
        diagnoses: diagnoses,
        daw: daw,
        treatments: treatments,
        notes: notes,
        icd_codes: icdCodes[0]?.code,
        reason: reasonIcd,
        //REFERRAL TYPE
        referral_type: referralType.value,
        doctor_id: selectedProvider?.provider?.firebase_id || props?.providerId,
        //PATIENT INFORMATION
        patient_id: props?.patientId,
        date: moment(new Date()).format('MM/DD/YYYY'),
        patient_name: props.patient?.label,
        patient_dob: moment(props.patient?.dob, 'DD/MM/YYYY').format(
          'MM/DD/YYYY',
        ),
        patient_gender: props.patient?.gender,
        patient_address: props.patient?.streetAddress,
        patient_phone: props.patient?.phoneNumber,
        patient_email: props.patient?.email,
        patient_zipcode: props.patient?.zipcode,
        insurance_provider:
          primary_insurance?.insurance_company?.insurance_name || '',
        insurance_plan: primary_insurance?.plan_type || '',
        insurance_member: primary_insurance?.member_name || '',
        insurance_subscriber: primary_insurance?.subscriber_id || '',
        insurance_group: primary_insurance?.group_number || '',
        height: height || '',
        weight: weight || '',

        //PROVIDER INFORMATION
        provider_signature: selectedProvider?.provider?.sign_image,
        provider_name:
          selectedProvider?.provider?.first_name +
          ' ' +
          selectedProvider?.provider?.last_name +
          ', ' +
          selectedProvider?.provider?.user_type,
        provider_specialty: selectedProvider?.provider?.speciality,

        //NOTE DATA
        note_data: note_data,
        medication_data: medication_data,
        labs_data: labs_data,
        allergies_data: allergies_data,
        condiions_data: condiions_data,
        family_history_data: family_history_data,
        selected_family_history: sendFamilyHistory,
        selected_conditions: sendConditions,
        selected_note: selected_note,
        selected_meds: sendMeds,
        selected_Labs: sendLabs,
        selected_allergies: sendAllergies,
        date_time: moment().format('MM/DD/YYYY hh:mm A'),
        appointment_event_id: appointmentEventId,
      }
      setSaveReferralData(data)

      setPdfTemplate(htmlModule2)

      if (!adminAccess) {
        saveReferralstoMongo(data)
      } else {
        onAdminSave()
      }

      // Axios(htmlModule2).then((res) => {
      //   let templateHtml = Handlebars.compile(res.data.toString())
      //   let bodyHtml = templateHtml(data)
      //   if (!adminAccess) {
      //     saveReferralstoMongo(data)
      //   } else {
      //     setPdfData(bodyHtml)
      //     setShowModal(true)
      //   }
      // })
    }
  }

  const handleHitClick = (hit) => {
    if (handleChecked(hit.DrugName)) {
      let arr = medication.filter((data) => data.DrugName !== hit.DrugName)
      setMedication(arr)
    } else {
      setMedication([
        // ...medication,
        { DrugName: hit.DrugName, AILA_ID: hit.AILA_ID },
      ])
    }
  }

  const removeBodySite = (index) => {
    let temp = [...bodySite]
    temp.splice(index, 1)
    setBodySite(temp)
  }

  // to handle the list of selected conditions from algolia
  const handleChecked = (DrugName) => {
    return medication.some((data) => data.DrugName === DrugName)
  }

  // const Hit = (props) => {
  //   let { hit } = props
  //   let checked = handleChecked(hit.DrugName)
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={() => {
  //         handleHitClick(hit)
  //       }}
  //     >
  //       {hit.AILA_ID} : {hit.DrugName}
  //     </div>
  //   )
  // }

  // const BodyHit = (props) => {
  //   let { hit } = props
  //   const handleClick = () => {
  //     setBodysiteQuery('')
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setBodySite({ id: hit.Code, name: hit.Display })
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit.Code} : {hit.Display}
  //     </div>
  //   )
  // }

  // const Hit = (props, type) => {
  //   let { hit } = props
  //   // let checked = handleChecked(q, hit.symptom)
  //   const handleClick = () => addMedication(hit)

  //   return (
  //     <>
  //       <button
  //         onClick={(e) => handleClick(e)}
  //         style={{ border: 'none', outline: 'none', display: 'block' }}
  //       >
  //         {hit.code}: {hit.display}
  //       </button>
  //       <hr />
  //     </>
  //   )
  // }

  const addIcdCode = (hit) => {
    // let temp = [...icdCodes, { code: hit.code, display: hit.display }]
    let temp = [{ code: hit.code, display: hit.display }]

    setIcdCodes(temp)
  }

  const HitICD = (props, type) => {
    let { hit } = props
    // let checked = handleChecked(q, hit.symptom)
    const handleClick = () => addIcdCode(hit)

    return (
      <>
        <button
          onClick={(e) => handleClick(e)}
          style={{ border: 'none', outline: 'none', display: 'block' }}
        >
          {hit.code}: {hit.display}
        </button>
        <hr />
      </>
    )
  }

  // Handle multiple option selection checkbox
  const handleMultipleOptions = (value) => {
    let tempq = [...imagingMed]
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })
    setImagingMed(tempq)
  }

  const renderImagingFields = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderBottomWidth: 1,
          borderBottomColor: Constants.primaryTheme,
          borderBottomStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Order Type</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'CT Scan', value: 'CT Scan' },
                { label: 'CTA', value: 'CTA' },
                { label: 'DEXA', value: 'DEXA' },
                { label: 'Fluoroscopy', value: 'Fluoroscopy' },
                { label: 'MRA', value: 'MRA' },
                { label: 'MRI', value: 'MRI' },
                { label: 'Mammography', value: 'Mammography' },
                { label: 'Ultrasound', value: 'Ultrasound' },
                { label: 'Nuclear Medicine', value: 'Nuclear Medicine' },
                { label: 'X-ray', value: 'X-ray' },
                { label: 'PET Scan', value: 'PET Scan' },
                { label: 'Other', value: 'Other' },
              ]}
              value={imagingStudy}
              autoBlur={true}
              onChange={(val) => setImagingStudy(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>If Other Order Type</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'If Other Order Type'}
              className={Styles.textInput}
              type="text"
              value={otherImagingStudy}
              onChange={(e) => {
                let text = e.target.value
                setOtherImagingStudy(text)
              }}
            />
          </div>
        </div>

        {imagingStudy?.value === 'Fluoroscopy' && (
          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Fluoroscopy</p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                options={[
                  { label: 'Arthrography', value: 'Arthrography' },
                  { label: 'IVP', value: 'IVP' },
                  { label: 'VCUG', value: 'VCUG' },
                  { label: 'Esophagram', value: 'Esophagram' },
                  {
                    label: 'Hysterosalpingogram (HSG)',
                    value: 'Hysterosalpingogram (HSG)',
                  },
                  { label: 'UGI', value: 'UGI' },
                  { label: 'UGI w/SBFT', value: 'UGI w/SBFT' },
                  { label: 'Small bowel', value: 'Small bowel' },
                  { label: 'Barium enema', value: 'Barium enema' },
                  {
                    label: 'Myelogram - with CT',
                    value: 'Myelogram - with CT',
                  },
                  {
                    label: 'Myelogram - without CT',
                    value: 'Myelogram - without CT',
                  },
                  {
                    label: 'Discogram, Lumbar Spine - with CT',
                    value: 'Discogram, Lumbar Spine - with CT',
                  },
                  {
                    label: 'Discogram, Lumbar Spine - with CT',
                    value: 'Discogram, Lumbar Spine - with CT',
                  },
                ]}
                value={fluoroscopy}
                autoBlur={true}
                onChange={(val) => setFluoroscopy(val)}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>
        )}

        {imagingStudy?.value === 'Nuclear Medicine' && (
          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Nuclear Medicine</p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                options={[
                  { label: 'Bone - Whole Body', value: 'Bone - Whole Body' },
                  { label: 'Bone  - Limited', value: 'Bone  - Limited' },
                  { label: 'Bone - 3-phase', value: 'Bone - 3-phase' },
                  { label: 'Bone SPECT', value: 'Bone SPECT' },
                  { label: 'Thyroid Scan', value: 'Thyroid Scan' },
                  {
                    label: 'Thyroid Uptake and Scan',
                    value: 'Thyroid Uptake and Scan',
                  },
                  { label: 'Parathyroid', value: 'Parathyroid' },
                  {
                    label: 'Myocardial Perfusion (heart) - Exercise',
                    value: 'Myocardial Perfusion (heart) - Exercise',
                  },
                  {
                    label: 'Myocardial Perfusion (heart) - Pharmacologic',
                    value: 'Myocardial Perfusion (heart) - Pharmacologic',
                  },
                  {
                    label: 'MUGA (cardiac blood pool)',
                    value: 'MUGA (cardiac blood pool)',
                  },
                  { label: 'LungVQ', value: 'LungVQ' },
                  { label: 'Liver/Spleen', value: 'Liver/Spleen' },
                  {
                    label: 'Gallbladder (HIDA) with CCK',
                    value: 'Gallbladder (HIDA) with CCK',
                  },
                  {
                    label: 'Gallbladder without CCK',
                    value: 'Gallbladder without CCK',
                  },
                  { label: 'GI Bleed', value: 'GI Bleed' },
                  { label: 'Meckels', value: 'Meckels' },
                  { label: 'Renal - Captopril', value: 'Renal - Captopril' },
                  { label: 'Renal - Lasix', value: 'Renal - Lasix' },
                  { label: 'Tumor Localization', value: 'Tumor Localization' },
                  { label: 'Gallium', value: 'Gallium' },
                ]}
                value={nuclearMedicine}
                autoBlur={true}
                onChange={(val) => setNuclearMedicine(val)}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>
        )}

        {/* Old body site search */}
        {/* <SearchProvider config={esConfigBodySites}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        searchAsYouType={true}
                        onSelectAutocomplete={(e) => {
                          setBodySite({ id: e?.code.raw, name: e?.display.raw })
                          setSearchTerm('')
                        }}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search body sites',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                            {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> // End of comment
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider> */}
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Body Sites</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Abdomen', value: 'Abdomen' },
                { label: 'Abdomen - Aorta', value: 'Abdomen - Aorta' },
                { label: 'Abdomen - Renal', value: 'Abdomen - Renal' },
                { label: 'Abdomen - Adrenals', value: 'Abdomen - Adrenals' },
                { label: 'Abdomen - Liver', value: 'Abdomen - Liver' },
                {
                  label: 'Abdomen - Gallbladder',
                  value: 'Abdomen - Gallbladder',
                },
                { label: 'Abdomen - MRCP', value: 'Abdomen - MRCP' },
                { label: 'Ankle', value: 'Ankle' },
                {
                  label: 'Aorta and runoff vessels',
                  value: 'Aorta and runoff vessels',
                },
                {
                  label: 'Aorta/Retroperitoneal',
                  value: 'Aorta/Retroperitoneal',
                },
                { label: 'Bladder', value: 'Bladder' },
                { label: 'Brachial Plexus', value: 'Brachial Plexus' },
                { label: 'Brain', value: 'Brain' },
                {
                  label: 'Brain - With special attention to IAC',
                  value: 'Brain - With special attention to IAC',
                },
                {
                  label: 'Brain- With Special attention to Pituitary',
                  value: 'Brain- With Special attention to Pituitary',
                },
                { label: 'Breast Bilateral', value: 'Breast Bilateral' },
                {
                  label: 'Breast Unilateral - Right',
                  value: 'Breast Unilateral - Right',
                },
                {
                  label: 'Breast Unilateral - Left',
                  value: 'Breast Unilateral - Left',
                },
                { label: 'Carotid Doppler', value: 'Carotid Doppler' },
                { label: 'Chest Thorax', value: 'Chest Thorax' },
                { label: 'Elbow', value: 'Elbow' },
                { label: 'Enterography', value: 'Enterography' },
                { label: 'Extremity - Upper', value: 'Extremity - Upper' },
                { label: 'Extremity - Lower', value: 'Extremity - Lower' },
                { label: 'Femur Thigh', value: 'Femur Thigh' },
                { label: 'Finger', value: 'Finger' },
                { label: 'Forearm', value: 'Forearm' },
                { label: 'Head', value: 'Head' },
                { label: 'Hand', value: 'Hand' },
                { label: 'Hindfoot', value: 'Hindfoot' },
                { label: 'Hip', value: 'Hip' },
                { label: 'Humerus', value: 'Humerus' },
                { label: 'IAC Middle Ear', value: 'IAC Middle Ear' },
                { label: 'Knee', value: 'Knee' },
                {
                  label: 'Lower Extremity Joint',
                  value: 'Lower Extremity Joint',
                },
                {
                  label: 'Lower Extremity Non Joint',
                  value: 'Lower Extremity Non Joint',
                },
                {
                  label: 'Lower Leg Tibia Fibula',
                  value: 'Lower Leg Tibia Fibula',
                },
                { label: 'Midfoot', value: 'Midfoot' },
                { label: 'Neck', value: 'Neck' },
                { label: 'Neck - Carotids', value: 'Neck - Carotids' },
                { label: 'Orbit Face Neck', value: 'Orbit Face Neck' },
                { label: 'Orbits', value: 'Orbits' },
                { label: 'Pelvis', value: 'Pelvis' },
                {
                  label: 'Pelvis Prostrate Reconstruction',
                  value: 'Pelvis Prostrate Reconstruction',
                },
                {
                  label: 'Pelvis (TV if indicated)',
                  value: 'Pelvis (TV if indicated)',
                },
                {
                  label: 'Pelvis Transabdominal Only',
                  value: 'Pelvis Transabdominal Only',
                },
                { label: 'Renal', value: 'Renal' },
                { label: 'Renal with Bladder', value: 'Renal with Bladder' },
                { label: 'Ribs', value: 'Ribs' },
                { label: 'Scapula', value: 'Scapula' },
                { label: 'Scrotum', value: 'Scrotum' },
                {
                  label: 'Scrotum with Doppler',
                  value: 'Scrotum with Doppler',
                },
                { label: 'Shoulder', value: 'Shoulder' },
                { label: 'Sinus', value: 'Sinus' },
                { label: 'Skull', value: 'Skull' },
                { label: 'Spine- Cervical', value: 'Spine- Cervical' },
                { label: 'Spine - Thoracic', value: 'Spine - Thoracic' },
                { label: 'Spine - Lumbar', value: 'Spine - Lumbar' },
                { label: 'TMJ', value: 'TMJ' },
                { label: 'Thyroid', value: 'Thyroid' },
                { label: 'Venus Doppler', value: 'Venus Doppler' },
                { label: 'Wrist', value: 'Wrist' },
              ]}
              value={bodySite}
              autoBlur={true}
              onChange={(e) => {
                setBodySite([...bodySite, { id: e?.value, name: e?.label }])
              }}
              theme={(theme) => selectTheme(theme)}
            />

            {bodySite?.length !== 0 && (
              <div className={Styles.searchAdded}>
                {bodySite.map((data, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        // marginTop: 5,
                      }}
                    >
                      <p
                        className={Styles.addManualTextInput}
                        style={{ marginTop: 10 }}
                      >
                        {data?.id ? `${data?.name}` : 'ERROR'}
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() => {
                            removeBodySite(index)
                          }}
                        />
                      </p>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Contrast</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'With Contrast', value: 'With Contrast' },
                { label: 'Without Contrast', value: 'Without Contrast' },
                {
                  label: 'With/ Without Contrast',
                  value: 'With/ Without Contrast',
                },
                { label: 'Allergy to Contrast', value: 'Allergy to Contrast' },
                {
                  label: '3D rendering, as indicated',
                  value: '3D rendering, as indicated',
                },
              ]}
              value={contrast}
              autoBlur={true}
              onChange={(val) => setContrast(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Literality</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Left', value: 'Left' },
                { label: 'Right', value: 'Right' },
                {
                  label: 'Bilateral',
                  value: 'Bilateral',
                },
              ]}
              value={literality}
              autoBlur={true}
              onChange={(val) => setLiterality(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Is the patient claustrophobic?</p>
          <div style={{ width: '10%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              Yes
              <input
                type="checkbox"
                checked={claustrophobic}
                onClick={() => {
                  setClaustrophobic(true)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div style={{ width: '10%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              No
              <input
                type="checkbox"
                checked={!claustrophobic}
                onClick={() => {
                  setClaustrophobic(false)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion} style={{ width: '100%' }}>
            Please Administer
          </p>
        </div>
        <div>
          <label class={Styles.checkContainer}>
            Oral Sedative (Medication given by referring provider)
            <input
              type="checkbox"
              checked={ifOralSedative}
              onClick={() => {
                setIfOralSedative(!ifOralSedative)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>

        <div>
          <label class={Styles.checkContainer}>
            IV Sedation by RAD's RN
            <input
              type="checkbox"
              checked={ifIVSedative}
              onClick={() => {
                setIfIVSedative(!ifIVSedative)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>

        <div>
          <label class={Styles.checkContainer}>
            General Anesthesia
            <input
              type="checkbox"
              checked={ifGeneralAnesthesia}
              onClick={() => {
                setIfGeneralAnesthesia(!ifGeneralAnesthesia)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>

        <div
          className={Styles.inputDiv}
          style={{
            marginTop: '30px',
            // borderTopWidth: 1,
            // borderTopColor: Constants.primaryTheme,
            // borderTopStyle: 'solid',
          }}
        >
          <p className={Styles.inputQuestion} style={{ width: '100%' }}>
            Additional instructions
          </p>
        </div>
        <div style={{}}>
          <label class={Styles.checkContainer}>
            Perform as ordered, DO NOT ALTER
            <input
              type="checkbox"
              checked={ifPerformAsOrdered}
              onClick={() => {
                setIfPerformAsOrdered(!ifPerformAsOrdered)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>

        <div>
          <label class={Styles.checkContainer}>
            OK to be altered per radiologist Discretion
            <input
              type="checkbox"
              checked={ifOkToBeAltered}
              onClick={() => {
                setIfOkToBeAltered(!ifOkToBeAltered)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>
        {/* </Form.Group> */}
      </div>
    )
  }

  const renderInfusion = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderBottomWidth: 1,
          borderBottomColor: Constants.primaryTheme,
          borderBottomStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>
            Date of Last Infusion/ Injectable
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <DatePicker
              onChange={(date) => {
                setDateInfusion(date)
              }}
              value={dateInfusion}
            />
          </div>

          <p className={Styles.inputQuestion}>Order Type</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'New To Therapy', value: 'New' },
                {
                  label: 'Order Renewal/ Continuing Therapy',
                  value: 'Renewal',
                },
                { label: 'Therapy Change', value: 'Change' },
              ]}
              value={orderType}
              autoBlur={true}
              onChange={(val) => setOrderType(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <div style={{ width: '40%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              Needs Prior Authorization
              <input
                type="checkbox"
                checked={txAuth}
                onClick={() => {
                  setTxAuth(!txAuth)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          {/* <p className={Styles.inputQuestion}>Date Authorized</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <DatePicker
              onChange={(date) => {
                setDateAuthInfusion(date)
              }}
              value={dateAuthInfusion}
            />
          </div> */}
        </div>

        <div
          className={Styles.inputDiv}

          //   style={{
          //     display: 'flex',
          //     flexDirection: 'row',
          //     marginTop: 50,
          //     width: '100%',
          //   }}
        >
          <p
            className={Styles.inputQuestion}

            // style={{
            //   alignSelf: 'center',
            //   color: Constants.primaryTheme,
            //   fontWeight: 'bold',
            // }}
          >
            Medication Order
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <SearchProvider config={esConfigMedications}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'drug_name',
                        }}
                        onSelectAutocomplete={(e) => {
                          setMedication([
                            {
                              AILA_ID: e?.aila_id.raw,
                              DrugName: e?.drug_name.raw,
                            },
                          ])
                          setSearchTerm('')
                          // TODO: add the medication dosage options to elastic search and then
                          // retreieve the options from there
                          updateDosageOptions(e?.drug_name.raw)
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search medications',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                            {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>

            {medication.length !== 0 && (
              <div className={Styles.searchAdded}>
                {medication.map((data, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                      }}
                    >
                      <p>{data.DrugName + ' '}</p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => handleHitClick(data)}
                      />
                      <br />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        {/* INFUSION NEW DRUG WORKFLOW - COMMENTED TILL JSON DATA IS READY */}

        {/* <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Select Drug Name</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={INFUSION_DRUGS}
              value={infusionDrug}
              autoBlur={true}
              onChange={(val) => {
                setInfusionDrug(val)
                console.log(val)
              }}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        {infusionDrug && (
          <>
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Provider Reminders</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.provider_reminders || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.provider_reminders = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Treatment Criteria</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.treatment_criteria || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.treatment_criteria = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Pre-Medications</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                {infusionDrug?.pre_medications &&
                  infusionDrug?.pre_medications?.map((premed, index) => (
                    <label class={Styles.checkContainer}>
                      {premed.name}
                      <input
                        type="checkbox"
                        checked={premed.isChecked}
                        onClick={() => {
                          let temp = { ...infusionDrug }
                          temp.pre_medications[index].isChecked =
                            !temp.pre_medications[index].isChecked
                          setInfusionDrug(temp)
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  ))}

              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Medications/ Procedure</p>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Infuse</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.medication_procedure || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.medication_procedure = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Dose</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.dose || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.dose = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>

            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Frequency</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.frequency || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.frequency = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Guidelines</p>
              <div style={{ width: '50%', margin: '0px 20px' }}>
                <textarea
                  placeholder="Enter"
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '100%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={infusionDrug?.guidelines || ''}
                  onChange={(e) => {
                    let temp = { ...infusionDrug }
                    temp.guidelines = e.target.value
                    setInfusionDrug(temp)
                  }}
                ></textarea>
              </div>
            </div>
          </>
        )} */}
        <div className={Styles.inputDiv}>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              Dispense as written DAW
              <input
                type="checkbox"
                checked={daw}
                onClick={() => {
                  setDaw(true)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              Substitution permitted
              <input
                type="checkbox"
                checked={!daw}
                onClick={() => {
                  setDaw(false)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Dosage</p>
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 250, overflowY: 'scroll', width: '100%' }}
        >
          {dosageOptions.map((type, index) => {
            return (
              <div className={Styles.contentRow} key={index}>
                <label class={Styles.checkContainer}>
                  {type.name}
                  <input
                    type="checkbox"
                    checked={type.isChecked}
                    onClick={() => {
                      console.log(type)
                      let temp = [...dosageOptions]
                      temp.forEach((item) => {
                        if (item.name === type?.name) {
                          setDosage(item.name)
                          item.isChecked = !item.isChecked
                        } else item.isChecked = false
                      })
                      setDosageOptions(temp)
                    }}
                  />
                  <span class={Styles.checkmark}></span>
                </label>
              </div>
            )
          })}
        </div>
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>
            If other, specify(if filled this option will be used)
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Dosage'}
              className={Styles.textInput}
              type="text"
              value={otherMeds}
              onChange={(e) => {
                let text = e.target.value
                setOtherMeds(text)
              }}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Max. # of Treatments</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter number of treatments'}
              className={Styles.textInput}
              type="text"
              value={treatments?.number}
              onChange={(e) => {
                let temp = { ...treatments }
                temp.number = e.target.value
                setTreatments(temp)
              }}
            />
          </div>
        </div>
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Every</p>
          <div style={{ width: '10%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Every'}
              className={Styles.textInput}
              type="text"
              value={treatments?.everyValue}
              onChange={(e) => {
                let temp = { ...treatments }
                temp.everyValue = e.target.value
                setTreatments(temp)
              }}
            />
          </div>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Day(s)', value: 'Day' },
                { label: 'Week(s)', value: 'Week' },
                { label: 'Month(s)', value: 'Month' },
                { label: 'Year(s)', value: 'Year' },
              ]}
              value={treatments?.everyUnit}
              autoBlur={true}
              onChange={(val) => {
                let temp = { ...treatments }
                temp.everyUnit = val
                setTreatments(temp)
              }}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
          {/* </div> */}
          {/* <div className={Styles.inputDiv}> */}
          <p className={Styles.inputQuestion}>For</p>
          <div style={{ width: '10%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter For'}
              className={Styles.textInput}
              type="text"
              value={treatments?.forValue}
              onChange={(e) => {
                let temp = { ...treatments }
                temp.forValue = e.target.value
                setTreatments(temp)
              }}
            />
          </div>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Week(s)', value: 'Week' },
                { label: 'Month(s)', value: 'Month' },
                { label: 'Year(s)', value: 'Year' },
              ]}
              value={treatments?.forUnit}
              autoBlur={true}
              onChange={(val) => {
                let temp = { ...treatments }
                temp.forUnit = val
                setTreatments(temp)
              }}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Weight(lb)</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter weight'}
              className={Styles.textInput}
              type="text"
              value={props.patient.weight}
              onChange={(e) => {
                let text = e.target.value
                setWeight(text)
              }}
            />
          </div>
          <p className={Styles.inputQuestion}>Height(in)</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter height'}
              className={Styles.textInput}
              type="text"
              value={props.patient.height}
              onChange={(e) => {
                let text = e.target.value
                setHeight(text)
              }}
            />
          </div>
        </div>

        {/* {renderAllergies()} */}

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Allergies</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              NKDA
              <input
                type="checkbox"
                checked={daw}
                onClick={() => {
                  setDaw(true)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <label class={Styles.checkContainer}>
              Latex Allergies
              <input
                type="checkbox"
                checked={daw}
                onClick={() => {
                  setDaw(true)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          {/* <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter weight'}
              className={Styles.textInput}
              type="text"
              value={props.patient.weight}
              onChange={(e) => {
                let text = e.target.value
                setWeight(text)
              }}
            />
          </div>
          <p className={Styles.inputQuestion}>Height(in)</p>
          <div style={{ width: '20%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter height'}
              className={Styles.textInput}
              type="text"
              value={props.patient.height}
              onChange={(e) => {
                let text = e.target.value
                setHeight(text)
              }}
            />
          </div> */}
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Type of Access</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <Select
              options={[
                { label: 'Peripheral', value: 'Peripheral' },
                { label: 'PICC', value: 'PICC' },
                { label: 'Port', value: 'Port' },
                { label: 'Midline', value: 'Midline' },
                { label: 'Subcutaneous', value: 'Subcutaneous' },
                { label: 'IM', value: 'IM' },
              ]}
              value={accessType}
              autoBlur={true}
              onChange={(val) => setAccessType(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Pre-medications</p>
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 250, overflowY: 'scroll', width: '100%' }}
        >
          {preMedications.map((type, index) => {
            return (
              <div className={Styles.contentRow} key={index}>
                <label class={Styles.checkContainer}>
                  {type.name}
                  <input
                    type="checkbox"
                    checked={type.isChecked}
                    onClick={() => {
                      console.log(type)
                      let temp = [...preMedications]
                      temp.forEach((item) => {
                        if (item.name === type?.name)
                          item.isChecked = !item.isChecked
                      })
                      setPreMedications(temp)
                    }}
                  />
                  <span class={Styles.checkmark}></span>
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderPatientNotesIcd = () => {
    let conditionsElement =
      patientIcdCodes &&
      patientIcdCodes.map(
        (condn, index) => (
          // condn?.code?.coding?.[0]?.display && !condn?.userEntered ?
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p
              key={index.toString()}
              style={{
                textAlign: 'left',
                padding: '2px 10px',
                fontSize: '16px',
              }}
            >
              {condn?.icd_10?.icd10_code} : {condn?.icd_10?.title}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{ marginRight: 10, cursor: 'pointer' }}
                onClick={() => {
                  console.log('add to icd', condn)
                  addIcdCode({
                    code: condn?.icd_10?.icd10_code,
                    display: condn?.icd_10?.title,
                  })
                  // let tempSelectedTests = [...selectedLabTests]
                  // let indexValue = tempSelectedTests.findIndex(
                  //   (test) => test.orderable === clickedOrderable,
                  // )
                  // tempSelectedTests[indexValue]['diagnosis'] =
                  //   tempSelectedTests[indexValue]?.['diagnosis'] || []
                  // tempSelectedTests[indexValue]['diagnosis'].push({
                  //   icd_code: condn?.icd_10?.icd10_code,
                  //   icd_desc: condn?.icd_10?.title,
                  // })
                  // this.setState({
                  //   selectedLabTests: tempSelectedTests,
                  //   clickedOrderable: null,
                  // })
                  // closeIcdModal()
                }}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            </div>
          </div>
        ),
        // : null,
      )
    return (
      <div style={{ width: '100%' }}>
        <div className={Styles.modalNotesRow}>
          <p
            style={{
              color: 'black',
              fontSize: '18px',
              marginTop: 5,
            }}
          >
            List of Selected ICD-10 for the patient
          </p>
        </div>
        <div className={Styles.modalNotesRow}>
          <div
            className={Styles.contentBackground}
            style={{ height: 150, overflowY: 'scroll', width: '100%' }}
          >
            {conditionsElement}
          </div>
        </div>
      </div>
    )
  }

  const renderIcdCodesAndDiagnosis = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          // borderBottomWidth: 1,
          // borderBottomColor: Constants.primaryTheme,
          // borderBottomStyle: 'solid',
          // borderTopWidth: 1,
          // borderTopColor: Constants.primaryTheme,
          // borderTopStyle: 'solid',
        }}
      >
        {/* <div    style={{ display: 'flex', flexDirection: 'row', padding: '30px 20px' }}   > */}

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>
            {/* <p
          style={{
            color: Constants.primaryTheme,
            fontWeight: 'bold',
            width: 140,
          }}
        > */}
            {'ICD-10 Code'}
          </p>
          <div style={{ width: '80%' }}>
            <div>
              {/* <InstantSearch
                searchClient={searchClient}
                indexName={'ICD_10_COMPLETE'}
              >
                <div style={{ position: 'relative' }}>
                  <SearchBox />
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                      paddingRight: 30,
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => HitICD(props)} />
                  </div>
                </div>
              </InstantSearch> */}
              <SearchProvider config={esConfigICD10}>
                <WithSearch
                  mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                    wasSearched,
                    setSearchTerm,
                  })}
                >
                  {({ wasSearched, setSearchTerm }) => {
                    // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                    return (
                      <div className="App">
                        <EsSearchBox
                          debounceLength={0}
                          autocompleteResults={{
                            titleField: 'search_term',
                          }}
                          onSelectAutocomplete={(e) => {
                            addIcdCode({
                              code: e?.code.raw,
                              display: e?.display.raw,
                            })
                            setSearchTerm('')
                          }}
                          searchAsYouType={true}
                          inputView={({
                            getAutocomplete,
                            getInputProps,
                            getButtonProps,
                          }) => (
                            <>
                              <div className="sui-search-box__wrapper">
                                <input
                                  {...getInputProps({
                                    placeholder: 'Search ICD10',
                                  })}
                                />
                                {getAutocomplete()}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    )
                  }}
                </WithSearch>
              </SearchProvider>
            </div>

            {renderPatientNotesIcd()}

            {icdCodes.length !== 0 && (
              <div>
                <p style={{ marginTop: 30, color: Constants.primaryTheme }}>
                  Selected ICD-10 Code
                </p>
                <div style={{ height: 160, overflow: 'auto', width: '50%' }}>
                  {icdCodes.map((icd, index) => (
                    <div
                      style={{
                        justifyContent: 'space-between',
                        borderTop: '1px solid #D3D3D3',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '4px 10px',
                      }}
                    >
                      <p>
                        {index + 1}. {icd.code}: {icd.display}
                      </p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => {
                          let arr = icdCodes.filter(
                            (data) => data.code !== icd.code,
                          )
                          setIcdCodes(arr)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Diagnosis</p>
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 250, overflowY: 'scroll', width: '100%' }}
        >
          {diagnoses.map((type, index) => {
            return (
              <div className={Styles.contentRow} key={index}>
                <label class={Styles.checkContainer}>
                  {type.name}
                  <input
                    type="checkbox"
                    checked={type.isChecked}
                    onClick={() => {
                      console.log(type)
                      let temp = [...diagnoses]
                      temp.forEach((item) => {
                        if (item.name === type?.name)
                          item.isChecked = !item.isChecked
                      })
                      setDiagnoses(temp)
                    }}
                  />
                  <span class={Styles.checkmark}></span>
                </label>
              </div>
            )
          })}
        </div> */}
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Reason</p>
          <div style={{ width: '60%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Reason'}
              className={Styles.textInput}
              type="text"
              value={reasonIcd}
              onChange={(e) => {
                let text = e.target.value
                setReasonIcd(text)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderInstruction = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Instructions/ Notes</p>
          <div style={{ width: '60%', margin: '0px 20px' }}>
            <input
              placeholder={'Enter Instructions'}
              className={Styles.textInput}
              type="text"
              value={notes}
              onChange={(e) => {
                let text = e.target.value
                setNotes(text)
              }}
            />
          </div>
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            // marginTop: '8vh',
            margin: '2%',
            justifyContent: 'flex-start',
          }}
        >
          <label className={Styles.checkContainer}>
            <p
              style={{
                color: Constants.darkGray,
                width: '100%',
                overflowWrap: 'break-word',
                display: 'block',
              }}
            >
              Include Patient Chart Information
            </p>
            <input
              type="checkbox"
              checked={includePChart}
              onClick={() => {
                setIncludePChart(!includePChart)
              }}
            />
            <span className={Styles.checkmark}></span>
          </label>
        </div> */}
      </div>
    )
  }

  const renderAllergies = () => {
    let allergies =
      patientCore && patientCore.allergies ? patientCore?.allergies : []
    let newAllergies = providerAllergies?.concat(allergies)
    let allergiesElement = newAllergies
      ? newAllergies?.map((allergy, index) => (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p key={index} style={{ textAlign: 'left', padding: '2px 10px' }}>
              {allergy?.code?.coding?.[0]?.display ||
                (allergy?.type &&
                  (allergy?.name?.length
                    ? allergy?.name?.join()
                    : allergy?.type)) ||
                allergy}
            </p>
          </div>
        ))
      : []
    return (
      <div style={{ width: '100%', margin: 10, position: 'relative' }}>
        <label class={Styles.checkContainer}>
          Include Allergies
          <input
            type="checkbox"
            checked={sendAllergies}
            onClick={() => {
              setSendAllergies(!sendAllergies)
            }}
          />
          <span class={Styles.checkmark}></span>
        </label>

        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {allergiesElement}
        </div>

        <label class={Styles.checkContainer}>
          No known Allergies
          <input
            type="checkbox"
            checked={noKnownAllergies}
            onClick={() => {
              setNoKnownAllergies(!noKnownAllergies)
            }}
          />
          <span class={Styles.checkmark}></span>
        </label>
      </div>
    )
  }

  const renderFamilyHistory = () => {
    let familyScreening = patientCore && patientCore.screening_family
    let entries = []

    function getFamilyHistoryFormatted(relations) {
      if (!relations) return null
      let toReturn = []
      relations.forEach((each) => {
        let conditions = []
        if (each.conditions)
          conditions = each.conditions.map((x, index) => (
            <span key={index.toString()} style={{ width: '80%' }}>
              {x}
              {index === each.conditions.length - 1 ? '' : ', '}
            </span>
          ))
        toReturn.push(
          <div
            key={each.relationship}
            className={Styles.contentRow}
            style={{ padding: '10px 10px', flexWrap: 'wrap' }}
          >
            <p className={Styles.contentRowKey}>{each.relationship}:</p>
            <p style={{ marginLeft: 5, overflowWrap: 'break-word' }}>
              {conditions}
            </p>
          </div>,
        )
      })

      return toReturn
    }

    if (familyScreening) {
      entries = getFamilyHistoryFormatted(familyScreening.relations)
    }
    return (
      <div style={{ width: '100%', margin: 10, position: 'relative' }}>
        <label class={Styles.checkContainer}>
          Family History
          <input
            type="checkbox"
            checked={sendFamilyHistory}
            onClick={() => {
              setSendFamilyHistory(!sendFamilyHistory)
            }}
          />
          <span class={Styles.checkmark}></span>
        </label>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {entries ? entries : <p>No Family History Found</p>}
        </div>
      </div>
    )
  }

  const renderMedications = () => {
    function getMedicationsOrVitaminsRow(isMedications, active) {
      let key1, key2

      if (isMedications) {
        if (active) {
          key1 = 'active_medications'
          key2 = 'active_medications_provider'
        } else {
          key1 = 'inactive_medications'
          key2 = 'inactive_medications_provider'
        }
      } else {
        if (active) {
          key1 = 'active_vitamins'
          key2 = 'active_vitamins_provider'
        } else {
          key1 = 'inactive_vitamins'
          key2 = 'inactive_vitamins_provider'
        }
      }

      let array1 =
        (patientMedicationsData && patientMedicationsData[key1]) || []
      let array2 = (providerMeds && providerMeds[key2]) || []
      let newArray = array2.concat(array1)
      let toReturn = newArray.map((x, i) => {
        let activeString = 'Active'
        if (!active)
          activeString = 'Inactive since ' + moment(x.end).format('MMM Do YYYY')
        if (newArray.length !== 0) {
          return (
            <div key={i.toString()} className={Styles.contentRow}>
              <p style={{ width: '35%', textAlign: 'center' }}>
                {x.dose?.quantity
                  ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit})`
                  : `${x.name}`}
              </p>
              {/*<p style={{width: '20%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>*/}
              <p style={{ width: '15%', textAlign: 'center' }}>
                {x.frequency?.quantity
                  ? `${x.frequency.quantity} / ${x.frequency.recurring}`
                  : '-'}
              </p>
              <p style={{ width: '20%', textAlign: 'center' }}>
                {moment(x.start).format('MMM Do YYYY')}
              </p>
              <p style={{ width: '20%', textAlign: 'center' }}>
                {activeString}
              </p>
            </div>
          )
        } else {
          return (
            <div key={i.toString()} className={Styles.contentRow}>
              <p style={{ width: '80%', textAlign: 'center' }}>
                No Medications Found
              </p>
            </div>
          )
        }
      })

      return toReturn
    }

    function renderMedicationsOrVitamins(isMedications) {
      let activeElement
      let inactiveElement

      activeElement = getMedicationsOrVitaminsRow(isMedications, true)
      inactiveElement = getMedicationsOrVitaminsRow(isMedications, false)

      return (
        <div style={{ width: '100%', margin: 10, position: 'relative' }}>
          <label class={Styles.checkContainer}>
            Medications
            <input
              type="checkbox"
              checked={sendMeds}
              onClick={() => {
                setSendMeds(!sendMeds)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
          {/* <h5 style={{ color: Constants.primaryTheme }}>
            {isMedications ? 'Medications' : 'Vitamins'}
          </h5> */}
          {/* <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <FontAwesomeIcon
              onClick={() => {
                isMedications
                  ? onManualAddClicked('medications')
                  : onManualAddClicked('vitamins')
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div> */}
          <div
            className={Styles.contentBackground}
            style={{ height: 340, overflowY: 'scroll' }}
          >
            <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
              <h6
                className={Styles.tableHeader}
                style={{ width: '35%', textAlign: 'center' }}
              >
                Name
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '15%', textAlign: 'center' }}
              >
                Dose
              </h6>
              {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
              <h6
                className={Styles.tableHeader}
                style={{ width: '20%', textAlign: 'center' }}
              >
                Start
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '20%', textAlign: 'center' }}
              >
                Status
              </h6>
            </div>
            {activeElement}
            {inactiveElement}
          </div>
        </div>
      )
    }

    return (
      <div className={Styles.tabContentWrapper}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          {renderMedicationsOrVitamins(true)}
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: 40,
          }}
        >
          {renderMedicationsOrVitamins(false)}
        </div> */}
      </div>
    )
  }

  const renderConditions = () => {
    function renderPatientConditions() {
      let conditions = patientConditionsData || []
      // filter out user entered data
      conditions = conditions.filter(
        (userCondn) =>
          !providerConds?.some(
            (prodCondn) =>
              prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
          ),
      )
      let newConditions = conditions?.concat(providerConds)
      let conditionsElement =
        newConditions &&
        newConditions.map((condn, index) => (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p
              key={index.toString()}
              style={{ textAlign: 'left', padding: '2px 10px' }}
            >
              {condn.symptom ||
                condn?.code?.coding?.[0]?.display?.replace(/"/g, '')}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {condn?.code?.coding?.[0]?.display && !condn?.userEntered && (
                <div style={{ marginRight: 10 }}>
                  <FontAwesomeIcon
                    icon={faUserMd}
                    style={{ color: Constants.primaryTheme, fontSize: 20 }}
                  />
                </div>
              )}
            </div>
          </div>
        ))
      if (newConditions.length) {
        return (
          <div style={{ width: '100%', margin: 10, position: 'relative' }}>
            <label class={Styles.checkContainer}>
              Conditions
              <input
                type="checkbox"
                checked={sendConditions}
                onClick={() => {
                  setSendConditions(!sendConditions)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
            {/* <h5 style={{ color: Constants.primaryTheme }}>Conditions</h5> */}

            <div
              className={Styles.contentBackground}
              style={{ height: 240, overflowY: 'scroll' }}
            >
              {conditionsElement}
            </div>
          </div>
        )
      } else {
        return <p>No Conditions Found</p>
      }
    }
    return (
      <div className={Styles.tabContentWrapper}>
        <div
          style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}
        >
          {renderPatientConditions()}
        </div>
      </div>
    )
  }
  const renderCarePlanNotes = () => {
    // let carePlanData1 = patientCarePlanData || []
    let carePlanData1 = selectedNote || []

    return (
      <>
        {carePlanData1 &&
          carePlanData1?.map((carePlanData) => {
            return (
              // latest notes from the provider
              <div
                className={Styles.contentRow}
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <label class={Styles.checkContainer}>
                    {/* {carePlanData.name} */}
                    <input
                      type="checkbox"
                      checked={carePlanData.isChecked}
                      onClick={() => {
                        let tempq = [...selectedNote]
                        tempq.forEach((item) => {
                          if (item._id === carePlanData._id)
                            item.isChecked = !item.isChecked
                        })
                        setSelectedNote(tempq)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>

                  {/* <Form.Check
                    type={'radio'}
                    // label={type}
                    value={carePlanData}
                    onClick={(val) => {
                      if (carePlanData === selectedNote) {
                        setSelectedNote(null)
                      } else {
                        setSelectedNote(carePlanData)
                      }
                      // this.handleRadioInput(val.target.value)
                    }}
                    style={{
                      color: 'rgb(23,61,68)',
                      fontFamily: 'Avenir',
                      marginLeft: '5%',
                    }}
                    checked={selectedNote === carePlanData}
                    classes={Styles.radioButton}
                  /> */}
                  <img
                    src={
                      carePlanData?.doctor?.image_url
                        ? carePlanData?.doctor?.image_url
                        : DocPlaceHolder
                    }
                    height={50}
                    width={50}
                    style={{ borderRadius: '50%', margin: 10 }}
                  />
                  <h6 style={{ color: Constants.primaryTheme }}>
                    {carePlanData?.doctor?.first_name}{' '}
                    {carePlanData?.doctor?.last_name} {', '}
                    {carePlanData?.appointment_date
                      ? moment(carePlanData?.appointment_date).format(
                          'MMM Do YYYY',
                        )
                      : moment(carePlanData?.created_at, 'YYYY-MM-DD').format(
                          'MMM Do YYYY',
                        )}
                  </h6>
                </div>
                <div style={{ width: '100%' }} className={Styles.carePlanRow}>
                  <div style={{ width: '80%' }}>
                    <h6 style={{ color: Constants.primaryTheme }}></h6>
                    {carePlanData?.careplan &&
                      carePlanData?.careplan.map((note_data, index) => (
                        <p key={index} style={{ marginLeft: '10%' }}>
                          <b>{note_data?.icd_10?.title?.replace(/"/g, '')}</b> -{' '}
                          {note_data.assessment}
                        </p>
                      ))}
                  </div>

                  {
                    // carePlanData?.careplan?.icd_10 &&
                    <div style={{ width: '80%' }}>
                      <p
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor:
                            carePlanData?.note_status === 'completed' ||
                            !carePlanData?.note_id
                              ? Constants.primaryTheme
                              : 'red',
                          color:
                            carePlanData?.note_status === 'completed' ||
                            !carePlanData?.note_id
                              ? Constants.primaryTheme
                              : 'red',
                          // cursor: 'pointer',
                          textAlign: 'left',
                          marginLeft: '10%',
                        }}
                      >
                        {carePlanData?.note_status === 'completed' ||
                        !carePlanData?._id
                          ? carePlanData?.appointment_date
                            ? 'Complete Notes from visit'
                            : 'Notes'
                          : 'Incomplete note'}
                      </p>
                    </div>
                  }
                </div>
              </div>
            )
          })}
      </>
    )
  }

  const renderSearchList = () => {
    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: [
        'facility_name',
        'email',
        'phone',
        'address',
        'city',
        'state',
        'zipcode',
      ],
    }

    let tempArray = facilityType ? metadata[facilityType?.value] : []
    fuse = new Fuse(tempArray, options)

    let uniqueList = []

    if (searchName.length !== 0) {
      const results = fuse.search(searchName)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else {
      uniqueList = tempArray
    }

    const renderList = (facilityData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.contentBackground}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            backgroundColor: Constants.contentBackground,
            borderRadius: Constants.borderRadius,
            padding: '1% 2%',
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {facilityType?.label === 'Imaging' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.type || ''} ${facilityData?.email || ''}`}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.address || ''} ${
                    facilityData?.city || ''
                  } \n ${facilityData?.state || ''} ${
                    facilityData?.zipcode || ''
                  }`}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {` ${facilityData?.operation_hours || ''} \n ${
                    facilityData?.medications_infused || ''
                  } `}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {` ${facilityData?.referral_link || ''}`}
                </h6>
              </div>
            ) : facilityType?.label === 'Infusion' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.type || ''}
                  <br />
                  {facilityData?.email || ''}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.address || ''}
                  {facilityData?.city || ''} {facilityData?.state || ''}{' '}
                  {facilityData?.zipcode || ''}
                  <br />
                  {` ${facilityData?.operation_hours || ''}`}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  Medications Infused: {facilityData?.medications_infused || ''}
                  <br />
                  Accepted Insurances : {facilityData?.accepted_insurance || ''}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {facilityData?.referral_link || ''}
                </h6>
              </div>
            ) : facilityType?.label === 'DME' ? (
              <div
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: 10,
                }}
              >
                <h6 style={{ color: Constants.primaryThemeDark }}>
                  {facilityData?.facility_name}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.email || ''}
                  <br />
                  Phone - {facilityData?.phone || ''}
                  <br />
                  Fax - {facilityData?.fax || ''}
                </h6>
                <h6 style={{ color: Constants.primaryTheme }}>
                  {facilityData?.address || ''}
                  {facilityData?.city || ''} {facilityData?.state || ''}{' '}
                  {facilityData?.zipcode || ''}
                </h6>
                <h6 style={{ color: 'blue' }}>
                  {facilityData?.referral_link || ''}
                </h6>
              </div>
            ) : (
              <div></div>
            )}

            <Button
              className={GlobalStyles.button}
              //   disabled={loading}
              onClick={() => {
                console.log(facilityData)
                setReferringTo(facilityData?.facility_name)
                setPhoneNumber(facilityData?.phone)
                let faxNumber = facilityData?.fax
                if(faxNumber.length === 10)
                  faxNumber = "1" + faxNumber
                setFaxNumber(faxNumber)
                setShowSearchList(false)
                setSearchName('')
                setSearchList([])
              }}
              style={{
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
            >
              Select
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Facility
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '2%',
            // padding: '1% 5%',
          }}
        >
          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Type</p>
            <div style={{ width: '50%', margin: '0px 20px' }}>
              <Select
                options={[
                  { label: 'Infusion', value: 'INFUSION_DATABASE' },
                  { label: 'Imaging', value: 'IMAGING_DATABASE' },
                  { label: 'DME', value: 'DME_DATABASE' },
                ]}
                value={facilityType}
                autoBlur={true}
                onChange={(val) => {
                  setFacilityType(val)
                  console.log(metadata[val?.value])
                }}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>

          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Search</p>
            <div style={{ width: '50%', margin: '0px 20px' }}>
              <input
                placeholder="Search Name"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={searchName}
                onChange={(e) => {
                  setSearchName(e.target.value)
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {uniqueList?.length !== 0 ? (
            uniqueList?.map((facility, index) => renderList(facility, index))
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search again
            </p>
          )}
        </div>
      </div>
    )
  }

  const getLabStatusText = (x) => {
    if (!labOrdersFromChange || !x.order_id) return 'order not placed'

    let ordersFromChange = labOrdersFromChange || {}
    let changeOrder = ordersFromChange[x.order_id]
    return ORDER_STATUSES[changeOrder?.status]
  }

  const renderLabsContent = () => {
    const a = null
    let tableHeader = (
      <div className={Styles.row}>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Name
        </p>
        <p className={Styles.headerText} style={{ width: '20%' }}>
          Location
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Payment Status
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Paid By
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Created Date
        </p>
        <p className={Styles.headerText} style={{ width: '15%' }}>
          Collection Date
        </p>
        <p className={Styles.headerText} style={{ width: '10%' }}>
          Status
        </p>
      </div>
    )

    let tableRows =
      labOrdersFromBackend &&
      labOrdersFromBackend.map((x) => {
        let tests = x.tests || []
        let testElements = tests.map((test) => <li>{test.name}</li>)

        return (
          <div className={Styles.row}>
            <ul
              className={Styles.entryText}
              style={{ width: '20%', textAlign: 'left' }}
            >
              {testElements}
            </ul>
            <p className={Styles.entryText} style={{ width: '20%' }}>
              {x.lab?.label}
            </p>
            <p
              // onClick={() => x.order_id && onLabRequistionRequested(x)}
              className={Styles.entryText}
              style={{
                width: '10%',
                color: x.order_id ? 'blue' : 'black',
                textDecoration: x.order_id ? 'underline' : 'none',
                cursor: x.order_id ? 'pointer' : 'auto',
              }}
            >
              {x.paid ? 'Paid' : 'Unpaid'}
            </p>
            <p className={Styles.entryText} style={{ width: '10%' }}>
              {x.payment_type}
            </p>
            <p className={Styles.entryText} style={{ width: '15%' }}>
              {moment(x.created_at).format('MM/DD/YYYY')}
            </p>
            <p className={Styles.entryText} style={{ width: '15%' }}>
              {moment(x.collection_date_time).format('MM/DD/YYYY h:mmA')}
            </p>
            <p
              className={Styles.entryText}
              // onClick={() => x.result && onLabReportRequested(x)}
              style={{
                width: '10%',
                cursor: x.result ? 'pointer' : 'auto',
                color: x.result ? 'blue' : 'black',
                textDecoration: x.result ? 'underline' : 'none',
              }}
            >
              {getLabStatusText(x)}
            </p>
          </div>
        )
      })

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: 20,
        }}
      >
        {labOrdersFromBackend && labOrdersFromBackend.length !== 0 ? (
          <div
            className={Styles.contentBackground}
            style={{ height: 500, overflowY: 'scroll', width: '100%' }}
          >
            <label class={Styles.checkContainer}>
              Lab Orders
              <input
                type="checkbox"
                checked={sendLabs}
                onClick={() => {
                  setSendLabs(!sendLabs)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
            {tableHeader}
            {labOrdersFromBackend && labOrdersFromBackend.length !== 0 && (
              <div
                // className={Styles.contentBackground}
                // style={{ height: 500, overflowY: 'scroll', width: '100%' }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: 20,
                }}
              >
                {tableRows}
              </div>
            )}
          </div>
        ) : (
          `No Labs Found`
        )}
      </div>
    )
  }

  {
    /* <Modal
          ariaHideApp={false}
          isOpen={showRequisitionModal}
          onRequestClose={() => setShowRequisitionModal(false)}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${modalRequisition}`}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={showLabResultModal}
          onRequestClose={() => setShowLabResultModal(false)}
          style={customChangeModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${labResultPdf}`}
          />
        </Modal> */
  }

  const renderReferringToModal = () => {
    // if (scheduleModalLoading) {
    //   return (
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         minWidth: '100%',
    //         height: '100%',
    //       }}
    //     >
    //       <div className={GlobalStyles.loader} />
    //     </div>
    //   )
    // }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => setShowReferringToModal(false)}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Referring To
        </h4>

        {/* <div
          className={Styles.inputDiv}
          style={{
            display: adminAccess ? 'flex' : 'none',
          }}
        >
        </div> */}
        <div
          className={Styles.inputDiv}
          style={{
            display: adminAccess ? 'flex' : 'none',
          }}
        >
          <p
            className={Styles.inputQuestion}
            style={{
              width: '100px',
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
            }}
          >
            Name
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Referring To'}
              className={Styles.textInput}
              type="text"
              value={referringTo}
              onChange={(e) => {
                let text = e.target.value
                setReferringTo(text)
              }}
            />
          </div>
          <FontAwesomeIcon
            onClick={() => {
              setShowSearchList(true)
              setSearchName('')
              setSearchList([])
            }}
            icon={faSearch}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </div>

        <div
          className={Styles.inputDiv}
          style={{ display: adminAccess ? 'flex' : 'none' }}
        >
          <p
            className={Styles.inputQuestion}
            style={{
              width: '100px',
            }}
          >
            Phone Number
          </p>
          <div
            style={{
              width: '30%',
              margin: '0px 20px',
            }}
          >
            <input
              placeholder={'Phone Number'}
              className={Styles.textInput}
              type="text"
              value={phoneNumber}
              onChange={(e) => {
                let text = e.target.value
                setPhoneNumber(text)
              }}
            />
          </div>

          <p
            className={Styles.inputQuestion}

            // style={{
            //   alignSelf: 'center',
            //   color: Constants.primaryTheme,
            //   fontWeight: 'bold',
            // }}
          >
            Fax Number
          </p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Fax Number'}
              className={Styles.textInput}
              type="text"
              value={faxNumber}
              onChange={(e) => {
                let text = e.target.value
                setFaxNumber(text)
              }}
            />
          </div>
        </div>
        <Button
          onClick={() => {
            setShowReferringToModal(false)
            setSaveReferralData({
              ...saveReferralData,
              fax_number: faxNumber,
              phone: phoneNumber,
              referring_to: referringTo,
            })
            console.log('button1')

            saveReferralToMongo({
              ...saveReferralData,
              fax_number: faxNumber,
              phone: phoneNumber,
              referring_to: referringTo,
            })

            Axios(pdfTemplate).then((res) => {
              let templateHtml = Handlebars.compile(res.data.toString())
              let bodyHtml = templateHtml({
                ...saveReferralData,
                fax_number: faxNumber,
                phone: phoneNumber,
                referring_to: referringTo,
              })
              setPdfData(bodyHtml)
              setPdfTemplate(templateHtml)
            })
            // try {
            //   let saveHtmlPdfData = pdfTemplate(saveReferralData)
            //   console.log('button3')
            //   setPdfData(saveHtmlPdfData)
            // } catch (error) {
            //   console.error(error)
            // }
            console.log('Button clicked') // Add this line to check if the function is being called
            setShowModal(true)
          }}
          className={GlobalStyles.button}
          style={{
            width: 100,
            marginTop: 10,
          }}
          variant="primary"
        >
          Select
        </Button>
      </div>
    )
  }

  const renderContent = () => {
    if (!showNext) {
      return (
        <>
          {/* <div
            className={Styles.inputDiv}
            style={{
              display: adminAccess ? 'flex' : 'none',
            }}
          >
            <p
              className={Styles.inputQuestion}
              style={{ color: Constants.darkGray, minWidth: '100%' }}
            >
              Task Description : {props?.task_description}
            </p>
          </div>
          <div
            className={Styles.inputDiv}
            style={{
              display: adminAccess ? 'flex' : 'none',
            }}
          >
            <p
              className={Styles.inputQuestion}

              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Facility Name
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <input
                placeholder={'Referring To'}
                className={Styles.textInput}
                type="text"
                value={referringTo}
                onChange={(e) => {
                  let text = e.target.value
                  setReferringTo(text)
                }}
              />
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setShowSearchList(true)
                setSearchName('')
                setSearchList([])
              }}
              icon={faSearch}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginLeft: 10,
                cursor: 'pointer',
              }}
            />
          </div> */}

          <div
            className={Styles.inputDiv}
            // style={{ display: !adminAccess ? 'flex' : 'none' }}
            style={{ display: 'flex' }}
          >
            <p className={Styles.inputQuestion}>Referral Type</p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                options={[
                  { label: 'To Physician/Provider', value: 'physician' },
                  { label: 'Durable Medical Equipment', value: 'medical' },
                  { label: 'Imaging/ Radiology', value: 'imaging' },
                  { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
                  { label: 'Patient Prior Auth', value: 'priorAuth' },
                  {
                    label: 'Patient needs to be admitted to the hospital',
                    value: 'patient_hospital',
                  },
                  {
                    label: 'Patient needs to be sent to urgent care',
                    value: 'urgent_care',
                  },
                ]}
                value={referralType}
                autoBlur={true}
                onChange={(val) => {
                  setReferralType(val)
                  if (referralType?.value === 'speciality') {
                    setHeight(
                      `${Math.floor(props?.patient?.height / 12)}.${
                        props?.patient?.height % 12
                      }`,
                    )
                    setWeight(props?.patient?.weight)
                  }
                }}
                theme={(theme) => selectTheme(theme)}
              />
            </div>

            {/*<p style={{ alignSelf: 'center', color: Constants.primaryTheme, fontWeight: 'bold' }}>Order Type</p>*/}
            {/*<div style={{ width: 200, margin: '0px 20px' }}>*/}
            {/*  <Select*/}
            {/*    style={{width: 100}}*/}
            {/*    options={[*/}
            {/*      {label: '1st Order type', value: '1st Order type'},*/}
            {/*      {label: '2nd Order type', value: '2nd Order type'},*/}
            {/*    ]}*/}
            {/*    value={this.state.orderType}*/}
            {/*    autoBlur={true}*/}
            {/*    onChange={(val) => this.setState({orderType:  val})}*/}
            {/*    theme={(theme) => selectTheme(theme)}*/}
            {/*  />*/}
            {/*</div>*/}

            <p
              className={Styles.inputQuestion}
              style={{
                visibility:
                  referralType?.value === 'physician' ? 'visible' : 'hidden',
              }}
            >
              Specialty
            </p>
            <div
              style={{
                width: '25%',
                margin: '0px 20px',
                visibility:
                  referralType?.value === 'physician' ? 'visible' : 'hidden',
              }}
            >
              <Select
                //   style={{ width: 100 }}
                options={SPECIALITY_OPTIONS}
                value={speciality}
                autoBlur={true}
                onChange={(val) => setSpeciality(val)}
                theme={(theme) => selectTheme(theme)}
              />
            </div>

            <div
              style={{
                width: '15%',
                // margin: '0px 20px',
                visibility:
                  speciality && speciality.value === 'Others'
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <input
                placeholder={'Enter Specialty'}
                className={Styles.textInput}
                type="text"
                value={extraSpecialty}
                onChange={(e) => {
                  let text = e.target.value
                  setExtraSpecialty(text)
                }}
              />
            </div>
          </div>

          {referralType && referralType?.value === 'speciality' && (
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion} style={{ minWidth: '100%' }}>
                <a
                  href="https://locator.infusioncenter.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: Constants.darkGray,
                    textDecoration: 'underline',
                  }}
                >
                  Infusion Center Locator
                </a>
              </p>
            </div>
          )}

          {/* <div
            className={Styles.inputDiv}
            style={{ display: adminAccess ? 'flex' : 'none' }}
          >
            <p className={Styles.inputQuestion}>Phone Number</p>
            <div
              style={{
                width: '30%',
                margin: '0px 20px',
              }}
            >
              <input
                placeholder={'Phone Number'}
                className={Styles.textInput}
                type="text"
                value={phoneNumber}
                onChange={(e) => {
                  let text = e.target.value
                  setPhoneNumber(text)
                }}
              />
            </div>

            <p
              className={Styles.inputQuestion}

              // style={{
              //   alignSelf: 'center',
              //   color: Constants.primaryTheme,
              //   fontWeight: 'bold',
              // }}
            >
              Fax Number
            </p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <input
                placeholder={'Fax Number'}
                className={Styles.textInput}
                type="text"
                value={faxNumber}
                onChange={(e) => {
                  let text = e.target.value
                  setFaxNumber(text)
                }}
              />
            </div>
          </div> */}

          <div
            className={Styles.inputDiv}
            // style={{ display: !adminAccess ? 'flex' : 'none' }}
            style={{ display: 'flex' }}
          >
            <p className={Styles.inputQuestion}>Priority</p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                style={{ width: 100 }}
                options={[
                  { label: 'Urgent', value: 'Urgent' },
                  { label: 'Routine', value: 'Routine' },
                ]}
                value={priority}
                autoBlur={true}
                onChange={(val) => setPriority(val)}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>
          <div
            className={Styles.inputDiv}
            style={{
              display: referralType?.value === 'physician' ? 'flex' : 'none',
            }}
          >
            <p className={Styles.inputQuestion}>Referring To</p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <input
                placeholder={'Referring To'}
                className={Styles.textInput}
                type="text"
                value={referringTo}
                onChange={(e) => {
                  let text = e.target.value
                  setReferringTo(text)
                }}
              />
            </div>
          </div>
          <div
            className={Styles.inputDiv}
            // style={{ display: !adminAccess ? 'flex' : 'none' }}
            style={{ display: 'flex' }}
          >
            <p className={Styles.inputQuestion}>Referring Provider</p>
            <div style={{ width: '30%', margin: '0px 20px' }}>
              <Select
                style={{ width: 100 }}
                options={providerOptionList}
                value={selectedProvider}
                getOptionValue={(option) => option.label}
                autoBlur={true}
                onChange={(val) => {
                  console.log('Selected Provider: ', val)

                  setSelectedProvider(val)
                }}
                theme={(theme) => selectTheme(theme)}
              />
            </div>
          </div>
        </>
      )
    } else if (showNext) {
      return (
        <>
          {referralType &&
            referralType.value === 'speciality' &&
            renderInfusion()}
          {referralType &&
            referralType.value === 'imaging' &&
            renderImagingFields()}
          {/* <div className={Styles.horizontalLine} /> */}
          {renderIcdCodesAndDiagnosis()}
          {/* <div className={Styles.horizontalLine} /> */}
          {renderInstruction()}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Conditions</p>

              {renderConditions()}

              {/* <Conditions
                patient={props?.patient}
                patientCore={props?.patient}
                conditions={patientConditionsData}
                providerConditions={providerConds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Allergies</p>

              {renderAllergies()}

              {/* <Conditions
                patient={props?.patient}
                patientCore={props?.patient}
                conditions={patientConditionsData}
                providerConditions={providerConds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Family History</p>

              {renderFamilyHistory()}

              {/* <Conditions
                patient={props?.patient}
                patientCore={props?.patient}
                conditions={patientConditionsData}
                providerConditions={providerConds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Medications</p>

              {renderMedications()}

              {/* <MedicationsTab
                patient={props?.patient}
                patientCore={props?.patient}
                medications={patientMedicationsData}
                providerMedications={providerMeds}
              /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Note</p>

              {patientCarePlanData && patientCarePlanData.length != 0 ? (
                <div
                  className={Styles.contentBackground}
                  style={{ height: 500, overflowY: 'scroll', width: '100%' }}
                >
                  {renderCarePlanNotes()}
                </div>
              ) : (
                `No Notes Found`
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '5% 1%',
              borderTopWidth: 1,
              borderTopColor: Constants.primaryTheme,
              borderTopStyle: 'solid',
            }}
          >
            <div className={Styles.inputDiv}>
              <p className={Styles.inputQuestion}>Patient Lab Orders</p>

              {renderLabsContent()}
            </div>
          </div>
        </>
      )
    }
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          minHeight: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        {renderContent()}

        {showModal && (
          <Modal
            ariaHideApp={false}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            <h3>Please verify the document and proceed to Fax</h3>
            <Button
              onClick={() => {
                let postData = {
                  htmlContent: pdfData,
                }
                let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
                //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
                axios({
                  method: 'post',
                  headers: {
                    Authorization: 'JWT ' + props?.token,
                    x_firebase_id: props?.patientId,
                  },
                  url: url,
                  data: postData,
                })
                  .then(({ data }) => {
                    // Insert a link that allows the user to download the PDF file
                    var link = document.createElement('a')
                    link.innerHTML = 'Download PDF file'
                    link.download = `Patient Referral ${props?.patient?.firstName.charAt(
                      0,
                    )}${props?.patient?.lastName.charAt(0)} ${moment().format(
                      'MM/DD/YYYY',
                    )}.pdf`
                    link.href =
                      'data:application/octet-stream;base64,' + data.result
                    // document.body.appendChild(link);
                    link.click()
                    console.log('downloaded')
                    toast.success('pdf downloaded')
                  })
                  .catch((err) => {
                    console.log(
                      'R1. error when getting saving provider goals data',
                      err,
                    )
                    toast.error('Could not download Notes, please try again')
                  })
              }}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 120,
                top: 10,
              }}
              variant="primary"
            >
              Download
            </Button>
            <Button
              onClick={() => onSendPressed()}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 5,
                top: 10,
              }}
              variant="primary"
            >
              Submit
            </Button>
            <iframe
              srcDoc={pdfData}
              title="Provider Referral"
              style={{ height: '100%', width: '100%' }}
            />
          </Modal>
        )}

        {/* {showReferringToModal && ( */}
        <Modal
          ariaHideApp={false}
          isOpen={showReferringToModal}
          onRequestClose={() => setShowModal(false)}
          style={{
            content: {
              width: '1000px', // Set your desired width
              height: '300px', // Set your desired height
              margin: 'auto',
            },
          }}
          contentLabel="Modal"
        >
          {renderReferringToModal()}
          {/* <h3>Please verify the document and proceed to Fax</h3>
          <Button
            onClick={() => {
              let postData = {
                htmlContent: pdfData,
              }
              let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
              //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
              axios({
                method: 'post',
                headers: {
                  Authorization: 'JWT ' + props?.token,
                  x_firebase_id: props?.patientId,
                },
                url: url,
                data: postData,
              })
                .then(({ data }) => {
                  // Insert a link that allows the user to download the PDF file
                  var link = document.createElement('a')
                  link.innerHTML = 'Download PDF file'
                  link.download = `Patient Referral ${props?.patient?.firstName.charAt(
                    0,
                  )}${props?.patient?.lastName.charAt(0)} ${moment().format(
                    'MM/DD/YYYY',
                  )}.pdf`
                  link.href =
                    'data:application/octet-stream;base64,' + data.result
                  // document.body.appendChild(link);
                  link.click()
                  console.log('downloaded')
                  toast.success('pdf downloaded')
                })
                .catch((err) => {
                  console.log(
                    'error when getting saving provider goals data',
                    err,
                  )
                  toast.error('Could not download Notes, please try again')
                })
            }}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 120,
              top: 10,
            }}
            variant="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => onSendPressed()}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 5,
              top: 10,
            }}
            variant="primary"
          >
            Submit
          </Button> */}
          {/* <iframe
            srcDoc={pdfData}
            title="Provider Referral"
            style={{ height: '100%', width: '100%' }}
          /> */}
        </Modal>
        {/* )} */}

        {showSearchList && (
          <Modal
            ariaHideApp={false}
            isOpen={showSearchList}
            onRequestClose={() => {
              setShowSearchList(false)
              setSearchName('')
              setSearchList([])
            }}
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            {renderSearchList()}
          </Modal>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => setShowNext(false)}
            className={GlobalStyles.button}
            style={{
              width: 100,
              marginTop: 60,
              alignSelf: 'flex-start',
              visibility: showNext ? 'visible' : 'hidden',
            }}
            variant="primary"
          >
            Back
          </Button>

          <Button
            onClick={() => {
              if (!showNext) setShowNext(true)
              // else if (adminAccess)
              //   onAdminSubmitPressed() // Not working for admin
              else onSubmitPressed()
            }}
            className={GlobalStyles.button}
            style={{ width: 200, marginTop: 60, alignSelf: 'flex-end' }}
            variant="primary"
          >
            {/* {showNext ? 'Place Order' : adminAccess ? 'Place Order' : 'Next'} */}
            {showNext ? 'Place Order' : 'Next'}
          </Button>
        </div>
      </div>
    )
  }
}

export default Referrals
