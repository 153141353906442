import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import 'react-datepicker/dist/react-datepicker.css'
import DateTimePicker from 'react-datetime-picker'
import FancyField from 'react-fancy-field'
import { ArrowBackOutline } from 'react-ionicons'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { Link, useParams } from 'react-router-dom'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import AilaLogo from '../../assets/images/aila_logo.png'
import firebase from '../../services/firebase.js'
import values from '../../values'
import GlobalStyles from '../styles/global.module.scss'
import './rangeSlider.css'
import Styles from './styles/AilaWebQues.module.scss'
import Config from '../../config'
import Select from 'react-select'
import metadata, * as Metadata from '../../metadata.js'
// import metadata from '../../metadata.js'

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import axios from 'axios'

const conditionConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  // THIS IS THE ACTUAL CONDITIONS INDEX, DONT CHANGE
  engineName: 'conditions',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigConditions = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: conditionConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      object_id: { raw: {} },
      symptom: { raw: {} },
      description: { raw: {} },
      node: { raw: {} },
    },
  },
}

const medicationsConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'medications',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigMedications = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: medicationsConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      aila_id: { raw: {} },
      drug_name: { raw: {} },
      object_id: { raw: {} },
    },
  },
}

const vitaminsConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'vitamins',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigVitamins = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: vitaminsConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      object_id: { raw: {} },
      name: { raw: {} },
    },
  },
}

// const condnClient = {
//   search(requests) {
//     if (requests[0].params.query === '') {
//       return []
//     }
//     return searchClient.search(requests)
//   },
// }

const questionStyle = {
  marginTop: 80,
  fontSize: 20,
  wordWrap: 'break-word',
  overflow: 'hidden',
}

const triggerNames = [
  { name: 'Stress', isChecked: false },
  { name: 'Allergies', isChecked: false },
  { name: 'Injury', isChecked: false },
  { name: 'Surgery', isChecked: false },
  { name: 'Weather/ Air pressure', isChecked: false },
  { name: 'Physical exertion', isChecked: false },
  { name: 'Cold or other viral infection', isChecked: false },
  { name: 'Cigarette Smoke', isChecked: false },
  { name: 'Ultraviolet (UV) light exposure', isChecked: false },
  { name: 'Menstruation', isChecked: false },
  { name: 'Gluten', isChecked: false },
  { name: 'Dairy', isChecked: false },
  { name: 'Eggs', isChecked: false },
  { name: 'Nightshade Vegetables (e.g. eggplant)', isChecked: false },
  { name: 'Legumes/ Beans', isChecked: false },
  { name: 'Refined sugar or sweets', isChecked: false },
  { name: 'Alcohol', isChecked: false },
  { name: 'Caffeine', isChecked: false },
  { name: 'Lack of sleep', isChecked: false },
  { name: 'Other, Please specify', isChecked: false },
]

export default class PatientAssistanceForm extends Component {
  constructor() {
    super()
    this.state = {
      q0: '',
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: 1,
      q7: '',
      q8: '',
      q9: '',
      q10: triggerNames,
      q11: '',
      q12: '',
      q13: 1,
      q14: 1,
      q15: '',
      q16: '',
      q17: '',
      autoCondn: '',
      triggers: '',
      gender: '',
      ethnicity: '',
      error: '',
      errorString: '',
      name: localStorage.getItem('name') || '',
      email: localStorage.getItem('email') || '',
      phNo: localStorage.getItem('phNo') || '',
      loading: false,
      onComplete: false,
      earlySymp: [],
      mainSymp: [],
      medications: [],
      suplements: [],
      docId: '',
      searchQuery: {
        chronic: '',
        early: '',
        main: '',
        medi: '',
        sup: '',
      },
      drugs: [],
      cronicCondn: [],
      patientFirstName: '',
      patientMiddleName: '',
      patientLastName: '',
      patientGender: '',
      patientSSN: '',
      noSSN: false,
      patientHomeAddress: '',
      patientPhoneNumber: '',
      patientEmail: '',
      insuranceCompanyName: '',
      insuranceMemberId: '',
      insuranceGroupNumber: '',
      insurancePhoneNumber: '',
      relationShipToInsured: null,
      policyHolderFName: '',
      policyHolderLName: '',
      pharmacyInsurance: '',
      rxInsurancePhoneNumber: '',
      rxPolicyNumber: '',
      rxGroupNumber: '',
      rxPCNNumber: '',
      rxBINNumber: '',
      hasSecondaryInsurnace: null,
      patientCommercialOrPrivateInsurance: null,
      federalOrStateFundedProgram: null,
      medicarePartANumber: ""
    }
    this.handleMultipleOptions = this.handleMultipleOptions.bind(this)
    this.handleMultipleOptionsMedication =
      this.handleMultipleOptionsMedication.bind(this)
    this.handleSendEmail = this.handleSendEmail.bind(this)
  }

  componentDidMount() {
    // let { id } = useParams()
    // let { id, size } = this.props.match.params
    const { match } = this.props
    console.log('Match', match)
    const id = match.params.id
    console.log('The id is', id)
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}patient/paf`,
      params: {
        id: id,
      },
    }).then((result) => {
      if (!result?.data?.success) {
        // TODO: Alert the patient
        return
      }
      let data = result?.data?.data
      console.log('Data: ', data)

      this.setState({ data })
      this.setState({ drugs: [data?.order_details?.drug] })
      this.setState({ cronicCondn: data?.order_details?.icd_code })
    })
  }

  // handleSubmit(event) {
  //   event.preventDefault()
  //   const {
  //     email,
  //     phNo,
  //     name,
  //     ethnicity,
  //     q0,
  //     q1,
  //     q2,
  //     q3,
  //     q4,
  //     q5,
  //     q6,
  //     q7,
  //     q8,
  //     q9,
  //     q10,
  //     q11,
  //     q12,
  //     q13,
  //     q14,
  //     q15,
  //     q16,
  //     q17,
  //     cronicCondn,
  //     autoCondn,
  //     mainSymp,
  //     earlySymp,
  //     suplements,
  //     medications,
  //     triggers,
  //   } = this.state
  //   if (
  //     !q1.length ||
  //     !q2.length ||
  //     !q3.length ||
  //     !q6 ||
  //     !phNo.length ||
  //     !name.length ||
  //     !email.length
  //   ) {
  //     this.setState({
  //       error: true,
  //       errorString: 'Please Fill the Form',
  //     })
  //     return
  //   }

  //   if (moment().diff(q0, 'years') <= 17) {
  //     this.setState({
  //       error: true,
  //       errorString: 'You must be atleast 18 years of age to submit',
  //     })
  //     return
  //   }

  //   if (!email || email.length === 0 || !this.validateEmail(email)) {
  //     this.setState({
  //       error: true,
  //       errorString: 'Please enter a valid email address',
  //     })
  //     return
  //   }

  //   if (!phNo || phNo.length < 10 || phNo.length > 10) {
  //     this.setState({
  //       error: true,
  //       errorString: 'Please enter a valid phone number',
  //     })
  //     return
  //   }

  //   this.setState({ loading: true })

  //   var triggerList = []
  //   this.state.q10.forEach((val) => {
  //     if (val.isChecked) {
  //       if (val.name === 'Other, Please specify') {
  //         triggerList.push(triggers)
  //       } else {
  //         triggerList.push(val.name)
  //       }
  //     }
  //   })

  //   var data = {
  //     dob: q0,
  //     zipCode: q1,
  //     gender: q2,
  //     ethnicity: q3 === 'Other' ? ethnicity : q3,
  //     diagnosedAutoImmDisease: this.props.location.diseaseInfo,
  //     cronicCondn: cronicCondn,
  //     qualityLife: q6,
  //     timeDisease: q7,
  //     earlySymp: earlySymp,
  //     mainSymp: mainSymp,
  //     bigTrigger: triggerList,
  //     medication: medications,
  //     suplements: suplements,
  //     alternativeTreatments: q17,
  //     userName: name,
  //     email: email,
  //     phoneNumber: phNo,
  //     created_at: new Date(),
  //     emailSent: 'Not opt',
  //   }
  //   localStorage.setItem('name', name)
  //   localStorage.setItem('email', email)
  //   localStorage.setItem('phNo', phNo)

  //   let self = this
  //   firebase
  //     .firestore()
  //     .collection('aila_questionnaire')
  //     .add(data)
  //     .then((res) => {
  //       console.log('successfully Added data', res.id)
  //       this.setState({
  //         q0: '',
  //         q1: '',
  //         q2: '',
  //         q3: '',
  //         q4: this.props.match.params.diseaseName, // params passed from the Intro Screen
  //         q5: '',
  //         q6: 1,
  //         q7: '',
  //         q8: '',
  //         q9: '',
  //         q10: [
  //           { name: 'Stress', isChecked: false },
  //           { name: 'Allergies', isChecked: false },
  //           { name: 'Injury', isChecked: false },
  //           { name: 'Surgery', isChecked: false },
  //           { name: 'Weather/ Air pressure', isChecked: false },
  //           { name: 'Physical exertion', isChecked: false },
  //           { name: 'Cold or other viral infection', isChecked: false },
  //           { name: 'Cigarette Smoke', isChecked: false },
  //           { name: 'Ultraviolet (UV) light exposure', isChecked: false },
  //           { name: 'Menstruation', isChecked: false },
  //           { name: 'Gluten', isChecked: false },
  //           { name: 'Dairy', isChecked: false },
  //           { name: 'Eggs', isChecked: false },
  //           { name: 'Nightshade Vegetables (e.g. eggplant)', isChecked: false },
  //           { name: 'Legumes/ Beans', isChecked: false },
  //           { name: 'Refined sugar or sweets', isChecked: false },
  //           { name: 'Alcohol', isChecked: false },
  //           { name: 'Caffeine', isChecked: false },
  //           { name: 'Lack of sleep', isChecked: false },
  //           { name: 'Other, Please specify', isChecked: false },
  //         ],
  //         q11: '',
  //         q12: '',
  //         q13: 1,
  //         q14: 1,
  //         q15: '',
  //         q16: '',
  //         q17: '',
  //         autoCondn: '',
  //         triggers: '',
  //         gender: '',
  //         ethnicity: '',
  //         error: '',
  //         errorString: '',
  //         loading: false,
  //         onComplete: true,
  //         cronicCondn: [],
  //         earlySymp: [],
  //         mainSymp: [],
  //         medications: [],
  //         suplements: [],
  //         docId: res.id,
  //       })
  //     })
  //     .catch(function (error) {
  //       console.log('error::::::', error)
  //       self.setState({
  //         error: true,
  //         errorString: 'Error has occured while adding data',
  //         loading: false,
  //       })
  //     })
  // }

  validateEmail(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // to handle the change of slider values
  handleSliderOnChange = (value, state) => {
    switch (state) {
      case 'q6':
        this.setState({
          q6: value,
        })
        break
      case 'q13':
        this.setState({
          q13: value,
        })
        break
      case 'q14':
        this.setState({
          q14: value,
        })
        break

      default:
        break
    }
  }

  // Handle multiple option selection checkbox
  handleMultipleOptions(value) {
    let tempq = this.state.q10
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })
    this.setState({ q10: tempq })
  }

  handleMultipleOptionsMedication(value) {
    let tempq = this.state.medications
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })
    this.setState({ medications: tempq })
  }

  // updating the firestore emailSent field to trigger the background listener
  handleSendEmail({ fireStoreId }) {
    alert('Please check your email for the app link')
    firebase
      .firestore()
      .collection('aila_questionnaire')
      .doc(fireStoreId)
      .update({ emailSent: false })
  }

  render() {
    const horizontalLabels = {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
    }

    // to display the names of selected medication
    const placeHolderMedication = this.state.medications
      .map((data, index) => {
        if (index === 0) {
          return data.name
        } else {
          return `, ${data.name}`
        }
      })
      .join('')

    // to display the names of suplements
    const placeHolderSup = this.state.suplements
      .map((data, index) => {
        if (index === 0) {
          return data.name
        } else {
          return `, ${data.name}`
        }
      })
      .join('')

    // name of condition passed from the intro screen
    const diseaseName = this.props.match.params.diseaseName
    const questionSet = [
      'Date of Birth:',
      'Zip Code:',
      'What gender were you at birth:',
      'Have you been diagnosed with any of the following autoimmune diseases? (Select all that apply)',
      'Are you living with any other chronic conditions?',
      'Chronic illnesses impact everyone differently, how would you rate your current overall quality of life?',
      `When thinking about your ${diseaseName}, How long ago were you diagnosed?`,
      `What were your early symptoms of ${diseaseName}`,
      `What are your main symptoms of ${diseaseName} now?`,
      `What are the biggest triggers to your ${diseaseName} symptom flare ups?`,
      `What medications have you taken for your  ${diseaseName}?`,
      `What supplements have you taken for your  ${diseaseName}?`,
      `How effective was ${
        placeHolderMedication.length === 0
          ? 'medications'
          : placeHolderMedication
      } in managing your ${diseaseName}? on a scale of 1-10 (1 = Not at all effective and 10 = Most effective)`,
      `How effective was ${
        placeHolderSup.length === 0 ? 'supplements' : placeHolderSup
      } in managing your ${diseaseName}? on a scale of 1-10 (1 = Not at all effective and 10 = Most effective)`,
      `Did you experience any side effects of being on medications?`,
      'If you answered yes, what were they?',
      `Are there any alternative therapies or treatments you used to manage your ${diseaseName}?`,
    ]

    // to render after completion of the survey
    const OnSuccess = () => {
      const urlSocial = 'https://www.ailahealth.com/community-insights-survey'
      const socialTitle = `I am supporting the chronic illness community by sharing my experiences with the symptoms and treatments for my disease. Together we can figure out which treatments works best and share insights today! Click the link to support the chronic illness community.`
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            paddingTop: 40,
          }}
        >
          <img src={AilaLogo} style={{ height: 120, width: 120 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px 6px',
              borderRadius: 4,
              border: '1px solid gray',
              width: '90%',
              marginTop: 20,
            }}
          >
            <svg
              className={GlobalStyles.checkmark}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className={GlobalStyles.checkmark__circle}
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className={GlobalStyles.checkmark__check}
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <h5
              style={{
                marginTop: 10,
                fontSize: 20,
                textAlign: 'center',
                color: '#7e7e7e',
              }}
            >
              Thank you for completing the chronic illness survey!
            </h5>
            <div style={{ alignSelf: 'center', width: '70%', marginTop: 30 }}>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 'normal',
                  color: values.primaryThemeDark,
                  textAlign: 'center',
                }}
              >
                Once we have enough responses we will share the insights on a
                dashboard. Share this survey with other patients in your
                community so we can increase awareness of autoimmune diseases
                and develop collective insight on how to manage them.
              </p>
            </div>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <EmailShareButton
                url={urlSocial}
                subject={'Support the chronic illness community!'}
                body={socialTitle}
                openShareDialogOnClick={true}
                onClick={() => {}}
                // title={socialTitle}
              >
                <EmailIcon size={32} style={{ margin: 10 }} round />
              </EmailShareButton>

              <TwitterShareButton url={urlSocial} title={socialTitle}>
                <TwitterIcon size={32} style={{ margin: 10 }} round />
              </TwitterShareButton>

              <WhatsappShareButton url={urlSocial} title={socialTitle}>
                <WhatsappIcon size={32} style={{ margin: 10 }} round />
              </WhatsappShareButton>

              {/* <LinkedinShareButton
                url={urlSocial}
                title={socialTitle}
              >
                <LinkedinIcon
                  size={32}
                  style={{margin: 10}}
                  round />
              </LinkedinShareButton> */}

              <FacebookShareButton
                url={urlSocial}
                // title={socialTitle}
                quote={socialTitle}
                hashtag={'#Cronicillness'}
              >
                <FacebookIcon size={32} style={{ margin: 10 }} round />
              </FacebookShareButton>
            </div>
            <div style={{ alignSelf: 'center', width: '70%', marginTop: 30 }}>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 'normal',
                  color: values.primaryThemeDark,
                  textAlign: 'center',
                }}
              >
                If you want to connect with a community of chronic illness
                warriors and manage your health all in one place, download the
                Aila Health App
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 30,
              }}
            >
              <div
                style={{ textAlign: 'center', marginLeft: 10, marginRight: 10 }}
              >
                <Button
                  onClick={() =>
                    this.handleSendEmail({ fireStoreId: this.state.docId })
                  }
                  className={GlobalStyles.button}
                  disabled={this.state.loading}
                  style={{ marginTop: 40, width: 200 }}
                  variant="primary"
                >
                  Download App
                </Button>
              </div>
              <div
                style={{ textAlign: 'center', marginLeft: 10, marginRight: 10 }}
              >
                <Link to={'/wix/aila-questionnaire-intro'}>
                  <Button
                    className={GlobalStyles.button}
                    disabled={this.state.loading}
                    style={{ marginTop: 40, width: 200 }}
                    variant="primary"
                  >
                    Take another Survey
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }

    // to handle the Selection of symptoms, cronic, earlySymptoms, main symptoms, drugname and suplements from algolia
    const handleHitClick = (hit, q) => {
      if (q === 'q1') {
        this.setState({ q1: hit.symptom })
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.drugs.filter((data) => data.name !== hit.symptom)
          this.setState({ drugs: arr })
        } else {
          this.setState({
            drugs: [...this.state.drugs, { name: hit.symptom, id: hit.node }],
          })
        }
      } else if (q === 'q2') {
        this.setState({ q2: hit.symptom })
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.cronicCondn.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ cronicCondn: arr })
        } else {
          this.setState({
            cronicCondn: [
              ...this.state.cronicCondn,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      } else if (q === 'q8') {
        this.setState({ q8: hit.symptom })
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.earlySymp.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ earlySymp: arr })
        } else {
          this.setState({
            earlySymp: [
              ...this.state.earlySymp,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      } else if (q === 'q9') {
        this.setState({ q9: hit.symptom })
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.mainSymp.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ mainSymp: arr })
        } else {
          this.setState({
            mainSymp: [
              ...this.state.mainSymp,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      } else if (q === 'q11') {
        this.setState({ q11: hit.DrugName })
        if (handleChecked(q, hit.DrugName)) {
          let arr = this.state.medications.filter(
            (data) => data.name !== hit.DrugName,
          )
          this.setState({ medications: arr })
        } else {
          this.setState({
            medications: [
              ...this.state.medications,
              { name: hit.DrugName, id: hit.AILA_ID, isChecked: false },
            ],
          })
        }
      } else if (q === 'q12') {
        this.setState({ q12: hit.name })
        if (handleChecked(q, hit.name)) {
          let arr = this.state.suplements.filter(
            (data) => data.name !== hit.name,
          )
          this.setState({ suplements: arr })
        } else {
          this.setState({
            suplements: [
              ...this.state.suplements,
              { name: hit.name, id: hit.objectID },
            ],
          })
        }
      }
    }

    // to handle the list of selected conditions from algolia
    const handleChecked = (q, name) => {
      if (q === 'q1') {
        return this.state.drugs.some((data) => data.name === name)
      } else if (q === 'q2') {
        return this.state.cronicCondn.some((data) => data.name === name)
      } else if (q === 'q8') {
        return this.state.earlySymp.some((data) => data.name === name)
      } else if (q === 'q9') {
        return this.state.mainSymp.some((data) => data.name === name)
      } else if (q === 'q11') {
        return this.state.medications.some((data) => data.name === name)
      } else if (q === 'q12') {
        return this.state.suplements.some((data) => data.name === name)
      }
    }

    // to display the list of results in algolia searchbox
    // const Hit = (props, q, type) => {
    //   let { hit } = props
    //   let checked = handleChecked(q, hit.symptom)
    //   return (
    //     <div
    //       style={{ width: '100%', cursor: 'pointer' }}
    //       onClick={() => {
    //         handleHitClick(hit, q)
    //       }}
    //     >
    //       {hit.symptom}
    //     </div>
    //   )
    // }

    // to display the list of results in algolia searchbox
    // const HitMedications = (props, q) => {
    //   let { hit } = props
    //   let checked = handleChecked(q, hit.DrugName)
    //   return (
    //     <div
    //       style={{ width: '100%', cursor: 'pointer' }}
    //       onClick={() => {
    //         handleHitClick(hit, q)
    //       }}
    //     >
    //       {hit.DrugName}
    //     </div>
    //   )
    // }

    // to display the list of results in algolia searchbox
    // const HitSuplements = (props, q) => {
    //   let { hit } = props
    //   let checked = handleChecked(q, hit.name)
    //   return (
    //     <div
    //       style={{ width: '100%', cursor: 'pointer' }}
    //       onClick={() => {
    //         handleHitClick(hit, q)
    //       }}
    //     >
    //       {hit.name}
    //     </div>
    //   )
    // }

    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          margin: '0 auto',
          backgroundColor: 'white',
        }}
      >
        {this.state.onComplete ? (
          // render after completion of survey
          <OnSuccess />
        ) : (
          <div className={Styles.wrapper}>
            {this.state.loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: 200,
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '90%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    marginTop: 10,
                    alignSelf: 'flex-start',
                  }}
                >
                  <Link to={'/wix/aila-questionnaire-intro'}>
                    <ArrowBackOutline
                      color={'#066D6F'}
                      height="30px"
                      width="30px"
                    />
                  </Link>
                </div>

                <p
                  style={{
                    fontSize: 40,
                    marginTop: 20,
                    fontWeight: 'bold',
                    color: values.primaryThemeDark,
                    fontFamily: 'Avenir-Heavy',
                  }}
                >
                  Patient Assistance
                </p>
                <div
                  style={{ marginTop: 30, width: '70%', textAlign: 'center' }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      color: values.primaryThemeDark,
                      fontFamily: 'Avenir',
                      marginTop: 10,
                    }}
                  >
                    Patient may be entitled to some patient assistance/
                    financial support for medications. Please complete form:
                  </p>
                </div>

                <div className={Styles.questionContainer}>
                  {/* Q1 */}
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Who is applying?
                    </p>
                    <Form.Group
                      controlId="ethnicity"
                      style={{ margin: 10, marginLeft: 20 }}
                    >
                      {[
                        'Patient',
                        'Legally Authorized person on behalf of the patient.',
                        'Healthcare Provider',
                        'Specialty Pharmacy Representatives',
                        // 'Other',
                      ].map((type) => (
                        <Form.Check
                          type={'radio'}
                          label={type}
                          value={type}
                          onClick={(val) => {
                            console.log('Setting q0 to', val.target.value)
                            this.setState({ q0: val.target.value })
                          }}
                          style={{
                            color: 'rgb(23,61,68)',
                            fontFamily: 'Avenir',
                          }}
                          checked={this.state.q0 === type}
                          classes={Styles.radioButton}
                        />
                      ))}
                      {/* This case does not exist for now */}
                      {/* {this.state.q3 === 'Other' && (
                        <div style={{ width: '30%' }}>
                          <FancyField
                            value={this.state.ethnicity}
                            classes={Styles.textInput2}
                            label="Ethnicity"
                            required={false}
                            name="ethnicity"
                            onChange={(val) =>
                              this.setState({ ethnicity: val })
                            }
                            placeholder="Enter your Ethnicity"
                          />
                        </div>
                      )} */}
                    </Form.Group>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      What is the patient assistance program you are applying
                      for?
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Form.Group
                        controlId="cronic condn"
                        style={{ margin: 10, marginLeft: 20, width: '40%' }}
                      >
                        <SearchProvider config={esConfigConditions}>
                          <WithSearch
                            mapContextToProps={({
                              wasSearched,
                              setSearchTerm,
                            }) => ({
                              wasSearched,
                              setSearchTerm,
                            })}
                          >
                            {({ wasSearched, setSearchTerm }) => {
                              // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                              return (
                                <div className="App">
                                  <EsSearchBox
                                    debounceLength={0}
                                    autocompleteResults={{
                                      titleField: 'symptom',
                                    }}
                                    searchAsYouType={true}
                                    onSelectAutocomplete={(e) => {
                                      // Q5
                                      this.setState({ q1: e.symptom.raw })
                                      if (handleChecked('q1', e.symptom.raw)) {
                                        let arr = this.state.drugs.filter(
                                          (data) => data.name !== e.symptom.raw,
                                        )
                                        this.setState({ drugs: arr })
                                      } else {
                                        this.setState({
                                          drugs: [
                                            ...this.state.drugs,
                                            {
                                              name: e.symptom.raw,
                                              id: e.node.raw,
                                            },
                                          ],
                                        })
                                      }
                                      setSearchTerm('')
                                    }}
                                    inputView={({
                                      getAutocomplete,
                                      getInputProps,
                                      getButtonProps,
                                    }) => (
                                      <>
                                        <div className="sui-search-box__wrapper">
                                          <input
                                            {...getInputProps({
                                              placeholder: 'Search Drugs',
                                            })}
                                          />
                                          {getAutocomplete()}
                                        </div>
                                        {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                                      </>
                                    )}
                                  />
                                </div>
                              )
                            }}
                          </WithSearch>
                        </SearchProvider>
                      </Form.Group>
                      {this.state.drugs.length !== 0 && (
                        <div
                          style={{
                            fontSize: 15,
                            marginTop: 20,
                            fontWeight: 'normal',
                            color: values.primaryThemeDark,
                            marginLeft: 30,
                          }}
                        >
                          Added:
                          <br />{' '}
                          {this.state.drugs.map((data, index) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  marginTop: 10,
                                }}
                              >
                                <p>
                                  {index + 1}. {data.name + ' '}
                                </p>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className={Styles.deleteIcon}
                                  onClick={() =>
                                    handleHitClick({ symptom: data.name }, 'q1')
                                  }
                                />
                                <br />
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      What is the diagnosis/reason for applying?
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Form.Group
                        controlId="cronic condn"
                        style={{ margin: 10, marginLeft: 20, width: '40%' }}
                      >
                        <SearchProvider config={esConfigConditions}>
                          <WithSearch
                            mapContextToProps={({
                              wasSearched,
                              setSearchTerm,
                            }) => ({
                              wasSearched,
                              setSearchTerm,
                            })}
                          >
                            {({ wasSearched, setSearchTerm }) => {
                              // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                              return (
                                <div className="App">
                                  <EsSearchBox
                                    debounceLength={0}
                                    autocompleteResults={{
                                      titleField: 'symptom',
                                    }}
                                    searchAsYouType={true}
                                    onSelectAutocomplete={(e) => {
                                      // Q5
                                      this.setState({ q2: e.symptom.raw })
                                      if (handleChecked('q2', e.symptom.raw)) {
                                        let arr = this.state.cronicCondn.filter(
                                          (data) => data.name !== e.symptom.raw,
                                        )
                                        this.setState({ cronicCondn: arr })
                                      } else {
                                        this.setState({
                                          cronicCondn: [
                                            ...this.state.cronicCondn,
                                            {
                                              name: e.symptom.raw,
                                              id: e.node.raw,
                                            },
                                          ],
                                        })
                                      }
                                      setSearchTerm('')
                                    }}
                                    inputView={({
                                      getAutocomplete,
                                      getInputProps,
                                      getButtonProps,
                                    }) => (
                                      <>
                                        <div className="sui-search-box__wrapper">
                                          <input
                                            {...getInputProps({
                                              placeholder: 'Search Diagnosis',
                                            })}
                                          />
                                          {getAutocomplete()}
                                        </div>
                                        {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                                      </>
                                    )}
                                  />
                                </div>
                              )
                            }}
                          </WithSearch>
                        </SearchProvider>
                      </Form.Group>
                      {this.state.cronicCondn.length !== 0 && (
                        <div
                          style={{
                            fontSize: 15,
                            marginTop: 20,
                            fontWeight: 'normal',
                            color: values.primaryThemeDark,
                            marginLeft: 30,
                          }}
                        >
                          Added:
                          <br />{' '}
                          {this.state.cronicCondn.map((data, index) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  marginTop: 10,
                                }}
                              >
                                <p>
                                  {index + 1}. {data.name + ' '}
                                </p>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className={Styles.deleteIcon}
                                  onClick={() =>
                                    handleHitClick({ symptom: data.name }, 'q2')
                                  }
                                />
                                <br />
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Have you already enrolled in the copay assistance program
                      for this drug?
                    </p>
                    <Form.Group
                      controlId="gender"
                      style={{ margin: 10, marginLeft: 20 }}
                    >
                      {['Yes', 'No'].map((type) => (
                        <Form.Check
                          type={'radio'}
                          label={type}
                          value={type}
                          onClick={(val) =>
                            this.setState({ q2: val.target.value })
                          }
                          style={{
                            color: 'rgb(23,61,68)',
                            fontFamily: 'Avenir',
                          }}
                          checked={this.state.q2 === type}
                          classes={Styles.radioButton}
                        />
                      ))}
                    </Form.Group>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Patient Name
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.patientFirstName}
                        classes={Styles.textInput2}
                        label="fname"
                        required={false}
                        name="fname"
                        onChange={(val) =>
                          this.setState({ patientFirstName: val })
                        }
                        placeholder="First Name"
                      />
                      <FancyField
                        value={this.state.patientMiddleName}
                        classes={Styles.textInput2}
                        label="mname"
                        required={false}
                        name="mname"
                        onChange={(val) =>
                          this.setState({ patientMiddleName: val })
                        }
                        placeholder="Middle Name"
                      />
                      <FancyField
                        value={this.state.patientLastName}
                        classes={Styles.textInput2}
                        label="lname"
                        required={false}
                        name="lname"
                        onChange={(val) => {
                          if (val.length <= 10) {
                            this.setState({ patientLastName: val })
                          }
                        }}
                        placeholder="Last Name"
                      />
                    </Form.Group>
                  </div>
                  {/* <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      {questionSet[0]}
                    </p>
                    <Form.Group
                      controlId="date"
                      style={{ margin: 10, marginLeft: 0, width: '30%' }}
                    >
                      <DateTimePicker
                        format={'MMM-dd-y'}
                        onChange={(val) => this.setState({ q0: val })}
                        value={this.state.q0}
                      />
                    </Form.Group>
                  </div> */}
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Gender at Birth
                    </p>
                    <Form.Group
                      controlId="gender"
                      style={{ margin: 10, marginLeft: 20 }}
                    >
                      {['Male', 'Female'].map((type) => (
                        <Form.Check
                          type={'radio'}
                          label={type}
                          value={type}
                          onClick={(val) =>
                            this.setState({ patientGender: val.target.value })
                          }
                          style={{
                            color: 'rgb(23,61,68)',
                            fontFamily: 'Avenir',
                          }}
                          checked={this.state.patientGender === type}
                          classes={Styles.radioButton}
                        />
                      ))}
                    </Form.Group>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Social Security Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.patientSSN}
                        classes={Styles.textInput2}
                        label="ssn"
                        required={false}
                        name="ssn"
                        onChange={(val) => this.setState({ patientSSN: val })}
                        placeholder="SSN"
                      />
                    </Form.Group>
                    {/* {[
                      {
                        name: 'Do not have a social security number',
                        isChecked: false,
                      },
                    ].map((type) => {
                      return ( */}
                    <>
                      <label class={Styles.checkContainer}>
                        {/* {type.name} */}
                        Do not have a social security number
                        <input
                          type="checkbox"
                          checked={this.state.noSSN}
                          onClick={() => {
                            // console.log('clocekd')
                            // this.handleMultipleOptions(type.name)
                            let { noSSN } = this.state
                            this.setState({ noSSN: !noSSN })
                          }}
                        />
                        <span class={Styles.checkmark}></span>
                      </label>
                    </>
                    {/* )
                    })} */}
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Home/ Shipping address
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.patientHomeAddress}
                        classes={Styles.textInput2}
                        label="patientHomeAddress"
                        required={false}
                        name="patientHomeAddress"
                        onChange={(val) =>
                          this.setState({
                            patientHomeAddress: val,
                            error: false,
                          })
                        }
                        placeholder="Home/ Shipping address"
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Phone Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.patientPhoneNumber}
                        classes={Styles.textInput2}
                        label="patientPhoneNumber"
                        required={false}
                        name="patientPhoneNumber"
                        onChange={(val) =>
                          this.setState({
                            patientPhoneNumber: val,
                            error: false,
                          })
                        }
                        placeholder="Phone Number"
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Email Address
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.patientEmail}
                        classes={Styles.textInput2}
                        label="patientEmail"
                        required={false}
                        name="patientEmail"
                        onChange={(val) =>
                          this.setState({ patientEmail: val, error: false })
                        }
                        placeholder="Email address"
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Insurnace Company Name
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.insuranceCompanyName}
                        classes={Styles.textInput2}
                        label="insuranceCompanyName"
                        required={false}
                        name="insuranceCompanyName"
                        onChange={(val) =>
                          this.setState({
                            insuranceCompanyName: val,
                            error: false,
                          })
                        }
                        placeholder="Insurance Company Name"
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Member Subscriber Id
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.insuranceMemberId}
                        classes={Styles.textInput2}
                        label="insuranceMemberId"
                        required={false}
                        name="insuranceMemberId"
                        onChange={(val) =>
                          this.setState({
                            insuranceMemberId: val,
                            error: false,
                          })
                        }
                        placeholder="Member Subscriber Id"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Group Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.insuranceGroupNumber}
                        classes={Styles.textInput2}
                        label="insuranceGroupNumber"
                        required={false}
                        name="insuranceGroupNumber"
                        onChange={(val) =>
                          this.setState({
                            insuranceGroupNumber: val,
                            error: false,
                          })
                        }
                        placeholder="Group Number"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Insurnace Company Phone Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.insurancePhoneNumber}
                        classes={Styles.textInput2}
                        label="insurancePhoneNumber"
                        required={false}
                        name="insurancePhoneNumber"
                        onChange={(val) =>
                          this.setState({
                            insurancePhoneNumber: val,
                            error: false,
                          })
                        }
                        placeholder="Insurance Company Phone Number"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Relationship to Insured
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <Select
                        inputId="eligibilityRelationship"
                        options={metadata.RELATIONSHIP_OPTIONS}
                        value={this.state.relationShipToInsured}
                        autoBlur
                        onChange={(val) => {
                          this.setState({ relationShipToInsured: val })
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#20A89233',
                            primary: '#20A892',
                          },
                        })}
                      />
                      {/* <FancyField
                        value={this.state.name}
                        classes={Styles.textInput2}
                        label="ssn"
                        required={false}
                        name="ssn"
                        onChange={(val) =>
                          this.setState({ name: val, error: false })
                        }
                        placeholder=""
                      /> */}
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Policy Holder Name
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.policyHolderFName}
                        classes={Styles.textInput2}
                        label="policyHolderFName"
                        required={false}
                        name="policyHolderFName"
                        onChange={(val) =>
                          this.setState({
                            policyHolderFName: val,
                            error: false,
                          })
                        }
                        placeholder="First Name"
                      />

                      <FancyField
                        value={this.state.policyHolderLName}
                        classes={Styles.textInput2}
                        label="policyHolderLName"
                        required={false}
                        name="policyHolderLName"
                        onChange={(val) =>
                          this.setState({
                            policyHolderLName: val,
                            error: false,
                          })
                        }
                        placeholder="Last Name"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Pharmacy Insurance (if different)
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.pharmacyInsurance}
                        classes={Styles.textInput2}
                        label="pharmacyInsurance"
                        required={false}
                        name="pharmacyInsurance"
                        onChange={(val) =>
                          this.setState({
                            pharmacyInsurance: val,
                            error: false,
                          })
                        }
                        placeholder="Insurance Company Name"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Rx Insurance Company Phone Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.rxInsurancePhoneNumber}
                        classes={Styles.textInput2}
                        label="rxInsurancePhoneNumber"
                        required={false}
                        name="rxInsurancePhoneNumber"
                        onChange={(val) =>
                          this.setState({
                            rxInsurancePhoneNumber: val,
                            error: false,
                          })
                        }
                        placeholder="Insurance Company Name"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Rx Policy Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.rxPolicyNumber}
                        classes={Styles.textInput2}
                        label="rxPolicyNumber"
                        required={false}
                        name="rxPolicyNumber"
                        onChange={(val) =>
                          this.setState({ rxPolicyNumber: val, error: false })
                        }
                        placeholder="Insurance Company Name"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Rx Group
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.rxGroupNumber}
                        classes={Styles.textInput2}
                        label="rxGroupNumber"
                        required={false}
                        name="rxGroupNumber"
                        onChange={(val) =>
                          this.setState({ rxGroupNumber: val, error: false })
                        }
                        placeholder="Insurance Company Name"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Rx PCN Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.rxPCNNumber}
                        classes={Styles.textInput2}
                        label="rxPCNNumber"
                        required={false}
                        name="rxPCNNumber"
                        onChange={(val) =>
                          this.setState({ rxPCNNumber: val, error: false })
                        }
                        placeholder="Insurance Company Name"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Rx BIN Number
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.rxBINNumber}
                        classes={Styles.textInput2}
                        label="rxBINNumber"
                        required={false}
                        name="rxBINNumber"
                        onChange={(val) =>
                          this.setState({ rxBINNumber: val, error: false })
                        }
                        placeholder="Insurance Company Name"
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Does the patient have secondary insurance?
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <Select
                        placeholder={'Yes/No'}
                        isSearchable={true}
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        getOptionValue={(option) => option.label}
                        value={this.state.hasSecondaryInsurnace}
                        onChange={(val) => {
                          this.setState({ hasSecondaryInsurnace: val })
                        }}
                        // theme={(theme) => selectTheme(theme)}
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Is the patient on commercial, or private, insurance? (This
                      includes insurance from an employer and non-government
                      funded insurance purchased from a health insurance
                      marketplace.)*
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <Select
                        placeholder={'Yes/No'}
                        isSearchable={true}
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        getOptionValue={(option) => option.label}
                        value={this.state.patientCommercialOrPrivateInsurance}
                        onChange={(val) => {
                          this.setState({
                            patientCommercialOrPrivateInsurance: val,
                          })
                        }}
                        // theme={(theme) => selectTheme(theme)}
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Is the patient using a federal or state-funded insurance
                      program? (This includes, but is not limited to, Medicare,
                      Medicaid, Medigap, VA, DoD and TRICARE.)*
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <Select
                        placeholder={'Yes/No'}
                        isSearchable={true}
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        getOptionValue={(option) => option.label}
                        value={this.state.federalOrStateFundedProgram}
                        onChange={(val) => {
                          this.setState({
                            federalOrStateFundedProgram: val,
                          })
                        }}
                        // theme={(theme) => selectTheme(theme)}
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <p className={Styles.questionsFont} style={questionStyle}>
                      Please provide patient’s Medicare Part A Identification
                      Number (If applicable):
                    </p>
                    <Form.Group
                      controlId="otherTreatments"
                      style={{ margin: 10, marginLeft: 0 }}
                    >
                      <FancyField
                        value={this.state.medicarePartANumber}
                        classes={Styles.textInput2}
                        label="medicarePartANumber"
                        required={false}
                        name="medicarePartANumber"
                        onChange={(val) =>
                          this.setState({ medicarePartANumber: val, error: false })
                        }
                        placeholder="Medicare Part A Identification Number"
                      />
                    </Form.Group>
                  </div>
                </div>

                <p
                  style={{
                    marginTop: 20,
                    fontSize: 12,
                    color: 'red',
                    visibility: this.state.error ? 'visible' : 'hidden',
                  }}
                >
                  {this.state.errorString}
                </p>

                <Button
                  // onClick={this.handleSubmit.bind(this)}
                  className={GlobalStyles.button}
                  disabled={this.state.loading}
                  style={{ marginTop: 40, width: 200 }}
                  variant="primary"
                >
                  {this.state.loading ? 'Loading....' : 'Submit'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
