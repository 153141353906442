import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/PracticeInfo.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import * as Metadata from '../../metadata.js'
import { toast } from 'react-toastify'
import moment from 'moment'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  addPatient,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
} from '../../redux/actions'
import { connectSortBy } from 'react-instantsearch-dom'

const axios = require('axios')

const DENIAL_REASONS = [
  { label: 'Patient Type Issue', value: 'patient_type_issue' },
  { label: 'CPT Code Issue', value: 'cpt_code_issue' },
  { label: 'ICD Code Issue', value: 'icd_code_issue' },
  { label: 'Timely filing', value: 'timely_filing' },
  { label: 'Place of Service Issue', value: 'place_of_service_issue' },
  { label: 'Modifier Issue', value: 'modifier_issue' },
  { label: 'Lack of Medical Necessity', value: 'lack_of_medical_necessity' },
  { label: 'Need authorization', value: 'need_authorization' },
  { label: 'Out of Network', value: 'out_of_network' },
  { label: 'Not Covered Under Plan/ Not Eligible', value: 'not_eligible' },
  { label: 'Has not met deductible', value: 'not_met_deductible' },
  {
    label: 'Charge Exceeds Maximum for Contracted Rate',
    value: 'charges_exceeds_max_contracted_rate',
  },
  { label: 'Wrong Payer ID', value: 'incorrect_payer_id' },
  { label: 'Invalid Insured Information', value: 'invalid_insurance' },
  { label: 'Invalid Subscriber ID', value: 'invalid_subscriber_id' },
  { label: 'Insurance Coverage Expired', value: 'insurance_expired' },
  { label: 'Entity/subscriber not found', value: 'subscriber_not_found' },
  { label: 'Duplicate Claim', value: 'duplicate_claim' },
  { label: 'Timely Filing Deadline', value: 'timely_filing_deadline' },
]

class PaymentEditModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      invalidInput: false,

      // Edit modal fields
      totalChargesBilled: this.props.totalChargesBilled || '',
      allowedAmount: this.props.allowedAmount || '',
      adjustmentAmount: this.props.adjustmentAmount || '',
      paidAmount: this.props.paidAmount || '',
      checkNumber: this.props.checkNumber || '',
      claimNumber: this.props.claimNumber || '',
      denialReason: this.props.denialReason || '',
      denialReasons: this.props.denialReasons || [],
      payerPhoneNumber: this.props.payerPhoneNumber || '',
      claimStatus: this.props.claimStatus || null,
      writeOff: this.props.writeOff || false,
      writeOffAmount: this.props.writeOffAmount || '',
      writeOffReason: this.props.writeOffReason || '',
      ifCareplanClaim: this.props.ifCareplanClaim || false,
      dueAmount: this.props.dueAmount || '',
    }

    //
    this.updateSave = this.updateSave.bind(this)
    // this.removeSpecialCharacters = this.removeSpecialCharacters.bind(this)
  }

  componentDidMount() {
    // if (!this.props.loggedIn) {
    //   this.props.history.push('/login')
    //   return
    // }
    // this.setState({ loading: true })
  }

  componentDidUpdate(prevProps, prevState) {
    // check when the claims data is loaded
    // if (prevState?.claims !== this.state.claims) {
    //   console.log('CLAIMS DATA CHANGED')
    // }
    if (
      prevState.totalChargesBilled != this.state.totalChargesBilled ||
      prevState.paidAmount != this.state.paidAmount ||
      // prevState.adjustmentAmount != this.state.adjustmentAmount ||
      prevState.allowedAmount != this.state.allowedAmount
      // prevState.totalChargesBilled != this.state.totalChargesBilled ||
      // prevState.totalChargesBilled != this.state.totalChargesBilled ||
    ) {
      console.log('Modal updated!')
      let { totalChargesBilled, paidAmount, adjustmentAmount, allowedAmount } =
        this.state
      totalChargesBilled = totalChargesBilled || 0
      paidAmount = paidAmount || 0
      adjustmentAmount = adjustmentAmount || 0

      if (totalChargesBilled && allowedAmount) {
        adjustmentAmount = totalChargesBilled - allowedAmount
        adjustmentAmount = adjustmentAmount.toFixed(2)
      }

      let dueAmount =
        Number(totalChargesBilled) -
        Number(paidAmount) -
        Number(adjustmentAmount)
      dueAmount = Number(dueAmount).toFixed(2)
      if (this.props.editModalDataLoaded) {
        this.setState({ dueAmount, adjustmentAmount })
      }
    }
  }

  removeSpecialCharacters = (str) => {
    // Use a regular expression to remove "$" and whitespace characters
    // return str.replace(/[$\s]/g, '')
    // Only numbers and dot
    let number = str.replace(/[^0-9.]/g, '')
    console.log('The number after cleansing is:', number)
    if (Number(number) || number == 0) {
      this.setState({ invalidInput: false })
      return number
    } else {
      toast.error('The entered number is Invalid!')
      this.setState({ invalidInput: true })
    }
  }

  updateSave = async () => {
    if (this.state.invalidInput) {
      toast.error('There is an error in the input data, please review!')
      return
    }
    let {
      totalChargesBilled,
      allowedAmount,
      adjustmentAmount,
      paidAmount,
      checkNumber,
      claimStatus,
      claimNumber,
      denialReasons,
      payerPhoneNumber,
      //   editRowEventId,
      dueAmount,
      writeOff,
      writeOffAmount,
      writeOffReason,
      ifCareplanClaim,
    } = this.state
    let { token, editRowEventId } = this.props

    // Check if data is valid and update if it contains $ or white space chars
    // totalChargesBilled = this.removeSpecialCharacters(totalChargesBilled)
    // allowedAmount = this.removeSpecialCharacters(allowedAmount)
    // adjustmentAmount = this.removeSpecialCharacters(adjustmentAmount)
    // paidAmount = this.removeSpecialCharacters(paidAmount)
    // dueAmount = this.removeSpecialCharacters(dueAmount)
    // writeOffAmount = this.removeSpecialCharacters(writeOffAmount)

    console.log('Saving the data to backend!')

    console.log(
      totalChargesBilled,
      allowedAmount,
      adjustmentAmount,
      paidAmount,
      checkNumber,
      claimStatus,
      claimNumber,
    )
    console.log(
      denialReasons,
      payerPhoneNumber,
      editRowEventId,
      dueAmount,
      writeOff,
      writeOffAmount,
      writeOffReason,
      ifCareplanClaim,
    )

    let toSaveData = {
      billed_amount: totalChargesBilled,
      allowed_amount: allowedAmount,
      adjustment_amount: adjustmentAmount,
      paid_amount: paidAmount,
      check_number: checkNumber,
      event_id: editRowEventId,
      claim_number: claimNumber,
      claim_status: claimStatus?.value,
      payer_phone_number: payerPhoneNumber,
      denial_reasons: denialReasons,
      due_amount: dueAmount,
      if_write_off: writeOff,
      write_off_amount: writeOffAmount,
      write_off_reason: writeOffReason,
      updated_at: moment().valueOf(),
      if_careplan_claim: ifCareplanClaim,
    }

    console.log('Saving the data to backend!', toSaveData)

    axios({
      method: 'patch',
      url: `${Config.BACKEND_URL}payments/update`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data: toSaveData,
    }).then(({ data }) => {
      console.log('response', data)
      if (data.success) {
        toast.success('Updated!')
        // this.getInsuranceClaims()
        this.props.updatePaymentData()
      }
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '90%',
          width: '90%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          style={{ padding: '4px 20px' }}
          onClick={this.props.closeEditModal}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Fields
        </h4>
        <div
          style={{
            width: '600px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Total Charges Billed
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={'Total Charges Billed'}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.totalChargesBilled}
                onChange={(e) => {
                  let totalChargesBilled = this.removeSpecialCharacters(
                    e.target.value,
                  )
                  console.log('total charged', totalChargesBilled)
                  // this.setState({ totalChargesBilled: e.target.value })
                  this.setState({ totalChargesBilled: totalChargesBilled })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Allowed Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.allowedAmount}
                onChange={(e) => {
                  console.log('E', e.target.value)
                  let allowedAmount = this.removeSpecialCharacters(
                    e.target.value,
                  )
                  console.log('total allowed', allowedAmount)
                  this.setState({ allowedAmount: allowedAmount })
                  // this.setState({ allowedAmount: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Adjustment Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.adjustmentAmount}
                onChange={(e) => {
                  let adjustmentAmount = this.removeSpecialCharacters(
                    e.target.value,
                  )
                  this.setState({ adjustmentAmount: adjustmentAmount })
                  // this.setState({ adjustmentAmount: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Ins.Paid Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paidAmount}
                onChange={(e) => {
                  // console.log('e', e)

                  let paidAmount = this.removeSpecialCharacters(e.target.value)
                  this.setState({ paidAmount: paidAmount })
                  // this.setState({ paidAmount: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Amount Due
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.dueAmount}
                onChange={(e) => {
                  // console.log('e', e)

                  let dueAmount = this.removeSpecialCharacters(e.target.value)
                  this.setState({ dueAmount: dueAmount })
                  // this.setState({ dueAmount: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Check Number
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.checkNumber}
                onChange={(e) => {
                  this.setState({ checkNumber: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Claim Number
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.claimNumber}
                onChange={(e) => {
                  this.setState({ claimNumber: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Denial Reason(s)
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <Select
                placeholder="Denial Reason(s)"
                inputId="name"
                options={DENIAL_REASONS}
                value={this.state.denialReason}
                isSearchable
                autoBlur
                onChange={(val) => {
                  let { denialReasons } = this.state
                  denialReasons.push(val)
                  this.setState({ denialReasons, denialReason: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalSelect}
            style={{ flex: 1, width: '100%' }}
          >
            {this.state.denialReasons.map((reason, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  backgroundColor: '#e8e8e8',
                  borderRadius: 20,
                  padding: 20,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>{reason.label}</p>
                <FontAwesomeIcon
                  style={{
                    cursor: 'pointer',
                  }}
                  icon={faTrash}
                  className={Styles.deleteIcon}
                  onClick={() => {
                    console.log('Delete', reason)
                    let { denialReasons } = this.state
                    let newDenialReasons = denialReasons.filter(
                      (r) => r.value !== reason.value,
                    )
                    console.log('newDR', newDenialReasons)
                    this.setState({ denialReasons: newDenialReasons })
                  }}
                />
              </div>
            ))}
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Claim Resolution Phone
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.payerPhoneNumber}
                onChange={(e) => {
                  this.setState({ payerPhoneNumber: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Claim Status
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <Select
                placeholder="Claim Status"
                inputId="name"
                options={Metadata.BILLING_STATUS_OPTIONS}
                value={this.state.claimStatus}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ claimStatus: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Mark as write off?
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                type="checkbox"
                checked={this.state.writeOff}
                onClick={() => {
                  let { writeOff } = this.state
                  this.setState({ writeOff: !writeOff })
                }}
                style={{ transform: 'scale(1.5)' }}
              />
            </div>
          </div>
          {this.state.writeOff ? (
            <>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Write-off Amount
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <input
                    placeholder={''}
                    className={Styles.textInput}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.writeOffAmount}
                    onChange={(e) => {
                      let writeOffAmount = this.removeSpecialCharacters(
                        e.target.value,
                      )
                      this.setState({ writeOffAmount: writeOffAmount })
                      // this.setState({ writeOffAmount: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Write-off reason
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <textarea
                    rows="3"
                    placeholder={'Write off reason'}
                    className={Styles.modalInputStyle}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      marginTop: '10px',
                      color: 'black',
                      fontSize: '18px',
                      width: '100%',
                    }}
                    type="text"
                    value={this.state.writeOffReason}
                    onChange={(e) => {
                      this.setState({ writeOffReason: e.target.value })
                    }}
                  />
                  {/* <input
                      placeholder={''}
                      className={Styles.textInput}
                      type="text"
                      style={{
                        width: '100%',
                      }}
                      value={this.state.writeOffReason}
                      onChange={(e) => {
                        this.setState({ writeOffReason: e.target.value })
                      }}
                    /> */}
                </div>
              </div>
            </>
          ) : null}
          {/*  */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center content horizontally
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => {
                this.updateSave()
                this.props.updatePaymentData()
              }}
              className={GlobalStyles.button}
              disabled={false}
              style={{
                width: 250,
                // marginLeft: 10,
                backgroundColor: Constants.primaryTheme,
                textAlign: 'center',
              }}
              variant="primary"
              color={Constants.primaryTheme}
              backgroundColor={Constants.primaryTheme}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentEditModal)
