import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/PracticeInfo.module.scss'
import Constants from '../../values.js'
import Config from '../../config.js'
import * as Metadata from '../../metadata.js'
import { toast } from 'react-toastify'
import moment from 'moment'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  addPatient,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
} from '../../redux/actions.js'

const axios = require('axios')
const MAX_UNRESTRICTED_CHARGE = 1000

class PaymentChargeModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      invalidInput: false,

      // Edit modal fields
      totalChargesBilled: this.props.totalChargesBilled || '',
      allowedAmount: this.props.allowedAmount || '',
      adjustmentAmount: this.props.adjustmentAmount || '',
      paidAmount: this.props.paidAmount || '',
      checkNumber: this.props.checkNumber || '',
      claimNumber: this.props.claimNumber || '',
      denialReason: this.props.denialReason || '',
      denialReasons: this.props.denialReasons || [],
      payerPhoneNumber: this.props.payerPhoneNumber || '',
      claimStatus: this.props.claimStatus || null,
      writeOff: this.props.writeOff || false,
      writeOffAmount: this.props.writeOffAmount || '',
      writeOffReason: this.props.writeOffReason || '',
      ifCareplanClaim: this.props.ifCareplanClaim || false,
      dueAmount: this.props.dueAmount || '',
      copayCharged: this.props.copayCharged || '',
      // chargeType: true,
      ifCopayCharge: false,
      ifStripeCharge: true,
      chargeAmount: this.props.dueAmount || '',
    }

    this.chargeDueAmount = this.chargeDueAmount.bind(this)
    // this.removeSpecialCharacters = this.removeSpecialCharacters.bind(this)
  }

  async getStripeCharge() {
    console.log('Charing Stripe!')
    const { token, mongoUser, appChargeData } = this.props
    let { chargeAmount } = this.state
    const url = `${Config.BACKEND_URL}stripe/appointment-payment`
    console.log('CHARGE: ', chargeAmount, appChargeData)
    try {
      // TODO: Why is this a get request? Needs to be a POST
      let configData = {
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          customer_id: appChargeData?.customer_id,
          event_id: appChargeData?.event_id,
          careplan_id: appChargeData?.careplan_id,
          duration: appChargeData?.duration,
          doctor_id: appChargeData?.doctor_id,
          patient_id: appChargeData?.patient_id,
          visit_start_time: appChargeData?.start,
          description: `Date of service: ${moment(appChargeData?.start).format(
            'll',
          )}`,
          charge_amount: Number(chargeAmount?.replace(/[^0-9|.]/g, '')) * 100,
        },
      }

      if (appChargeData?.transfer) configData.headers.transfer = true

      const response = await axios(configData)

      const charge = response.data
      if (charge?.status === 'paid') toast.success('Successfully Charged')
      else {
        // Write code here to handle when payment fails
      }
      console.log('stripeCharge received', charge)
      this.setState({
        appChargeData: {},
        stripeChargeModalIsOpen: false,
        chargeAmount: '',
        chargeLoader: false,
      })
      this.getInsuranceClaims()
      // this.setState({ stripeCharge: chargeData || [] })
    } catch (err) {
      toast.error('Could not charge patient Credit card, please try again!')
      console.log('Error when charging patient', err)
      this.setState({
        appChargeData: {},
        stripeChargeModalIsOpen: false,
        chargeAmount: '',
        chargeLoader: false,
      })
    }
  }

  async chargeCopayAmount() {
    console.log('Charing Copay!')
    const { token, mongoUser, appChargeData } = this.props
    // let { appCopayChargeData: appChargeData, chargeAmount } = this.state
    let { chargeAmount } = this.state
    const url = `${Config.BACKEND_URL}stripe/appointment-copayment`
    console.log('CHARGE: ', chargeAmount, appChargeData)
    try {
      let configData = {
        method: 'post',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          customer_id: appChargeData?.customer_id,
          event_id: appChargeData?.event_id,
          careplan_id: appChargeData?.careplan_id,
          duration: appChargeData?.duration,
          doctor_id: appChargeData?.doctor_id,
          patient_id: appChargeData?.patient_id,
          visit_start_time: appChargeData?.start,
          description: `Date of service: ${moment(appChargeData?.start).format(
            'll',
          )}`,
          charge_amount:
            Number(chargeAmount?.replace(/[^0-9|.]/g, '')) * 100,
        },
      }

      if (appChargeData?.transfer) configData.headers.transfer = true

      const response = await axios(configData)

      const charge = response.data
      if (charge?.status === 'paid') toast.success('Successfully Charged Copay')
      else {
        // Write code here to handle when payment fails
      }
      console.log('stripeCharge copay received', charge)
      this.setState({
        appCopayChargeData: {},
        stripeChargeModalIsOpen: false,
        charge_amount: '',
        chargeLoader: false,
      })
      this.getInsuranceClaims()
      // this.setState({ stripeCharge: chargeData || [] })
    } catch (err) {
      toast.error('Could not process stripe copay charge, please try again!')
      console.log('error when getting stripe copay charge from backend', err)

      this.setState({
        appCopayChargeData: {},
        stripeChargeModalIsOpen: false,
        charge_amount: '',
        chargeLoader: false,
      })
    }
  }

  chargeBasedOnChargeType = () => {
    let { ifCopayCharge, ifStripeCharge } = this.state
    if (ifCopayCharge && !ifStripeCharge) {
      this.chargeCopayAmount()
    } else if (ifStripeCharge && !ifCopayCharge) {
      this.getStripeCharge()
    } else {
      console.log('Error no case matched for charging patient!')
      toast.error('Error charging patient, contact support!')
    }
  }

  chargeDueAmount = () => {
    let { chargeAmount } = this.state
    this.setState({ chargeLoader: true })
    if (Number(chargeAmount) > MAX_UNRESTRICTED_CHARGE) {
      if (
        window.confirm(
          `Amount being charged is greater than $${MAX_UNRESTRICTED_CHARGE}. Are you sure you want to proceed?`,
        )
      ) {
        this.chargeBasedOnChargeType()
      }
    } else {
      this.chargeBasedOnChargeType()
    }
  }

  removeSpecialCharacters = (str) => {
    // Use a regular expression to remove "$" and whitespace characters
    // return str.replace(/[$\s]/g, '')
    // Only numbers and dot
    let number = str.replace(/[^0-9.]/g, '')
    if (Number(number)) {
      this.setState({ invalidInput: false })
      return number
    } else {
      toast.error('The entered number is Invalid!')
      this.setState({ invalidInput: true })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '90%',
          width: '90%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          style={{ padding: '4px 20px' }}
          onClick={this.props.closeChargeModal}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Charge Modal
        </h4>
        <div
          style={{
            width: '600px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Total Charges Billed
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={'Total Charges Billed'}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                disabled={true}
                value={this.state.totalChargesBilled}
                // onChange={(e) => {
                //   let totalChargesBilled = this.removeSpecialCharacters(
                //     e.target.value,
                //   )
                //   console.log('total charged', totalChargesBilled)
                //   // this.setState({ totalChargesBilled: e.target.value })
                //   this.setState({ totalChargesBilled: totalChargesBilled })
                // }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Allowed Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                disabled={true}
                value={this.state.allowedAmount}
                // onChange={(e) => {
                //   console.log('E', e.target.value)
                //   let allowedAmount = this.removeSpecialCharacters(
                //     e.target.value,
                //   )
                //   console.log('total allowed', allowedAmount)
                //   this.setState({ allowedAmount: allowedAmount })
                //   // this.setState({ allowedAmount: e.target.value })
                // }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Adjustment Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                disabled={true}
                value={this.state.adjustmentAmount}
                // onChange={(e) => {
                //   let adjustmentAmount = this.removeSpecialCharacters(
                //     e.target.value,
                //   )
                //   this.setState({ adjustmentAmount: adjustmentAmount })
                //   // this.setState({ adjustmentAmount: e.target.value })
                // }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Ins.Paid Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                disabled={true}
                value={this.state.paidAmount}
                // onChange={(e) => {
                //   // console.log('e', e)

                //   let paidAmount = this.removeSpecialCharacters(e.target.value)
                //   this.setState({ paidAmount: paidAmount })
                //   // this.setState({ paidAmount: e.target.value })
                // }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Amount Due
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                disabled
                value={this.state.dueAmount}
                // onChange={(e) => {
                //   // console.log('e', e)

                //   let dueAmount = this.removeSpecialCharacters(e.target.value)
                //   this.setState({ dueAmount: dueAmount })
                //   // this.setState({ dueAmount: e.target.value })
                // }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Copay Amount Charged
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                disabled
                value={this.state.copayCharged}
                // onChange={(e) => {
                //   this.setState({ checkNumber: e.target.value })
                // }}
              />
            </div>
          </div>
          <div
            style={{
              borderBottom: '1px solid green',
              width: '90%',
              margin: '5px',
            }}
          ></div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Charge Type
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
              }}
            >
              <div style={{}}>
                <input
                  // placeholder={''}
                  // className={Styles.textInput}
                  type="radio"
                  style={{
                    margin: '5px',
                  }}
                  // value={this.state.ifCopayCharge}
                  checked={this.state.ifCopayCharge}
                  onChange={() => {
                    let { ifCopayCharge } = this.state
                    ifCopayCharge = !ifCopayCharge
                    console.log('ifCopayCharge', ifCopayCharge)
                    this.setState({ ifCopayCharge }, () => {
                      this.setState({ ifStripeCharge: !ifCopayCharge })
                    })
                  }}
                />
                {/* <span> */}
                Copay Charge
                {/* </span> */}
              </div>

              <div>
                <input
                  // placeholder={''}
                  // className={Styles.textInput}
                  type="radio"
                  style={{
                    margin: '5px',
                  }}
                  // value={this.state.ifStripeCharge}
                  checked={this.state.ifStripeCharge}
                  onChange={() => {
                    let { ifStripeCharge } = this.state
                    ifStripeCharge = !ifStripeCharge
                    console.log('ifStripeCharge', ifStripeCharge)
                    this.setState({ ifStripeCharge: ifStripeCharge }, () => {
                      this.setState({ ifCopayCharge: !ifStripeCharge })
                    })
                  }}
                />
                {/* <span> */}
                After Billing Charge
                {/* </span> */}
              </div>
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Charge Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.chargeAmount}
                onChange={(e) => {
                  this.setState({ chargeAmount: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center content horizontally
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => {
                // this.updateSave()
                // this.props.updatePaymentData()
                this.chargeDueAmount()
              }}
              className={GlobalStyles.button}
              disabled={false}
              style={{
                width: 250,
                // marginLeft: 10,
                backgroundColor: Constants.primaryTheme,
                textAlign: 'center',
              }}
              variant="primary"
              color={Constants.primaryTheme}
              backgroundColor={Constants.primaryTheme}
            >
              Charge
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChargeModal)
