import React, { Component } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  stopTimer,
  addProviderData,
  fetchSpecificDoctorMongoUser,
} from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'
import SignaturePad from 'react-signature-canvas'

import { SPECIALITY_OPTIONS } from '../../metadata.js'
import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Account.module.scss'
import Config from '../../config'
import DatePicker from '../../components/DatePicker'
import moment from 'moment'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showConnectCalendar: true,
      speciality: null,
      degree: null,
      gender: null,
      languages: null,
      insurances: null,
      userDetails: null,
      loading: true,

      phoneNumber: '',
      dob: new Date(),
      birth_place: null,

      signLocation: '',
      signImage: null,
      signUploaded: false,
      signChanged: false,
      trimmedDataURL: null,

      profileChanged: false,
      imageUploaded: false,
      imageLocation: '',
      image: null,
      error: false,
      errorString: '',
      saving: false,
      locations: [null],
      modalIsOpen: false,
      modalLoading: false,
      modalError: false,
      modalErrorString: '',
      modalNumber: '',
      organization: '',
      showIframe: false,
      region: '',
      states: null,
      licenseNumbers: null,
      statesAbbrs: null,
      verifiedFlags: null,
      selectedProvider: null,
      providerOptionsData: [],
      allProviderData: [],
    }
    this.sigPad = {}
    this.signRef = React.createRef()
    this.imageUploadRef = React.createRef()
    this.frontImageRef = React.createRef()
    this.saveUserToBackend = this.saveUserToBackend.bind(this)
    this.getAllProviderData = this.getAllProviderData.bind(this)
    this.saveImageToFirebase = this.saveImageToFirebase.bind(this)
    this.onManageCalendarPressed = this.onManageCalendarPressed.bind(this)
    this.trim = this.trim.bind(this)
    this.clear = this.clear.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.token && props.mongoUser) {
      let user = props.mongoUser
      const userLanguages = user.languages || []
      let mappedLang = userLanguages.map((x) => ({ label: x, value: x }))
      if (!userLanguages[0]?.label) user.languages = mappedLang
      const userStates = user.licensed_states || []
      const states = []
      const licenseNumbers = []
      const statesAbbrs = []
      const verifiedFlags = []
      if (userStates.length > 0) {
        userStates.forEach((x) => {
          states.push(x.state)
          licenseNumbers.push(x.number)
          statesAbbrs.push(x.abbr)
          verifiedFlags.push(x.verified)
        })
      }
      const speciality = user.speciality
        ? { label: user.speciality, value: user.speciality }
        : null
      // let country = user.country ? {label: user.country, value: user.country} : null
      const region = user.region
        ? { label: user.region, value: user.region }
        : null

      const degree = user.degree
        ? { label: user.degree, value: user.degree }
        : null

      const gender = user.gender
        ? { label: user.gender, value: user.gender }
        : null
      let insurances
      if (user.insurances) {
        insurances = user.insurances.map((x) => ({ label: x, value: x }))
      } else {
        insurances = []
      }
      const phoneNumber = user.phoneNumber || ''
      const dob = user.dob || new Date()
      const birth_place = user.birth_place || null

      return {
        loading: state.saving,
        userDetails: user,
        selectedProvider: user?.firebase_id,
        // languages: languages,
        speciality: speciality,
        phoneNumber: phoneNumber,
        dob: dob,
        region: region,
        degree: degree,
        insurances: insurances,
        gender: gender,
        locations: state.locations,
        birth_place: birth_place,
        organization: user.organization,
        states: states,
        licenseNumbers: licenseNumbers,
        statesAbbrs: statesAbbrs,
        verifiedFlags: verifiedFlags,
        showConnectCalendar: !(props.mongoUser && props.mongoUser.nylas),
      }
    }

    return null
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    const { token, adminId, mongoUser } = this.props
    this.getAllProviderData()
    // if specific provider is not chosen yet
    this.props.fetchSpecificDoctorMongoUser(
      mongoUser?.firebase_id || adminId,
      token,
    )
    this.props.stopTimer(adminId, token)
  }

  getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: this.props.adminId,
        Authorization: 'JWT ' + this.props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        let providerOptionList = []
        let tempAllProviderData = data.reduce((data, doc) => {
          data[doc.speciality || 'Other'] =
            data[doc.speciality || 'Other'] || []
          data[doc.speciality || 'Other'].push({
            label:
              doc?.first_name + ' ' + doc?.last_name + `(${doc?.user_type})`,
            value: doc?.firebase_id,
          })
          return data
        }, Object.create(null))
        Object.entries(tempAllProviderData).forEach(
          ([userType, doctorArray]) => {
            providerOptionList.push({
              label: userType,
              options: doctorArray,
            })
          },
        )
        let tempData = data.map((doc) => ({
          label: doc?.first_name + ' ' + doc?.last_name,
          value: doc?.firebase_id,
        }))
        this.setState({
          providerOptionsData: providerOptionList,
          allProviderData: tempData,
        })
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  onManageCalendarPressed() {
    // this.setState({modalIsOpen: true})
    const { mongoUser } = this.props

    window.nylas.scheduler.show({
      auth: {
        // Account access_token with active calendar scope
        accessToken: mongoUser.nylas && mongoUser.nylas.access_token,
      },
      style: {
        // Style the schedule editor
        tintColor: Constants.primaryTheme,
        backgroundColor: 'white',
      },
      behavior: {
        displayOnly: ['event-info', 'opening-hours', 'calendars', 'reminders'],
      },
      defaults: {
        appearance: {
          show_nylas_branding: false,
          color: Constants.primaryTheme,
          submit_text: 'Create Appointment',
          company_name: 'Aila Health',
          logo: 'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Faila_logo.png?alt=media&token=f0018d3c-4875-4d49-babf-a187c92cd7ec',
          thank_you_redirect: `${Config.WEB_HOST}nylas/thankyou`,
        },
        event: {
          title: 'Aila Appointment',
          duration: 30,
          location: 'Aila Health',
        },
        reminders: [
          {
            delivery_method: 'email',
            delivery_recipient: 'both',
            email_subject: 'Your upcoming appointment tomorrow on Aila Health',
            time_before_event: 1440,
          },
          {
            delivery_method: 'email',
            delivery_recipient: 'both',
            email_subject: 'Your upcoming appointment on Aila Health',
            time_before_event: 60,
          },
        ],
        booking: {
          min_buffer: 15,
          confirmation_method: 'automatic',
          available_days_in_future: 30,
          confirmation_emails_to_host: true,
          confirmation_emails_to_guests: true,
          calendar_invite_to_guests: true,
          min_booking_notice: 720,
          min_cancellation_notice: 1440,
        },
      },
    })
  }

  onModalSubmitPressed() {
    const { modalNumber } = this.state
    if (!modalNumber || modalNumber.length !== 10) {
      // npi number must be exactly 10 digits
      this.setState({
        modalError: true,
        modalErrorString:
          'NPI number must be unique 10-digit National Provider Identifier assigned to the provider',
      })
      return
    }

    this.setState({
      modalLoading: true,
    })

    // use the NPI registry API to verify the provided NPI
    const { mongoUser, token } = this.props
    const url = `${Config.BACKEND_URL}provider/npi-verification`
    axios({
      method: 'post',
      headers: { Authorization: `JWT ${token}` },
      url,
      data: { number: modalNumber, firebase_id: mongoUser.firebase_id },
    })
      .then(({ data }) => {
        console.log('got the response baby', data)
        if (data.npiVerification) {
          toast.success('Successfully verified NPI. You are all set.')
          this.props.addProviderData(data.user)
          this.setState({
            modalLoading: false,
            modalError: false,
            modalIsOpen: false,
          })
        } else {
          toast.error('Invalid NPI number')
          this.setState({
            modalLoading: false,
            modalError: true,
            modalErrorString: 'Invalid NPI number. Could not verify.',
          })
        }
      })
      .catch((err) => {
        console.log('error when verifying NPI', err)
        this.setState({
          modalLoading: false,
          modalError: true,
          modalErrorString:
            'The verification system is down. Please try again later. If the problem persists, contact support. ',
        })
      })
  }

  onSavePressed() {
    const {
      languages,
      speciality,
      phoneNumber,
      degree,
      insurances,
      userDetails,
      gender,
      organization,
      states,
      licenseNumbers,
      statesAbbrs,
      verifiedFlags,
    } = this.state
    if (!userDetails?.speciality) {
      console.log('missing speciality')
      this.setState({
        error: true,
        errorString: 'Please select a speciality',
        loading: false,
      })
      return
    }
    userDetails.speciality = userDetails?.speciality

    // if (!degree) {
    //   console.log('missing degree')
    //   this.setState({
    //     error: true,
    //     errorString: 'Please select a Degree',
    //     loading: false,
    //   })
    //   return
    // }
    // userDetails.degree = degree.value

    if (!userDetails.languages || userDetails?.languages?.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please select at least one language',
        loading: false,
      })
      return
    }
    userDetails.languages = userDetails?.languages?.map((x) => x.label)

    // if (!states || states.length === 0) {
    //   this.setState({
    //     error: true,
    //     errorString: 'Please select at least one state!',
    //     loading: false,
    //   })
    //   return
    // }
    // userDetails.states = states.map((x) => x.label)

    userDetails.insurances = insurances.map((x) => x.label)
    userDetails.gender = userDetails?.gender
    // userDetails.organization = organization

    if (userDetails.locations) {
      const locations = userDetails.locations.map((x) => x)
      userDetails.locations = locations
    }

    userDetails.phoneNumber = userDetails?.phoneNumber
    userDetails.dob = userDetails?.dob
    userDetails.birth_place = userDetails?.birth_place

    // if (
    //   licenseNumbers &&
    //   licenseNumbers.length > 0 &&
    //   states &&
    //   states.length > 0 &&
    //   states[0] !== null
    // ) {
    //   const licensedStates = []
    //   let flag = false
    //   licenseNumbers.forEach((x, index) => {
    //     if (!x) {
    //       console.log('missing license number', index)
    //       flag = true
    //     }

    //     if (!states[index] || states[index].length === 0) {
    //       console.log('missing state ', index)
    //       flag = true
    //     }

    //     if (!statesAbbrs[index] || statesAbbrs[index].length === 0) {
    //       console.log('missing state abbrs ', index)
    //       flag = true
    //     }

    //     if (!flag) {
    //       licensedStates.push({
    //         state: states[index],
    //         abbr: statesAbbrs[index],
    //         verified: verifiedFlags[index] || false,
    //         number: x,
    //       })
    //     }
    //   })

    //   if (flag) {
    //     toast.error('Missing values')
    //     this.setState({
    //       error: true,
    //       errorString: 'Please fill missing license number information',
    //       loading: false,
    //     })
    //     return
    //   }
    //   userDetails.licensed_states = licensedStates
    // }

    console.log('trying to save', userDetails)
    this.setState({ error: false, loading: true, saving: true })

    if (this.state.imageUploaded || this.state.signUploaded) {
      this.saveImageToFirebase(userDetails)
    } else {
      this.saveUserToBackend(userDetails)
    }
  }

  saveUserToBackend(user) {
    console.log('user', user)
    const self = this
    const { token } = this.props
    const url = `${Config.BACKEND_URL}providers`
    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: user,
    })
      .then(({ data }) => {
        console.log('successfully added doctor to backend')
        self.props.addProviderData(user)
        toast.success('Successfully updated profile')
        if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false, imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  clear() {
    this.sigPad.clear()
  }
  trim() {
    const self = this
    const { mongoUser, token } = this.props
    const { userDetails } = this.state

    console.log(this.sigPad)
    let trimmedDataURL = this.sigPad.getTrimmedCanvas().toDataURL()

    // userDetails.sign_image = trimmedDataURL
    this.setState({ trimmedDataURL: trimmedDataURL })
    const storageRef = firebase.storage().ref()

    const uploadTask = storageRef
      .child(`signatures/${mongoUser.firebase_id}/ReactCanvasSign`)
      .putString(trimmedDataURL.split(',')[1], 'base64', {
        contentType: 'image/png',
      })
    // const uploadTask = storageRef
    //   .child(`signatures/${mongoUser.firebase_id}/${file.name}`)
    //   .put(file)
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`${progress}%`)
      },
      function (err) {
        // Handle unsuccessful uploads
        console.log('error when uploading image', err)
        self.saveUserToBackend(userDetails)
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          userDetails.sign_image = downloadURL
          console.log(downloadURL)
          self.saveUserToBackend(userDetails)
          self.clear()
        })
      },
    )

    console.log(trimmedDataURL)
    // self.saveUserToBackend(userDetails)
    // console.log(this.state.trimmedDataURL);
  }

  saveImageToFirebase(user) {
    const self = this
    const { mongoUser, token } = this.props
    if (this.state.imageUploaded && this.state.signUploaded) {
      const file = this.state.image
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef
        .child(`doctors/${mongoUser.firebase_id}/${file.name}`)
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            user.image_url = downloadURL

            // self.saveUserToBackend(user)

            const file = self.state.signImage
            const storageRef = firebase.storage().ref()

            const uploadTask = storageRef
              .child(`signatures/${mongoUser.firebase_id}/ReactCanvasSign`)
              .putString(self.state.trimmedDataURL, 'base64', {
                contentType: 'image/png',
              })
            // const uploadTask = storageRef
            //   .child(`signatures/${mongoUser.firebase_id}/${file.name}`)
            //   .put(file)
            uploadTask.on(
              'state_changed',
              function (snapshot) {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log(`${progress}%`)
              },
              function (err) {
                // Handle unsuccessful uploads
                console.log('error when uploading image', err)
                self.saveUserToBackend(user)
              },
              function () {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(function (downloadURL) {
                    user.sign_image = downloadURL
                    console.log(downloadURL)
                    self.saveUserToBackend(user)
                  })
              },
            )
          })
        },
      )
    } else if (this.state.imageUploaded) {
      const file = this.state.image
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef
        .child(`doctors/${mongoUser.firebase_id}/${file.name}`)
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            user.image_url = downloadURL
            self.saveUserToBackend(user)
          })
        },
      )
    } else if (this.state.signUploaded) {
      const file = this.state.signImage
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef
        .child(`signatures/${mongoUser.firebase_id}/ReactCanvasSign`)
        .putString(self.state.trimmedDataURL.split(',')[1], 'base64', {
          contentType: 'image/png',
        })

      // const uploadTask = storageRef
      //   .child(`signatures/${mongoUser.firebase_id}/${file.name}`)
      //   .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            user.sign_image = downloadURL
            console.log(downloadURL)
            self.saveUserToBackend(user)
          })
        },
      )
    }
  }

  onImageChange(e) {
    const file = e.target.files[0]
    if (window.confirm(`Are you sure you want to upload ${file.name}?`)) {
      // this.setState({imageUploaded: true, imageLocation: })
      const reader = new FileReader()
      const self = this
      reader.onload = function (e) {
        const image = e.target.result
        if (self.state.profileChanged) {
          console.log('confirmed upload - profile')

          self.setState(
            {
              imageLocation: image,
              imageUploaded: true,
              image: file,
            },
            () => {
              self.setState({ profileChanged: false })
            },
          )
        } else if (self.state.signChanged) {
          console.log('confirmed upload - profile')

          self.setState(
            {
              signLocation: image,
              signUploaded: true,
              signImage: file,
            },
            () => {
              self.setState({ signChanged: false })
            },
          )
        }
      }
      reader.readAsDataURL(file) // convert to base64 string
    } else {
      console.log('ignored')
    }
  }

  renderInfoCard() {
    const { userDetails } = this.state
    const userLanguages = userDetails?.languages
    const languages = userLanguages?.map((x, index) => (
      <span key={index.toString()}>
        {x?.value || ''}
        {index === userLanguages.length - 1 ? '' : ', '}
      </span>
    ))
    let insurances = null
    if (this.state.insurances)
      insurances = this.state.insurances.map((x, index) => (
        <span key={index.toString()}>
          {x.value}
          {index === this.state.insurances.length - 1 ? '' : ', '}
        </span>
      ))

    const stars = []
    for (let i = 0; i < 5; i++) {
      stars.push(
        <FontAwesomeIcon
          icon={faStar}
          style={{
            color: 'gold',
            fontSize: 20,
            marginRight: 5,
            marginLeft: i === 0 ? 10 : 0,
          }}
        />,
      )
    }

    let image
    if (this.state?.imageUploaded) image = this.state?.imageLocation
    else image = userDetails?.image_url || PlaceHolderProfileImage

    return (
      <div style={{ width: '35%', paddingTop: 50, paddingRight: 20 }}>
        <div className={Styles.infoWrapper}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={image}
              style={{ height: 100, width: 100, objectFit: 'cover' }}
              alt="No Image"
            />
            <input
              onChange={(e) => {
                this.setState({ profileChanged: true })
                this.onImageChange(e)
              }}
              type="file"
              id="file"
              ref={this.imageUploadRef}
              accept="image/*"
              style={{ display: 'none' }}
              multiple={false}
            />
            <p
              onClick={() => this.imageUploadRef.current.click()}
              style={{
                textDecoration: 'underline',
                color: 'gray',
                cursor: 'pointer',
              }}
            >
              Upload/Change Photo
            </p>
          </div>
          <p style={{ color: Constants.primaryTheme, marginTop: 10 }}>
            {`${userDetails?.first_name || ''} ${userDetails?.last_name || ''}`}
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', width: '40%', marginRight: 10 }}>
              Profile:{' '}
            </p>
            {this.state.userDetails &&
              (this.state?.userDetails?.user_type || '')}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Specialty:
            </p>
            <p>
              {this.state?.userDetails?.speciality &&
                (this.state?.userDetails?.speciality || '')}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Languages Spoken:{' '}
            </p>
            <p style={{ width: '50%', textOverflow: 'break-word' }}>
              {languages || ''}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Insurance Accepted:{' '}
            </p>
            <p style={{ width: '50%', textOverflow: 'break-word' }}>
              {insurances || ''}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Residency:{' '}
            </p>
            <p style={{ width: '50%', textOverflow: 'break-word' }}>
              {userDetails?.residency || ''}
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderLicensedStatesRow(licenseNumber, index) {
    const { states, statesAbbrs, verifiedFlags } = this.state

    return (
      <div
        key={index.toString()}
        style={{
          width: '100%',
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '40%' }}>
            <Select
              placeholder="state..."
              inputId="state"
              options={metadata.stateResidence}
              value={
                states[index]
                  ? {
                      label: states[index],
                      value: states[index],
                      abbreviation: statesAbbrs[index],
                    }
                  : null
              }
              isMulti={false}
              isSearchable
              autoBlur
              onChange={(val) => {
                const { states, statesAbbrs } = this.state
                states[index] = val.value
                statesAbbrs[index] = val.abbreviation
                this.setState({ states, statesAbbrs })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
          <div style={{ width: '40%', marginLeft: 10 }}>
            <input
              placeholder="license number..."
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={licenseNumber}
              onChange={(e) => {
                const { licenseNumbers } = this.state
                licenseNumbers[index] = e.target.value
                this.setState({ licenseNumbers })
              }}
            />
          </div>
          {verifiedFlags && verifiedFlags[index] && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginLeft: 10,
              }}
            />
          )}
        </div>

        <FontAwesomeIcon
          onClick={() => {
            const { licenseNumbers, states, statesAbbrs, verifiedFlags } =
              this.state
            licenseNumbers.splice(index, 1)
            states.splice(index, 1)
            statesAbbrs.splice(index, 1)
            verifiedFlags.splice(index, 1)
            this.setState({
              licenseNumbers,
              states,
              statesAbbrs,
              verifiedFlags,
            })
          }}
          icon={faTrash}
          style={{
            color: 'gray',
            fontSize: 20,
            marginLeft: 10,
            cursor: 'pointer',
          }}
        />
      </div>
    )
  }

  renderLocationRow(index) {
    return (
      <div
        key={index.toString()}
        style={{
          width: '100%',
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%', marginRight: 10 }}>
          <GooglePlacesAutocomplete
            apiKey={Config.GOOGLE_API_KEY}
            selectProps={{
              value: this.state?.userDetails?.locations[index],
              onChange: (picked) => {
                if (
                  this.props.userType &&
                  this.props.userType.includes('admin')
                ) {
                  const { userDetails } = this.state
                  userDetails.locations[index] = picked
                  this.setState({ userDetails })
                } else {
                  toast.info('Team members cannot update location info')
                }
              },
            }}
          />
        </div>
        <FontAwesomeIcon
          onClick={() => {
            if (this.props.userType && this.props.userType.includes('admin')) {
              const { userDetails } = this.state
              if (userDetails?.locations?.length <= 1) return
              userDetails.locations.splice(index, 1)
              this.setState({ userDetails })
            } else {
              toast.info('Team members cannot update location info')
            }
          }}
          icon={faTrash}
          style={{
            color: 'gray',
            fontSize: 20,
            marginRight: 10,
            cursor: 'pointer',
          }}
        />
      </div>
    )
  }

  renderAccountDetails() {
    const { adminId, token } = this.props
    const { userDetails, licenseNumbers } = this.state
    const locationObjects = this.state?.userDetails?.locations
    console.log('LOCA: ', locationObjects)
    const locations = locationObjects?.map((x, index) =>
      this.renderLocationRow(index),
    )

    const states = licenseNumbers.map((x, index) =>
      this.renderLicensedStatesRow(x, index),
    )
    // let npiLocations = userDetails.npi ? userDetails.npi.addresses : []
    // let npiLocationStrings = []
    // npiLocations.forEach((addressComponents) => {
    //   let {address_1, address_2, city, state, postal_code, country_name} = addressComponents
    //   let string = address_1 + ' ' + address_2 + ', ' + city + ' ' + postal_code + ', ' + country_name
    //   npiLocationStrings.push(
    //     <p>{string}</p>
    //   )
    // })
    const nylasAuthUrlBase = 'https://api.nylas.com/oauth/authorize'
    const redirectUri = `${Config.WEB_HOST}nylas/callback`
    const nylasAuthUrl = `${nylasAuthUrlBase}?client_id=${Config.NYLAS_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=token&scopes=calendar&state=${adminId}`

    let signImage
    if (this.state.signUploaded) signImage = this.state.signLocation
    else signImage = userDetails?.sign_image || null
    return (
      <div style={{ width: '65%', padding: '20px 40px' }}>
        {/* license no */}
        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>NPI Number</p>
          <div
            style={{
              height: 40,
              width: '50%',
              borderRadius: 4,
              padding: '4px 10px',
              border: '1px solid #d0d0d0',
            }}
          >
            <p>{userDetails.npi ? userDetails.npi.number : 'N/A'}</p>
          </div>
          <Button
            onClick={() => this.setState({ modalIsOpen: true })}
            className={GlobalStyles.button}
            disabled={this.state.loading}
            style={{ width: 120, marginLeft: 10 }}
            variant="primary"
          >
            Verify
          </Button>
        </div>

        {userDetails.npi && userDetails.npi.verified ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 5,
              }}
            />
            <p style={{ color: Constants.primaryTheme }}>Verified</p>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: 'red', fontSize: 20, marginRight: 5 }}
            />
            <p style={{ color: 'red' }}>Not Verified</p>
          </div>
        )} */}

        {/* DEA license number */}
        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>DEA Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter DEA License Number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails.dea}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.dea = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div> */}

        {/*  countries */}
        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Licensed in States</p>
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {states}
          </div>
          <div
            onClick={() => {
              const { licenseNumbers, states, statesAbbrs, verifiedFlags } =
                this.state
              licenseNumbers.push('')
              states.push('')
              statesAbbrs.push('')
              verifiedFlags.push('')

              this.setState({
                licenseNumbers,
                states,
                statesAbbrs,
                verifiedFlags,
              })
            }}
            style={{
              cursor: 'pointer',
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 5,
              }}
            />
            <p style={{ color: Constants.primaryTheme }}>add another</p>
          </div>
        </div> */}

        {/*  degree */}
        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Medical Degree</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="degree"
              options={metadata.degreeOptions}
              value={this.state.degree}
              isSearchable
              autoBlur
              onChange={(val) => this.setState({ degree: val })}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div> */}

        {/* education and training */}
        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Education and Training</p>
          <div style={{ width: '50%' }}>
            <p className={Styles.subRowLabel}>Medical School</p>
            <input
              placeholder="Enter medical school"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails.medSchool}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.medSchool = e.target.value
                this.setState({ userDetails })
              }}
            />
            <p className={Styles.subRowLabel}>Residency</p>
            <input
              placeholder="Enter residency"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails.residency}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.residency = e.target.value
                this.setState({ userDetails })
              }}
            />
            <p className={Styles.subRowLabel}>Fellowship</p>
            <input
              placeholder="Enter fellowship"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails.fellowship}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.fellowship = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div> */}

        {
          // only visible to administrators
          this.props.loggedInUser?.user_type?.toLowerCase() ===
            'administrator' && (
            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Select Provider</p>
              <div style={{ width: '50%' }}>
                <Select
                  options={this.state.providerOptionsData}
                  // className={Styles.addSelectInput}
                  autoBlur={true}
                  isMulti={false}
                  onChange={(val) => {
                    console.log('selected: ', val.value)
                    this.setState({ selectedProvider: val.value })
                    this.props.fetchSpecificDoctorMongoUser(val.value, token)
                  }}
                  value={
                    this.state.allProviderData.find(
                      (obj) => obj.value === this.state.selectedProvider,
                    ) || null
                  }
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
          )
        }

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Email ID</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Email"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails?.email || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.email = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>ID</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter ID"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state?.userDetails?.id_number || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.id_number = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Sync with Google Calendar</p>
          <div style={{ width: '50%' }}>
            <Button
              onClick={() => {
                axios({
                  method: 'get',
                  url: `${Config.BACKEND_URL}google/login`,
                  params: {
                    providerId: this.state.userDetails?.firebase_id,
                  },
                })
                  .then((res) => {
                    if (res.data.success) {
                      console.log(res.data.redirect)
                      window.open(res.data.redirect)
                    }
                  })
                  .catch((err) => {
                    console.log('Err', err)
                  })
              }}
              className={GlobalStyles.button}
              style={{ width: 200 }}
              variant="primary"
            >
              Connect
            </Button>
            {/* <input
              placeholder="Enter Google Calendar ID"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails.google_calendar_id}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.google_calendar_id = e.target.value
                this.setState({ userDetails })
              }}
            /> */}
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Phone Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Phone"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state?.userDetails?.phoneNumber || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.phoneNumber = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Date of Birth</p>
          <div style={{ width: '50%' }}>
            <DatePicker
              defaultDate={moment(this.state?.userDetails?.dob).format()}
              dateChange={(date) => {
                const { userDetails } = this.state
                userDetails.dob = date
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        {/* Location/Hospital */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Place of Birth</p>
          <div
            style={{
              width: '50%',
              // display: 'flex',
              // flexDirection: 'column',
              // alignItems: 'center',
            }}
          >
            <GooglePlacesAutocomplete
              apiKey={Config.GOOGLE_API_KEY}
              selectProps={{
                value: this.state?.userDetails?.birth_place,
                onChange: (picked) => {
                  const { userDetails } = this.state
                  userDetails.birth_place = picked
                  this.setState({ userDetails })
                },
              }}
            />
          </div>
        </div>

        {/* Bio field */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Bio</p>
          <div style={{ width: '50%' }}>
            <textarea
              placeholder="Enter your bio..."
              cols="20"
              rows="4"
              style={{
                width: '100%',
                borderBlockColor: 'lightgray',
                borderRadius: '5px',
                resize: 'none',
              }}
              value={this.state?.userDetails?.bio || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.bio = e.target.value
                this.setState({ userDetails })
              }}
            ></textarea>
          </div>
        </div>

        {/*  languages */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Languages Spoken</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="languages"
              options={[
                { label: 'English', value: 'English' },
                { label: 'Spanish', value: 'Spanish' },
                { label: 'French', value: 'French' },
                { label: 'Korean', value: 'Korean' },
                { label: 'German', value: 'German' },
                { label: 'Cantonese', value: 'Cantonese' },
                { label: 'Japanese', value: 'Japanese' },
              ]}
              value={this.state?.userDetails?.languages}
              isMulti
              isSearchable={false}
              autoBlur
              onChange={(val) => {
                let { userDetails } = this.state
                userDetails.languages = val
                this.setState({ userDetails })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {/*  gender */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Gender</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="gender"
              options={[
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
              ]}
              value={{
                label: this.state?.userDetails?.gender,
                value: this.state?.userDetails?.gender,
              }}
              autoBlur
              onChange={(val) => {
                const { userDetails } = this.state
                userDetails.gender = val.value
                this.setState({ userDetails })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {/* Location/Hospital */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Addresses</p>
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {locations}
          </div>

          <div
            onClick={() => {
              if (
                this.props.userType &&
                this.props.userType.includes('admin')
              ) {
                const { userDetails } = this.state
                userDetails.locations.push(null)
                this.setState({ userDetails })
              } else {
                toast.info('Team members cannot update location info')
              }
            }}
            style={{
              cursor: 'pointer',
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 5,
              }}
            />
            <p style={{ color: Constants.primaryTheme }}>add another</p>
          </div>
        </div>

        {/*  speciality */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Specialty</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="speciality"
              options={SPECIALITY_OPTIONS}
              value={{
                label: this.state.userDetails?.speciality,
                value: this.state.userDetails?.speciality,
              }}
              isSearchable
              autoBlur
              onChange={(value) => {
                const { userDetails } = this.state
                userDetails.speciality = value.value
                this.setState({ userDetails })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Sub Specialty</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter sub specialty"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails?.sub_speciality || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.sub_speciality = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Taxonomy #</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter taxonomy number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails?.taxonomy || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.taxonomy = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Credential</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter credential(MD/DO)"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.userDetails?.credential || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.credential = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        {/* Change Password */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Change Password</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder=""
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="password"
              value={this.state.userDetails?.changePassword || ''}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.changePassword = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        {/* organization */}
        {/* <div className={Styles.row}> */}
        {/*  <p className={Styles.rowLabel}>Organization</p> */}
        {/*  <div style={{ width: '50%' }}> */}
        {/*    <input */}
        {/*      placeholder="Enter organization" */}
        {/*      className={Styles.textInput} */}
        {/*      style={{ width: '100%' }} */}
        {/*      type="text" */}
        {/*      value={this.state.organization} */}
        {/*      onChange={(e) => { */}
        {/*        if ( */}
        {/*          this.props.userType && */}
        {/*          this.props.userType.includes('admin') */}
        {/*        ) { */}
        {/*          this.setState({ organization: e.target.value }) */}
        {/*        } else { */}
        {/*          toast.info('Team members cannot update organization') */}
        {/*        } */}
        {/*      }} */}
        {/*    /> */}
        {/*  </div> */}
        {/* </div> */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Signature</p>
          {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}> */}
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                borderBlock: '1px solid gray',
                borderLeft: '1px solid gray',
                borderRight: '1px solid gray',
              }}
            >
              <SignaturePad
                canvasProps={{ className: Styles.sigPad }}
                ref={(ref) => {
                  this.sigPad = ref
                }}
              />
            </div>

            <div
              style={{
                width: '100%',
                marginTop: '10%',
                marginBottom: '10%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                onClick={this.clear}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ width: 200, marginRight: '5%' }}
                variant="primary"
              >
                Clear
              </Button>

              <Button
                onClick={this.trim}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ width: 200, marginLeft: '5%' }}
                variant="primary"
              >
                Sign
              </Button>
            </div>

            {signImage && (
              <>
                <img
                  src={signImage}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
                <p
                  style={{
                    // textDecoration: 'underline',
                    color: 'gray',
                    // cursor: 'pointer',
                  }}
                >
                  Saved Signature
                </p>
              </>
            )}

            {/* <input
                onChange={(e) => {
                  this.setState({ signChanged: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.frontImageRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.frontImageRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload Signature
              </p> */}
          </div>
          {/* </div> */}
        </div>

        {/* calendar connect */}
        {/* {this.state.showConnectCalendar ? (
          <a href={nylasAuthUrl} style={{ textDecoration: 'none' }}>
            <Button
              onClick={null}
              className={GlobalStyles.button}
              style={{ marginTop: 30 }}
              variant="primary"
            >
              Connect Calendar
            </Button>
          </a>
        ) : (
          <Button
            onClick={this.onManageCalendarPressed}
            className={GlobalStyles.button}
            style={{ marginTop: 30 }}
            variant="primary"
          >
            Manage Calendar
          </Button>
        )} */}

        {/*  residency */}
        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Residency</p>
          <input
            placeholder={'Enter where you completed residency..'}
            className={Styles.textInput}
            type="text"
            value={this.state.userDetails.residency || ''}
            onChange={(e) => {
              let user = this.state.userDetails
              user.residency = e.target.value
              this.setState({ userDetails: user })
            }}
          />
        </div> */}

        {/*  insurances accepted */}
        {/* <div className={Styles.row}> */}
        {/*  <p className={Styles.rowLabel}>Insurance Accepted</p> */}
        {/*  <div style={{ width: '50%' }}> */}
        {/*    <Select */}
        {/*      inputId="insurances" */}
        {/*      options={[ */}
        {/*        { label: 'UnitedHealth', value: 'UnitedHealth' }, */}
        {/*        { label: 'Kaiser Foundation', value: 'Kaiser Foundation' }, */}
        {/*        { label: 'Anthem Inc.', value: 'Anthem Inc.' }, */}
        {/*        { label: 'Humana', value: 'Humana' }, */}
        {/*        { */}
        {/*          label: 'Blue Cross of California', */}
        {/*          value: 'Blue Cross of California', */}
        {/*        }, */}
        {/*        { label: 'Wellcare', value: 'Wellcare' }, */}
        {/*      ]} */}
        {/*      value={this.state.insurances} */}
        {/*      isMulti */}
        {/*      isSearchable */}
        {/*      autoBlur */}
        {/*      onChange={(val) => { */}
        {/*        if ( */}
        {/*          this.props.userType && */}
        {/*          this.props.userType.includes('admin') */}
        {/*        ) */}
        {/*          this.setState({ insurances: val }) */}
        {/*        else toast.info('Team members cannot update insurances') */}
        {/*      }} */}
        {/*      theme={(theme) => ({ */}
        {/*        ...theme, */}
        {/*        colors: { */}
        {/*          ...theme.colors, */}
        {/*          primary25: '#20A89233', */}
        {/*          primary: '#20A892', */}
        {/*        }, */}
        {/*      })} */}
        {/*    /> */}
        {/*  </div> */}
        {/* </div> */}

        {/* <div className={Styles.row}> */}
        {/*  <p className={Styles.rowLabel} style={{textDecoration: 'underline'}}>Sync Calendar</p> */}
        {/* </div> */}

        {/* <div className={Styles.row}> */}
        {/*  <p className={Styles.rowLabel} style={{textDecoration: 'underline'}}>Payment Information</p> */}
        {/* </div> */}

        {/* <div className={Styles.row}> */}
        {/*  <p className={Styles.rowLabel} style={{color: Constants.primaryTheme}}>Add Team Member Email</p> */}
        {/*  <input */}
        {/*    placeholder={'Enter email...'} */}
        {/*    className={Styles.textInput} */}
        {/*    type="text" */}
        {/*    value={this.state.emailText} */}
        {/*    onChange={(e) => this.setState({emailText: e.target.value})} */}
        {/*  /> */}
        {/*  <div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}> */}
        {/*    <FontAwesomeIcon icon={faPlusCircle} style={{color: Constants.primaryTheme, fontSize: 20, marginRight: 5}}/> */}
        {/*    <p style={{color: Constants.primaryTheme}}>add another</p> */}
        {/*  </div> */}
        {/* </div> */}
      </div>
    )
  }

  onModalClose() {
    this.setState({
      modalNumber: null,
      modalError: false,
      modalLoading: false,
      modalIsOpen: false,
    })
  }

  renderModal() {
    // console.log('this ', this.state.showIframe)
    // if(this.state.showIframe) {
    //   return (
    //       <div style={{height: '100%', width: '100%', overflowY: 'hidden'}}>
    //         <iframe
    //             height={windowHeight - 20}
    //             width={windowWidth - 200}
    //             src="https://cli-cert.changehealthcare.com/" />
    //       </div>
    //   )
    // }
    return (
      <div style={{ height: '100%', width: '100%', overflowY: 'hidden' }}>
        <p className={Styles.closeModalBtn} onClick={() => this.onModalClose()}>
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          NPI Verification
        </h4>

        {this.state.modalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 40,
            }}
          >
            <input
              placeholder="Enter number...."
              className={Styles.textInput}
              type="text"
              value={this.state.modalNumber}
              onChange={(e) => {
                this.setState({ modalNumber: e.target.value })
              }}
            />

            {this.state.modalError && (
              <p style={{ marginTop: 5, color: 'red', fontSize: 12 }}>
                {this.state.modalErrorString}
              </p>
            )}

            <Button
              onClick={this.onModalSubmitPressed.bind(this)}
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{ width: 200, position: 'absolute', bottom: 20 }}
              variant="primary"
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { userDetails } = this.state

    return (
      <div className={GlobalStyles.container}>
        <Header header="My Account" />

        {!userDetails || this.state.loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
              alignItems: 'center',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div className={GlobalStyles.contentWrapper}>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              {this.renderAccountDetails()}
              {this.renderInfoCard()}
            </div>

            <div
              style={{
                width: '100%',
                marginTop: 60,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={this.onSavePressed.bind(this)}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ width: 200 }}
                variant="primary"
              >
                {this.state.loading ? 'Loading....' : 'Save'}
              </Button>
              {this.state.error && (
                <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
                  {this.state.errorString}
                </p>
              )}
            </div>

            <Modal
              ariaHideApp={false}
              onRequestClose={() => this.onModalClose()}
              isOpen={this.state.modalIsOpen}
              style={customModalStyles}
              contentLabel="Modal"
            >
              {this.renderModal()}
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    loggedInUser: userReducer.mongoUser,
    mongoUser: userReducer.providerData,
    userType: userReducer.userType,
    adminId: userReducer.adminId,
  }
}

const mapDispatchToProps = {
  addProviderData,
  stopTimer,
  fetchSpecificDoctorMongoUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
