import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/PracticeInfo.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import * as Metadata from '../../metadata.js'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faFilter } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import {
  BarChart,
  Bar,
  Rectangle,
  Cell,
  ReferenceLine,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  Pie,
  PieChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Area,
  Scatter,
} from 'recharts'
import {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  addPatient,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
} from '../../redux/actions'
import Table from '../../components/Table'
import { providerReferralsColumns } from './Columns/providerReferralsColumns'
import { referringProvidersColumn } from './Columns/referringProvidersColumn'
import { timeSpentColumns } from './Columns/timeSpentColumns'
// import DatePicker from '../../components/DatePicker'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { patientTimeSpentColumns } from './Columns/patientTimeSpentColumns'
import Table1 from 'react-bootstrap/Table'
// import 'bootstrap/dist/css/bootstrap.min.css';

const axios = require('axios')

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const TEST_DATA = [
  {
    date: 'Dec 2023',
    billed: '350.00',
    allowed: '350.00',
    paid: '0.00',
    all_access: '350.00',
    membership: '0.00',
    appointment: '0.00',
  },
  {
    date: 'Jan 2024',
    billed: '0.00',
    allowed: '0.00',
    paid: '225.00',
    all_access: '350.00',
    membership: '0.00',
    appointment: '0.00',
  },
]

class ArGraph extends Component {
  constructor(props) {
    super(props)
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      showProvider: true,
      columnData: [],
      patientTimeData: [],
      monitoringTime: [],
      supportRequestTime: [],
      allCommunications: [],
      searchMonth: new Date(),
      referrals: [],
      referringProviders: [],
      showReferringModal: false,
      providerMap: {},
      patientList: [],
      patientMap: {},
      events: null,
      pdfData: '',
      showModal: false,
      //REFERRING PROVIDER MODAL
      type: null,
      firstName: '',
      lastName: '',
      specialty: null,
      organization: '',
      phone: '',
      email: '',
      fax: '',
      state: '',
      allProviders: [],
      provider_availabilities: [],
      providerInsightsMonth: null,
      //
      basicData: [],
    }
    this.fuse = null
    this.imageUploadRef = React.createRef()
    this.fileRef = React.createRef()
    this.getAllProviders = this.getAllProviders.bind(this)
    this.preparePatientList = this.preparePatientList.bind(this)
    this.getEventsFromBackendOnly = this.getEventsFromBackendOnly.bind(this)
    this.getPatientTimeSpent = this.getPatientTimeSpent.bind(this)
    this.getmonitoringLogs = this.getmonitoringLogs.bind(this)
    this.getSupportRequest = this.getSupportRequest.bind(this)
    this.getAllCommunications = this.getAllCommunications.bind(this)
    this.getReferringProviders = this.getReferringProviders.bind(this)
    this.navigatetoPatientProfile = this.navigatetoPatientProfile.bind(this)
    this.saveRefrringProvider = this.saveRefrringProvider.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.setState({ loading: true })
    this.getEventsFromBackendOnly()
    this.getReferringProviders()
    this.getAllProviders()

    this.getGraphData()
  }

  async getGraphData() {
    const MAPPING = {
      '2023-06': '90.91',
      '2024-01': '110.00',
      '2023-03': '755.53',
      '2023-10': '200.00',
      '2023-02': '1020.73',
      '2023-04': '134.81',
      '2023-08': '24.77',
      '2023-09': '93.93',
      '2023-05': '487.72',
    }

    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}product-analytics/ar/basic`,
    }).then(async (response) => {
      let result = response.data
      console.log('result', result)
      result.forEach((item) => {
        let total_dollar_copay = Number(item.total_copay / 100) || 0
        let total_dollar_stripe = Number(item.total_stripe / 100) || 0
        item.total_dollar_copay = total_dollar_copay
        item.total_dollar_stripe = total_dollar_stripe
        let total_paid =
          total_dollar_copay + total_dollar_stripe + item.total_era_paid
        item.total_paid = total_paid
        let total_dollar_billed = Number(
          item.total_billed + item.total_era_billed,
        ).toFixed(2)
        console.log('E->', total_dollar_billed)
        item.total_dollar_billed = total_dollar_billed
        // item.total_write_off = MAPPING[item._id]
      })
      this.setState({ basicData: result })
    })
  }

  async getAllProviders() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        let docObj = {}
        if (data) {
          this.setState({ allProviders: data })
          let provider_availabilities = []
          // console.log(data)
          data.forEach((provider) => {
            let total_minutes = 0
            provider?.availability &&
              Object.keys(provider?.availability).forEach((avail) => {
                let total_days = 0
                var day_of_month = moment(this.state.searchMonth)
                  .startOf('month')
                  .day(avail)
                if (day_of_month.date() > 7) day_of_month.add(7, 'd')
                var month = day_of_month.month()
                //CALCULATING TOTAL DAYS (MON, TUES, WED) IN THE MONTH
                while (month === day_of_month.month()) {
                  total_days = total_days + 1
                  day_of_month.add(7, 'd')
                }
                // avail = Mon, Tue, Wed ...
                if (provider?.availability?.[avail]?.length) {
                  provider.availability[avail] &&
                    provider.availability[avail].forEach((time) => {
                      let availTimeStart = moment(time?.start)
                      let availTimeEnd = moment(time?.end)
                      // splitting the block of time into 30 minutes chunks
                      var duration = moment.duration(
                        availTimeEnd.diff(availTimeStart),
                      )
                      total_minutes =
                        total_minutes + duration.asMinutes() * total_days
                    })
                }
              })
            provider_availabilities.push({
              provider_id: provider?.firebase_id,
              time: total_minutes,
            })
          })
          console.log(provider_availabilities)
          this.setState({ provider_availabilities: provider_availabilities })
          data.forEach((x) => {
            docObj[x.firebase_id] = x
          })
          this.setState(
            {
              providerMap: docObj,
            },
            () => this.preparePatientList(),
          )
        }
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const patientMap = {}
    const list = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        patientMap[patient.uid] = patient
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientMap, patientList: list }, () => {
      this.getEventsFromBackendOnly()
    })
  }

  async getEventsFromBackendOnly() {
    const { token, mongoUser, firebaseUser } = this.props
    const url = `${Config.BACKEND_URL}time-spent/provider`
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_doctor_id: mongoUser.firebase_id,
          search_month:
            this.state.providerInsightsMonth?.value || moment().month() + 1,
          // moment(
          //   moment(this.state.searchMonth).format('YYYY-MM-DD'),
          // ).month() + 1,
          search_year:
            this.state.providerInsightsYear?.value || moment().year(),
          // moment(
          //   moment(this.state.searchMonth).format('YYYY-MM-DD'),
          // ).year(),
        },
      })

      const events = response.data
      console.log(events)
      this.setState({ columnData: events || [] })
      this.setState({ loading: false })
      this.setState({ tableLoading: false })
    } catch (err) {
      toast.error('Could not fetch referrals, please try again')
      console.log('TSP: error when getting referrals from backend', err)
    }
  }

  async getPatientTimeSpent() {
    const { token, mongoUser, firebaseUser } = this.props
    const url = `${Config.BACKEND_URL}time-spent/patient`
    this.setState({ loading: true })
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          // x_doctor_id: mongoUser.firebase_id,
          search_month:
            moment(
              moment(this.state.searchMonth).format('YYYY-MM-DD'),
            ).month() + 1,
          search_year: moment(
            moment(this.state.searchMonth).format('YYYY-MM-DD'),
          ).year(),
        },
      })

      const events = response.data
      // console.log('PATIENT TIME SPENT:', events)
      this.setState({ patientTimeData: events || [] })
      this.setState({ loading: false })
    } catch (err) {
      this.setState({ loading: false })
      toast.error('Could not fetch data, please try again')
      console.log('error when getting patients time spent from backend', err)
    }
  }

  async getmonitoringLogs() {
    const { token, mongoUser, firebaseUser } = this.props
    const url = `${Config.BACKEND_URL}rpm/monitoring-log`
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then(({ data }) => {
        if (data) {
          // console.log('Monitoring logs : ', data)
          this.setState({ monitoringTime: data || [] })
        }
      })
      .catch((err) => {
        console.log('error when getting lab orders', err)
      })
  }

  async getSupportRequest() {
    const { token, adminId, firebaseUser, loading, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'support-request'
    let config = {
      method: 'get',
      url,
      headers: {
        x_firebase_id: mongoUser.firebase_id,
        Authorization: `JWT ${token}`,
        admin: true,
      },
    }
    // if(mongoUser?.user_type?.toLowerCase() === 'administrator')
    //   config.headers.admin = true
    axios(config)
      .then(({ data }) => {
        // console.log('SUPPORT REQUESTS: ', data)
        this.setState({
          supportRequestTime: data,
        })
      })
      .catch((err) => console.log('err while fetching support list: ', err))
  }

  async getAllCommunications() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}communications/phone`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        // x_firebase_id: adminId,
        // getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        // console.log(data)
        if (data) {
          // console.log('COMMUNICATIONS:', data)
          this.setState({ allCommunications: data })
        }
      })
      .catch((err) => {
        console.log('error when getting all communications', err)
      })
  }

  async getReferringProviders() {
    const { token, mongoUser, firebaseUser } = this.props
    const url = `${Config.BACKEND_URL}referring-providers`
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_doctor_id: mongoUser.firebase_id,
        },
      })

      const events = response.data
      this.setState({ referringProviders: events || [] })
      this.setState({ loading: false })
    } catch (err) {
      toast.error('Could not fetch referring providers, please try again')
      console.log('RP: error when getting referrals from backend', err)
    }
  }

  async saveRefrringProvider() {
    const { token, mongoUser, firebaseUser } = this.props
    let {
      firstName,
      lastName,
      organization,
      phone,
      email,
      specialty,
      state,
      fax,
    } = this.state
    this.setState({ loading: true })

    const url = `${Config.BACKEND_URL}referring-providers`
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      fax: fax,
      organization: organization,
      state: state,
      specialty: specialty,
      created_at: moment.utc().format(),
    }
    // console.log(data)
    try {
      const response = await axios({
        method: 'post',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_doctor_id: mongoUser.firebase_id,
        },
        data: data,
      })

      const events = response.data

      console.log('Saved referring provider')
      this.getReferringProviders()
      this.setState({
        loading: false,
        type: null,
        firstName: '',
        lastName: '',
        specialty: null,
        organization: '',
        phone: '',
        email: '',
        fax: '',
        state: '',
      })
    } catch (err) {
      toast.error('Could not fetch referring providers, please try again')
      console.log('REFP: error when getting referrals from backend', err)
      this.setState({ loading: false })
    }
  }

  onPatientNavLinkClicked = (lp) => {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  navigatetoPatientProfile = (p_id) => {
    const { patientList, patientTimer, updateRender, adminId, token } =
      this.props
    console.log('testing on click: ', p_id)
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        // console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (patientTimer && patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(patient.uid, token, timeline)
            this.props.fetchPatientCoreDate(patient.uid, token)
            this.props.fetchPatientWellnessScreening(patient.uid, token)
            this.props.fetchPatientCalculateProgressData(patient.uid, token)
            this.props.fetchPatientConditions(patient.uid, token)
            this.props.fetchPatientMedications(patient.uid, token)
            this.props.fetchPatientLabs(patient.uid, token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!updateRender)
          }
        })
      }
    })
  }

  renderReferringProviderModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1%',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.props.toggleSidebar(false)
            this.setState({ showReferringModal: false })
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Referral Partner
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '5% 0% 5% 4%',
          }}
        >
          <div className={Styles.row} style={{ position: 'relative' }}>
            <p className={Styles.rowLabel}>First Name</p>

            <input
              placeholder={'Enter First Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Last Name</p>

            <input
              placeholder={'Enter Last Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Specialty</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder="Select specialty"
                inputId="specialty"
                options={Metadata.SPECIALITY_OPTIONS}
                value={this.state.specialty ? this.state.specialty : null}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ specialty: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Organization</p>
            <input
              placeholder={'Enter Organization'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.organization}
              onChange={(e) => this.setState({ organization: e.target.value })}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>State</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select'}
                isMulti={false}
                options={Metadata.stateResidence}
                value={this.state.state ? this.state.state : null}
                onChange={(val) => this.setState({ state: val })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Email</p>
            <input
              placeholder={'Enter Email'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Fax Number</p>
            <input
              placeholder={'Enter Fax Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.fax}
              onChange={(e) => this.setState({ fax: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Phone</p>
            <input
              placeholder={'Enter Phone'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.phone}
              onChange={(e) => this.setState({ phone: e.target.value })}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.saveRefrringProvider()
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  paymentData() {
    return (
      <>
        <div
          style={{
            //   width: 730,
            height: '50vh',
          }}
        >
          {/* <LineChart
                      width={730}
                      height={250}
                      data={this.state.lineChartData}
                      // data={this.state.pieChartData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name" />
                      <YAxis dataKey="value" />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} />
                      <Line
                        name="pv of pages"
                        type="monotone"
                        dataKey="key"
                        stroke="#8884d8"
                      />
                      <Line
                        name="uv of pages"
                        type="monotone"
                        dataKey="value"
                        stroke="#82ca9d"
                      />
                    </LineChart> */}
          <h1
            style={{
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Line Char of Payment data
          </h1>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={730}
              height={50}
              //   data={this.state.paymentData}
              data={this.state.basicData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="_id" />
              <YAxis dataKey="total" type="number" domain={[0, 6000]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Line
                name="Total Billed"
                type="monotone"
                dataKey="total_dollar_billed"
                stroke="#8884d8"
              />
              <Line
                name="Total Reimbursed"
                type="monotone"
                dataKey="total_paid"
                stroke="#82ca9d"
              />
              <Line
                name="Copay Amount"
                type="monotone"
                dataKey="total_dollar_copay"
                stroke="red"
              />
              <Line
                name="Stripe Amount"
                type="monotone"
                dataKey="total_dollar_stripe"
                stroke="red"
              />
              <Line
                name="Era Paid Amount"
                type="monotone"
                dataKey="total_era_paid"
                stroke="red"
              />
              <Line
                name="Write off Amount"
                type="monotone"
                dataKey="total_write_off"
                stroke="black"
              />
              {/* <Line
                name="all_access"
                type="monotone"
                dataKey="all_access"
                stroke="#000000"
              />
              <Line
                name="membership"
                type="monotone"
                dataKey="membership"
                stroke="green"
              />
              <Line
                name="appointment"
                type="monotone"
                dataKey="appointment"
                stroke="blue"
              /> */}
              <Legend verticalAlign="top" height={40} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </>
    )
  }

  unpaidPaymentDataGraph() {

    const data = [
      {
        name: '15-30',
        uv: 0,
        pv: 125,
        amt: 0,
      },
      {
        name: '30-45',
        uv: 0,
        pv: 284,
        amt: 0,
      },
      {
        name: '45-60',
        uv: 0,
        pv: 1087,
        amt: 0,
      },
      {
        name: '60-75',
        uv: 0,
        pv: 1363,
        amt: 0,
      },
      {
        name: '75-90',
        uv: 0,
        pv: 1574,
        amt: 0,
      },
      {
        name: '90-105',
        uv: 0,
        pv: 852,
        amt: 0,
      },
      {
        name: '105-120',
        uv: 0,
        pv: 832,
        amt: 0,
      },
      {
        name: '>120',
        uv: 0,
        pv: 6477.85,
        amt: 0,
      },
    ]
    return (
      <>
        <div
          style={{
            //   width: 730,
            height: '50vh',
          }}
        >
          <h1
            style={{
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Aging Analysis Graph
          </h1>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="pv"
                fill="#ff0000"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                name="Current Aging"
              />
              <Bar
                dataKey="amt"
                fill="#8884d8"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                name="Aging on last report"
              />
              <Bar
                dataKey="uv"
                fill="#82ca9d"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
                name="Aging on 1st June"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    )
  }

  unpaidPaymentData() {
    const agingReport = [
      {
        count: 1,
        totalClaimAmount: 125,
        totalDueAmount: 0,
        ageCategory: 15,
      },
      {
        count: 1,
        totalClaimAmount: 284,
        totalDueAmount: 0,
        ageCategory: 30,
      },
      {
        count: 8,
        totalClaimAmount: 1371,
        totalDueAmount: 19.88,
        ageCategory: 45,
      },
      {
        count: 7,
        totalClaimAmount: 1363,
        totalDueAmount: 0,
        ageCategory: 60,
      },
      {
        count: 9,
        totalClaimAmount: 1574,
        totalDueAmount: 0,
        ageCategory: 75,
      },
      {
        count: 7,
        totalClaimAmount: 1704,
        totalDueAmount: 148,
        ageCategory: 90,
      },
      {
        count: 5,
        totalClaimAmount: 832,
        totalDueAmount: 0,
        ageCategory: 105,
      },
      {
        count: 61,
        totalClaimAmount: 6900,
        totalDueAmount: 1565.85,
        ageCategory: '>120',
      },
    ]
    // const data = [
    //   { name: 'Aging on 1st June', age: '-', gender: '-' },
    //   { name: 'Aging on last report', age: '-', gender: '-' },
    //   { name: 'Current Aging', age: '-', gender: '-' },
    // ]
    const data = [
      ['Aging on 1st June', '-', '-', '-', '-', '-', '-', '-', '-'],
      ['Aging on last report', '-', '-', '-', '-', '-', '-', '-', '-'],
      [
        'Current Aging',
        '125',
        '284',
        '1087',
        '1363',
        '1574',
        '852',
        '832',
        '6477.85',
      ],
    ]
    return (
      <>
        <div
          style={{
            //   width: 730,
            // height: '50vh',
            padding: '10px',
            overflowX: 'auto', // Make the table scrollable horizontally if necessary
            border: '2px solid black', // Add a black border around the div
            boxSizing: 'border-box', // Include padding and border in the element's total width and height
          }}
        >
          <h1
            style={{
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Aging Analysis
          </h1>
          <div
            style={{
              overflowX: 'auto',
            }}
          >
            <table
              style={{
                width: '100%', // Set the table width to 100% of the container
                borderCollapse: 'collapse', // Ensure borders are collapsed for a cleaner look
              }}
            >
              <tr>
                {[
                  'Aging Report',
                  // '0-15',
                  '15-30',
                  '30-45',
                  '45-60',
                  '60-75',
                  '75-90',
                  '90-105',
                  '105-120',
                  '>120',
                ].map((val, key) => {
                  return (
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {val}
                    </th>
                  )
                })}
              </tr>
              {data.map((val, key) => {
                return (
                  <tr
                    key={key}
                    style={{ maxHeight: '20px', overflow: 'hidden' }}
                  >
                    {val.map((value, ikey) => {
                      return (
                        <td
                          style={{ padding: '8px', border: '1px solid #ddd' }}
                        >
                          {value}
                        </td>
                      )
                    })}
                    {/* <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {val.name}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {val.age}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {val.gender}
                    </td> */}
                  </tr>
                )
              })}
            </table>
          </div>
        </div>
      </>
    )
  }

  unpaidPaymentData1() {
    const data = [
      { name: 'Anom', age: 19, gender: 'Male' },
      { name: 'Megha', age: 19, gender: 'Female' },
      { name: 'Subham', age: 25, gender: 'Male' },
    ]
    return (
      <>
        <div
          style={{
            //   width: 730,
            // height: '50vh',
            padding: '10px',
            overflowX: 'auto', // Make the table scrollable horizontally if necessary
            border: '2px solid black', // Add a black border around the div
            boxSizing: 'border-box', // Include padding and border in the element's total width and height
          }}
        >
          <h1
            style={{
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Aging Analysis
          </h1>

          <Table1 striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Metric Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr key="0" style={{ maxHeight: '20px', overflow: 'hidden' }}>
                <td>1</td>
                <td>Total number of users on the app</td>
                <td>1</td>
              </tr>
            </tbody>
          </Table1>
        </div>
      </>
    )
  }

  unpaidPaymentSplitData() {
    const agingReport = [
      {
        count: 1,
        totalClaimAmount: 125,
        totalDueAmount: 0,
        ageCategory: 15,
      },
      {
        count: 1,
        totalClaimAmount: 284,
        totalDueAmount: 0,
        ageCategory: 30,
      },
      {
        count: 8,
        totalClaimAmount: 1371,
        totalDueAmount: 19.88,
        ageCategory: 45,
      },
      {
        count: 7,
        totalClaimAmount: 1363,
        totalDueAmount: 0,
        ageCategory: 60,
      },
      {
        count: 9,
        totalClaimAmount: 1574,
        totalDueAmount: 0,
        ageCategory: 75,
      },
      {
        count: 7,
        totalClaimAmount: 1704,
        totalDueAmount: 148,
        ageCategory: 90,
      },
      {
        count: 5,
        totalClaimAmount: 832,
        totalDueAmount: 0,
        ageCategory: 105,
      },
      {
        count: 61,
        totalClaimAmount: 6900,
        totalDueAmount: 1565.85,
        ageCategory: '>120',
      },
    ]
    // const data = [
    //   { name: 'Aging on 1st June', age: '-', gender: '-' },
    //   { name: 'Aging on last report', age: '-', gender: '-' },
    //   { name: 'Current Aging', age: '-', gender: '-' },
    // ]
    const data = [
      ['15-30', '125', '0', '125'],
      ['30-45', '284', '0', '284'],
      ['45-60', '1087', '0', '1087'],
      ['60-75', '1363', '0', '1363'],
      ['75-90', '1574', '0', '1574'],
      ['90-105', '852', '0', '852'],
      ['105-120', '832', '0', '832'],
      ['>120', '6477.85', '45', '0'],
      ['Gross Total', '12594.85', '45', '12639.85'],
    ]
    return (
      <>
        <div
          style={{
            //   width: 730,
            // height: '50vh',
            padding: '10px',
            overflowX: 'auto', // Make the table scrollable horizontally if necessary
            border: '2px solid black', // Add a black border around the div
            boxSizing: 'border-box', // Include padding and border in the element's total width and height
          }}
        >
          <h1
            style={{
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Aging Analysis 2
          </h1>
          <div
            style={{
              overflowX: 'auto',
            }}
          >
            <table
              style={{
                width: '100%', // Set the table width to 100% of the container
                borderCollapse: 'collapse', // Ensure borders are collapsed for a cleaner look
              }}
            >
              <tr>
                {['Aging Report', 'Insurance', 'Patient', 'Total'].map(
                  (val, key) => {
                    return (
                      <th style={{ padding: '8px', border: '1px solid #ddd' }}>
                        {val}
                      </th>
                    )
                  },
                )}
              </tr>
              {data.map((val, key) => {
                return (
                  <tr
                    key={key}
                    style={{ maxHeight: '20px', overflow: 'hidden' }}
                  >
                    {val.map((value, ikey) => {
                      return (
                        <td
                          style={{ padding: '8px', border: '1px solid #ddd' }}
                        >
                          {value}
                        </td>
                      )
                    })}
                    {/* <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {val.name}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {val.age}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {val.gender}
                    </td> */}
                  </tr>
                )
              })}
            </table>
          </div>
        </div>
      </>
    )
  }

  showGraphs() {
    return (
      <>
        <div>{this.paymentData()}</div>
        <div
          style={{
            margin: '50px',
          }}
        >
          {this.unpaidPaymentData()}
        </div>

        <div
          style={{
            margin: '50px',
          }}
        >
          {this.unpaidPaymentDataGraph()}
        </div>
        <div
          style={{
            margin: '50px',
          }}
        >
          {this.unpaidPaymentSplitData()}
        </div>
      </>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h4
          style={{
            color: Constants.primaryTheme,
            width: '100%',
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          Ar Graph
        </h4>
        {/* <div style={{ width: '100%', position: 'relative' }}>
          <div
            style={{
              width: 300,
              height: 40,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '2vw',
            }}
          >
            <div
              onClick={() =>
                this.setState(
                  { showProvider: true },
                  () => this.getEventsFromBackendOnly,
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: this.state.showProvider
                  ? Constants.primaryTheme
                  : 'white',
              }}
            >
              <p
                style={{
                  color: this.state.showProvider ? 'white' : 'black',
                }}
              >
                Provider
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({ showProvider: false }, () => {
                  this.getPatientTimeSpent()
                  this.getmonitoringLogs()
                  this.getSupportRequest()
                  this.getAllCommunications()
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: !this.state.showProvider
                  ? Constants.primaryTheme
                  : 'white',
              }}
            >
              <p
                style={{
                  color: !this.state.showProvider ? 'white' : 'black',
                }}
              >
                Patient
              </p>
            </div>
          </div>
        </div> */}

        <div className={GlobalStyles.contentWrapper}>
          {this.state.showProvider ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '2%',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex', // New flex container for Selects
                    // alignItems: 'center', // Adjust alignment if needed
                    // width: '100%',
                  }}
                >
                  <div style={{ width: '200px' }}>
                    <Select
                      placeholder="Select Month"
                      inputId="providerInsightsMonth"
                      value={this.state.providerInsightsMonth}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        this.setState({ tableLoading: true })
                        this.setState({ providerInsightsMonth: val })
                        this.getAllProviders()
                        this.getEventsFromBackendOnly()
                      }}
                      style={{ width: '300px', marginRight: '10px' }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                      options={[
                        { label: 'January', value: 1 },
                        { label: 'February', value: 2 },
                        { label: 'March', value: 3 },
                        { label: 'April', value: 4 },
                        { label: 'May', value: 5 },
                        { label: 'June', value: 6 },
                        { label: 'July', value: 7 },
                        { label: 'August', value: 8 },
                        { label: 'September', value: 9 },
                        { label: 'October', value: 10 },
                        { label: 'November', value: 11 },
                        { label: 'December', value: 12 },
                      ]}
                    />
                  </div>
                  <div style={{ width: '200px' }}>
                    <Select
                      placeholder="Select Year"
                      inputId="providerInsightsYear"
                      value={this.state.providerInsightsYear}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        this.setState({ tableLoading: true })
                        this.setState({ providerInsightsYear: val })
                        this.getAllProviders()
                        this.getEventsFromBackendOnly()
                      }}
                      style={{ width: '300px' }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                      options={[
                        { label: '2020', value: 2020 },
                        { label: '2021', value: 2021 },
                        { label: '2022', value: 2022 },
                        { label: '2023', value: 2023 },
                        { label: '2024', value: 2024 },
                      ]}
                    />
                  </div>
                </div>
              </div>

              {this.showGraphs()}
              {/* {this.state.tableLoading ? (
                <div
                  className={GlobalStyles.container}
                  style={{ overflow: 'hidden', maxHeight: '100vh' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '100%',
                      height: '80vh',
                    }}
                  >
                    <div className={GlobalStyles.loader} />
                  </div>
                </div>
              ) : (
                <Table
                  columns={timeSpentColumns(this.state.provider_availabilities)}
                  data={this.state.columnData}
                />
              )} */}
            </>
          ) : (
            <Table
              columns={patientTimeSpentColumns(
                this.state.monitoringTime,
                this.state.supportRequestTime,
                this.state.allCommunications,
                this.state.searchMonth,
              )}
              data={this.state.patientTimeData}
            />
          )}

          {/* <Modal
            ariaHideApp={false}
            isOpen={this.state.showReferringModal}
            onRequestClose={() => {
              this.props.toggleSidebar(false)
              this.setState({ showReferringModal: false })
            }}
            style={customModalStyles}
            contentLabel="Modal"
          >
            {this.renderReferringProviderModal()}
          </Modal> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArGraph)
