import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/PracticeInfo.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import * as Metadata from '../../metadata.js'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faSearch,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import DatePicker from 'react-date-picker'
import {
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
} from '../../redux/actions'
import firebase from '../../services/firebase'
import Table from '../../components/Table'
import { groupCredColumns } from './Columns/groupCredColumns'
import { contractsColumns } from './Columns/contractsColumns'
import { providerCredColumns } from './Columns/providerCredColumns'
import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const axios = require('axios')

const payerConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'payer',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigPayer = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: payerConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      payer_name: { raw: {} },
      payerid: { raw: {} },
    },
  },
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customProviderModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 200,
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 200,
  },
}

const customEmailModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2147483647,
  },
}

class GroupCredentialing extends Component {
  constructor(props) {
    super(props)
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      modalLoading: false,
      selectedTab: 'Group Credentialing',
      //HEALTH PLAN CREDENTIALING
      healthPlanModal: false,
      healthPlanEmailModal: false,
      providerEmailModal: false,
      sendProviderTinLetter: false,
      selectedPlan: null,
      healthPlanName: '',
      address: '',
      state: null,
      dateOfSubmission: null,
      status: null,
      applicationNumber: '',
      receivedDate: null,
      contactName: '',
      contactEmail: '',
      phoneNumber: '',
      notesList: [],
      noteText: '',
      practiceName: 'Aila Health',
      practiceNpi: '1043980915',
      practiceEinOrTaxId: '873581303',
      practiceBillingEmail: 'credentialing@ailahealth.com',
      practiceState: 'CA',
      practiceAddress:
        '1999 Harrison Street, 18th Floor, #5135, Oakland, CA 94612',
      practiceBillingAddress:
        '1999 Harrison Street, 18th Floor, #5135, Oakland, CA 94612',
      practicePhone: '(415) 340-6174',
      practiceFax: '(720) 500-9759',
      linesOfBusiness: 'PPO/ FFS, Commercial, Medicare',
      selectedProviderHealthPlan: '',
      selectedProviderState: '',
      providerRosterData: [],

      //CONTRACTS
      contractModal: false,
      selectedContract: null,
      uploadingIndex: null,
      otherIndex: null,

      //COLUMN DATA
      groupCredentialList: [],
      contractList: [],

      //SEARCH INSURANCES
      insuranceModalOpen: false,
      searchName: '',
      searchCity: '',
      searchAddress: '',
      searchState: '',
      searchList: [],

      groupCredentialingStatusLastSent: null,
      groupCredentialingInLastSent: null,
      groupCredentialingOutLastSent: null,
      providerCredentialingModal: false,

      selectedProviderData: null,
    }
    this.fuse = null
    this.imageUploadRef = React.createRef()
    this.fileRef = React.createRef()
    this.getAllProviders = this.getAllProviders.bind(this)
    this.getPatientList = this.getPatientList.bind(this)
    this.saveHealthPlan = this.saveHealthPlan.bind(this)
    this.openHealthPlan = this.openHealthPlan.bind(this)
    this.openProviderRoster = this.openProviderRoster.bind(this)
    this.getAllHealthPlans = this.getAllHealthPlans.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    if (this.props.mongoUser) {
      let cohorts = this.props?.mongoUser?.cohorts || []
      let cohortObjects = []
      cohorts.forEach((cohort) =>
        cohortObjects.push({ label: cohort, value: cohort }),
      )
      this.setState({ cohortOptions: cohortObjects })
    }

    this.getPatientList()
    this.getAllProviders()
    this.getAllHealthPlans()
  }

  async getAllProviders() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        let providersList = []
        data &&
          data.forEach((item) => {
            providersList.push({
              label: item?.first_name + ' ' + item?.last_name,
              value: item.email,
              isChecked: false,
              firebase_id: item?.firebase_id,
              metadata: item,
              // ...item,
            })
          })

        this.setState({ providersList: providersList })
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        toast.error('Something went wrong')
        this.setState({
          loading: false,
          modalLoading: false,
          showScheduleModal: false,
        })
      })
  }

  getPatientList() {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ||
      mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '?access=admin'
        : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          let patientList = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })

          total &&
            total.forEach((item) => {
              patientList.push({
                label: item?.firstName + ' ' + item?.lastName,
                value: item?.email,
                firebase_id: item?.uid,
                ...item,
              })
            })

          self.setState({
            loading: false,
            modalLoading: false,
            // patientListData: data || {},
            patientList: data ? patientList : [], // default
            completePatientList: patientList,
          })
          self.props.addPatientList(data)
          //   if (self.props.rpmPatientId) {
          //     self.navigateToRpmPatientProfile(data)
          //   }
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }

  async getAllHealthPlans() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}group-credentialing`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
      },
      url,
    })
      .then(async ({ data }) => {
        this.setState({ groupCredentialList: data })
        let contracts = data.filter((each) => each.status === 'In-Network')
        this.setState({ contractList: contracts })
      })
      .catch((err) => {
        console.log('error when getting all healthplans', err)
        toast.error('Something went wrong')
        this.setState({
          loading: false,
          modalLoading: false,
          showScheduleModal: false,
        })
      })
  }

  openHealthPlan(plan) {
    if (this.state.selectedTab === 'Group Credentialing') {
      this.setState({
        selectedPlan: plan,
        healthPlanName: plan.health_plan_name,
        address: plan.address,
        state: plan.state,
        dateOfSubmission: plan.date_of_submission,
        status: plan.status,
        applicationNumber: plan.application_number,
        receivedDate: plan.received_date,
        contactName: plan.contact_name,
        contactEmail: plan.contact_email,
        faxNumber: plan.credentialing_fax,
        phoneNumber: plan.phone_number,
        notesList: plan.notes_list,
      })
      this.props.toggleSidebar(true)
      this.setState({ healthPlanModal: true })
    } else {
      let coverageValues = [
        { value: 'Commercial', label: 'Commercial', isChecked: false },
        { value: 'Medicare', label: 'Medicare', isChecked: false },
        { value: 'Medicaid', label: 'Medicaid', isChecked: false },
        {
          value: "Worker's Compensation",
          label: "Worker's Compensation",
          isChecked: false,
        },
        { value: 'HMO', label: 'HMO', isChecked: false },
        { value: 'POS', label: 'POS', isChecked: false },
        {
          value: 'Open Access Plus (OAP)',
          label: 'Open Access Plus (OAP)',
          isChecked: false,
        },
        { value: 'Network', label: 'Network', isChecked: false },
        { value: 'EPO', label: 'EPO', isChecked: false },
        { value: 'PPO', label: 'PPO', isChecked: false },
        { value: 'Indemnity', label: 'Indemnity', isChecked: false },
      ]
      let temp = {
        health_plan_name: plan.health_plan_name,
        address: plan.address,
        state: plan.state,
        date_of_submission: plan.date_of_submission,
        status: plan.status,
        application_number: plan.application_number,
        received_date: plan.received_date,
        contact_name: plan.contact_name,
        contact_email: plan.contact_email,
        phone_number: plan.phone_number,
        notes_list: plan.notes_list,
        customer_service: plan.customer_service || {
          phone: '',
          email: '',
        },
        provider_portal: plan.provider_portal,
        prior_auth: plan.prior_auth || {
          phone: '',
          fax: '',
        },
        fax_number: plan.fax_number,
        credentialed_providers:
          plan.credentialed_providers || this.state.providersList,
        coverage: plan.coverage || coverageValues,
        reimbursement_rate: plan.reimbursement_rate || {
          primary_care: {
            percentage: '',
            unit: null,
          },
          psychiatrist: {
            percentage: '',
            unit: null,
          },
          psychologist: {
            percentage: '',
            unit: null,
          },
          midlevel_providers: {
            percentage: '',
            unit: null,
          },
          medicaid: '',
          noncovered_services: '',
        },
        claims: plan.claims || {
          address: '',
          state: null,
          zipcode: '',
          claimsDeadline: {
            value: '',
            unit: null,
          },
          planDeadline: {
            value: '',
            unit: null,
          },
        },
        provider_payments: plan.provider_payments,
        added_plan: plan.added_plan || false,
        compliance_requirement: plan.compliance_requirement,
        offshore_attestation: plan.offshore_attestation || false,
        reporting_deadline: plan?.reporting_deadline || {
          date: new Date(),
          unit: null,
        },
        contract_url: plan.contract_url || '',
        effective_date: plan?.effective_date || new Date(),
        contract_termination: plan?.contract_termination || {
          with_cause: '',
          without_cause: '',
          other_notes: '',
        },
        other_docs: plan?.other_docs || [],
      }
      this.setState({ selectedContract: temp, selectedPlan: plan })
      this.props.toggleSidebar(true)
      this.setState({ contractModal: true })
    }
  }

  openProviderRosterModal() {
    this.setState({ providerCredentialingModal: true })
  }

  openProviderRoster(healthPlanName, healthPlanState, id) {
    console.log('Opening provider roster', id)
    this.setState({
      selectedProviderHealthPlan: healthPlanName,
      selectedProviderHealthPlanId: id,
      selectedProviderState: healthPlanState,
      selectedTab: 'Provider Credentialing',
    })
    // TODO: Write code to get the provider in this healthplan
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}group-credentialing/provider`,
      params: {
        id: id,
      },
    })
      .then((response) => {
        let data = response.data
        console.log('Data for this healthplan:', data)
        if (data.success == true) {
          let providerRosterData = data.data
          this.setState({ providerRosterData })
        }
      })
      .catch((err) => {
        toast.error('Error getting data!')
        console.log('Error when getting data for the health plan')
      })
  }

  saveHealthPlan() {
    const {
      healthPlanModal,
      selectedPlan,
      healthPlanName,
      address,
      state,
      dateOfSubmission,
      status,
      applicationNumber,
      receivedDate,
      contactName,
      contactEmail,
      faxNumber,
      phoneNumber,
      notesList,
    } = this.state
    const { token } = this.props
    const url = selectedPlan
      ? `${Config.BACKEND_URL}group-credentialing?id=${selectedPlan._id}`
      : `${Config.BACKEND_URL}group-credentialing`
    let toReturn =
      this.state.selectedTab === 'Group Credentialing'
        ? {
            //HEALTH PLAN CREDENTIALING
            health_plan_name: healthPlanName,
            address: address,
            state: state,
            date_of_submission: dateOfSubmission,
            status: status,
            application_number: applicationNumber,
            received_date: receivedDate,
            contact_name: contactName,
            contact_email: contactEmail,
            credentialing_fax: faxNumber,
            phone_number: phoneNumber,
            notes_list: notesList,
          }
        : this.state.selectedContract

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: toReturn,
    })
      .then(({ data }) => {
        console.log('successfully added health plan')
        this.getAllHealthPlans()
        this.closeAllModals()
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again')
        console.log('error when adding doctor data to backend', err)
      })
  }

  saveProviderRoster() {
    const {
      healthPlanModal,
      selectedPlan,
      healthPlanName,
      address,
      state,
      contactName,
      contactEmail,
      faxNumber,
      phoneNumber,
      notesList,
      providerName,
      providerEin,
      dateOfSubmission,
      applicationNumber,
      status,
      inNetworkStatus,
      receivedDate,
      noteText,
    } = this.state
    const { token } = this.props
    const url = `${Config.BACKEND_URL}group-credentialing/provider`
    let toReturn = {
      provider_name: providerName,
      provider_id: this.state.selectedProviderData.firebase_id,
      provider_ein: providerEin,
      date_of_submission: dateOfSubmission,
      application_number: applicationNumber,
      application_status: status,
      in_network_status: inNetworkStatus,
      received_date: receivedDate,
      notes: noteText,
      id: this.state.selectedProviderHealthPlanId,
    }

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: toReturn,
    })
      .then((response) => {
        console.log('successfully added health plan')
        console.log('successfully added provider to health plan')
        // this.getAllHealthPlans()
        // this.closeAllModals()
        if (response.data?.success == true) {
          this.setState({
            providerRosterData: [...this.state.providerRosterData, toReturn],
            providerCredentialingModal: false,
          })
        }
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again')
        console.log('error when adding doctor data to backend', err)
      })
  }

  onFileChange = (event) => {
    const { token, adminId } = this.props
    let { uploadingIndex, otherIndex, selectedContract } = this.state
    const self = this
    // Update the state
    self.setState({ modalLoading: true })
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      let storageUrl = `practiceInfo/${selectedContract?.health_plan_name}_${adminId}/${uploadingIndex}/${file.name}`
      if (uploadingIndex === 'other-docs')
        storageUrl = `practiceInfo/${selectedContract?.health_plan_name}_${adminId}/${uploadingIndex}/${file.name}`

      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef.child(storageUrl).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.setState({ modalLoading: false })
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL)

            //SAVE EDUCATION
            let temp = { ...selectedContract }

            switch (uploadingIndex) {
              case 'contract':
                temp.contract_url = downloadURL
                temp.contract_file_name = file.name
                break
              case 'other-docs':
                let tempQ = [...selectedContract.other_docs]
                tempQ.push({
                  doc_url: downloadURL,
                  doc_name: file.name,
                })
                temp.other_docs = tempQ
                break
              default:
                console.log('no option')
            }
            self.setState({ selectedContract: temp, modalLoading: false })
          })
        },
      )
    }
  }

  closeAllModals() {
    this.props.toggleSidebar(false)
    this.setState({
      healthPlanModal: false,
      selectedPlan: null,
      healthPlanName: '',
      address: '',
      state: null,
      dateOfSubmission: null,
      status: null,
      applicationNumber: '',
      receivedDate: null,
      contactName: '',
      contactEmail: '',
      faxNumber: '',
      phoneNumber: '',
      notesList: [],
      noteText: '',

      selectedContract: null,
      contractModal: false,

      insuranceModalOpen: false,
      searchName: '',
      searchCity: '',
      searchAddress: '',
      searchState: '',
      searchList: [],

      providerCredentialingModal: false,
    })
  }

  renderContractModal() {
    let { selectedContract } = this.state

    if (this.state.modalLoading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          // height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1%',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.closeAllModals()
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Contract
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '5% 0% 5% 4%',
          }}
        >
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Payer Name</p>

            <input
              placeholder={'Enter Payer Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={selectedContract?.health_plan_name}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.health_plan_name = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Address</p>

            <input
              placeholder={'Enter Address'}
              className={Styles.studyEntryInput}
              type="text"
              value={selectedContract?.address}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.address = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>State</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder="Select State from Dropdown"
                inputId="primarystate"
                options={Metadata.stateResidence}
                value={
                  selectedContract?.state
                    ? {
                        label: selectedContract?.state,
                        value: selectedContract?.state,
                        abbreviation: selectedContract?.state,
                      }
                    : null
                }
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  let temp = { ...selectedContract }
                  temp.state = val.abbreviation
                  this.setState({ selectedContract: temp })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Contact</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <input
                placeholder={'Enter Name'}
                className={Styles.studyEntryInput}
                style={{ width: '30%' }}
                type="text"
                value={selectedContract?.contact_name}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.contact_name = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
              <input
                placeholder={'Enter Email'}
                className={Styles.studyEntryInput}
                style={{ width: '30%' }}
                type="text"
                value={selectedContract?.contact_email}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.contact_email = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
              <input
                placeholder={'Enter Phone'}
                style={{ width: '30%' }}
                className={Styles.studyEntryInput}
                type="text"
                value={selectedContract?.phone_number}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.phone_number = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Customer Service</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <input
                placeholder={'Enter Phone'}
                className={Styles.studyEntryInput}
                style={{ width: '45%' }}
                type="text"
                value={selectedContract?.customer_service?.phone}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.customer_service.phone = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
              <input
                placeholder={'Enter Email'}
                style={{ width: '45%' }}
                className={Styles.studyEntryInput}
                type="text"
                value={selectedContract?.customer_service?.email}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.customer_service.email = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Provider Portal</p>
            <input
              placeholder={'Enter Link'}
              className={Styles.studyEntryInput}
              type="text"
              value={selectedContract?.provider_portal}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.provider_portal = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>
              Referrals & Prior Authorization Fax
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <input
                placeholder={'Enter Phone'}
                className={Styles.studyEntryInput}
                style={{ width: '45%' }}
                type="text"
                value={selectedContract?.prior_auth?.phone}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.prior_auth.phone = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
              <input
                placeholder={'Enter Fax'}
                className={Styles.studyEntryInput}
                style={{ width: '45%' }}
                type="text"
                value={selectedContract?.prior_auth?.fax}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.prior_auth.fax = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Credentialing</p>
            <input
              placeholder={'Enter Fax Number'}
              className={Styles.studyEntryInput}
              type="text"
              value={selectedContract?.fax_number}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.fax_number = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Credentialed Providers</p>
          </div>
          <div
            style={{
              height: '40vh',
              overflowY: 'auto',
              backgroundColor: '#F3F3F3',
              width: '60%',
              marginLeft: '25%',
              marginTop: '-4%',
              marginBottom: '2%',
            }}
          >
            {selectedContract?.credentialed_providers &&
              selectedContract?.credentialed_providers?.map(
                (provider, index) => (
                  <div className={Styles.row}>
                    <label
                      style={{ color: '#20A892' }}
                      class={Styles.checkContainer}
                    >
                      {provider.label}
                      <input
                        type="checkbox"
                        checked={provider.isChecked}
                        onClick={() => {
                          let tempQ = { ...selectedContract }
                          let temp = [...tempQ?.credentialed_providers]
                          temp[index].isChecked = !temp[index].isChecked
                          tempQ.credentialed_providers = temp
                          this.setState({ selectedContract: tempQ })
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </div>
                ),
              )}
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Categories of Coverage</p>
          </div>
          <div
            style={{
              height: '40vh',
              overflowY: 'auto',
              backgroundColor: '#F3F3F3',
              width: '60%',
              marginLeft: '25%',
              marginTop: '-4%',
              marginBottom: '2%',
            }}
          >
            {selectedContract?.coverage &&
              selectedContract?.coverage?.map((provider, index) => (
                <div className={Styles.row}>
                  <label
                    style={{ color: '#20A892' }}
                    class={Styles.checkContainer}
                  >
                    {provider?.label}
                    <input
                      type="checkbox"
                      checked={provider?.isChecked}
                      onClick={() => {
                        let tempQ = { ...selectedContract }
                        let temp = [...tempQ?.coverage]
                        temp[index].isChecked = !temp[index].isChecked
                        tempQ.coverage = temp
                        this.setState({ selectedContract: tempQ })
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>
                </div>
              ))}
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Reimbursement Rate</p>
          </div>
          <div>
            <div className={Styles.row}>
              <p className={Styles.rowLabel} style={{ color: 'gray' }}>
                MD (Primary Care, Internal Medicine)
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '60%',
                }}
              >
                <input
                  placeholder={'Enter Percentage %'}
                  className={Styles.studyEntryInput}
                  style={{ width: '45%' }}
                  type="text"
                  value={
                    selectedContract?.reimbursement_rate?.primary_care
                      ?.percentage
                  }
                  onChange={(e) => {
                    let temp = { ...selectedContract }
                    temp.reimbursement_rate.primary_care.percentage =
                      e.target.value
                    this.setState({ selectedContract: temp })
                  }}
                />
                %
                <div style={{ width: '45%' }}>
                  <Select
                    placeholder="Select"
                    inputId="primarystate"
                    options={[{ label: 'Medicare FFS', value: 'Medicare FFS' }]}
                    value={
                      selectedContract?.reimbursement_rate?.primary_care?.unit
                        ? {
                            label:
                              selectedContract?.reimbursement_rate?.primary_care
                                ?.unit,
                            value:
                              selectedContract?.reimbursement_rate?.primary_care
                                ?.unit,
                          }
                        : null
                    }
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...selectedContract }
                      temp.reimbursement_rate.primary_care.unit = val.label
                      this.setState({ selectedContract: temp })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className={Styles.row}>
              <p className={Styles.rowLabel} style={{ color: 'gray' }}>
                MD (Psychiatrists)
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '60%',
                }}
              >
                <input
                  placeholder={'Enter Percentage %'}
                  className={Styles.studyEntryInput}
                  style={{ width: '45%' }}
                  type="text"
                  value={
                    selectedContract?.reimbursement_rate?.psychiatrist
                      ?.percentage
                  }
                  onChange={(e) => {
                    let temp = { ...selectedContract }
                    temp.reimbursement_rate.psychiatrist.percentage =
                      e.target.value
                    this.setState({ selectedContract: temp })
                  }}
                />
                %
                <div style={{ width: '45%' }}>
                  <Select
                    placeholder="Select"
                    inputId="primarystate"
                    options={[{ label: 'Medicare FFS', value: 'Medicare FFS' }]}
                    value={
                      selectedContract?.reimbursement_rate?.psychiatrist?.unit
                        ? {
                            label:
                              selectedContract?.reimbursement_rate?.psychiatrist
                                ?.unit,
                            value:
                              selectedContract?.reimbursement_rate?.psychiatrist
                                ?.unit,
                          }
                        : null
                    }
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...selectedContract }
                      temp.reimbursement_rate.psychiatrist.unit = val.label
                      this.setState({ selectedContract: temp })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className={Styles.row}>
              <p className={Styles.rowLabel} style={{ color: 'gray' }}>
                Ph.D. (Psychologist)
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '60%',
                }}
              >
                <input
                  placeholder={'Enter Percentage %'}
                  className={Styles.studyEntryInput}
                  style={{ width: '45%' }}
                  type="text"
                  value={
                    selectedContract?.reimbursement_rate?.psychologist
                      ?.percentage
                  }
                  onChange={(e) => {
                    let temp = { ...selectedContract }
                    temp.reimbursement_rate.psychologist.percentage =
                      e.target.value
                    this.setState({ selectedContract: temp })
                  }}
                />
                %
                <div style={{ width: '45%' }}>
                  <Select
                    placeholder="Select"
                    inputId="primarystate"
                    options={[{ label: 'Medicare FFS', value: 'Medicare FFS' }]}
                    value={
                      selectedContract?.reimbursement_rate?.psychologist?.unit
                        ? {
                            label:
                              selectedContract?.reimbursement_rate?.psychologist
                                ?.unit,
                            value:
                              selectedContract?.reimbursement_rate?.psychologist
                                ?.unit,
                          }
                        : null
                    }
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...selectedContract }
                      temp.reimbursement_rate.psychologist.unit = val.label
                      this.setState({ selectedContract: temp })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className={Styles.row}>
              <p className={Styles.rowLabel} style={{ color: 'gray' }}>
                Mid-Level Providers (NP,PA,LCSW,LPC,etc)
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '60%',
                }}
              >
                <input
                  placeholder={'Enter Percentage %'}
                  className={Styles.studyEntryInput}
                  style={{ width: '45%' }}
                  type="text"
                  value={
                    selectedContract?.reimbursement_rate?.midlevel_providers
                      ?.percentage
                  }
                  onChange={(e) => {
                    let temp = { ...selectedContract }
                    temp.reimbursement_rate.midlevel_providers.percentage =
                      e.target.value
                    this.setState({ selectedContract: temp })
                  }}
                />
                %
                <div style={{ width: '45%' }}>
                  <Select
                    placeholder="Select"
                    inputId="primarystate"
                    options={[{ label: 'Medicare FFS', value: 'Medicare FFS' }]}
                    value={
                      selectedContract?.reimbursement_rate?.midlevel_providers
                        ?.unit
                        ? {
                            label:
                              selectedContract?.reimbursement_rate
                                ?.midlevel_providers?.unit,
                            value:
                              selectedContract?.reimbursement_rate
                                ?.midlevel_providers?.unit,
                          }
                        : null
                    }
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...selectedContract }
                      temp.reimbursement_rate.midlevel_providers.unit =
                        val.label
                      this.setState({ selectedContract: temp })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className={Styles.row}>
              <p className={Styles.rowLabel} style={{ color: 'gray' }}>
                Other (Medicaid)
              </p>
              <textarea
                placeholder={'Enter notes'}
                className={Styles.studyEntryInput}
                rows="2"
                type="text"
                value={selectedContract?.reimbursement_rate?.medicaid}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.reimbursement_rate.medicaid = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
            </div>
            <div className={Styles.row}>
              <p className={Styles.rowLabel} style={{ color: 'gray' }}>
                {' '}
                Non-Covered Services
              </p>
              <textarea
                placeholder={'Enter notes'}
                className={Styles.studyEntryInput}
                rows="2"
                type="text"
                value={
                  selectedContract?.reimbursement_rate?.noncovered_services
                }
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.reimbursement_rate.noncovered_services = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Claims Submission Address</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <input
                placeholder={'Address'}
                className={Styles.studyEntryInput}
                style={{ width: '30%' }}
                type="text"
                value={selectedContract?.claims?.address}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.claims.address = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
              <div style={{ width: '30%' }}>
                <Select
                  placeholder="State"
                  inputId="primarystate"
                  options={Metadata.stateResidence}
                  value={
                    selectedContract?.claims?.state
                      ? {
                          label: selectedContract?.claims?.state,
                          value: selectedContract?.claims?.state,
                          abbreviation: selectedContract?.claims?.state,
                        }
                      : null
                  }
                  isMulti={false}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    let temp = { ...selectedContract }
                    temp.claims.state = val.abbreviation
                    this.setState({ selectedContract: temp })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>

              <input
                placeholder={'Zipcode'}
                style={{ width: '30%' }}
                className={Styles.studyEntryInput}
                type="text"
                value={selectedContract?.claims?.zipcode}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.claims.zipcode = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>
              Deadline to submit Claims from Date of Service
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <input
                placeholder={'Number'}
                className={Styles.studyEntryInput}
                style={{ width: '45%' }}
                type="text"
                value={selectedContract?.claims?.claimsDeadline?.value}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.claims.claimsDeadline.value = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
              <div style={{ width: '45%' }}>
                <Select
                  placeholder="Select"
                  inputId="primarystate"
                  options={[
                    {
                      label: 'Days',
                      value: 'Days',
                    },
                    { label: 'Months', value: 'Months' },
                    { label: 'Years', value: 'Years' },
                  ]}
                  value={
                    selectedContract?.claims?.claimsDeadline?.unit
                      ? {
                          label: selectedContract?.claims?.claimsDeadline?.unit,
                          value: selectedContract?.claims?.claimsDeadline?.unit,
                        }
                      : null
                  }
                  isMulti={false}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    let temp = { ...selectedContract }
                    temp.claims.claimsDeadline.unit = val.label
                    this.setState({ selectedContract: temp })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Plan will pay Claims within</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <input
                placeholder={'Number'}
                className={Styles.studyEntryInput}
                style={{ width: '45%' }}
                type="text"
                value={selectedContract?.claims?.planDeadline?.value}
                onChange={(e) => {
                  let temp = { ...selectedContract }
                  temp.claims.planDeadline.value = e.target.value
                  this.setState({ selectedContract: temp })
                }}
              />
              <div style={{ width: '45%' }}>
                <Select
                  placeholder="Select"
                  inputId="primarystate"
                  options={[
                    {
                      label: 'Days',
                      value: 'Days',
                    },
                    { label: 'Months', value: 'Months' },
                    { label: 'Years', value: 'Years' },
                  ]}
                  value={
                    selectedContract?.claims?.planDeadline?.unit
                      ? {
                          label: selectedContract?.claims?.planDeadline?.unit,
                          value: selectedContract?.claims?.planDeadline?.unit,
                        }
                      : null
                  }
                  isMulti={false}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    let temp = { ...selectedContract }
                    temp.claims.planDeadline.unit = val.label
                    this.setState({ selectedContract: temp })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Provider Payments</p>
            <input
              placeholder={'Enter Link'}
              className={Styles.studyEntryInput}
              type="text"
              value={selectedContract?.provider_payments}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.provider_payments = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>

          <div className={Styles.row}>
            <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
              Added Plan for reimbursement through portal
              <input
                type="checkbox"
                checked={selectedContract?.added_plan}
                onClick={() => {
                  let temp = { ...selectedContract }
                  temp.added_plan = !temp?.added_plan
                  this.setState({ selectedContract: temp })
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Compliance & Quality Requirements</p>
            <textarea
              placeholder={'Enter notes'}
              className={Styles.studyEntryInput}
              rows="2"
              type="text"
              value={selectedContract?.compliance_requirement}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.compliance_requirement = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>

          <div className={Styles.row}>
            <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
              Attestation for offshore resources required
              <input
                type="checkbox"
                checked={selectedContract?.offshore_attestation}
                onClick={() => {
                  let temp = { ...selectedContract }
                  temp.offshore_attestation = !temp?.offshore_attestation
                  this.setState({ selectedContract: temp })
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Reporting Deadline</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <div style={{ width: '45%' }}>
                <DatePicker
                  onChange={(date) => {
                    let tempQ = { ...selectedContract }
                    tempQ.reporting_deadline.date = date
                    this.setState({ selectedContract: tempQ })
                  }}
                  value={
                    selectedContract?.reporting_deadline?.date
                      ? new Date(selectedContract?.reporting_deadline?.date)
                      : new Date()
                  }
                  style={{ width: '40%', margin: '0% 0% 0% 5%' }}
                />
              </div>
              <div style={{ width: '45%' }}>
                <Select
                  placeholder="Select"
                  inputId="primarystate"
                  options={[
                    { label: 'Quarterly', value: 'Quarterly' },
                    { label: 'Monthly', value: 'Monthly' },
                    { label: 'Annually', value: 'Annually' },
                  ]}
                  value={
                    selectedContract?.reporting_deadline?.unit
                      ? {
                          label: selectedContract?.reporting_deadline?.unit,
                          value: selectedContract?.reporting_deadline?.unit,
                        }
                      : null
                  }
                  isMulti={false}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    let temp = { ...selectedContract }
                    temp.reporting_deadline.unit = val.label
                    this.setState({ selectedContract: temp })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Contract</p>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '60%' }}
            >
              {selectedContract?.contract_url && (
                <FontAwesomeIcon
                  onClick={() => {
                    console.log('open file')
                    window.open(selectedContract?.contract_url, '_blank')
                  }}
                  icon={faEye}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    width: '10%',
                    cursor: 'pointer',
                  }}
                />
              )}

              <label
                htmlFor="contract-file"
                style={{
                  textDecoration: 'underline',
                  color: Constants.primaryTheme,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  console.log('contract-upload')
                  this.setState({ uploadingIndex: 'contract' })
                }}
              >
                Upload Contract pdf
              </label>
              <input
                id="contract-file"
                accept="application/pdf"
                style={{ display: 'none' }}
                type="file"
                onChange={this.onFileChange}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Contract Effective Date</p>
            <div style={{ width: '60%' }}>
              <DatePicker
                onChange={(date) => {
                  let tempQ = { ...selectedContract }
                  tempQ.effective_date = date
                  this.setState({ selectedContract: tempQ })
                }}
                value={
                  selectedContract?.effective_date
                    ? new Date(selectedContract?.effective_date)
                    : new Date()
                }
                style={{ width: '40%', margin: '0% 0% 0% 5%' }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Contract Termination</p>
          </div>
          <div className={Styles.row}>
            <p
              className={Styles.rowLabel}
              style={{ color: 'gray', textDecoration: 'underline' }}
            >
              With Cause
            </p>
            <textarea
              placeholder={'Enter'}
              className={Styles.studyEntryInput}
              rows="2"
              type="text"
              value={selectedContract?.contract_termination?.with_cause}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.contract_termination.with_cause = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>
          <div className={Styles.row}>
            <p
              className={Styles.rowLabel}
              style={{ color: 'gray', textDecoration: 'underline' }}
            >
              Without Cause
            </p>
            <textarea
              placeholder={'Enter notes'}
              className={Styles.studyEntryInput}
              rows="2"
              type="text"
              value={selectedContract?.contract_termination?.without_cause}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.contract_termination.without_cause = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>
          <div className={Styles.row}>
            <p
              className={Styles.rowLabel}
              style={{ color: 'gray', textDecoration: 'underline' }}
            >
              Other Notes
            </p>
            <textarea
              placeholder={'Enter notes'}
              className={Styles.studyEntryInput}
              rows="2"
              type="text"
              value={selectedContract?.contract_termination?.other_notes}
              onChange={(e) => {
                let temp = { ...selectedContract }
                temp.contract_termination.other_notes = e.target.value
                this.setState({ selectedContract: temp })
              }}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Other Documents</p>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '60%' }}
            >
              <label
                htmlFor="other-docs"
                style={{
                  textDecoration: 'underline',
                  color: Constants.primaryTheme,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  console.log('other-docs')
                  this.setState({
                    uploadingIndex: 'other-docs',
                  })
                }}
              >
                Upload pdf
              </label>
              <input
                id="other-docs"
                accept="application/pdf"
                style={{ display: 'none' }}
                type="file"
                onChange={this.onFileChange}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}></p>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '60%' }}
            >
              {selectedContract?.other_docs?.length
                ? selectedContract?.other_docs?.map((doc, index) => {
                    return (
                      <>
                        <p
                          onClick={() => {
                            console.log('open file')
                            window.open(doc?.doc_url, '_blank')
                          }}
                          style={{
                            textDecoration: 'underline',
                            color: Constants.primaryTheme,
                            cursor: 'pointer',
                          }}
                        >
                          View File_{index + 1}
                        </p>
                      </>
                    )
                  })
                : ''}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                this.saveHealthPlan()
              }}
              className={GlobalStyles.button}
              style={{ width: 300, margin: '4%' }}
              variant="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderHealthPlanEmailModal() {
    // let {}
    return (
      <div
        style={{
          height: '90%',
          width: '90%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          style={{ padding: '4px 20px' }}
          onClick={() => this.setState({ healthPlanEmailModal: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Email Fields
        </h4>
        <div
          style={{
            width: '600px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Email To
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.emailTo}
                onChange={(e) => {
                  this.setState({ emailTo: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Practice Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceName}
                onChange={(e) => {
                  this.setState({ practiceName: e.target.value })
                }}
              />
            </div>
          </div>
          {this.state.sendInNetworkEmail ? (
            <>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Practice Address
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <input
                    placeholder={''}
                    className={Styles.textInput}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.practiceAddress}
                    onChange={(e) => {
                      this.setState({ practiceAddress: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Practice Billing Address
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <input
                    placeholder={''}
                    className={Styles.textInput}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.practiceBillingAddress}
                    onChange={(e) => {
                      this.setState({ practiceBillingAddress: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Practice State
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <input
                    placeholder={''}
                    className={Styles.textInput}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.practiceState}
                    onChange={(e) => {
                      this.setState({ practiceState: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Practice Address
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <input
                    placeholder={''}
                    className={Styles.textInput}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.practicePhone}
                    onChange={(e) => {
                      this.setState({ practicePhone: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Practice Fax
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <input
                    placeholder={''}
                    className={Styles.textInput}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.practiceFax}
                    onChange={(e) => {
                      this.setState({ practiceFax: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div
                className={Styles.filterModalRow}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span
                  className={Styles.filterModalLabel}
                  style={{
                    whiteSpace: 'nowrap',
                    color: Constants.primaryTheme,
                    flex: 1,
                  }}
                >
                  Lines of Business
                </span>
                <div
                  className={Styles.filterModalSelect}
                  style={{ flex: 1, width: '100%' }}
                >
                  <input
                    placeholder={''}
                    className={Styles.textInput}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.linesOfBusiness}
                    onChange={(e) => {
                      this.setState({ linesOfBusiness: e.target.value })
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Practice NPI
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceNpi}
                onChange={(e) => {
                  this.setState({ practiceNpi: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Practice EIN/Tax ID
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceEinOrTaxId}
                onChange={(e) => {
                  this.setState({ practiceEinOrTaxId: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Credentialing email
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceBillingEmail}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ practiceBillingEmail: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center content horizontally
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => {
                let sendData = {
                  id: this.state.selectedPlan?._id,
                  prac_name: this.state.practiceName,
                  prac_npi: this.state.practiceNpi,
                  prac_ein: this.state.practiceEinOrTaxId,
                  prac_cred_email: this.state.practiceBillingEmail,
                  send_to: this.state.emailTo,
                  email_type: 'group-status',
                }
                this.setState({
                  groupCredentialingStatusLastSent:
                    moment().format('MM/DD/YYYY'),
                })
                if (this.state.sendInNetworkEmail === true) {
                  sendData = {
                    ...sendData,
                    prac_state: this.state.practiceState,
                    prac_addr: this.state.practiceAddress,
                    prac_phone: this.state.practicePhone,
                    prac_fax: this.state.practiceFax,
                    line_of_business: this.state.linesOfBusiness,
                    prac_billing_email:
                      this.state.practiceBillingAddress ||
                      this.state.practiceAddress,
                    email_type: 'group-in-network',
                  }
                  this.setState({
                    groupCredentialingInLastSent: moment().format('MM/DD/YYYY'),
                  })
                }
                console.log('emailDetails', sendData)
                axios({
                  method: 'post',
                  url: `${Config.BACKEND_URL}group-credentialing/send-credentialing-email`,
                  headers: { Authorization: `JWT ${this.props.token}` },
                  data: sendData,
                })
                  .then((res) => {
                    console.log('Got data back from cred', res)
                    if (res?.data?.success === true) {
                      toast.success('Email Sent!')
                      this.setState({
                        groupCredentialingStatusLastSent:
                          moment().format('MM/DD/YYYY'),
                        healthPlanEmailModal: false,
                      })
                    } else toast.error('Failed to send email!')
                  })
                  .catch((err) => {
                    console.log('Error in axios request', err)
                  })
              }}
              className={GlobalStyles.button}
              disabled={false}
              style={{
                width: 250,
                // marginLeft: 10,
                backgroundColor: Constants.primaryTheme,
                textAlign: 'center',
              }}
              variant="primary"
              color={Constants.primaryTheme}
              backgroundColor={Constants.primaryTheme}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderProviderEmailModal() {
    // let {}
    return (
      <div
        style={{
          height: '90%',
          width: '90%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          style={{ padding: '4px 20px' }}
          onClick={() => this.setState({ providerEmailModal: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Email Fields
        </h4>
        <div
          style={{
            width: '600px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Email To
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.emailTo}
                onChange={(e) => {
                  this.setState({ emailTo: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Practice Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceName}
                onChange={(e) => {
                  this.setState({ practiceName: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider First Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.providerFirstName}
                onChange={(e) => {
                  this.setState({ providerFirstName: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider Last Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.providerLastName}
                onChange={(e) => {
                  this.setState({ providerLastName: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider Credential
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.providerCredential}
                onChange={(e) => {
                  this.setState({ providerCredential: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider Specialty
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.providerSpecialty}
                onChange={(e) => {
                  this.setState({ providerSpecialty: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider NPI
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.providerNpi}
                onChange={(e) => {
                  this.setState({ providerNpi: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider CAQH
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.providerCAQH}
                onChange={(e) => {
                  this.setState({ providerCAQH: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Practice NPI
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceNpi}
                onChange={(e) => {
                  this.setState({ practiceNpi: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Practice EIN/Tax ID
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceEinOrTaxId}
                onChange={(e) => {
                  this.setState({ practiceEinOrTaxId: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Credentialing email
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.practiceBillingEmail}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ practiceBillingEmail: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center content horizontally
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => {
                let sendData = {
                  id: this.state.selectedPlan?._id,
                  prac_name: this.state.practiceName,
                  prac_npi: this.state.practiceNpi,
                  prac_ein: this.state.practiceEinOrTaxId,
                  prac_cred_email: this.state.practiceBillingEmail,
                  send_to: this.state.emailTo,
                  prov_first_name: this.state.providerFirstName,
                  prov_last_name: this.state.providerLastName,
                  prov_credential: this.state.providerCredential,
                  prov_specialty: this.state.providerSpecialty,
                  prov_npi: this.state.providerNpi,
                  prov_caqh: this.state.providerCAQH,
                  email_type: 'provider-status',
                }
                if (this.state.sendProviderTinLetter === true) {
                  sendData = {
                    ...sendData,
                    email_type: 'provider-in-network',
                  }
                }
                console.log('emailDetails', sendData)
                axios({
                  method: 'post',
                  url: `${Config.BACKEND_URL}group-credentialing/send-credentialing-email`,
                  headers: { Authorization: `JWT ${this.props.token}` },
                  data: sendData,
                })
                  .then((res) => {
                    console.log('Got data back from cred', res)
                    if (res?.data?.success === true) {
                      toast.success('Email Sent!')
                      this.setState({
                        groupCredentialingStatusLastSent:
                          moment().format('MM/DD/YYYY'),
                        providerEmailModal: false,
                      })
                    } else toast.error('Failed to send email!')
                  })
                  .catch((err) => {
                    console.log('Error in axios request', err)
                  })
              }}
              className={GlobalStyles.button}
              disabled={false}
              style={{
                width: 250,
                // marginLeft: 10,
                backgroundColor: Constants.primaryTheme,
                textAlign: 'center',
              }}
              variant="primary"
              color={Constants.primaryTheme}
              backgroundColor={Constants.primaryTheme}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderHealthPlanModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1%',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.closeAllModals()
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Health Plan Credentialing
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '5% 0% 5% 4%',
          }}
        >
          <div className={Styles.row} style={{ position: 'relative' }}>
            <p className={Styles.rowLabel}>Health Plan Name</p>

            {/* <input
              placeholder={'Enter Health Plan Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.healthPlanName}
              onChange={(e) =>
                this.setState({ healthPlanName: e.target.value })
              }
            /> */}
            <SearchProvider config={esConfigPayer}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'payer_name',
                        }}
                        searchAsYouType={true}
                        onSelectAutocomplete={(e) => {
                          setSearchTerm(e?.payer_name?.raw)
                          this.setState({
                            healthPlanId: e?.payerid?.raw,
                            healthPlanName: e?.payer_name?.raw,
                          })
                        }}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Payer Name',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
            {/* <div style={{ position: 'absolute', top: 0, right: '5%' }}>
              <FontAwesomeIcon
                onClick={() => {
                  this.props.toggleSidebar(true)
                  this.setState({ insuranceModalOpen: true })
                }}
                icon={faSearch}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 18,
                  right: 14,
                  cursor: 'pointer',
                }}
              />
            </div> */}
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Address</p>

            <input
              placeholder={'Enter Address'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.address}
              onChange={(e) => this.setState({ address: e.target.value })}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Payer ID</p>

            <input
              placeholder={'Payer ID'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.healthPlanId}
              onChange={(e) => this.setState({ healthPlanId: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>State</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder="Select State from Dropdown"
                inputId="primarystate"
                options={Metadata.stateResidence}
                value={
                  this.state.state
                    ? {
                        label: this.state.state,
                        value: this.state.state,
                        abbreviation: this.state.state,
                      }
                    : null
                }
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ state: val.abbreviation })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Submission Date</p>
            <div style={{ width: '60%' }}>
              <DatePicker
                // minDate={new Date('09/01/2019')}
                // format={'MMM-dd-y'}
                onChange={(date) => {
                  this.setState({ dateOfSubmission: date })
                }}
                value={
                  this.state.dateOfSubmission
                    ? new Date(this.state.dateOfSubmission)
                    : new Date()
                }
                style={{ width: '40%', margin: '0% 0% 0% 5%' }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>
              Status Reference/ Application Number
            </p>
            <input
              placeholder={'Enter Application Number'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.applicationNumber}
              onChange={(e) =>
                this.setState({ applicationNumber: e.target.value })
              }
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Status</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select'}
                isMulti={false}
                options={Metadata.healthPlanStatus}
                value={
                  this.state.status
                    ? {
                        label: this.state.status,
                        value: this.state.status,
                      }
                    : null
                }
                onChange={(val) => this.setState({ status: val.label })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Contract Received Date</p>
            <div style={{ width: '60%' }}>
              <DatePicker
                // minDate={new Date('09/01/2019')}
                // format={'MMM-dd-y'}
                onChange={(date) => {
                  this.setState({ receivedDate: date })
                }}
                value={
                  this.state.receivedDate
                    ? new Date(this.state.receivedDate)
                    : new Date()
                }
                style={{ width: '40%', margin: '0% 0% 0% 5%' }}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Contact Name</p>
            <input
              placeholder={'Enter Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.contactName}
              onChange={(e) => this.setState({ contactName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Contact Email</p>
            <input
              placeholder={'Enter Email'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.contactEmail}
              onChange={(e) => this.setState({ contactEmail: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Credentialing Fax</p>
            <input
              placeholder={'Enter Phone'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.faxNumber}
              onChange={(e) => this.setState({ faxNumber: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Phone Number</p>
            <input
              placeholder={'Enter Phone'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.phoneNumber}
              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Notes</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <textarea
                placeholder={'Enter notes'}
                className={Styles.studyEntryInput}
                style={{ width: '80%' }}
                rows="2"
                type="text"
                value={this.state.noteText || ''}
                onChange={(e) => this.setState({ noteText: e.target.value })}
              />
              <FontAwesomeIcon
                className={Styles.plusButton}
                onClick={() => {
                  // if (this.state.notesList.length) {
                  let tempNote = this.state.notesList
                  tempNote.unshift({
                    author_id: this.props.adminId,
                    name:
                      (this.props?.mongoUser?.first_name || '') +
                      ' ' +
                      (this.props?.mongoUser?.last_name || ''),
                    note_text: this.state.noteText,
                    created_at: new Date(),
                  })
                  this.setState({ notesList: tempNote, noteText: '' })
                  // }
                }}
                icon={faPlusCircle}
              />
            </div>
          </div>
          {this.state.notesList?.map((note, index) => (
            <div className={Styles.row}>
              <p className={Styles.rowLabel}>
                {' '}
                {note.name} {`(${moment(note.created_at).format('ll')})`}:{' '}
              </p>
              <textarea
                placeholder={'Enter notes'}
                className={Styles.studyEntryInput}
                rows="2"
                type="text"
                value={note.note_text || ''}
                onChange={(e) => {
                  let tempNote = this.state.notesList
                  tempNote[index].note_text = e.target.value
                  tempNote[index].updated_at = new Date()
                  this.setState({ notesList: tempNote })
                }}
                disabled={
                  !(
                    note.author_id !== this.props.adminId ||
                    this.props?.mongoUser?.user_type?.toLowerCase() ===
                      'administrator'
                  )
                } // only let authors to edit their note
              />
            </div>
          ))}
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Email Health Plan</p>
            <p
              className={Styles.rowLabel}
              style={{
                textDecoration: 'underline',
              }}
              onClick={() => {
                this.setState({
                  healthPlanEmailModal: true,
                  sendInNetworkEmail: false,
                })
              }}
            >
              Send Group Credentialing Status Update Letter
            </p>
            <p className={Styles.rowLabel} style={{ color: 'black' }}>
              Last Sent: {this.state.groupCredentialingStatusLastSent || 'N/A'}
            </p>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}></p>
            <p
              className={Styles.rowLabel}
              style={{
                textDecoration: 'underline',
              }}
              onClick={() => {
                this.setState({
                  healthPlanEmailModal: true,
                  sendInNetworkEmail: true,
                })
                // axios({
                //   method: 'post',
                //   url: `${Config.BACKEND_URL}send-email-in-network-join`,
                //   headers: { Authorization: `JWT ${this.props.token}` },
                //   data: {
                //     test: true,
                //   },
                // }).then((res) => {
                //   if (res?.data?.success === true) {
                //     toast.success('Email Sent!')
                //     this.setState({
                //       groupCredentialingInLastSent:
                //         moment().format('MM/DD/YYYY'),
                //     })
                //   }
                // })
              }}
            >
              Send Group Credentialing Application - In-network
            </p>
            <p className={Styles.rowLabel} style={{ color: 'black' }}>
              Last Sent: {this.state.groupCredentialingInLastSent || 'N/A'}
            </p>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}></p>
            <p
              className={Styles.rowLabel}
              style={{
                textDecoration: 'underline',
              }}
              onClick={() => {
                toast.warn('Feature not implemented!')
                // this.setState({
                //   groupCredentialingOutLastSent: moment().format('MM/DD/YYYY'),
                // })
              }}
            >
              Send Group Credentialing Application - Out of Network
            </p>
            <p className={Styles.rowLabel} style={{ color: 'black' }}>
              Last Sent: {this.state.groupCredentialingOutLastSent || 'N/A'}
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.saveHealthPlan()
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  renderProviderCredentialingModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1%',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.closeAllModals()
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Provider Credentialing
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '5% 0% 5% 4%',
          }}
        >
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Provider SSN</p>
            <input
              placeholder={'Enter SSN'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.providerSsn}
              onChange={(e) => this.setState({ providerSsn: e.target.value })}
            />
          </div>
          <div className={Styles.row} style={{ position: 'relative' }}>
            <p className={Styles.rowLabel}>EIN</p>

            <input
              placeholder={'Enter EIN'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.providerEin}
              onChange={(e) => this.setState({ providerEin: e.target.value })}
            />
          </div>
          {/* <div className={Styles.row}>
            <p className={Styles.rowLabel}>Provider Name</p>

            <input
              placeholder={'Payer ID'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.providerName}
              onChange={(e) => this.setState({ healthPlanId: e.target.value })}
            />
          </div> */}
          {/* <div className={Styles.row} style={{ position: 'relative' }}>
            <p className={Styles.rowLabel}>EIN</p>

            <input
              placeholder={'EIN'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.providerEIN}
              onChange={(e) => this.setState({ providerEIN: e.target.value })}
            />
          </div> */}
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Provider Name</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select Provider'}
                isMulti={false}
                options={this.state.providersList}
                value={this.state.selectedProviderData}
                onChange={(val) => {
                  this.setState({
                    providerName: val.label,
                    selectedProviderData: val,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>

            {/* <input
              placeholder={'Enter Address'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.providerName}
              onChange={(e) => this.setState({ providerName: e.target.value })}
            /> */}
          </div>

          {/* <div className={Styles.row}>
            <p className={Styles.rowLabel}>Payer ID</p>

            <input
              placeholder={'Payer ID'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.healthPlanId}
              onChange={(e) => this.setState({ healthPlanId: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>State</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder="Select State"
                inputId="primarystate"
                options={Metadata.stateResidence}
                value={
                  this.state.state
                    ? {
                        label: this.state.state,
                        value: this.state.state,
                        abbreviation: this.state.state,
                      }
                    : null
                }
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ state: val.abbreviation })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div> */}
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Date of request to join network</p>
            <div style={{ width: '60%' }}>
              <DatePicker
                onChange={(date) => {
                  this.setState({ dateOfSubmission: date })
                }}
                value={
                  this.state.dateOfSubmission
                    ? new Date(this.state.dateOfSubmission)
                    : new Date()
                }
                style={{ width: '40%', margin: '0% 0% 0% 5%' }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>
              Status Reference/ Application Number
            </p>
            <input
              placeholder={'Enter Application Number'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.applicationNumber}
              onChange={(e) =>
                this.setState({ applicationNumber: e.target.value })
              }
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Application Status</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select'}
                isMulti={false}
                options={Metadata.healthPlanStatus}
                value={
                  this.state.status
                    ? {
                        label: this.state.status,
                        value: this.state.status,
                      }
                    : null
                }
                onChange={(val) => this.setState({ status: val.label })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>In Network Status</p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select'}
                isMulti={false}
                options={Metadata.healthPlanStatus}
                value={
                  this.state.inNetworkStatus
                    ? {
                        label: this.state.inNetworkStatus,
                        value: this.state.inNetworkStatus,
                      }
                    : null
                }
                onChange={(val) =>
                  this.setState({ inNetworkStatus: val.label })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          {/* This was called Contract Received Date before */}
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>In Network Effective Date</p>
            <div style={{ width: '60%' }}>
              <DatePicker
                // minDate={new Date('09/01/2019')}
                // format={'MMM-dd-y'}
                onChange={(date) => {
                  this.setState({ receivedDate: date })
                }}
                value={
                  this.state.receivedDate
                    ? new Date(this.state.receivedDate)
                    : new Date()
                }
                style={{ width: '40%', margin: '0% 0% 0% 5%' }}
              />
            </div>
          </div>
          {/* <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Contact Name</p>
            <input
              placeholder={'Enter Name'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.contactName}
              onChange={(e) => this.setState({ contactName: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Contact Email</p>
            <input
              placeholder={'Enter Email'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.contactEmail}
              onChange={(e) => this.setState({ contactEmail: e.target.value })}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Credentialing Fax</p>
            <input
              placeholder={'Enter Phone'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.faxNumber}
              onChange={(e) => this.setState({ faxNumber: e.target.value })}
            />
          </div> */}
          {/* <div className={Styles.row}>
            <p className={Styles.rowLabel}>Network Phone Number</p>
            <input
              placeholder={'Enter Phone'}
              className={Styles.studyEntryInput}
              type="text"
              value={this.state.phoneNumber}
              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            />
          </div> */}
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Notes</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <textarea
                placeholder={'Enter notes'}
                className={Styles.studyEntryInput}
                style={{ width: '80%' }}
                rows="2"
                type="text"
                value={this.state.noteText || ''}
                onChange={(e) => this.setState({ noteText: e.target.value })}
              />
              <FontAwesomeIcon
                className={Styles.plusButton}
                onClick={() => {
                  // if (this.state.notesList.length) {
                  let tempNote = this.state.notesList
                  tempNote.unshift({
                    author_id: this.props.adminId,
                    name:
                      (this.props?.mongoUser?.first_name || '') +
                      ' ' +
                      (this.props?.mongoUser?.last_name || ''),
                    note_text: this.state.noteText,
                    created_at: new Date(),
                  })
                  this.setState({ notesList: tempNote, noteText: '' })
                  // }
                }}
                icon={faPlusCircle}
              />
            </div>
          </div>
          {this.state.notesList?.map((note, index) => (
            <div className={Styles.row}>
              <p className={Styles.rowLabel}>
                {' '}
                {note.name} {`(${moment(note.created_at).format('ll')})`}:{' '}
              </p>
              <textarea
                placeholder={'Enter notes'}
                className={Styles.studyEntryInput}
                rows="2"
                type="text"
                value={note.note_text || ''}
                onChange={(e) => {
                  let tempNote = this.state.notesList
                  tempNote[index].note_text = e.target.value
                  tempNote[index].updated_at = new Date()
                  this.setState({ notesList: tempNote })
                }}
                disabled={
                  !(
                    note.author_id !== this.props.adminId ||
                    this.props?.mongoUser?.user_type?.toLowerCase() ===
                      'administrator'
                  )
                } // only let authors to edit their note
              />
            </div>
          ))}
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Email Health Plan</p>
            <p
              className={Styles.rowLabel}
              onClick={() => {
                let { selectedProviderData } = this.state
                console.log(
                  'Opening provider status modal',
                  selectedProviderData,
                )
                if (selectedProviderData) {
                  let x = selectedProviderData?.metadata?.user_type?.split(' ')
                  let speciality = selectedProviderData.metadata?.speciality
                  let credential = null
                  if (x) credential = x?.[1]?.replace(/[()]/g, '') || ''
                  console.log('EEER')
                  this.setState({
                    providerEmailModal: true,
                    sendProviderTinLetter: false,

                    // Modal data
                    providerFirstName:
                      selectedProviderData?.metadata?.first_name,
                    providerLastName: selectedProviderData?.metadata?.last_name,
                    providerCredential: credential,
                    providerSpecialty: speciality,
                    providerNpi: selectedProviderData?.metadata?.npi?.number,
                    providerCAQH: selectedProviderData?.metadata?.caqh,
                  })
                  this.setState({
                    groupCredentialingStatusLastSent:
                      moment().format('MM/DD/YYYY'),
                  })
                } else {
                  this.setState({
                    providerEmailModal: true,
                    sendProviderTinLetter: false,

                    // Modal data
                    providerFirstName: null,
                    providerLastName: null,
                    providerCredential: null,
                    providerSpecialty: null,
                    providerNpi: null,
                    providerCAQH: null,
                  })
                }
              }}
            >
              Send Provider Status Update Letter
            </p>
            <p className={Styles.rowLabel} style={{ color: 'black' }}>
              Last Sent: {this.state.groupCredentialingStatusLastSent || 'N/A'}
            </p>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}></p>
            <p
              className={Styles.rowLabel}
              onClick={() => {
                this.setState({
                  providerEmailModal: true,
                  sendProviderTinLetter: true,
                })
                this.setState({
                  groupCredentialingInLastSent: moment().format('MM/DD/YYYY'),
                })
              }}
            >
              Send Link to TIN Letter
            </p>
            <p className={Styles.rowLabel} style={{ color: 'black' }}>
              Last Sent: {this.state.groupCredentialingInLastSent || 'N/A'}
            </p>
          </div>
          {/* <div className={Styles.row}>
            <p className={Styles.rowLabel}></p>
            <p
              className={Styles.rowLabel}
              onClick={() => {
                this.setState({
                  groupCredentialingOutLastSent: moment().format('MM/DD/YYYY'),
                })
              }}
            >
              Send Group Credentialing Application - Out of Network
            </p>
            <p className={Styles.rowLabel} style={{ color: 'black' }}>
              Last Sent: {this.state.groupCredentialingOutLastSent || 'N/A'}
            </p>
          </div> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.saveProviderRoster()
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  async onSearch() {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const { searchName, searchAddress, searchCity, searchState } = this.state
    if (searchName?.length === 0 || !searchState) {
      console.log('Insurance : No NAME and STATE search')
      toast.warning('Please specify Name and State of Insurance')
      return
    }
    try {
      let url = Config.CHANGE_BACKEND_URL
      var config = {
        method: 'get',
        url: `${url}change/search-insurance?city=${searchCity}&address_1=${searchAddress}&name=${searchName}&state=${searchState?.abbreviation}`,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: adminId,
        },
      }

      axios(config)
        .then(({ data }) => {
          if (data?.RESULT?.hasOwnProperty('OBJECT')) {
            Array.isArray(data?.RESULT?.OBJECT)
              ? this.setState({ searchList: data.RESULT.OBJECT })
              : this.setState({ searchList: [data.RESULT.OBJECT] })
          } else if (data?.hasOwnProperty('ERROR')) {
            toast.warning(
              'The search criteria you entered found too many matching records. Make your search criteria more specific.',
            )
          }
        })
        .catch(function (error) {
          // setPharmacyList([])
          toast.error('Could not fetch Insurances, please try again later')
          console.log('error - search insurance', error)
        })
    } catch (error) {
      console.log('error - search insurance', error)
      toast.error('Could not fetch Insurances, please try again later')
    }
  }

  renderSearchList() {
    const {
      searchName,
      searchAddress,
      searchCity,
      searchState,
      searchList,
      acceptedInsurance,
    } = this.state

    const renderList = (insuranceData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.insuranceList}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            backgroundColor: Constants.contentBackground,
            borderRadius: Constants.borderRadius,
            padding: '1% 2%',
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
              }}
            >
              <h6 style={{ color: Constants.primaryThemeDark }}>
                {insuranceData?.name[1]}
                {/* {'\n '}
                  Visit with {insuranceData?.first_name}{' '}
                  {insuranceData?.last_name} */}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.address_1 || ''} ${
                  insuranceData?.city || ''
                }`}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.state || ''} ${insuranceData?.zip || ''} `}
              </h6>
            </div>

            <Button
              className={GlobalStyles.button}
              onClick={() => {
                this.setState({
                  healthPlanName: insuranceData?.name[1],
                  address: `${insuranceData?.address_1},
                  ${insuranceData?.city}, ${insuranceData?.state},
                  ${insuranceData?.zip} `,
                  state: `${insuranceData?.state || ''}`,
                })
                this.setState({
                  insuranceModalOpen: false,
                  searchName: '',
                  searchCity: '',
                  searchAddress: '',
                  searchState: '',
                  searchList: [],
                })
                // this.closeAllModals()
              }}
              style={{
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
            >
              Add
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Insurance
        </h4>
        <div className={Styles.row} style={{ margin: '2% 0%' }}>
          <p className={Styles.rowLabel}>Name</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchName}
              onChange={(e) => {
                this.setState({ searchName: e.target.value })
              }}
            />
          </div>
          {/* <p className={Styles.rowLabel}>Address</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Address"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchAddress}
              onChange={(e) => {
                setSearchAddress(e.target.value)
              }}
            />
          </div> */}
          <p className={Styles.rowLabel}>City</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search City"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchCity}
              onChange={(e) => {
                this.setState({ searchCity: e.target.value })
              }}
            />
          </div>

          <p className={Styles.rowLabel}>State</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <Select
              placeholder="Select State from Dropdown"
              inputId="primarystate"
              options={Metadata.stateResidence}
              value={searchState}
              isMulti={false}
              isSearchable
              autoBlur
              onChange={(val) => {
                this.setState({ searchState: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <FontAwesomeIcon
            onClick={() => {
              //   setActiveInsurance(1)
              console.log(
                'SEARCH PARAMS:',
                searchAddress,
                searchName,
                searchCity,
              )
              this.onSearch()
              //   setShowSearchList(true)
            }}
            icon={faSearch}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </div>

        <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {searchList?.length !== 0 ? (
            searchList?.map((insurance, index) => renderList(insurance, index))
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search by city, name and address
            </p>
          )}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {/* <h4
          style={{
            color: Constants.primaryTheme,
            width: '100%',
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          Group Credentialing
        </h4> */}
        <div style={{ width: '100%', position: 'relative' }}>
          <div
            style={{
              width: 500,
              height: 40,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '2vw',
            }}
          >
            <div
              onClick={() => {
                // Check if health plan is set before opening prov cred
                let { selectedProviderHealthPlanId } = this.state
                if (selectedProviderHealthPlanId)
                  this.setState({
                    showInbox: true,
                    selectedTab: 'Provider Credentialing',
                  })
                else
                  toast.error(
                    'Open provider Credentialing after selecting it from the group credentialing section',
                  )
              }}
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Provider Credentialing'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Provider Credentialing'
                      ? 'white'
                      : 'black',
                }}
              >
                Provider Credentialing
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({
                  showInbox: true,
                  selectedTab: 'Group Credentialing',
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Group Credentialing'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Group Credentialing'
                      ? 'white'
                      : 'black',
                }}
              >
                Group Credentialing
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({ showInbox: false, selectedTab: 'Contracts' })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Contracts'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Contracts' ? 'white' : 'black',
                }}
              >
                Contracts
              </p>
            </div>
          </div>
          {/* {this.state.selectedTab === 'Group Credentialing' && ( */}
          <div style={{ position: 'absolute', top: 0, right: '5%' }}>
            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.state.selectedTab === 'Group Credentialing'
                  ? this.setState({ healthPlanModal: true })
                  : this.state.selectedTab === 'Provider Credentialing'
                  ? this.setState({ providerCredentialingModal: true })
                  : this.setState({ contractModal: true })
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 'x-large',
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div>
          {/* )} */}
        </div>

        {this.state.selectedTab === 'Provider Credentialing' ? (
          <h4
            style={{
              color: Constants.primaryTheme,
              width: '100%',
              marginBottom: 10,
              textAlign: 'center',
            }}
          >
            Provider Credentialing - {this.state.selectedProviderHealthPlan},{' '}
            {this.state.selectedProviderState}
          </h4>
        ) : null}

        <div className={GlobalStyles.contentWrapper}>
          {this.state.selectedTab === 'Group Credentialing' ? (
            <Table
              columns={groupCredColumns(
                this.state.providersList,
                this.state.patientList,
                this.openHealthPlan,
                this.openProviderRoster,
              )}
              data={this.state.groupCredentialList}
            />
          ) : this.state.selectedTab === 'Contracts' ? (
            <Table
              columns={contractsColumns(
                this.state.providersList,
                this.state.patientList,
                this.openHealthPlan,
              )}
              data={this.state.contractList}
            />
          ) : this.state.selectedTab === 'Provider Credentialing' ? (
            <Table
              columns={providerCredColumns(
                this.state.providersList,
                this.state.patientList,
                this.openProviderRosterModal,
                this.openProviderRoster,
              )}
              data={this.state.providerRosterData}
            />
          ) : (
            <Table
              columns={groupCredColumns(
                this.state.providersList,
                this.state.patientList,
              )}
              data={this.state.groupCredentialList}
            />
          )}
        </div>
        {/* HEALTH PLAN CREDENTIALING */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.healthPlanModal}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderHealthPlanModal()}
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.healthPlanEmailModal}
          onRequestClose={() => {
            this.closeEmailModal()
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderHealthPlanEmailModal()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.providerEmailModal}
          onRequestClose={() => {
            this.closeEmailModal()
          }}
          style={customProviderModalStyles}
          contentLabel="Modal"
        >
          {this.renderProviderEmailModal()}
        </Modal>
        {/* ACCEPTED INSURANCES SEARCH */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.insuranceModalOpen}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderSearchList()}
        </Modal>
        {/* CONTRACT MODAL */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.contractModal}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderContractModal()}
        </Modal>
        {/* PROVIDER CRED MODAL */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.providerCredentialingModal}
          onRequestClose={() => {
            this.closeAllModals()
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderProviderCredentialingModal()}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupCredentialing)
