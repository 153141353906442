import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { healthPlanStatus, stateResidence } from '../../../metadata'
import Constants from '../../../values'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'

export const groupCredColumns = (
  providersList,
  patientList,
  openHealthPlan,
  openProviderRoster
) => {
  return [
    {
      Header: 'Health Plan',
      accessor: 'health_plan_name',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'State',
      accessor: 'state',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={stateResidence}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                        abbreviation: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.abbreviation)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Network Contact',
      accessor: 'contact_name',
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Network Email',
      accessor: 'contact_email',
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Phone',
      accessor: 'phone_number',
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Status',
      accessor: (obj) => obj?.status,
      width: 250,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Statustype"
                placeholder="Select"
                options={healthPlanStatus}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Days since Submission',
      accessor: 'date_of_submission',
      Cell: ({ row }) => {
        let days = row?.original.date_of_submission
          ? moment().diff(moment(row?.original.date_of_submission), 'days')
          : 'Nil'
        return (
          <p
            style={{
              color: days <= 30 ? 'green' : days <= 74 ? 'orange' : 'red',
            }}
          >
            {days}
          </p>
        )
      },
      width: 250,
      sort: true,
    },
    {
      id: 'Provider Roster',
      Header: 'Provider Roster',
      width: 100,
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              console.log('Open provider roster!')
              let healthPlanState = row?.original?.state
              let healthPlanId = row?.original?._id
              let healthPlanName = row?.original?.health_plan_name
              console.log("HP", healthPlanName, healthPlanState)
              openProviderRoster(healthPlanName, healthPlanState, healthPlanId)
            }}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon
              style={{
                color: 'green',
                cursor: 'pointer',
              }}
              icon={faExternalLinkAlt}
            />
          </div>
        )
      },
    },
    {
      Header: 'Notes',
      accessor: 'notes_list',
      Cell: ({ row }) => {
        let toDisplay = row.original.notes_list?.length
          ? row.original.notes_list[0].note_text
          : // .map((each, index) => each.note_text + '\n')
            // .join(',')
            '-'
        return toDisplay
      },
      width: 350,
    },
    // {
    //   Header: 'Dependencies?',
    //   accessor: 'dependencies',
    //   width: 200,
    // },
    {
      Header: 'Contract Received Date',
      accessor: (obj) =>
        obj?.received_date ? moment(obj?.received_date).format('ll') : '-',
      width: 200,
      sort: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              console.log('view claim')
              openHealthPlan(row.original)
            }}
            className={Styles.link}
            style={{ textDecoration: 'underline' }}
          >
            Edit
          </p>
        )
      },
      width: 200,
    },
  ]
}
