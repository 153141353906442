import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Config from '../../config'
import Modal from 'react-modal'
import {
  addPatientList,
  fetchPatientCarePlanData,
  setAppointmentNotesData,
  toggleSidebar,
} from '../../redux/actions'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/cms.module.scss'
import moment from 'moment'
import addresser from 'addresser'
import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
const mtz = require('moment-timezone')
const axios = require('axios')

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    zIndex: 10,
    width: '80%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2147483647,
  },
}

const payerConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'payer',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigPayer = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: payerConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      payer_name: { raw: {} },
      payerid: { raw: {} },
    },
  },
}

const REQUIRED_BK_COLOR = '#f53b3b'

const PATIENT_GENDER_OPTIONS = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Unknown', value: 'U' },
]

const PAYER_RELATIONSHIP_OPTIONS = [
  { label: 'SPOUSE', value: '01' },
  { label: 'SELF', value: '18' },
  { label: 'CHILD', value: '19' },
  { label: 'EMPLOYEE', value: '20' },
  { label: 'UNKNOWN', value: '21' },
  { label: 'ORGAN DONOR', value: '39' },
  { label: 'CADAVER DONOR', value: '40' },
  { label: 'LIFE PARTNER', value: '53' },
  { label: 'OTHER RELATIONSHIP', value: 'G8' },
]

const ADD_DATE_OPTIONS = [
  { label: 'Date Last Seen(304)', value: '01' },
  { label: 'Acute Manifest Date (453)', value: '18' },
  { label: 'Accident Date (439)', value: '19' },
  { label: 'Initial Treatment Date (454)', value: '20' },
  { label: 'Last menstrual Period (484)', value: '21' },
  { label: 'Last X-Ray Date (455)', value: '39' },
  { label: 'ASSUMED CARE DATE (090)', value: '40' },
  { label: 'RELINQUISHED CARE DATE (001)', value: '53' },
  { label: 'LAST WORKED DATE (297)', value: 'G8' },
  { label: 'DISABILITY FROM DATE (360)', value: 'G8' },
  { label: 'DISABILITY THRU DATE (361)', value: 'G8' },
  {
    label: 'HEARING AND VISION PRESCRIPTION DATE (471)',
    value: 'G8',
  },
  {
    label: 'AUTHORIZED RETURN TO WORK DATE (296)',
    value: 'G8',
  },
  { label: 'FIRST CONTACT DATE (444)', value: 'G8' },
  { label: 'PATIENT DEATH DATE', value: 'G8' },
]

const TAX_ID_TYPE_OPTIONS = [
  { label: 'EIN', value: 'E' },
  { label: 'SSN', value: 'S' },
]

const EMG_OPTIONS = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
]

const NDC_MEASURE = [
  { label: 'INTERNATIONAL UNIT (F2)', value: 'F2' },
  { label: 'MILLIGRAM (ME)', value: 'ME' },
  { label: 'GRAM (GR)', value: 'GR' },
  { label: 'MILLILITER (ML)', value: 'ME' },
  { label: 'UNIT (UN)', value: 'UN' },
]

const ACCEPT_ASSIGNMENT_OPTIONS = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
]

const PROCEDURE_COST_MAP = {
  99202: '150.00',
  99203: '284.00',
  99204: '432.00',
  99205: '495.00',
  99211: '80.00',
  99212: '125.00',
  99213: '198.00',
  99214: '287.00',
  99215: '350.00',
  99354: '400.00',
  99355: '198.00',
  '0591T': '150.00',
  '0592T': '110.00',
  97802: '50.00',
  97803: '50.00',
  97804: '110.00',
  90832: '150.00',
  90834: '225.00',
  90837: '300.00',
  99358: '250.00',
  99417: '75.00',
  99447: '70.00',
  99452: '99.00',
  99454: '125.00',
  99457: '65.00',
  99499: '175.00',
  99421: '30.00',
  99422: '60.00',
  99423: '96.00',
  98970: '150.00',
}

// export default class CmsTab extends Component {
class CmsTab extends Component {
  constructor(props) {
    super(props)
    // let notesData = this.props?.carePlanNotesData
    // let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,

      // Header details
      claimSubmissionDate: '',
      claimStatus: '',
      claimMdNumber: '',
      clearingHouseNumber: '',
      chequeNumber: '',

      // This is for the CMS 1500 form
      payerName: '',
      payerAddress: '',
      payerId: '',
      payerCity: '', // I dont think this is used here
      payerType: null,
      patientFirstName: this.props.patientFirstName || '',
      patientLastName: this.props.patientLastName || '',
      patientMiddleName: this.props.patientMiddleName || '',
      patientDob: this.props.patientDob || null,
      patientGender: this.props.patientGender || '',
      patientAddress: this.props.patientAddress || '',
      patientAddress2: this.props.patientAddress2 || '',
      patientCity: this.props.patientCity || '',
      patientState: this.props.patientState || '',
      patientZip: this.props.patientZip || '',
      patientCountry: this.props.patientCountry || 'US',
      patientRelationship: this.props.patientRelationship || '',
      patientMartialStatus: this.props.patientMartialStatus || '',
      patientEmploymentStatus: this.props.patientEmploymentStatus || '',

      insuredIdNumber: '',
      insuredFirstName: '',
      insuredLastName: '',
      insuredMiddleName: '',
      insuredAddress: '',
      insuredAddress2: '',
      insuredCity: '',
      insuredState: '',
      insuredZip: '',
      insuredCountry: 'US',

      secondaryInsuredFirstName: '',
      secondaryInsuredLastName: '',
      secondaryInsuredMiddleName: '',
      secondaryInsuredPolicyNumber: '',
      secondaryInsuredDob: null,
      secondaryInsuredSex: null,
      primaryPaymentDate: null,
      secondaryPayerName: '',
      secondaryPayerId: '',
      secondaryPayerGroupName: '',
      secondaryPayerGroupNumber: '',
      secondaryPayerClaimId: '',
      secondaryMedicareTypeCode: '',
      secondaryPatientRelationship: null,

      patientConditionEmploymentStatus: null,
      patientConditionAutoAccident: null,
      patientConditionAutoAccidentState: null,
      patientConditionAutoAccidentCountry: 'US',
      patientConditionOtherAccident: null,
      claimCodes: null,

      insuredPolicyGroup: '',
      insuredDob: null,
      insuredSex: null,
      insuredEmployersName: '',
      insurancePlanName: '',

      supervisingProviderFirstName: '',
      supervisingProviderLastName: '',
      supervisingProviderMiddleName: '',
      supervisingProviderNpi: '',
      supervisingProviderId: '',

      dateOfCondition: null,
      addDateOfCondition: null,
      referringPhysicianFirstName: '',
      referringPhysicianLastName: '',
      referringPhysicianMiddleName: '',
      referringPhysicianNpi: '',
      referringPhysicianOtherId: '',

      claimNarrative: '',
      hospitalizationDateFrom: null,
      hospitalizationDateTo: null,

      resubmissionCode: '',
      priorAuthorizationNumber: '',
      referralNumber: '',
      cliaNumber: '',

      diagnosisA: '',
      diagnosisB: '',
      diagnosisC: '',
      diagnosisD: '',
      diagnosisE: '',
      diagnosisF: '',
      diagnosisG: '',
      diagnosisH: '',
      diagnosisI: '',
      diagnosisJ: '',
      diagnosisK: '',
      diagnosisL: '',

      diagnosisList: [
        {
          dateOfServiceStart: null,
          dateOfServiceEnd: null,
          bPos: '11',
          CEmg: null,
          procedure: '',
          modifier1: '',
          modifier2: '',
          modifier3: '',
          modifier4: '',
          diagnosisReference: '',
          amountChanges: '', // Damn typo, needs to be amountCharges
          diagnosisUnits: '1',
          ndcCode: '',
          ndcDosage: '',
          ndcMeasure: '',
        },
      ],

      taxId: '',
      taxIdType: { label: 'EIN', value: 'E' },
      patientAccountNumber: '',
      acceptAssignment: { label: 'Yes', value: 'Y' },
      totalCharge: 0.0,
      totalAmountPaid: '',
      balanceDue: '',

      renderingProviderFirstName: '',
      renderingProviderLastName: '',
      renderingProviderMiddleName: '',
      renderingProviderNpi: '',
      renderingProviderId: '',
      renderingProviderTaxonomy: '',

      facilityName: '',
      facilityAddress: '',
      facilityAddress2: '',
      facilityCity: '',
      facilityState: '',
      facilityZip: '',
      facilityCountry: 'US',
      facilityNpi: '',
      facilityId: '',

      billingProviderName: '',
      billingProviderAddress: '',
      billingProviderAddress2: '',
      billingProviderCity: '',
      billingProviderCountry: 'US',
      billingProviderState: '',
      billingProviderZip: '',
      billingProviderNpi: '',
      billingProviderId: '',
      billingProviderTaxonomy: '',
      billingProviderPhone: '',

      showPayerSearchModal: false,
    }
    this.range = this.range.bind(this)
    this.getMonth = this.getMonth.bind(this)
    this.getYear = this.getYear.bind(this)
    this.submitCMS1500Form = this.submitCMS1500Form.bind(this)
    this.saveCMS1500Form = this.saveCMS1500Form.bind(this)
    this.parseDate = this.parseDate.bind(this)
    this.parseInsuranceDetails = this.parseInsuranceDetails.bind(this)

    this.inputRef = React.createRef()
  }

  // componentWillUnmount() {
  //   console.log('CMS 1500 COMPONENT UNMOUNTED!')
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log('COMPONENT UPDATED', prevProps, prevState)
  // }

  componentDidMount() {
    if (this.props.eventId) {
      console.log('I GOT AN EVENT ID AND NEED TO FETCH DATA')
      if (this.props.savedClaim) {
        console.log('Saved Claim')
        axios({
          method: 'get',
          url: `${Config.BACKEND_URL}claims/get-saved`,
          params: {
            eventId: this.props.eventId,
          },
        }).then(async (res) => {
          console.log('Got response back')
          let data = res?.data?.data
          console.log('The data is: : : ', data)
          // change the dates to correct formats
          // TODO: need to sanitize these data
          // secondaryInsuredDob
          // secondaryInsuredSex
          // primaryPaymentDate
          // secondaryPatientRelationship
          // patientConditionEmploymentStatus <= patientConditionEmployment
          // patientConditionAutoAccident
          // patientConditionOtherAccident
          // insuredDob
          // insuredSex
          // dateOfCondition
          // hospitalizationDateFrom
          // hospitalizationDateTo
          // resubmissionCode
          //

          data.patientDob = moment(data.patientDob, 'YYYY-MM-DD').toDate()
          data.payerType = {
            label: data?.payerType?.toUpperCase(),
            value: data?.payerType,
          }
          data.patientGender = PATIENT_GENDER_OPTIONS.find(
            (gender) => gender.value == data.patientGender,
          )
          data.patientRelationship = PAYER_RELATIONSHIP_OPTIONS.find(
            (rel) => rel.value == data.patientRelationship,
          )
          for (let i = 0; i < data.diagnosisList.length; i++) {
            data.diagnosisList[i].dateOfServiceStart = moment(
              data.diagnosisList[i].dateOfServiceStart,
              'YYYY-MM-DD',
            ).toDate()
            data.diagnosisList[i].dateOfServiceEnd = moment(
              data.diagnosisList[i].dateOfServiceEnd,
              'YYYY-MM-DD',
            ).toDate()
            data.diagnosisList[i].CEmg =
              EMG_OPTIONS.find(
                (emg) => emg.value == data.diagnosisList[i].CEmg,
              ) || null
          }
          data.acceptAssignment = ACCEPT_ASSIGNMENT_OPTIONS.find(
            (options) => options.value == data.acceptAssignment,
          )
          data.taxIdType = TAX_ID_TYPE_OPTIONS.find(
            (val) => val.value == data.taxIdType,
          )

          this.setState({ ...data })
        })
      } else {
        axios({
          method: 'get',
          url: `${Config.BACKEND_URL}claims/get`,
          params: {
            eventId: this.props.eventId,
            isOnlyNote: this.props.noteOnly || false,
          },
        })
          .then(async (res) => {
            console.log(':', res)
            let claimDetails = res?.data?.data?.[0]

            // BUG: This is also misplaced, need to fix this
            // if (claimDetails.claimTransmitted) {
            //   this.setState({ claimTransmitted: claimDetails.claimTransmitted })
            //   this.setState({ validClaim: claimDetails.validClaim })
            // }

            claimDetails.patientDob = await this.parseDate(
              claimDetails.patientDob,
            )
            claimDetails.patientGender = {
              label: claimDetails?.patientGender,
              value: claimDetails?.patientGender?.[0],
            }

            let insuranceDetails = this.parseInsuranceDetails(
              claimDetails.insuranceData,
            )

            let parsedPatientAddress = null
            let parsedPatientAddress2 = null
            try {
              parsedPatientAddress = addresser.parseAddress(
                claimDetails.patientAddress,
              )
            } catch (err) {
              console.log('ERROR parsing address details: ', err)
            }
            try {
              parsedPatientAddress2 = addresser.parseAddress(
                claimDetails.patientAddress2,
              )
            } catch (err) {
              console.log('ERROR parsing address2 details: ', err)
            }
            claimDetails.patientCity =
              parsedPatientAddress?.placeName ||
              parsedPatientAddress2?.placeName ||
              ''
            claimDetails.patientState =
              parsedPatientAddress?.stateAbbreviation ||
              parsedPatientAddress2?.stateAbbreviation ||
              ''

            // Facility info
            axios({
              method: 'get',
              url: `${Config.BACKEND_URL}facility/info`,
              params: {
                facilityState: claimDetails.patientState,
              },
            })
              .then((res) => {
                this.setState({ ...res?.data?.data })
              })
              .catch((err) => {
                console.log('ERROR5000: ', err)
                toast.error('Error when loading facility details!')
              })

            // Practice info
            axios({
              method: 'get',
              url: `${Config.BACKEND_URL}practice/info`,
            })
              .then((res) => {
                this.setState({ ...res?.data?.data })
              })
              .catch((err) => {
                console.log('ERROR5: ', err)
                toast.error('Error when loading practice details!')
              })

            let parsedInsuredAddress = null
            let parsedInsuredAddress2 = null
            try {
              parsedInsuredAddress = addresser.parseAddress(
                claimDetails.insuredAddress,
              )
            } catch (err) {
              console.log('ERROR parsing insured address details: ', err)
            }
            try {
              parsedInsuredAddress2 = addresser.parseAddress(
                claimDetails.insuredAddress2,
              )
            } catch (err) {
              console.log('ERROR parsing insured address2 details: ', err)
            }
            claimDetails.insuredCity =
              parsedInsuredAddress?.placeName ||
              parsedInsuredAddress2?.placeName ||
              ''
            claimDetails.insuredState =
              parsedInsuredAddress?.stateAbbreviation ||
              parsedInsuredAddress2?.stateAbbreviation ||
              ''

            let icdData = claimDetails?.notesData?.[0]?.careplan
            let cpts = claimDetails?.notesData?.[0]?.cpt
            let amended_cpts = claimDetails?.notesData?.[0]?.amended_cpt
            if (amended_cpts?.length > 0) {
              cpts = amended_cpts
            }
            console.log('The amended cpts are: ', amended_cpts)
            let diagRef = ''
            let totalAmount = 0.0
            for (let i = 0; i < icdData?.length; i++) {
              console.log('careplan: ', i, ' ', icdData[i])
              let icdCode = icdData[i]?.icd_10?.icd10_code
              switch (i) {
                case 0: {
                  claimDetails.diagnosisA = icdCode
                  diagRef += 'A'
                  break
                }
                case 1: {
                  claimDetails.diagnosisB = icdCode
                  diagRef += 'B'
                  break
                }
                case 2: {
                  claimDetails.diagnosisC = icdCode
                  diagRef += 'C'
                  break
                }
                case 3: {
                  claimDetails.diagnosisD = icdCode
                  diagRef += 'D'
                  break
                }
                case 4: {
                  claimDetails.diagnosisE = icdCode
                  diagRef += 'E'
                  break
                }
                case 5: {
                  claimDetails.diagnosisF = icdCode
                  diagRef += 'F'
                  break
                }
                case 6: {
                  claimDetails.diagnosisG = icdCode
                  diagRef += 'G'
                  break
                }
                case 7: {
                  claimDetails.diagnosisH = icdCode
                  diagRef += 'H'
                  break
                }
                case 8: {
                  claimDetails.diagnosisI = icdCode
                  diagRef += 'I'
                  break
                }
                case 9: {
                  claimDetails.diagnosisJ = icdCode
                  diagRef += 'J'
                  break
                }
                case 10: {
                  claimDetails.diagnosisK = icdCode
                  diagRef += 'K'
                  break
                }
                case 11: {
                  claimDetails.diagnosisL = icdCode
                  diagRef += 'L'
                  break
                }
              }
            }
            let diagnosisObj = {
              dateOfServiceStart: null,
              dateOfServiceEnd: null,
              bPos: '11',
              CEmg: null,
              procedure: '',
              modifier1: '',
              modifier2: '',
              modifier3: '',
              modifier4: '',
              diagnosisReference: '',
              amountChanges: '',
              diagnosisUnits: '1',
            }
            diagnosisObj.diagnosisReference = diagRef
            let x = new Date(moment(claimDetails.dateOfServiceStart).utcOffset('-12.30').format('YYYY-MM-DD HH:mm'));
            diagnosisObj.dateOfServiceStart = x
            // diagnosisObj.dateOfServiceStart = mtz(
            //   claimDetails.dateOfServiceStart,
            // )
            //   .tz('America/Los_Angeles')
            //   // .subtract('8', 'hours')
            //   .toDate()
            let y = new Date(moment(claimDetails.dateOfServiceEnd).utcOffset('-12.30').format('YYYY-MM-DD HH:mm'));
            diagnosisObj.dateOfServiceEnd = y
            // diagnosisObj.dateOfServiceEnd = mtz(claimDetails.dateOfServiceEnd)
            //   .tz('America/Los_Angeles')
            //   // .subtract('8', 'hours')
            //   .toDate()
            console.log(
              'Start and End',
              diagnosisObj.dateOfServiceStart,
              diagnosisObj.dateOfServiceEnd,
            )
            diagnosisObj.procedure = cpts?.[0]?.cpt_code
            diagnosisObj.diagnosisUnits = '1'
            diagnosisObj.amountChanges =
              Number(PROCEDURE_COST_MAP[cpts?.[0]?.cpt_code] || 0).toFixed(2) ||
              0.0
            totalAmount += Number(diagnosisObj.amountChanges).toFixed(2)
            totalAmount = Number(totalAmount).toFixed(2) || Number(0).toFixed(2)
            let diagnosisList = []
            diagnosisList.push(diagnosisObj)
            if (cpts?.length > 1) {
              for (let i = 1; i < cpts?.length; i++) {
                let diagnosisObjI = {
                  dateOfServiceStart: moment(claimDetails.dateOfServiceStart)
                    .subtract('8', 'hours')
                    .toDate(),
                  dateOfServiceEnd: moment(claimDetails.dateOfServiceEnd)
                    .subtract('8', 'hours')
                    .toDate(),
                  bPos: '11',
                  CEmg: null,
                  procedure: '',
                  modifier1: '',
                  modifier2: '',
                  modifier3: '',
                  modifier4: '',
                  diagnosisReference: diagRef,
                  amountChanges: '', // This was meant to be amountCharges, cant change this easily there are a lot of records in the database with this field name
                  diagnosisUnits: '1',
                }
                diagnosisObjI.procedure = cpts[i]?.cpt_code
                diagnosisObjI.amountChanges =
                  Number(PROCEDURE_COST_MAP[cpts[i]?.cpt_code] || 0).toFixed(
                    2,
                  ) || 0.0
                totalAmount +=
                  Number(diagnosisObjI.amountChanges || 0).toFixed(2) ||
                  Number(0).toFixed(2)
                diagnosisList.push(diagnosisObjI)
              }
            }
            claimDetails.diagnosisList = diagnosisList
            claimDetails.totalCharge = totalAmount

            console.log('res: ', claimDetails)
            console.log('insDet: ', insuranceDetails)
            this.setState({
              ...claimDetails,
              ...insuranceDetails,
              taxIdType: { label: 'EIN', value: 'E' },
              payerType: { label: 'PRIMARY', value: 'Primary' },
            })
          })
          .catch((err) => {
            console.log('ERROR4: ', err)
            toast.error(
              'Error when loading details, you might need to manually enter the data!',
            )
          })
      }
    } else {
      console.log('YO I DID NOT GET AN EVENT ID AND NEED NOT FETCH ANY DATA')
    }
  }

  parseInsuranceDetails = (insuranceData) => {
    let pInsurance = Array.isArray(insuranceData)
      ? insuranceData?.find((ins) => ins.preference === 1) || null
      : insuranceData || null
    let sInsurance = Array.isArray(insuranceData)
      ? insuranceData?.find((ins) => ins.preference === 2)
      : null
    let pDetails = {}
    let sDetails = {}
    if (pInsurance) {
      pDetails.payerName =
        pInsurance?.insurance_company?.insurance_name ||
        pInsurance?.insurance_provider ||
        null
      pDetails.payerId = pInsurance?.payer_id || null
      pDetails.insuredIdNumber = pInsurance?.subscriber_id || null
      pDetails.insuredFirstName =
        pInsurance?.insured?.insured_first_name || null
      pDetails.insuredLastName = pInsurance?.insured?.insured_last_name || null
      pDetails.insuredAddress = pInsurance?.insured?.insured_address_1 || ''
      pDetails.insuredAddress2 = pInsurance?.insured?.insured_address_2 || ''
      pDetails.insuredCity = pInsurance?.insured?.insured_city || ''
      pDetails.insuredState = pInsurance?.insured?.insured_state || ''
      pDetails.insuredZip = pInsurance?.insured?.insured_zip || ''
      pDetails.insuredCountry = pInsurance?.insured?.insured_country || 'US'
      pDetails.insuredPolicyGroup = pInsurance?.group_number || ''
      pDetails.insurancePlanName = pInsurance?.plan_type || ''
      pDetails.patientRelationship =
        pInsurance?.insured?.insured_relationship?.toLowerCase() == 'self'
          ? { label: 'SELF', value: '18' }
          : null
    }
    if (sDetails) {
      sDetails.secondaryInsuredFirstName =
        sInsurance?.insured_first_name || null
      sDetails.secondaryInsuredLastName = sInsurance?.insured_last_name || null
      // sDetails.secondaryInsuredMiddleName = sInsurance?.insured_middle_name || null
      // sDetails.secondaryInsuredPolicyNumber = sInsurance?.policy_number || null
      // sDetails.secondaryInsuredDob = sInsurance?.insured_dob || null
    }
    return { ...pDetails, ...sDetails }
  }

  parseDate = (date) => {
    console.log('got date', date)
    if (date === 'Invalid date') {
      toast.error("Patient's date of birth is invalid!")
      return new Date()
    }
    return moment(date, 'DD/MM/YYYY').toDate()
  }

  checkIfCanSubmit = () => {
    let {
      payerName,
      payerId,
      patientAccountNumber,
      patientFirstName,
      patientLastName,
      patientDob,
      patientAddress,
      patientCity,
      patientState,
      patientZip,
      patientGender,
      patientRelationship,
      insuredIdNumber,
      acceptAssignment,
      totalCharge,
      diagnosisA,
      billingProviderName,
      billingProviderAddress,
      billingProviderAddress2,
      billingProviderCity,
      billingProviderState,
      billingProviderZip,
      billingProviderNpi,
      billingProviderId,
      billingProviderPhone,
      taxId,
      taxIdType,
      diagnosisList, // check dateOfServiceStart, dateOfServiceEnd, diagnosisReference, amountChanges, diagnosisUnits
    } = this.state

    // DO: From the above code complete the entire code block
    if (!payerName) {
      toast.error('Please fill in the payer name')
      return false
    } else if (!payerId) {
      toast.error('Please fill in the payer id')
      return false
    } else if (!patientAccountNumber) {
      toast.error('Please fill in the patient account number')
      return false
    } else if (!patientFirstName) {
      toast.error('Please fill in the patient first name')
      return false
    } else if (!patientLastName) {
      toast.error('Please fill in the patient last name')
      return false
    } else if (!patientDob) {
      toast.error('Please fill in the patient date of birth')
      return false
    } else if (!patientAddress) {
      toast.error('Please fill in the patient address')
      return false
    } else if (!patientCity) {
      toast.error('Please fill in the patient city')
      return false
    } else if (!patientState) {
      toast.error('Please fill in the patient state')
      return false
    } else if (!patientZip) {
      toast.error('Please fill in the patient zip')
      return false
    } else if (!patientGender) {
      toast.error('Please fill in patient Sex')
      return false
    } else if (!patientRelationship) {
      toast.error('Please fill in patient Relationship')
      return false
    } else if (!insuredIdNumber) {
      toast.error('Please fill in insured Id Number')
      return false
    } else if (!acceptAssignment) {
      toast.error('Please fill in 27. accept assignment')
      return false
    } else if (!totalCharge) {
      toast.error('Please fill in total charge')
      return false
    } else if (!diagnosisA) {
      toast.error('Please fill in diagnosis A')
      return false
    } else if (!billingProviderName) {
      toast.error('Please fill in billing provider name')
      return false
    } else if (!billingProviderAddress) {
      toast.error('Please fill in billing provider address')
      return false
    } else if (!billingProviderAddress2) {
      toast.error('Please fill in billing provider address 2')
      return false
    } else if (!billingProviderCity) {
      toast.error('Please fill in billing provider city')
      return false
    } else if (!billingProviderState) {
      toast.error('Please fill in billing provider state')
      return false
    } else if (!billingProviderZip) {
      toast.error('Please fill in billing provider zip')
      return false
    } else if (!billingProviderNpi && !billingProviderId) {
      toast.error('Please fill in billing provider npi or ID')
      return false
    } else if (!billingProviderPhone) {
      toast.error('Please fill in billing provider phone')
      return false
    } else if (!taxId) {
      toast.error('Please fill in tax id')
      return false
    } else if (!taxIdType) {
      toast.error('Please fill in tax id type')
      return false
    } else {
      for (let i = 0; i < diagnosisList.length; i++) {
        // dateOfServiceStart, dateOfServiceEnd, diagnosisReference, amountChanges, diagnosisUnits
        if (!diagnosisList[i].dateOfServiceStart) {
          toast.error(
            'Please fill in date of service start for the diagnosis row ' +
              (i + 1),
          )
          return false
        }
        if (!diagnosisList[i].dateOfServiceEnd) {
          toast.error(
            'Please fill in date of service end for the diagnosis row ' +
              (i + 1),
          )
          return false
        }
        if (!diagnosisList[i].diagnosisReference) {
          toast.error(
            'Please fill in diagnosis reference for the diagnosis row ' +
              (i + 1),
          )
          return false
        }
        if (!diagnosisList[i].amountChanges) {
          toast.error(
            'Please fill in amount changes for the diagnosis row ' + (i + 1),
          )
          return false
        }
        if (!diagnosisList[i].diagnosisUnits) {
          toast.error(
            'Please fill in diagnosis units for the diagnosis row ' + (i + 1),
          )
          return false
        }
      }
    }
    return true
  }

  submitCMS1500Form = async () => {
    console.log('SUBMITTING CMS 1500 FORM')

    if (!this.checkIfCanSubmit()) {
      // toast.error('Please fill in all the required fields')
      return
    }

    let {
      payerName,
      payerAddress,
      payerId,
      payerCity,
      payerType,
      patientFirstName,
      patientLastName,
      patientMiddleName,
      patientDob,
      patientGender,
      patientAddress,
      patientAddress2,
      patientCity,
      patientState,
      patientZip,
      patientCountry,
      patientRelationship,
      patientMartialStatus,
      patientEmploymentStatus,
      insuredIdNumber,
      insuredFirstName,
      insuredLastName,
      insuredMiddleName,
      insuredAddress,
      insuredAddress2,
      insuredCity,
      insuredState,
      insuredZip,
      insuredCountry,
      secondaryInsuredFirstName,
      secondaryInsuredLastName,
      secondaryInsuredMiddleName,
      secondaryInsuredPolicyNumber,
      secondaryInsuredDob,
      secondaryInsuredSex,
      primaryPaymentDate,
      secondaryPayerName,
      secondaryPayerId,
      secondaryPayerGroupName,
      secondaryPayerGroupNumber,
      secondaryPayerClaimId,
      secondaryMedicareTypeCode,
      secondaryPatientRelationship,
      patientConditionEmploymentStatus,
      patientConditionAutoAccident,
      patientConditionAutoAccidentState,
      patientConditionAutoAccidentCountry,
      patientConditionOtherAccident,
      claimCodes,
      insuredPolicyGroup,
      insuredDob,
      insuredSex,
      insuredEmployersName,
      insurancePlanName,
      supervisingProviderFirstName,
      supervisingProviderLastName,
      supervisingProviderMiddleName,
      supervisingProviderNpi,
      supervisingProviderId,
      dateOfCondition,
      addDateOfCondition,
      referringPhysicianFirstName,
      referringPhysicianLastName,
      referringPhysicianMiddleName,
      referringPhysicianNpi,
      referringPhysicianOtherId,
      claimNarrative,
      hospitalizationDateFrom,
      hospitalizationDateTo,
      resubmissionCode,
      priorAuthorizationNumber,
      referralNumber,
      cliaNumber,
      diagnosisA,
      diagnosisB,
      diagnosisC,
      diagnosisD,
      diagnosisE,
      diagnosisF,
      diagnosisG,
      diagnosisH,
      diagnosisI,
      diagnosisJ,
      diagnosisK,
      diagnosisL,
      diagnosisList,
      taxId,
      taxIdType,
      patientAccountNumber,
      acceptAssignment,
      totalCharge,
      totalAmountPaid,
      balanceDue,
      renderingProviderFirstName,
      renderingProviderLastName,
      renderingProviderMiddleName,
      renderingProviderNpi,
      renderingProviderId,
      renderingProviderTaxonomy,
      facilityName,
      facilityAddress,
      facilityAddress2,
      facilityCity,
      facilityState,
      facilityZip,
      facilityCountry,
      facilityNpi,
      facilityId,
      billingProviderName,
      billingProviderAddress,
      billingProviderAddress2,
      billingProviderCity,
      billingProviderState,
      billingProviderZip,
      billingProviderNpi,
      billingProviderId,
      billingProviderTaxonomy,
      billingProviderPhone,
    } = this.state

    console.log('Here1')
    // Sanitize the data
    if (patientDob)
      patientDob =
        patientDob?.getFullYear() +
        '-' +
        (patientDob?.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        patientDob?.getDate().toString().padStart(2, '0')
    if (secondaryInsuredDob)
      secondaryInsuredDob =
        secondaryInsuredDob.getFullYear() +
        '-' +
        (secondaryInsuredDob.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        secondaryInsuredDob.getDate().toString().padStart(2, '0')
    if (hospitalizationDateFrom)
      hospitalizationDateFrom =
        hospitalizationDateFrom.getFullYear() +
        (hospitalizationDateFrom.getMonth() + 1).toString().padStart(2, '0') +
        hospitalizationDateFrom.getDate().toString().padStart(2, '0')
    if (hospitalizationDateTo)
      hospitalizationDateTo =
        hospitalizationDateTo.getFullYear() +
        (hospitalizationDateTo.getMonth() + 1).toString().padStart(2, '0') +
        hospitalizationDateTo.getDate().toString().padStart(2, '0')
    if (insuredDob)
      insuredDob =
        insuredDob.getFullYear() +
        (insuredDob.getMonth() + 1).toString().padStart(2, '0') +
        insuredDob.getDate().toString().padStart(2, '0')
    if (dateOfCondition)
      dateOfCondition =
        dateOfCondition.getFullYear() +
        '-' +
        (dateOfCondition.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        dateOfCondition.getDate().toString().padStart(2, '0')
    // let localDiagRef = ""
    for (let i = 0; i < diagnosisList.length; i++) {
      if (diagnosisList[i].dateOfServiceStart)
        diagnosisList[i].dateOfServiceStart =
          diagnosisList[i].dateOfServiceStart.getFullYear() +
          '-' +
          (diagnosisList[i].dateOfServiceStart.getMonth() + 1)
            .toString()
            .padStart(2, '0') +
          '-' +
          diagnosisList[i].dateOfServiceStart
            .getDate()
            .toString()
            .padStart(2, '0')
      if (diagnosisList[i].dateOfServiceEnd)
        diagnosisList[i].dateOfServiceEnd =
          diagnosisList[i].dateOfServiceEnd.getFullYear() +
          '-' +
          (diagnosisList[i].dateOfServiceEnd.getMonth() + 1)
            .toString()
            .padStart(2, '0') +
          '-' +
          diagnosisList[i].dateOfServiceEnd
            .getDate()
            .toString()
            .padStart(2, '0')
    }

    // Transmit the $ amounts with .00 at the end or the cents value

    // console.log('diagnosisList: ', diagnosisList)
    // console.log('Here2')

    // Send data to the backend
    axios({
      method: 'post',
      url: `${Config.BACKEND_URL}claims/submit`,
      // This is for testing
      // method: 'get',
      // url: `${Config.BACKEND_URL}health`,
      headers: {
        // Authorization: `JWT ${token}`,
        Authorization: `JWT `,
        isSaved: this.props.savedClaim,
        isOnlyNote: this.props.noteOnly || false,
      },
      data: {
        payerName,
        payerAddress,
        payerId,
        payerCity,
        payerType: payerType?.value,
        patientFirstName,
        patientLastName,
        patientMiddleName,
        patientDob,
        patientGender: patientGender?.value,
        patientAddress,
        patientAddress2,
        patientCity,
        patientState,
        patientZip,
        patientCountry,
        patientRelationship: patientRelationship?.value,
        patientMartialStatus: patientMartialStatus?.value,
        patientEmploymentStatus: patientEmploymentStatus?.value,
        insuredIdNumber,
        insuredFirstName,
        insuredLastName,
        insuredMiddleName,
        insuredAddress,
        insuredAddress2,
        insuredCity,
        insuredState,
        insuredZip,
        insuredCountry,
        secondaryInsuredFirstName,
        secondaryInsuredLastName,
        secondaryInsuredMiddleName,
        secondaryInsuredPolicyNumber,
        secondaryInsuredDob,
        secondaryInsuredSex: secondaryInsuredSex?.value,
        primaryPaymentDate,
        secondaryPayerName,
        secondaryPayerId,
        secondaryPayerGroupName,
        secondaryPayerGroupNumber,
        secondaryPayerClaimId,
        secondaryMedicareTypeCode,
        secondaryPatientRelationship,
        patientConditionEmploymentStatus:
          patientConditionEmploymentStatus?.value,
        patientConditionAutoAccident: patientConditionAutoAccident?.value,
        patientConditionAutoAccidentState,
        patientConditionAutoAccidentCountry,
        patientConditionOtherAccident: patientConditionOtherAccident?.value,
        claimCodes,
        insuredPolicyGroup,
        insuredDob,
        insuredSex: insuredSex?.value,
        insuredEmployersName,
        insurancePlanName,
        supervisingProviderFirstName,
        supervisingProviderLastName,
        supervisingProviderMiddleName,
        supervisingProviderNpi,
        supervisingProviderId,
        dateOfCondition,
        addDateOfCondition,
        referringPhysicianFirstName,
        referringPhysicianLastName,
        referringPhysicianMiddleName,
        referringPhysicianNpi,
        referringPhysicianOtherId,
        claimNarrative,
        hospitalizationDateFrom,
        hospitalizationDateTo,
        resubmissionCode: resubmissionCode?.value,
        priorAuthorizationNumber,
        referralNumber,
        cliaNumber,
        diagnosisA,
        diagnosisB,
        diagnosisC,
        diagnosisD,
        diagnosisE,
        diagnosisF,
        diagnosisG,
        diagnosisH,
        diagnosisI,
        diagnosisJ,
        diagnosisK,
        diagnosisL,
        diagnosisList,
        taxId,
        taxIdType: taxIdType?.value,
        patientAccountNumber,
        acceptAssignment: acceptAssignment?.value,
        totalCharge,
        totalAmountPaid,
        balanceDue,
        renderingProviderFirstName,
        renderingProviderLastName,
        renderingProviderMiddleName,
        renderingProviderNpi,
        renderingProviderId,
        renderingProviderTaxonomy,
        facilityName,
        facilityAddress,
        facilityAddress2,
        facilityCity,
        facilityState,
        facilityZip,
        facilityCountry,
        facilityNpi,
        facilityId,
        billingProviderName,
        billingProviderAddress,
        billingProviderAddress2,
        billingProviderCity,
        billingProviderState,
        billingProviderZip,
        billingProviderNpi,
        billingProviderId,
        billingProviderTaxonomy,
        billingProviderPhone,
        eventId: this.props.eventId,
      },
    })
      .then((res) => {
        if (res?.data?.success == true) {
          toast.success('Successfully submitted claim')
        } else {
          console.log('err:', res)
          // toast.error('Something went wrong!')
          let errors = res?.data?.message
          for (let err of errors) {
            toast.error('Error' + String(err), {
              pauseOnHover: true,
              autoClose: 6000,
            })
          }
        }
        console.log('WTF1')
        if (this.props.closeModal) {
          console.log("B1")
          // toggleSidebar(false)
          console.log("B2")
          this.props.closeModal(false)
          console.log("B3")
        }
        console.log('WTF2')
      })
      .catch((err) => {
        console.log('ERROR 3: ', err)
        toast.error('Duplicate claim submission, contact support!')
      })
  }

  saveCMS1500Form = async () => {
    console.log('SAVE CMS 1500 FORM')

    if (!this.checkIfCanSubmit()) {
      // toast.error('Please fill in all the required fields')
      return
    }

    let {
      payerName,
      payerAddress,
      payerId,
      payerCity,
      payerType,
      patientFirstName,
      patientLastName,
      patientMiddleName,
      patientDob,
      patientGender,
      patientAddress,
      patientAddress2,
      patientCity,
      patientState,
      patientZip,
      patientCountry,
      patientRelationship,
      patientMartialStatus,
      patientEmploymentStatus,
      insuredIdNumber,
      insuredFirstName,
      insuredLastName,
      insuredMiddleName,
      insuredAddress,
      insuredAddress2,
      insuredCity,
      insuredState,
      insuredZip,
      insuredCountry,
      secondaryInsuredFirstName,
      secondaryInsuredLastName,
      secondaryInsuredMiddleName,
      secondaryInsuredPolicyNumber,
      secondaryInsuredDob,
      secondaryInsuredSex,
      primaryPaymentDate,
      secondaryPayerName,
      secondaryPayerId,
      secondaryPayerGroupName,
      secondaryPayerGroupNumber,
      secondaryPayerClaimId,
      secondaryMedicareTypeCode,
      secondaryPatientRelationship,
      patientConditionEmploymentStatus,
      patientConditionAutoAccident,
      patientConditionAutoAccidentState,
      patientConditionAutoAccidentCountry,
      patientConditionOtherAccident,
      claimCodes,
      insuredPolicyGroup,
      insuredDob,
      insuredSex,
      insuredEmployersName,
      insurancePlanName,
      supervisingProviderFirstName,
      supervisingProviderLastName,
      supervisingProviderMiddleName,
      supervisingProviderNpi,
      supervisingProviderId,
      dateOfCondition,
      addDateOfCondition,
      referringPhysicianFirstName,
      referringPhysicianLastName,
      referringPhysicianMiddleName,
      referringPhysicianNpi,
      referringPhysicianOtherId,
      claimNarrative,
      hospitalizationDateFrom,
      hospitalizationDateTo,
      resubmissionCode,
      priorAuthorizationNumber,
      referralNumber,
      cliaNumber,
      diagnosisA,
      diagnosisB,
      diagnosisC,
      diagnosisD,
      diagnosisE,
      diagnosisF,
      diagnosisG,
      diagnosisH,
      diagnosisI,
      diagnosisJ,
      diagnosisK,
      diagnosisL,
      diagnosisList,
      taxId,
      taxIdType,
      patientAccountNumber,
      acceptAssignment,
      totalCharge,
      totalAmountPaid,
      balanceDue,
      renderingProviderFirstName,
      renderingProviderLastName,
      renderingProviderMiddleName,
      renderingProviderNpi,
      renderingProviderId,
      renderingProviderTaxonomy,
      facilityName,
      facilityAddress,
      facilityAddress2,
      facilityCity,
      facilityState,
      facilityZip,
      facilityCountry,
      facilityNpi,
      facilityId,
      billingProviderName,
      billingProviderAddress,
      billingProviderAddress2,
      billingProviderCity,
      billingProviderState,
      billingProviderZip,
      billingProviderNpi,
      billingProviderId,
      billingProviderTaxonomy,
      billingProviderPhone,
    } = this.state

    console.log('Here1')
    // Sanitize the data
    if (patientDob)
      patientDob =
        patientDob?.getFullYear() +
        '-' +
        (patientDob?.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        patientDob?.getDate().toString().padStart(2, '0')
    if (secondaryInsuredDob)
      secondaryInsuredDob =
        secondaryInsuredDob.getFullYear() +
        '-' +
        (secondaryInsuredDob.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        secondaryInsuredDob.getDate().toString().padStart(2, '0')
    if (hospitalizationDateFrom)
      hospitalizationDateFrom =
        hospitalizationDateFrom.getFullYear() +
        (hospitalizationDateFrom.getMonth() + 1).toString().padStart(2, '0') +
        hospitalizationDateFrom.getDate().toString().padStart(2, '0')
    if (hospitalizationDateTo)
      hospitalizationDateTo =
        hospitalizationDateTo.getFullYear() +
        (hospitalizationDateTo.getMonth() + 1).toString().padStart(2, '0') +
        hospitalizationDateTo.getDate().toString().padStart(2, '0')
    if (insuredDob)
      insuredDob =
        insuredDob.getFullYear() +
        (insuredDob.getMonth() + 1).toString().padStart(2, '0') +
        insuredDob.getDate().toString().padStart(2, '0')
    if (dateOfCondition)
      dateOfCondition =
        dateOfCondition.getFullYear() +
        '-' +
        (dateOfCondition.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        dateOfCondition.getDate().toString().padStart(2, '0')
    // let localDiagRef = ""
    for (let i = 0; i < diagnosisList.length; i++) {
      if (diagnosisList[i].dateOfServiceStart)
        diagnosisList[i].dateOfServiceStart =
          diagnosisList[i].dateOfServiceStart.getFullYear() +
          '-' +
          (diagnosisList[i].dateOfServiceStart.getMonth() + 1)
            .toString()
            .padStart(2, '0') +
          '-' +
          diagnosisList[i].dateOfServiceStart
            .getDate()
            .toString()
            .padStart(2, '0')
      if (diagnosisList[i].dateOfServiceEnd)
        diagnosisList[i].dateOfServiceEnd =
          diagnosisList[i].dateOfServiceEnd.getFullYear() +
          '-' +
          (diagnosisList[i].dateOfServiceEnd.getMonth() + 1)
            .toString()
            .padStart(2, '0') +
          '-' +
          diagnosisList[i].dateOfServiceEnd
            .getDate()
            .toString()
            .padStart(2, '0')
    }

    console.log('diagnosisList: ', diagnosisList)
    console.log('Here2')
    // Send data to the backend
    axios({
      method: this.props.savedClaim ? 'patch' : 'put',
      url: `${Config.BACKEND_URL}claims/save`,
      headers: {
        // Authorization: `JWT ${token}`,
        Authorization: `JWT `,
      },
      data: {
        payerName,
        payerAddress,
        payerId,
        payerCity,
        payerType: payerType?.value,
        patientFirstName,
        patientLastName,
        patientMiddleName,
        patientDob,
        patientGender: patientGender?.value,
        patientAddress,
        patientAddress2,
        patientCity,
        patientState,
        patientZip,
        patientCountry,
        patientRelationship: patientRelationship?.value,
        patientMartialStatus: patientMartialStatus?.value,
        patientEmploymentStatus: patientEmploymentStatus?.value,
        insuredIdNumber,
        insuredFirstName,
        insuredLastName,
        insuredMiddleName,
        insuredAddress,
        insuredAddress2,
        insuredCity,
        insuredState,
        insuredZip,
        insuredCountry,
        secondaryInsuredFirstName,
        secondaryInsuredLastName,
        secondaryInsuredMiddleName,
        secondaryInsuredPolicyNumber,
        secondaryInsuredDob,
        secondaryInsuredSex: secondaryInsuredSex?.value,
        primaryPaymentDate,
        secondaryPayerName,
        secondaryPayerId,
        secondaryPayerGroupName,
        secondaryPayerGroupNumber,
        secondaryPayerClaimId,
        secondaryMedicareTypeCode,
        secondaryPatientRelationship,
        patientConditionEmploymentStatus:
          patientConditionEmploymentStatus?.value,
        patientConditionAutoAccident: patientConditionAutoAccident?.value,
        patientConditionAutoAccidentState,
        patientConditionAutoAccidentCountry,
        patientConditionOtherAccident: patientConditionOtherAccident?.value,
        claimCodes,
        insuredPolicyGroup,
        insuredDob,
        insuredSex: insuredSex?.value,
        insuredEmployersName,
        insurancePlanName,
        supervisingProviderFirstName,
        supervisingProviderLastName,
        supervisingProviderMiddleName,
        supervisingProviderNpi,
        supervisingProviderId,
        dateOfCondition,
        addDateOfCondition,
        referringPhysicianFirstName,
        referringPhysicianLastName,
        referringPhysicianMiddleName,
        referringPhysicianNpi,
        referringPhysicianOtherId,
        claimNarrative,
        hospitalizationDateFrom,
        hospitalizationDateTo,
        resubmissionCode: resubmissionCode?.value,
        priorAuthorizationNumber,
        referralNumber,
        cliaNumber,
        diagnosisA,
        diagnosisB,
        diagnosisC,
        diagnosisD,
        diagnosisE,
        diagnosisF,
        diagnosisG,
        diagnosisH,
        diagnosisI,
        diagnosisJ,
        diagnosisK,
        diagnosisL,
        diagnosisList,
        taxId,
        taxIdType: taxIdType?.value,
        patientAccountNumber,
        acceptAssignment: acceptAssignment?.value,
        totalCharge,
        totalAmountPaid,
        balanceDue,
        renderingProviderFirstName,
        renderingProviderLastName,
        renderingProviderMiddleName,
        renderingProviderNpi,
        renderingProviderId,
        renderingProviderTaxonomy,
        facilityName,
        facilityAddress,
        facilityAddress2,
        facilityCity,
        facilityState,
        facilityZip,
        facilityCountry,
        facilityNpi,
        facilityId,
        billingProviderName,
        billingProviderAddress,
        billingProviderAddress2,
        billingProviderCity,
        billingProviderState,
        billingProviderZip,
        billingProviderNpi,
        billingProviderId,
        billingProviderTaxonomy,
        billingProviderPhone,
        eventId: this.props.eventId,
      },
    })
      .then((res) => {
        if (res?.data?.success == true) {
          let date = new moment().format('MM/DD/YYYY')
          this.setState({ claimSubmissionDate: date })
          toast.success(
            `Successfully ${this.props.savedClaim ? 'updated' : 'saved'} claim`,
          )
        } else {
          toast.error(
            `Something went wrong while ${
              this.props.savedClaim ? 'updating' : 'saving'
            } claim!`,
          )
          toast.error(res?.data?.message)
        }
      })
      .catch((err) => {
        console.log('ERROR 5: ', err)
        toast.error(
          `Something went wrong while ${
            this.props.savedClaim ? 'updating' : 'saving'
          } claim!`,
        )
      })
  }

  range = (start, end) => {
    var ans = []
    for (let i = start; i <= end; i++) {
      ans.push(i)
    }
    return ans
  }

  getMonth(date) {
    // console.log('DATEM:', date.getMonth())
    let date_num = date.getMonth()
    switch (date_num) {
      case 0:
        return 'January'
      case 1:
        return 'February'
      case 2:
        return 'March'
      case 3:
        return 'April'
      case 4:
        return 'May'
      case 5:
        return 'June'
      case 6:
        return 'July'
      case 7:
        return 'August'
      case 8:
        return 'September'
      case 9:
        return 'October'
      case 10:
        return 'November'
      case 11:
        return 'December'
      default: {
        console.log('ERROR1: Invalid Month')
        return 'January'
      }
      // case 'February':
      //   return 1
      // case 'March':
      //   return 2
      // case 'April':
      //   return 3
      // case 'May':
      //   return 4
      // case 'June':
      //   return 5
      // case 'July':
      //   return 6
      // case 'August':
      //   return 7
      // case 'September':
      //   return 8
      // case 'October':
      //   return 9
      // case 'November':
      //   return 10
      // case 'December':
      //   return 11
      // default: {
      //   console.log('ERROR2: Invalid Month')
      //   return 0
      // }
    }
  }

  getYear(date) {
    console.log('getYear:', date.getFullYear())
    return date.getFullYear()
  }

  onChangeSearchTerm = (e) => {
    console.log('EEE', e.target.value)
    this.setState({ payerName: e.target.value })
  }

  // handleInputKeyDown = (event) => {
  //   // Prevent default behavior of the event, which causes the input field to lose focus
  //   console.log('EEE1', event)
  //   if (event.key === "Enter" || event.key === "Escape") {
  //     // event.preventDefault();

  //   console.log('EEE12', event)
  //   }
  // }

  renderSearchList = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Payer Name
        </h4>

        <div className={Styles.row} style={{ margin: '2% 0%' }}>
          <p className={Styles.rowLabel}>Payer Name</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <SearchProvider config={esConfigPayer}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'payer_name',
                        }}
                        searchAsYouType={true}
                        onSelectAutocomplete={(e) => {
                          this.setState({
                            payerName: e?.payer_name?.raw,
                            payerId: e?.payerid?.raw,
                            showPayerSearchModal: false,
                          })
                        }}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Payer Name',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
        </div>

        {/* <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {searchList.length !== 0 ? (
            searchList.map((insurance, index) => renderList(insurance, index))
          ) : addInsurance ? (
            renderAddInsurance()
          ) : insuranceSearchLoading ? (
            renderLoader()
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search by city, name and address
            </p>
          )}
        </div> */}
      </div>
    )
  }

  render() {
    const years = this.range(1930, 2023, 1)
    // BUG WITH SELECTING JAN
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    // if (this.state.loading) {
    //   return (
    //     <div
    //       className={GlobalStyles.container}
    //       style={{ overflow: 'hidden', maxHeight: '100vh' }}
    //     >
    //       <div
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //           minWidth: '100%',
    //           height: '80vh',
    //         }}
    //       >
    //         <div className={GlobalStyles.loader} />
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <h4
            style={{
              color: Constants.primaryTheme,
              width: '100%',
              marginBottom: 10,
              textAlign: 'center',
            }}
          >
            CMS 1500 Form
          </h4>
        </div>

        {/* Payer Search Modal */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showPayerSearchModal}
          onRequestClose={() => {
            this.setState({ showPayerSearchModal: false })
          }}
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderSearchList()}
        </Modal>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1, padding: '10px' }}>
            <h6
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              <span>
                Claim Submission Date: {this.state.claimSubmissionDate} PDT
              </span>
            </h6>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Claim Status: {this.state.claimStatus}
            </h5>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Clearing House #: {this.state.claimMdNumber}
            </h5>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Claim #: {this.state.claimMdNumber}
            </h5>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Cheque #: {this.state.chequeNumber}
            </h5>
          </div>
        </div>

        {/* Form Elements */}
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {/* Row 1: 2 columns */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>1. Payer Name</p>
              {/* <SearchProvider config={esConfigPayer}>
                <WithSearch
                  mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                    wasSearched,
                    setSearchTerm,
                  })}
                >
                  {({ wasSearched, setSearchTerm }) => {
                    // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                    return (
                      <div className="App">
                        <EsSearchBox
                          debounceLength={0}
                          autocompleteResults={{
                            titleField: 'payer_name',
                          }}
                          searchAsYouType={true}
                          onSelectAutocomplete={(e) => {
                            // setCondition({
                            //   id: e?.code.raw,
                            //   name: e?.display.raw,
                            // })
                            this.setState({ payerName: e.payer_name.raw })
                            this.setState({ payerId: e.payerid.raw })
                            setSearchTerm(e.payer_name.raw)
                          }}
                          // inputProps={{
                          //   placeholder: this.state.payerName,
                          //   // value: this.state.payerName,
                          //   // onChange: this.onChangeSearchTerm,
                          // }}
                          inputView={({
                            getAutocomplete,
                            getInputProps,
                            getButtonProps,
                          }) => (
                            <>
                              <div className="sui-search-box__wrapper">
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Payer Name",
                                    // placeholder: this.state.payerName,
                                    // value: this.state.payerName,
                                    // onChange: this.onChangeSearchTerm(
                                    //   setSearchTerm,
                                    // ),
                                    // onChange: ,
                                    // onKeyDown: this.handleInputKeyDown,
                                  })}
                                />
                                {getAutocomplete()}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    )
                  }}
                </WithSearch>
              </SearchProvider> */}
              <input
                placeholder={'Enter Payer Name'}
                className={Styles.studyEntryInput}
                style={{
                  backgroundColor: this.state.payerName
                    ? '#FFFFFF'
                    : REQUIRED_BK_COLOR,
                }}
                type="text"
                value={this.state.payerName}
                onChange={(e) => this.setState({ payerName: e.target.value })}
              />
              <FontAwesomeIcon
                onClick={() => {
                  this.setState({ showPayerSearchModal: true })
                }}
                icon={faSearch}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ minWidth: '50%' }}>
                  <p className={Styles.studyEntryKey}>Payer ID</p>
                  <input
                    placeholder={'Payer ID'}
                    className={Styles.studyEntryInput}
                    style={{
                      backgroundColor: this.state.payerId
                        ? '#FFFFFF'
                        : REQUIRED_BK_COLOR,
                    }}
                    type="text"
                    value={this.state.payerId}
                    onChange={(e) => this.setState({ payerId: e.target.value })}
                  />
                </div>
                <div style={{ width: '100%', marginLeft: '5px' }}>
                  <p className={Styles.studyEntryKey}>Type</p>
                  <Select
                    placeholder="Select Type"
                    inputId="payerType"
                    options={[
                      { label: 'PRIMARY', value: 'Primary' },
                      { label: 'SECONDARY', value: 'Secondary' },
                      { label: 'TERTIARY', value: 'Tertiary' },
                    ]}
                    value={this.state.payerType}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({ payerType: val })
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        background: this.state.payerType
                          ? '#FFFFFF'
                          : REQUIRED_BK_COLOR,
                        // match with the menu
                        // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
                        // Overwrittes the different states of border
                        // borderColor: state.isFocused ? 'yellow' : 'green',
                        // Removes weird border around container
                        // boxShadow: state.isFocused ? null : null,
                        // '&:hover': {
                        //   // Overwrittes the different states of border
                        //   borderColor: state.isFocused ? 'red' : 'blue',
                        // },
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>1a. Insured ID Number</p>
              <input
                placeholder={'Insured ID Number'}
                className={Styles.studyEntryInput}
                style={{
                  backgroundColor: this.state.insuredIdNumber
                    ? '#FFFFFF'
                    : REQUIRED_BK_COLOR,
                }}
                type="text"
                value={this.state.insuredIdNumber}
                onChange={(e) =>
                  this.setState({ insuredIdNumber: e.target.value })
                }
              />
            </div>
          </div>

          {/* Row 2: 3 columns */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>2. Patient Name (L,F,M)</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  placeholder={'Last Name'}
                  className={Styles.studyEntryInput}
                  style={{
                    backgroundColor: this.state.patientLastName
                      ? '#FFFFFF'
                      : REQUIRED_BK_COLOR,
                  }}
                  type="text"
                  value={this.state.patientLastName}
                  onChange={(e) =>
                    this.setState({ patientLastName: e.target.value })
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    margin: '0 0px',
                  }}
                >
                  ,
                </div>
                <input
                  placeholder={'First Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                    backgroundColor: this.state.patientFirstName
                      ? '#FFFFFF'
                      : REQUIRED_BK_COLOR,
                  }}
                  value={this.state.patientFirstName}
                  onChange={(e) =>
                    this.setState({ patientFirstName: e.target.value })
                  }
                />
                <input
                  placeholder={'Middle Initial'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                    width: '75px',
                  }}
                  value={this.state.patientMiddleName}
                  onChange={(e) =>
                    this.setState({ patientMiddleName: e.target.value })
                  }
                />
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ minWidth: '50%' }}>
                  <p className={Styles.studyEntryKey}>3. Patient DOB</p>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {'<'}
                        </button>
                        <select
                          // BUG: value needs a value
                          value={this.getYear(date)}
                          onChange={({ target: { value } }) => {
                            changeYear(value)
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          // BUG: 0 needs to be the correct index
                          value={months[this.getMonth(date)]}
                          onChange={({ target: { value } }) => {
                            console.log(
                              'changing Month to:',
                              value,
                              months.indexOf(value),
                            )
                            changeMonth(months.indexOf(value))
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {'>'}
                        </button>
                      </div>
                    )}
                    selected={this.state.patientDob}
                    onChange={(dates) => {
                      console.log('dates ', dates)
                      this.setState({ patientDob: dates })
                    }}
                  />
                  {/* <input
                    placeholder={'Patient Date of Birth'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientDob}
                    onChange={(e) =>
                      this.setState({ patientDob: e.target.value })
                    }
                  /> */}
                </div>
                <div style={{ marginLeft: '5px', width: '100%' }}>
                  <p className={Styles.studyEntryKey}>Patient Sex</p>
                  <Select
                    placeholder="Select Type"
                    inputId="patientSex"
                    options={PATIENT_GENDER_OPTIONS}
                    value={this.state.patientGender}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({ patientGender: val })
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        background: this.state.patientGender
                          ? '#FFFFFF'
                          : REQUIRED_BK_COLOR,
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                padding: '10px',
                // borderLeft: '2px solid black',
                // borderTop: '2px solid black',
                // borderRight: '2px solid black',
              }}
            >
              <p className={Styles.studyEntryKey}>4. Insured Name(L,F,M)</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  placeholder={'Last Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.insuredLastName}
                  onChange={(e) =>
                    this.setState({ insuredLastName: e.target.value })
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    margin: '0 0px',
                  }}
                >
                  ,
                </div>
                <input
                  placeholder={'First Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.insuredFirstName}
                  style={{
                    marginLeft: '5px',
                  }}
                  onChange={(e) =>
                    this.setState({ insuredFirstName: e.target.value })
                  }
                />
                <input
                  placeholder={'Middle Initial'}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.insuredMiddleName}
                  style={{
                    marginLeft: '5px',
                    width: '75px',
                  }}
                  onChange={(e) =>
                    this.setState({ insuredMiddleName: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          {/* Row 3: 3 columns */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>5. Patient Address</p>
              <input
                placeholder={'Patient Address'}
                className={Styles.studyEntryInput}
                style={{
                  backgroundColor: this.state.patientAddress
                    ? '#FFFFFF'
                    : REQUIRED_BK_COLOR,
                }}
                type="text"
                value={this.state.patientAddress}
                onChange={(e) =>
                  this.setState({ patientAddress: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>6. Patient Relationship</p>
              <Select
                placeholder="Select Type"
                inputId="patientRelationship"
                options={PAYER_RELATIONSHIP_OPTIONS}
                value={this.state.patientRelationship}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ patientRelationship: val })
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    background: this.state.patientRelationship
                      ? '#FFFFFF'
                      : REQUIRED_BK_COLOR,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div
              style={{
                flex: 1,
                padding: '10px',
                // borderLeft: '2px solid black',
                // borderRight: '2px solid black',
              }}
            >
              <p className={Styles.studyEntryKey}>7. Insured Address</p>
              <input
                placeholder={'Insured Address'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.insuredAddress}
                onChange={(e) =>
                  this.setState({ insuredAddress: e.target.value })
                }
              />
            </div>
          </div>

          {/* Row 4: 2 columns */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Patient Address 2</p>
              <input
                placeholder={'Patient Address 2'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.patientAddress2}
                onChange={(e) =>
                  this.setState({ patientAddress2: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>8. Marital Status</p>
              <Select
                placeholder="Select Type"
                inputId="martialStatus"
                options={[
                  { label: 'Married', value: 'Y' },
                  { label: 'Single', value: 'N' },
                ]}
                value={this.state.patientMartialStatus}
                isMulti={false}
                isSearchable
                autoBlur
                isDisabled={true}
                onChange={(val) => {
                  this.setState({ patientMartialStatus: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div
              style={{
                flex: 1,
                padding: '10px',
                // borderLeft: '2px solid black',
                // borderBottom: '2px solid black',
              }}
            >
              <p className={Styles.studyEntryKey}>Insured Address 2</p>
              <input
                placeholder={'Insured Address 2'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.insuredAddress2}
                onChange={(e) =>
                  this.setState({ insuredAddress2: e.target.value })
                }
              />
            </div>
          </div>

          {/* Row 5: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <p className={Styles.studyEntryKey}>Patient City</p>
                  <input
                    placeholder={'Patient City'}
                    className={Styles.studyEntryInput}
                    style={{
                      width: '100%',
                      backgroundColor: this.state.patientCity
                        ? '#FFFFFF'
                        : REQUIRED_BK_COLOR,
                    }}
                    type="text"
                    value={this.state.patientCity}
                    onChange={(e) =>
                      this.setState({ patientCity: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>State</p>
                  <input
                    placeholder={'State'}
                    className={Styles.studyEntryInput}
                    style={{
                      backgroundColor: this.state.patientState
                        ? '#FFFFFF'
                        : REQUIRED_BK_COLOR,
                    }}
                    type="text"
                    value={this.state.patientState}
                    onChange={(e) =>
                      this.setState({ patientState: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>Zip</p>
                  <input
                    placeholder={'Zip'}
                    className={Styles.studyEntryInput}
                    style={{
                      backgroundColor: this.state.patientZip
                        ? '#FFFFFF'
                        : REQUIRED_BK_COLOR,
                    }}
                    type="text"
                    value={this.state.patientZip}
                    onChange={(e) =>
                      this.setState({ patientZip: e.target.value })
                    }
                  />
                </div>

                <div>
                  <p className={Styles.studyEntryKey}>Country</p>
                  <input
                    placeholder={'Country'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientCountry}
                    onChange={(e) =>
                      this.setState({ patientCountry: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Employment Status</p>
              <Select
                placeholder="Select Type"
                inputId="employmentStatus"
                options={[
                  { label: 'Yes', value: 'Y' },
                  { label: 'No', value: 'N' },
                ]}
                value={this.state.patientEmploymentStatus}
                isMulti={false}
                isSearchable
                autoBlur
                isDisabled={true}
                onChange={(val) => {
                  this.setState({ patientEmploymentStatus: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <p className={Styles.studyEntryKey}>Insured City</p>
                  <input
                    placeholder={'Insured City'}
                    className={Styles.studyEntryInput}
                    style={{
                      width: '100%',
                    }}
                    type="text"
                    value={this.state.insuredCity}
                    onChange={(e) =>
                      this.setState({ insuredCity: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>State</p>
                  <input
                    placeholder={'State'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.insuredState}
                    onChange={(e) =>
                      this.setState({ insuredState: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>Zip</p>
                  <input
                    placeholder={'Zip'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.insuredZip}
                    onChange={(e) =>
                      this.setState({ insuredZip: e.target.value })
                    }
                  />
                </div>

                <div>
                  <p className={Styles.studyEntryKey}>Country</p>
                  <input
                    placeholder={'Insured Country'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.insuredCountry}
                    onChange={(e) =>
                      this.setState({ insuredCountry: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Row 6: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderTop: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>
                9. Secondary Insured Name(L,F,M)
              </p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  placeholder={'Last Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.secondaryInsuredLastName}
                  onChange={(e) =>
                    this.setState({ secondaryInsuredLastName: e.target.value })
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    margin: '0 0px',
                  }}
                >
                  ,
                </div>
                <input
                  placeholder={'First Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                  }}
                  value={this.state.secondaryInsuredFirstName}
                  onChange={(e) =>
                    this.setState({ secondaryInsuredFirstName: e.target.value })
                  }
                />
                <input
                  placeholder={'Middle Initial'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                    width: '75px',
                  }}
                  value={this.state.secondaryInsuredMiddleName}
                  onChange={(e) =>
                    this.setState({
                      secondaryInsuredMiddleName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>
                9a. Secondary Insured Policy Number
              </p>
              <input
                placeholder={'Secondary Insured Policy'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.secondaryInsuredPolicyNumber}
                onChange={(e) =>
                  this.setState({
                    secondaryInsuredPolicyNumber: e.target.value,
                  })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <p className={Styles.studyEntryKey}>
                    9b. Secondary Insured DOB
                  </p>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {'<'}
                        </button>
                        <select
                          // BUG: value needs a value
                          // value={this.getYear(date)}
                          onChange={({ target: { value } }) => {
                            changeYear(value)
                          }}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          // BUG: 0 needs to be the correct index
                          value={months[this.getMonth(date)]}
                          onChange={({ target: { value } }) => {
                            changeMonth(months.indexOf(value))
                          }}
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {'>'}
                        </button>
                      </div>
                    )}
                    selected={this.state.secondaryInsuredDob}
                    onChange={(dates) => {
                      console.log('dates ', dates)
                      this.setState({ secondaryInsuredDob: dates })
                    }}
                  />
                </div>
                <div>
                  <p
                    className={Styles.studyEntryKey}
                    style={{ paddingLeft: '10px' }}
                  >
                    Secondary Insured Sex
                  </p>
                  <Select
                    placeholder="Select Sex"
                    inputId="SecondaryInsuredSex"
                    options={[
                      { label: 'Male', value: 'M' },
                      { label: 'Female', value: 'F' },
                      { label: 'Unknown', value: 'U' },
                    ]}
                    value={this.state.secondaryInsuredSex}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({ secondaryInsuredSex: val })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Row 7: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <div>
                <p className={Styles.studyEntryKey}>9c. Primary Payment Date</p>
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {'<'}
                      </button>
                      <select
                        // BUG: value needs a value
                        value={this.getYear(date)}
                        onChange={({ target: { value } }) => {
                          changeYear(value)
                        }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        // BUG: 0 needs to be the correct index
                        value={months[this.getMonth(date)]}
                        onChange={({ target: { value } }) => {
                          changeMonth(months.indexOf(value))
                        }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  selected={this.state.primaryPaymentDate}
                  onChange={(dates) => {
                    console.log('dates ', dates)
                    this.setState({ primaryPaymentDate: dates })
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <p className={Styles.studyEntryKey}>
                    d. Secondary Payer Name
                  </p>
                  <input
                    placeholder={'Secondary Payer Name'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.secondaryPayerName}
                    onChange={(e) =>
                      this.setState({ secondaryPayerName: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>Secondary Payer ID</p>
                  <input
                    placeholder={'Secondary Payer ID'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.secondaryPayerId}
                    onChange={(e) =>
                      this.setState({ secondaryPayerId: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <p className={Styles.studyEntryKey}>
                    Secondary Payer Claim ID
                  </p>
                  <input
                    placeholder={'Secondary Payer Claim ID'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.secondaryPayerClaimId}
                    onChange={(e) =>
                      this.setState({ secondaryPayerClaimId: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>Medicare Type Code</p>
                  <Select
                    placeholder="Code"
                    inputId="medicareTypeCode"
                    options={[
                      { label: 'Working Age', value: '01' },
                      { label: 'Medicare Disabled', value: '18' },
                      { label: 'Conditional/Accident', value: '19' },
                      { label: 'End-Stage Renal Beneficiary', value: '20' },
                      { label: 'Auto Accident', value: '21' },
                      { label: 'Workers Comp/ Black Lung', value: '39' },
                      { label: 'Other Federal Agency', value: '40' },
                      { label: 'WCMSA', value: '53' },
                      { label: 'BLACK LUNG', value: 'G8' },
                      { label: 'VETERANS ADMINISTRATION', value: 'G8' },
                      { label: 'Liability', value: 'G8' },
                    ]}
                    value={this.state.secondaryMedicareTypeCode}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({
                        secondaryMedicareTypeCode: val,
                      })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Row 8: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>
                9e. Secondary Patient Relationship
              </p>
              <Select
                placeholder="Select Type"
                inputId="SecondaryPatientRelationship"
                options={
                  // Use the ones in the metadata.RELATIONSHIP_OPTIONS
                  [
                    { label: 'SPOUSE', value: '01' },
                    { label: 'SELF', value: '18' },
                    { label: 'CHILD', value: '19' },
                    { label: 'EMPLOYEE', value: '20' },
                    { label: 'UNKNOWN', value: '21' },
                    { label: 'ORGAN DONOR', value: '39' },
                    { label: 'CADAVER DONOR', value: '40' },
                    { label: 'LIFE PARTNER', value: '53' },
                    { label: 'OTHER RELATIONSHIP', value: 'G8' },
                  ]
                }
                value={this.state.secondaryPatientRelationship}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({
                    secondaryPatientRelationship: val,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Secondary Payer Group Name</p>
              <input
                placeholder={'Secondary Payer Group Name'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.secondaryPayerGroupName}
                onChange={(e) =>
                  this.setState({ secondaryPayerGroupName: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>
                9f. Secondary Payer Group Number
              </p>
              <input
                placeholder={'Secondary Payer Group Number'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.secondaryPayerGroupNumber}
                onChange={(e) =>
                  this.setState({ secondaryPayerGroupNumber: e.target.value })
                }
              />
            </div>
          </div>

          <div
            style={{
              width: '100%',
            }}
          >
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              10. Patient Condition Related
            </h5>
          </div>
          {/* Row 9: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>10a. Employment</p>
              <Select
                placeholder="Select Type"
                inputId="employmentStatus"
                options={[
                  { label: 'Yes', value: 'Y' },
                  { label: 'No', value: 'N' },
                ]}
                value={this.state.patientConditionEmploymentStatus}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ patientConditionEmploymentStatus: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%' }}>
                  <p className={Styles.studyEntryKey}>10b. Auto Accident</p>
                  <Select
                    placeholder="Auto Accident"
                    inputId="autoAccident"
                    options={[
                      { label: 'Yes', value: 'Y' },
                      { label: 'No', value: 'N' },
                    ]}
                    value={this.state.patientConditionAutoAccident}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({
                        patientConditionAutoAccident: val,
                      })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
                <div style={{ marginLeft: '5px' }}>
                  <p className={Styles.studyEntryKey}>State</p>
                  <input
                    placeholder={'Auto Accident'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientConditionAutoAccidentState}
                    onChange={(e) =>
                      this.setState({
                        patientConditionAutoAccidentState: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>Country</p>
                  <input
                    placeholder={'Auto Accident'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientConditionAutoAccidentCountry}
                    onChange={(e) =>
                      this.setState({
                        patientConditionAutoAccidentCountry: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>10c. Other Accident</p>
              <Select
                placeholder="Other Accident"
                inputId="otherAccident"
                options={[
                  { label: 'Yes', value: 'Y' },
                  { label: 'No', value: 'N' },
                ]}
                value={this.state.patientConditionOtherAccident}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({
                    patientConditionOtherAccident: val,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          {/* Row 10: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>10d. Claim Code</p>
              <input
                placeholder={'Claim Codes'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.claimCodes}
                onChange={(e) => this.setState({ claimCodes: e.target.value })}
              />
            </div>
          </div>

          {/* Row 11: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>11. Insured Policy Group</p>
              <input
                placeholder={'Insured Policy Group'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.insuredPolicyGroup}
                onChange={(e) =>
                  this.setState({ insuredPolicyGroup: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              {/* <p className={Styles.studyEntryKey}>11a. Insured DOB/ Sex</p> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ minWidth: '50%' }}>
                  <p className={Styles.studyEntryKey}>11a. Insured DOB</p>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {'<'}
                        </button>
                        <select
                          // BUG: value needs a value
                          // value={this.getYear(date)}
                          onChange={({ target: { value } }) => {
                            changeYear(value)
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          // BUG: 0 needs to be the correct index
                          value={months[this.getMonth(date)]}
                          onChange={({ target: { value } }) => {
                            changeMonth(months.indexOf(value))
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {'>'}
                        </button>
                      </div>
                    )}
                    placeholder="Date of Birth"
                    // value={this.state.patientDob}
                    // selected={this.state.patientDob}
                    selected={this.state.insuredDob}
                    onChange={(dates) => {
                      console.log('dates ', dates)
                      this.setState({ insuredDob: dates })
                    }}
                  />
                  {/* <input
                    placeholder={'Patient Date of Birth'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientDob}
                    onChange={(e) =>
                      this.setState({ patientDob: e.target.value })
                    }
                  /> */}
                </div>
                <div style={{ marginLeft: '5px', width: '100%' }}>
                  <p className={Styles.studyEntryKey}>Insured Sex</p>
                  <Select
                    placeholder="Select Type"
                    inputId="insuredSex"
                    options={[
                      { label: 'Male', value: 'M' },
                      { label: 'Female', value: 'F' },
                      { label: 'Unknown', value: 'U' },
                    ]}
                    value={this.state.insuredSex}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({ insuredSex: val })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>11b. Employers name</p>
              <input
                placeholder={'Employers Name'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.insuredEmployersName}
                onChange={(e) =>
                  this.setState({ insuredEmployersName: e.target.value })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>11c. Insurance Plan Name</p>
              <input
                placeholder={'Insurance Plan Name'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.insurancePlanName}
                onChange={(e) =>
                  this.setState({ insurancePlanName: e.target.value })
                }
              />
            </div>
          </div>

          {/* Row 12: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>
                Supervising Provider Name(L,F,M)
              </p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  placeholder={'Last Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.supervisingProviderLastName}
                  onChange={(e) =>
                    this.setState({
                      supervisingProviderLastName: e.target.value,
                    })
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    margin: '0 0px',
                  }}
                >
                  ,
                </div>
                <input
                  placeholder={'First Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                  }}
                  value={this.state.supervisingProviderFirstName}
                  onChange={(e) =>
                    this.setState({
                      supervisingProviderFirstName: e.target.value,
                    })
                  }
                />
                <input
                  placeholder={'Middle Initial'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                    width: '75px',
                  }}
                  value={this.state.supervisingProviderMiddleName}
                  onChange={(e) =>
                    this.setState({
                      supervisingProviderMiddleName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Supervising Provider ID</p>
              <input
                placeholder={'Supervising Provider ID'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.supervisingProviderId}
                onChange={(e) =>
                  this.setState({ supervisingProviderId: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Supervising Provider NPI</p>
              <input
                placeholder={'Supervising Provider NPI'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.supervisingProviderNpi}
                onChange={(e) =>
                  this.setState({ supervisingProviderNpi: e.target.value })
                }
              />
            </div>
          </div>

          {/* Row 12: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <p className={Styles.studyEntryKey}>14. Date of Condition</p>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {'<'}
                        </button>
                        <select
                          // BUG: value needs a value
                          // value={this.getYear(date)}
                          onChange={({ target: { value } }) => {
                            changeYear(value)
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          // BUG: 0 needs to be the correct index
                          value={months[this.getMonth(date)]}
                          onChange={({ target: { value } }) => {
                            changeMonth(months.indexOf(value))
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {'>'}
                        </button>
                      </div>
                    )}
                    placeholder="Date of Condition"
                    selected={this.state.dateOfCondition}
                    onChange={(dates) => {
                      console.log('dates ', dates)
                      this.setState({ dateOfCondition: dates })
                    }}
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>Add Date</p>
                  <Select
                    placeholder="Date Field"
                    inputId="addDate"
                    options={ADD_DATE_OPTIONS}
                    value={this.state.addDateOfCondition}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({
                        addDateOfCondition: val,
                      })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>
                17. Referring Physician Name(L,F,M)
              </p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  placeholder={'Last Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.referringPhysicianLastName}
                  onChange={(e) =>
                    this.setState({
                      referringPhysicianLastName: e.target.value,
                    })
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    margin: '0 0px',
                  }}
                >
                  ,
                </div>
                <input
                  placeholder={'First Name'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                  }}
                  value={this.state.referringPhysicianFirstName}
                  onChange={(e) =>
                    this.setState({
                      referringPhysicianFirstName: e.target.value,
                    })
                  }
                />
                <input
                  placeholder={'Middle Initial'}
                  className={Styles.studyEntryInput}
                  type="text"
                  style={{
                    marginLeft: '5px',
                    width: '75px',
                  }}
                  value={this.state.referringPhysicianMiddleName}
                  onChange={(e) =>
                    this.setState({
                      referringPhysicianMiddleName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <p className={Styles.studyEntryKey}>17a. Referring NPI</p>
                  <input
                    placeholder={'Referring NPI'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.referringPhysicianNpi}
                    onChange={(e) =>
                      this.setState({ referringPhysicianNpi: e.target.value })
                    }
                  />
                </div>

                <div style={{ marginLeft: '5px' }}>
                  <p className={Styles.studyEntryKey}>Other ID</p>
                  <input
                    placeholder={'Referring NPI'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.referringPhysicianOtherId}
                    onChange={(e) =>
                      this.setState({
                        referringPhysicianOtherId: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Row 13: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #20A892',
            }}
          >
            <div style={{ width: '66%', padding: '10px' }}>
              <p className={Styles.studyEntryKey}>19. Claim Narrative</p>
              <input
                placeholder={'Claim Narrative'}
                className={Styles.studyEntryInput}
                style={{ width: '300px' }}
                type="text"
                value={this.state.claimNarrative}
                onChange={(e) =>
                  this.setState({ claimNarrative: e.target.value })
                }
              />
            </div>
            <div style={{ width: '33%', padding: '10px' }}>
              {/* <p className={Styles.studyEntryKey}>
                Hospitalization Dates(From,To)
              </p> */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <p className={Styles.studyEntryKey}>
                    18. Hospitalization Date(From)
                  </p>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {'<'}
                        </button>
                        <select
                          // BUG: value needs a value
                          // value={this.getYear(date)}
                          onChange={({ target: { value } }) => {
                            changeYear(value)
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          // BUG: 0 needs to be the correct index
                          value={months[this.getMonth(date)]}
                          onChange={({ target: { value } }) => {
                            changeMonth(months.indexOf(value))
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {'>'}
                        </button>
                      </div>
                    )}
                    placeholder="From"
                    selected={this.state.hospitalizationDateFrom}
                    onChange={(dates) => {
                      console.log('dates ', dates)
                      this.setState({ hospitalizationDateFrom: dates })
                    }}
                  />
                </div>
                <div>
                  <p className={Styles.studyEntryKey}>
                    Hospitalization Date(To)
                  </p>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {'<'}
                        </button>
                        <select
                          // BUG: value needs a value
                          // value={this.getYear(date)}
                          onChange={({ target: { value } }) => {
                            changeYear(value)
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          // BUG: 0 needs to be the correct index
                          value={months[this.getMonth(date)]}
                          onChange={({ target: { value } }) => {
                            changeMonth(months.indexOf(value))
                            // this.setState({ patientDob: value })
                          }}
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {'>'}
                        </button>
                      </div>
                    )}
                    placeholder="From"
                    selected={this.state.hospitalizationDateTo}
                    onChange={(dates) => {
                      console.log('dates ', dates)
                      this.setState({ hospitalizationDateTo: dates })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Row 14: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>22. Resubmission Code</p>
              <Select
                placeholder="Resubmission Code"
                inputId="resubmissionCode"
                options={[
                  // { label: 'A-Automatic Corrected', value: 'A' },
                  { label: '1-Original Claim', value: '1' },
                  { label: '7-Corrected Claim', value: '7' },
                  { label: '8-Void Claim', value: '8' },
                ]}
                value={this.state.resubmissionCode}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ resubmissionCode: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>
                23. Prior Authorization Number
              </p>
              <input
                placeholder={'Prior Authorization Number'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.priorAuthorizationNumber}
                onChange={(e) =>
                  this.setState({ priorAuthorizationNumber: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Referral Number</p>
              <input
                placeholder={'Referral Number'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.referralNumber}
                onChange={(e) =>
                  this.setState({ referralNumber: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Clia Number</p>
              <input
                placeholder={'Clia Number'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.cliaNumber}
                onChange={(e) => this.setState({ cliaNumber: e.target.value })}
              />
            </div>
          </div>

          <div
            style={{
              width: '100%',
            }}
          >
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                margin: '10px 10px',
                borderTop: '2px solid #20A892',
              }}
            >
              21. Diagnosis or Nature of Illness or Injury
            </h5>
          </div>
          {/* Row 15: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>A</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisA}
                style={{
                  backgroundColor: this.state.diagnosisA
                    ? '#FFFFFF'
                    : REQUIRED_BK_COLOR,
                }}
                onChange={(e) => {
                  this.setState({ diagnosisA: e.target.value })
                  let diagnosisList = this.state.diagnosisList
                  const LETTER = 'A'
                  if (
                    e.target.value !== '' &&
                    !diagnosisList[0].diagnosisReference.includes(LETTER)
                  )
                    diagnosisList[0].diagnosisReference += LETTER
                  else if (e.target.value === '')
                    diagnosisList[0].diagnosisReference =
                      diagnosisList[0].diagnosisReference.replace(LETTER, '')
                  // if (
                  //   diagnosisList[0].diagnosisReference.indexOf(
                  //     e.target.value,
                  //   ) === -1 &&
                  //   e.target.value !== ''
                  // )
                  //   diagnosisList[0].diagnosisReference.push('A')
                  // if (e.target.value === '')
                  //   diagnosisList[0].diagnosisReference =
                  //     diagnosisList[0].diagnosisReference.filter(
                  //       (x) => x !== 'A',
                  //     )
                }}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>B</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisB}
                onChange={(e) => {
                  this.setState({ diagnosisB: e.target.value })
                  let diagnosisList = this.state.diagnosisList
                  const LETTER = 'B'
                  if (
                    e.target.value !== '' &&
                    !diagnosisList[0].diagnosisReference.includes(LETTER)
                  )
                    diagnosisList[0].diagnosisReference += LETTER
                  else if (e.target.value === '')
                    diagnosisList[0].diagnosisReference =
                      diagnosisList[0].diagnosisReference.replace(LETTER, '')
                  // if (
                  //   diagnosisList[0].diagnosisReference.indexOf(
                  //     e.target.value,
                  //   ) === -1 &&
                  //   e.target.value !== ''
                  // )
                  //   diagnosisList[0].diagnosisReference.push('B')
                  // if (e.target.value === '')
                  //   diagnosisList[0].diagnosisReference =
                  //     diagnosisList[0].diagnosisReference.filter(
                  //       (x) => x !== 'B',
                  //     )
                }}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>C</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisC}
                onChange={(e) => {
                  this.setState({ diagnosisC: e.target.value })
                  let diagnosisList = this.state.diagnosisList
                  const LETTER = 'C'
                  if (
                    e.target.value !== '' &&
                    !diagnosisList[0].diagnosisReference.includes(LETTER)
                  )
                    diagnosisList[0].diagnosisReference += LETTER
                  else if (e.target.value === '')
                    diagnosisList[0].diagnosisReference =
                      diagnosisList[0].diagnosisReference.replace(LETTER, '')
                }}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>D</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisD}
                onChange={(e) => {
                  this.setState({ diagnosisD: e.target.value })
                  let diagnosisList = this.state.diagnosisList
                  const LETTER = 'D'
                  if (
                    e.target.value !== '' &&
                    !diagnosisList[0].diagnosisReference.includes(LETTER)
                  )
                    diagnosisList[0].diagnosisReference += LETTER
                  else if (e.target.value === '')
                    diagnosisList[0].diagnosisReference =
                      diagnosisList[0].diagnosisReference.replace(LETTER, '')
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>E</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisE}
                onChange={(e) => {
                  this.setState({ diagnosisE: e.target.value })
                  let diagnosisList = this.state.diagnosisList
                  const LETTER = 'E'
                  if (
                    e.target.value !== '' &&
                    !diagnosisList[0].diagnosisReference.includes(LETTER)
                  )
                    diagnosisList[0].diagnosisReference += LETTER
                  else if (e.target.value === '')
                    diagnosisList[0].diagnosisReference =
                      diagnosisList[0].diagnosisReference.replace(LETTER, '')
                }}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>F</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisF}
                onChange={(e) => this.setState({ diagnosisF: e.target.value })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>G</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisG}
                onChange={(e) => this.setState({ diagnosisG: e.target.value })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>H</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisH}
                onChange={(e) => this.setState({ diagnosisH: e.target.value })}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>I</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisI}
                onChange={(e) => this.setState({ diagnosisI: e.target.value })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>J</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisJ}
                onChange={(e) => this.setState({ diagnosisJ: e.target.value })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>K</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisK}
                onChange={(e) => this.setState({ diagnosisK: e.target.value })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>L</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.diagnosisL}
                onChange={(e) => this.setState({ diagnosisL: e.target.value })}
              />
            </div>
          </div>

          {/* Row 16: 2 columns */}
          {this.state.diagnosisList.map((diagnosis, index) => {
            return (
              <>
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div style={{ flex: 1, padding: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* <div style={{ justifyContent: 'space-evenly' }}> */}
                      <div>
                        <p className={Styles.studyEntryKey}>
                          24A. Dates of Service From
                        </p>
                        <DatePicker
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                {'<'}
                              </button>
                              <select
                                // BUG: value needs a value
                                // value={this.getYear(date)}
                                onChange={({ target: { value } }) => {
                                  changeYear(value)
                                  // this.setState({ patientDob: value })
                                }}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                // BUG: 0 needs to be the correct index
                                value={months[this.getMonth(date)]}
                                onChange={({ target: { value } }) => {
                                  changeMonth(months.indexOf(value))
                                  // this.setState({ patientDob: value })
                                }}
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {'>'}
                              </button>
                            </div>
                          )}
                          placeholder="From"
                          selected={
                            this.state.diagnosisList[index].dateOfServiceStart
                          }
                          onChange={(dates) => {
                            console.log('dates ', dates)
                            let { diagnosisList } = this.state
                            diagnosisList[index].dateOfServiceStart = dates
                            this.setState({ diagnosisList })
                          }}
                        />
                      </div>
                      <div>
                        <p className={Styles.studyEntryKey}>To</p>
                        <DatePicker
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                {'<'}
                              </button>
                              <select
                                // BUG: value needs a value
                                // value={this.getYear(date)}
                                onChange={({ target: { value } }) => {
                                  changeYear(value)
                                  // this.setState({ patientDob: value })
                                }}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                // BUG: 0 needs to be the correct index
                                value={months[this.getMonth(date)]}
                                onChange={({ target: { value } }) => {
                                  changeMonth(months.indexOf(value))
                                  // this.setState({ patientDob: value })
                                }}
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {'>'}
                              </button>
                            </div>
                          )}
                          placeholder="From"
                          selected={
                            this.state.diagnosisList[index].dateOfServiceEnd
                          }
                          onChange={(dates) => {
                            console.log('dates ', dates)
                            let { diagnosisList } = this.state
                            diagnosisList[index].dateOfServiceEnd = dates
                            this.setState({ diagnosisList })
                          }}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div style={{ flex: 1, padding: '10px' }}>
                    <p className={Styles.studyEntryKey}>B. POS</p>
                    <input
                      placeholder={''}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={diagnosis.bPos}
                      onChange={(e) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].bPos = e.target.value
                        this.setState({ diagnosisList })
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: '90px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p className={Styles.studyEntryKey}>C. EMG</p>
                    <Select
                      placeholder=""
                      inputId="emg"
                      options={EMG_OPTIONS}
                      value={diagnosis.cEmg}
                      isMulti={false}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].cEmg = val
                        this.setState({ diagnosisList })
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                  <div style={{ flex: 1, padding: '10px' }}>
                    <p className={Styles.studyEntryKey}>D. Procedure</p>
                    <input
                      placeholder={''}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={diagnosis.procedure}
                      style={{
                        backgroundColor: diagnosis.procedure
                          ? '#ffffff'
                          : REQUIRED_BK_COLOR,
                      }}
                      onChange={(e) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].procedure = e.target.value
                        diagnosisList[index].amountChanges = Number(
                          PROCEDURE_COST_MAP[e.target.value] || 0,
                        ).toFixed(2)
                        this.setState({ diagnosisList })
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: '175px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p className={Styles.studyEntryKey}>Modifier</p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <input
                        placeholder={'M1'}
                        className={Styles.studyEntryInput}
                        type="text"
                        value={diagnosis.modifier1}
                        onChange={(e) => {
                          let { diagnosisList } = this.state
                          diagnosisList[index].modifier1 = e.target.value
                          this.setState({ diagnosisList })
                        }}
                      />
                      <input
                        placeholder={'M2'}
                        className={Styles.studyEntryInput}
                        type="text"
                        value={diagnosis.modifier2}
                        onChange={(e) => {
                          let { diagnosisList } = this.state
                          diagnosisList[index].modifier2 = e.target.value
                          this.setState({ diagnosisList })
                        }}
                      />
                      <input
                        placeholder={'M3'}
                        className={Styles.studyEntryInput}
                        type="text"
                        value={diagnosis.modifier3}
                        onChange={(e) => {
                          let { diagnosisList } = this.state
                          diagnosisList[index].modifier3 = e.target.value
                          this.setState({ diagnosisList })
                        }}
                      />
                      <input
                        placeholder={'M4'}
                        className={Styles.studyEntryInput}
                        type="text"
                        value={diagnosis.modifier4}
                        onChange={(e) => {
                          let { diagnosisList } = this.state
                          diagnosisList[index].modifier4 = e.target.value
                          this.setState({ diagnosisList })
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ flex: 1, padding: '10px' }}>
                    <p className={Styles.studyEntryKey}>E. Diag Ref</p>
                    <input
                      placeholder={''}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={
                        diagnosis.diagnosisReference
                        // ? diagnosis.diagnosisReference.join('')
                        // : ''
                      }
                      style={{
                        backgroundColor: diagnosis.diagnosisReference
                          ? '#ffffff'
                          : REQUIRED_BK_COLOR,
                      }}
                      onChange={(e) => {
                        // let { diagnosisList } = this.state
                        // diagnosisList[index].diagnosisReference = e.target.value
                        // this.setState({ diagnosisList })
                      }}
                    />
                  </div>
                  <div style={{ flex: 1, padding: '10px' }}>
                    <p className={Styles.studyEntryKey}>F. $ Charges</p>
                    <input
                      placeholder={''}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={diagnosis.amountChanges}
                      style={{
                        backgroundColor: diagnosis.amountChanges
                          ? '#ffffff'
                          : REQUIRED_BK_COLOR,
                      }}
                      onChange={(e) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].amountChanges = e.target.value
                        this.setState({ diagnosisList })
                      }}
                    />
                  </div>
                  <div style={{ flex: 1, padding: '10px' }}>
                    <p className={Styles.studyEntryKey}>G. Units</p>
                    <input
                      placeholder={''}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={diagnosis.diagnosisUnits}
                      style={{
                        backgroundColor: diagnosis.diagnosisUnits
                          ? '#ffffff'
                          : REQUIRED_BK_COLOR,
                      }}
                      onChange={(e) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].diagnosisUnits = e.target.value
                        this.setState({ diagnosisList })
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={() => {
                        let { diagnosisList } = this.state
                        diagnosisList.push({
                          dateOfServiceStart: null,
                          dateOfServiceEnd: null,
                          bPos: '11',
                          CEmg: null,
                          procedure: '',
                          modifier1: '',
                          modifier2: '',
                          modifier3: '',
                          modifier4: '',
                          diagnosisReference:
                            diagnosisList[0].diagnosisReference,
                          amountChanges: '',
                          diagnosisUnits: '1',
                        })
                        this.setState({ diagnosisList })
                      }}
                      icon={faPlusCircle}
                      style={{
                        color: Constants.primaryTheme,
                        fontSize: 18,
                        // right: 14,
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </div>
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div style={{ flex: 1, padding: '10px' }}>
                    <p className={Styles.studyEntryKey}>NDC Code</p>
                    <input
                      placeholder={''}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={diagnosis.ndcCode}
                      onChange={(e) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].ndcCode = e.target.value
                        this.setState({ diagnosisList })
                      }}
                    />
                  </div>
                  <div style={{ flex: 1, padding: '10px' }}>
                    <p className={Styles.studyEntryKey}>NDC Dosage</p>
                    <input
                      placeholder={''}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={diagnosis.ndcDosage}
                      onChange={(e) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].ndcDosage = e.target.value
                        this.setState({ diagnosisList })
                      }}
                    />
                  </div>
                  <div
                    style={{
                      // width: '90px',
                      flex: 1,
                      // display: 'flex',
                      // flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p className={Styles.studyEntryKey}>NDC Measure</p>
                    <Select
                      placeholder=""
                      inputId="ndc_measure"
                      options={NDC_MEASURE}
                      value={diagnosis.ndcMeasure}
                      isMulti={false}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        let { diagnosisList } = this.state
                        diagnosisList[index].ndcMeasure = val
                        this.setState({ diagnosisList })
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                </div>
              </>
            )
          })}

          {/* Row 17: 2 columns */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderTop: '2px solid #20A892',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>25. Tax ID</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.taxId}
                style={{
                  backgroundColor: this.state.taxId
                    ? '#ffffff'
                    : REQUIRED_BK_COLOR,
                }}
                onChange={(e) => this.setState({ taxId: e.target.value })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Type</p>
              <Select
                placeholder="Tax Type"
                inputId="taxType"
                options={TAX_ID_TYPE_OPTIONS}
                value={this.state.taxIdType}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({
                    taxIdType: val,
                  })
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    background: this.state.taxIdType
                      ? '#FFFFFF'
                      : REQUIRED_BK_COLOR,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>26. Patient Account #</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                style={{
                  backgroundColor: this.state.patientAccountNumber
                    ? '#ffffff'
                    : REQUIRED_BK_COLOR,
                }}
                type="text"
                value={this.state.patientAccountNumber}
                onChange={(e) =>
                  this.setState({ patientAccountNumber: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>27. Accept Assignment</p>
              <Select
                placeholder="Accept Assignment"
                inputId="acceptAssignment"
                options={ACCEPT_ASSIGNMENT_OPTIONS}
                value={this.state.acceptAssignment}
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({
                    acceptAssignment: val,
                  })
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    background: this.state.acceptAssignment
                      ? '#FFFFFF'
                      : REQUIRED_BK_COLOR,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>28. Total Charge</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                style={{
                  backgroundColor:
                    this.state.totalCharge ||
                    Number(this.state.totalCharge) === 0
                      ? '#FFFFFF'
                      : REQUIRED_BK_COLOR,
                }}
                type="number"
                value={this.state.totalCharge}
                onChange={(e) => {
                  // let charge = Number(e.target.value).toFixed(2)
                  let charge = e.target.value
                  this.setState({ totalCharge: charge })
                }}
              />
            </div>

            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>29. Amount Paid</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.totalAmountPaid}
                onChange={(e) =>
                  this.setState({ totalAmountPaid: e.target.value })
                }
              />
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>30. Balance</p>
              <input
                placeholder={''}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.balanceDue}
                onChange={(e) => this.setState({ balanceDue: e.target.value })}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '2px solid #20A892',
              // borderTop: '2px solid #20A892',
              // borderBottom: '2px solid #20A892',
            }}
          >
            <div
              style={{
                flex: 1,
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                borderRight: '2px solid #20A892',
              }}
            >
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>31. Rendering Provider</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <input
                    placeholder={'Last Name'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.renderingProviderLastName}
                    onChange={(e) =>
                      this.setState({
                        renderingProviderLastName: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      fontWeight: 'bold',
                      margin: '0 0px',
                    }}
                  >
                    ,
                  </div>
                  <input
                    placeholder={'First Name'}
                    className={Styles.studyEntryInput}
                    type="text"
                    style={{
                      marginLeft: '5px',
                    }}
                    value={this.state.renderingProviderFirstName}
                    onChange={(e) =>
                      this.setState({
                        renderingProviderFirstName: e.target.value,
                      })
                    }
                  />
                  <input
                    placeholder={'Middle Initial'}
                    className={Styles.studyEntryInput}
                    type="text"
                    style={{
                      marginLeft: '5px',
                      width: '75px',
                    }}
                    value={this.state.renderingProviderMiddleName}
                    onChange={(e) =>
                      this.setState({
                        renderingProviderMiddleName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Taxonomy</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.renderingProviderTaxonomy}
                  onChange={(e) =>
                    this.setState({ renderingProviderTaxonomy: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>NPI</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.renderingProviderNpi}
                  onChange={(e) =>
                    this.setState({ renderingProviderNpi: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Prov ID</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.renderingProviderId}
                  onChange={(e) =>
                    this.setState({ renderingProviderId: e.target.value })
                  }
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                borderRight: '2px solid #20A892',
              }}
            >
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>32. Facility</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.facilityName}
                  onChange={(e) =>
                    this.setState({ facilityName: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Address 1</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.facilityAddress}
                  onChange={(e) =>
                    this.setState({ facilityAddress: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Address 2</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.facilityAddress2}
                  onChange={(e) =>
                    this.setState({ facilityAddress2: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <p className={Styles.studyEntryKey}>Facility City</p>
                    <input
                      placeholder={'Facility City'}
                      className={Styles.studyEntryInput}
                      style={{
                        width: '100%',
                      }}
                      type="text"
                      value={this.state.facilityCity}
                      onChange={(e) =>
                        this.setState({ facilityCity: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <p className={Styles.studyEntryKey}>State</p>
                    <input
                      placeholder={'State'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.facilityState}
                      onChange={(e) =>
                        this.setState({ facilityState: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <p className={Styles.studyEntryKey}>Zip</p>
                    <input
                      placeholder={'Zip'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.facilityZip}
                      onChange={(e) =>
                        this.setState({ facilityZip: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <p className={Styles.studyEntryKey}>Country</p>
                    <input
                      placeholder={'Country'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.facilityCountry}
                      onChange={(e) =>
                        this.setState({ facilityCountry: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>NPI</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.facilityNpi}
                  onChange={(e) =>
                    this.setState({ facilityNpi: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Facility ID</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.facilityId}
                  onChange={(e) =>
                    this.setState({ facilityId: e.target.value })
                  }
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>33. Billing provider</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.billingProviderName}
                  style={{
                    backgroundColor: this.state.billingProviderName
                      ? '#ffffff'
                      : REQUIRED_BK_COLOR,
                  }}
                  onChange={(e) =>
                    this.setState({ billingProviderName: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Address 1</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.billingProviderAddress}
                  style={{
                    backgroundColor: this.state.billingProviderAddress
                      ? '#ffffff'
                      : REQUIRED_BK_COLOR,
                  }}
                  onChange={(e) =>
                    this.setState({ billingProviderAddress: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Address 2</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.billingProviderAddress2}
                  style={{
                    backgroundColor: this.state.billingProviderAddress2
                      ? '#ffffff'
                      : REQUIRED_BK_COLOR,
                  }}
                  onChange={(e) =>
                    this.setState({ billingProviderAddress2: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <p className={Styles.studyEntryKey}>City</p>
                    <input
                      placeholder={'City'}
                      className={Styles.studyEntryInput}
                      style={{
                        width: '100%',
                        backgroundColor: this.state.billingProviderCity
                          ? '#ffffff'
                          : REQUIRED_BK_COLOR,
                      }}
                      type="text"
                      value={this.state.billingProviderCity}
                      onChange={(e) =>
                        this.setState({ billingProviderCity: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <p className={Styles.studyEntryKey}>State</p>
                    <input
                      placeholder={'State'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.billingProviderState}
                      style={{
                        backgroundColor: this.state.billingProviderState
                          ? '#ffffff'
                          : REQUIRED_BK_COLOR,
                      }}
                      onChange={(e) =>
                        this.setState({ billingProviderState: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <p className={Styles.studyEntryKey}>Zip</p>
                    <input
                      placeholder={'Zip'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.billingProviderZip}
                      style={{
                        backgroundColor: this.state.billingProviderZip
                          ? '#ffffff'
                          : REQUIRED_BK_COLOR,
                      }}
                      onChange={(e) =>
                        this.setState({ billingProviderZip: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <p className={Styles.studyEntryKey}>Country</p>
                    <input
                      placeholder={'Country'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.billingProviderCountry}
                      onChange={(e) =>
                        this.setState({
                          billingProviderCountry: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Phone</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.billingProviderPhone}
                  style={{
                    backgroundColor: this.state.billingProviderPhone
                      ? '#ffffff'
                      : REQUIRED_BK_COLOR,
                  }}
                  onChange={(e) =>
                    this.setState({ billingProviderPhone: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Taxonomy</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.billingProviderTaxonomy}
                  onChange={(e) =>
                    this.setState({ billingProviderTaxonomy: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>NPI</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.billingProviderNpi}
                  style={{
                    backgroundColor:
                      this.state.billingProviderNpi ||
                      this.state.billingProviderId
                        ? '#ffffff'
                        : REQUIRED_BK_COLOR,
                  }}
                  onChange={(e) =>
                    this.setState({ billingProviderNpi: e.target.value })
                  }
                />
              </div>
              <div style={{ flex: 1, padding: '10px' }}>
                <p className={Styles.studyEntryKey}>Bill ID</p>
                <input
                  placeholder={''}
                  className={Styles.studyEntryInput}
                  type="text"
                  value={this.state.billingProviderId}
                  style={{
                    backgroundColor:
                      this.state.billingProviderNpi ||
                      this.state.billingProviderId
                        ? '#ffffff'
                        : REQUIRED_BK_COLOR,
                  }}
                  onChange={(e) =>
                    this.setState({ billingProviderId: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {/* TODO: Need to implement this */}
          {/* <Button
            disabled={this.state.claimTransmitted}
            isDisabled={this.state.claimTransmitted}
            onClick={() => {
              console.log('Upload Document!')
            }}
            className={GlobalStyles.button}
            style={{
              width: 150,
              position: 'relative',
              margin: 20,
              float: 'right',
            }}
            variant="primary"
          >
            Attach Document
          </Button> */}
          <Button
            disabled={this.state.claimTransmitted}
            isDisabled={this.state.claimTransmitted}
            onClick={() => {
              console.log('Clicked on save!')
              this.saveCMS1500Form()
            }}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'relative',
              margin: 20,
              float: 'right',
            }}
            variant="primary"
          >
            Save
          </Button>
          <Button
            disabled={this.state.claimTransmitted}
            isDisabled={this.state.claimTransmitted}
            onClick={() => {
              console.log('Clicked on save and submit!')
              this.submitCMS1500Form()
            }}
            className={GlobalStyles.button}
            style={{
              width: 200,
              position: 'relative',
              margin: 20,
              float: 'right',
            }}
            variant="primary"
          >
            {this.state.claimTransmitted ? 'Save & Resubmit' : 'Save & Submit'}
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsTab)
// export default CmsTab
