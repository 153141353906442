import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { healthPlanStatus, stateResidence } from '../../../metadata'

export const providerCredColumns = (
  providersList,
  patientList,
  openProviderRoster,
) => {
  return [
    {
      Header: 'Doctor Name',
      accessor: 'provider_name',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'EIN',
      accessor: 'provider_ein',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={stateResidence}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                        abbreviation: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.abbreviation)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Specialty',
      accessor: 'specialty',
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Days Since Submission',
      width: 250,
      sort: true,
      Cell: ({ row }) => {
        // let submitted_date =
        // BUG: Need to implement this
        return <p>0</p>
      },
    },
    {
      Header: 'Reference Number',
      accessor: 'phone_number',
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Application Status',
      accessor: (obj) => obj?.status,
      width: 250,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Statustype"
                placeholder="Select"
                options={healthPlanStatus}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'In Network Status',
      accessor: 'ins',
      Cell: ({ row }) => {
        let days = row?.original.date_of_submission
          ? moment().diff(moment(row?.original.date_of_submission), 'days')
          : 'Nil'
        return (
          <p
            style={{
              color: days <= 30 ? 'green' : days <= 74 ? 'orange' : 'red',
            }}
          >
            {days}
          </p>
        )
      },
      width: 250,
      sort: true,
    },
    {
      Header: 'Effective Date',
      accessor: 'rd',
      Cell: ({ row }) => {
        let toDisplay = row.original.notes_list?.length
          ? row.original.notes_list[0].note_text
          : // .map((each, index) => each.note_text + '\n')
            // .join(',')
            '-'
        return toDisplay
      },
      width: 350,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              console.log('view claim')
              openProviderRoster(row.original)
            }}
            className={Styles.link}
            style={{ textDecoration: 'underline' }}
          >
            Edit
          </p>
        )
      },
      width: 200,
    },
  ]
}
