import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../../config'
import moment from 'moment'

class LastChargeTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <div>Status:{this.props.status?.status}</div>
        <div>Error:{this.props.status?.error}</div>
        <div>
          Amount:
          {this.props.status?.charge_amount
            ? Number(this.props.state?.charge_amount) / 100
            : null}
        </div>
      </div>
    )
  }
}

export default LastChargeTooltip
