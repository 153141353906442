import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/PracticeInfo.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import * as Metadata from '../../metadata.js'
import { ToastContainer, toast } from 'react-toastify'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import moment from 'moment'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import {
  faTrash,
  faPlusCircle,
  faPaperclip,
  faCheckCircle,
  faCalendarPlus,
  faEdit,
  faSearch,
  faFilter,
} from '@fortawesome/free-solid-svg-icons'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Draggable from 'react-draggable'
import Select from 'react-select'
import cloneDeep from 'lodash/cloneDeep'
import TaskModal from '../Patients/Careplan/TaskModal'
import {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  addPatient,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
} from '../../redux/actions'
import ReactTooltip from 'react-tooltip'
import firebase from '../../services/firebase'
import Fuse from 'fuse.js'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import Table from '../../components/Table'
// import { claimsColumns } from './Columns/claimsColumns'
import claimsColumns from './Columns/claimsColumns'
import { invoicesColumns } from './Columns/invoicesColumns'
import { payoutsColumns } from './Columns/payoutsColumns'
import NotesModal from '../Appointments/NotesModal'
// import superBillTemplate from '../../helpers/PdfTemplates/SuperbillTemplate.txt'
import superBillTemplate from '../../helpers/PdfTemplates/SuperbillTemplate.new3.txt'
import VisitNotesPdf from '../../helpers/PdfTemplates/VisitNotesPdf.txt'
import patientInvoice from '../../helpers/PdfTemplates/patientInvoice.txt'
import NotesPdf from '../../helpers/PdfTemplates/NotesPdf.txt'
import Helpers from '../../helpers/Global'
import Axios from 'axios'
import Handlebars from 'handlebars'
import questionMetadata from '../../questionMetadata'
import PaginationTable from '../../components/PaginationTable'
import PatientInsurance from '../PatientWeb/PatientAccount/PatientInsurance'
import { debounce, map } from 'lodash'
import { CSVLink } from 'react-csv'
// import CmsTab from './CmsTab1.js'
import CmsTab from './CmsTab.js'
import { ErrorBoundary } from 'react-error-boundary'
import FeeSchedule from './FeeSchedule.js'
import { constrainPoint } from '@fullcalendar/core/internal'
import addresser from 'addresser'
import PaymentEditModal from './PaymentEditModal.js'
import PaymentChargeModal from './PaymentChargeModal.js'

const mtz = require('moment-timezone')

const axios = require('axios')

// const BILLING_STATUS_OPTIONS = [
//   /*
//     Not ready to Bill
//     Ready to Bill
//     Sent for Billing
//     Deductible Applies
//     Needs Investigation
//     Partial Payment
//     Fully Paid
//     Denied
//   */
//   // Using above create the options
//   {
//     label: 'Not ready to Bill',
//     value: 'not_ready_to_bill',
//   },
//   {
//     label: 'Ready to Bill',
//     value: 'ready_to_bill',
//   },
//   {
//     label: 'Sent for Billing',
//     value: 'sent_for_billing',
//   },
//   {
//     label: 'Deductible Applies',
//     value: 'deductible_applies',
//   },
//   {
//     label: 'Needs Investigation',
//     value: 'needs_investigation',
//   },
//   {
//     label: 'Partial Payment',
//     value: 'partial_payment',
//   },
//   {
//     label: 'Fully Paid',
//     value: 'fully_paid',
//   },
//   {
//     label: 'Appeal Submitted',
//     value: 'appeal_submitted',
//   },
//   {
//     label: 'Denied',
//     value: 'denied',
//   },
// ]

const DENIAL_REASONS = [
  { label: 'Patient Type Issue', value: 'patient_type_issue' },
  { label: 'CPT Code Issue', value: 'cpt_code_issue' },
  { label: 'ICD Code Issue', value: 'icd_code_issue' },
  { label: 'Timely filing', value: 'timely_filing' },
  { label: 'Place of Service Issue', value: 'place_of_service_issue' },
  { label: 'Modifier Issue', value: 'modifier_issue' },
  { label: 'Lack of Medical Necessity', value: 'lack_of_medical_necessity' },
  { label: 'Need authorization', value: 'need_authorization' },
  { label: 'Out of Network', value: 'out_of_network' },
  { label: 'Not Covered Under Plan/ Not Eligible', value: 'not_eligible' },
  { label: 'Has not met deductible', value: 'not_met_deductible' },
  {
    label: 'Charge Exceeds Maximum for Contracted Rate',
    value: 'charges_exceeds_max_contracted_rate',
  },
  { label: 'Wrong Payer ID', value: 'incorrect_payer_id' },
  { label: 'Invalid Insured Information', value: 'invalid_insurance' },
  { label: 'Invalid Subscriber ID', value: 'invalid_subscriber_id' },
  { label: 'Insurance Coverage Expired', value: 'insurance_expired' },
  { label: 'Entity/subscriber not found', value: 'subscriber_not_found' },
  { label: 'Duplicate Claim', value: 'duplicate_claim' },
  { label: 'Timely Filing Deadline', value: 'timely_filing_deadline' },
]

const PROVIDER_GOALS = [
  { text: 'sleep_goal', value: '' },
  { text: 'weight_goal', value: '' },
  { text: 'step_goal', value: '' },
  { text: 'meditation_goal', value: '' },
  { text: 'exercise_goal', value: '' },
  { text: 'weight_loss_goal', value: '' },
  { text: 'change_habits', value: '' },
]

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customEraModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    zIndex: 10,
    width: '80%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2147483647,
  },
}

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}
const insuranceAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    // width: '75%',
    width: '95%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

const insuranceEditModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    // width: '75%',
    width: '95%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2147483647,
  },
}

const filterModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    width: '750px',
    height: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2147483647,
  },
}

const editModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    width: '750px',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2147483647,
  },
}

const chargeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    width: '750px',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2147483647,
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    width: '750px',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2147483647,
  },
}

const taskModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483647,
  },
  content: {
    width: '1150px',
    height: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2147483647,
  },
}

const cms1500ModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10000,
  },
  content: {
    width: '95%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    // zIndex: 10000,
  },
}

const stripeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '55%',
    height: '35%',
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="icdclear" onClick={onClick}>
      ClearAll
    </button>
  )
}

class PaymentTab extends Component {
  constructor(props) {
    super(props)
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      selectedTab: 'Claims',
      providerMap: {},
      patientList: [],
      patientPcnList: [],
      patientMap: {},
      events: null,
      reload: false,
      eventsFromBackend: {},
      eventIdsFromBackend: [],
      ailaEventsSelected: true,
      claims: [],
      invoices: [],
      payouts: [],
      downloadData: [],
      dataToDownload: [],
      stripeCharge: {},
      pdfData: '',
      showModal: false,
      showEraModal: false,
      eraPdfViewData: '',
      showNotesModal: false,
      selectedPatient: null,
      providerConditions: [],
      providerAllergies: [],
      providerFoodSensitivities: [],
      providerImmunization: [],
      providerMedications: [],
      providerGoals: [],
      wellnessData: null,
      conditionsData: [],
      refund_amount: '',
      charge_amount: '',
      appChargeData: {},
      chargeLoader: false,
      stripeModalIsOpen: false,
      stripeChargeModalIsOpen: false,
      stripeChargeCopayIsOpen: false,
      refundData: {},
      // page: 1,
      // size: 50,
      appointmentType: {
        label: 'Completed Appointments',
        value: 'Completed Appointments',
      },
      selectedNotesFromVisit: {},
      selectedPatient: null,
      modalAddNotesIsOpen: false,
      insuranceModalIsOpen: false,
      insuranceEditModalIsOpen: false,
      editInsuranceData: null,
      selectedPatientId: null,
      selectedTaskModalPatientId: null,
      initiallyRenderedClaimsTable: false,
      selectedClaimsProvider: {},
      selectedClaimsPatient: {},
      selectedClaimsType: {},
      selectedClaimsDate: null,
      providerSearchOptions: [],
      patientSearchOptions: [],
      cms1500ModalOpen: false,
      cms1500EventID: null,
      cms1500OnlyNote: false,
      filterModalOpen: false,
      paymentFromDate: new Date(),
      paymentToDate: new Date(),
      filterByPatient: null,
      filterByPatientPcn: null,
      filterByProvider: null,
      showCareplanNotesModal: false,
      copayChargeAmount: '',
      taskModalIsOpen: false,
      editModalIsOpen: false,
      chargeModalIsOpen: false,
      editModalDataLoaded: false,
      editRowEventId: null,

      // Edit modal fields
      totalChargesBilled: '',
      allowedAmount: '',
      adjustmentAmount: '',
      paidAmount: '',
      checkNumber: '',
      claimNumber: '',
      denialReason: '',
      denialReasons: [],
      payerPhoneNumber: '',
      claimStatus: null,
      writeOff: false,
      writeOffAmount: '',
      writeOffReason: '',
      ifCareplanClaim: false,
      dueAmount: '',

      // Charge modal
      copayCharged: '',

      // Send email
      paymentFirstName: '',
      paymentLastName: '',
      paymentVisitDate: '',
      paymentAmount: '',
      paymentPatientId: '',
      paymentEventId: '',
    }
    this.fuse = null
    this.imageUploadRef = React.createRef()
    this.fileRef = React.createRef()
    this.csvlink = React.createRef()
    this.getAllProviders = this.getAllProviders.bind(this)
    this.getFilterData = this.getFilterData.bind(this)
    this.preparePatientList = this.preparePatientList.bind(this)
    this.getInsuranceClaims = this.getInsuranceClaims.bind(this)
    this.generateSuperBillPdf = this.generateSuperBillPdf.bind(this)
    this.showEraData = this.showEraData.bind(this)
    this.generateNotesPdf = this.generateNotesPdf.bind(this)
    this.getStripeChargeUrl = this.getStripeChargeUrl.bind(this)
    this.getStripeRefund = this.getStripeRefund.bind(this)
    this.openStripeRefundModal = this.openStripeRefundModal.bind(this)
    // this.openStripeChargeModal = this.openStripeChargeModal.bind(this)
    this.openStripeCopayChargeModal = this.openStripeCopayChargeModal.bind(this)
    this.hideNotesModal = this.hideNotesModal.bind(this)
    this.openNotesEditModal = this.openNotesEditModal.bind(this)
    this.careplanNotesModal = this.careplanNotesModal.bind(this)
    this.getProviderMedications = this.getProviderMedications.bind(this)
    this.getProviderAllergies = this.getProviderAllergies.bind(this)
    this.getPatientGoals = this.getPatientGoals.bind(this)
    this.getProviderConditions = this.getProviderConditions.bind(this)
    this.navigatetoPatientProfile = this.navigatetoPatientProfile.bind(this)
    this.saveAppointmentData = this.saveAppointmentData.bind(this)
    this.saveToCareplanData = this.saveToCareplanData.bind(this)
    this.onInsuranceEditClicked = this.onInsuranceEditClicked.bind(this)
    this.onTaskModalClicked = this.onTaskModalClicked.bind(this)
    this.onEditModalClicked = this.onEditModalClicked.bind(this)
    this.onChargeModalClicked = this.onChargeModalClicked.bind(this)
    this.onCMS1500Clicked = this.onCMS1500Clicked.bind(this)
    this.renderEditable = this.renderEditable.bind(this)
    this.renderCMS1500Modal = this.renderCMS1500Modal.bind(this)
    // this.handleInputChange = debounce(this.handleInputChange.bind(this), 1500)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.download = this.download.bind(this)
    this.onClickSearch = this.onClickSearch.bind(this)
    this.clearAllFilters = this.clearAllFilters.bind(this)
    this.generateNotesPdfNew = this.generateNotesPdfNew.bind(this)
    this.sendPaymentEmailModal = this.sendPaymentEmailModal.bind(this)
    this.openInsurnaceModal = this.openInsurnaceModal.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.setState({ loading: true })
    this.getSearchData()
    this.getAllProviders()
    this.getFilterData()
    this.getAppointmentInvoice()
    this.getInsuranceClaims()
  }

  async getFilterData() {
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}claims/filter/pcn/data`,
    }).then((response) => {
      if (response?.data?.success === true) {
        this.setState({ patientPcnList: response?.data?.data })
      } else {
        toast.error('Error loading PCN options for filter!')
      }
    })
  }

  async getSearchData() {
    const { token } = this.props
    // console.log(`${Config.BACKEND_URL}search/admin/payments/claims`)
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}search/admin/payments/claims`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        // For provider search
        let { providerSearchOptions, patientSearchOptions } = data
        this.setState({
          providerSearchOptions,
          patientSearchOptions,
        })
      })
      .catch((err) => {
        console.log('error when getting search claims data', err)
      })
  }

  async getAllProviders() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        let docObj = {}
        let providerList = []
        if (data) {
          data.forEach((x) => {
            docObj[x.firebase_id] = x
          })
          data.forEach((x) =>
            providerList.push({
              label: `${x.first_name} ${x.last_name}`,
              value: x.firebase_id,
            }),
          )
          this.setState(
            {
              providerMap: docObj,
              providerList,
            },
            () => this.preparePatientList(),
          )
        }
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  getConditionsData(patient) {
    let { token } = this.props
    let url = Config.BACKEND_URL + 'user/conditions'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient?.uid },
    })
      .then((response) => {
        console.log('got conditions data')
        self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })
  }

  async getProviderMedications(patient) {
    let { token } = this.props
    try {
      const getMeds = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/MedicationRequest?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getMeds()
      if (response?.data) {
        let meds = response.data
        this.setState({ providerMedications: meds })
      } else {
        this.setState({ providerMedications: [] })
      }
    } catch (err) {
      console.log('error when getting meds data', err)
      toast.error('Something went wrong when getting meds')
    }
  }

  async getProviderAllergies(patient) {
    let { token } = this.props
    try {
      const getAllergy = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/AllergyIntolerance?patient=' + patient?.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient?.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getAllergy()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let allergies = response?.data || []
        this.setState({ providerAllergies: allergies })
      } else {
        this.setState({ providerAllergies: [] })
      }
    } catch (err) {
      console.log('error when getting allergies data', err)
      toast.error('Something went wrong when getting allergies')
    }
  }

  saveAppointmentData(appointment, toSave) {
    let { token, adminId } = this.props
    // this.setState({ loading: true })
    let updatedFields = toSave
    if (toSave?.updateReviewed) {
      updatedFields = {
        reviewed: toSave?.reviewed,
        reviewed_by: { supervisor: adminId, date: new Date() },
      }
    }
    console.log('claim updating', {
      event_id: appointment.event_id,
      ...updatedFields,
    })
    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}appointment`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        event_id: appointment.event_id,
        ...updatedFields,
      },
    })
      .then((result) => {
        console.log('updated claim')
        toast.success('Successfully updated')
        if (toSave?.updateReviewed) {
          console.log('updating claims because of saveAppointmentData')
          this.getInsuranceClaims()
        }
      })
      .catch((err) => {
        this.setState({ loading: false })
        toast.error('Something went wrong, please try again')
        console.log('error when updating appointment', err)
      })
  }

  saveToCareplanData(careplan, toSave, careplanRow) {
    let { token, adminId } = this.props
    console.log('claim updating in careplan')
    // this.setState({ loading: true })
    let updatedFields = toSave
    if (toSave?.updateReviewed) {
      updatedFields = {
        reviewed: toSave?.reviewed,
        reviewed_by: { supervisor: adminId, date: new Date() },
      }
    }
    console.log('claim updating in careplan', {
      x_doctor_id: careplan.doctor_id,
      careplan_id: careplan?.careplan?.note_id, // note_id
      ...updatedFields,
    })
    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}provider/patients/notes`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
        x_doctor_id: careplan.doctor_id,
        careplan_id: careplan?.careplan?.note_id, // note_id
      },
      data: {
        ...updatedFields,
      },
    })
      .then((result) => {
        console.log('updated claim')
        toast.success('Successfully updated')
        if (toSave?.updateReviewed) {
          console.log('updating claims because of saveAppointmentData')
          this.getInsuranceClaims()
        }
      })
      .catch((err) => {
        this.setState({ loading: false })
        toast.error('Something went wrong, please try again')
        console.log('error when updating appointment', err)
      })
  }

  openInsurnaceModal(val, patientID) {
    console.log('->', val, patientID)

    if (val) {
      axios({
        method: 'get',
        url: `${Config.BACKEND_URL}/user/insurance`,
        params: {
          uid: patientID,
        },
      }).then((response) => {
        let status = response?.data?.status
        if (status) {
          let insurance = response?.data?.data
          this.props.toggleSidebar(val)
          this.setState({
            insuranceEditModalIsOpen: val,
            editInsuranceData: insurance,
            selectedPatientId: patientID,
          })
        }
      })
    } else {
      this.props.toggleSidebar(val)
      this.setState({
        insuranceEditModalIsOpen: val,
        editInsuranceData: null,
        selectedPatientId: null,
      })
    }
  }

  onInsuranceEditClicked(val, eventOrNoteId, isSaved, isNote = false) {
    console.log(val, eventOrNoteId, isSaved, isNote)
    this.props.toggleSidebar(val)
    if (val === false) {
      this.setState({ insuranceModalIsOpen: false })
      console.log('returning')
      return
    }
    this.setState(
      {
        cms1500EventID: eventOrNoteId,
        cms1500Saved: isSaved || false,
        cms1500OnlyNote: isNote || false,
        // selectedPatientId: patientID,
      },
      () => {
        this.setState({
          insuranceModalIsOpen: val,
          // cms1500EventID: insurance,
          // selectedPatientId: patientID,
        })
      },
    )
  }

  loadTaskModalData(eventId) {
    // new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}billing/task`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      params: {
        eventId: eventId,
      },
    })
      .then((result) => {
        console.log('result', result.data)
        this.setState({
          modalTaskData: result.data,
        })
        // resolve()
      })
      .catch((err) => {
        console.log('error when getting task data', err)
        toast.error('Something went wrong getting data for the task!')
        // reject()
      })
    // })
  }

  onTaskModalClicked(val, eventId, patientId, dos) {
    console.log('onTaskModalClicked', val, eventId, patientId)

    this.props.toggleSidebar(val)
    if (val === false) {
      this.setState({ taskModalIsOpen: false, modalTaskData: null })
      return
    }
    // function to load the data for the task modal if present
    this.loadTaskModalData(eventId)
    this.setState(
      {
        taskModalEventID: eventId,
        selectedTaskModalPatientId: patientId,
        selectedTaskModalDateOfService: dos,
      },
      () => {
        this.setState({
          taskModalIsOpen: val,
        })
      },
    )
  }

  // WIP:
  onEditModalClicked(val, rowData, ifCareplanClaim) {
    console.log('onEditModalClicked', val, rowData, ifCareplanClaim)
    this.props.toggleSidebar(val)
    if (val === false) {
      this.setState({ editModalIsOpen: false, editModalDataLoaded: false })
      return
    }
    let paid_amount = rowData?.paid_amount //
    let amount_collected = rowData?.invoice?.[0]?.amount_paid
    let era_billed_amount = rowData?.era_billed_amount
    if (amount_collected) {
      amount_collected = parseFloat(amount_collected / 100).toFixed(2)
    }
    let billed_amount = paid_amount || era_billed_amount || null
    let total_billed_amount =
      rowData?.billed_amount || //
      rowData?.claim_total_charge ||
      amount_collected ||
      null
    let status =
      Metadata.BILLING_STATUS_OPTIONS.find(
        (s) => s.value === rowData?.claim_status,
      ) || null

    let editRowEventId = rowData?.event_id || rowData?.note_id
    console.log('|', {
      editModalIsOpen: val,
      allowedAmount: rowData.allowed_amount,
      adjustmentAmount: rowData.adjustment_amount,
      paidAmount: billed_amount,
      totalChargesBilled: total_billed_amount,
      claimNumber: rowData?.claim_number,
      payerPhoneNumber: rowData?.payer_phone_number,
      denialReasons: rowData?.denial_reasons || [],
      checkNumber: rowData?.check_number,
      dueAmount: rowData?.due_amount,
      claimStatus: status,
      editRowEventId: editRowEventId,
      writeOff: rowData?.if_write_off,
      writeOffAmount: rowData?.write_off_amount,
      writeOffReason: rowData?.write_off_reason,
      ifCareplanClaim: ifCareplanClaim,
    })
    this.setState(
      {
        editModalIsOpen: val,
        allowedAmount: rowData.allowed_amount,
        adjustmentAmount: rowData.adjustment_amount,
        paidAmount: billed_amount,
        totalChargesBilled: total_billed_amount,
        claimNumber: rowData?.claim_number,
        payerPhoneNumber: rowData?.payer_phone_number,
        denialReasons: rowData?.denial_reasons || [],
        checkNumber: rowData?.check_number,
        dueAmount: rowData?.due_amount,
        claimStatus: status,
        editRowEventId: editRowEventId,
        writeOff: rowData?.if_write_off,
        writeOffAmount: rowData?.write_off_amount,
        writeOffReason: rowData?.write_off_reason,
        ifCareplanClaim: ifCareplanClaim,
      },
      () => {
        this.setState({ editModalDataLoaded: true })
      },
    )
  }

  onChargeModalClicked(
    val,
    rowData,
    ifCareplanClaim,
    customer_id,
    transfer,
    event_id,
    duration,
    doctor_id,
    allowed_amount,
    patient_due_amount,
    patient_id,
    start,
    careplan_id,
  ) {
    console.log('onChargeModalClicked', val, rowData, ifCareplanClaim)
    this.props.toggleSidebar(val)
    if (val === false) {
      this.setState({ editModalIsOpen: false, editModalDataLoaded: false })
      return
    }
    let paid_amount = rowData?.paid_amount //
    let amount_collected = rowData?.invoice?.[0]?.amount_paid
    let era_billed_amount = rowData?.era_billed_amount
    if (amount_collected) {
      amount_collected = parseFloat(amount_collected / 100).toFixed(2)
    }
    let billed_amount = paid_amount || era_billed_amount || null
    let total_billed_amount =
      rowData?.billed_amount || //
      rowData?.claim_total_charge ||
      amount_collected ||
      null
    let status =
      Metadata.BILLING_STATUS_OPTIONS.find(
        (s) => s.value === rowData?.claim_status,
      ) || null

    let copay_amount_collected = rowData?.total_copay

    let editRowEventId = rowData?.event_id || rowData?.note_id
    console.log('|', {
      chargeModalIsOpen: val,
      allowedAmount: rowData.allowed_amount,
      adjustmentAmount: rowData.adjustment_amount,
      paidAmount: billed_amount,
      totalChargesBilled: total_billed_amount,
      claimNumber: rowData?.claim_number,
      payerPhoneNumber: rowData?.payer_phone_number,
      denialReasons: rowData?.denial_reasons || [],
      checkNumber: rowData?.check_number,
      dueAmount: rowData?.due_amount,
      claimStatus: status,
      editRowEventId: editRowEventId,
      writeOff: rowData?.if_write_off,
      writeOffAmount: rowData?.write_off_amount,
      writeOffReason: rowData?.write_off_reason,
      ifCareplanClaim: ifCareplanClaim,
      copayCharged: copay_amount_collected,
    })
    this.setState(
      {
        chargeModalIsOpen: val,
        allowedAmount: rowData.allowed_amount,
        adjustmentAmount: rowData.adjustment_amount,
        paidAmount: billed_amount,
        totalChargesBilled: total_billed_amount,
        claimNumber: rowData?.claim_number,
        payerPhoneNumber: rowData?.payer_phone_number,
        denialReasons: rowData?.denial_reasons || [],
        checkNumber: rowData?.check_number,
        dueAmount: rowData?.due_amount,
        claimStatus: status,
        editRowEventId: editRowEventId,
        writeOff: rowData?.if_write_off,
        writeOffAmount: rowData?.write_off_amount,
        writeOffReason: rowData?.write_off_reason,
        ifCareplanClaim: ifCareplanClaim,
        copayCharged: copay_amount_collected,
        appChargeData: {
          customer_id,
          transfer,
          event_id,
          duration,
          doctor_id,
          allowed_amount,
          patient_due_amount,
          patient_id,
          start,
          careplan_id,
        },
      },
      () => {
        this.setState({ editModalDataLoaded: true })
      },
    )
  }

  onFilterModalClicked(val) {
    this.props.toggleSidebar(val)
    this.setState({
      filterModalOpen: val,
    })
  }

  onCMS1500Clicked(val) {
    console.log('WTF IS GOING ON HELP ME', val)
    // this.props.toggleSidebar(val)
    this.setState({
      cms1500ModalOpen: val,
    })
  }

  getPatientGoals(patient) {
    // this.setState({ loadingContent: true })
    let { token } = this.props
    let self = this
    let url = Config.BACKEND_URL + 'patients/goals'
    axios({
      method: 'get',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient.uid },
      url: url,
    })
      .then(({ data }) => {
        // console.log('getting patient goals', data)
        self.setState({ patientGoals: data })
        let tempData = []
        if (!patient || !patient.goals || patient.goals.length === 0) {
          if (Object.keys(data).length === 0) {
            tempData = PROVIDER_GOALS.reverse()
          } else {
            Object.keys(data).forEach((element) => {
              if (element === 'free_form_goals') {
                return
              }
              tempData.push({ text: element, value: data[element] })
            })
          }
        } else {
          let data =
            patient?.goals?.length &&
            patient?.goals.map((x) => ({
              text: x.text,
              value: x.value,
              created_at: x.created_at,
            }))
          data.forEach((element) => {
            tempData.push(element)
          })
        }
        this.setState({
          providerGoals: tempData,
          // loadingContent: false
        })
      })
      .catch((err) => {
        console.log('error when getting patient goals data', err)
      })
  }

  async getProviderConditions(patient) {
    let { token } = this.props
    try {
      const getCondition = async () => {
        let url = Config.BACKEND_URL + 'fhir/Condition?patient=' + patient?.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient?.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getCondition()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let conditions = response.data || []
        this.setState({ providerConditions: conditions })
        // console.log(conditions)
      } else {
        this.setState({ providerConditions: [] })
      }
    } catch (err) {
      console.log('error when getting particle conditions data', err)
      toast.error('Something went wrong when getting external conditions')
    }
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const patientMap = {}
    const list = []
    const pcnList = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        patientMap[patient.uid] = patient
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
        pcnList.push({
          label: patient.pcn,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientMap, patientList: list })
  }

  hideNotesModal() {
    this.setState({ modalAddNotesIsOpen: false })
  }

  openNotesEditModal(event_id, appData) {
    let { token } = this.props
    // Need to get careplan data from event id
    // let careplanNote = careplan
    console.log('Getting additional info from the backend for the careplan')
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}provider/patients/notes`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
        // x_doctor_id: doctor_id,
      },
      params: {
        event_id: event_id,
      },
    })
      .then((result) => {
        console.log('result', result.data)
        let careplanNote = result.data || {}
        careplanNote.doctor = careplanNote.doctor || appData?.doctor || {}
        this.setState({
          modalAddNotesIsOpen: true,
          selectedNotesFromVisit: careplanNote,
          selectedPatient: appData?.patientData || {}, // Looks like this is always empty?
        })
      })
      .catch((err) => {
        console.log('error when getting careplan data', err)
        toast.error('Something went wrong')
      })
  }

  getPatientData(patientId) {
    console.log('Inside it')
    return axios({
      method: 'get',
      url: `${Config.BACKEND_URL}user`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      params: {
        uid: patientId,
      },
    }).then((result) => {
      console.log('Got patient data', result.data)
      return result.data.data
    })
  }

  getPatientInsuranceData(patientId) {
    return axios({
      method: 'get',
      url: `${Config.BACKEND_URL}user/insurance`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      params: {
        uid: patientId,
      },
    }).then((result) => {
      console.log('Got patient insurance data', result.data)
      return result.data.data
    })
  }

  getProviderDetails(providerId) {
    return axios({
      method: 'get',
      url: `${Config.BACKEND_URL}providers`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        'Content-Type': 'application/json',
        x_firebase_id: providerId,
      },
    }).then((result) => {
      console.log('Got provider data', result.data)
      return result.data
    })
  }

  async generateNotesPdfNew(selectedNote) {
    let { token, conditions, medications } = this.props
    let {
      providerMedications,
      providerAllergies,
      wellnessData,
      providerGoals,
    } = this.state
    console.log('cond::: ', selectedNote)
    // let patient = { ...this.props?.patient, ...this.props?.patient } || {}
    let patient = await this.getPatientData(selectedNote?.patient_id)

    // Get Timezone for the patient
    // Get the state
    let patientTimeZone = undefined
    let patientState = undefined
    let parsedPatientAddress = null
    let parsedPatientAddress2 = null
    try {
      parsedPatientAddress = addresser.parseAddress(patient.street_address)
    } catch (err) {
      // console.log('ERROR parsing address details: ', err)
    }
    try {
      parsedPatientAddress2 = addresser.parseAddress(patient.street_address2)
    } catch (err) {
      // console.log('ERROR parsing address2 details: ', err)
    }
    patientState =
      parsedPatientAddress?.stateAbbreviation ||
      parsedPatientAddress2?.stateAbbreviation ||
      ''
    if (patientState) {
      patientTimeZone = Metadata.STATE_TO_TIMEZONE_MAPPING[patientState]
    } else {
      patientTimeZone = Metadata.STATE_TO_TIMEZONE_MAPPING['CA']
    }
    // console.log("Timezone", patientTimeZone, obj. patientData)
    if (!patientTimeZone) {
      patientTimeZone = Metadata.STATE_TO_TIMEZONE_MAPPING['CA']
    }

    console.log('Got patient data', patient)
    let doctor = await this.getProviderDetails(selectedNote?.doctor_id)
    let data = {}
    let noteData = {}
    let icd_10_data = ''
    let cpt_data = ''
    let amended_cpt_data = ''
    let conditions_data = ''
    let medication_data = ''
    let allergies_data = ''
    let family_history_data = ''
    let goals_list = ''
    let referrals_requested = ''
    let time_spent = ''

    // INSURANCE
    let patientInsuranceData = await this.getPatientInsuranceData(
      selectedNote?.patient_id,
    )
    if (patientInsuranceData?.length > 1)
      patientInsuranceData = patientInsuranceData.find(
        (insurance) => insurance?.preference === 1,
      )
    else if (patientInsuranceData?.length == 1)
      patientInsuranceData = patientInsuranceData[0]
    console.log('patientInsuranceData::: ', patientInsuranceData)

    // CONDITIONS
    let conditionsData = conditions || []
    // filter out user entered data
    conditionsData = conditionsData.filter(
      (userCondn) =>
        !this.state.providerConditions?.some(
          (prodCondn) =>
            prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
        ),
    )
    let newConditions = conditions?.concat(this.state.providerConditions) || []

    newConditions.forEach((condn, index) => {
      conditions_data = conditions_data.concat(
        index + 1,
        '. ',
        condn.symptom
          ? condn.symptom
          : condn?.code?.coding?.[0]?.display?.replace(/"/g, ''),
        condn?.code?.coding?.[0]?.display && !condn?.userEntered
          ? ' (Doctor Reported) '
          : '',

        index !== newConditions?.length && '\n',
      )
    })

    // Workers comp data
    // TODO: Edit this to be in the outmost part of the collection, not nested
    let ifWorkersCompData = patient?.insurance_type?.workers_comp || false
    let patientWorkersCompData = patient?.workers_comp_data || {}
    let wc_date_of_injury = patientWorkersCompData?.date_of_injury || 'N/A'
    let wc_claim = patientWorkersCompData?.claim_number || 'N/A'
    let wc_carrier = patientWorkersCompData?.wc_carrier || 'N/A'
    let wc_employer = patientWorkersCompData?.employer || 'N/A'
    let wc_adjuster = patientWorkersCompData?.adjuster || 'N/A'
    let wc_ur = patientWorkersCompData?.ur || 'N/A' // BUG: This doesn't exist
    let wc_attorney = patientWorkersCompData?.attorney || 'N/A'
    let wc_ncm = patientWorkersCompData?.ncm || 'N/A'
    // let wc_phone = workersCompData?.phone || 'N/A'
    console.log('wc data::: ', patientWorkersCompData)
    let pdfWCData = {
      wc_date_of_injury,
      wc_claim,
      wc_carrier,
      wc_employer,
      wc_adjuster,
      wc_ur,
      wc_attorney,
      wc_ncm,
    }

    console.log('noteId:::', selectedNote?.id)

    //ALLERGIES
    let allergies = patient && patient.allergies ? patient?.allergies : []
    let newAllergies = providerAllergies?.concat(allergies)
    newAllergies.forEach((allergy, index) => {
      allergies_data = allergies_data.concat(
        index + 1,
        '. ',
        allergy?.code?.coding?.[0]?.display ||
          (allergy?.type &&
            (allergy?.name?.length ? allergy?.name?.join() : allergy?.type)) ||
          allergy,
        index !== newAllergies?.length && '\n',
      )
    })

    //FAMILY HISTORY
    let familyScreening =
      patient && patient?.screening_family
        ? patient?.screening_family?.relations
        : []

    familyScreening.forEach((each, index) => {
      let conditions = []
      if (each.conditions)
        conditions = each.conditions.map(
          (x, index) =>
            `${x} ${index === each.conditions.length - 1 ? '' : ', '}`,
        )
      family_history_data = family_history_data.concat(
        index + 1,
        '. ',
        each.relationship,
        ' : ',
        conditions,
        index !== familyScreening?.length && '\n',
      )
    })

    //MEDICATION
    let array1 = (medications && medications['active_medications']) || []
    let array2 =
      (providerMedications &&
        providerMedications['active_medications_provider']) ||
      []

    let array3 = (medications && medications['inactive_medications']) || []
    let array4 =
      (providerMedications &&
        providerMedications['inactive_medications_provider']) ||
      []

    let finalArray = array4.concat(array3.concat(array2.concat(array1)))
    // medication_data = finalArray

    finalArray.forEach((x, index) => {
      console.log(
        ':-:',
        moment(x.start),
        moment(selectedNote.created_at),
        typeof x.start,
        typeof selectedNote.created_at,
        x.start < selectedNote.created_at,
      )
      if (moment(x.start) > moment(selectedNote.created_at)) return
      medication_data = medication_data.concat(
        index + 1,
        '. ',
        x.dose?.quantity
          ? // ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit}) : `
            `${x.name} (qty:${x.dose?.quantity}) : `
          : `${x.name} : `,

        x.frequency?.quantity
          ? `${x.frequency.quantity} / ${x.frequency.recurring} : `
          : '- : ',
        moment(x.start).format('MMM Do YYYY') + ' : ',
        x.end ? 'Inactive' : 'Active',
        index !== finalArray?.length && '\n',
      )
    })

    //NOTES DATA TO BE SHOWN IN THE PDF
    noteData = {
      selected_note: 'visible',
      chief_concern: selectedNote?.chief_concern,
      present_illness: selectedNote?.present_illness,
      physical_exam: selectedNote?.physical_exam,
    }

    let tempArray1 = selectedNote?.careplan || []
    tempArray1.forEach((careplan, index) => {
      icd_10_data = icd_10_data.concat(
        index + 1,
        '. ',
        careplan?.icd_10?.icd10_code,
        ' : ',
        careplan?.icd_10?.title,
        ' : ',
        careplan?.assessment,
        ' : ',
        careplan?.note,
        index !== tempArray1.length && '\n',
        // '\n',
      )
    })
    noteData.icd_10 = icd_10_data

    let tempArray2 = selectedNote?.cpt || []
    tempArray2.forEach(function (cpt, index) {
      cpt_data = cpt_data.concat(
        index + 1,
        '. ',
        cpt?.cpt_code,
        ' : ',
        cpt?.title,
        index !== tempArray2.length && '\n',
        // '\n',
      )
    })

    let tempAmendCptArray = selectedNote?.amended_cpt || []
    tempAmendCptArray.forEach(function (cpt, index) {
      amended_cpt_data = amended_cpt_data.concat(
        index + 1,
        '. ',
        cpt?.cpt_code,
        ' : ',
        cpt?.title,
        index !== tempAmendCptArray.length && '\n',
        // '\n',
      )
    })

    let socialHistory =
      questionMetadata['screenings/wellness']['lifestyle_habits.cigarette'] +
      `: ${wellnessData?.lifestyle_habits?.cigarette || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['lifestyle_habits.alcohol'] +
      `: ${wellnessData?.lifestyle_habits?.alcohol || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['lifestyle_habits.drugs'] +
      `: ${wellnessData?.lifestyle_habits?.drugs || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['relationships.marital_status'] +
      `: ${wellnessData?.relationships?.marital_status || 'N/A'}\n`

    noteData.cpt_codes = cpt_data
    // if(selectedNote?.amended_cpt)
    noteData.amended_cpt_codes = amended_cpt_data
    noteData.authorization_number = selectedNote?.authorization_number
    noteData.diagnostic_review = selectedNote?.diagnostic_review?.text
      ? selectedNote?.diagnostic_review?.text
      : selectedNote?.diagnostic_review

    let bmiValues = {}
    // let bmiValues = Helpers.calculateBMIValues(
    //   patient.height,
    //   patient.weight_update || patient.weight,
    //   patient.gender,
    //   moment().diff(moment(patient.dob, 'DD/MM/YYYY'), 'years'),
    // )

    providerGoals = []
    providerGoals.forEach(function (entry, index) {
      goals_list = goals_list.concat(
        index + 1,
        '. ',
        entry.text === 'sleep_goal'
          ? 'Sleep'
          : entry.text === 'weight_goal'
          ? 'Weight'
          : entry.text === 'step_goal'
          ? 'Steps'
          : entry.text === 'meditation_goal'
          ? 'Meditation'
          : entry.text === 'exercise_goal'
          ? 'Exercise'
          : entry.text === 'weight_loss_goal'
          ? 'Weight Loss'
          : entry.text === 'change_habits'
          ? 'Habits'
          : entry?.text || 'N/A',
        ' : ',
        entry.value || 'N/A',
        index !== providerGoals.length && '\n',
        // '\n',
      )
    })

    let follow_up = selectedNote?.followupData?.follow_up_value
      ? `Requested follow up in ${selectedNote?.followupData?.follow_up_value} ${selectedNote?.followupData?.follow_up_unit?.label}`
      : 'N/A'

    referrals_requested =
      (selectedNote?.followupData?.refer_specialty_arr?.join(', ') ||
        selectedNote?.followupData?.refer_specialty?.label ||
        'N/A') +
      '\n' +
      (selectedNote?.followupData?.referral_suggested || '')

    time_spent = `
    Video Visit Start Time: ${moment
      .unix(selectedNote.appointment_date / 1000)
      .format('lll')} ${new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)} \n
    Video Visit End Time: ${moment
      .unix(selectedNote.appointment_end_date / 1000)
      .format('lll')} ${new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)} \n
    Video Consult Minutes: ${moment
      .unix(selectedNote.appointment_end_date / 1000)
      .diff(
        moment.unix(selectedNote.appointment_date / 1000),
        'minute',
      )} minutes \n
    Pre-Visit Review of Records: ${
      selectedNote?.time_spent?.pre_review_time || 0
    } minutes \n
    After Visit Documentation: ${
      selectedNote?.time_spent?.after_visit || 0
    } minutes \n
    Provider Collaboration: ${
      selectedNote?.time_spent?.provider_colab || 0
    } minutes \n
    Total Time Spent: ${
      Number(
        moment
          .unix(selectedNote.appointment_end_date / 1000)
          .diff(moment.unix(selectedNote.appointment_date / 1000), 'minute') ||
          0,
      ) +
      Number(selectedNote.time_spent?.pre_review_time || 0) +
      Number(selectedNote.time_spent?.after_visit || 0) +
      Number(selectedNote.time_spent?.provider_colab || 0)
    } minutes\n
    Interpreter Present at Visit: N/A\n
    Method of visit: ${selectedNote.time_spent?.method_of_visit || 'N/A'}
    `
    let provider_credentials =
      doctor?.degree === 'Medical Doctor'
        ? 'MD'
        : doctor?.degree === 'Doctor of Osteopathic Medicine'
        ? 'DO'
        : ''
    let careplan_note = ''
    let assessment_note = ''
    if (selectedNote?.careplan?.length) {
      let tempArray = selectedNote?.careplan || []
      tempArray.forEach((icd, index) => {
        if (icd?.assessment)
          assessment_note = assessment_note.concat(
            icd?.assessment,
            index !== tempArray?.length && '\n',
          )
        if (icd?.note)
          careplan_note = careplan_note.concat(
            icd?.note,
            index !== tempArray?.length && '\n',
          )
      })
    }
    if (selectedNote?.assessment_note)
      assessment_note = selectedNote?.assessment_note
    if (selectedNote?.careplan_note) careplan_note = selectedNote?.careplan_note
    console.log('npi', doctor)
    console.log('|->', selectedNote?.appointment_date)
    let dos = undefined

    if (selectedNote?.appointment_date) {
      dos = mtz
        .tz(selectedNote.appointment_date, 'GMT')
        .tz(patientTimeZone)
        .format('MM/DD/YYYY z')
    } else {
      dos = mtz
        .tz(selectedNote.created_at, patientTimeZone)
        .format('MM/DD/YYYY z')
    }
    console.log("*->The final date of service is:", dos)
    // if (selectedNote?.appointment_date) {
    //   dos = `${moment
    //     .unix(selectedNote.appointment_date / 1000)
    //     .format('MM/DD/YYYY')}`
    // } else {
    //   let dosArr = String(selectedNote.created_at).split('T')[0].split('-')
    //   dos = dosArr[1] + '/' + dosArr[2] + '/' + dosArr[0]
    // }
    // let dos = selectedNote?.appointment_date
    //   ? `${moment
    //       .unix(selectedNote.appointment_date / 1000)
    //       .format('MM/DD/YYYY')}`
    //   : String(selectedNote.created_at)
    console.log(
      'X->',
      selectedNote?.created_at
        ? moment(selectedNote.created_at).format('DD/MM/YYYY')
        : null,
    )
    data = {
      //PATIENT INFORMATION
      // date_of_service: `${moment
      //   .unix(selectedNote.appointment_date / 1000)
      //   .format('MM/DD/YYYY')}`,
      date_of_service: dos,
      patient_name:
        (patient?.firstName || patient?.first_name) +
        ' ' +
        (patient?.lastName || patient?.last_name),
      patient_dob: moment(patient?.dob, 'DD/MM/YYYY').format('MM/DD/YYYY'),
      patient_gender: patient?.gender,
      patient_address: patient?.streetAddress || patient.street_address,
      patient_phone: patient?.phoneNumber || patient?.phone_number || 'N/A',
      patient_email: patient?.email || 'N/A',
      insurance_provider:
        patientInsuranceData?.insurance_provider ||
        patientInsuranceData?.insurance_company?.insurance_name ||
        'N/A',
      insurance_plan: patientInsuranceData?.plan_type || 'N/A',
      insurance_member: patientInsuranceData?.member_name || 'N/A',
      insurance_subscriber: patientInsuranceData?.subscriber_id || 'N/A',
      insurance_group: patientInsuranceData?.group_number || 'N/A',
      height: patient?.height
        ? `${Math.floor(patient?.height / 12)} ft ${patient?.height % 12} in`
        : 'N/A',
      weight: (patient?.weight_update || patient?.weight || 'N/A') + ' lbs',
      bmi: bmiValues?.bmi || 'N/A',
      social_history: socialHistory,
      diagnostic_review: selectedNote?.diagnostic_review?.no_review
        ? 'There are no studies to review at today’s visit'
        : selectedNote?.diagnostic_review?.text || 'N/A',
      goals_list,
      follow_up,
      auth_request: selectedNote?.followupData?.requested_auth || 'N/A',
      referrals_requested,
      time_spent,
      assessment_note,
      careplan_note,
      provider_chief_concern: selectedNote?.provider_chief_concern || '',
      provider_illness: selectedNote?.provider_illness || '',
      //PROVIDER INFORMATION
      provider_signature: doctor?.sign_image,
      provider_name: doctor?.first_name + ' ' + doctor?.last_name,
      provider_speciality: doctor?.speciality,
      provider_npi: doctor?.npi?.number,
      provider_credentials,
      medication_data: medication_data,
      allergies_data: allergies_data,
      conditions_data: conditions_data,
      family_history_data: family_history_data,
      careplan: [{}], // Hack to just make it easier to deploy faster
      // careplan: [{}] || selectedNote.careplan,
      icd10s: selectedNote?.careplan?.map((x) => x?.icd_10?.icd10_code) || '-',
      other_items_addressed: 'N/A',
      method_of_visit:
        selectedNote?.time_spent?.method_of_visit || 'Telehealth - Video Visit',
      // PCP Info
      pcp_doc_credentials:
        (patient?.pcp?.first_name || 'N/A') +
        ' ' +
        (patient?.pcp?.last_name || ' '),
      pcp_phone: patient?.pcp?.phone_number || 'N/A',
      pcp_fax: patient?.pcp?.fax || 'N/A',
      // BUG: This needs to read data form correct place
      phq9_data: 'N/A',
      gad_data: 'N/A',
      waist_circumference: 'N/A',
      bp: 'N/A',
      hr: 'N/A',
      o2_saturation: 'N/A',
      rpm_devices_ordered: 'N/A',
      address_confirmed: 'N/A',
      rpm_eligible: 'N/A',
      ccm_eligible: 'N/A',
      complex_eligible: 'N/A',
      date_time: `${moment(selectedNote.updated_at).format(
        'MM/DD/YYYY HH:MM:SS',
      )}`,
    }
    console.log('TESTING: ', data)
    console.log(
      'The appointment event id is:',
      selectedNote?.appointment_event_id || null,
    )
    console.log('Token', token)
    Axios({
      method: 'get',
      url: `${Config.BACKEND_URL}careplan/orders`,
      // url: `${Config.BACKEND_URL}health`,
      headers: {
        Authorization: 'JWT ' + token,
        'Content-Type': 'application/json',
      },
      params: {
        appointment_event_id: selectedNote?.appointment_event_id || null,
      },
    })
      .then((careplanAdditionalData) => {
        console.log('careplanAdditionalData', careplanAdditionalData)
        let allOrdersData = careplanAdditionalData?.data
        let ordersData = {
          lab_orders:
            allOrdersData?.lab_orders?.length > 0
              ? allOrdersData?.lab_orders
                  ?.map((x) => {
                    // TODO: I think there can be only 1 test per order as of now
                    return (
                      x?.tests[0]?.name +
                      ' on ' +
                      moment(x.created_at).format('MMMM Do YYYY')
                    )
                  })
                  .join('\n')
              : 'N/A',
          rx_orders:
            allOrdersData?.rx_orders?.length > 0
              ? allOrdersData?.rx_orders
                  ?.map((x) => {
                    return (
                      x?.drug?.name +
                      ' quantity: ' +
                      x?.quantity +
                      ' on ' +
                      moment(x.created_at).format('MMMM Do YYYY')
                    )
                  })
                  .join('\n')
              : 'N/A',
          infusion_orders:
            allOrdersData?.infusion_orders?.length > 0
              ? allOrdersData?.infusion_orders
              : 'N/A',
          imaging_orders:
            allOrdersData?.imaging_orders?.length > 0
              ? allOrdersData?.imaging_orders
                  ?.map((x) => {
                    return (
                      x?.order_type +
                      ' on ' +
                      moment(x.created_at).format('MMMM Do YYYY')
                    )
                  })
                  .join('\n')
              : 'N/A',
          dme_orders:
            allOrdersData?.dme_orders?.length > 0
              ? allOrdersData?.dme_orders
              : 'N/A',
        }
        // Confirm this
        // VisitNotes is the careplan note for a normal visit,
        // Notes is the careplan note for a async visit I think
        // Axios(selectedNote.appointment_date ? VisitNotesPdf : NotesPdf).then(
        Axios(VisitNotesPdf).then((res) => {
          try {
            let templateHtml = Handlebars.compile(res.data.toString())
            let bodyHtml = templateHtml({
              ...data,
              ...noteData,
              conditions_data,
              ...pdfWCData,
              ...ordersData,
            })
            this.setState({ pdfData: bodyHtml })

            let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
            let postData = {
              htmlContent: bodyHtml,
            }

            //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
            // axios({
            //   method: 'post',
            //   headers: {
            //     Authorization: 'JWT ' + token,
            //     x_firebase_id: patient.uid,
            //   },
            //   url: url,
            //   data: postData,
            // })
            //   .then(({ data }) => {
            //     console.log('got data back from the pdf generator')
            //     // Insert a link that allows the user to download the PDF file
            //     // var link = document.createElement('a')
            //     // link.innerHTML = 'Download PDF file'
            //     // link.download = `Patient Note ${patient?.first_name.charAt(
            //     //   0,
            //     // )}${patient?.last_name.charAt(0)} ${moment(
            //     //   selectedNote?.careplan?.created_at,
            //     // ).format('MM/DD/YYYY')}.pdf`
            //     // link.href =
            //     //   'data:application/octet-stream;base64,' + data.result
            //     // // document.body.appendChild(link);
            //     // link.click()
            //     // console.log('downloaded')
            //     // toast.success('Note downloaded')
            //   })
            //   .catch((err) => {
            //     console.log('error 111', err)
            //     toast.error('Could not download Notes, please try again')
            //   })
          } catch (err) {
            console.log('error generating pdf', err)
          }
        })
      })
      .catch((err) => {
        console.log('error here:', err)
      })
  }

  careplanNotesModal(event_id, appData, fName, lName, dos) {
    let { token } = this.props
    // Need to get careplan data from event id
    // let careplanNote = careplan
    let fullName = fName + ' ' + lName
    let dateOfService = dos ? moment(dos).format('MM/DD/YYYY') : 'X'
    console.log("*->THE DOS IS:")
    this.setState({ fullName, dateOfService })
    console.log(
      'Getting additional info from the backend for the careplan',
      event_id,
    )
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}provider/patients/notes`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
        // x_doctor_id: doctor_id,
      },
      params: {
        // event_id: event_id,
        note_id: event_id,
      },
    })
      .then((result) => {
        console.log('*->result1', result.data)
        let careplanNote = result.data || {}
        careplanNote.doctor = careplanNote.doctor || appData?.doctor || {}
        this.generateNotesPdfNew(careplanNote)
        this.setState({
          // modalAddNotesIsOpen: true,
          showCareplanNotesModal: true,
          selectedNotesFromVisit: careplanNote,
          // selectedPatient: appData?.patientData || {}, // Looks like this is always empty?
        })
      })
      .catch((err) => {
        console.log('Error when getting careplan data', err)
        toast.error('Something went wrong')
      })
  }

  async getStripeChargeUrl(charge_id) {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}stripe/charge`

    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          charge_id: charge_id,
        },
      })

      const chargeData = response.data

      console.log('stripeCharge recieved', chargeData)
      if (chargeData.receipt_url) window.open(chargeData.receipt_url)
      this.setState({ stripeCharge: chargeData || [] })
    } catch (err) {
      toast.error('Could not fetch stripeCharge, please try again')
      console.log('error when getting stripeCharge from backend', err)
    }
  }

  openStripeRefundModal(charge_id, transfer, amount_paid) {
    console.log('CLICKED on open modal:', charge_id, transfer, amount_paid)
    this.setState({
      stripeModalIsOpen: true,
      refundData: { charge_id, transfer, amount_paid },
    })
  }

  // openStripeChargeModal(
  //   customer_id,
  //   transfer,
  //   event_id,
  //   duration,
  //   doctor_id,
  //   allowed_amount,
  //   patient_due_amount,
  //   patient_id,
  //   start,
  //   careplan_id,
  // ) {
  //   console.log(
  //     'event id',
  //     customer_id,
  //     transfer,
  //     event_id,
  //     duration,
  //     doctor_id,
  //     allowed_amount,
  //     patient_due_amount,
  //     patient_id,
  //     start,
  //     careplan_id,
  //   )
  //   this.setState({
  //     stripeChargeModalIsOpen: true,
  //     appChargeData: {
  //       customer_id,
  //       transfer,
  //       event_id,
  //       duration,
  //       doctor_id,
  //       allowed_amount,
  //       patient_due_amount,
  //       patient_id,
  //       start,
  //       careplan_id,
  //     },
  //     charge_amount: patient_due_amount || '',
  //   })
  // }

  openStripeCopayChargeModal(
    customer_id,
    transfer,
    event_id,
    duration,
    doctor_id,
    patient_id,
    start,
    careplan_id,
  ) {
    console.log('event id', event_id)
    this.setState({
      stripeChargeCopayIsOpen: true,
      appCopayChargeData: {
        customer_id,
        transfer,
        event_id,
        duration,
        doctor_id,
        patient_id,
        start,
        careplan_id,
      },
      copayChargeAmount: '',
    })
  }

  async getStripeRefund(charge_id, transfer, refund_amount) {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}stripe/refund`

    try {
      let configData = {
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          charge_id: charge_id,
          refund_amount: Number(refund_amount) * 100,
        },
      }
      if (transfer) configData.headers.transfer = true

      const response = await axios(configData)

      const refundData = response.data
      if (refundData?.status === 'succeeded')
        toast.success('Successfully refunded')
      console.log('stripeRefund recieved', refundData)
      this.setState({
        refundData: {},
        stripeModalIsOpen: false,
        refund_amount: '',
      })
      console.log('get insurance claims because of getStripeRefund')
      this.getInsuranceClaims()
      // this.setState({ stripeCharge: chargeData || [] })
    } catch (err) {
      toast.error('Could not fetch stripeRefund, please try again')
      console.log('error when getting stripeRefund from backend', err)
    }
  }

  // These names are really confusing
  // async getStripeCharge() {
  //   const { token, mongoUser } = this.props
  //   let { appChargeData, charge_amount } = this.state
  //   const url = `${Config.BACKEND_URL}stripe/appointment-payment`
  //   console.log('CHARGE: ', charge_amount, appChargeData)
  //   try {
  //     // TODO: Why is this a get request? Needs to be a POST
  //     let configData = {
  //       method: 'get',
  //       url,
  //       headers: {
  //         Authorization: `JWT ${token}`,
  //         x_firebase_id: mongoUser.firebase_id,
  //         customer_id: appChargeData?.customer_id,
  //         event_id: appChargeData?.event_id,
  //         careplan_id: appChargeData?.careplan_id,
  //         duration: appChargeData?.duration,
  //         doctor_id: appChargeData?.doctor_id,
  //         patient_id: appChargeData?.patient_id,
  //         visit_start_time: appChargeData?.start,
  //         description: `Date of service: ${moment(appChargeData?.start).format(
  //           'll',
  //         )}`,
  //         charge_amount: Number(charge_amount?.replace(/[^0-9|.]/g, '')) * 100,
  //       },
  //     }

  //     if (appChargeData?.transfer) configData.headers.transfer = true

  //     const response = await axios(configData)

  //     const charge = response.data
  //     if (charge?.status === 'paid') toast.success('Successfully Charged')
  //     else {
  //       // Write code here to handle when payment fails
  //     }
  //     console.log('stripeCharge received', charge)
  //     this.setState({
  //       appChargeData: {},
  //       stripeChargeModalIsOpen: false,
  //       charge_amount: '',
  //       chargeLoader: false,
  //     })
  //     this.getInsuranceClaims()
  //     // this.setState({ stripeCharge: chargeData || [] })
  //   } catch (err) {
  //     toast.error('Could not charge patient Credit card, please try again!')
  //     console.log('Error when charging patient', err)
  //     this.setState({
  //       appChargeData: {},
  //       stripeChargeModalIsOpen: false,
  //       charge_amount: '',
  //       chargeLoader: false,
  //     })
  //   }
  // }

  // async chargeCopayAmount() {
  //   const { token, mongoUser } = this.props
  //   let { appCopayChargeData, copayChargeAmount } = this.state
  //   const url = `${Config.BACKEND_URL}stripe/appointment-copayment`
  //   console.log('CHARGE: ', copayChargeAmount, appCopayChargeData)
  //   try {
  //     let configData = {
  //       method: 'post',
  //       url,
  //       headers: {
  //         Authorization: `JWT ${token}`,
  //         x_firebase_id: mongoUser.firebase_id,
  //         customer_id: appCopayChargeData?.customer_id,
  //         event_id: appCopayChargeData?.event_id,
  //         careplan_id: appCopayChargeData?.careplan_id,
  //         duration: appCopayChargeData?.duration,
  //         doctor_id: appCopayChargeData?.doctor_id,
  //         patient_id: appCopayChargeData?.patient_id,
  //         visit_start_time: appCopayChargeData?.start,
  //         description: `Date of service: ${moment(
  //           appCopayChargeData?.start,
  //         ).format('ll')}`,
  //         charge_amount:
  //           Number(copayChargeAmount?.replace(/[^0-9|.]/g, '')) * 100,
  //       },
  //     }

  //     if (appCopayChargeData?.transfer) configData.headers.transfer = true

  //     const response = await axios(configData)

  //     const charge = response.data
  //     if (charge?.status === 'paid') toast.success('Successfully Charged Copay')
  //     else {
  //       // Write code here to handle when payment fails
  //     }
  //     console.log('stripeCharge copay received', charge)
  //     this.setState({
  //       appCopayChargeData: {},
  //       stripeChargeModalIsOpen: false,
  //       charge_amount: '',
  //       chargeLoader: false,
  //     })
  //     this.getInsuranceClaims()
  //     // this.setState({ stripeCharge: chargeData || [] })
  //   } catch (err) {
  //     toast.error('Could not process stripe copay charge, please try again!')
  //     console.log('error when getting stripe copay charge from backend', err)

  //     this.setState({
  //       appCopayChargeData: {},
  //       stripeChargeModalIsOpen: false,
  //       charge_amount: '',
  //       chargeLoader: false,
  //     })
  //   }
  // }

  async generateNotesPdf(note_id, selectedPatient) {
    // window.alert("This feature isn't available yet")
    // return
    console.log('note_id: ', note_id)
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}provider/patients/notes`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      params: {
        note_id: note_id,
      },
    }).then(async (result) => {
      let selectedNote = result
      // this.setState({ loading: true })
      let patient = selectedPatient?.patientData || {}
      let wellnessData = selectedPatient?.wellnessData?.length
        ? selectedPatient?.wellnessData[
            selectedPatient?.wellnessData?.length - 1
          ]
        : {}
      let supervisor = selectedPatient?.reviewed
        ? this.state.providerMap[selectedPatient?.reviewed_by?.supervisor]
        : null

      let { medications } = this.props
      this.setState({ selectedPatient: patient })

      await this.getConditionsData(patient)
      await this.getProviderConditions(patient)

      await this.getProviderAllergies(patient)
      await this.getProviderMedications(patient)

      // await this.props.fetchPatientCoreDate(patient.uid, this.props.token)
      // await this.props.fetchPatientConditions(patient.uid, this.props.token)
      await this.props.fetchPatientMedications(patient.uid, this.props.token)
      let { providerMedications, providerAllergies, conditionsData } =
        this.state

      let doctor = selectedPatient.providerData || {}
      let data
      let noteData
      let icd_10_data = ''
      let cpt_data = ''
      let amended_cpt_data = ''
      let conditions_data = ''
      let medication_data = ''
      let allergies_data = ''
      let family_history_data = ''
      let goals_list = ''
      let referrals_requested = ''
      let time_spent = ''

      //FAMILY HISTORY
      let familyScreening =
        selectedPatient && selectedPatient?.screening_family
          ? selectedPatient?.screening_family?.relations
          : []

      familyScreening &&
        familyScreening.forEach((each, index) => {
          let conditions = []
          if (each.conditions)
            conditions = each.conditions.map(
              (x, index) =>
                `${x} ${index === each.conditions.length - 1 ? '' : ', '}`,
            )
          family_history_data = family_history_data.concat(
            index + 1,
            '. ',
            each.relationship,
            ' : ',
            conditions,
            index !== familyScreening?.length && '\n',
          )
        })

      //MEDICATION
      let array1 = (medications && medications['active_medications']) || []
      let array2 =
        (providerMedications &&
          providerMedications['active_medications_provider']) ||
        []

      let array3 = (medications && medications['inactive_medications']) || []
      let array4 =
        (providerMedications &&
          providerMedications['inactive_medications_provider']) ||
        []

      let finalArray = array4.concat(array3.concat(array2.concat(array1)))
      // medication_data = finalArray

      finalArray.forEach((x, index) => {
        medication_data = medication_data.concat(
          index + 1,
          '. ',
          x.dose?.quantity
            ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit}) : `
            : `${x.name} : `,

          x.frequency?.quantity
            ? `${x.frequency.quantity} / ${x.frequency.recurring} : `
            : '- : ',
          moment(x.start).format('MMM Do YYYY') + ' : ',
          x.end ? 'Inactive' : 'Active',
          index !== finalArray?.length && '\n',
        )
      })

      // CONDITIONS
      let conditions = conditionsData || []
      // filter out user entered data
      conditions = conditions.filter(
        (userCondn) =>
          !this.state.providerConditions?.some(
            (prodCondn) =>
              prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
          ),
      )
      let newConditions = conditions?.concat(this.state.providerConditions)

      newConditions?.length &&
        newConditions.forEach((condn, index) => {
          conditions_data = conditions_data.concat(
            index + 1,
            '. ',
            condn.symptom
              ? condn.symptom
              : condn?.code?.coding?.[0]?.display?.replace(/"/g, ''),
            condn?.code?.coding?.[0]?.display && !condn?.userEntered
              ? ' (Doctor Reported) '
              : '',

            index !== newConditions?.length && '\n',
          )
        })

      //ALLERGIES
      let allergies = patient && patient.allergies ? patient?.allergies : []
      let newAllergies = providerAllergies?.concat(allergies)
      newAllergies.forEach((allergy, index) => {
        allergies_data = allergies_data.concat(
          index + 1,
          '. ',
          allergy?.code?.coding?.[0]?.display ||
            (allergy?.type &&
              (allergy?.name?.length
                ? allergy?.name?.join()
                : allergy?.type)) ||
            allergy,
          index !== newAllergies?.length && '\n',
        )
      })

      //NOTES DATA TO BE SHOWN IN THE PDF
      noteData = {
        selected_note: 'visible',
        chief_concern: selectedNote?.chief_concern,
        present_illness: selectedNote?.present_illness,
        physical_exam: selectedNote?.physical_exam,
      }

      let tempArray1 = selectedNote?.careplan || []
      tempArray1.forEach((careplan, index) => {
        icd_10_data = icd_10_data.concat(
          index + 1,
          '. ',
          careplan?.icd_10?.icd10_code,
          ' : ',
          careplan?.icd_10?.title,
          ' : ',
          careplan?.assessment,
          ' : ',
          careplan?.note,
          index !== tempArray1.length && '\n',
          // '\n',
        )
      })
      noteData.icd_10 = icd_10_data

      let tempArray2 = selectedNote?.cpt || []
      tempArray2.forEach(function (cpt, index) {
        cpt_data = cpt_data.concat(
          index + 1,
          '. ',
          cpt?.cpt_code,
          ' : ',
          cpt?.title,
          index !== tempArray2.length && '\n',
          // '\n',
        )
      })

      let tempAmendCptArray = selectedNote?.amended_cpt || []
      tempAmendCptArray.forEach(function (cpt, index) {
        amended_cpt_data = amended_cpt_data.concat(
          index + 1,
          '. ',
          cpt?.cpt_code,
          ' : ',
          cpt?.title,
          index !== tempAmendCptArray.length && '\n',
          // '\n',
        )
      })

      let socialHistory =
        questionMetadata['screenings/wellness']['lifestyle_habits.cigarette'] +
        `: ${wellnessData?.lifestyle_habits?.cigarette || 'N/A'}\n` +
        questionMetadata['screenings/wellness']['lifestyle_habits.alcohol'] +
        `: ${wellnessData?.lifestyle_habits?.alcohol || 'N/A'}\n` +
        questionMetadata['screenings/wellness']['lifestyle_habits.drugs'] +
        `: ${wellnessData?.lifestyle_habits?.drugs || 'N/A'}\n` +
        questionMetadata['screenings/wellness'][
          'relationships.marital_status'
        ] +
        `: ${wellnessData?.relationships?.marital_status || 'N/A'}\n`

      noteData.cpt_codes = cpt_data
      noteData.amended_cpt_codes = amended_cpt_data

      let bmiValues = Helpers.calculateBMIValues(
        patient?.height,
        patient?.weight_update || patient?.weight,
        patient?.gender,
        moment().diff(moment(patient?.dob, 'DD/MM/YYYY'), 'years'),
      )

      patient?.goals &&
        Object.keys(patient.goals).forEach(function (entry, index) {
          if (entry !== 'free_form_goals')
            goals_list = goals_list.concat(
              index + 1,
              '. ',
              entry === 'sleep_goal'
                ? 'Sleep'
                : entry === 'weight_goal'
                ? 'Weight'
                : entry === 'step_goal'
                ? 'Steps'
                : entry === 'meditation_goal'
                ? 'Meditation'
                : entry === 'exercise_goal'
                ? 'Exercise'
                : entry === 'weight_loss_goal'
                ? 'Weight Loss'
                : entry === 'change_habits'
                ? 'Habits'
                : entry || 'N/A',
              ' : ',
              patient.goals[entry] || 'N/A',
              index !== patient?.goals?.length && '\n',
              // '\n',
            )
        })

      let follow_up = selectedNote?.followupData?.follow_up_value
        ? `Requested follow up in ${selectedNote?.followupData?.follow_up_value} ${selectedNote?.followupData?.follow_up_unit?.label}`
        : 'N/A'

      referrals_requested =
        (this.state.followupData?.refer_specialty_arr?.join(', ') ||
          selectedNote?.followupData?.refer_specialty?.label ||
          'N/A') +
        '\n' +
        (selectedNote?.followupData?.referral_suggested || '')

      // BUG: This data is missing
      time_spent = `
    Video Visit Start Time: ${moment
      .unix(selectedNote?.appointment_date / 1000)
      .format('lll')} ${new Date()
        .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
        .substring(4)} \n
    Video Visit End Time: ${moment
      .unix(selectedNote?.appointment_end_date / 1000)
      .format('lll')} ${new Date()
        .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
        .substring(4)} \n
    Video Consult Minutes: ${moment
      .unix(selectedNote?.appointment_end_date / 1000)
      .diff(
        moment.unix(selectedNote?.appointment_date / 1000),
        'minute',
      )} minutes \n
    Pre-Visit Review of Records: ${
      selectedNote?.time_spent?.pre_review_time || 0
    } minutes \n
    After Visit Documentation: ${
      selectedNote?.time_spent?.after_visit || 0
    } minutes \n
    Provider Collaboration: ${
      selectedNote?.time_spent?.provider_colab || 0
    } minutes \n
    Total Time Spent: ${
      Number(
        moment
          .unix(selectedNote?.appointment_end_date / 1000)
          .diff(moment.unix(selectedNote?.appointment_date / 1000), 'minute') ||
          0,
      ) +
      Number(selectedNote?.time_spent?.pre_review_time || 0) +
      Number(selectedNote?.time_spent?.after_visit || 0) +
      Number(selectedNote?.time_spent?.provider_colab || 0)
    } minutes\n
    Interpreter Present at Visit: N/A\n
    Method of visit: ${selectedNote?.time_spent?.method_of_visit || 'N/A'}
    `

      let insurance = Array.isArray(selectedPatient?.insurances)
        ? selectedPatient?.insurances?.find((ins) => ins.preference === 1) ||
          selectedPatient?.insurances[0]
        : selectedPatient?.insurances || null

      let careplan_note = ''
      let assessment_note = ''
      if (selectedNote?.careplan?.length) {
        let tempArray = selectedNote?.careplan || []
        tempArray.forEach((icd, index) => {
          if (icd?.assessment)
            assessment_note = assessment_note.concat(
              icd?.assessment,
              index !== tempArray?.length && '\n',
            )
          if (icd?.note)
            careplan_note = careplan_note.concat(
              icd?.note,
              index !== tempArray?.length && '\n',
            )
        })
      }
      if (selectedNote?.assessment_note)
        assessment_note = selectedNote?.assessment_note
      if (selectedNote?.careplan_note)
        careplan_note = selectedNote?.careplan_note

      data = {
        //PATIENT INFORMATION
        date_of_service: `${moment
          .unix(selectedNote?.appointment_date / 1000)
          .format('MM/DD/YYYY')}`,
        patient_name:
          selectedPatient?.firstName + ' ' + selectedPatient?.lastName,
        patient_dob: moment(patient?.dob, 'DD/MM/YYYY').format('MM/DD/YYYY'),
        patient_gender: patient?.gender,
        patient_address: selectedPatient?.streetAddress,
        patient_phone: selectedPatient?.phoneNumber,
        patient_email: selectedPatient?.email,
        insurance_provider: insurance?.insurance_provider,
        insurance_plan: insurance?.plan_type,
        insurance_member: insurance?.member_name,
        insurance_subscriber: insurance?.subscriber_id,
        insurance_group: insurance?.group_number,
        height: patient?.height
          ? `${Math.floor(patient?.height / 12)} ft ${patient?.height % 12} in`
          : 'N/A',
        weight: (patient?.weight_update || patient?.weight || 'N/A') + ' lbs',
        bmi: bmiValues?.bmi || 'N/A',
        social_history: socialHistory,
        diagnostic_review: selectedNote?.diagnostic_review?.no_review
          ? 'There are no studies to review at today’s visit'
          : selectedNote?.diagnostic_review?.text || 'N/A',
        goals_list,
        follow_up,
        auth_request: selectedNote?.followupData?.requested_auth || 'N/A',
        referrals_requested,
        time_spent,
        assessment_note,
        careplan_note,
        provider_chief_concern: selectedNote?.provider_chief_concern || '',
        provider_illness: selectedNote?.provider_illness || '',

        //PROVIDER INFORMATION
        provider_signature: doctor?.sign_image,
        provider_name: doctor?.first_name + ' ' + doctor?.last_name,
        provider_specialty: doctor?.speciality,
        medication_data: medication_data,
        allergies_data: allergies_data,
        conditions_data: conditions_data,
        family_history_data: family_history_data,
        careplan: selectedNote?.careplan,
        reviewed: selectedPatient.reviewed ? 'visible' : 'hidden',
        supervisor_signature: supervisor?.sign_image,
        supervisor_name: supervisor?.first_name + ' ' + supervisor?.last_name,
        supervising_date:
          selectedPatient?.reviewed_by?.date || moment(new Date()).format('ll'),
      }
      // console.log('TESTING: ', data)

      Axios(VisitNotesPdf)
        .then((res) => {
          let templateHtml = Handlebars.compile(res.data.toString())
          let bodyHtml = templateHtml({ ...data, ...noteData })
          this.setState({
            pdfData: bodyHtml,
            showModal: false,
            showNotesModal: true,
            // loading: false,
          })
        })
        .catch((err) => {
          this.setState({ loading: false })
          console.log('error with pdf generation', err)
          toast.error('Could not display notes, please try again')
        })
    })
  }

  sendPaymentEmailModal(
    val,
    firstName,
    lastName,
    visitDate,
    amount,
    firebase_id,
    event_id,
    totalChargesBilled,
    adjustedAmount,
    insPaidAmount,
    patientPaidAmount,
    methodOfVisit,
    providerName,
    providerCredential,
  ) {
    if (val === false) {
      this.setState({
        paymentFirstName: '',
        paymentLastName: '',
        paymentVisitDate: '',
        paymentAmount: '',
        paymentPatientId: '',
        paymentEventId: '',
        paymentDueDate: '',
        paymentPatientPaidAmount: '',
        paymentAdjustmentAmount: '',
        paymentInsurancePaidAmount: '',
        paymentBilledAmount: '',
        paymentProviderName: '',
        paymentProviderCredential: '',
        paymentMethodOfVisit: '',
      })
      this.setState({ paymentEmailModalIsOpen: false })
      return
    }
    this.setState({
      paymentEmailModalIsOpen: val,
      paymentFirstName: firstName,
      paymentLastName: lastName,
      paymentVisitDate: visitDate,
      paymentAmount: amount,
      paymentPatientId: firebase_id,
      paymentEventId: event_id,
      paymentBilledAmount: totalChargesBilled,
      paymentAdjustmentAmount: adjustedAmount,
      paymentInsurancePaidAmount: insPaidAmount,
      paymentPatientPaidAmount: patientPaidAmount,
      paymentMethodOfVisit: methodOfVisit,
      paymentProviderName: providerName,
      paymentProviderCredential: providerCredential,
    })
  }

  // TODO: Make the time zone in dates
  async generateSuperBillPdf(careplanArr) {
    let completedCareplans = undefined
    let note_id = undefined
    let has_event_id = undefined
    if (Array.isArray(careplanArr)) {
      completedCareplans = careplanArr.filter(
        (obj) => obj.note_status === 'completed',
      )
      note_id = completedCareplans?.[0]?.note_id
      has_event_id = completedCareplans?.[0]?.appointment_event_id
        ? true
        : false
    } else {
      completedCareplans = careplanArr
      note_id = completedCareplans?.note_id
      has_event_id = completedCareplans?.appointment_event_id ? true : false
    }
    console.log('CC->', completedCareplans)

    try {
      let superBillData = await axios({
        method: 'get',
        url: `${Config.BACKEND_URL}super-bill`,
        params: {
          id: note_id,
          is_async_note: false, // why is this being sent??
          has_event_id: has_event_id,
        },
      })

      if (!superBillData?.data?.success) {
        window.alert('Error getting super bill data, contact support!')
        return
      }
      superBillData = superBillData?.data?.data

      let pInsurnace = Array.isArray(superBillData?.insurances)
        ? superBillData?.insurances?.find((ins) => ins.preference === 1) || null
        : superBillData?.insurances || null

      let sInsurance = Array.isArray(superBillData?.insurances)
        ? superBillData?.insurances?.find((ins) => ins.preference === 2)
        : null

      let icd_10_data = []
      let tempArray = superBillData?.careplan || []
      tempArray.forEach((icd, index) => {
        icd_10_data.push({
          index: index + 1,
          icd_10:
            // icd?.icd_10?.icd10_code?
            `${index + 1}. ${icd?.icd_10?.icd10_code || ''} : ${
              icd?.icd_10?.title
            }`,
          // : '' + icd?.icd_10?.title
          // ? `${icd?.icd_10?.title}`
          // : '',
        })
      })

      console.log('insurances')

      let cpt_data = []
      // BUG: This will break the webpage, careplan is an array
      let tempArray1 = superBillData?.cpt || []
      tempArray1.forEach(function (cpt, index) {
        cpt_data.push({
          date: moment(superBillData?.created_at).format('MM/DD/YYYY'),
          code: cpt.cpt_code,
          dx: '',
          desc: cpt.title,
          fee: '',
          paid: '',
        })
      })
      console.log('cpt', cpt_data)
      console.log('icd', icd_10_data)
      let invoice = superBillData?.invoice?.[0]
      console.log('SuperBillData', superBillData)
      let total_era_billed =
        superBillData?.claim?.claim_md_response?.claim?.[0]?.total_charge
      let total_charge =
        superBillData?.billed_amount ||
        superBillData?.appointment_info?.billed_amount ||
        total_era_billed
      let total_era_paid_info = superBillData?.claim?.era_data?.charge
      console.log('total_era_paid_info', total_era_paid_info)
      let total_era_paid = total_era_paid_info?.reduce(
        (accumulator, currentValue) => {
          return accumulator + Number(currentValue.paid)
        },
        0,
      )
      let total_paid = null
      // superBillData?.paid_amount ||
      // superBillData?.appointment_info?.paid_amount ||
      // total_era_paid
      //
      let era_diagnosis = superBillData?.claim?.diagnosis_list
      let cpt_charges = []
      if (era_diagnosis) {
        for (let diagnosis of era_diagnosis) {
          let era_paid_for_proc_code = total_era_paid_info?.filter(
            (obj) => obj.proc_code == diagnosis.procedure,
          )
          let paidAsString = era_paid_for_proc_code?.paid
            ? `$ ${era_paid_for_proc_code.paid}`
            : null
          console.log(
            'Ee->',
            total_era_paid_info?.[0]?.proc_code,
            diagnosis.procedure,
          )
          cpt_charges.push({
            dos: diagnosis.dateOfServiceStart,
            cpt: diagnosis.procedure,
            fee: diagnosis.amountChanges,
            paid: paidAsString || 0,
          })
        }
      } else {
        for (let diagnosis of superBillData?.cpt) {
          let dos = undefined
          if (superBillData?.appointment_date) {
            dos = mtz
              .tz(superBillData.appointment_date, 'GMT')
              .tz('America/Los_Angeles')
              .format('MM/DD/YYYY')
          } else {
            dos = mtz
              .tz(superBillData.created_at, 'America/Los_Angeles')
              .format('ll')
          }
          cpt_charges.push({
            dos: dos,
            cpt: diagnosis.cpt_code,
            fee: 'Error',
            // paid: paidAsString || 0,
          })
        }
        if (cpt_charges.length == 1) cpt_charges[0].fee = total_charge
      }

      // total_paid
      let invoices = superBillData?.invoice
      // let has_copay = superBillData?.copay_invoice
      // let total_copay_paid = 0
      // if(has_copay)
      let total_copay_paid = superBillData?.copay_invoice?.reduce(
        (accumulator, currentValue) => {
          return accumulator + Number(currentValue.paid_invoice?.amount_paid)
        },
        0,
      )
      total_paid = invoices?.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.amount_paid)
      }, 0)
      console.log('total_paid', total_paid, total_copay_paid)
      total_paid += total_copay_paid
      total_paid /= 100

      let pdfValues = {
        practice_name: 'Aila Health',
        practice_address:
          '1999 Harrison St 18th Floor, #5135 Oakland, CA 94612',
        patient_name: `${
          superBillData?.patientData?.firstName ||
          superBillData?.patientData?.first_name
        } ${
          superBillData?.patientData?.lastName ||
          superBillData?.patientData?.last_name
        }`,
        patient_address: `${
          superBillData?.patientData?.streetAddress ||
          superBillData?.patientData?.street_address
        }`,
        patient_phone: `${
          superBillData?.patientData?.phoneNumber ||
          superBillData?.patientData?.phone_number
        }`,
        patient_dob: moment(
          superBillData?.patientData?.dob,
          'DD/MM/YYYY',
        ).format('MM/DD/YYYY'),
        patient_email: superBillData?.patientData?.email,
        // statement_num: 1,
        issue_date: moment().format('MM/DD/YYYY'),
        practice_phone: superBillData?.practice_info?.phone || '-',
        p_insurance_plan: `${
          pInsurnace?.insurance_company?.insurance_name || '-'
        }`,
        p_member_id: `${pInsurnace?.subscriber_id || '-'}`,
        p_group_number: `${pInsurnace?.group_number || '-'}`,
        s_insurance_plan: `${
          sInsurance?.insurance_company?.insurance_name || '-'
        }`,
        s_member_id: `${sInsurance?.subscriber_id || '-'}`,
        s_group_number: `${sInsurance?.group_number || '-'}`,
        provider_name: `${superBillData?.providerData?.first_name} ${superBillData?.providerData?.last_name}`,
        provider_credentials: `${
          superBillData?.providerData?.credential || ''
        }`,
        credentials: `${superBillData?.providerData?.credential || ''}`,
        provider_npi: `${superBillData?.providerData?.npi?.number || ''}`,
        license: ``,
        icd_10: icd_10_data || [],
        cpt_10: cpt_data || [],
        total_charge: `$ ${total_charge || '-'}`,
        total_paid: `$ ${total_paid || '-'}`,
        practice_tax_id: superBillData?.practice_info?.taxEntity || '-',
        practice_npi: superBillData?.practice_info?.billing_npi || '-',
        billing_contact: 'billing@ailahealth.com',
        billing_phone: '415-704-9601',
        if_insurance_paid: total_era_paid_info ? 'X' : ' ',
        if_cash_pay: ' ',
        cpt_charges: cpt_charges,
      }
      console.log('pdfValues', pdfValues)
      Axios(superBillTemplate).then((res) => {
        let templateHtml = Handlebars.compile(res.data.toString())
        let bodyHtml = templateHtml(pdfValues)
        this.setState({
          pdfData: bodyHtml,
          showModal: true,
          showNotesModal: false,
        })
      })

      // let invoice = data?.invoice?.[0]
      // let pdfValues = {
      //   practice_name: 'Aila Health',
      //   practice_address:
      //     '1999 Harrison St 18th Floor, #5135 Oakland, CA 94612',
      //   patient_name: `${data?.firstName} ${data?.lastName}`,
      //   patient_address: `${data?.streetAddress}`,
      //   patient_phone: data?.phoneNumber,
      //   patient_dob: moment(data?.patientData?.dob, 'DD/MM/YYYY').format(
      //     'MM/DD/YYYY',
      //   ),
      //   statement_num: 1,
      //   issue_date: moment().format('MM/DD/YYYY'),
      //   insurance_plan: `${insurance?.insurance_company?.insurance_name || ''}`,
      //   member_number: `${insurance?.subscriber_id || ''}`,
      //   provider_name: `${data?.providerData?.first_name} ${data?.providerData?.last_name}`,
      //   credentials: `${data?.providerData?.npi?.number}`,
      //   provider_npi: `${data?.providerData?.npi?.number}`,
      //   license: ``,
      //   icd_10: icd_10_data || [],
      //   cpt_10: cpt_data || [],
      //   total_paid: `${
      //     invoice?.amount_paid
      //       ? '$' + parseFloat(invoice?.amount_paid / 100).toFixed(2)
      //       : '-'
      //   }`,
      // }
      // Axios(htmlModule).then((res) => {
      //   let templateHtml = Handlebars.compile(res.data.toString())
      //   let bodyHtml = templateHtml(pdfValues)
      //   this.setState({
      //     pdfData: bodyHtml,
      //     showModal: true,
      //     showNotesModal: false,
      //   })
      // })
    } catch (err) {
      console.log('Some err', err)
    }
  }

  async showEraData(era_id) {
    try {
      console.log('getting era pdf data', era_id)
      let eraPdfData = await axios({
        method: 'get',
        url: `${Config.BACKEND_URL}claims/era/pdf`,
        params: {
          id: era_id,
        },
      })
      console.log('got era pdf data', eraPdfData)

      if (!eraPdfData?.data?.success) {
        window.alert('Error getting ERA data, contact support!')
        return
      } else {
        console.log('got back data', eraPdfData)
      }
      eraPdfData = eraPdfData?.data?.data
      let bodyPdf = eraPdfData?.data?.[0]

      this.setState({
        eraPdfViewData: bodyPdf,
        showEraModal: true,
      })
    } catch (err) {
      console.log('Some err', err)
    }
  }

  // download(event) {
  //   const currentRecords = this.state.downloadData
  //   var data_to_download = []
  //   for (var index = 0; index < currentRecords.length; index++) {
  //     data_to_download.push(currentRecords[index].values)
  //   }
  //   this.setState({ dataToDownload: data_to_download }, () => {
  //     // click the CSVLink component to trigger the CSV download
  //     // this.csvlink.current.click()
  //     this.csvLink.link.click()
  //   })
  // }

  // New download function - all data is now fetched from backend
  download = async (event) => {
    console.log("I'm here")
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}reports/payments`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })
      .then((response) => {
        // result contains a CSV data, need to make this downloadable
        console.log('result', response)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'payments.csv')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  handleTableCallBack = (childData) => {
    if (this.state.initiallyRenderedClaimsTable) return
    this.setState(
      {
        page: childData.pageIndex + 1,
        size: childData.pageSize,
        downloadData: childData?.page,
      },
      () => {
        console.log('Handling table callback')
        // I have commented this out as this is the one causing the table to get re-rendered
        if (this.state.initiallyRenderedClaimsTable) {
          // this.setState({ initiallyRenderedClaimsTable: false })
          console.log('YES-initiallyRenderedClaimsTable')
        } else {
          // this.getInsuranceClaims()
          this.setState({ loading: false })
          this.setState({ initiallyRenderedClaimsTable: true })
        }
      },
    )
  }

  clearAllFilters = () => {
    console.log('clearing all filters')
    this.setState(
      {
        filterByPatient: null,
        filterByPatientPcn: null,
        filterByProvider: null,
        paymentFromDate: null,
        paymentToDate: null,
        filterByBillType: null,
        filterByClaimStatus: null,
        filterByVisitType: null,
        filterByDaysSinceSubmission: null,
        filterByFacility: null,
      },
      () => {
        this.getInsuranceClaims()
      },
    )
  }

  sendPaymentEmail = async () => {
    let {
      paymentFirstName,
      paymentAmount,
      paymentVisitDate,
      paymentPatientId,
      paymentEventId,
    } = this.state

    //**************

    Axios(patientInvoice).then((res) => {
      let templateHtml = Handlebars.compile(res.data.toString())

      let chargeAmount = parseFloat(this.state.paymentAmount) * 100 // Convert to cents for stripe
      axios({
        method: 'get',
        url: `${Config.BACKEND_URL}stripe/appointment/payment-link`,
        params: {
          event_id: this.state.paymentEventId,
          charge_amount: chargeAmount,
        },
      })
        .then((result) => {
          if (!result?.data?.success) return

          let stripePaymentLinkInfo = result?.data?.data
          let stripePaymentUrl = stripePaymentLinkInfo.url
          console.log('Payment URL', stripePaymentUrl)

          let bodyHtml = templateHtml({
            // Data to fill in the template
            practice_name: 'Aila Health',
            practice_address:
              '1999 Harrison St 18th floor 5135, Oakland, CA 94612, USA',
            practice_billing_number: '415-704-9601',
            patient_name:
              this.state.paymentFirstName + ' ' + this.state.paymentLastName,
            patient_address: '',
            date_of_service: this.state.paymentVisitDate,
            invoice_payment_status: 'Unpaid',
            due_date: this.state.paymentDueDate,
            if_past_due: 'PAST DUE',
            method_of_visit: this.state.paymentMethodOfVisit,
            provider_name: this.state.paymentProviderName,
            provider_credential: this.state.paymentProviderCredential,
            billed_amount: '$ ' + this.state.paymentBilledAmount,
            insurance_paid_amount: '$ ' + this.state.paymentInsurancePaidAmount,
            adjustment_amount: '$ ' + this.state.paymentAdjustmentAmount,
            patient_paid: '$ ' + this.state.paymentPatientPaidAmount,
            amount_due: '$ ' + this.state.paymentAmount,
            pay_now_link: stripePaymentUrl,
            // 'https://checkout.stripe.com/c/pay/cs_test_a1YE6WfQ9DyZBK0630kRUdmKyspFpRCsLRvkHPIkJoWkaKLRhrSLEtLVk6#fidkdWxOYHwnPyd1blpxYHZxWjA0TE81T2dJPUJnTUxdPFB%2FPF1RNUBSb1ZRV0R0cUFQSVNQNzFDMl1fYXBHVFFyXEZTNGJMcVdOZHxScGdmSHJHdlFMUlNoQHRIUF01UFNDfGBwMXRAT3c9NTVsSkN%2FYGpgMCcpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl',
          })
          // set bodyHtml
          let postData = {
            htmlContent: bodyHtml,
          }
          let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
          //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
          axios({
            method: 'post',
            headers: {
              Authorization: `JWT ${this.props.token}`,
              // x_firebase_id: this.props?.patientId,
              x_firebase_id: 'pPuqrbHhGrSjw6vqLGZ4jd0lkK62',
            },
            url: url,
            data: postData,
          })
            .then(({ data }) => {
              axios({
                method: 'post',
                url: `${Config.BACKEND_URL}payment/link/email`,
                data: {
                  first_name: paymentFirstName,
                  amount: paymentAmount,
                  visit_date: paymentVisitDate,
                  patient_id: paymentPatientId,
                  event_id: paymentEventId,
                  pdf_data: data.result,
                },
              })
                .then((response) => {
                  if (response.data.success === true) {
                    toast.success('Email Sent!')
                  } else {
                    toast.error('Something went wrong!')
                  }
                })
                .catch((err) => {
                  toast.error('Something went wrong!')
                })

              // Insert a link that allows the user to download the PDF file
              var link = document.createElement('a')
              link.innerHTML = 'Download PDF file'
              link.download = `Patient Invoice.pdf`
              link.href = 'data:application/octet-stream;base64,' + data.result
              // document.body.appendChild(link);
              link.click()
              console.log('downloaded')
              // toast.success('pdf downloaded')
            })
            .catch((err) => {
              console.log('error when getting saving provider goals data', err)
              toast.error('Could not download Notes, please try again')
            })
        })
        .catch((err) => {
          console.log('Error when generating stripe payment URL', err)
        })
    })
    //**************
  }

  getInsuranceClaims = async () => {
    const { token, mongoUser, firebaseUser, patient, getAllPatients } =
      this.props
    // const url =
    //   this.state.appointmentType?.label === 'Upcoming Appointments'
    //     ? `${Config.BACKEND_URL}insurance/upcoming-claim`
    //     : `${Config.BACKEND_URL}insurance/careplan-claim`
    // I think this is the func running to get the claims
    let queryParams = {
      page: this.state.page || 1,
      size: this.state.size || 10,
      upcoming:
        this.state.appointmentType?.label === 'Upcoming Appointments'
          ? true
          : false,
    }
    if (this.state.filterByPatient) {
      queryParams.patient_id = this.state.filterByPatient?.value
    }
    if (this.state.filterByPatientPcn) {
      queryParams.pcn = this.state.filterByPatientPcn?.value
    }
    if (this.state.filterByProvider) {
      queryParams.doctor_id = this.state.filterByProvider?.value
    }
    if (
      moment(this.state.paymentFromDate).valueOf() !==
      moment(this.state.paymentToDate).valueOf()
    ) {
      if (this.state.paymentFromDate) {
        queryParams.from_date = moment(this.state.paymentFromDate).valueOf()
      }

      if (this.state.paymentToDate) {
        queryParams.to_date = moment(this.state.paymentToDate).valueOf()
      }
    } else {
      console.log('paymentFromDate and paymentToDate are the same')
    }
    if (this.state.filterByVisitType) {
      queryParams.visit_type = this.state.filterByVisitType?.value
    }
    if (this.state.filterByBillType) {
      queryParams.bill_type = this.state.filterByBillType?.value
    }
    if (this.state.filterByClaimStatus) {
      queryParams.claim_status = this.state.filterByClaimStatus?.value
    }
    // Need to add days since submission and facility

    let headersConfig = {
      Authorization: `JWT ${token}`,
      x_doctor_id: mongoUser.firebase_id,
      patient_id: patient?.uid,
    }

    // for admin tab
    if (getAllPatients) delete headersConfig.patient_id

    console.log('Is this running first?') // Yes

    // merging the data from appointment and careplan notes claims
    axios
      .all([
        axios({
          method: 'get',
          url: `${Config.BACKEND_URL}insurance/claim`,
          // url: `${Config.BACKEND_URL}insurance/claim/test`,
          // url: `localhost:15000/insurance/claim`,
          headers: headersConfig,
          params: queryParams,
        }),
        axios({
          method: 'get',
          url: `${Config.BACKEND_URL}insurance/careplan-claim`,
          headers: headersConfig,
          params: queryParams,
        }),
      ])
      .then(
        axios.spread((appointment, careplan) => {
          console.log('received insurance claim data: ', appointment.data[0])
          console.log(
            'received insurance careplan claim data: ',
            careplan.data[0],
          )
          let { mongoUser } = this.props

          if (mongoUser.authorization_level !== 'external_doctor')
            this.setState({
              loading: false,
              claims: [...appointment.data, ...careplan.data],
              // claims: [...appointment.data],
            })
          else
            this.setState({
              loading: false,
              claims: [],
            })
          // this.setState()
        }),
      )
      .catch((err) => {
        this.setState({ loading: false })
        toast.error('Could not fetch payments, please try again')
        console.log('error when getting insurance events from backend', err)
      })

    // try {
    //   const response = await axios({
    //     method: 'get',
    //     url,
    //     headers: {
    //       Authorization: `JWT ${token}`,
    //       x_doctor_id: mongoUser.firebase_id,
    //       patient_id: patient?.uid
    //     },
    //     params: queryParams,
    //   })
    //   console.log('patient?.uid: ', patient?.uid)
    //   const events = response.data

    //   console.log('CLaims recieved: ', events)
    //   this.setState({ claims: events || [] })
    //   this.setState({ loading: false })
    // } catch (err) {
    //   toast.error('Could not fetch payments, please try again')
    //   console.log('error when getting calendar events from backend', err)
    // }
  }

  getAppointmentInvoice = async () => {
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}admin/appointments`

    try {
      this.setState({ loading: true })
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser.firebase_id,
          invoice: true,
        },
      })

      const invoiceData = response.data

      // console.log('invoices recieved', invoiceData)
      if (mongoUser.authorization_level !== 'external_doctor')
        this.setState({ invoices: invoiceData || [], loading: false })
      else this.setState({ invoices: [], loading: false })
    } catch (err) {
      toast.error('Could not fetch invoices, please try again')
      console.log('error when getting invoices from backend', err)
    }
  }

  onPatientNavLinkClicked = (lp) => {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  navigateToCms1500 = (claim) => {
    console.log('claim: ', claim)
    this.props.navigateToCms()
    // this.setState({ cms1500ModalOpen: true })
  }

  navigatetoPatientProfile = (p_id) => {
    const { patientList, patientTimer, updateRender, adminId, token } =
      this.props
    console.log('testing on click: ', p_id)
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        // console.log('RESULT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (patientTimer && patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(patient.uid, token, timeline)
            this.props.fetchPatientCoreDate(patient.uid, token)
            this.props.fetchPatientWellnessScreening(patient.uid, token)
            this.props.fetchPatientCalculateProgressData(patient.uid, token)
            this.props.fetchPatientConditions(patient.uid, token)
            this.props.fetchPatientMedications(patient.uid, token)
            this.props.fetchPatientLabs(patient.uid, token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!updateRender)
          }
        })
      }
    })
  }

  // BUG: this still dosent work yet
  handleInsuranceCallback = (childData) => {
    console.log('callback::', childData)
    const self = this
    const { token } = this.props
    if (childData) {
      self.setState({ showInsuranceModal: false }, () => {
        self.props.toggleSidebar(false)
        console.log('Handling insurance callback')
        this.getInsuranceClaims()
      })
    } else {
      self.setState({ insuranceModalIsOpen: false })
      self.props.toggleSidebar(false)
    }
  }

  renderInsuranceEditModal() {
    const { editInsuranceData, selectedPatientId } = this.state

    let primary_insurance = Array.isArray(editInsuranceData)
      ? editInsuranceData?.find((ins) => ins.preference === 1) || null
      : editInsuranceData || null

    let secondary_insurance = Array.isArray(editInsuranceData)
      ? editInsuranceData?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.openInsurnaceModal(false)}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient Insurance
        </h4>
        <PatientInsurance
          parentCallback={this.handleInsuranceCallback.bind(this)}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          patientId={selectedPatientId}
          fromProvider={true}
        />
      </div>
    )
  }

  // Function name is misleading, this is to render the CMS15000 tab
  renderInsuranceModal() {
    // const { editInsuranceData, selectedPatientId } = this.state

    // let primary_insurance = Array.isArray(editInsuranceData)
    //   ? editInsuranceData?.find((ins) => ins.preference === 1) || null
    //   : editInsuranceData || null

    // let secondary_insurance = Array.isArray(editInsuranceData)
    //   ? editInsuranceData?.find((ins) => ins.preference === 2)
    //   : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onInsuranceEditClicked(false)}
        >
          X
        </p>
        {/* <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient Insurance
        </h4> */}
        {/* <PatientInsurance
          parentCallback={this.handleInsuranceCallback.bind(this)}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          patientId={selectedPatientId}
          fromProvider={true}
        /> */}
        {this.state.insuranceModalIsOpen ? (
          <CmsTab
            eventId={this.state.cms1500EventID}
            savedClaim={this.state.cms1500Saved}
            noteOnly={this.state.cms1500OnlyNote}
            closeModal={() => {
              this.setState({ insuranceModalIsOpen: false })
              console.log('returning')
            }}
          />
        ) : null}
      </div>
    )
  }

  renderPaymentEmailModal() {
    return (
      <div
        style={{
          height: '90%',
          width: '90%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          style={{ padding: '4px 20px' }}
          onClick={() => this.setState({ paymentEmailModalIsOpen: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Payment Email Details
        </h4>
        <div
          style={{
            width: '600px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Patient First Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={'First name'}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentFirstName}
                onChange={(e) => {
                  this.setState({ paymentFirstName: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Patient Last Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={'First name'}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentLastName}
                onChange={(e) => {
                  this.setState({ paymentLastName: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Date of Service
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentVisitDate}
                onChange={(e) => {
                  this.setState({ paymentVisitDate: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Due Date
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentDueDate}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentDueDate: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Method of Visit
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentMethodOfVisit}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentMethodOfVisit: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentProviderName}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentProviderName: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Provider Credential
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentProviderCredential}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentProviderCredential: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Billed Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentBilledAmount}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentBilledAmount: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Insurance Paid Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentInsurancePaidAmount}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentInsurancePaidAmount: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Adjustment Paid Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentAdjustmentAmount}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentAdjustmentAmount: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Patient Paid Amount
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentPatientPaidAmount}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentPatientPaidAmount: e.target.value })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Amount Due
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <input
                placeholder={''}
                className={Styles.textInput}
                type="text"
                style={{
                  width: '100%',
                }}
                value={this.state.paymentAmount}
                onChange={(e) => {
                  console.log('e', e)
                  this.setState({ paymentAmount: e.target.value })
                }}
              />
            </div>
          </div>

          {/*  */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center content horizontally
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => {
                this.setState({ paymentEmailModalIsOpen: false })
                this.sendPaymentEmail()
              }}
              className={GlobalStyles.button}
              disabled={false}
              style={{
                width: 250,
                // marginLeft: 10,
                backgroundColor: Constants.primaryTheme,
                textAlign: 'center',
              }}
              variant="primary"
              color={Constants.primaryTheme}
              backgroundColor={Constants.primaryTheme}
            >
              Send Email
            </Button>
          </div>
        </div>
      </div>
    )
  }

  // renderTaskModal() {
  //   return (
  //     <div
  //       style={{
  //         height: '90%',
  //         width: '90%',
  //         position: 'absolute',
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //         zIndex: 2147483647,
  //       }}
  //     >
  //       <TaskModal
  //         supportData={this.state.modalSupportData}
  //         // modalIsOpen={this.state.taskModalIsOpen}
  //         hideTaskModal={this.hideTaskModal}
  //         getUserTask={this.getUserTask}
  //       />
  //     </div>
  //   )
  // }

  range = (start, end) => {
    var ans = []
    for (let i = start; i <= end; i++) {
      ans.push(i)
    }
    return ans
  }

  getMonth(date, val) {
    console.log('DATEM:', val, '|', date.getMonth())
    let date_num = date.getMonth()
    return date_num
    // switch (date_num) {
    //   case 0:{
    //     console.log("Returning January")
    //     return 'January'
    //   }
    //   case 1:
    //     return 'February'
    //   case 2:
    //     return 'March'
    //   case 3:
    //     return 'April'
    //   case 4:
    //     return 'May'
    //   case 5:
    //     return 'June'
    //   case 6:
    //     return 'July'
    //   case 7:
    //     return 'August'
    //   case 8:
    //     return 'September'
    //   case 9:
    //     return 'October'
    //   case 10:
    //     return 'November'
    //   case 11:
    //     return 'December'
    //   default: {
    //     console.log('ERROR1: Invalid Month')
    //     return 'January|'
    //   }
    // }
  }

  getYear(date) {
    // console.log('getYear:', date.getFullYear())
    return date.getFullYear()
  }

  renderFilterModal() {
    const years = this.range(2020, 2024, 1)
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    // return <div>Hello</div>

    let scheduleInfusionData = {}
    let ref_infusion_id = scheduleInfusionData?._id || '123'
    let drugName = scheduleInfusionData?.drug || {}
    let dosage = scheduleInfusionData?.dosage || {}
    let authorization_number = scheduleInfusionData?.authorization_number || {}

    return (
      <div
        style={{
          height: '90%',
          width: '90%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          style={{ padding: '4px 20px' }}
          onClick={() => this.setState({ filterModalOpen: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Filter Options
        </h4>
        <div
          style={{
            width: '600px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                flex: 1,
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
              }}
            >
              Date of Service
            </span>
            <div style={{ flex: 1 }}>
              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {'<'}
                    </button>
                    <select
                      // BUG: value needs a value
                      value={this.getYear(date)}
                      onChange={({ target: { value } }) => {
                        changeYear(value)
                        // this.setState({ patientDob: value })
                      }}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      // BUG: 0 needs to be the correct index
                      value={months[this.getMonth(date, 1)]}
                      onChange={({ target: { value } }) => {
                        changeMonth(months.indexOf(value))
                        // this.setState({ patientDob: value })
                      }}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {'>'}
                    </button>
                  </div>
                )}
                placeholder="From"
                selected={this.state.paymentFromDate}
                onChange={(dates) => {
                  console.log('dates ', dates)
                  this.setState({ paymentFromDate: dates })
                }}
              />
            </div>

            <div
              className={Styles.filterModalLabel}
              style={{
                marginLeft: '2%',
                flex: 1,
                color: Constants.primaryTheme,
              }}
            >
              To
            </div>
            <div style={{ flex: 1 }}>
              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {'<'}
                    </button>
                    <select
                      // BUG: value needs a value
                      value={this.getYear(date)}
                      onChange={({ target: { value } }) => {
                        changeYear(value)
                        // this.setState({ patientDob: value })
                      }}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      // BUG: 0 needs to be the correct index
                      value={months[this.getMonth(date)]}
                      onChange={({ target: { value } }) => {
                        changeMonth(months.indexOf(value))
                        // this.setState({ patientDob: value })
                      }}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {'>'}
                    </button>
                  </div>
                )}
                placeholder="From"
                selected={this.state.paymentToDate}
                onChange={(dates) => {
                  console.log('dates ', dates)
                  this.setState({ paymentToDate: dates })
                }}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Patient Name
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <Select
                placeholder="Patient Name"
                inputId="name"
                options={this.state.patientList}
                value={this.state.filterByPatient}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ filterByPatient: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              PCN
            </span>
            <div
              className={Styles.filterModalSelect}
              style={{ flex: 1, width: '100%' }}
            >
              <Select
                placeholder="Patient Name"
                inputId="name"
                options={this.state.patientPcnList}
                value={this.state.filterByPatientPcn}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ filterByPatientPcn: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Rendering Provider Name
            </span>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Provider Name"
                inputId="name"
                options={this.state.providerList}
                value={this.state.filterByProvider}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ filterByProvider: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Visit Type
            </span>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Visit Type"
                inputId="name"
                options={[
                  {
                    label: 'Telehealth - Video Visit',
                    value: 'Telehealth - Video Visit',
                  },
                  {
                    label: 'Telehealth - Phone Call',
                    value: 'Telehealth - Phone Call',
                  },
                  {
                    label: 'Telehealth - E-Consult',
                    value: 'Telehealth - E-Consult',
                  },
                  {
                    label: 'Telehealth- Asynchronous visit',
                    value: 'Telehealth- Asynchronous visit',
                  },
                ]}
                value={this.state.filterByVisitType}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ filterByVisitType: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          {/* <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Bill Type
            </span>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Bill Type"
                inputId="name"
                options={[
                  {
                    label: 'Insurance Pay',
                    value: 'Insurance Pay',
                  },
                  {
                    label: 'Cash Pay',
                    value: 'Cash Pay',
                  },
                ]}
                value={this.state.filterByBillType}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ filterByBillType: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div> */}

          <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Claim Status
            </span>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Claim Status"
                inputId="name"
                options={Metadata.BILLING_STATUS_OPTIONS}
                value={this.state.filterByClaimStatus}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ filterByClaimStatus: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          {/* <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Days Since Submission
            </span>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Days Since Submission"
                inputId="name"
                options={[
                  { label: '0-30', value: '0-30' },
                  { label: '31-60', value: '31-60' },
                  { label: '61-90', value: '61-90' },
                  { label: '90+', value: '90+' },
                ]}
                value={this.state.filterByDaysSinceSubmission}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ filterByDaysSinceSubmission: val })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div> */}

          {/* <div
            className={Styles.filterModalRow}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <span
              className={Styles.filterModalLabel}
              style={{
                whiteSpace: 'nowrap',
                color: Constants.primaryTheme,
                flex: 1,
              }}
            >
              Facility
            </span>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Facility Name"
                inputId="name"
                options={[]}
                value={null}
                isSearchable
                autoBlur
                onChange={(val) => {}}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div> */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center content horizontally
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => {
                this.setState({ filterModalOpen: false })
                this.getInsuranceClaims()
              }}
              className={GlobalStyles.button}
              disabled={false}
              style={{
                width: 250,
                // marginLeft: 10,
                backgroundColor: Constants.primaryTheme,
                textAlign: 'center',
              }}
              variant="primary"
              color={Constants.primaryTheme}
              backgroundColor={Constants.primaryTheme}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderCMS1500Modal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.setState({ cms1500ModalOpen: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Refund Amount
        </h4>
        <div className={Styles.row} style={{ padding: 20 }}>
          <p className={Styles.rowLabel}>Refund from remaining ${'-'}</p>
          {/* <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>User Name</p> */}
          <input
            placeholder={'Enter Refund Amount'}
            className={Styles.studyEntryInput}
            type="text"
            value={null}
            onChange={(e) => {}}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              console.log()
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Refund
          </Button>
        </div>
      </div>
    )

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          // onClick={() => this.onCMS1500Clicked(false)}
          onClick={() => this.setState({ cms1500ModalOpen: false })}
        >
          X
        </p>
        {/* <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Edit Patient Insurance
          </h4> */}
        {/* <CmsTab /> */}
      </div>
    )
  }

  renderRefundModal() {
    const { refund_amount, refundData } = this.state

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({
              refundData: {},
              stripeModalIsOpen: false,
              refund_amount: '',
            })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Refund Amount
        </h4>
        <div className={Styles.row} style={{ padding: 20 }}>
          <p className={Styles.rowLabel}>
            Refund from remaining ${refundData.amount_paid || '-'}
          </p>
          {/* <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>User Name</p> */}
          <input
            placeholder={'Enter Refund Amount'}
            className={Styles.studyEntryInput}
            type="text"
            value={refund_amount}
            onChange={(e) => this.setState({ refund_amount: e.target.value })}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              console.log(
                'CLICK ON REFUND: ',
                refundData.charge_id,
                refundData.transfer,
              )
              this.getStripeRefund(
                refundData.charge_id,
                refundData.transfer,
                refund_amount,
              )
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Refund
          </Button>
        </div>
      </div>
    )
  }

  // renderChargeModal() {
  //   const { charge_amount, appChargeData, chargeLoader } = this.state

  //   if (chargeLoader) {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           width: '100%',
  //           height: '100%',
  //         }}
  //       >
  //         <div className={GlobalStyles.loader} />
  //       </div>
  //     )
  //   }

  //   return (
  //     <div
  //       style={{
  //         height: '100%',
  //         width: '100%',
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <p
  //         className={Styles.closeModalBtn}
  //         onClick={() =>
  //           this.setState({
  //             stripeChargeModalIsOpen: false,
  //             charge_amount: '',
  //             appChargeData: {},
  //           })
  //         }
  //       >
  //         X
  //       </p>
  //       <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
  //         Charge Amount
  //       </h4>
  //       {/* <div className={Styles.row} style={{ padding: 20 }}>
  //         <p className={Styles.rowLabel}>
  //           Allowed Amount $
  //           {appChargeData?.allowed_amount?.replace(/[^0-9|.]/g, '') || '-'}
  //         </p>
  //       </div> */}
  //       <div className={Styles.row} style={{ padding: 20 }}>
  //         <p className={Styles.rowLabel}>
  //           Patient Due With Professional Discount $
  //         </p>
  //         {/* <div className={Styles.studyEntryRow}>
  //         <p className={Styles.studyEntryKey}>User Name</p> */}
  //         <input
  //           placeholder={'Enter Amount'}
  //           className={Styles.studyEntryInput}
  //           type="text"
  //           value={charge_amount}
  //           onChange={(e) => this.setState({ charge_amount: e.target.value })}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           width: '100%',
  //           justifyContent: 'center',
  //         }}
  //       >
  //         <Button
  //           onClick={() => {
  //             if (Number(charge_amount) > 500) {
  //               if (
  //                 window.confirm(
  //                   'Amount being charged is greater than $500. Are you sure you want to proceed?',
  //                 )
  //               ) {
  //                 this.setState({ chargeLoader: true })
  //                 this.getStripeCharge()
  //               }
  //             } else {
  //               this.setState({ chargeLoader: true })
  //               this.getStripeCharge()
  //             }
  //           }}
  //           className={GlobalStyles.button}
  //           style={{ width: 300, margin: '4%' }}
  //           variant="primary"
  //         >
  //           Charge
  //         </Button>
  //       </div>
  //     </div>
  //   )
  // }

  // renderCopayChargeModal() {
  //   const { copayChargeAmount, chargeLoader } = this.state

  //   if (chargeLoader) {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           width: '100%',
  //           height: '100%',
  //         }}
  //       >
  //         <div className={GlobalStyles.loader} />
  //       </div>
  //     )
  //   }

  //   return (
  //     <div
  //       style={{
  //         height: '100%',
  //         width: '100%',
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <p
  //         className={Styles.closeModalBtn}
  //         onClick={() =>
  //           this.setState({
  //             stripeChargeCopayIsOpen: false,
  //             copayChargeAmount: '',
  //           })
  //         }
  //       >
  //         X
  //       </p>
  //       <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
  //         Charge Copay Amount
  //       </h4>
  //       {/* <div className={Styles.row} style={{ padding: 20 }}>
  //         <p className={Styles.rowLabel}>
  //           Copay Amount $
  //           {appChargeData?.allowed_amount?.replace(/[^0-9|.]/g, '') || '-'}
  //         </p>
  //       </div> */}
  //       <div className={Styles.row} style={{ padding: 20 }}>
  //         <p className={Styles.rowLabel}>Copay Amount $</p>
  //         {/* <div className={Styles.studyEntryRow}>
  //         <p className={Styles.studyEntryKey}>User Name</p> */}
  //         <input
  //           placeholder={'Enter Amount'}
  //           className={Styles.studyEntryInput}
  //           type="text"
  //           value={copayChargeAmount}
  //           onChange={(e) =>
  //             this.setState({ copayChargeAmount: e.target.value })
  //           }
  //         />
  //       </div>
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           width: '100%',
  //           justifyContent: 'center',
  //         }}
  //       >
  //         <Button
  //           onClick={() => {
  //             this.setState({ chargeLoader: true })
  //             this.chargeCopayAmount()
  //           }}
  //           className={GlobalStyles.button}
  //           style={{ width: 300, margin: '4%' }}
  //           variant="primary"
  //         >
  //           Charge
  //         </Button>
  //       </div>
  //     </div>
  //   )
  // }

  handleInputChange = (cellInfo, editable_field, event) => {
    console.log('saving', cellInfo, editable_field, event)
    // Using set state callback as the updates are asynchronous
    this.setState(async (prevState) => {
      let data = [...prevState.claims]
      data[cellInfo.index][editable_field] = event.target.value
      let toSave = {}
      // Rather than replacing elements, it needs to warn the user about the issue here
      toSave[editable_field] = event.target.value?.replace(/[^0-9|.]/g, '')
      // If it has event_id its an appointment
      if (cellInfo?.original?.event_id) {
        await this.saveAppointmentData(data[cellInfo.index], toSave, cellInfo)
      } else {
        console.log('SAVE HERE careplan', data[cellInfo.index])
        await this.saveToCareplanData(data[cellInfo.index], toSave, cellInfo)
      }

      return { claims: data }
    })
  }

  onClickSearch() {
    console.log(
      'Searching with params: ',
      this.state.selectedClaimsProvider.value,
      this.state.selectedClaimsPatient.value,
      // this.state.selectedClaimsType.value,
      this.state.selectedClaimsDate,
    )
    // axios({
    //   method: 'get',
    //   url: `${Config.BACKEND_URL}insurance/claim`,
    //   headers: {
    //     Authorization: `JWT ${this.props.token}`,
    //   },
    //   params: {
    //     doctor_id: this.state.selectedClaimsProvider.value,
    //     patient_id: this.state.selectedClaimsPatient.value,
    //     claim_type: this.state.selectedClaimsType.value || null,
    //     date_of_service: this.state.selectedClaimsDate,
    //   },
    // }).then((res) => {
    //   console.log('SEARCH RESULTS: ', res.data)
    //   this.setState({ claims: res.data })
    // })

    let headersConfig = {
      Authorization: `JWT ${this.props.token}`,
    }
    let queryParams = {
      doctor_id: this.state.selectedClaimsProvider.value,
      patient_id: this.state.selectedClaimsPatient.value,
      claim_type: this.state.selectedClaimsType.value || null,
      date_of_service: this.state.selectedClaimsDate,
      // size: 10,
      // page: 0,
    }

    console.log('I think im getting the payments information')
    axios
      .all([
        axios({
          method: 'get',
          // url: `${Config.BACKEND_URL}insurance/claim/test`,
          url: `${Config.BACKEND_URL}insurance/claim`,
          // url: `localhost:15000/insurance/claim`,
          headers: headersConfig,
          params: queryParams,
        }),
        axios({
          method: 'get',
          url: `${Config.BACKEND_URL}insurance/careplan-claim`,
          headers: headersConfig,
          params: queryParams,
        }),
      ])
      .then(
        axios.spread((appointment, careplan) => {
          console.log('searched insurance claim data: ', appointment.data[0])
          console.log(
            'searched insurance careplan claim data: ',
            careplan.data[0],
          )
          this.setState({
            loading: false,
            claims: [...appointment.data, ...careplan.data],
            // claims: [...appointment.data],
          })
          // this.setState()
        }),
      )
      .catch((err) => {
        this.setState({ loading: false })
        toast.error('Could not fetch searched payments, please try again')
        console.log(
          'error when getting searched insurance events from backend',
          err,
        )
      })
  }

  handleSearchProviderChange = (val) => {
    this.setState({ selectedClaimsProvider: val })
  }

  handleSearchPatientChange = (val) => {
    this.setState({ selectedClaimsPatient: val })
  }

  renderEditable = (cellInfo, editable_field) => {
    console.log(cellInfo, editable_field)
    const cellValue = this.state.claims[cellInfo.index][editable_field]

    return (
      <input
        key={cellInfo.index}
        placeholder="Enter"
        name="input"
        type="text"
        onChange={this.handleInputChange.bind(null, cellInfo, editable_field)}
        value={cellValue}
      />
    )
  }

  claimsSearchBar = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          margin: '2vh',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
      >
        <div style={{ flex: 1, marginRight: '10px' }}>
          <Select
            placeholder={'Search Provider'}
            isMulti={false}
            options={this.state.providerSearchOptions}
            value={this.state.selectedClaimsProvider}
            onChange={(val) => {
              this.handleSearchProviderChange(val)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
            }}
          />
        </div>
        <div style={{ flex: 1, marginRight: '10px', zIndex: 2147483640 }}>
          <Select
            placeholder={'Search Patient'}
            isMulti={false}
            options={this.state.patientSearchOptions}
            value={this.state.selectedClaimsPatient}
            onChange={(val) => {
              this.handleSearchPatientChange(val)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 2147483640 }),
            }}
          />
        </div>
        <div style={{ flex: 1, marginRight: '10px' }}>
          <Select
            placeholder={'Search Claim Status'}
            isMulti={false}
            options={this.props.claimsTypeOptions}
            value={this.state.selectedClaimsType}
            onChange={this.handleSearchClaimsChange}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
            styles={{
              control: (base, state) => ({
                ...base,
                boxShadow: 'none',
                border: '1px solid #20A892',
                '&:hover': {
                  border: '1px solid #20A892',
                },
              }),
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <input
            placeholder={'Requested Response Due Date'}
            className={Styles.textInput}
            type="date"
            // min={moment(new Date()).format('YYYY-MM-DD')}
            value={this.state.selectedClaimsDate}
            onChange={(e) => {
              this.setState({ selectedClaimsDate: e.target.value })
            }}
          />
          {/* <Select
            placeholder={'Search Date of Service'}
            isMulti={false}
            options={this.props.insuranceOptions}
            value={this.state.selectedInsurance}
            onChange={this.handleInsuranceChange}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
            styles={{
              control: (base, state) => ({
                ...base,
                boxShadow: 'none',
                border: '1px solid #20A892',
                '&:hover': {
                  border: '1px solid #20A892',
                },
              }),
            }}
          /> */}
        </div>
        <Button
          onClick={this.onClickSearch}
          className={GlobalStyles.button}
          style={{
            marginLeft: '10px', // Add some spacing between the buttons
          }}
          variant="secondary" // Set the variant as needed
        >
          Search Filter
        </Button>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h4
          style={{
            color: Constants.primaryTheme,
            width: '100%',
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          Payments
        </h4>
        <div
          style={{
            width: 400,
            height: 40,
            border: '1px solid black',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '2vw',
          }}
        >
          <div
            onClick={() =>
              this.setState({ showInbox: true, selectedTab: 'Claims' }, () =>
                // this.getInbox(false),
                console.log('claims'),
              )
            }
            style={{
              borderRadius: 4,
              cursor: 'pointer',
              width: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                this.state.selectedTab === 'Claims'
                  ? Constants.primaryTheme
                  : 'white',
            }}
          >
            <p
              style={{
                color: this.state.selectedTab === 'Claims' ? 'white' : 'black',
              }}
            >
              Claims
            </p>
          </div>
          <div
            onClickCapture={() => {
              this.setState({ showInbox: false, selectedTab: 'Invoices' })
              this.getAppointmentInvoice()
            }}
            style={{
              borderRadius: 4,
              cursor: 'pointer',
              width: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                this.state.selectedTab === 'Invoices'
                  ? Constants.primaryTheme
                  : 'white',
            }}
          >
            <p
              style={{
                color:
                  this.state.selectedTab === 'Invoices' ? 'white' : 'black',
              }}
            >
              Invoices
            </p>
          </div>
          <div
            onClick={() =>
              this.setState({ showInbox: true, selectedTab: 'Payouts' }, () =>
                // this.getInbox(true),
                console.log('payouts'),
              )
            }
            style={{
              borderRadius: 4,
              cursor: 'pointer',
              width: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                this.state.selectedTab === 'Payouts'
                  ? Constants.primaryTheme
                  : 'white',
            }}
          >
            <p
              style={{
                color: this.state.selectedTab === 'Payouts' ? 'white' : 'black',
              }}
            >
              Payouts
            </p>
          </div>
          <div
            onClick={() =>
              this.setState(
                { showInbox: true, selectedTab: 'Fee Schedule' },
                () =>
                  // this.getInbox(true),
                  console.log('Fee Schedule'),
              )
            }
            style={{
              borderRadius: 4,
              cursor: 'pointer',
              width: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                this.state.selectedTab === 'Fee Schedule'
                  ? Constants.primaryTheme
                  : 'white',
            }}
          >
            <p
              style={{
                color:
                  this.state.selectedTab === 'Fee Schedule' ? 'white' : 'black',
              }}
            >
              Fee Schedule
            </p>
          </div>
        </div>
        {/* <div style={{ width: 300, marginLeft: '2vw', marginTop: '1%' }}>
          <Select
            placeholder={'Appointments'}
            isMulti={false}
            options={[
              // {
              //   label: 'Upcoming Appointments',
              //   value: 'Upcoming Appointments',
              // },
              {
                label: 'Completed Appointments',
                value: 'Completed Appointments',
              },
            ]}
            value={this.state.appointmentType}
            onChange={(val) =>
              this.setState({ appointmentType: val, page: 1 }, () =>
                this.getInsuranceClaims(),
              )
            }
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div> */}

        {/* <NotesModal
          modalIsOpen={this.state.modalAddNotesIsOpen}
          hideNotesModal={this.hideNotesModal}
          getUserCarePlan={() => this.getInsuranceClaims()}
          carePlanNotesData={this.state.selectedNotesFromVisit}
          comingFrom={'billing'}
          patientId={this.state.selectedNotesFromVisit?.patient_id}
          selectedPatient={this.state.selectedPatient}
        /> */}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.insuranceModalIsOpen}
          onRequestClose={() => this.onInsuranceEditClicked(false)}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderInsuranceModal()}
          {/* {this.renderCMS1500Modal()} */}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.insuranceEditModalIsOpen}
          onRequestClose={() => this.openInsurnaceModal(false)}
          style={insuranceEditModalStyles}
          contentLabel="Modal"
        >
          {this.renderInsuranceEditModal()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.paymentEmailModalIsOpen}
          onRequestClose={() => this.sendPaymentEmailModal(false)}
          style={paymentModalStyles}
          contentLabel="PaymentEmailModal"
        >
          {this.renderPaymentEmailModal()}
        </Modal>

        {/* <Modal
          ariaHideApp={false}
          isOpen={this.state.taskModalIsOpen}
          onRequestClose={() => this.onTaskModalClicked(false)}
          style={taskModalStyles}
          contentLabel="TaskModal"
        >
          {this.renderTaskModal()}
        </Modal> */}

        <TaskModal
          supportData={this.state.modalTaskData} // All data related to the task
          modalIsOpen={this.state.taskModalIsOpen}
          hideTaskModal={() => {
            this.setState({ taskModalIsOpen: false })
          }}
          getUserTask={this.getUserTask} // This is just a callback to get the updated task data once task is created
          eventId={this.state.taskModalEventID}
          patientId={this.state.selectedTaskModalPatientId}
          dateOfService={this.state.selectedTaskModalDateOfService}
        />

        <Modal
          ariaHideApp={false}
          isOpen={this.state.filterModalOpen}
          onRequestClose={() => this.onFilterModalClicked(false)}
          style={filterModalStyles}
          contentLabel="FilterModal"
        >
          {this.renderFilterModal()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.editModalIsOpen}
          onRequestClose={() => this.onEditModalClicked(false)}
          style={editModalStyles}
          contentLabel="EditModal"
        >
          {/* {this.renderEditModal()} */}
          <PaymentEditModal
            totalChargesBilled={this.state.totalChargesBilled}
            allowedAmount={this.state.allowedAmount}
            adjustmentAmount={this.state.adjustmentAmount}
            paidAmount={this.state.paidAmount}
            checkNumber={this.state.checkNumber}
            claimNumber={this.state.claimNumber}
            denialReason={this.state.denialReason}
            denialReasons={this.state.denialReasons}
            payerPhoneNumber={this.state.payerPhoneNumber}
            claimStatus={this.state.claimStatus}
            writeOff={this.state.writeOff}
            writeOffAmount={this.state.writeOffAmount}
            writeOffReason={this.state.writeOffReason}
            ifCareplanClaim={this.state.ifCareplanClaim}
            dueAmount={this.state.dueAmount}
            editModalDataLoaded={this.state.editModalDataLoaded}
            editRowEventId={this.state.editRowEventId}
            // Functions
            closeEditModal={() => {
              console.log('Closing edit modal')
              this.setState({ editModalIsOpen: false })
              // TODO: side modal needs to be brought back to fore front
            }}
            updatePaymentData={() => {
              this.setState({ editModalIsOpen: false })
              this.getInsuranceClaims()
            }}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.chargeModalIsOpen}
          onRequestClose={() => this.onChargeModalClicked(false)}
          style={chargeModalStyles}
          contentLabel="ChargeModal"
        >
          {/* {this.renderEditModal()} */}
          <PaymentChargeModal
            totalChargesBilled={this.state.totalChargesBilled}
            allowedAmount={this.state.allowedAmount}
            adjustmentAmount={this.state.adjustmentAmount}
            paidAmount={this.state.paidAmount}
            checkNumber={this.state.checkNumber}
            claimNumber={this.state.claimNumber}
            denialReason={this.state.denialReason}
            denialReasons={this.state.denialReasons}
            payerPhoneNumber={this.state.payerPhoneNumber}
            claimStatus={this.state.claimStatus}
            writeOff={this.state.writeOff}
            writeOffAmount={this.state.writeOffAmount}
            writeOffReason={this.state.writeOffReason}
            ifCareplanClaim={this.state.ifCareplanClaim}
            dueAmount={this.state.dueAmount}
            editModalDataLoaded={this.state.editModalDataLoaded}
            copayCharged={this.state.copayCharged}
            editRowEventId={this.state.editRowEventId}
            appChargeData={this.state.appChargeData}
            // Functions
            closeChargeModal={() => {
              console.log('Closing charge modal')
              this.setState({ chargeModalIsOpen: false })
              // TODO: side modal needs to be brought back to fore front
            }}
            updatePaymentData={() => {
              this.setState({ editModalIsOpen: false })
              this.getInsuranceClaims()
            }}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showCareplanNotesModal}
          onRequestClose={() =>
            this.setState({ showCareplanNotesModal: false })
          }
          style={{
            overlay: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.44)',
              zIndex: 2147483647,
            },
            content: {
              zIndex: 2147483647,
              width: '95%',
              height: '90%',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
          contentLabel="Modal"
        >
          <h3>Careplan Note</h3>

          <Button
            onClick={() => {
              let postData = {
                htmlContent: this.state.pdfData,
              }
              let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
              //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
              axios({
                method: 'post',
                headers: {
                  Authorization: 'JWT ' + this.props.token,
                  x_firebase_id: this.props.adminId,
                },
                url: url,
                data: postData,
              })
                .then(({ data }) => {
                  // Insert a link that allows the user to download the PDF file
                  let initials = this.state.fullName
                  let dos = this.state.dateOfService
                  var link = document.createElement('a')
                  link.innerHTML = 'Download PDF file'
                  link.download = `${initials}_${dos}.pdf`
                  link.href =
                    'data:application/octet-stream;base64,' + data.result
                  // document.body.appendChild(link);
                  link.click()
                  console.log('downloaded')
                  toast.success('pdf downloaded')
                })
                .catch((err) => {
                  console.log(
                    'error when getting saving provider goals data',
                    err,
                  )
                  toast.error('Could not download Notes, please try again')
                })
            }}
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 120,
              top: 10,
            }}
            variant="primary"
          >
            Download
          </Button>

          <iframe
            srcDoc={this.state.pdfData}
            title="Provider Referral"
            style={{ height: '100%', width: '100%' }}
          />
        </Modal>

        {/* <ErrorBoundary>
          <Modal
            ariaHideApp={false}
            isOpen={this.state.cms1500ModalOpen}
            // onRequestClose={() => this.onCMS1500Clicked()}
            onRequestClose={() => this.setState({ cms1500ModalOpen: false })}
            style={cms1500ModalStyles}
            contentLabel="Modal1"
          >
            {/* {this.renderCMS1501Modal()}
          </Modal>
        </ErrorBoundary> */}

        {/* <BSModal
          ariaHideApp={false}
          show={this.state.cms1500ModalOpen}
          onHide={() =>
            this.setState({
              cms1500ModalOpen: false,
            })
          }
          // style={stripeModalStyles}
          dialogClassName="modal-90w"
          size="lg"
          contentLabel="Slot Modal"
          centered
        >
          {/* {this.renderCMS1500Modal()}
          {this.renderRefundModal()}
        </BSModal> */}

        <BSModal
          // ariaHideApp={false}
          show={this.state.stripeModalIsOpen}
          onHide={() =>
            this.setState({
              refundData: {},
              stripeModalIsOpen: false,
              refund_amount: '',
              charge_amount: '',
              appChargeData: {},
            })
          }
          // style={stripeModalStyles}
          size="lg"
          contentLabel="Slot Modal"
          centered
        >
          {this.renderRefundModal()}
        </BSModal>

        {/* <BSModal
          // ariaHideApp={false}
          show={this.state.stripeChargeModalIsOpen}
          onHide={() =>
            this.setState({
              refundData: {},
              stripeChargeModalIsOpen: false,
              refund_amount: '',
              charge_amount: '',
              appChargeData: {},
            })
          }
          // style={stripeModalStyles}
          size="lg"
          contentLabel="Modal"
          centered
        >
          {this.renderChargeModal()}
        </BSModal> */}

        {/* <BSModal
          // ariaHideApp={false}
          show={this.state.stripeChargeCopayIsOpen}
          onHide={() =>
            this.setState({
              copayData: {},
              stripeChargeModalIsOpen: false,
              copayChargeAmount: '',
              appChargeData: {},
            })
          }
          // style={stripeModalStyles}
          size="lg"
          contentLabel="Modal"
          centered
        >
          {this.renderCopayChargeModal()}
        </BSModal> */}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showEraModal}
          onRequestClose={() => {
            this.setState({ showEraModal: false, eraPdfViewData: '' })
          }}
          style={customEraModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${this.state.eraPdfViewData}`}
          />
        </Modal>

        {(this.state.showModal || this.state.showNotesModal) && (
          <Modal
            ariaHideApp={false}
            isOpen={this.state.showModal || this.state.showNotesModal}
            onRequestClose={() =>
              this.setState({ showModal: false, showNotesModal: false })
            }
            style={customChangeModalStyles}
            contentLabel="Modal"
          >
            <h3>
              {this.state.showModal
                ? `Superbill Template`
                : `Appointment Notes`}
            </h3>
            <Button
              onClick={() => {
                let postData = {
                  htmlContent: this.state.pdfData,
                }
                let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
                //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
                axios({
                  method: 'post',
                  headers: {
                    Authorization: 'JWT ' + this.props?.token,
                    x_firebase_id: this.props?.adminId,
                  },
                  url: url,
                  data: postData,
                })
                  .then(({ data }) => {
                    // Insert a link that allows the user to download the PDF file
                    var link = document.createElement('a')
                    link.innerHTML = 'Download PDF file'
                    link.download = this.state.showModal
                      ? `Superbill.pdf`
                      : `PatientNote.pdf`
                    link.href =
                      'data:application/octet-stream;base64,' + data.result
                    // document.body.appendChild(link);
                    link.click()
                    console.log('downloaded')
                    toast.success('Document downloaded')
                  })
                  .catch((err) => {
                    console.log(
                      'error when getting saving provider goals data',
                      err,
                    )
                    toast.error('Could not download Notes, please try again')
                  })
              }}
              className={GlobalStyles.button}
              style={{
                width: 100,
                position: 'absolute',
                right: 120,
                top: 10,
              }}
              variant="primary"
            >
              Download
            </Button>
            <iframe
              srcDoc={this.state.pdfData}
              title="Superbill Template"
              style={{ height: '100%', width: '100%' }}
            />
          </Modal>
        )}

        <div
          className={GlobalStyles.contentWrapper}
          style={{
            flex: 1,
            visibility:
              this.state.insuranceModalIsOpen ||
              this.state.showModal ||
              this.state.showNotesModal ||
              this.state.modalAddNotesIsOpen
                ? 'hidden'
                : 'visible',
          }}
        >
          {this.state.selectedTab === 'Claims' ? (
            // Here is the claims table
            <>
              {/* {this.claimsSearchBar()} */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '2%',
                  width: '100%',
                }}
              >
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    onClick={() => {
                      this.setState({ filterModalOpen: true })
                    }}
                    className={GlobalStyles.button}
                    style={{
                      // width: 100,
                      // marginLeft: 'auto',
                      marginRight: '10px',
                      // marginRight: 'auto',
                      // marginLeft: 'auto',
                    }}
                    variant="primary"
                  >
                    <FontAwesomeIcon
                      icon={faFilter}
                      style={{ marginRight: 10 }}
                    />
                  </Button>
                  <Button
                    onClick={this.download}
                    className={GlobalStyles.button}
                    style={{
                      // width: 100,
                      marginLeft: 'auto',
                    }}
                    variant="primary"
                  >
                    Download CSV
                  </Button>
                </div>
              </div>
              <div>
                <CSVLink
                  data={this.state.dataToDownload}
                  filename="data.csv"
                  className="hidden"
                  ref={(r) => (this.csvLink = r)}
                  target="_blank"
                />
              </div>
              <PaginationTable
                columns={claimsColumns({
                  providersList: this.state.providersList,
                  patientList: this.state.patientList,
                  generateSuperBillPdf: this.generateSuperBillPdf,
                  generateNotesPdf: this.generateNotesPdfNew,
                  navigatetoPatientProfile: this.navigatetoPatientProfile,
                  onPatientNavLinkClicked: this.onPatientNavLinkClicked,
                  saveAppointmentData: this.saveAppointmentData,
                  saveToCareplanData: this.saveToCareplanData,
                  supervisor: this.props?.mongoUser?.supervisor,
                  openNotesEditModal: this.openNotesEditModal,
                  careplanNotesModal: this.careplanNotesModal,
                  onInsuranceEditClicked: this.onInsuranceEditClicked,
                  renderEditable: this.renderEditable,
                  handleInputChange: this.handleInputChange,
                  getStripeChargeUrl: this.getStripeChargeUrl,
                  getStripeRefund: this.getStripeRefund,
                  openStripeRefundModal: this.openStripeRefundModal,
                  // openStripeChargeModal: this.openStripeChargeModal,
                  navigateToCms1500: this.navigateToCms1500.bind(this),
                  openStripeCopayChargeModal: this.openStripeCopayChargeModal,
                  onTaskModalClicked: this.onTaskModalClicked,
                  onEditModalClicked: this.onEditModalClicked,
                  onChargeModalClicked: this.onChargeModalClicked,
                  sendPaymentEmailModal: this.sendPaymentEmailModal,
                  openInsurnaceModal: this.openInsurnaceModal,
                  showEraData: this.showEraData,
                })}
                data={this.state.claims}
                pageCnt={1000}
                pageIdx={this.state.page}
                parentCallback={this.handleTableCallBack}
                onClickCallback={this.clearAllFilters}
              />
            </>
          ) : this.state.selectedTab === 'Invoices' ? (
            <Table
              columns={invoicesColumns({
                getStripeChargeUrl: this.getStripeChargeUrl,
                getStripeRefund: this.getStripeRefund,
              })}
              data={this.state.invoices}
            />
          ) : this.state.selectedTab === 'Payouts' ? (
            <Table
              columns={payoutsColumns(
                this.state.providersList,
                this.state.patientList,
              )}
              data={this.state.payouts}
            />
          ) : this.state.selectedTab === 'Fee Schedule' ? (
            <FeeSchedule />
          ) : (
            <Table
              columns={claimsColumns(
                this.state.providersList,
                this.state.patientList,
              )}
              data={this.state.claims}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  stopTimer,
  resetTimer,
  startTimer,
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
  toggleSidebar,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  updatePatientData,
  updateSideNavBar,
  addPatientLP,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTab)
