import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import { addMongoUser, stopTimer } from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'
import FileViewer from '../../components/pdf/FileViewer'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const Identification = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)

  const mongoUser = useSelector((state) => state.userReducer?.providerData)
  const userType = useSelector((state) => state.userReducer?.userType)
  const adminId = useSelector((state) => state.userReducer?.adminId)

  const [loading, setLoading] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [docFile, setDocFile] = useState('')

  //IDENTIFICATION DATA
  const [npi, setNpi] = useState(null)
  const [groupNpi, setGroupNpi] = useState(null)
  const [tax, setTax] = useState(null)
  const [caqh, setCaqh] = useState('')
  const [ssn, setSsn] = useState('')
  const [accessData, setAccessData] = useState(false)
  const [dea, setDea] = useState('')
  const [deaExpiration, setDeaExpiration] = useState('')
  const [ptan, setPtan] = useState('')
  const [cds, setCds] = useState('')
  const [fcvs, setFcvs] = useState('')
  const [licence, setLicense] = useState(null)
  const [medicareId, setMedicareId] = useState('')
  const [medicaidId, setMedicaidId] = useState('')
  const [attest, setAttest] = useState(false)
  const [authorizeAila, setAuthorizeAila] = useState(false)

  useEffect(() => {
    setLoading(true)
    getProviderData()
    // setLoading(false)
  }, [])

  const getProviderData = () => {
    setLoading(true)
    // console.log('mongo user::', adminId, userType, mongoUser)

    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: mongoUser?.firebase_id,
        Authorization: `JWT ${token}`,
      },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)

        setNpi(data?.npi || '')
        setGroupNpi(data?.groupNpi || '')
        setTax(data?.tax || null)
        setCaqh(data?.caqh || '')
        setSsn(data?.ssn || '')
        setAccessData(data?.accessData || '')
        setDea(data?.dea || '')
        setDeaExpiration(data?.deaExpiration || null)
        setPtan(data?.ptan || '')
        setCds(data?.cds || '')
        setFcvs(data?.fcvs || '')
        setMedicaidId(data?.medicaidId || '')
        setMedicareId(data?.medicareId || '')
        setLicense({
          front: data?.licence?.front || '',
          front_type: data?.licence?.front_type || '',
          back: data?.licence?.back || '',
          back_type: data?.licence?.back_type || '',
        })
        setAttest(data?.attest || '')

        setLoading(false)
        // dispatch(addMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting doctor mongo user Identification', err)
      })
  }

  const onSavePressed = () => {
    if (!attest) {
      toast.warning('Please attest')
      return
    }
    let returnData = {
      npi: npi,
      groupNpi: groupNpi,
      caqh: caqh,
      ssn: ssn,
      accessData: accessData,
      dea: dea,
      ptan: ptan,
      tax: tax,
      cds: cds,
      fcvs: fcvs,
      licence: licence,
      medicaidId: medicaidId,
      medicareId: medicareId,
      attest: attest,
    }
    console.log(returnData)
    props.parentCallback(returnData)
  }

  const saveImageToFirebase = (uploadFile) => {
    setLoading(true)

    // licensedStates.forEach((license, index) => {
    // if (license.image) {
    const file = uploadFile
    // let temp = [...licensedStates]

    const storageRef = firebase.storage().ref()
    const uploadTask = storageRef
      .child(`providers/${mongoUser.firebase_id}/CDS_${file.name}`)
      .put(file)
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`${progress}%`)
      },
      function (err) {
        // Handle unsuccessful uploads
        console.log('error when uploading image', err)
        setLoading(false)
        // self.saveUserToBackend(user)
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log(downloadURL)
          setCds(downloadURL)
          setLoading(false)
        })
      },
    )
  }

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    console.log(event)
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])
      // if (event?.target?.files[0].type !== 'application/pdf') {
      //   toast.warning('Please upload PDF files')
      //   return
      // }
      //SAVE FILE TO FIREBASE STORAGE
      saveImageToFirebase(event?.target?.files[0])
    }
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: '60vh', // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const verifyNpiPressed = () => {
    if (!npi.number || npi.number.length !== 10) {
      // npi number must be exactly 10 digits
      toast.warning(
        'NPI number must be unique 10-digit National Provider Identifier assigned to the provider',
      )
      //   this.setState({
      //     error: true,
      //     modalErrorString:
      //       'NPI number must be unique 10-digit National Provider Identifier assigned to the provider',
      //   })
      return
    }

    // use the NPI registry API to verify the provided NPI
    // const { mongoUser, token } = this.props
    const url = `${Config.BACKEND_URL}provider/npi-verification`
    axios({
      method: 'post',
      headers: { Authorization: `JWT ${token}` },
      url,
      data: { number: npi.number, firebase_id: mongoUser.firebase_id },
    })
      .then(({ data }) => {
        console.log('got the response', data)
        if (data.npiVerification) {
          toast.success('Successfully verified NPI. You are all set.')
          //   this.props.addMongoUser(data.user)
          dispatch(addMongoUser(data.user))
          //   this.setState({
          //     modalLoading: false,
          //     modalError: false,
          //     modalIsOpen: false,
          //   })
        } else {
          toast.error('Invalid NPI number')
          //   this.setState({
          //     modalLoading: false,
          //     modalError: true,
          //     modalErrorString: 'Invalid NPI number. Could not verify.',
          //   })
        }
      })
      .catch((err) => {
        console.log('error when verifying NPI', err)
        toast.warning(
          'The verification system is down. Please try again later. If the problem persists, contact support.',
        )
        // this.setState({
        //   modalLoading: false,
        //   modalError: true,
        //   modalErrorString:
        //     'The verification system is down. Please try again later. If the problem persists, contact support. ',
        // })
      })
  }

  const onImageChange = (e, index) => {
    const file = e.target.files[0] || false
    let temp = { ...licence }
    setLoading(true)

    console.log(file, index)
    if (
      file &&
      window.confirm(`Are you sure you want to upload ${file.name}?`)
    ) {
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef
        .child(`providers/${mongoUser.firebase_id}/license_${file.name}`)
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          setLoading(false)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL)

            if (index === 'front') {
              temp.front = downloadURL
              temp.front_type = file.type
            } else {
              temp.back = downloadURL
              temp.back_type = file.type
            }
            setLicense(temp)
            setLoading(false)
          })
        },
      )

      // temp[index]['location'] = image
      // temp[index]['image'] = file
      // setLicensedStates(temp)
      console.log('confirmed upload - license', temp)
    } else {
      setLoading(false)
      console.log('ignored')
    }
  }

  const renderContent = () => {
    if (loading) return renderLoader()
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          padding: '1% 5%',
        }}
      >
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>NPI Number</p>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <input
              placeholder="Enter NPI Number"
              className={Styles.textInput}
              style={{ width: '80%' }}
              type="text"
              value={npi?.number}
              onChange={(e) => {
                let temp = { ...npi }
                temp.number = e.target.value
                setNpi(temp)
              }}
            />

            <Button
              onClick={() => verifyNpiPressed()}
              className={GlobalStyles.button}
              disabled={loading}
              style={{ width: 120, marginLeft: 10 }}
              variant="primary"
            >
              Verify
            </Button>
          </div>
        </div>

        {npi && npi?.verified ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className={Styles.rowLabel}></p>

            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 5,
                marginLeft: '-4%',
              }}
            />
            <p style={{ color: Constants.primaryTheme }}>Verified</p>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: 'red', fontSize: 20, marginRight: 5 }}
            />
            <p style={{ color: 'red' }}>Not Verified</p>
          </div>
        )}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Group NPI Number</p>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <input
              placeholder="Enter Group NPI"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={groupNpi?.number}
              onChange={(e) => {
                let temp = { ...groupNpi }
                temp.number = e.target.value
                setGroupNpi(temp)
              }}
            />
          </div>
        </div>

        {/* PTAN number */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>PTAN</p>
          <input
            placeholder="Enter Provider Transaction Access Number"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={ptan}
            onChange={(e) => {
              setPtan(e.target.value)
            }}
          />
        </div>

        {/* TAX ID number */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Rendering Tax ID</p>
          <input
            placeholder="Enter Tax ID"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={tax?.renderingTax}
            onChange={(e) => {
              let temp = { ...tax }
              temp.renderingTax = e.target.value
              setTax(temp)
            }}
          />
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Group Tax ID</p>
          <input
            placeholder="Enter Group Tax ID"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={tax?.groupTax}
            onChange={(e) => {
              let temp = { ...tax }
              temp.groupTax = e.target.value
              setTax(temp)
            }}
          />
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>CAQH Number</p>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <input
              placeholder="Enter CAQH Number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={caqh}
              onChange={(e) => {
                setCaqh(e.target.value)
              }}
            />

            <p style={{ color: Constants.primaryThemeDark, marginTop: '1%' }}>
              <a
                href="https://proview.caqh.org/PR/Registration/SelfRegistration"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: Constants.darkGray,
                  textDecoration: 'underline',
                }}
              >
                Set up CAQH number here if you don’t have one
              </a>
            </p>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: '1%',
                justifyContent: 'flex-start',
              }}
            >
              <label className={Styles.checkContainer}>
                <p
                  style={{
                    color: Constants.darkGray,
                    width: '100%',
                    overflowWrap: 'break-word',
                    display: 'block',
                  }}
                >
                  Yes, I authorize the release of my data to any organization
                  that requests access
                </p>
                <input
                  type="checkbox"
                  checked={accessData}
                  onClick={() => {
                    setAccessData(!accessData)
                  }}
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>SSN Number</p>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <input
              placeholder="Enter SSN Number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={ssn}
              onChange={(e) => {
                setSsn(e.target.value)
              }}
            />
          </div>
        </div>

        {/* DEA license number */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>DEA Number</p>
          <input
            placeholder="Enter DEA License Number"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={dea}
            onChange={(e) => {
              setDea(e.target.value)
            }}
          />
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>DEA Expiration Date</p>
          <input
            style={{ width: '100%' }}
            type="date"
            value={deaExpiration}
            onChange={(e) => {
              setDeaExpiration(e.target.value)
            }}
          />
        </div>

        {/* Upload CDS (Controlled and Dangerous Substances) certificate */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            CDS (Controlled and Dangerous Substances) Certificate
          </p>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {cds && (
              <FontAwesomeIcon
                onClick={() => {
                  setDocFile(cds)
                  setOpenDoc(true)
                }}
                icon={faEye}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  width: '10%',
                  cursor: 'pointer',
                }}
              />
            )}
            <label
              htmlFor="proof_files"
              style={{
                textDecoration: 'underline',
                color: Constants.primaryTheme,
                cursor: 'pointer',
              }}
            >
              Upload CDS certificate
            </label>
            <input
              id="proof_files"
              accept="application/pdf"
              style={{ display: 'none' }}
              type="file"
              onChange={onFileChange}
            />
            {/* <input
              placeholder="Slect File to be Uploaded"
              // className={Styles.textInput}
              style={{ width: '100%' }}
              type="file"
              onChange={onFileChange}
            /> */}
          </div>
        </div>

        {/* Federation number */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            Federation Credentials Verification Service (FCVS)
          </p>
          <input
            placeholder="Enter FCVS"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={fcvs}
            onChange={(e) => {
              setFcvs(e.target.value)
            }}
          />
        </div>

        {/* Medicare number */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Medicare ID Number</p>
          <input
            placeholder="Enter Medicare ID Number"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={medicareId}
            onChange={(e) => {
              setMedicareId(e.target.value)
            }}
          />
        </div>

        {/* Medicaid number */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Medicaid ID Number </p>
          <input
            placeholder="Enter Medicaid ID Number"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={medicaidId}
            onChange={(e) => {
              setMedicaidId(e.target.value)
            }}
          />
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>License Photo</p>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {licence?.front && (
                <img
                  src={licence?.front}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              <label
                htmlFor={`front`}
                style={{
                  textDecoration: 'underline',
                  color: Constants.primaryTheme,
                  cursor: 'pointer',
                  width: '40%',
                  margin: '0% 0% 0% 5%',
                }}
                disabled={loading}
              >
                Upload Front
              </label>
              <input
                id={`front`}
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  console.log('upload FRONT licence clicked')
                  onImageChange(e, 'front')
                }}
                // onChange={() => {
                // setUploadingProof(true)
                // onFileChange()
                // }}
              />
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {licence?.back && (
                <img
                  src={licence?.back}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              <label
                htmlFor={`back`}
                style={{
                  textDecoration: 'underline',
                  color: Constants.primaryTheme,
                  cursor: 'pointer',
                  width: '40%',
                  margin: '0% 0% 0% 5%',
                }}
              >
                Upload Back
              </label>
              <input
                id={`back`}
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  console.log('upload BACK licence clicked')
                  onImageChange(e, 'back')
                }}
                disabled={loading}
                // onChange={() => {
                // setUploadingProof(true)
                // onFileChange()
                // }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: '20px',
            justifyContent: 'flex-start',
          }}
        >
          <label className={Styles.checkContainer}>
            <p
              style={{
                color: Constants.darkGray,
                width: '100%',
                overflowWrap: 'break-word',
                display: 'block',
              }}
            >
              I attest that:
              <br />
              1. There are no limitations or inabilities that affect my ability
              to perform any of the position’s essential functions, with or
              without accommodation;
              <br />
              2. I have no history of loss of license or felony conviction;
              <br />
              3. I have history of loss or limitation of privileges or
              disciplinary activity;
              <br />
              4. I am not using illegal drugs or substances
              <br />
              5. The information is accurate and complete
            </p>
            <input
              type="checkbox"
              checked={attest}
              onClick={() => {
                setAttest(!attest)
              }}
            />
            <span className={Styles.checkmark}></span>
          </label>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: '20px',
            justifyContent: 'flex-start',
          }}
        >
          <label className={Styles.checkContainer}>
            <p
              style={{
                color: Constants.darkGray,
                width: '100%',
                overflowWrap: 'break-word',
                display: 'block',
              }}
            >
              I authorize Aila Health’s Credentialing Department to manage my
              CAQH account on my behalf, including but not limited to profile
              data updates, document uploads, re-attestations, and authorization
              settings. I will update credentialing@ailahealth.com immediately
              if I make any changes to my login information, and Aila Health
              agrees to never change my login credentials to the CAQH site, so
              that I may log in anytime as myself to check or validate the
              information being provided. I understand that I am responsible for
              proactively reporting any changes to my information, including my
              disclosure question responses and explanations as applicable, to
              credentialing@ailahealth.com so that they may update my CAQH
              profile as needed or required. I also understand that I will be
              asked to periodically review my entire CAQH profile, including my
              disclosure question responses, for accuracy and completeness. I am
              responsible for notifying the credentialing team of any necessary
              corrections and affirm that re-attestations completed by them on
              my behalf are done so with my knowledge and approval. I have the
              right to revoke this authorization at any time, for any reason, by
              providing email notification to credentialing@ailahealth.com.*
            </p>
            <input
              type="checkbox"
              checked={attest}
              onClick={() => {
                // setAttest(!attest)
                setAuthorizeAila(!authorizeAila)
              }}
            />
            <span className={Styles.checkmark}></span>
          </label>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={onSavePressed}
            className={GlobalStyles.button}
            disabled={loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {loading ? 'Loading....' : 'Save'}
          </Button>
          {/* {error && (
            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
              {errorString}
            </p>
          )} */}
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={openDoc}
          onRequestClose={() => {
            setOpenDoc(false)
            setDocFile('')
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <FileViewer fileType="pdf" fileName={docFile} />
          {/* <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${docFile}`}
          /> */}
        </Modal>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default Identification
