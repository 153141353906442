import React, { Component, useState } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import Constants from '../../values'
import styles from './styles/admin.module.scss'
import * as Metadata from '../../metadata.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import TimePicker from 'react-time-picker'
import {
  faUsers,
  faTrash,
  faEdit,
  faMailBulk,
  faUsersCog,
  faFileExport,
  faFileMedical,
  faListUl,
  faFileContract,
  faCalendarAlt,
  faHeadphones,
  faIdCard,
  faCashRegister,
  faFileExcel,
  faSearch,
  faClinicMedical,
  faUserFriends,
  faBusinessTime,
  faLaptopMedical,
  // faHandHoldingDollar,
  faListAlt,
  faUserMd,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Config from '../../config'
import { toast, ToastContainer } from 'react-toastify'
import metadata from '../../metadata.js'
import {
  addMongoUser,
  fetchDoctorInviteList,
  addDoctorInvites,
  fetchPatientsList,
  stopTimer,
  toggleSidebar,
  resetTimer,
} from '../../redux/actions'
import moment from 'moment'
import Select from 'react-select'
import Header from '../../components/Header'
import ReactTooltip from 'react-tooltip'
import Switch from 'react-switch'
import DatePicker from '../../components/DatePicker'
import {
  firebase,
  auth,
  googleProvider,
  appleProvider,
  analytics,
} from '../../services/firebase'
import MyCalendar from '../../components/appointment/MyCalendar'
import Communications from './Communications'
import PracticeInfo from './PracticeInfo'
import PaymentTab from './PaymentTab'
// import CmsTab from './CmsTab1.js'
import CmsTab from './CmsTab.js'
import ProviderRoster from './ProviderRoster'
import GroupCredentialing from './GroupCredentialing'
import ProviderReferrals from './ProviderReferrals'
import TimeSpent from './TimeSpent'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import zipcodes from 'zipcodes'
import QualityManagement from './QualityManagement'
import RosterTable from '../Roster/RosterTable'
import PatientAssistanceFormTable from './PatientAssistanceFormTable'
import { ErrorBoundary } from 'react-error-boundary'
import ProviderInsights from './ProviderInsights.js'
import ArGraph from './ArGraph.js'
import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const axios = require('axios')
const MODAL_ERROR_NAME = 'Please Enter both first name and last name'
const MODAL_ERROR_GENDER = 'Please select gender'
const MODAL_ERROR_EMAIL = 'Please Enter a valid email address'
const MODAL_ERROR_PROGRAM = 'Please select a program type'
const MODAL_ERROR_COHORT = 'Please select at least one cohort'

let windowHeight = window.innerHeight
const customAddCohortModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: windowHeight / 1.4,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customInvitePatientsModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customSurveyModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '70%',
    height: windowHeight / 1.1,
    top: '50%',
    left: '60%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const SCHEDULE_APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const SURVEY_QUESTION_TYPES = [
  { label: 'Free Text', value: 'free' },
  { label: 'Rate on a scale', value: 'range' },
  { label: 'Multiple Choice', value: 'multiple' },
  { label: 'Single Choice', value: 'single' },
]

const payerConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'payer',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigPayer = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: payerConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      payer_name: { raw: {} },
      payerid: { raw: {} },
    },
  },
}

class Admin extends Component {
  constructor(props) {
    super(props)
    console.log('Admin props:', props)
    this.state = {
      patientList: null,
      loading: false,
      modalIsOpen: false,
      cohortModalError: false,
      cohortModalErrorString: '',
      renderAcceptedList: false,
      doctorInvites: null,
      shortDynamicLink: null,
      modalPatientRows: null,
      modalError: false,
      modalErrorString: '',
      patientModalType: null,
      cohortOptions: [],
      patientCohortList: [],
      batchCohortList: null,
      surveyError: '',
      editSurveyError: '',
      batchPatientList: null,
      batchPatientOptions: null,
      selectedTab: props?.location?.state
        ? props?.location?.state
        : props.default_page,
      teamModalFName: '',
      teamModalLName: '',
      teamModalEmail: '',
      teamModalType: null,
      teamModalError: false,
      teamModalErrorString: '',
      teamModalLoading: false,
      teamInvitesLoading: false,
      surveyListLoading: false,
      viewSurveyLoading: true,
      editSurveyLoading: true,
      teamInvites: null,
      renderTeamAcceptedList: false,
      teamInviteTabIndex: 1,
      surveyList: [],
      surveyTitle: '',
      surveyDesc: '',
      startDate: '',
      endDate: '',
      cadence: '',
      surveyCohortList: [],
      filterPatientInvite: { label: 'Unscheduled', value: 'unscheduled' },
      patientInviteList: [],
      surveyQuestions: [
        { question: '', options: ['', ''], range: [], type: null, error: '' },
      ],
      editSurvey: '',
      editSurveyChecked: '',
      editSurveyTitle: '',
      editSurveyDesc: '',
      editSurveyCadence: '',
      editSurveyCohortList: [],
      editSurveyQuestions: [
        { question: '', options: ['', ''], range: [], type: null, error: '' },
      ],
      editSurveyStartDate: '',
      editSurveyEndDate: '',
      editSurveyStatus: '',
      survey: {},
      surveyDetails: {},
      surveyAnswers: [],
      surveyPatientList: [],
      questionsElements: '',
      is_dateSubmitted_enabled: true,
      dateList: [],
      patientSelected: '',
      surveyResultDate: null,
      eventTitle: '',
      eventDesc: '',
      eventStartDate: '',
      eventDuration: '',
      eventType: '',
      editError: '',
      eventLoading: false,
      eventRecurring: '',
      circleEventData: [],
      //INSURANCE SEARCH DATA
      showScheduleModal: false,
      showSearchList: false,
      addInsurance: false,
      searchName: '',
      searchCity: '',
      searchAddress: '',
      searchList: [],
      insuranceIndex: 0,
      patientCareTeam: [],
      selectedSchedulePatient: null,
      selectedScheduleDoctor: null,
      scheduleModalLoading: false,
      axiosConfig: {},
      pharmacySearchModalOpen: false,
      pharmacySearchModalLoading: false,
      pharmacySearchString: '',
      pharmacySelectedCity: '',
      pharmacyData: [],
      selectedPharmacy: null,
      // PBM
      pbm: '',
      pbmPatientInsuranceState: '',
      rxBinNumber: '',
      rxPcnNumber: '',
      rxGroup: '',
    }
    this.editValidate = this.editValidate.bind(this)
    this.validate = this.validate.bind(this)
    this.validateEvent = this.validateEvent.bind(this)
    this.createSurvey = this.createSurvey.bind(this)
    this.createEvent = this.createEvent.bind(this)
    this.getEventList = this.getEventList.bind(this)
    this.getTeamInvites = this.getTeamInvites.bind(this)
    this.addCohortsToDoctorProfile = this.addCohortsToDoctorProfile.bind(this)
    this.sendInvites = this.sendInvites.bind(this)
    this.getDynamicLink = this.getDynamicLink.bind(this)
    this.sendBatchRequestToBackend = this.sendBatchRequestToBackend.bind(this)
    this.getCohortsMap = this.getCohortsMap.bind(this)
    this.getPatientWebInviteLink = this.getPatientWebInviteLink.bind(this)
    this.getSelectedCohorts = this.getSelectedCohorts.bind(this)
    this.getPatientCohorts = this.getPatientCohorts.bind(this)
    this.getOptionsElement = this.getOptionsElement.bind(this)
    this.onSurveyModalClose = this.onSurveyModalClose.bind(this)
    this.updateSurvey = this.updateSurvey.bind(this)
    this.getSurveyDetails = this.getSurveyDetails.bind(this)
    this.getSurveyPatientList = this.getSurveyPatientList.bind(this)
    this.getPatientCareTeam = this.getPatientCareTeam.bind(this)
    this.getAppointmentUrl = this.getAppointmentUrl.bind(this)
    this.getPatientInviteList = this.getPatientInviteList.bind(this)
    this.onPharmacySearch = this.onPharmacySearch.bind(this)
    this.renderPharmacySearchModal = this.renderPharmacySearchModal.bind(this)
    this.renderPharmacyList = this.renderPharmacyList.bind(this)
    this.onTabSelected = this.onTabSelected.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn || !this.props.docFirebaseAuthUser) {
      this.props.history.push('/login')
      return
    }

    let { mongoUser, token, doctorInvites, adminId } = this.props
    this.getPatientCohorts()
    this.getCohortOptions()
    this.props.stopTimer(adminId, token)
    this.getDynamicLink()

    if (!doctorInvites) {
      console.log('docInvites')
      this.setState({ loading: true })
      this.props.fetchDoctorInviteList(mongoUser?.firebase_id, token)
    }

    if (this?.props?.location?.state === 'events') {
      this.setState({ eventLoading: true })
      this.getEventList()
    }
  }

  // componentDidUpdate(prevProps) {
  //   let { mongoUser, token, doctorInvites, adminId } = this.props
  //   if (this.props?.doctorInvites !== prevProps?.doctorInvites) {
  //     this.props.fetchDoctorInviteList(mongoUser.firebase_id, token)
  //   }
  // }

  static getDerivedStateFromProps(props, state) {
    let toReturn = {}

    if (!props.patientList) return { patientList: null, loading: true }
    if (props.patientList && !state.patientList) {
      //if patient list data is availbale in memory but not loaded into state
      let patientList = []
      let batchPatientOptions = []
      Object.values(props.patientList).forEach((list) => {
        list.forEach((patient) => {
          patientList.push(patient)
          batchPatientOptions.push({
            label: patient.firstName + ' ' + patient.lastName,
            value: patient.uid,
          })
        })
      })
      toReturn.patientList = patientList
      toReturn.batchPatientOptions = batchPatientOptions
    }

    if (props.doctorInvites && !state.doctorInvites) {
      //doctor invites is available in memory but not loaded into state
      toReturn.doctorInvites = props.doctorInvites
    }

    toReturn.loading = !(props.patientList && props.doctorInvites)

    // if (props.mongoUser && !state.cohortOptions) {
    //   let cohorts = props?.mongoUser?.cohorts || []
    //   let cohortObjects = []
    //   cohorts.forEach((cohort) =>
    //     cohortObjects.push({ label: cohort, value: cohort }),
    //   )
    //   toReturn.cohortOptions = cohortObjects
    // }

    return toReturn
  }

  async getPatientCareTeam(uid) {
    const { token } = this.props

    // get the care team members
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    const doctorList = []

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: uid,
      },
      url,
    })
      .then(async ({ data }) => {
        if (data)
          data.forEach((x) => {
            doctorList.push({
              label: `${x.first_name} ${x.last_name}`,
              value: x.doctor_id,
              doctor: x,
            })
          })
        this.setState({
          patientCareTeam: doctorList,
          showScheduleModal: true,
          scheduleModalLoading: false,
        })
        this.props.toggleSidebar(true)
      })
      .catch((err) => {
        console.log('error when getting the care team for patient', err)
        toast.error('Something went wrong')
        this.setState({
          loading: false,
          showScheduleModal: false,
          selectedSchedulePatient: null,
          selectedScheduleDoctor: null,
        })
      })
  }

  async getPatientCohorts() {
    const { token, adminId } = this.props
    // getting cohort options
    const url = `${Config.BACKEND_URL}patient-cohorts`
    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
      },
      url,
    })
      .then(({ data }) => {
        this.setState({
          patientCohortList: data,
        })
      })
      .catch((err) => {
        console.log('error when getting the cohort options', err)
        toast.error('Something went wrong')
      })
  }

  async getCohortOptions() {
    const { token, adminId } = this.props
    // getting cohort options
    const url = `${Config.BACKEND_URL}cohorts`
    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
      },
      url,
    })
      .then(({ data }) => {
        let tempCohortList =
          data?.map((cohort) => ({
            label: cohort.name,
            value: cohort.name,
          })) || []
        this.setState({
          cohortOptions: tempCohortList,
        })
      })
      .catch((err) => {
        console.log('error when getting the cohort options', err)
        toast.error('Something went wrong')
      })
  }

  getDynamicLink() {
    let { mongoUser } = this.props
    let deepLink =
      'https://www.ailahealth.com/referred/doctor_id/' + mongoUser?.firebase_id
    let androidFallbackUrl = 'https://www.ailahealth.com/download'
    let longLink =
      'https://ailahealth.page.link/?link=' +
      deepLink +
      '&isi=' +
      Config.APP_STORE_ID +
      '&ibi=' +
      Config.APP_BUNDLE_ID +
      '&afl=' +
      androidFallbackUrl +
      '&ofl=' +
      androidFallbackUrl

    let url =
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' +
      Config.FIREBASE_API_KEY
    let self = this
    axios({
      method: 'POST',
      url: url,
      headers: { 'Content-Type': 'application/json' },
      data: { longDynamicLink: longLink },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let data = response.data
          self.setState({ shortDynamicLink: data.shortLink })
        } else {
          self.setState({ shortDynamicLink: null })
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting short link for user', error)
        self.setState({ shortDynamicLink: null })
      })
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  // Deprecated code
  // onAddMorePatientsClicked() {
  //   let modalRows = this.state.modalPatientRows
  //   modalRows.push({
  //     name: '',
  //     email: '',
  //     cohorts: [],
  //     //NEW FIELDS ADDED
  //     phone: '',
  //     dob: new Date(),
  //     state: null,
  //     hasInsurance: false,
  //     voicemail_messages: false,
  //     primaryInsurance: {
  //       _id: null,
  //       subscriber_id: '', // it is the policy number/member ID; change UI to show Member ID
  //       group_number: '',
  //       plan_type: '',
  //       member_name: '',
  //       front_image_url: null,
  //       back_image_url: null,
  //       insurance_company: {
  //         insurance_isp: '', // returned if selected insurance from the search result
  //         insurance_address_1: '', // enter only if it is not present in the search list
  //         insurance_city: '',
  //         insurance_state: '',
  //         insurance_zip: '',
  //         insurance_name: '',
  //       },
  //       insured: {
  //         insured_address_1: '',
  //         insured_city: '',
  //         insured_state: null,
  //         insured_zip: '',
  //         insured_first_name: '',
  //         insured_last_name: '',
  //         insured_sex: null,
  //         insured_relationship: null,
  //         insured_home_phone: '',
  //       },
  //       preference: 1,
  //       medicaid: false,
  //       medicare: false,
  //       benefits: {
  //         category: null,
  //         copay: '',
  //         coinsurance: '',
  //         deductible: '',
  //         telemedicine: false,
  //       },
  //     },
  //     insurance_company: '',
  //     member_number: '',
  //     provider_contact: '',
  //     medicaid: false,
  //     medicare: false,
  //     service_type: '',
  //     notes: '',
  //     action_item: '',
  //     error: false,
  //     errorString: '',
  //     // PBM
  //     pbm: '',
  //     insuranceState: '',
  //     rxBinNumber: '',
  //     rxPcnNumber: '',
  //     rxGroup: '',
  //     // Pharmacy
  //     pharmacy: {
  //       name: '',
  //       address_1: '',
  //       city: '',
  //       state: '',
  //       zip: '',
  //       phone: '',
  //     },
  //   })

  //   this.setState({ modalPatientRows: modalRows })
  // }

  getSelectedCohorts(cohorts) {
    let { userType } = this.props
    if (userType && userType.includes('ccfm')) return [cohorts.label]

    return cohorts.map((x) => x.value)
  }
  getType(val) {
    const valObj = SURVEY_QUESTION_TYPES.filter((x) => x.value == val)
    return valObj[0]
  }
  setRange(val) {
    var valObj = []
    if (val.low != undefined) {
      valObj.push(val.low)
    }
    if (val.low != undefined) {
      valObj.push(val.high)
    }
    return valObj
  }
  surveyQuestionsToArray(sq) {
    var questions = []
    Object.keys(sq).forEach((x, i) => {
      questions.push({
        question: sq[x].text ? sq[x].text : '',
        options: sq[x].options ? sq[x].options : [],
        range: sq[x].range ? this.setRange(sq[x].range) : [],
        type: this.getType(sq[x].type),
      })
    })
    return questions
  }
  getSurveyQuestions(surveyQuestions) {
    var questions = {}
    surveyQuestions.forEach((item, index) => {
      questions[index + 1] = {
        text: item.question,
        type: item.type ? item.type.value : 'free',
        range: { low: item.range[0], high: item.range[1] },
        options: item.options,
      }
    })
    return questions
  }
  getSurveyCohortList(surveyCohortList) {
    var cohortList = []
    surveyCohortList.forEach((item, index) => {
      cohortList.push(item.value)
    })
    return cohortList
  }
  onSurveyModalClose(action) {
    this.setState({
      modalIsOpen: false,
      modalType: '',
      surveyTitle: '',
      surveyDesc: '',
      cadence: '',
      startDate: '',
      endDate: '',
      surveyCohortList: [],
      surveyQuestions: [
        { question: '', options: ['', ''], range: [], type: null, error: '' },
      ],
      surveyResultDate: null,
      is_dateSubmitted_enabled: false,
      dateList: [],
    })
    this.getSurveyList()
    if (action === 'create') {
      toast.success('Survey Published Successfully.')
    } else {
      toast.success('Survey Updated Successfully.')
    }
  }
  // title: this.state.editSurveyTitle,
  //     start:this.state.editSurveyStartDate,
  //     description: this.state.editSurveyDesc,
  //     end:this.state.editSurveyEndDate,
  //     cadence: this.state.editSurveyCadence.value,
  validate() {
    if (this.state.surveyTitle === '') {
      this.setState({ surveyError: 'Title is a mandatory' })
      return false
    } else if (this.state.surveyDesc === '') {
      this.setState({ surveyError: 'Description is mandatory' })
      return false
    } else if (this.state.cadence === '') {
      this.setState({ surveyError: 'Frequency is mandatory' })
      return false
    } else if (
      !this.state.surveyCohortList ||
      this.state.surveyCohortList.length === 0
    ) {
      this.setState({ surveyError: 'Cohort is mandatory' })
      return false
    } else if (this.state.startDate === '') {
      this.setState({ surveyError: 'Start Date is mandatory' })
      return false
    }
    // e
    else if (this.state.surveyQuestions) {
      let surveyboolean = this.state.surveyQuestions.map((x, i) => {
        if (x.question === '') {
          x.error = 'Question cannot be empty'
          let arr = this.state.surveyQuestions
          arr.splice(i, 1, x)
          this.setState({ surveyQuestions: arr })
          return false
        } else if (x.type === '' || x.type === null) {
          x.error = 'Question type is mandatory'
          let arr = this.state.surveyQuestions
          arr.splice(i, 1, x)
          this.setState({ surveyQuestions: arr })
          return false
        } else if (x.type.value === 'range' && x.range.length !== 2) {
          x.error = 'Please fill low and high range'
          let arr = this.state.surveyQuestions
          arr.splice(i, 1, x)
          this.setState({ surveyQuestions: arr })
          return false
        } else if (x.type.value === 'multiple' || x.type.value === 'single') {
          const filtered = x.options.filter((x) => x === '')
          if (filtered.length > 0) {
            x.error = 'Please fill all options'
            let arr = this.state.surveyQuestions
            arr.splice(i, 1, x)
            this.setState({ surveyQuestions: arr })
            return false
          } else {
            return true
          }
        } else {
          x.error = ''
          let arr = this.state.surveyQuestions
          arr.splice(i, 1, x)
          this.setState({ surveyQuestions: arr })
          return true
        }
      })
      return !surveyboolean.includes(false)
    } else {
      this.setState({ surveyError: '' })
      return true
    }

    //return true;
  }

  validateEvent() {
    if (this.state.eventTitle === '') {
      this.setState({ surveyError: 'Title is a mandatory' })
      return false
    } else if (this.state.eventDesc === '') {
      this.setState({ surveyError: 'Description is mandatory' })
      return false
    } else if (this.state.eventDuration === '') {
      this.setState({ surveyError: 'Duration is mandatory' })
      return false
    } else if (!this.state.eventType || this.state.eventType.length === 0) {
      this.setState({ surveyError: 'Event Type is mandatory' })
      return false
    } else if (this.state.eventStartDate === '') {
      this.setState({ surveyError: 'Schedule Date is mandatory' })
      return false
    } else if (this.state.eventRecurring === '') {
      this.setState({ surveyError: 'Recurring value is mandatory' })
      return false
    } else {
      this.setState({ surveyError: '' })
      return true
    }

    //return true;
  }

  editValidate() {
    if (this.state.editSurveyTitle === '') {
      this.setState({ editSurveyError: 'Title is a mandatory' })
      return false
    } else if (this.state.editSurveyDesc === '') {
      this.setState({ editSurveyError: 'Description is mandatory' })
      return false
    } else if (this.state.editSurveyCadence === '') {
      this.setState({ editSurveyError: 'Frequency is mandatory' })
      return false
    } else if (
      this.state.editSurveyCohortList == null ||
      this.state.editSurveyCohortList === ''
    ) {
      this.setState({ editSurveyError: 'Cohort is mandatory' })
      return false
    } else if (this.state.editSurveyStartDate === '') {
      this.setState({ editSurveyError: 'Start Date is mandatory' })
      return false
    }
    // else if (this.state.editSurveyEndDate == ""){
    //   this.setState({editSurveyError:"End Date is mandatory"});
    //   return false;
    // }
    else if (this.state.editSurveyQuestions) {
      let editSurveyboolean = this.state.editSurveyQuestions.map((x, i) => {
        if (x.question == '') {
          x.error = 'Question cannot be empty'
          let arr = this.state.editSurveyQuestions
          arr.splice(i, 1, x)
          this.setState({ editSurveyQuestions: arr })
          return false
        } else if (x.type == '' || x.type == null) {
          x.error = 'Question type is mandatory'
          let arr = this.state.editSurveyQuestions
          arr.splice(i, 1, x)
          this.setState({ editSurveyQuestions: arr })
          return false
        } else if (x.type.value == 'range' && x.range.length != 2) {
          x.error = 'Please fill low and high range'
          let arr = this.state.editSurveyQuestions
          arr.splice(i, 1, x)
          this.setState({ editSurveyQuestions: arr })
          return false
        } else if (x.type.value == 'multiple' || x.type.value == 'single') {
          const filtered = x.options.filter((x) => x == '')
          if (filtered.length > 0) {
            x.error = 'Please fill all options'
            let arr = this.state.editSurveyQuestions
            arr.splice(i, 1, x)
            this.setState({ editSurveyQuestions: arr })
            return false
          } else {
            return true
          }
        } else {
          x.error = ''
          let arr = this.state.editSurveyQuestions
          arr.splice(i, 1, x)
          this.setState({ editSurveyQuestions: arr })
          return true
        }
      })
      return !editSurveyboolean.includes(false)
    } else {
      this.setState({ editSurveyError: '' })
      return true
    }

    //return true;
  }

  getEventList() {
    let self = this
    let { mongoUser, token } = this.props
    let url = Config.BACKEND_URL + 'circles'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: mongoUser?.firebase_id,
        Authorization: 'JWT ' + token,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let circleArray = [
            ...response?.data?.recurringCircles,
            ...response?.data?.circlesHosted,
            ...response?.data?.closedCircles,
            ...response?.data?.openCircles,
          ].sort((first, second) => {
            return moment(first.scheduled_date).diff(
              moment(second.scheduled_date),
            )
          })
          self.setState({
            eventLoading: false,
            circleEventData: circleArray || [],
          })
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting circle events data', error)
        toast.error('Something went wrong')
      })
  }

  createEvent = (event) => {
    event.preventDefault()
    let isvalid = this.validateEvent()
    if (isvalid) {
      let self = this
      let { mongoUser, token } = this.props
      let url = Config.BACKEND_URL + 'circles'
      let {
        eventTitle,
        eventDesc,
        eventDuration,
        eventType,
        eventStartDate,
        eventRecurring,
      } = this.state
      // duration should be in minutes format so convert hours to minutes and adding to minutes
      let duration = eventDuration
      let eventData = {
        host_id: mongoUser?.firebase_id,
        title: eventTitle,
        description: eventDesc,
        // converting local to utc time
        scheduled_date: moment(eventStartDate, 'YYYY-MM-DDTHH:mm')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        type: eventType.value,
        duration: duration.value,
        host_type: 'Provider',
        recurring: eventRecurring.value,
      }
      console.log('eventData:::', eventData)
      axios({
        method: 'post',
        url: url,
        headers: {
          x_firebase_id: mongoUser?.firebase_id,
          Authorization: 'JWT ' + token,
        },
        data: eventData,
      })
        .then(function (response) {
          if (response.data.success) {
            toast.success('Successfully created a Circle !')
            self.setState({
              eventTitle: '',
              eventDesc: '',
              eventStartDate: '',
              eventDuration: '',
              eventType: '',
            })
            self.onModalClosePressed()
          }
        })
        .catch(function (error) {
          // handle error
          console.log('error when team invites', error)
          toast.error('Something went wrong')
        })
    }
  }

  createSurvey = (event) => {
    event.preventDefault()
    let isvalid = this.validate()
    if (isvalid) {
      let self = this
      let { mongoUser, token } = this.props
      let url = Config.BACKEND_URL + 'providers/surveys'
      axios({
        method: 'post',
        url: url,
        headers: {
          x_firebase_id: mongoUser?.firebase_id,
          Authorization: 'JWT ' + token,
        },
        data: {
          doctor_id: mongoUser?.firebase_id,
          title: this.state.surveyTitle,
          description: this.state.surveyDesc,
          start: moment(this.state.startDate).valueOf(),
          end: moment(this.state.endDate).valueOf(),
          cadence: this.state.cadence.value,
          cohorts: this.getSurveyCohortList(this.state.surveyCohortList),
          status: 'active',
          questions: this.getSurveyQuestions(this.state.surveyQuestions),
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            self.onSurveyModalClose('create')
          }
        })
        .catch(function (error) {
          // handle error
          console.log('error when team invites', error)
          toast.error('Something went wrong')
        })
    }
  }

  updateSurvey = (event) => {
    event.preventDefault()
    let self = this
    let isvalid = this.editValidate()
    if (isvalid) {
      let { mongoUser, token } = this.props
      let url = Config.BACKEND_URL + 'providers/surveys'
      axios({
        method: 'put',
        url: url,
        headers: {
          x_firebase_id: mongoUser?.firebase_id,
          Authorization: 'JWT ' + token,
        },
        data: {
          //doctor_id: mongoUser.firebase_id,
          _id: this.state.edit_id,
          title: this.state.editSurveyTitle,
          start: this.state.editSurveyStartDate,
          description: this.state.editSurveyDesc,
          end: this.state.editSurveyEndDate,
          cadence: this.state.editSurveyCadence.value,
          cohorts: this.getSurveyCohortList(this.state.editSurveyCohortList),
          status: this.state.editSurveyChecked == true ? 'active' : 'inactive',

          questions: this.getSurveyQuestions(this.state.editSurveyQuestions),
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            self.onSurveyModalClose('update')
          }
        })
        .catch(function (error) {
          // handle error
          console.log('error when update survey', error)
          toast.error('Something went wrong')
        })
    }
  }
  /////////////////////////////////////
  getTeamInvites() {
    let self = this
    let { mongoUser, token } = this.props
    let url = Config.BACKEND_URL + 'provider/team/invites'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: mongoUser?.firebase_id,
        Authorization: 'JWT ' + token,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let data = response.data
          self.setState({ teamInvitesLoading: false, teamInvites: data || {} })
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when team invites', error)
        toast.error('Something went wrong')
      })
  }

  getSurveyList() {
    let self = this
    let { mongoUser, token } = this.props
    let url = Config.BACKEND_URL + 'providers/surveys'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_doctor_id: mongoUser?.firebase_id,
        Authorization: 'JWT ' + token,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let data = response.data
          self.setState({ surveyListLoading: false, surveyList: data || [] })
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error while fetching surveys', error)
        toast.error('Something went wrong')
      })
  }

  getSurveyDetails(survey_id) {
    let self = this
    let { mongoUser, token } = this.props
    let url = Config.BACKEND_URL + '/providers/surveys/answers'
    axios({
      method: 'get',
      url: url,
      headers: { x_survey_id: survey_id, Authorization: 'JWT ' + token },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let data = response.data
          self.setState({ surveyAnswers: data || [] })
          self.getSurveyPatientList(data)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error while fetching surveys', error)
        toast.error('Something went wrong')
      })
  }
  getPatientWebInviteLink(email) {
    let { adminId } = this.props
    if (!adminId || !email) return null
    let decodedString = adminId + '+' + email
    let encodedString = btoa(decodedString)
    return (
      window.location.origin + '/signup/ccfm/participant?q=' + encodedString
    )
  }

  onDeletePatientInvitePressed(email) {
    if (!email) return
    let tempDoctorInvites = this.state.doctorInvites
    this.props.addDoctorInvites(null)
    this.setState({ loading: true, doctorInvites: null })
    let self = this
    let { mongoUser, token } = this.props
    let url = Config.BACKEND_URL + 'providers/patients/invites'
    axios({
      method: 'delete',
      url: url,
      headers: {
        x_doctor_id: mongoUser?.firebase_id,
        patient_email: email,
        Authorization: 'JWT ' + token,
      },
    })
      .then(({ data }) => {
        if (data && !data.success) {
          //request was successful but no email was deleted due to mismatch
          toast.info('Invite not deleted')
          self.props.addDoctorInvites(tempDoctorInvites)
          self.setState({ loading: false, doctorInvites: tempDoctorInvites })
          return
        }
        self.props.addDoctorInvites(data.result)
        toast.success('Invitation revoked')
        self.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when deleting invite', err)
        toast.error('Something went wrong when revoking invite')
      })
  }

  sendInvites() {
    console.log('In send Invites')
    let { shortDynamicLink } = this.state
    console.log(1)
    let { userType, mongoUser, token, doctorInvites, adminId } = this.props
    console.log(2)
    let entries = this.state.modalPatientRows
    console.log(3)
    let ccfmAppLink = 'https://ailahealth.app.link/ccfm'
    console.log(4)
    this.setState({ loading: true })
    console.log(5)
    let invites = entries.map((x, index) => {
      console.log(6, '|1', index)
      let cohorts = x.cohorts ? this.getSelectedCohorts(x.cohorts) : []
      console.log(6, '|2', index)
      let zipCodeData = zipcodes.lookup(x?.zipcode)
      console.log(6, '|3', index)
      let returnData = {
        first_name: x.firstName,
        last_name: x.lastName,
        email: x.email,
        ethnicity: x.ethnicity?.label || '',
        language: x.language?.label || '',
        timezone: x.timezone?.value || '',
        cohorts: cohorts,
        referral: x.referral,
        referral_id: x.referral_id,
        //NEW FIELDS ADDED
        phone: x.phone,
        gender: x.gender?.value || x.gender,
        dob: moment.utc(x.dob).format('DD/MM/YYYY'),
        //ADDRESS
        address: x.address,
        address2: x.address2,
        addressComponents: x.addressComponents,
        zipcode: x.zipcode,
        zipCodeData: zipCodeData || null,
        emergencyContactName: x.emergencyContactName,
        emergencyContactPhone: x.emergencyContactPhone,
        state: x.state,
        hasInsurance: x.hasInsurance,
        voicemail_messages: x.voicemail_messages,
        primaryInsurance: x.primaryInsurance,
        pcp: x.pcp,
        termsChecked: x.termsChecked,
        // insurance_company: x.insurance_company,
        // member_number: x.member_number,
        pbm: {
          pbm: x.pbm,
          insuranceState: x.insuranceState,
          rxBinNumber: x.rxBinNumber,
          rxPcnNumber: x.rxPcnNumber,
          rxGroup: x.rxGroup,
        },
        pharmacy: x.pharmacy,
        provider_contact: x.provider_contact,
        medicaid: x.medicaid,
        medicare: x.medicare,
        service_type: x.service_type?.value || 'basic',
        notes: x.notes,
        action_item: x.action_item,
        referral_link:
          userType && userType.includes('ccfm')
            ? ccfmAppLink
            : shortDynamicLink,
        program_type:
          userType && userType.includes('ccfm')
            ? 'Wellness Program'
            : 'RPM Monitoring',
        edu_prog: x.program,
        requested_specialities: x.requested_specialities,
        admin_name: mongoUser.first_name + ' ' + mongoUser.last_name,
        organization: mongoUser.organization,
        // New data added
        //
        created_at: new Date(),
      }

      console.log(6, '|4', index, returnData)
      return returnData
    })

    console.log(7)
    let toSend = { doctor_id: adminId, invites: invites }
    console.log(8)
    let url = Config.BACKEND_URL + 'provider/invites'
    console.log(9)
    let tempDoctorInvites = this.state.doctorInvites
    console.log(10)
    this.props.addDoctorInvites(null)
    console.log(11)
    let self = this
    console.log('In send Invites FURTHER')

    //CODE TO CREATE INVITE PATIENT ACCOUNT IN FRONTEND - KEEP COMMENTED.
    // invites.forEach((patient) => {
    //   this.createPatientAccount(patient.email, patient.password, patient)
    // })
    axios({
      method: 'post',
      url: url,
      headers: { x_doctor_id: adminId, Authorization: 'JWT ' + token },
      data: toSend,
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let currentInvites = doctorInvites.invites_sent
          doctorInvites.invites_sent = currentInvites.concat(invites)
          self.setState({
            modalLoading: false,
            modalIsOpen: false,
            modalType: '',
            doctorInvites: null,
          })
          toast.success('Invites Sent')
          setTimeout(() => {
            // self.props.fetchDoctorInviteList(mongoUser.firebase_id, token)
            self.getPatientInviteList()
            // self.props.addDoctorInvites(doctorInvites)
            self.setState({ loading: false })
          }, 5000)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when sending invites', error)
        self.props.addDoctorInvites(tempDoctorInvites)
        self.setState({
          modalLoading: false,
          modalError: true,
          modalErrorString:
            'Error when sending invites. Please try again later.',
        })
        self.setState({ loading: false })

        toast.error('Error when sending invites. Please try again later.')
      })
  }

  async addCohortsToDoctorProfile(cohorts) {
    console.log(cohorts)
    let { mongoUser, token } = this.props
    let url = Config.BACKEND_URL + 'doctor/cohorts'
    let self = this
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: {
        cohorts: cohorts,
        doctor_id: mongoUser?.firebase_id,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          mongoUser.cohorts = mongoUser.cohorts.concat(cohorts)
          self.setState({
            modalLoading: false,
            modalIsOpen: false,
            modalType: '',
            // cohortOptions: null,
          })
          toast.success('Added cohorts successfully')
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when adding doctor cohorts', error)
        self.setState({ modalLoading: false })
        toast.error('Error when adding cohorts')
      })
    this.getCohortOptions()
  }

  onResendEmailPressed(invite) {
    if (!invite) return
    let { token } = this.props
    let url = Config.BACKEND_URL + 'providers/patients/invites/resend'
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: invite,
    })
      .then(function (response) {
        // handle success
        toast.success('Resent invitation email')
      })
      .catch(function (error) {
        // handle error
        console.log('error when resending invites', error)
        toast.error('Something went wrong')
      })
  }

  onTabSelected(index) {
    let tab
    switch (index) {
      case 0:
        tab = 'patient_roster'
        break
      case 1:
        tab = 'provider_roster'
        break
      case 2:
        tab = 'invite'
        this.getPatientInviteList()
        break
      case 3:
        tab = 'current'
        break
      case 4:
        tab = 'team'
        if (!this.state.teamInvites) {
          this.setState({ teamInvitesLoading: true })
          this.getTeamInvites()
        }
        break
      case 5:
        tab = 'survey'
        this.setState({ surveyListLoading: true })
        this.getSurveyList()
        break
      case 6:
        tab = 'clinical study'
        break
      case 7:
        tab = 'events'
        this.setState({ eventLoading: true })
        this.getEventList()
        break
      case 8:
        tab = 'communications'
        this.setState({ eventLoading: true })
        this.getEventList()
        break
      case 9:
        tab = 'practice_info'
        break
      case 10:
        tab = 'payment'
        break
      case 11:
        tab = 'group-credentialing'
        break
      case 12:
        tab = 'provider_referrals'
        break
      case 13:
        tab = 'time_spent'
        break
      case 14:
        tab = 'quality_management'
        break
      case 15:
        tab = 'patient_assistance_form'
        break
      case 16:
        tab = 'cms_1500'
        break
      case 17:
        tab = 'provider_insights'
        break
      case 18:
        tab = 'ar_graph'
        break
      default:
        tab = 'current'
    }
    console.log('ST', tab)
    this.setState({ selectedTab: tab })
  }

  onRemoveModalUserEntryClicked(index) {
    let modalPatientRows = this.state.modalPatientRows
    if (modalPatientRows.length === 1) {
      toast.error("Can't remove the last entry")
      return
    }

    modalPatientRows.splice(index, 1)
    this.setState({ modalPatientRows })
  }

  onSearch = async (index) => {
    let { searchName, searchCity, searchState, searchAddress, searchList } =
      this.state
    let { mongoUser, token } = this.props
    if (searchName.length === 0 || !searchState) {
      console.log('Insurance : No NAME and STATE search')
      toast.warning('Please specify Name and State of Insurance')
      return
    }
    // setLoading(true)
    try {
      let url = Config.CHANGE_BACKEND_URL
      var config = {
        method: 'get',
        url: `${url}change/search-insurance?city=${searchCity}&address_1=${searchAddress}&name=${searchName}&state=${searchState?.abbreviation}`,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: mongoUser?.firebase_id,
        },
      }

      axios(config)
        .then(({ data }) => {
          if (data?.RESULT?.hasOwnProperty('OBJECT')) {
            Array.isArray(data?.RESULT?.OBJECT)
              ? this.setState({ searchList: data.RESULT.OBJECT })
              : this.setState({ searchList: [data.RESULT.OBJECT] })
          } else if (data?.hasOwnProperty('ERROR')) {
            toast.warning(
              'The search criteria you entered found too many matching records. Make your search criteria more specific.',
            )
          } else {
            this.setState({ addInsurance: true })
          }
        })
        .catch(function (error) {
          // setLoading(false)
          // setPharmacyList([])
          toast.error('Could not fetch Insurances, please try again later')
          console.log('error - search insurance', error)
        })
    } catch (error) {
      console.log('error - search insurance', error)
      toast.error('Could not fetch Insurances, please try again later')
    }
  }

  handleCalendarCallback = (childData) => {
    if (childData.success) {
      // console.log(childData)
      //in case the provider booked a appointment, we want to get them all again
      this.setState({ loading: true, reload: true, eventsFromNylas: null })
      // this.getEventsFromBackendOnly()

      this.setState({
        modalIsOpen: false,
        showScheduleModal: false,
        // showSlotAvailableModal: false,
        selectedSchedulePatient: null,
        selectedScheduleDoctor: null,
      })
      this.props.toggleSidebar(false)
      toast.success('Appointment scheduled successfully')
    } else {
      this.props.toggleSidebar(false)
      toast.error('Appointment was not scheduled successfully')
      // console.log(childData)
    }
  }

  getAppointmentUrl = (doctor_id, patient_id) => {
    let data = {
      doctor_id: doctor_id,
      patient_id: patient_id,
      symptoms: [],
      through_api: true,
      appointment_payment_paid: true,
      through_provider: true,
    }
    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}appointments`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    this.setState({ axiosConfig: config })

    this.setState({ scheduleModalLoading: false })
  }

  getPatientInviteList = () => {
    const { mongoUser, token } = this.props
    console.log('get patient invite called', mongoUser, token)
    const url = `${Config.BACKEND_URL}provider/invites`
    axios({
      method: 'get',
      url,
      headers: {
        x_doctor_id: mongoUser?.firebase_id,
        Authorization: `JWT ${token}`,
      },
    })
      .then(({ data }) => {
        this.setState({ doctorInvites: { invites_sent: data } })
        this.props.addDoctorInvites({ invites_sent: data })
      })
      .catch((error) => {
        // handle error
        console.log('error when getting doctor invites', error)
      })
  }

  renderScheduleModal() {
    const { scheduleModalLoading } = this.state

    if (scheduleModalLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={styles.closeModalBtn}
          onClick={() => {
            this.setState({
              showScheduleModal: false,
              selectedSchedulePatient: null,
              selectedScheduleDoctor: null,
            })
            this.props.toggleSidebar(false)
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Schedule Appointment
        </h4>
        {/*
        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="select patient"
            inputId="name"
            options={this.state.patientList}
            value={this.state.selectedSchedulePatient}
            isSearchable
            autoBlur
            onChange={(val) => {
              // console.log(val)
              this.setState({
                selectedSchedulePatient: val,
                selectedScheduleDoctor: null,
                scheduleModalLoading: true,
              })
              this.getPatientCareTeam(val.value)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div> */}

        {this.state.selectedSchedulePatient &&
          this.state.patientCareTeam &&
          this.state.patientCareTeam.length > 0 && (
            <div style={{ width: '50%', margin: 20 }}>
              <Select
                placeholder={'select care team member'}
                inputId="doctor_name"
                options={this.state.patientCareTeam}
                value={this.state.selectedScheduleDoctor}
                isSearchable
                autoBlur
                onChange={(val) => {
                  // console.log(val)
                  this.setState({
                    selectedScheduleDoctor: val,
                    axiosConfig: {},
                    scheduleModalLoading: true,
                  })

                  console.log("Getting appointment URL")
                  this.getAppointmentUrl(
                    val.doctor.firebase_id,
                    this.state.selectedSchedulePatient.patient_id,
                  )
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          )}
        {this.state.selectedScheduleDoctor &&
          this.state.selectedSchedulePatient &&
          this.state.axiosConfig && (
            <MyCalendar
              key={this.state.selectedScheduleDoctor.doctor}
              parentCallback={this.handleCalendarCallback}
              doctorDetails={this.state.selectedScheduleDoctor.doctor}
              axiosConfig={this.state.axiosConfig}
              patientId={this.state.selectedSchedulePatient.patient_id}
              token={this.props.token}
              fromProvider={true}
            />
          )}
      </div>
    )
  }

  renderSearchList = () => {
    let {
      searchName,
      searchCity,
      searchState,
      searchAddress,
      searchList,
      insuranceIndex,
      modalPatientRows,
      addInsurance,
    } = this.state

    const renderAddInsurance = () => {
      let temp = modalPatientRows
      let pinsurance = modalPatientRows[insuranceIndex].primaryInsurance

      const onAddPressed = () => {
        this.setState({
          showSearchList: false,
          addInsurance: false,
          searchName: '',
          searchCity: '',
          searchAddress: '',
          searchList: [],
        })
      }
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%', padding: '20px 20px' }}>
            <div className={styles.row}>
              <p className={styles.rowLabel}>Name</p>
              <div
                style={{ width: '50%', display: 'flex', flexDirection: 'row' }}
              >
                <input
                  placeholder="Insurance Name"
                  className={styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_name}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_name = e.target.value
                    temp[insuranceIndex].primaryInsurance = pinsurance
                    this.setState({ modalPatientRows: temp })
                  }}
                />
              </div>
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>Address</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Address"
                  className={styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_address_1}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_address_1 =
                      e.target.value
                    temp[insuranceIndex].primaryInsurance = pinsurance
                    this.setState({ modalPatientRows: temp })
                  }}
                />
              </div>
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>City</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured City"
                  className={styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_city}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_city = e.target.value
                    temp[insuranceIndex].primaryInsurance = pinsurance
                    this.setState({ modalPatientRows: temp })
                  }}
                />
              </div>
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>Zip</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter Insured Zip"
                  className={styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  value={pinsurance?.insurance_company?.insurance_zip}
                  onChange={(e) => {
                    pinsurance.insurance_company.insurance_zip = e.target.value
                    temp[insuranceIndex].primaryInsurance = pinsurance
                    this.setState({ modalPatientRows: temp })
                  }}
                />
              </div>
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>State</p>
              <div style={{ width: '50%' }}>
                <Select
                  placeholder="Select State"
                  inputId="newinsurancestate"
                  options={Metadata.stateResidence}
                  value={
                    pinsurance?.insurance_company?.insurance_state &&
                    !pinsurance?.insurance_company?.insurance_state.label
                      ? {
                          label: pinsurance?.insurance_company?.insurance_state,
                          value: pinsurance?.insurance_company?.insurance_state,
                        }
                      : pinsurance?.insurance_company?.insurance_state
                  }
                  isMulti={false}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    pinsurance.insurance_company.insurance_state =
                      val.abbreviation
                    temp[insuranceIndex].primaryInsurance = pinsurance
                    this.setState({ modalPatientRows: temp })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
            <Button
              onClick={() => onAddPressed()}
              className={GlobalStyles.button}
              style={{ width: 200, margin: '5% auto' }}
              variant="primary"
            >
              Add Insurance
            </Button>
          </div>
        </div>
      )
    }
    const renderList = (insuranceData, index) => {
      return (
        <div
          key={index}
          // className={styles.contentRow}
          className={styles.contentBackground}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
            backgroundColor: Constants.contentBackground,
            borderRadius: Constants.borderRadius,
            padding: '1% 2%',
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
              }}
            >
              <h6 style={{ color: Constants.primaryThemeDark }}>
                {insuranceData?.name[1]}
                {/* {'\n '}
                  Visit with {insuranceData?.first_name}{' '}
                  {insuranceData?.last_name} */}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.address_1 || ''} ${
                  insuranceData?.city || ''
                }`}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${insuranceData?.state || ''} ${insuranceData?.zip || ''} `}
              </h6>
            </div>

            <Button
              className={GlobalStyles.button}
              //   disabled={loading}
              onClick={() => {
                let temp = modalPatientRows
                let pinsurance = {
                  ...modalPatientRows[insuranceIndex].primaryInsurance,
                }
                pinsurance.insurance_company.insurance_name =
                  insuranceData?.name[1]
                pinsurance.insurance_company.insurance_isp = insuranceData?.isp
                pinsurance.insurance_company.insurance_address_1 =
                  insuranceData?.address_1
                pinsurance.insurance_company.insurance_city =
                  insuranceData?.city
                pinsurance.insurance_company.insurance_state =
                  insuranceData?.state
                pinsurance.insurance_company.insurance_zip = insuranceData?.zip
                temp[insuranceIndex].primaryInsurance = pinsurance
                this.setState({
                  modalPatientRows: temp,
                  showSearchList: false,
                  searchAddress: '',
                  searchCity: '',
                  searchList: [],
                  searchName: '',
                })
              }}
              style={{
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
            >
              Select
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Insurance
        </h4>
        {/* <div className={styles.row} style={{ margin: '2% 0%' }}>
          <p className={styles.rowLabel}>Name</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Name"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchName}
              onChange={(e) => {
                this.setState({ searchName: e.target.value })
              }}
            />
          </div>
          {/* <p className={styles.rowLabel}>Address</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search Address"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchAddress}
              onChange={(e) => {
                setSearchAddress(e.target.value)
              }}
            />
          </div> // Comment ends here
          <p className={styles.rowLabel}>City</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <input
              placeholder="Search City"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={searchCity}
              onChange={(e) => {
                this.setState({ searchCity: e.target.value })
              }}
            />
          </div>

          <p className={styles.rowLabel}>State</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <Select
              placeholder="Select State"
              inputId="primarystate"
              options={Metadata.stateResidence}
              value={searchState}
              isMulti={false}
              isSearchable
              autoBlur
              onChange={(val) => {
                this.setState({ searchState: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <FontAwesomeIcon
            onClick={() => {
              //   setActiveInsurance(1)
              console.log(
                'SEARCH PARAMS:',
                searchAddress,
                searchName,
                searchCity,
              )
              this.onSearch()
              //   setShowSearchList(true)
            }}
            icon={faSearch}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </div> */}

        <div className={styles.row} style={{ margin: '2% 0%' }}>
          <p className={styles.rowLabel}>Payer Name</p>
          <div style={{ width: '50%', marginRight: '2%' }}>
            <SearchProvider config={esConfigPayer}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'payer_name',
                        }}
                        searchAsYouType={true}
                        onSelectAutocomplete={(e) => {
                          let temp = modalPatientRows
                          let pinsurance = {
                            ...modalPatientRows[insuranceIndex]
                              .primaryInsurance,
                          }
                          pinsurance.insurance_company.insurance_name =
                            e?.payer_name?.raw
                          pinsurance.payer_id = e?.payerid?.raw
                          // pinsurance.insurance_company.insurance_isp = insuranceData?.isp
                          // pinsurance.insurance_company.insurance_address_1 =
                          //   insuranceData?.address_1
                          // pinsurance.insurance_company.insurance_city =
                          //   insuranceData?.city
                          // pinsurance.insurance_company.insurance_state =
                          //   insuranceData?.state
                          // pinsurance.insurance_company.insurance_zip = insuranceData?.zip
                          temp[insuranceIndex].primaryInsurance = pinsurance
                          this.setState({
                            modalPatientRows: temp,
                            showSearchList: false,
                            searchAddress: '',
                            searchCity: '',
                            searchList: [],
                            searchName: '',
                          })
                        }}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Payer Name',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
        </div>

        {/* <div style={{ height: '65vh', overflowY: 'scroll' }}>
          {searchList.length !== 0 ? (
            searchList.map((insurance, index) => renderList(insurance, index))
          ) : addInsurance ? (
            renderAddInsurance()
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              No Searches found <br />
              Please search by city, name and address
            </p>
          )}
        </div> */}
      </div>
    )
  }

  validateEmail(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  async onPharmacySearch() {
    // toast.info('Searching pharmacy')
    // console.log(1)
    let { pharmacySearchString, pharmacySelectedCity } = this.state
    let { token, patient } = this.props
    this.setState({ pharmacySearchModalLoading: true })
    console.log('pharmacy search string', pharmacySearchString)
    console.log('token', token)
    if (pharmacySearchString.length === 0) {
      toast.error('Please enter pharmacy name or zip code')
      this.setState({ pharmacySearchModalLoading: false })
      return
    }

    let config = {
      method: 'get',
      url: `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`,
      headers: {
        Authorization: `JWT ${token}`,
        // Don't think this param is needed
        // x_firebase_id: props?.patientId ? props?.patientId : patient?.uid,
      },
    }
    // if entered only zip code (ie, string contains only digits)
    // What fancy code is this?
    if (/^\d+$/.test(pharmacySearchString)) {
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`
      config.headers.zip = pharmacySearchString
    } else {
      if (pharmacySelectedCity.length === 0) {
        toast.error('Please enter city name')
        this.setState({ pharmacySearchModalLoading: false })
        return
      }
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/name`
      config.headers.name = pharmacySearchString
      config.headers.city = pharmacySelectedCity
    }

    axios(config)
      .then(({ data }) => {
        console.log('got pharmacy results', data)
        if (data) {
          this.setState({ pharmacyData: data || [] })
          this.setState({ pharmacySearchModalLoading: false })
        }
      })
      .catch(function (error) {
        this.setState({ pharmacySearchModalLoading: false })
        this.setState({ pharmacyData: [] })
        toast.error(
          'Error occurred while fetching the pharmacy details, please contact support',
        )
        console.log('error when searching pharmacy', error)
      })
  }

  renderPharmacyList(index) {
    let {
      pharmacySearchString,
      pharmacySelectedCity,
      pharmacyData,
      selectedPharmacy,
    } = this.state
    if (
      !pharmacySearchString ||
      pharmacySearchString.length === 0 ||
      pharmacyData.length === 0
    ) {
      return
    }

    if (
      !pharmacyData ||
      (pharmacyData.length !== 0 && pharmacyData[0] === null)
    ) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>no results</p>
        </div>
      )
    }

    let elements = pharmacyData.map((pharmacy) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid #d0d0d0',
          padding: '8px 2px',
          cursor: 'pointer',
        }}
        onClick={() => {
          this.setState({ selectedPharmacy: pharmacy })
          console.log('selected pharmacy', pharmacy)
          let { modalPatientRows } = this.state
          modalPatientRows[index].pharmacy = pharmacy
          this.setState({ modalPatientRows })
          this.setState({ pharmacySearchModalOpen: false })
        }}
      >
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.name?.[1]}
        </p>
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.address_1}, {pharmacy?.city}
        </p>
      </div>
    ))

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'scroll',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #d0d0d0',
            padding: 10,
          }}
        >
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Name
          </p>
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Address
          </p>
        </div>

        {elements}
      </div>
    )
  }

  renderPharmacySearchModal(index) {
    let { pharmacySearchModalOpen } = this.state
    return (
      <Modal
        ariaHideApp={false}
        isOpen={pharmacySearchModalOpen}
        onRequestClose={() => this.setState({ pharmacySearchModalOpen: false })}
        style={customModalStyles}
        contentLabel="Modal"
      >
        {this.state.pharmacySearchModalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              minHeight: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
              Search Pharmacy
            </h4>
            <p
              className={styles.closeModalBtn}
              onClick={() => this.setState({ pharmacySearchModalOpen: false })}
            >
              X
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 10px',
                width: '80%',
                justifyContent: 'space-evenly',
              }}
            >
              <input
                placeholder={'Enter pharmacy or zip code'}
                className={styles.textInput}
                style={{ width: '40%' }}
                type="textInput"
                value={this.state.pharmacySearchString}
                onChange={(e) =>
                  this.setState({ pharmacySearchString: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.onPharmacySearch()
                  }
                }}
              />
              <input
                placeholder={'Enter city name'}
                className={styles.textInput}
                style={{ width: '40%' }}
                type="textInput"
                value={this.state.pharmacySelectedCity}
                onChange={(e) =>
                  this.setState({ pharmacySelectedCity: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.onPharmacySearch()
                  }
                }}
              />
              <Button
                onClick={this.onPharmacySearch}
                className={GlobalStyles.button}
                style={{ marginLeft: 5, height: 40 }}
                variant="primary"
              >
                Search
              </Button>
              {/* <div style={{ width: '50%', marginLeft: 10 }}>

              </div> */}
            </div>

            {this.renderPharmacyList(index)}
          </div>
        )}
      </Modal>
    )
  }

  renderModalEntryForm(index, modalPatientRows) {
    let { userType } = this.props
    return (
      <div className={styles.patientModalEntryForm} key={index}>
        {modalPatientRows.length > 1 && (
          <div
            onClick={() => this.onRemoveModalUserEntryClicked(index)}
            key={index}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        )}
        <div className={styles.row}>
          <p className={styles.rowLabel}>Patient First Name*</p>
          <input
            placeholder={'Enter first name....'}
            className={styles.textInput}
            type="text"
            value={this.state.modalPatientRows[index].firstName}
            onChange={(e) => {
              let currentRows = this.state.modalPatientRows
              currentRows[index].firstName = e.target.value
              currentRows[index].primaryInsurance.insured.insured_first_name =
                e.target.value
              if (currentRows[index].errorString === MODAL_ERROR_NAME) {
                currentRows[index].error = false
                currentRows[index].errorString = ''
              }

              this.setState({ modalPatientRows: currentRows })
            }}
          />
        </div>
        <div className={styles.row}>
          <p className={styles.rowLabel}>Patient Last Name*</p>
          <input
            placeholder={'Enter last name....'}
            className={styles.textInput}
            type="text"
            value={this.state.modalPatientRows[index].lastName}
            onChange={(e) => {
              let currentRows = this.state.modalPatientRows
              currentRows[index].lastName = e.target.value
              currentRows[index].primaryInsurance.insured.insured_last_name =
                e.target.value
              if (currentRows[index].errorString === MODAL_ERROR_NAME) {
                currentRows[index].error = false
                currentRows[index].errorString = ''
              }

              this.setState({ modalPatientRows: currentRows })
            }}
          />
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Patient Email*</p>
          <input
            placeholder={'Enter valid email....'}
            className={styles.textInput}
            type="text"
            value={this.state.modalPatientRows[index].email}
            onChange={(e) => {
              let currentRows = this.state.modalPatientRows
              currentRows[index].email = e.target.value
              if (currentRows[index].errorString === MODAL_ERROR_EMAIL) {
                currentRows[index].error = false
                currentRows[index].errorString = ''
              }
              this.setState({ modalPatientRows: currentRows })
            }}
          />
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Patient Phone Number*</p>
          <input
            placeholder={'Enter phone number....'}
            className={styles.textInput}
            type="text"
            value={this.state.modalPatientRows[index].phone}
            onChange={(e) => {
              let currentRows = this.state.modalPatientRows
              currentRows[index].phone = e.target.value
              currentRows[index].primaryInsurance.insured.insured_home_phone =
                e.target.value
              this.setState({ modalPatientRows: currentRows })
            }}
          />
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Gender*</p>
          <div style={{ width: '50%' }}>
            <Select
              placeholder={'Select Gender'}
              isMulti={false}
              options={Metadata.GENDER_OPTIONS}
              value={this.state.modalPatientRows[index].gender}
              onChange={(val) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].gender = val
                this.setState({ modalPatientRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>
        <div className={styles.row}>
          <p className={styles.rowLabel}>DOB*</p>
          <div style={{ width: '50%' }}>
            <DatePicker
              defaultDate={this.state.modalPatientRows[index].dob}
              dateChange={(date) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].dob = date
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <p className={styles.rowLabel}>Ethnicity</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="ethnicity"
              options={Metadata.ETHNICITY_OPTIONS}
              value={this.state.modalPatientRows[index].ethnicity}
              isSearchable
              autoBlur
              onChange={(val) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].ethnicity = val
                this.setState({ modalPatientRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Preferred Language</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="languages"
              options={Metadata.LANGUAGES}
              value={this.state.modalPatientRows[index].language}
              isSearchable={false}
              autoBlur
              onChange={(val) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].language = val
                this.setState({ modalPatientRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Timezone</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="timezone"
              options={Metadata.TIMEZONE_ABBREVATION_MAPPING}
              value={this.state.modalPatientRows[index].timezone}
              autoBlur
              onChange={(val) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].timezone = val
                this.setState({ modalPatientRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/*  ADDRESS */}

        <div className={styles.row}>
          <p className={styles.rowLabel}>Address*</p>
          <div style={{ width: '50%' }}>
            <GooglePlacesAutocomplete
              apiKey={Config.GOOGLE_API_KEY}
              selectProps={{
                placeholder:
                  this.state.modalPatientRows[index].address ||
                  'Search Address',
                value: this.state.modalPatientRows[index].address,
                onChange: (picked = null) => {
                  //get ADDRESS COMPONENT
                  geocodeByAddress(picked.label)
                    .then((details) => {
                      // 'details' is provided when fetchDetails = true
                      const zip = details[0]?.address_components.find(
                        (addressComponent) =>
                          addressComponent.types.includes('postal_code'),
                      )?.short_name
                      const address = picked.value.description || ''
                      let { modalPatientRows } = this.state
                      modalPatientRows[index].address = address
                      modalPatientRows[index].zipcode = zip
                      modalPatientRows[index].addressComponents =
                        details && details[0].address_components
                      this.setState({ modalPatientRows })
                    })
                    .catch((error) => console.error(error))
                },
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Apt, Floor</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Apt, Floor"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].address2}
              onChange={(e) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].address2 = e.target.value
                this.setState({ modalPatientRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Zip Code*</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Zip Code"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].zipcode}
              onChange={(e) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].zipcode = e.target.value
                this.setState({ modalPatientRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Patient State*</p>
          <div style={{ width: '50%' }}>
            <Select
              placeholder="State..."
              inputId="state"
              options={Metadata.stateResidence}
              value={
                modalPatientRows[index].state
                  ? modalPatientRows[index].state
                  : null
              }
              isMulti={false}
              isSearchable
              autoBlur
              onChange={(val) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].state = val
                currentRows[index].primaryInsurance.insured.insured_state =
                  val.abbreviation
                this.setState({ modalPatientRows: currentRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Select Speciality</p>
          <div style={{ width: '50%' }}>
            <Select
              placeholder={'Select Speciality'}
              isMulti={!(userType && userType.includes('ccfm'))}
              options={Metadata.SPECIALITY_OPTIONS}
              value={this.state.modalPatientRows[index].requested_specialities}
              onChange={(val) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].requested_specialities = val
                this.setState({ modalPatientRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Patient Cohort</p>
          <div style={{ width: '50%' }}>
            <Select
              placeholder={'Select cohorts'}
              isMulti={true}
              options={Metadata.COHORT_OPTIONS}
              value={this.state.modalPatientRows[index].cohorts}
              onChange={(val) => {
                let { modalPatientRows } = this.state
                modalPatientRows[index].cohorts = val
                this.setState({ modalPatientRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {/* Insurance */}
        <>
          <div className={styles.row}>
            <label style={{ color: '#20A892' }} class={styles.checkContainer}>
              Has Insurance
              <input
                type="checkbox"
                checked={this.state.modalPatientRows[index].hasInsurance}
                onClick={() => {
                  // console.log('clocked')
                  let currentRows = this.state.modalPatientRows
                  currentRows[index].hasInsurance =
                    !currentRows[index].hasInsurance
                  this.setState({ modalPatientRows: currentRows })
                }}
              />
              <span class={styles.checkmark}></span>
            </label>
          </div>

          {this.state.modalPatientRows[index].hasInsurance && (
            <div>
              <div className={styles.row}>
                <p className={styles.rowLabel}>Patient Insurance Company</p>
                <input
                  placeholder={'Enter insurance company....'}
                  className={styles.textInput}
                  type="text"
                  // disabled={true}
                  // value={this.state.primaryInsurance?.insurance_company?.insurance_name}
                  value={
                    this.state.modalPatientRows[index].primaryInsurance
                      ?.insurance_company?.insurance_name
                  }
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[
                      index
                    ].primaryInsurance.insurance_company.insurance_name =
                      e.target.value
                    this.setState({ modalPatientRows: currentRows })
                  }}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    this.setState({
                      insuranceIndex: index,
                      showSearchList: true,
                      addInsurance: false,
                      searchName: '',
                      searchCity: '',
                      searchAddress: '',
                      searchList: [],
                    })
                  }}
                  icon={faSearch}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                />
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Insurance Payer ID</p>
                <input
                  placeholder={'Enter payer id number....'}
                  className={styles.textInput}
                  type="text"
                  value={
                    this.state.modalPatientRows[index].primaryInsurance.payer_id
                  }
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].primaryInsurance.payer_id =
                      e.target.value
                    this.setState({ modalPatientRows: currentRows })
                  }}
                />
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Insurance Subscriber ID</p>
                <input
                  placeholder={'Enter subscriber id number....'}
                  className={styles.textInput}
                  type="text"
                  value={
                    this.state.modalPatientRows[index].primaryInsurance
                      .subscriber_id
                  }
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].member_number = e.target.value
                    currentRows[index].primaryInsurance.subscriber_id =
                      e.target.value
                    this.setState({ modalPatientRows: currentRows })
                  }}
                />
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Insurance Group Number</p>
                <input
                  placeholder={'Enter group number....'}
                  className={styles.textInput}
                  type="text"
                  value={
                    this.state.modalPatientRows[index].primaryInsurance
                      .group_number
                  }
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    // currentRows[index].member_number = e.target.value
                    currentRows[index].primaryInsurance.group_number =
                      e.target.value
                    this.setState({ modalPatientRows: currentRows })
                  }}
                />
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Insurnace Contact Number </p>
                <input
                  placeholder={'Enter provider contact....'}
                  className={styles.textInput}
                  type="text"
                  value={
                    this.state.modalPatientRows[index].primaryInsurance
                      .insurance_contact_number
                  }
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    // currentRows[index].member_number = e.target.value
                    currentRows[
                      index
                    ].primaryInsurance.insurance_contact_number = e.target.value
                    this.setState({ modalPatientRows: currentRows })
                  }}
                />
              </div>

              {/* <div className={styles.row}>
                <p className={styles.rowLabel}>
                  Provider Contact Number on Back of Member ID card{' '}
                </p>
                <input
                  placeholder={'Enter provider contact....'}
                  className={styles.textInput}
                  type="text"
                  value={this.state.modalPatientRows[index].provider_contact}
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].provider_contact = e.target.value
                    this.setState({ modalPatientRows: currentRows })

                    let currentRowz = this.state.modalPatientRows
                    currentRowz[index].primaryInsurance.provider_contact =
                      e.target.value
                    this.setState({ modalPatientRows: currentRowz })
                  }}
                />
              </div> */}

              <div className={styles.row}>
                <p className={styles.rowLabel}>Relationship to insured</p>
                <div style={{ width: '50%' }}>
                  <Select
                    placeholder={'Relationship to insured'}
                    isMulti={false}
                    options={Metadata.RELATIONSHIP_OPTIONS}
                    value={
                      this.state.modalPatientRows[index].insured_relationship
                    }
                    onChange={(val) => {
                      let { modalPatientRows } = this.state
                      modalPatientRows[index].insured_relationship = val
                      this.setState({ modalPatientRows })

                      let currentRows = this.state.modalPatientRows
                      currentRows[index].primaryInsurance.insured_relationship =
                        val.value
                      this.setState({ modalPatientRows: currentRows })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Insured member name</p>
                <input
                  placeholder={'Insured Member First Name'}
                  className={styles.textInput}
                  type="text"
                  value={this.state.modalPatientRows[index].insured_first_name}
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].insured_first_name = e.target.value
                    this.setState({ modalPatientRows: currentRows })

                    let currentRowz = this.state.modalPatientRows
                    currentRowz[index].primaryInsurance.insured_first_name =
                      e.target.value
                    this.setState({ modalPatientRows: currentRowz })
                  }}
                />
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Insured member name</p>
                <input
                  placeholder={'Insured Member Last Name'}
                  className={styles.textInput}
                  type="text"
                  value={this.state.modalPatientRows[index].insured_last_name}
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].insured_last_name = e.target.value
                    this.setState({ modalPatientRows: currentRows })

                    let currentRowz = this.state.modalPatientRows
                    currentRowz[index].primaryInsurance.insured_last_name =
                      e.target.value
                    this.setState({ modalPatientRows: currentRowz })
                  }}
                />
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Plan Type</p>
                <input
                  placeholder={'Plan Type'}
                  className={styles.textInput}
                  type="text"
                  value={this.state.modalPatientRows[index].plan_type}
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].plan_type = e.target.value
                    this.setState({ modalPatientRows: currentRows })

                    let currentRowz = this.state.modalPatientRows
                    currentRowz[index].primaryInsurance.plan_type =
                      e.target.value
                    this.setState({ modalPatientRows: currentRowz })
                  }}
                />
              </div>

              <div className={styles.row}>
                <label
                  style={{ color: '#20A892' }}
                  class={styles.checkContainer}
                >
                  Has Medicare
                  <input
                    type="checkbox"
                    checked={this.state.modalPatientRows[index].medicare}
                    onClick={() => {
                      // console.log('clocked')
                      let currentRows = this.state.modalPatientRows
                      currentRows[index].medicare = !currentRows[index].medicare
                      currentRows[index].primaryInsurance.medicare =
                        !currentRows[index].primaryInsurance.medicare
                      this.setState({ modalPatientRows: currentRows })
                    }}
                  />
                  <span class={styles.checkmark}></span>
                </label>
              </div>

              <div className={styles.row}>
                <label
                  style={{ color: '#20A892' }}
                  class={styles.checkContainer}
                >
                  Has Medicaid
                  <input
                    type="checkbox"
                    checked={this.state.modalPatientRows[index].medicaid}
                    onClick={() => {
                      // console.log('clocked')
                      let currentRows = this.state.modalPatientRows
                      currentRows[index].medicaid = !currentRows[index].medicaid
                      currentRows[index].primaryInsurance.medicaid =
                        !currentRows[index].primaryInsurance.medicaid
                      this.setState({ modalPatientRows: currentRows })
                    }}
                  />
                  <span class={styles.checkmark}></span>
                </label>
              </div>

              <div className={styles.row}>
                <label
                  style={{ color: '#20A892' }}
                  class={styles.checkContainer}
                >
                  Medicare Advantage
                  <input
                    type="checkbox"
                    checked={this.state.modalPatientRows[index].medicaid_adv}
                    onClick={() => {
                      // console.log('clocked')
                      let currentRows = this.state.modalPatientRows
                      currentRows[index].medicaid_adv =
                        !currentRows[index].medicaid_adv
                      currentRows[index].primaryInsurance.medicaid_adv =
                        !currentRows[index].primaryInsurance.medicaid_adv
                      this.setState({ modalPatientRows: currentRows })
                    }}
                  />
                  <span class={styles.checkmark}></span>
                </label>
              </div>

              <div className={styles.row}>
                <label
                  style={{ color: '#20A892' }}
                  class={styles.checkContainer}
                >
                  Workers Compensation
                  <input
                    type="checkbox"
                    checked={this.state.modalPatientRows[index].workers_comp}
                    onClick={() => {
                      // console.log('clocked')
                      let currentRows = this.state.modalPatientRows
                      currentRows[index].workers_comp =
                        !currentRows[index].workers_comp
                      currentRows[index].primaryInsurance.workers_comp =
                        !currentRows[index].primaryInsurance.workers_comp
                      this.setState({ modalPatientRows: currentRows })
                    }}
                  />
                  <span class={styles.checkmark}></span>
                </label>
              </div>

              <div className={styles.row}>
                <label
                  style={{ color: '#20A892' }}
                  class={styles.checkContainer}
                >
                  HMO
                  <input
                    type="checkbox"
                    checked={this.state.modalPatientRows[index].hmo}
                    onClick={() => {
                      // console.log('clocked')
                      let currentRows = this.state.modalPatientRows
                      currentRows[index].hmo = !currentRows[index].hmo
                      currentRows[index].primaryInsurance.hmo =
                        !currentRows[index].primaryInsurance.hmo
                      this.setState({ modalPatientRows: currentRows })
                    }}
                  />
                  <span class={styles.checkmark}></span>
                </label>
              </div>

              <div className={styles.row}>
                <label
                  style={{ color: '#20A892' }}
                  class={styles.checkContainer}
                >
                  Cash Pay
                  <input
                    type="checkbox"
                    checked={this.state.modalPatientRows[index].cashpay}
                    onClick={() => {
                      // console.log('clocked')
                      let currentRows = this.state.modalPatientRows
                      currentRows[index].cashpay = !currentRows[index].cashpay
                      currentRows[index].primaryInsurance.cashpay =
                        !currentRows[index].primaryInsurance.cashpay
                      this.setState({ modalPatientRows: currentRows })
                    }}
                  />
                  <span class={styles.checkmark}></span>
                </label>
              </div>

              <div className={styles.row}>
                <p className={styles.rowLabel}>Copay ($)</p>
                <input
                  placeholder={'$'}
                  className={styles.textInput}
                  type="text"
                  value={
                    this.state.modalPatientRows[index].primaryInsurance.benefits
                      ?.copay
                  }
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].primaryInsurance.benefits.copay =
                      e.target.value
                    this.setState({ modalPatientRows: currentRows })
                  }}
                />
              </div>
              <div className={styles.row}>
                <p className={styles.rowLabel}>Coinsurance (%)</p>
                <input
                  placeholder={'%'}
                  className={styles.textInput}
                  type="text"
                  value={
                    this.state.modalPatientRows[index].primaryInsurance.benefits
                      ?.coinsurance
                  }
                  onChange={(e) => {
                    let currentRows = this.state.modalPatientRows
                    currentRows[index].primaryInsurance.benefits.coinsurance =
                      e.target.value
                    this.setState({ modalPatientRows: currentRows })
                  }}
                />
              </div>
              <div className={styles.row}>
                <label
                  style={{ color: '#20A892' }}
                  class={styles.checkContainer}
                >
                  Telemedicine covered
                  <input
                    type="checkbox"
                    checked={
                      this.state.modalPatientRows[index].primaryInsurance
                        ?.benefits?.telemedicine
                    }
                    onClick={() => {
                      // console.log('clocked')
                      let currentRows = this.state.modalPatientRows
                      currentRows[
                        index
                      ].primaryInsurance.benefits.telemedicine =
                        !currentRows[index].primaryInsurance.benefits
                          .telemedicine
                      this.setState({ modalPatientRows: currentRows })
                    }}
                  />
                  <span class={styles.checkmark}></span>
                </label>
              </div>
            </div>
          )}
        </>

        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/* PBM */}
        <>
          <div className={styles.row}>
            <p className={styles.rowLabel} style={{ fontSize: 'large' }}>
              Pharmacy Benefit Managers(PBM)
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.rowLabel}>PBM</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="Enter PBM"
                className={styles.textInput}
                style={{ width: '100%' }}
                type="text"
                // value={this.state.pbm}
                value={this.state.modalPatientRows[index].pbm}
                onChange={(e) => {
                  this.setState({ pbm: e.target.value })
                  let currentRows = this.state.modalPatientRows
                  currentRows[index].pbm = e.target.value
                  // What is this code for?
                  // if (currentRows[index].errorString === MODAL_ERROR_EMAIL) {
                  //   currentRows[index].error = false
                  //   currentRows[index].errorString = ''
                  // }
                  this.setState({ modalPatientRows: currentRows })
                }}
              />
            </div>
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>Patient Insurance State</p>
            <div style={{ width: '50%' }}>
              <Select
                placeholder="Select State"
                inputId="pbmbenefitstate"
                options={metadata.stateResidence}
                value={
                  modalPatientRows[index].insuranceState
                    ? modalPatientRows[index].insuranceState
                    : null
                }
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  this.setState({ pbmPatientInsuranceState: val })
                  let currentRows = this.state.modalPatientRows
                  currentRows[index].insuranceState = val
                  currentRows[index].primaryInsurance.insured.insured_state =
                    val.abbreviation
                  this.setState({ modalPatientRows: currentRows })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <p className={styles.rowLabel}>RxBIN Number</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="RxBIN"
                className={styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.modalPatientRows[index].rxBinNumber}
                onChange={(e) => {
                  this.setState({ rxBinNumber: e.target.value })
                  let { modalPatientRows } = this.state
                  modalPatientRows[index].rxBinNumber = e.target.value
                  modalPatientRows[index].primaryInsurance.benefits.rxbin =
                    e.target.value
                  this.setState({ modalPatientRows })
                }}
              />
            </div>
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>RxPCN Number</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder={'RxPCN'}
                type="text"
                value={this.state.modalPatientRows[index].rxPcnNumber}
                onChange={(e) => {
                  this.setState({ rxPcnNumber: e.target.value })
                  let { modalPatientRows } = this.state
                  modalPatientRows[index].rxPcnNumber = e.target.value
                  modalPatientRows[index].primaryInsurance.benefits.rxpcn =
                    e.target.value
                  this.setState({ modalPatientRows })
                }}
                className={styles.textInput}
                style={{ width: '100%' }}
              />
            </div>
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>RX Group</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder={'RX Group'}
                type="text"
                value={this.state.modalPatientRows[index].rxGroup}
                onChange={(e) => {
                  this.setState({ rxGroup: e.target.value })
                  let { modalPatientRows } = this.state
                  modalPatientRows[index].rxGroup = e.target.value
                  modalPatientRows[index].primaryInsurance.benefits.rxgroup =
                    e.target.value
                  this.setState({ modalPatientRows })
                }}
                className={styles.textInput}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </>
        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/* Pharmacy */}
        <>
          <div className={styles.row}>
            <p className={styles.rowLabel} style={{ fontSize: 'large' }}>
              Preferred/Local Pharmacy
            </p>
          </div>
          {this.renderPharmacySearchModal(index)}
          <div className={styles.row}>
            <p className={styles.rowLabel}>Name:</p>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <input
                placeholder="Search Pharmacy"
                className={styles.textInput}
                // style={{ backgroundColor: '#a6a6a6', width: 'auto' }}
                style={{ width: 'auto' }}
                type="text"
                // disabled={true}
                onChange={(e) => {
                  let currentRows = this.state.modalPatientRows
                  let pharmacy = currentRows[index].pharmacy
                  pharmacy.name = e.target.value
                  // currentRows[index].pharmacy.name = e.target.value
                  currentRows[index].pharmacy = pharmacy
                  this.setState({ modalPatientRows: currentRows })
                }}
                value={
                  Array.isArray(
                    this.state.modalPatientRows[index].pharmacy?.name,
                  )
                    ? this.state.modalPatientRows[index].pharmacy.name.join(' ')
                    : this.state.modalPatientRows[index].pharmacy?.name
                }
              />
              <FontAwesomeIcon
                onClick={() => {
                  this.setState({ pharmacySearchModalOpen: true })
                }}
                icon={faSearch}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>Address:</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="Address"
                className={styles.textInput}
                // style={{ backgroundColor: '#a6a6a6', width: '100%' }}
                type="text"
                value={
                  this.state.modalPatientRows[index].pharmacy?.address_1
                    ? this.state.modalPatientRows[index].pharmacy.address_1
                    : ''
                }
                onChange={(e) => {
                  // let currentRows = this.state.modalPatientRows
                  // currentRows[index].pharmacy.address_1 = e.target.value
                  // this.setState({ modalPatientRows: currentRows })

                  let currentRows = this.state.modalPatientRows
                  let pharmacy = currentRows[index].pharmacy
                  pharmacy.address_1 = e.target.value
                  // currentRows[index].pharmacy.name = e.target.value
                  currentRows[index].pharmacy = pharmacy
                  this.setState({ modalPatientRows: currentRows })
                }}
                // disabled={true}
              />
            </div>
          </div>
          <div className={styles.row}>
            <p className={styles.rowLabel}>State:</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="State"
                className={styles.textInput}
                // style={{ backgroundColor: '#a6a6a6', width: '100%' }}
                type="text"
                value={
                  this.state.modalPatientRows[index].pharmacy?.state
                    ? this.state.modalPatientRows[index].pharmacy.state
                    : ''
                }
                onChange={(e) => {
                  // let currentRows = this.state.modalPatientRows
                  // currentRows[index].pharmacy.state = e.target.value
                  // this.setState({ modalPatientRows: currentRows })

                  let currentRows = this.state.modalPatientRows
                  let pharmacy = currentRows[index].pharmacy
                  pharmacy.state = e.target.value
                  // currentRows[index].pharmacy.name = e.target.value
                  currentRows[index].pharmacy = pharmacy
                  this.setState({ modalPatientRows: currentRows })
                }}
                // disabled={true}
              />
            </div>
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>Zip:</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder={'Zip'}
                type="text"
                // disabled={true}
                value={
                  this.state.modalPatientRows[index].pharmacy?.zip
                    ? this.state.modalPatientRows[index].pharmacy.zip
                    : ''
                }
                className={styles.textInput}
                // style={{ backgroundColor: '#a6a6a6', width: '100%' }}
                onChange={(e) => {
                  // let currentRows = this.state.modalPatientRows
                  // currentRows[index].pharmacy.zip = e.target.value
                  // this.setState({ modalPatientRows: currentRows })

                  let currentRows = this.state.modalPatientRows
                  let pharmacy = currentRows[index].pharmacy
                  pharmacy.zip = e.target.value
                  // currentRows[index].pharmacy.name = e.target.value
                  currentRows[index].pharmacy = pharmacy
                  this.setState({ modalPatientRows: currentRows })
                }}
              />
            </div>
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>Phone:</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder={'Phone'}
                type="text"
                value={
                  this.state.modalPatientRows[index].pharmacy?.phone
                    ? this.state.modalPatientRows[index].pharmacy.phone
                    : ''
                }
                // disabled={true}
                className={styles.textInput}
                // style={{ backgroundColor: '#a6a6a6', width: '100%' }}
                onChange={(e) => {
                  // let currentRows = this.state.modalPatientRows
                  // currentRows[index].pharmacy.phone = e.target.value
                  // this.setState({ modalPatientRows: currentRows })

                  let currentRows = this.state.modalPatientRows
                  let pharmacy = currentRows[index].pharmacy
                  pharmacy.phone = e.target.value
                  // currentRows[index].pharmacy.name = e.target.value
                  currentRows[index].pharmacy = pharmacy
                  this.setState({ modalPatientRows: currentRows })
                }}
              />
            </div>
          </div>
        </>
        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/* PCP INFORMATION */}
        <div className={styles.row}>
          <p className={styles.rowLabel} style={{ fontSize: 'large' }}>
            Primary Care Contact
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.rowLabel}>First Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter First Name"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].pcp?.first_name}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].pcp.first_name = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Last Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter First Name"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].pcp?.last_name}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].pcp.last_name = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <p className={styles.rowLabel}>Email</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Email"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].pcp?.email}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].pcp.email = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Phone Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder={'Phone Number'}
              type="number"
              id="phone"
              name="phone"
              //   min="0"
              value={this.state.modalPatientRows[index].pcp?.phone_number}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].pcp.phone_number = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
              className={styles.textInput}
              style={{ width: '50%' }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <p className={styles.rowLabel}>Cell</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder={'Cell Number'}
              type="number"
              id="cell"
              name="cell"
              //   min="0"
              value={this.state.modalPatientRows[index].pcp?.cell}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].pcp.cell = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
              className={styles.textInput}
              style={{ width: '50%' }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Fax Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Mobile number"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].pcp?.fax}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].pcp.fax = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Address</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Address"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].pcp?.address}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].pcp.address = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Emergency Contact Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter emergency contact name"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].emergencyContactName}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].emergencyContactName = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Emergency Contact Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter emergency contact number"
              className={styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.modalPatientRows[index].emergencyContactPhone}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].emergencyContactPhone = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Service Type</p>
          <div style={{ width: '50%' }}>
            <Select
              placeholder="Select Subscription"
              inputId="subscriptiom"
              options={Metadata.subscriptionPlanOptions}
              value={
                modalPatientRows[index].service_type
                  ? modalPatientRows[index].service_type
                  : null
              }
              isMulti={false}
              isSearchable
              autoBlur
              onChange={(val) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].service_type = val
                this.setState({ modalPatientRows: currentRows })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row}>
          <p className={styles.rowLabel}>Notes</p>
          <div style={{ width: '50%' }}>
            <textarea
              placeholder="Enter notes..."
              cols="20"
              rows="4"
              style={{
                width: '100%',
                borderBlockColor: 'lightgray',
                borderRadius: '5px',
                resize: 'none',
              }}
              value={this.state.modalPatientRows[index].notes}
              onChange={(e) => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].notes = e.target.value
                this.setState({ modalPatientRows: currentRows })
              }}
            ></textarea>
          </div>
        </div>

        <div className={styles.row}>
          <label style={{ color: '#20A892' }} class={styles.checkContainer}>
            Ok to leave Voicemail/ Messages?
            <input
              type="checkbox"
              // checked={this.state.modalPatientRows[index].voicemail_messages}
              checked={
                this.state.modalPatientRows[index]?.termsChecked
                  ?.voicemail_messages
              }
              onClick={() => {
                // console.log('clocked')
                // let currentRows = this.state.modalPatientRows
                // currentRows[index].voicemail_messages =
                //   !currentRows[index].voicemail_messages
                // this.setState({ modalPatientRows: currentRows })
                //
                let currentRows = this.state.modalPatientRows
                currentRows[index].termsChecked.voicemail_messages =
                  !currentRows[index].termsChecked.voicemail_messages
                this.setState({ modalPatientRows: currentRows })
              }}
            />
            <span class={styles.checkmark}></span>
          </label>
        </div>

        <div className={styles.row}>
          <label style={{ color: '#20A892' }} class={styles.checkContainer}>
            I authorize the release of any medical or other information
            necessary to process claims for my medical visits. I also request
            payment of government benefits either to myself or to the party who
            accepts assignment.
            <input
              type="checkbox"
              checked={
                this.state.modalPatientRows[index]?.termsChecked
                  ?.medical_release
              }
              onClick={() => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].termsChecked.medical_release =
                  !currentRows[index].termsChecked.medical_release
                this.setState({ modalPatientRows: currentRows })
              }}
            />
            <span class={styles.checkmark}></span>
          </label>
        </div>
        <div className={styles.row}>
          <label style={{ color: '#20A892' }} class={styles.checkContainer}>
            I authorize payment of medical benefits to the undersigned physician
            or supplier for services rendered and described on the claim being
            submitted to the insurance carrier.
            <input
              type="checkbox"
              checked={
                this.state.modalPatientRows[index]?.termsChecked?.payments
              }
              onClick={() => {
                let currentRows = this.state.modalPatientRows
                currentRows[index].termsChecked.payments =
                  !currentRows[index].termsChecked.payments
                this.setState({ modalPatientRows: currentRows })
              }}
            />
            <span class={styles.checkmark}></span>
          </label>
        </div>

        {/*this is ccfm specific*/}
        {/*{*/}
        {/*  userType && userType.includes('ccfm') &&*/}
        {/*  <div className={styles.row}>*/}
        {/*    <p className={styles.rowLabel}>Program Type</p>*/}
        {/*    <div style={{width: '50%'}}>*/}
        {/*      <Select*/}
        {/*        placeholder={'Select program type'}*/}
        {/*        isMulti={false}*/}
        {/*        options={[{label: 'Wellness Program', value: 'Wellness Program'}, {label: 'RPM Program', value: 'RPM Program'}]}*/}
        {/*        value={this.state.modalPatientRows[index].programType}*/}
        {/*        onChange={(val) => {*/}
        {/*          let {modalPatientRows} = this.state*/}
        {/*          modalPatientRows[index].programType = val*/}
        {/*          this.setState({modalPatientRows})*/}
        {/*        }}*/}
        {/*        theme={(theme) => ({*/}
        {/*          ...theme,*/}
        {/*          colors: {*/}
        {/*            ...theme.colors,*/}
        {/*            primary25: '#20A89233',*/}
        {/*            primary: '#20A892',*/}
        {/*          },*/}
        {/*        })}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*}*/}
        {this.state.modalPatientRows[index].error && (
          <p style={{ fontSize: 12, color: 'red' }}>
            {this.state.modalPatientRows[index].errorString}
          </p>
        )}
      </div>
    )
  }

  renderInviteList(data, isAcceptedList) {
    let { userType } = this.props
    let filter = false
    // console.log('testing: ', this.state.filterPatientInvite)
    if (this.state.filterPatientInvite === 'unscheduled') filter = true
    let displayEntries = data
      .filter((invite) => {
        if (
          this.state.filterPatientInvite.value === 'scheduled' &&
          invite.appointment_scheduled
        ) {
          return true
        } else if (
          !invite.appointment_scheduled &&
          this.state.filterPatientInvite.value === 'unscheduled'
        ) {
          return true
        }
      })
      .map(
        (x, i) =>
          x.email && (
            <div
              key={i.toString()}
              className={styles.inviteRow}
              style={{
                position: 'relative',
                backgroundColor: i % 2 === 0 ? '#e8e8e8' : '',
              }}
            >
              <p
                className={styles.inviteEntryColumn}
                style={{
                  width: '20%',
                }}
              >
                {x.first_name + ' ' + x.last_name}
              </p>
              <p
                className={styles.inviteEntryColumn}
                style={{
                  width: '30%',
                }}
              >
                {x.email}
              </p>
              <p
                className={styles.inviteEntryColumn}
                style={{
                  width: '20%',
                }}
              >
                {x.phone}
              </p>
              <div
                className={styles.inviteEntryColumn}
                style={{
                  width: '20%',
                }}
              >
                {isAcceptedList
                  ? moment(x.accepted_date).format('MMM Do YYYY')
                  : moment(x.created_at).format('MMM Do YYYY')}
              </div>
              <p
                className={styles.inviteEntryColumn}
                style={{
                  width: '20%',
                  textDecoration: 'underline',
                  textDecorationColor: Constants.primaryTheme,
                  color: Constants.primaryTheme,
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
                data-tip={
                  'Insurance info: ' +
                    (x?.mongoInsurance?.[0]?.insurance_company
                      ?.insurance_name ||
                      x?.mongoInsurance?.[0]?.insurance_provider ||
                      'N/A') +
                    '<br /> Notes: ' +
                    x.notes || ''
                }
                data-for={i + 'note'}
              >
                Notes
              </p>
              <ReactTooltip
                id={i + 'note'}
                // multiline={true}
                place={'top'}
                effect={'solid'}
                clickable={true}
                html={true}
                className={styles.tooltip}
              />
              {/* { 'Insurance info: ' +
                (
                  x?.mongoInsurance?.[0]?.insurance_company?.insurance_name ||
                  x?.mongoInsurance?.[0]?.insurance_provider || 'N/A'
                ) +
                '\n Notes: ' + x.notes || ''
              }
            </ReactTooltip> */}
              <p
                className={styles.inviteEntryColumn}
                style={{
                  width: '10%',
                }}
              >
                {x?.mongoPatient?.[0]?.stripe?.customer_id ? 'Added' : 'N/A'}
              </p>
              <p
                className={styles.inviteEntryColumn}
                style={{
                  width: userType && userType.includes('ccfm') ? '30%' : '30%',
                  visibility: x.patient_id ? 'visible' : 'hidden',
                }}
              >
                <Button
                  onClick={() => {
                    this.getPatientCareTeam(x.patient_id)
                    this.setState({ selectedSchedulePatient: x })
                  }}
                  className={GlobalStyles.button}
                  style={{ width: 200 }}
                  variant="primary"
                >
                  Schedule
                </Button>
              </p>

              {/* {!isAcceptedList && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '10%',
              justifyContent: 'space-evenly',
            }}
          >
            <FontAwesomeIcon
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure you want to resend the invitation email to ${x.email}?`,
                  )
                ) {
                  this.onResendEmailPressed(x)
                }
              }}
              icon={faFileExport}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                cursor: 'pointer',
              }}
            />

            <FontAwesomeIcon
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure you want to revoke invitation to ${x.email}?`,
                  )
                ) {
                  this.onDeletePatientInvitePressed(x.email)
                }
              }}
              icon={faTrash}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                cursor: 'pointer',
              }}
            />
          </div>
        )} */}
            </div>
          ),
      )

    return displayEntries
  }

  renderPendingInvitesList() {
    let { doctorInvites } = this.state
    if (!doctorInvites) return null
    let { userType } = this.props
    return (
      <div
        className={styles.listWrapper}
        style={{ marginTop: 20, height: 500 }}
      >
        <div className={styles.inviteRow}>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: '20%',
            }}
          >
            Name
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: '30%',
            }}
          >
            Email
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: '20%',
            }}
          >
            Phone Number
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: '20%',
            }}
          >
            Sent Date
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: '20%',
            }}
          >
            Notes
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: '10%',
            }}
          >
            CC Detail
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: '30%',
            }}
          >
            Schedule Appointment
          </p>
        </div>
        {this.renderInviteList(doctorInvites.invites_sent, false)}
      </div>
    )
  }

  renderAcceptedInvitesList() {
    let { doctorInvites } = this.state
    let { userType } = this.props
    if (!doctorInvites) return null
    return (
      <div className={styles.listWrapper} style={{ marginTop: 20 }}>
        <div className={styles.inviteRow}>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: userType && userType.includes('ccfm') ? '20%' : '30%',
            }}
          >
            Name
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: userType && userType.includes('ccfm') ? '30%' : '30%',
            }}
          >
            Email
          </p>
          <p
            className={styles.inviteEntryColumnHeader}
            style={{
              width: userType && userType.includes('ccfm') ? '20%' : '30%',
            }}
          >
            Accepted Date
          </p>
          {userType && userType.includes('ccfm') && (
            <p
              className={styles.inviteEntryColumnHeader}
              style={{ width: '20%' }}
            >
              Program
            </p>
          )}
        </div>
        {this.renderInviteList(doctorInvites.invites_accepted, true)}
      </div>
    )
  }

  onSubmitCohortsPressed() {
    let { cohortsToAdd } = this.state
    let toSend = []
    cohortsToAdd.every((each) => {
      if (
        (!each && cohortsToAdd.length === 1) ||
        (each.length === 0 && cohortsToAdd.length === 0)
      ) {
        this.setState({
          cohortModalErrorString: 'Please Enter at least one value',
          cohortModalError: true,
        })
        return false
      }
      if (each.length > 0) toSend.push(each)
      return true
    })

    if (toSend.length > 0) {
      this.setState({ modalLoading: true })
      let newToSend = []
      toSend.forEach((each) => {
        let strings = each.split(' ')
        let stringsArrayCapitalized = strings.map((x) => this.capitalize(x))
        let newString = stringsArrayCapitalized.join(' ')
        newToSend.push(newString)
      })
      this.addCohortsToDoctorProfile(newToSend)
    } else {
      this.setState({
        cohortModalErrorString: 'Please Enter at least one value',
        cohortModalError: true,
      })
    }
  }

  onAddCohortClicked() {
    this.setState({
      modalIsOpen: true,
      modalType: 'cohorts',
      cohortsToAdd: [''],
    })
  }

  onBatchPatientCohortsPressed() {
    this.setState({
      modalIsOpen: true,
      modalType: 'batch',
    })
  }

  onInvitePatientsClicked(referredPatient) {
    let { doctorInvites } = this.state
    console.log('doctor invites:', doctorInvites.invites_sent)
    console.log('referrals data:', referredPatient)
    this.setState({
      modalIsOpen: true,
      modalType: 'invite-patients',
      modalPatientRows: [
        {
          firstName: referredPatient ? referredPatient?.firstName : '',
          lastName: referredPatient ? referredPatient?.lastName : '',
          email: referredPatient ? referredPatient?.email : '',
          ethnicity: referredPatient ? referredPatient?.ethnicity : null,
          language: referredPatient ? referredPatient?.language : null,
          // REFERRAL INVITE
          referral: referredPatient ? true : false,
          referral_id: referredPatient ? referredPatient?._id : '',
          //NEW FIELDS ADDED
          phone: referredPatient ? referredPatient?.phone : '',
          gender: null,
          dob: referredPatient ? moment(referredPatient?.dob) : new Date(),
          address: referredPatient ? referredPatient?.address : '',
          address2: referredPatient ? referredPatient?.address2 : null,
          addressComponents: referredPatient
            ? referredPatient?.addressComponents
            : null,
          zipcode: referredPatient ? referredPatient?.zipcode : '',
          emergencyContactName: referredPatient
            ? referredPatient?.emergencyContactName
            : '',
          emergencyContactPhone: referredPatient
            ? referredPatient?.emergencyContactPhone
            : '',
          state: referredPatient ? referredPatient?.state : null,
          requested_specialities: referredPatient
            ? referredPatient?.specialty
            : null,
          cohorts: referredPatient ? referredPatient?.program : [],
          primaryInsurance: {
            _id: null,
            subscriber_id: '', // it is the policy number/member ID; change UI to show Member ID
            group_number: '',
            plan_type: '',
            member_name: '',
            front_image_url: null,
            back_image_url: null,
            insurance_company: {
              insurance_isp: '', // returned if selected insurance from the search result
              insurance_address_1: '', // enter only if it is not present in the search list
              insurance_city: '',
              insurance_state: '',
              insurance_zip: '',
              insurance_name: '',
            },
            insured: {
              insured_address_1: '',
              insured_city: '',
              insured_state: null,
              insured_zip: '',
              insured_first_name: '',
              insured_last_name: '',
              insured_sex: null,
              insured_relationship: null,
              insured_home_phone: '',
            },
            preference: 1,
            medicaid: false,
            medicare: false,
            benefits: {
              category: null,
              copay: '',
              coinsurance: '',
              deductible: '',
              telemedicine: false,
              office_visit_copay: '',
              deductible_calendar: '',
              deductible_date: '',
              // PBM
              pbm: '',
              insuranceState: '',
              rxbin: '',
              rxpcn: '',
              rxgroup: '',
            },
          },
          pcp: {
            first_name: '',
            last_name: '',
            address: '',
            phone_number: '',
            cell: '',
            fax: '',
            email: '',
          },
          termsChecked: {
            messages: true,
            policy: true,
            medical_release: false,
            payments: false,
          },
          hasInsurance: referredPatient?.payer ? true : false,
          insurance_company: referredPatient ? referredPatient?.payer : '',
          member_number: referredPatient ? referredPatient?.insurance : '',
          provider_contact: '',
          medicaid: false,
          medicare: false,
          service_type: '',
          notes: '',
          action_item: '',
          error: false,
          errorString: '',
          voicemail_messages: null,
          // PBM
          pbm: '',
          insuranceState: '',
          rxbin: '',
          rxpcn: '',
          rxgroup: '',

          // Pharmacy
          pharmacy: {
            name: '',
            address_1: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
          },
        },
      ],
    })
  }

  onSendInvitesClicked() {
    let { shortDynamicLink } = this.state
    if (!shortDynamicLink) {
      toast.error('Invitation System is Down. Try again later.')
      return
    }

    let { userType } = this.props
    let modalRows = this.state.modalPatientRows
    let errorExists = false
    for (let i = 0; i < modalRows.length; i++) {
      let entry = modalRows[i]

      if (
        !entry.email ||
        entry.email.length === 0 ||
        !this.validateEmail(entry.email)
      ) {
        errorExists = true
        modalRows[i].error = true
        modalRows[i].errorString = MODAL_ERROR_EMAIL
      }

      if (
        !entry.firstName ||
        entry.firstName.length === 0 ||
        !entry.lastName ||
        entry.lastName.length === 0
      ) {
        errorExists = true
        modalRows[i].error = true
        modalRows[i].errorString = MODAL_ERROR_NAME
      }
      if (!entry.gender) {
        errorExists = true
        modalRows[i].error = true
        modalRows[i].errorString = MODAL_ERROR_GENDER
      }

      if (
        userType &&
        userType.includes('ccfm') &&
        (!entry.cohorts || entry.cohorts.length === 0)
      ) {
        errorExists = true
        modalRows[i].error = true
        modalRows[i].errorString = MODAL_ERROR_COHORT
      }

      // if(!errorExists && userType && userType.includes('ccfm') && !entry.programType) {
      //   errorExists = true
      //   modalRows[i].error = true
      //   modalRows[i].errorString = MODAL_ERROR_PROGRAM
      // }
    }

    this.setState({
      modalPatientRows: modalRows,
    })

    if (!errorExists) {
      if (
        window.confirm(
          'Are you sure you want to send invites to ' +
            modalRows.length +
            ' users?',
        )
      ) {
        this.setState({ modalLoading: true })
        this.sendInvites()
      }
    } else {
      toast.error('Please correct the errors')
    }
  }

  onModalClosePressed() {
    this.setState({
      modalIsOpen: false,
      modalType: '',
      batchPatientList: null,
      batchCohortList: null,
      surveyResultDate: null,
      is_dateSubmitted_enabled: false,
      dateList: [],
    })
  }

  onAddPatientsToCohortsClicked() {
    let { batchPatientList, batchCohortList } = this.state
    if (!batchPatientList) {
      this.setState({
        batchModalError: true,
        batchModalErrorString: 'Please select at least one patient',
      })
      return
    }

    if (!batchCohortList) {
      this.setState({
        batchModalError: true,
        batchModalErrorString: 'Please select at least one cohort',
      })
      return
    }

    this.setState({ modalLoading: true })

    let patientIds = []
    let cohorts = []
    batchPatientList.forEach((patient) => {
      patientIds.push(patient.value)
    })

    batchCohortList.forEach((cohort) => {
      cohorts.push(cohort.value)
    })

    this.sendBatchRequestToBackend(patientIds, cohorts)
  }

  onTeammemberInvitePressed() {
    let { teamModalFName, teamModalLName, teamModalEmail, teamModalType } =
      this.state
    if (!teamModalFName || teamModalFName.length === 0) {
      this.setState({
        teamModalError: true,
        teamModalErrorString: 'Please Enter a valid first name',
      })
      return
    }

    if (!teamModalLName || teamModalLName.length === 0) {
      this.setState({
        teamModalError: true,
        teamModalErrorString: 'Please Enter a valid last name',
      })
      return
    }

    if (
      !teamModalEmail ||
      teamModalEmail.length === 0 ||
      !this.validateEmail(teamModalEmail)
    ) {
      this.setState({
        teamModalError: true,
        teamModalErrorString: 'Please Enter a valid email',
      })
      return
    }

    if (!teamModalType) {
      this.setState({
        teamModalError: true,
        teamModalErrorString: 'Please select member type from dropdown',
      })
      return
    }

    this.setState({ teamModalError: false })

    if (
      window.confirm(
        `Are you sure you want to send invite to ${teamModalEmail} ?`,
      )
    ) {
      this.setState({ teamModalLoading: true })
      this.sendTeamInvite()
    }
  }

  sendTeamInvite() {
    let { teamModalFName, teamModalLName, teamModalEmail, teamModalType } =
      this.state
    let { adminId, mongoUser, token } = this.props
    let stringToEncode = adminId + '+' + teamModalEmail
    let encodedString = btoa(stringToEncode)
    let inviteLink =
      window.location.origin + '/signup/team-member?q=' + encodedString

    const self = this
    let url = Config.BACKEND_URL + 'provider/team/invites'
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: {
        doctor_id: mongoUser?.firebase_id,
        first_name: teamModalFName,
        last_name: teamModalLName,
        email: teamModalEmail,
        user_type: teamModalType.value,
        admin_name: mongoUser.first_name + ' ' + mongoUser.last_name,
        organization: mongoUser.organization,
        link: inviteLink,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let data = response.data
          self.setState({
            teamModalLoading: false,
            teamInvites: data,
            modalIsOpen: false,
            modalType: '',
          })
          toast.success('Invites Sent')
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when sending invites to team member', error, token)
        toast.error('Error when trying to send invites')
        self.setState({ teamModalLoading: false })
      })
  }

  async sendBatchRequestToBackend(patientIds, cohorts) {
    const self = this
    let { mongoUser, token } = this.props
    let url = Config.BACKEND_URL + 'provider/patients/batch-cohorts'
    await axios({
      method: 'post',
      url: url,
      headers: {
        x_doctor_id: mongoUser?.firebase_id,
        Authorization: 'JWT ' + token,
      },
      data: {
        patient_ids: patientIds,
        cohorts: cohorts,
        doctor_id: mongoUser?.firebase_id,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          self.setState({
            modalLoading: false,
            modalIsOpen: false,
            modalType: '',
            loading: true,
            // patientList: null,
            batchPatientList: null,
            batchCohortList: null,
          })
          // self.props.fetchPatientsList(mongoUser.firebase_id, token)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when sending batch requests', error, token)
        toast.error('Error when adding patient to cohorts')
      })
    this.getPatientCohorts()
  }

  renderModalCohortRow(x, index) {
    return (
      <div
        key={index.toString()}
        style={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <input
          placeholder={'Enter cohort to add....'}
          className={styles.textInput}
          type="text"
          value={x}
          onChange={(e) => {
            let text = e.target.value
            let { cohortsToAdd } = this.state
            cohortsToAdd[index] = text
            this.setState({
              cohortsToAdd: cohortsToAdd,
              cohortModalError: false,
            })
          }}
        />
        {this.state.cohortsToAdd.length > 1 && (
          <FontAwesomeIcon
            onClick={() => {
              let { cohortsToAdd } = this.state
              cohortsToAdd.splice(index, 1)
              this.setState({ cohortsToAdd })
            }}
            icon={faTrash}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 20,
              cursor: 'pointer',
            }}
          />
        )}
      </div>
    )
  }

  renderCohortsModal() {
    let cohorts =
      this.props.mongoUser && this.props.mongoUser.cohorts
        ? this.props.mongoUser.cohorts
        : []
    let cohortsString = cohorts.map((x, index) => (
      <span key={x}>
        {x}
        {index !== cohorts.length - 1 && ', '}
      </span>
    ))
    let { cohortsToAdd } = this.state
    let cohortEntries = cohortsToAdd.map((x, index) => {
      return this.renderModalCohortRow(x, index)
    })
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <p
          className={styles.closeModalBtn}
          onClick={() => this.onModalClosePressed()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Create Cohorts
        </h4>

        {/*<p style={{marginTop: 20, textAlign: 'center', marginBottom: 40}}>*/}
        {/*  <span style={{fontWeight: 'bold'}}>Current Options :</span> {cohortsString}*/}
        {/*</p>*/}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            height: '80%',
            overflowY: 'scroll',
            paddingBottom: 40,
            paddingTop: 20,
          }}
        >
          {cohortEntries}
          <p
            onClick={() => {
              let { cohortsToAdd } = this.state
              cohortsToAdd.push('')
              this.setState({ cohortsToAdd })
            }}
            style={{
              cursor: 'pointer',
              marginTop: 20,
              textDecoration: 'underline',
              color: Constants.primaryTheme,
            }}
          >
            Add More
          </p>
        </div>

        <p
          style={{
            fontSize: 12,
            color: 'red',
            marginTop: 10,
            visibility: this.state.cohortModalError ? 'visible' : 'hidden',
          }}
        >
          {this.state.cohortModalErrorString}
        </p>

        <Button
          onClick={this.onSubmitCohortsPressed.bind(this)}
          className={GlobalStyles.button}
          style={{
            width: 300,
            marginTop: 40,
            position: 'absolute',
            bottom: 20,
          }}
          variant="primary"
        >
          Create
        </Button>
      </div>
    )
  }

  renderInvitePatientsModal() {
    let modalPatientRows = this.state.modalPatientRows
    let entries = modalPatientRows.map((x, i) =>
      this.renderModalEntryForm(i, modalPatientRows),
    )
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={styles.closeModalBtn}
          onClick={() => this.onModalClosePressed()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Invite and Add Patients
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            height: '80%',
            overflowY: 'scroll',
            paddingBottom: 40,
          }}
        >
          {/*<div style={{marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>*/}
          {/*  <p style={{marginRight: 14}}>Unique Link: </p>*/}
          {/*  <p*/}
          {/*    style={{textAlign: 'center', color: 'blue', textDecoration: 'underline'}}>*/}
          {/*    {this.state.shortDynamicLink}*/}
          {/*  </p>*/}

          {/*</div>*/}

          {entries}
          {/* <p
            onClick={this.onAddMorePatientsClicked.bind(this)}
            style={{
              cursor: 'pointer',
              marginTop: 20,
              textDecoration: 'underline',
              color: Constants.primaryTheme,
              flex: 'none',
            }}
          >
            Add More
          </p> */}
        </div>

        <Button
          onClick={this.onSendInvitesClicked.bind(this)}
          className={GlobalStyles.button}
          style={{ position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Send Invitations
        </Button>
        {this.state.modalError && (
          <p style={{ fontSize: 12, color: 'red', marginTop: 4 }}>
            {this.state.modalErrorString}
          </p>
        )}
      </div>
    )
  }

  renderSurveyModal() {
    let { teamModalLoading } = this.state
    if (teamModalLoading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={styles.closeModalBtn}
          onClick={() => this.onModalClosePressed()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}></h4>

        <div className={styles.patientModalEntryForm}>
          <div className={styles.row}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              First Name
            </p>
            <input
              style={{ width: '60%' }}
              placeholder={'Enter first name....'}
              className={styles.textInput}
              type="text"
              value={this.state.teamModalFName}
              onChange={(e) => {
                this.setState({ teamModalFName: e.target.value })
              }}
            />
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              Last Name
            </p>
            <input
              style={{ width: '60%' }}
              placeholder={'Enter last name....'}
              className={styles.textInput}
              type="text"
              value={this.state.teamModalLName}
              onChange={(e) => {
                this.setState({ teamModalLName: e.target.value })
              }}
            />
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              Team Member Email
            </p>
            <input
              style={{ width: '60%' }}
              placeholder={'Enter email address....'}
              className={styles.textInput}
              type="text"
              value={this.state.teamModalEmail}
              onChange={(e) => {
                this.setState({ teamModalEmail: e.target.value })
              }}
            />
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              Team Member Type
            </p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select member type...'}
                options={Metadata.userTypes}
                value={this.state.teamModalType}
                onChange={(val) => this.setState({ teamModalType: val })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        </div>

        {this.state.teamModalError && (
          <p style={{ fontSize: 12, color: 'red', marginTop: 20 }}>
            {this.state.teamModalErrorString}
          </p>
        )}

        <Button
          onClick={this.onTeammemberInvitePressed.bind(this)}
          className={GlobalStyles.button}
          style={{ width: 250, position: 'absolute', bottom: 40 }}
          variant="primary"
        >
          Invite
        </Button>
      </div>
    )
  }

  getSurveyPatientList(surveyAnswers) {
    let pList = []
    let result = this.state.patientList.filter((p1) =>
      surveyAnswers.some((p2) => p1.patient_id === p2.patient_id),
    )
    result.forEach((p) => {
      pList.push({
        label: p.firstName + ' ' + p.lastName,
        value: p.patient_id,
      })
    })

    this.setState({
      surveyPatientList: pList,
      viewSurveyLoading: false,
      editSurveyLoading: false,
    })
  }
  getDateList(val) {
    var surveyAnsList = this.state.surveyAnswers.filter(
      (x) => x.patient_id === val.value,
    )
    let dateList = []
    surveyAnsList.forEach((x) => {
      dateList.push({
        label: moment(x.created_at).format('MMM Do YYYY'),
        value: x.created_at,
      })
    })
    return dateList
  }

  viewSurveyAnswers(val) {
    this.setState({ surveyResultDate: val })
    var surveyAns = this.state.surveyAnswers.find(
      (x) =>
        x.patient_id === this.state.patientSelected &&
        x.created_at === val.value,
    )
    this.setState({ survey: surveyAns })

    let { surveyDetails } = this.state
    let q = []
    Object.keys(surveyDetails.questions).forEach((x, i) => {
      q.push(surveyDetails.questions[x])
    })

    this.setState({
      questionsElements: q.map((x, i) => {
        console.log('x', x)
        return this.renderSurveyAnswers(x, i, surveyAns.answers[i + 1])
      }),
    })
  }

  renderSurveyAnswers(x, i, ans) {
    let question = x.text
    let options = x.options
    return (
      <div className={styles.surveyQuestionForm}>
        <div style={{ alignItems: 'center', width: '100%' }}>
          <div>
            {x.type === 'free' && (
              <>
                <div>
                  <p>
                    {i + 1}. {question}
                  </p>
                </div>
                <div>
                  <p style={{ color: Constants.primaryTheme }}>
                    Ans. {ans.text}
                  </p>
                </div>
              </>
            )}
            {x.type === 'range' && (
              <>
                <div>
                  <p>
                    {i + 1}. {question}
                  </p>
                </div>
                <div>
                  <p style={{ color: Constants.primaryTheme }}>
                    Ans. {ans.value} / {x.range.high}{' '}
                  </p>
                </div>
              </>
            )}
            {x.type === 'multiple' && (
              <>
                <div>
                  <p>
                    {i + 1}. {question}
                  </p>
                </div>
                <div>
                  <p style={{ color: Constants.primaryTheme }}>
                    Ans. {JSON.stringify(ans.options).slice(1, -1)}{' '}
                  </p>
                </div>
              </>
            )}
            {x.type === 'single' && (
              <>
                <div>
                  <p>
                    {i + 1}. {question}
                  </p>
                </div>
                <div>
                  <p style={{ color: Constants.primaryTheme }}>
                    Ans. {ans.option}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  enableDateSubmitted(val) {
    if (val.value !== this.state.patientSelected) {
      this.setState({ surveyResultDate: null })
    }
    this.setState({ dateList: this.getDateList(val) })
    this.setState({ is_dateSubmitted_enabled: false })
    this.setState({ patientSelected: val.value, questionsElements: '' })
  }

  renderViewSurveyResults() {
    let { viewSurveyLoading, surveyPatientList } = this.state

    if (viewSurveyLoading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ height: '100vh', backgroundColor: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    if (surveyPatientList.length > 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            width: '100%',
          }}
        >
          <div className={styles.row} style={{ marginLeft: 20 }}>
            <p className={styles.rowLabel} style={{ marginRight: 10 }}>
              Patients:
            </p>
            <div style={{ width: '75%' }}>
              <Select
                inputId={'patientList'}
                options={surveyPatientList}
                autoBlur={true}
                onChange={(val) => this.enableDateSubmitted(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={styles.row} style={{ marginLeft: 20 }}>
            <p className={styles.rowLabel} style={{ marginRight: 10 }}>
              Date Submitted:
            </p>
            <div style={{ width: '75%' }}>
              <Select
                inputId={'dateSubmitted'}
                options={this.state.dateList}
                autoBlur={true}
                value={this.state.surveyResultDate}
                isDisabled={this.state.is_dateSubmitted_enabled}
                onChange={(val) => this.viewSurveyAnswers(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          {this.state.questionsElements}

          <div style={{ paddingTop: '50px' }} />
        </div>
      )
    } else {
      return (
        <div>
          <h6 style={{ color: Constants.primaryTheme, marginTop: '120%' }}>
            No patient has taken the survey yet
          </h6>
        </div>
      )
    }
  }

  renderViewSurveyModal() {
    return (
      <div className={styles.divider}>
        <p
          className={styles.closeModalBtn}
          onClick={() => this.onModalClosePressed()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme }}>Survey Results</h4>
        {this.renderViewSurveyResults()}
      </div>
    )
  }

  renderViewSurvey() {
    let { surveyQuestions } = this.state
    let questionsElements = surveyQuestions.map((x, i) =>
      this.renderQuestionForm(x, i),
    )
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 10,
          width: '100%',
        }}
      >
        <div className={styles.row} style={{ marginTop: 10, marginLeft: 20 }}>
          <p className={styles.rowLabel} style={{ marginRight: 45 }}>
            {' '}
            Date of Survey Taken
          </p>
          <div style={{ width: '40%' }}>
            <input
              type="date"
              id="startDate"
              name="startDate"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row} style={{ marginLeft: 20 }}>
          <p className={styles.rowLabel} style={{ marginRight: 10 }}>
            Patients:
          </p>
          <div style={{ width: '75%' }}>
            <Select
              inputId={'cadence'}
              options={[
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              value={this.state.cadence}
              autoBlur={true}
              onChange={(val) => this.setState({ cadence: val })}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {/* {questionsElements} */}

        <div
          style={{
            position: 'relative',
            marginTop: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={this.updateSurvey}
            className={GlobalStyles.button}
            style={{ width: 250 }}
            variant="primary"
          >
            View Patient Response
          </Button>
        </div>
      </div>
    )
  }
  renderEditSurveyModal() {
    return (
      <div className={styles.divider}>
        <h4 style={{ color: Constants.primaryTheme }}>Edit Survey</h4>
        {this.renderEditIndividualSurvey()}
      </div>
    )
  }
  getDropDownOdject(dropdownValue) {
    switch (dropdownValue) {
      case 'monthly':
        return { value: 'monthly', label: 'Monthly' }
      case 'weekly':
        return { value: 'weekly', label: 'Weekly' }
      case 'daily':
        return { value: 'daily', label: 'Daily' }
    }
  }
  getDropDownEventType(dropdownValue) {
    switch (dropdownValue) {
      case 'open':
        return { value: 'open', label: 'Open' }
      case 'closed':
        return { value: 'closed', label: 'Closed' }
    }
  }
  getCohortsDropdown(dropdownvalue) {
    return { label: dropdownvalue, value: dropdownvalue }
  }

  renderEditIndividualSurvey() {
    let { editSurveyQuestions, editSurveyLoading, surveyAnswers } = this.state
    let questionsElements = editSurveyQuestions.map((x, i) =>
      this.renderEditQuestionForm(x, i),
    )
    if (editSurveyLoading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ height: '100vh', backgroundColor: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    if (surveyAnswers.length == 0) {
      const classes = `d-flex float-right`
      return (
        <div className="w-100">
          <p
            className={styles.closeModalBtn}
            onClick={() => this.onModalClosePressed()}
          >
            X
          </p>

          <div className={classes}>
            <div style={{ color: Constants.primaryTheme }}>Inactive</div>
            <Switch
              className={'mx-2'}
              onColor={Constants.primaryThemeDark}
              onChange={(e) => this.setState({ editSurveyChecked: e })}
              checked={this.state.editSurveyChecked}
            />
            <div style={{ color: Constants.primaryTheme }}>Active</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 10,
              width: '100%',
            }}
          >
            <div>
              <div className={styles.row}>
                <p className={styles.rowLabel}>Survey Title:</p>
                <input
                  placeholder={'Please Enter Title'}
                  className={styles.textInput}
                  type="text"
                  value={this.state.editSurveyTitle}
                  onChange={(e) =>
                    this.setState({ editSurveyTitle: e.target.value })
                  }
                />
              </div>
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>Description:</p>
              {/* <textarea
            placeholder={'Please Enter Description'}
            style={{width: '75%', height: 100, padding: '5px 20px', resize: 'none', border: 0, color: '#c8c8c8'}}
            value={this.state.editSurveyDesc}
            onChange={(e) => this.setState({editSurveyDesc: e.target.value})}/> */}
              <textarea
                placeholder={'Please Enter Description'}
                className={styles.textInput}
                rows="4"
                cols="50"
                value={this.state.editSurveyDesc}
                onChange={(e) =>
                  this.setState({ editSurveyDesc: e.target.value })
                }
              />
            </div>
            <div>
              <div className={styles.row}>
                <p className={styles.rowLabel}>Start Date:</p>
                <input
                  //placeholder={'Please Enter Title'}
                  className={styles.textInput}
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={moment(this.state.editSurveyStartDate).format(
                    'YYYY-MM-DD',
                  )}
                  defaultValue={moment(this.state.editSurveyStartDate).format(
                    'YYYY-MM-DD',
                  )}
                  onChange={(val) =>
                    this.setState({ editSurveyStartDate: val.target.value })
                  }
                />
              </div>
            </div>
            <div>
              <div className={styles.row}>
                <p className={styles.rowLabel}>End Date:</p>
                <input
                  //placeholder={'Please Enter Title'}
                  className={styles.textInput}
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={moment(this.state.editSurveyEndDate).format(
                    'YYYY-MM-DD',
                  )}
                  defaultValue={moment(this.state.editSurveyEndDate).format(
                    'YYYY-MM-DD',
                  )}
                  onChange={(val) =>
                    this.setState({ editSurveyEndDate: val.target.value })
                  }
                />
              </div>
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>Frequency</p>
              <Select
                options={[
                  { label: 'Daily', value: 'daily' },
                  { label: 'Weekly', value: 'weekly' },
                  { label: 'Monthly', value: 'monthly' },
                ]}
                className={'w-50'}
                value={this.getDropDownOdject(this.state.editSurveyCadence)}
                autoBlur={true}
                onChange={(val) => this.setState({ editSurveyCadence: val })}
              />
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>Cohort List</p>
              <Select
                placeholder={'Select cohorts'}
                isMulti={true}
                isSearchable={true}
                className={'w-50'}
                // options={this.state.cohortOptions}
                // value={this.state.surveyCohortList}
                // value={this.getCohortsDropdown(this.state.surveyCohortList)}
                options={Metadata.COHORT_OPTIONS}
                value={this.state.editSurveyCohortList}
                // value={{label: 'Diabetes', value: 'Diabetes'}}
                onChange={(val) =>
                  this.setState({
                    editSurveyCohortList: val,
                    surveyModalError: false,
                  })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            {/* <div className={styles.row} style={{marginLeft: 20}}>
            <p className={styles.rowLabel} style={{marginRight: 10}}>Cohort List</p>
              <div style={{width: '75%'}}>
                  <Select
                    placeholder={'Select cohorts'}
                    isMulti={true}
                    isSearchable={true}
                    // options={this.state.cohortOptions}
                    // value={this.state.surveyCohortList}
                    // value={this.getCohortsDropdown(this.state.surveyCohortList)}
                    options={this.state.cohortOptions}
                    value={this.state.editSurveyCohortList}
                    // value={{label: 'Diabetes', value: 'Diabetes'}}
                    onChange={(val) => this.setState({editSurveyCohortList: val, surveyModalError: false})}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
            </div> */}
            {questionsElements}

            <p
              onClick={() => {
                let { editSurveyQuestions } = this.state
                editSurveyQuestions.push({
                  question: '',
                  type: null,
                  options: ['', ''],
                  range: [],
                })
                this.setState({ editSurveyQuestions })
              }}
              style={{
                textDecoration: 'underline',
                marginTop: 10,
                color: Constants.primaryTheme,
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              Add More
            </p>

            <p
              style={{
                fontSize: 18,
                align: 'center',
                color: 'red',
                position: 'relative',
                marginTop: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.state.editSurveyError}
            </p>
            <div
              style={{
                position: 'relative',
                marginTop: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={this.updateSurvey}
                className={GlobalStyles.button}
                style={{ width: 250, marginBottom: '50px' }}
                variant="primary"
              >
                Update Survey
              </Button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          {' '}
          <p
            className={styles.closeModalBtn}
            onClick={() => this.onModalClosePressed()}
          >
            X
          </p>
          <h6 style={{ color: Constants.primaryTheme, marginTop: '50%' }}>
            Patients have already submitted their responses. So, you can't edit
            the survey. Please create a new survey.
          </h6>
        </div>
      )
    }
  }

  renderTeamModal() {
    let { teamModalLoading } = this.state
    if (teamModalLoading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={styles.closeModalBtn}
          onClick={() => this.onModalClosePressed()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Invite Team Member
        </h4>

        <div className={styles.patientModalEntryForm}>
          <div className={styles.row}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              First Name
            </p>
            <input
              style={{ width: '60%' }}
              placeholder={'Enter first name....'}
              className={styles.textInput}
              type="text"
              value={this.state.teamModalFName}
              onChange={(e) => {
                this.setState({ teamModalFName: e.target.value })
              }}
            />
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              Last Name
            </p>
            <input
              style={{ width: '60%' }}
              placeholder={'Enter last name....'}
              className={styles.textInput}
              type="text"
              value={this.state.teamModalLName}
              onChange={(e) => {
                this.setState({ teamModalLName: e.target.value })
              }}
            />
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              Team Member Email
            </p>
            <input
              style={{ width: '60%' }}
              placeholder={'Enter email address....'}
              className={styles.textInput}
              type="text"
              value={this.state.teamModalEmail}
              onChange={(e) => {
                this.setState({ teamModalEmail: e.target.value })
              }}
            />
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <p className={styles.rowLabel} style={{ width: '30%' }}>
              Team Member Type
            </p>
            <div style={{ width: '60%' }}>
              <Select
                placeholder={'Select member type...'}
                options={Metadata.userTypes}
                value={this.state.teamModalType}
                onChange={(val) => this.setState({ teamModalType: val })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        </div>

        {this.state.teamModalError && (
          <p style={{ fontSize: 12, color: 'red', marginTop: 20 }}>
            {this.state.teamModalErrorString}
          </p>
        )}

        <Button
          onClick={this.onTeammemberInvitePressed.bind(this)}
          className={GlobalStyles.button}
          style={{ width: 250, position: 'absolute', bottom: 40 }}
          variant="primary"
        >
          Invite
        </Button>
      </div>
    )
  }

  renderBatchModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={styles.closeModalBtn}
          onClick={() => this.onModalClosePressed()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Patients To Cohorts
        </h4>

        <div className={styles.row} style={{ marginTop: 50 }}>
          <p className={styles.rowLabel} style={{ marginRight: 10 }}>
            Cohort List
          </p>
          <div style={{ width: '80%' }}>
            <Select
              placeholder={'Select cohorts'}
              isMulti={true}
              isSearchable={true}
              options={this.state.cohortOptions}
              value={this.state.batchCohortList}
              onChange={(val) =>
                this.setState({ batchCohortList: val, batchModalError: false })
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={styles.row} style={{ marginTop: 50 }}>
          <p className={styles.rowLabel} style={{ marginRight: 10 }}>
            Patient List
          </p>
          <div style={{ width: '80%' }}>
            <Select
              placeholder={'Select patients'}
              isMulti={true}
              isSearchable={true}
              options={this.state.batchPatientOptions}
              value={this.state.batchPatientList}
              onChange={(val) =>
                this.setState({ batchPatientList: val, batchModalError: false })
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <Button
          onClick={this.onAddPatientsToCohortsClicked.bind(this)}
          className={GlobalStyles.button}
          style={{ width: 250, position: 'absolute', bottom: 40 }}
          variant="primary"
        >
          Add
        </Button>

        {this.state.batchModalError && (
          <p style={{ fontSize: 12, color: 'red' }}>
            {this.state.batchModalErrorString}
          </p>
        )}
      </div>
    )
  }

  renderModal() {
    let { modalType } = this.state
    if (this.state.modalLoading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    if (modalType === 'cohorts') {
      return this.renderCohortsModal()
    } else if (modalType === 'invite-patients') {
      return this.renderInvitePatientsModal()
    } else if (modalType === 'batch') {
      return this.renderBatchModal()
    } else if (modalType === 'team') {
      return this.renderTeamModal()
    } else if (modalType === 'survey') {
      return this.renderSurveyModal()
    } else if (modalType === 'edit-survey') {
      return this.renderEditSurveyModal()
    } else if (modalType === 'view-survey') {
      return this.renderViewSurveyModal()
    } else if (modalType === 'add-event') {
      return this.renderAddEventModal()
    }
  }

  getCohortsMap() {
    let { patientCohortList } = this.state
    if (!patientCohortList) return null

    let toReturn = {}
    patientCohortList.forEach((patient) => {
      let cohorts = patient.cohorts || []
      cohorts.forEach((cohort) => {
        let tempArray = toReturn[cohort] || []
        let name = patient.first_name + ' ' + patient.last_name
        tempArray.push(name)
        toReturn[cohort] = tempArray
      })
    })

    return toReturn
  }

  renderCurrentList() {
    let data = this.getCohortsMap()
    let { mongoUser } = this.props
    let { cohortOptions } = this.state
    let cohorts = cohortOptions || []
    console.log('TESTING COHO: ', cohorts, data)
    let rows = cohorts.map((cohort) => {
      let patientNames = data[cohort.label] || []
      let patients = patientNames.join(', ')
      return (
        <tr>
          <td style={{ width: '25%' }}>{cohort.label}</td>
          <td style={{ width: '60%', textAlign: 'left' }}>{patients}</td>
          <td style={{ width: '15%' }}>{patientNames.length}</td>
        </tr>
      )
    })

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Cohort</th>
              <th style={{ width: '60%' }}>Patient Names</th>
              <th style={{ width: '15%' }}>Total</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    )
  }

  onRevokeAccessPressed(index) {
    let { teamInvites } = this.state
    let { token, mongoUser } = this.props
    let acceptedList = teamInvites.invites_accepted
    let self = this
    let url = Config.BACKEND_URL + 'provider/team/invites-accepted'
    this.setState({ teamInvitesLoading: true })
    axios({
      method: 'DELETE',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser?.firebase_id,
        x_teammember_id: acceptedList[index].firebase_id,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          let data = response.data
          toast.success('Revoked access to team member')
          self.setState({
            teamInvitesLoading: false,
            teamInvites: data,
            teamInviteTabIndex: 2,
          })
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when revoking teammmates access', error)
        toast.error('Something went wrong')
        self.setState({ teamInvitesLoading: false })
      })
  }

  onRemoveInvitePressed(index) {
    let { teamInvites } = this.state
    let { token, mongoUser } = this.props
    let sentList = teamInvites.invites_sent
    let self = this
    let url = Config.BACKEND_URL + 'provider/team/invites-sent'
    this.setState({ teamInvitesLoading: true })
    axios({
      method: 'DELETE',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser?.firebase_id,
        email: sentList[index].email,
      },
    })
      .then(function (response) {
        // handle success
        sentList.splice(index, 1)
        toast.success('Cancelled invitation')
        self.setState({ teamInvitesLoading: false, teamInvites: teamInvites })
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting deleting invitation', error)
        toast.error('Something went wrong')
        self.setState({ teamInvitesLoading: false })
      })
  }

  renderTeamPendingInvitesList(list) {
    let displayEntries = list.map((x, i) => (
      <div
        key={i.toString()}
        className={styles.inviteRow}
        style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
      >
        <p className={styles.inviteListEntry} style={{ width: '25%' }}>
          {x.first_name + ' ' + x.last_name}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '25%' }}>
          {x.email}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '25%' }}>
          {x.user_type}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '25%' }}>
          {moment(x.sent).format('MMM Do YYYY')}
        </p>

        <div
          onClick={() => this.onRemoveInvitePressed(i)}
          style={{ position: 'absolute', right: 10, cursor: 'pointer' }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            style={{ color: Constants.primaryTheme, fontSize: 20 }}
          />
        </div>
      </div>
    ))

    return (
      <div className={styles.listWrapper} style={{ marginTop: 20 }}>
        <div className={styles.inviteRow}>
          <p className={styles.inviteListHeader} style={{ width: '25%' }}>
            Name
          </p>
          <p className={styles.inviteListHeader} style={{ width: '25%' }}>
            Email
          </p>
          <p className={styles.inviteListHeader} style={{ width: '25%' }}>
            Member Type
          </p>
          <p className={styles.inviteListHeader} style={{ width: '25%' }}>
            Sent Date
          </p>
        </div>

        {displayEntries}
      </div>
    )
  }

  renderTeamAcceptedInvitesList(list) {
    let displayEntries = list.map((x, i) => (
      <div
        key={i.toString()}
        className={styles.inviteRow}
        style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
      >
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {x.first_name + ' ' + x.last_name}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {x.email}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {x.user_type}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {moment(x.accepted).format('MMM Do YYYY')}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {x.status}
        </p>

        {x.status === 'active' && (
          <div
            onClick={() => this.onRevokeAccessPressed(i)}
            style={{ position: 'absolute', right: 10, cursor: 'pointer' }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        )}
      </div>
    ))
    return (
      <div className={styles.listWrapper} style={{ marginTop: 20 }}>
        <div className={styles.inviteRow}>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Name
          </p>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Email
          </p>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Member Type
          </p>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Accepted Date
          </p>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Status
          </p>
        </div>

        {displayEntries}
      </div>
    )
  }
  getEditCohotsList(val) {
    let objVal = []
    val.map((x) => {
      objVal.push({ label: x, value: x })
    })
    return objVal
  }

  viewSurveyResults(event, x) {
    this.getSurveyDetails(x._id)
    this.setState({
      modalIsOpen: true,
      modalType: 'view-survey',
      surveyDetails: x,
      questionsElements: '',
      viewSurveyLoading: true,
    })
  }
  editSurvey(event, x) {
    this.getSurveyDetails(x._id)
    this.setState({
      modalIsOpen: true,
      modalType: 'edit-survey',
      editSurvey: x,
      edit_id: x._id,
      editSurveyTitle: x.title,
      editSurveyDesc: x.description,
      editSurveyCadence: x.cadence,
      editSurveyChecked: x.status == 'active' ? true : false,
      editSurveyCohortList: this.getEditCohotsList(x.cohorts),
      editSurveyQuestions: this.surveyQuestionsToArray(x.questions),
      editSurveyStartDate: x.start,
      editSurveyEndDate: x.end,
      editSurveyStatus: x.status,
      editSurveyLoading: true,
    })
  }

  renderSurveyList(list) {
    let displayEntries = list.map((x, i) => (
      <div
        key={i.toString()}
        className={styles.inviteRow}
        style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
      >
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {x.title}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '10%' }}>
          {x.cadence}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {moment(x.start).format('MMM Do YYYY')}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {x.end ? moment(x.end).format('MMM Do YYYY') : '-'}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '20%' }}>
          {x.status}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '10%' }}></p>

        <div
          onClick={(event) => this.editSurvey(event, x)}
          style={{ position: 'absolute', right: 35, cursor: 'pointer' }}
        >
          <FontAwesomeIcon
            icon={faEdit}
            style={{ color: Constants.primaryTheme, fontSize: 20 }}
          />
        </div>
        <div
          onClick={(event) => this.viewSurveyResults(event, x)}
          style={{ position: 'absolute', right: 10, cursor: 'pointer' }}
        >
          <FontAwesomeIcon
            icon={faListUl}
            style={{ color: Constants.primaryTheme, fontSize: 20 }}
          />
        </div>
      </div>
    ))
    return (
      <div className={styles.listWrapper} style={{ marginTop: 20 }}>
        <div className={styles.inviteRow}>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Survey Title
          </p>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}>
            Cadence
          </p>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Start Date
          </p>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            End Date
          </p>
          <p className={styles.inviteListHeader} style={{ width: '20%' }}>
            Status
          </p>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}></p>
        </div>

        {displayEntries}
      </div>
    )
  }

  onSurveyQuestionDeletePressed(index) {
    let { surveyQuestions } = this.state
    if (surveyQuestions.length === 1) return

    surveyQuestions.splice(index, 1)
    this.setState({ surveyQuestions })
  }
  onEditSurveyQuestionDeletePressed(index) {
    let { editSurveyQuestions } = this.state
    if (editSurveyQuestions.length === 1) return

    editSurveyQuestions.splice(index, 1)
    this.setState({ editSurveyQuestions })
  }

  getOptionsElement(index) {
    let { surveyQuestions } = this.state
    let questionType = surveyQuestions[index].type
    if (!questionType) return null

    if (questionType.value.includes('free')) {
      return null
    } else if (questionType.value.includes('range')) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            marginTop: 10,
            padding: '0px 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p>Lowest</p>
            <input
              defaultValue={'1'}
              className={styles.textInput}
              style={{
                color: Constants.primaryTheme,
                backgroundColor: '#e8e8e8',
                marginLeft: 10,
                width: 40,
                padding: 4,
              }}
              type="text"
              value={surveyQuestions[index].range[0]}
              onChange={(e) => {
                let { surveyQuestions } = this.state
                surveyQuestions[index].range[0] = e.target.value
                this.setState({ surveyQuestions })
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 10,
              alignItems: 'center',
            }}
          >
            <p>Highest</p>
            <input
              defaultValue={'10'}
              className={styles.textInput}
              style={{
                color: Constants.primaryTheme,
                backgroundColor: '#e8e8e8',
                marginLeft: 10,
                width: 40,
                padding: 4,
              }}
              type="text"
              value={surveyQuestions[index].range[1]}
              onChange={(e) => {
                let { surveyQuestions } = this.state
                surveyQuestions[index].range[1] = e.target.value
                this.setState({ surveyQuestions })
              }}
            />
          </div>
        </div>
      )
    } else {
      let options = surveyQuestions[index].options
      let optionsElement = options.map((x, i) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <input
            placeholder={`option ${i + 1}`}
            className={styles.textInput}
            style={{
              color: Constants.primaryTheme,
              width: '80%',
              marginTop: 4,
            }}
            type="text"
            value={surveyQuestions[index].options[i]}
            onChange={(e) => {
              let { surveyQuestions } = this.state
              surveyQuestions[index].options[i] = e.target.value
              this.setState({ surveyQuestions })
            }}
          />
          {options.length > 2 && (
            <FontAwesomeIcon
              onClick={() => {
                let { surveyQuestions } = this.state
                let options = surveyQuestions[index].options
                options.splice(i, 1)
                this.setState({ surveyQuestions })
              }}
              icon={faTrash}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                cursor: 'pointer',
                marginLeft: 14,
              }}
            />
          )}
        </div>
      ))
      return (
        <div style={{ padding: 20, width: '100%' }}>
          {optionsElement}
          <p
            onClick={() => {
              let { surveyQuestions } = this.state
              surveyQuestions[index].options.push('')
              this.setState({ surveyQuestions })
            }}
            style={{
              marginTop: 8,
              textDecoration: 'underline',
              color: 'gray',
              cursor: 'pointer',
              marginLeft: 20,
            }}
          >
            Add option
          </p>
        </div>
      )
    }
  }
  getOptionsElementEdit(index) {
    let { editSurveyQuestions } = this.state
    let questionType = editSurveyQuestions[index].type
    if (!questionType) return null

    if (questionType.value.includes('free')) {
      return null
    } else if (questionType.value.includes('range')) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            marginTop: 10,
            padding: '0px 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p>Lowest</p>
            <input
              placeholder={'1'}
              className={styles.textInput}
              style={{
                color: Constants.primaryTheme,
                backgroundColor: '#e8e8e8',
                marginLeft: 10,
                width: 40,
                padding: 4,
              }}
              type="text"
              value={editSurveyQuestions[index].range[0]}
              onChange={(e) => {
                let { editSurveyQuestions } = this.state
                editSurveyQuestions[index].range[0] = e.target.value
                this.setState({ editSurveyQuestions })
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 10,
              alignItems: 'center',
            }}
          >
            <p>Highest</p>
            <input
              placeholder={'10'}
              className={styles.textInput}
              style={{
                color: Constants.primaryTheme,
                backgroundColor: '#e8e8e8',
                marginLeft: 10,
                width: 40,
                padding: 4,
              }}
              type="text"
              value={editSurveyQuestions[index].range[1]}
              onChange={(e) => {
                let { editSurveyQuestions } = this.state
                editSurveyQuestions[index].range[1] = e.target.value
                this.setState({ editSurveyQuestions })
              }}
            />
          </div>
        </div>
      )
    } else {
      let options = editSurveyQuestions[index].options
      let optionsElement = options.map((x, i) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <input
            placeholder={`option ${i + 1}`}
            className={styles.textInput}
            style={{
              color: Constants.primaryTheme,
              width: '80%',
              marginTop: 4,
            }}
            type="text"
            value={editSurveyQuestions[index].options[i]}
            onChange={(e) => {
              let { editSurveyQuestions } = this.state
              editSurveyQuestions[index].options[i] = e.target.value
              this.setState({ editSurveyQuestions })
            }}
          />
          {options.length > 2 && (
            <FontAwesomeIcon
              onClick={() => {
                let { editSurveyQuestions } = this.state
                let options = editSurveyQuestions[index].options
                options.splice(i, 1)
                this.setState({ editSurveyQuestions })
              }}
              icon={faTrash}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                cursor: 'pointer',
                marginLeft: 14,
              }}
            />
          )}
        </div>
      ))
      return (
        <div style={{ padding: 20, width: '100%' }}>
          {optionsElement}
          <p
            onClick={() => {
              let { editSurveyQuestions } = this.state
              editSurveyQuestions[index].options.push('')
              this.setState({ editSurveyQuestions })
            }}
            style={{
              marginTop: 8,
              textDecoration: 'underline',
              color: 'gray',
              cursor: 'pointer',
              marginLeft: 20,
            }}
          >
            Add option
          </p>
        </div>
      )
    }
  }
  renderQuestionForm(question, index) {
    let optionsElement = this.getOptionsElement(index)
    return (
      <div className={styles.surveyQuestionForm}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <input
            placeholder={'Please Enter the Question'}
            className={styles.textInput}
            style={{
              color: Constants.primaryTheme,
              border: '0px',
              width: '65%',
              backgroundColor: '#e8e8e8',
            }}
            type="text"
            //value="first value"
            value={this.state.surveyQuestions[index].question}
            onChange={(e) => {
              let { surveyQuestions } = this.state
              surveyQuestions[index].question = e.target.value
              this.setState({ surveyQuestions })
            }}
          />

          <div style={{ width: '35%', marginLeft: 8 }}>
            <Select
              placeholder={'Question Type'}
              isMulti={false}
              options={SURVEY_QUESTION_TYPES}
              value={this.state.surveyQuestions[index].type}
              onChange={(val) => {
                let { surveyQuestions } = this.state
                surveyQuestions[index].type = val
                this.setState({ surveyQuestions })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {optionsElement}
        <div style={{ fontSize: 12, color: 'red' }}>
          {this.state.surveyQuestions[index].error}
        </div>
        {this.state.surveyQuestions.length > 1 && (
          <FontAwesomeIcon
            onClick={() => this.onSurveyQuestionDeletePressed(index)}
            icon={faTrash}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              position: 'absolute',
              bottom: 10,
              right: 10,
              cursor: 'pointer',
            }}
          />
        )}
      </div>
    )
  }
  renderEditQuestionForm(question, index) {
    let optionsElement = this.getOptionsElementEdit(index)

    return (
      <div className={styles.surveyQuestionForm}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <input
            placeholder={'Please Enter the Question'}
            className={styles.textInput}
            style={{
              color: Constants.primaryTheme,
              border: '0px',
              width: '65%',
              backgroundColor: '#e8e8e8',
            }}
            type="text"
            //value="first value"
            value={this.state.editSurveyQuestions[index].question}
            onChange={(e) => {
              let { editSurveyQuestions } = this.state
              editSurveyQuestions[index].question = e.target.value
              this.setState({ editSurveyQuestions })
            }}
          />
          <div style={{ width: '35%', marginLeft: 8 }}>
            <Select
              placeholder={'Question Type'}
              isMulti={false}
              options={SURVEY_QUESTION_TYPES}
              value={this.state.editSurveyQuestions[index].type}
              onChange={(val) => {
                let { editSurveyQuestions } = this.state
                editSurveyQuestions[index].type = val
                this.setState({ editSurveyQuestions })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {optionsElement}
        <div style={{ fontSize: 12, color: 'red' }}>
          {this.state.editSurveyQuestions[index].error}
        </div>

        {this.state.editSurveyQuestions.length > 1 && (
          <FontAwesomeIcon
            onClick={() => this.onEditSurveyQuestionDeletePressed(index)}
            icon={faTrash}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              position: 'absolute',
              bottom: 10,
              right: 10,
              cursor: 'pointer',
            }}
          />
        )}
      </div>
    )
  }
  renderIndividualSurvey() {
    let { surveyQuestions } = this.state
    let questionsElements = surveyQuestions.map((x, i) =>
      this.renderQuestionForm(x, i),
    )
    return (
      <form className={'w-100'} onSubmit={this.createSurvey}>
        <p
          className={styles.closeModalBtn}
          onClick={() => this.onModalClosePressed()}
        >
          X
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            width: '100%',
          }}
        >
          <div className={styles.row}>
            <p className={styles.rowLabel}>Survey Title:</p>
            <input
              placeholder={'Please Enter Title'}
              className={styles.textInput}
              type="text"
              value={this.state.surveyTitle}
              onChange={(e) => this.setState({ surveyTitle: e.target.value })}
            />
          </div>
          <div className={styles.row}>
            <p className={styles.rowLabel}>Description:</p>
            <textarea
              placeholder={'Please Enter Description'}
              className={styles.textInput}
              rows="4"
              cols="50"
              value={this.state.surveyDesc}
              onChange={(e) => this.setState({ surveyDesc: e.target.value })}
            />
          </div>

          <div>
            <div className={styles.row}>
              <p className={styles.rowLabel}>Start Date:</p>
              <input
                //placeholder={'Please Enter Title'}
                className={styles.textInput}
                type="date"
                id="startDate"
                name="startDate"
                min={moment(new Date()).format('YYYY-MM-DD')}
                value={moment(this.state.startDate).format('YYYY-MM-DD')}
                defaultValue={moment(this.state.startDate).format('YYYY-MM-DD')}
                onChange={(val) =>
                  this.setState({ startDate: val.target.value })
                }
              />
            </div>
          </div>
          <div>
            <div className={styles.row}>
              <p className={styles.rowLabel}>End Date:</p>
              <input
                //placeholder={'Please Enter Title'}
                className={styles.textInput}
                type="date"
                id="endDate"
                name="endDate"
                min={moment(new Date()).format('YYYY-MM-DD')}
                value={moment(this.state.endDate).format('YYYY-MM-DD')}
                defaultValue={moment(this.state.endDate).format('YYYY-MM-DD')}
                onChange={(val) => this.setState({ endDate: val.target.value })}
              />
            </div>
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>Frequency</p>
            <Select
              options={[
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              className={'w-50'}
              value={this.getDropDownOdject(this.state.cadence)}
              autoBlur={true}
              onChange={(val) => this.setState({ cadence: val })}
            />
          </div>

          <div className={styles.row}>
            <p className={styles.rowLabel}>Cohort List</p>
            <Select
              placeholder={'Select cohorts'}
              isMulti={true}
              isSearchable={true}
              className={'w-50'}
              // options={this.state.cohortOptions}
              // value={this.state.surveyCohortList}
              // value={this.getCohortsDropdown(this.state.surveyCohortList)}
              options={this.state.cohortOptions}
              value={this.state.surveyCohortList}
              // value={{label: 'Diabetes', value: 'Diabetes'}}
              onChange={(val) =>
                this.setState({
                  surveyCohortList: val,
                  surveyModalError: false,
                })
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          {/* <div className={styles.row} style={{marginLeft: 20}}>
          <p className={styles.rowLabel} style={{marginRight: 10}}>Cadence</p>
          <div style={{width: '75%'}}>
            <Select required={true}
              inputId={'cadence'}
              options={[{label: 'Daily', value: 'daily'}, {label: 'Weekly', value: 'weekly'}, {label: 'Monthly', value: 'monthly'}]}
              value={this.state.cadence}
              autoBlur={true}
              onChange={(val) => this.setState({cadence: val})}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>
        <div className={styles.row} style={{marginLeft: 20}}>
          <p className={styles.rowLabel} style={{marginRight: 10}}>Cohort List</p>
            <div style={{width: '75%'}}>
                <Select
                  placeholder={'Select cohorts'}
                  isMulti={true}
                  isSearchable={true}
                  options={this.state.cohortOptions}
                  value={this.state.surveyCohortList}
                  onChange={(val) => this.setState({surveyCohortList: val, surveyModalError: false})}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
          </div> */}
          {questionsElements}

          <p
            onClick={() => {
              let { surveyQuestions } = this.state
              surveyQuestions.push({
                question: '',
                type: null,
                options: ['', ''],
                range: [],
              })
              this.setState({ surveyQuestions })
            }}
            style={{
              textDecoration: 'underline',
              marginTop: 10,
              color: Constants.primaryTheme,
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            Add More
          </p>

          <p
            style={{
              fontSize: 18,
              align: 'center',
              color: 'red',
              position: 'relative',
              marginTop: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {this.state.surveyError}
          </p>
          <div
            style={{
              position: 'relative',
              marginTop: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              type="submit"
              className={GlobalStyles.button}
              style={{ width: 250, marginBottom: '50px' }}
              variant="primary"
            >
              Create Survey
            </Button>
          </div>
        </div>
      </form>
    )
  }

  renderSurveyModal() {
    return (
      <div className={styles.divider}>
        <h4 style={{ color: Constants.primaryTheme }}>Create Survey</h4>
        {this.renderIndividualSurvey()}
      </div>
    )
  }
  renderTeamView() {
    let { teamInvitesLoading, teamInvites } = this.state
    if (teamInvitesLoading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ height: '100vh', backgroundColor: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    let pendingList = (teamInvites && teamInvites.invites_sent) || []
    let acceptedList = (teamInvites && teamInvites.invites_accepted) || []

    return (
      <div className={styles.divider}>
        <h4 style={{ color: Constants.primaryTheme }}>Team Members</h4>
        <p style={{ fontSize: 14 }}>Invite Team Members</p>

        <div style={{ width: '90%', marginTop: 10 }}>
          <Button
            onClick={() =>
              this.setState({
                modalIsOpen: true,
                modalType: 'team',
              })
            }
            className={GlobalStyles.button}
            style={{ width: 250 }}
            variant="primary"
          >
            Invite Team Member
          </Button>
        </div>

        <InviteTabs
          activeTab={this.state.teamInviteTabIndex}
          onInviteTabSelected={(index) => {
            this.setState({
              renderTeamAcceptedList: index === 2,
              teamInviteTabIndex: index,
            })
          }}
        />

        {this.state.renderTeamAcceptedList
          ? this.renderTeamAcceptedInvitesList(acceptedList)
          : this.renderTeamPendingInvitesList(pendingList)}
      </div>
    )
  }

  handleCommunicationsCallback = (childData) => {
    if (childData.success) {
      console.log('going to admin')
      this.setState({ selectedTab: 'invite' })
    }
  }

  navigateToCms1500() {
    console.log('I am here')
    // this.setState({ selectedTab: 'cms_1500' })
    this.onTabSelected(16)
  }

  renderPracticeInfo() {
    return (
      <div className={styles.divider}>
        <PracticeInfo
          parentCallback={this.handleCommunicationsCallback.bind(this)}
        />
      </div>
    )
  }

  renderPaymentTab() {
    return (
      <div className={styles.divider}>
        <ErrorBoundary>
          <PaymentTab
            parentCallback={this.handleCommunicationsCallback.bind(this)}
            getAllPatients={true}
            navigateToCms={this.navigateToCms1500.bind(this)}
          />
        </ErrorBoundary>
      </div>
    )
  }

  renderCMS1500Tab() {
    return (
      <div className={styles.divider}>
        <CmsTab parentCallback={this.handleCommunicationsCallback.bind(this)} />
      </div>
    )
  }

  renderProviderReferralsTab() {
    return (
      <div className={styles.divider}>
        <ProviderReferrals
          invitePatientClicked={this.onInvitePatientsClicked.bind(this)}
          parentCallback={this.handleCommunicationsCallback.bind(this)}
        />
      </div>
    )
  }

  renderTimeSpentTab() {
    return (
      <div className={styles.divider}>
        <TimeSpent
          invitePatientClicked={this.onInvitePatientsClicked.bind(this)}
          parentCallback={this.handleCommunicationsCallback.bind(this)}
        />
      </div>
    )
  }

  renderProviderInsights() {
    return (
      <div className={styles.divider}>
        <ProviderInsights />
      </div>
    )
  }

  arGraph() {
    return (
      <div className={styles.divider}>
        <ArGraph />
      </div>
    )
  }

  renderQualityManagementTab() {
    return (
      <div className={styles.divider}>
        <QualityManagement
          parentCallback={this.handleCommunicationsCallback.bind(this)}
        />
      </div>
    )
  }

  renderCommunications() {
    return (
      <div className={styles.divider}>
        <Communications
          parentCallback={this.handleCommunicationsCallback.bind(this)}
        />
      </div>
    )
  }

  renderGroupCredentialing() {
    return (
      <div className={styles.divider}>
        <GroupCredentialing
          parentCallback={this.handleCommunicationsCallback.bind(this)}
        />
      </div>
    )
  }

  renderClinicalStudyInfo() {
    return (
      <div className={styles.divider}>
        <ClinicalStudy />
      </div>
    )
  }

  surveyListView() {
    let { surveyListLoading, teamInvites } = this.state
    if (surveyListLoading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ height: '100vh', backgroundColor: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div className={styles.divider}>
        <h4 style={{ color: Constants.primaryTheme }}>Survey List</h4>
        <p style={{ fontSize: 14 }}>Patients Survey List</p>

        <div style={{ width: '90%', marginTop: 10 }}>
          <Button
            onClick={() =>
              this.setState({
                modalIsOpen: true,
                modalType: 'survey',
              })
            }
            className={GlobalStyles.button}
            style={{ width: 250 }}
            variant="primary"
          >
            Add Survey
          </Button>
        </div>

        {this.renderSurveyList(this.state.surveyList)}
      </div>
    )
  }

  renderCurrentPatientData() {
    return (
      <div className={styles.divider}>
        <h4 style={{ color: Constants.primaryTheme }}>Cohort Management</h4>
        <p style={{ fontSize: 14 }}>
          Create new cohorts or add patients to cohorts
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 20,
            width: '100%',
          }}
        >
          <Button
            onClick={this.onAddCohortClicked.bind(this)}
            className={GlobalStyles.button}
            style={{ width: 200, marginRight: 40 }}
            variant="primary"
          >
            Create Cohorts
          </Button>

          <Button
            onClick={this.onBatchPatientCohortsPressed.bind(this)}
            className={GlobalStyles.button}
            style={{ width: 200 }}
            variant="primary"
          >
            Add Patients
          </Button>
        </div>
        {this.renderCurrentList()}
      </div>
    )
  }

  renderNewInviteData() {
    return (
      <div className={styles.divider}>
        <h4 style={{ color: Constants.primaryTheme }}>Invite Patients</h4>
        <p
          style={{
            textAlign: 'center',
            fontSize: 14,
            width: '60%',
            marginBottom: 20,
          }}
        >
          Invite patients to the app
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
            width: '90%',
          }}
        >
          <div style={{ marginRight: 10 }}>
            <Button
              onClick={this.onInvitePatientsClicked.bind(this)}
              className={GlobalStyles.button}
              style={{ width: 200 }}
              variant="primary"
            >
              Invite Patients
            </Button>
          </div>
          <div style={{ marginRight: 10 }}>
            <Button
              onClick={() => this.getPatientInviteList()}
              className={GlobalStyles.button}
              style={{ width: 200 }}
              variant="primary"
            >
              Refresh
            </Button>
          </div>
          <div style={{ width: '15%' }}>
            <Select
              placeholder={'Select Filter'}
              isMulti={false}
              options={[
                { label: 'Unscheduled', value: 'unscheduled' },
                { label: 'Scheduled', value: 'scheduled' },
                // {label: 'All', value: 'all'}
              ]}
              value={[this.state.filterPatientInvite]}
              onChange={(val) => {
                this.setState({ filterPatientInvite: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {/* <InviteTabs
          activeTab={1}
          onInviteTabSelected={(index) => {
            this.setState({ renderAcceptedList: index === 2 })
          }}
        />

        {this.state.renderAcceptedList
          ? this.renderAcceptedInvitesList()
          :  this.renderPendingInvitesList() } */}

        {this.renderPendingInvitesList()}
      </div>
    )
  }

  renderEvents() {
    let { eventLoading, circleEventData } = this.state
    if (eventLoading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ height: '100vh', backgroundColor: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div className={styles.divider}>
        <h4 style={{ color: Constants.primaryTheme }}>Events</h4>
        <div style={{ width: '90%', marginTop: 10 }}>
          <Button
            onClick={() =>
              this.setState({
                modalIsOpen: true,
                modalType: 'add-event',
              })
            }
            className={GlobalStyles.button}
            style={{ width: 250 }}
            variant="primary"
          >
            Add New Event
          </Button>
        </div>
        {/* <p style={{fontSize: 14}}>Create Circles</p> */}
        {this.renderEventList(circleEventData)}
      </div>
    )
  }

  renderEventList(circleList) {
    console.log(circleList)
    let displayEvents = circleList.map((circle, i) => (
      <div
        key={i.toString()}
        className={styles.inviteRow}
        style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
      >
        <p className={styles.inviteListEntry} style={{ width: '10%' }}>
          {moment(circle.scheduled_date).format('MMM Do YYYY hh:mm A z')}
        </p>
        <p className={styles.inviteListHeader} style={{ width: '10%' }}></p>
        <p
          className={styles.inviteListEntry}
          style={{ width: '30%', textAlign: 'start' }}
        >
          {circle.title}
        </p>
        {/* <p className={styles.inviteListEntry} style={{width: '20%'}}>
          {circle.end ? moment(circle.end).format('MMM Do YYYY'): "-"}
        </p> */}
        <p className={styles.inviteListEntry} style={{ width: '10%' }}>
          {circle.duration &&
            (circle.duration >= 60
              ? `${Math.floor(circle.duration / 60)}h ${circle.duration % 60}m`
              : `${circle.duration}m`)}
        </p>
        <p className={styles.inviteListHeader} style={{ width: '10%' }}></p>
        <p className={styles.inviteListEntry} style={{ width: '10%' }}>
          {circle.recurring ? `${circle.recurring}ly` : 'False'}
        </p>
        <p className={styles.inviteListEntry} style={{ width: '10%' }}>
          <Link
            to={{
              pathname: 'admin/circle',
              circle: circle,
            }}
          >
            Join
          </Link>
        </p>
      </div>
    ))
    return (
      <div className={styles.listWrapper} style={{ marginTop: 20 }}>
        <div className={styles.inviteRow}>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}>
            Scheduled Date
          </p>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}></p>
          <p className={styles.inviteListHeader} style={{ width: '30%' }}>
            Circle Title
          </p>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}>
            Circle Duration
          </p>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}></p>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}>
            Recurring
          </p>
          <p className={styles.inviteListHeader} style={{ width: '10%' }}>
            Join
          </p>
        </div>

        {displayEvents}
      </div>
    )
  }

  renderAddEventModal() {
    return (
      <div className={styles.divider}>
        <h4
          style={{
            color: Constants.primaryTheme,
            marginBottom: 50,
            alignSelf: 'center',
          }}
        >
          Create Circles
        </h4>
        <form className={'w-100'} onSubmit={this.createEvent}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 10,
              width: '100%',
            }}
          >
            <p
              className={styles.closeModalBtn}
              onClick={() => this.onModalClosePressed()}
            >
              X
            </p>
            <div className={styles.row}>
              <p className={styles.rowLabel}>Title of the Event:</p>
              <input
                placeholder={'Please Enter Title'}
                className={styles.textInput}
                type="text"
                value={this.state.eventTitle}
                onChange={(e) => this.setState({ eventTitle: e.target.value })}
              />
            </div>
            <div className={styles.row}>
              <p className={styles.rowLabel}>Description:</p>
              <textarea
                placeholder={'Please Enter Description'}
                className={styles.textInput}
                rows="4"
                cols="50"
                value={this.state.eventDesc}
                onChange={(e) => this.setState({ eventDesc: e.target.value })}
              />
            </div>

            <div>
              <div className={styles.row}>
                <p className={styles.rowLabel}>Schedule Datetime:</p>
                <input
                  //placeholder={'Please Enter Title'}
                  className={styles.textInput}
                  type="datetime-local"
                  id="eventStartDate"
                  name="eventStartDate"
                  min={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
                  value={moment(this.state.eventStartDate).format(
                    'YYYY-MM-DDTHH:mm',
                  )}
                  // defaultValue={moment(this.state.eventStartDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}
                  onChange={(val) =>
                    this.setState({ eventStartDate: val.target.value })
                  }
                />
              </div>
            </div>

            <div>
              <div className={styles.row}>
                <p className={styles.rowLabel}>Duration:</p>
                <Select
                  options={[
                    { label: '15m', value: '15' },
                    { label: '30m', value: '30' },
                    { label: '45m', value: '45' },
                    { label: '1hr', value: '60' },
                    { label: '1hr 30m', value: '90' },
                    { label: '2hrs', value: '120' },
                  ]}
                  className={'w-50'}
                  value={this.getDropDownEventType(this.state.eventDuration)}
                  autoBlur={true}
                  onChange={(val) => this.setState({ eventDuration: val })}
                />
              </div>
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>Event Type:</p>
              <Select
                options={[
                  { label: 'Open', value: 'open' },
                  { label: 'Closed', value: 'closed' },
                ]}
                className={'w-50'}
                value={this.getDropDownEventType(this.state.eventType)}
                autoBlur={true}
                onChange={(val) => this.setState({ eventType: val })}
              />
            </div>

            <div className={styles.row}>
              <p className={styles.rowLabel}>Recurring:</p>
              <Select
                options={[
                  { label: 'False', value: false },
                  { label: 'Daily', value: 'day' },
                  { label: 'Weekly', value: 'week' },
                  { label: 'Monthly', value: 'month' },
                ]}
                className={'w-50'}
                value={this.getDropDownEventType(this.state.eventRecurring)}
                autoBlur={true}
                onChange={(val) => this.setState({ eventRecurring: val })}
              />
            </div>

            <p
              style={{
                fontSize: 18,
                align: 'center',
                color: 'red',
                position: 'relative',
                marginTop: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.state.surveyError}
            </p>
            <div
              style={{
                position: 'relative',
                marginTop: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                type="submit"
                className={GlobalStyles.button}
                style={{ width: 250, marginBottom: '50px' }}
                variant="primary"
              >
                Create Event
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  renderPatientAssistanceForm() {}

  renderTabContent() {
    let { selectedTab } = this.state
    switch (selectedTab) {
      case 'current':
        return this.renderCurrentPatientData()
      case 'invite':
        return this.renderNewInviteData()
      case 'team':
        return this.renderTeamView()
      case 'survey':
        return this.surveyListView()
      case 'clinical study':
        return this.renderClinicalStudyInfo()
      case 'events':
        return this.renderEvents()
      case 'communications':
        return this.renderCommunications()
      case 'provider_roster':
        return <ProviderRoster />
      case 'patient_roster':
        return <RosterTable />
      case 'practice_info':
        return this.renderPracticeInfo()
      case 'payment':
        return this.renderPaymentTab()
      case 'group-credentialing':
        return this.renderGroupCredentialing()
      case 'provider_referrals':
        return this.renderProviderReferralsTab()
      case 'time_spent':
        return this.renderTimeSpentTab()
      case 'quality_management':
        return this.renderQualityManagementTab()
      case 'patient_assistance_form':
        return <PatientAssistanceFormTable />
      case 'cms_1500':
        return this.renderCMS1500Tab()
      case 'provider_insights':
        return this.renderProviderInsights()
      case 'ar_graph':
        return this.arGraph()
      default:
        return null
    }
  }

  render() {
    // if (this.state.loading) {
    //   return (
    //     <div className={GlobalStyles.container} style={{ height: '100vh' }}>
    //       <div
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //           minWidth: '100%',
    //           height: '100%',
    //         }}
    //       >
    //         <div className={GlobalStyles.loader} />
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div className={GlobalStyles.container}>
        <Header header={'Admin Portal'} />
        <div className={GlobalStyles.contentWrapper}>
          <Tabs
            // onTabSelected={this.onTabSelected.bind(this)}
            onTabSelected={this.onTabSelected}
            selectedTab={this.state.selectedTab}
            userType={this.props.userType}
            user_role={this.props?.mongoUser?.user_type}
            authorization_level={this.props?.mongoUser?.authorization_level}
            default_page={
              this.props?.mongoUser?.authorization_level === 'external_doctor'
                ? 'practice_info'
                : 'patient_roster'
            }
            // Where is this being checked?
            isSupervisor={this.props?.mongoUser?.supervisor}
            hasPaymentsInfoAccess={this.props?.mongoUser?.payments_info_access}
            tabIndex={
              this.state.selectedTab === 'events'
                ? 7
                : this.state.selectedTab === 'invite'
                ? 2
                : this.props?.mongoUser?.authorization_level ===
                  'external_doctor'
                ? 9
                : null
            }
          />
          {this.renderTabContent()}

          <Modal
            ariaHideApp={false}
            onRequestClose={() => this.onModalClosePressed()}
            isOpen={this.state.modalIsOpen} //invite-patients
            style={
              this.state.modalType === 'invite-patients' ||
              this.state.modalType === 'team'
                ? customInvitePatientsModalStyles
                : this.state.modalType === 'survey' ||
                  this.state.modalType === 'edit-survey' ||
                  this.state.modalType === 'view-survey'
                ? customSurveyModalStyles
                : customAddCohortModalStyles
            }
            contentLabel="Modal"
          >
            {this.renderModal()}
          </Modal>

          {/*MODAL TO SCHEDULE APPOINTMENT APPOINTMENT - SHOW CARETEAM MEMBERS */}
          <Modal
            ariaHideApp={false}
            onRequestClose={() => {
              this.setState({
                showScheduleModal: false,
                selectedSchedulePatient: null,
                selectedScheduleDoctor: null,
              })
              this.props.toggleSidebar(false)
            }}
            isOpen={this.state.showScheduleModal}
            style={SCHEDULE_APPOINTMENT_MODAL_STYLES}
            contentLabel="Modal"
          >
            {this.renderScheduleModal()}
          </Modal>

          {this.state.showSearchList && (
            <Modal
              ariaHideApp={false}
              isOpen={this.state.showSearchList}
              onRequestClose={() => {
                this.setState({
                  showSearchList: false,
                  addInsurance: false,
                  searchName: '',
                  searchCity: '',
                  searchAddress: '',
                  searchList: [],
                })
              }}
              style={customInvitePatientsModalStyles}
              contentLabel="Modal"
            >
              {this.renderSearchList()}
            </Modal>
          )}
        </div>
      </div>
    )
  }
}

const InviteTabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.activeTab)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onInviteTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        width: 300,
        height: 40,
        marginTop: 10,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={styles.tabItem}
        style={{ backgroundColor: activeTab === 1 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Pending</p>
      </div>

      <div
        onClick={() => onTabSelected(2)}
        className={styles.tabItem}
        style={{ backgroundColor: activeTab === 2 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Accepted</p>
      </div>
    </div>
  )
}

const ClinicalStudy = (props) => {
  const [title, setTitle] = useState('')
  const [irbId, setIrbId] = useState('')
  const [protocolId, setProtocolId] = useState('')
  const [indNumber, setIndNumber] = useState('')
  const [drugName, setDrugName] = useState('')
  const [trialPhase, setTrialPhase] = useState(null)
  const [doctor, setDoctor] = useState('')
  const [studySite, setStudySite] = useState('')
  const [phone, setPhone] = useState('')
  const [sponsor, setSponsor] = useState('')
  const [principalInvestigator, setPrincipalInvestigator] = useState('')
  const [studySiteTelephone, setStudySiteTelephone] = useState('')
  const [afterHoursTelephone, setAfterHoursTelephone] = useState('')
  const [email, setEmail] = useState('')
  const [study, setStudy] = useState('')

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <h4
        style={{
          color: Constants.primaryTheme,
          width: '100%',
          marginBottom: 10,
          textAlign: 'center',
        }}
      >
        Clinical Study Information
      </h4>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Study Title</p>
        <input
          placeholder={'Enter study title....'}
          className={styles.studyEntryInput}
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>IRB ID</p>
        <input
          placeholder={'Enter IRB ID...'}
          className={styles.studyEntryInput}
          type="text"
          value={irbId}
          onChange={(e) => setIrbId(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Protocol ID</p>
        <input
          placeholder={'Enter protocol ID...'}
          className={styles.studyEntryInput}
          type="text"
          value={protocolId}
          onChange={(e) => setProtocolId(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>IND Number</p>
        <input
          placeholder={'Enter IND number...'}
          className={styles.studyEntryInput}
          type="text"
          value={indNumber}
          onChange={(e) => setIndNumber(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Drug Name</p>
        <input
          placeholder={'Enter drug name...'}
          className={styles.studyEntryInput}
          type="text"
          value={drugName}
          onChange={(e) => setDrugName(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Trial Phase</p>
        <div style={{ width: '60%' }}>
          <Select
            placeholder={'Select trial phase'}
            isMulti={false}
            options={[
              { label: 'Phase I', value: 'Phase I' },
              { label: 'Phase II', value: 'Phase II' },
              { label: 'Phase III', value: 'Phase III' },
              { label: 'Post Market', value: 'Post Market' },
            ]}
            value={trialPhase}
            onChange={(val) => setTrialPhase(val)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Study Doctor</p>
        <input
          placeholder={'Enter study doctor name...'}
          className={styles.studyEntryInput}
          type="text"
          value={doctor}
          onChange={(e) => setDoctor(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Study Site</p>
        <input
          placeholder={'Enter study site...'}
          className={styles.studyEntryInput}
          type="text"
          value={studySite}
          onChange={(e) => setStudySite(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Telephone</p>
        <input
          placeholder={'Enter telephone number...'}
          className={styles.studyEntryInput}
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Sponsor</p>
        <input
          placeholder={'Enter sponsor...'}
          className={styles.studyEntryInput}
          type="text"
          value={sponsor}
          onChange={(e) => setSponsor(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Principal Investigator</p>
        <input
          placeholder={'Enter principal investigator...'}
          className={styles.studyEntryInput}
          type="text"
          value={principalInvestigator}
          onChange={(e) => setPrincipalInvestigator(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Study Site Telephone</p>
        <input
          placeholder={'Enter study site telephone number...'}
          className={styles.studyEntryInput}
          type="text"
          value={studySiteTelephone}
          onChange={(e) => setStudySiteTelephone(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>After Hours Telephone</p>
        <input
          placeholder={'Enter after hours telephone number...'}
          className={styles.studyEntryInput}
          type="text"
          value={afterHoursTelephone}
          onChange={(e) => setAfterHoursTelephone(e.target.value)}
        />
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Email</p>
        <input
          placeholder={'Enter email address...'}
          className={styles.studyEntryInput}
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div
        className={styles.studyEntryRow}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <p className={styles.studyEntryKey}>Resources</p>
        <p
          style={{ color: Constants.primaryTheme, textDecoration: 'underline' }}
        >
          Upload Resources
        </p>
      </div>

      <div className={styles.studyEntryRow}>
        <p className={styles.studyEntryKey}>Study Information</p>
        <textarea
          placeholder={'Paste study information here.....'}
          style={{
            width: '60%',
            height: 300,
            padding: '5px 20px',
            resize: 'none',
            borderColor: '#aeaeae',
            borderRadius: 4,
          }}
          value={study}
          onChange={(e) => setStudy(e.target.value)}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={null}
          className={GlobalStyles.button}
          style={{ width: 300, marginTop: 40 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(
    props?.tabIndex ? props?.tabIndex : 0,
  )

  // Does'nt look like this is being used
  // const onTabSelected = (index) => {
  //   setActiveTab(index)
  //   props.onTabSelected(index)
  // }

  function getTooltip(label) {
    return (
      <ReactTooltip
        id={label}
        textColor="black"
        backgroundColor={Constants.primaryThemeMedium}
        effect="solid"
      >
        <p style={{ color: 'white', fontSize: 18 }}>{label}</p>
      </ReactTooltip>
    )
  }

  function getTab(name, index, icon, fontAwesome) {
    return (
      <div
        data-tip
        data-for={name}
        onClick={() => {
          setActiveTab(index)
          props.onTabSelected(index)
        }}
        className={styles.rowIcon}
        style={{
          backgroundColor:
            activeTab === index
              ? Constants.primaryTheme
              : Constants.primaryThemeDark,
        }}
      >
        {fontAwesome ? (
          <FontAwesomeIcon
            icon={icon}
            style={{ color: 'white', fontSize: 30 }}
          />
        ) : (
          <img src={icon} style={{ height: '75%', width: '75%' }} />
        )}
        {getTooltip(name)}
      </div>
    )
  }
  console.log('props.userType1', props.userType)

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        padding: '20px 40px',
      }}
    >
      {
        // console.log("User Auth Level", props.mongoUser?.authorization_level)
        console.log('User Auth Level', props.authorization_level)
      }
      {props.authorization_level !== 'external_doctor' &&
        getTab('Patient Roster', 0, faUsers, true)}
      {/* faListAlt */}
      {props.authorization_level !== 'external_doctor' &&
        (props.user_role === 'Administrator' || props.isSupervisor) &&
        getTab('Provider Roster', 1, faUserMd, true)}
      {props?.authorization_level !== 'external_doctor' &&
        getTab('Invite Patients', 2, faMailBulk, true)}
      {props.authorization_level !== 'external_doctor' &&
        getTab('Cohorts', 3, faUsers, true)}
      {/*{*/}
      {/*  props.userType && props.userType.includes("admin") &&*/}
      {/*  getTab("Team", 4, faUsersCog, true)*/}
      {/*}*/}
      {props.authorization_level !== 'external_doctor' &&
        props.userType &&
        !props.userType.includes('ccfm') &&
        getTab('Surveys', 5, faFileMedical, true)}

      {props.authorization_level !== 'external_doctor' &&
        props.userType &&
        !props.userType.includes('ccfm') &&
        getTab('Clinical Study', 6, faFileContract, true)}
      {props.authorization_level !== 'external_doctor' &&
        props.userType &&
        !props.userType.includes('ccfm') &&
        getTab('Events', 7, faCalendarAlt, true)}
      {props.authorization_level !== 'external_doctor' &&
        props.userType &&
        !props.userType.includes('ccfm') &&
        getTab('Communications', 8, faHeadphones, true)}

      {(true ||
        (props.userType &&
          !props.userType.includes('ccfm') &&
          (props.user_role === 'Administrator' || props.isSupervisor))) &&
        getTab('Practice Info', 9, faIdCard, true)}
      {(props.authorization_level === 'external_doctor' ||
        (props.userType &&
          !props.userType.includes('ccfm') &&
          // TODO:
          // Need to change the following code to restrict payment details for new Admins
          // Until given the permission to view payment details
          (props.user_role === 'Administrator' ||
            (props.isSupervisor && props.hasPaymentsInfoAccess)))) &&
        getTab('Payments', 10, faCashRegister, true)}
      {props.authorization_level !== 'external_doctor' &&
        props.userType &&
        !props.userType.includes('ccfm') &&
        (props.user_role === 'Administrator' || props.isSupervisor) &&
        getTab('Credentialing', 11, faFileExcel, true)}
      {props.authorization_level !== 'external_doctor' &&
        props.userType &&
        !props.userType.includes('ccfm') &&
        getTab('Provider Referrals', 12, faUserFriends, true)}
      {(props.authorization_level === 'external_doctor' ||
        (props.userType &&
          !props.userType.includes('ccfm') &&
          (props.user_role === 'Administrator' || props.isSupervisor))) &&
        getTab('Provider/Patient Insights', 13, faClinicMedical, true)}
      {/* faBusinessTime */}
      {props.authorization_level !== 'external_doctor' &&
        props.userType &&
        !props.userType.includes('ccfm') &&
        (props.user_role === 'Administrator' || props.isSupervisor) &&
        getTab('Quality Management', 14, faLaptopMedical, true)}
      {/* TODO: Following code is removed for now, will add it back when we merge infusion */}
      {/* {props.userType &&
        !props.userType.includes('ccfm') &&
        (props.user_role === 'Administrator' || props.isSupervisor) &&
        getTab('PAF', 15, faLaptopMedical, true)} */}
      {props.authorization_level !== 'external_doctor' &&
        // props.userType &&
        //   !props.userType.includes('ccfm') &&
        //   (props.user_role === 'Administrator' ||
        //     (props.isSupervisor && props.hasPaymentsInfoAccess)) &&
        getTab('CMS 1500', 16, faCashRegister, true)}
      {/* {getTab('Provider Insights', 17, faUserMd, true)} */}
      {props.authorization_level !== 'external_doctor' &&
        // props.userType &&
        //   !props.userType.includes('ccfm') &&
        //   (props.user_role === 'Administrator' ||
        //     (props.isSupervisor && props.hasPaymentsInfoAccess)) &&
        getTab('AR Graph', 18, faChartBar, true)}
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    patientList: state.patientReducer.patientList,
    mongoUser: state.userReducer.mongoUser,
    doctorInvites: state.userReducer.doctorInvites,
    userType: state.userReducer.userType,
    adminId: state.userReducer.adminId,
  }
}

const mapDispatchToProps = {
  addMongoUser,
  fetchDoctorInviteList,
  addDoctorInvites,
  fetchPatientsList,
  stopTimer,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)

// //CODE BLOCK TO CREATE INITED PATIENT ACCOUNT IN FRONT END - KEEP COMMENTED
// // Firebase Login
// signup = async (email, password) => {
//   const user = await firebase
//     .auth()
//     .createUserWithEmailAndPassword(email, password)
//   let error = false
//   if (user.user.uid) {
//     // setCurrentUser(user.user.uid)
//     console.log('Logged in PATIENT')
//   } else {
//     error = true
//     console.log('ERROR LOGGING IN')
//   }
//   return { userData: user, errorLogin: error }
// }

// saveInvites = (user_uid, userDetails) => {
//   let { userType, mongoUser, token, doctorInvites, adminId } = this.props
//   let self = this

//   let toSend = { doctor_id: adminId, invites: [userDetails] }
//   let url = Config.BACKEND_URL + 'provider/invites'
//   let tempDoctorInvites = this.state.doctorInvites
//   this.props.addDoctorInvites(null)

//   console.log(toSend)

//   axios({
//     method: 'post',
//     url: url,
//     headers: { x_doctor_id: adminId, Authorization: 'JWT ' + token },
//     data: toSend,
//   })
//     .then(function (response) {
//       // handle success
//       if (response.status === 200) {
//         let currentInvites = doctorInvites?.invites_sent
//         doctorInvites.invites_sent = currentInvites.concat([userDetails])
//         self.setState({
//           modalLoading: false,
//           modalIsOpen: false,
//           modalType: '',
//           doctorInvites: null,
//         })
//         self.props.addDoctorInvites(doctorInvites)
//         toast.success('Invites Sent')
//       }
//     })
//     .catch(function (error) {
//       // handle error
//       console.log('error when sending invites', error)
//       // self.props.addDoctorInvites(tempDoctorInvites)
//       self.setState({
//         modalLoading: false,
//         modalError: true,
//         modalErrorString:
//           'Error when sending invites. Please try again later.',
//       })
//       toast.error('Error when sending invites. Please try again later.')
//     })
// }

// saveMongoData = (user_uid, userDetails) => {
//   let { userType, mongoUser, token, doctorInvites, adminId } = this.props
//   let self = this

//   const mongoData = {
//     firebase_id: user_uid,
//     dob: userDetails?.dob,
//     medicaid: userDetails?.medicaid || false,
//     medicare: userDetails?.medicare || false,
//   }

//   const url = `${Config.BACKEND_URL}users`

//   console.log('mongo data::', mongoData, url)

//   axios({
//     method: 'post',
//     url,
//     headers: { Authorization: `JWT ${token}`, x_firebase_id: user_uid },
//     data: mongoData,
//   })
//     .then(({ data }) => {
//       console.log('successfully added user details to mongo backend')

//       toast.success('Successfully created patient account')

//       this.saveInvites(user_uid, userDetails)

//       self.setState({ loading: false, saving: false })

//     })
//     .catch((err) => {
//       console.log('error when adding doctor data to backend', err)
//     })
// }

// saveDetailsToFirebase = (user_uid, userdata) => {
//   const toSaveObject = {
//     email: userdata?.email.trim(),
//     uid: user_uid,
//     lastSignedIn: new Date(),
//     platform: 'web',
//     signup_platform: 'web',
//     phoneNumber: userdata?.phone,
//   }

//   firebase
//     .firestore()
//     .collection('users')
//     .doc(user_uid)
//     .set(toSaveObject)
//     .then((data) => {
//       console.log('saved-firebase', data)
//       // analytics.logEvent('Web_Signup')
//       firebase
//         .firestore()
//         .collection('users')
//         .doc(user_uid)
//         .get()
//         .then(async (doc) => {
//           if (doc && doc.data()) {
//             const data = doc.data()
//             console.log('user', data)
//             this.saveMongoData(user_uid, userdata)
//             return
//           }
//         })
//     })
//     .catch((err) => {
//       console.log('somethign went wrong when saving user to firestore', err)
//     })
// }

// createPatientAccount = async (email, pwd, userdata) => {
//   try {
//     const { userData, errorLogin } = await this.signup(email, pwd)
//     if (!errorLogin) {
//       this.saveDetailsToFirebase(userData.user.uid, userdata)
//     } else {
//       console.log('Error creating patient')
//     }
//   } catch (err) {
//     console.log(err)

//   }
// }
