import React from 'react'
import Select from 'react-select'
import Styles from './styles/ReferralsTable.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { SPECIALITY_OPTIONS, userTypes } from '../../../metadata'
import ReactSelect from 'react-select'

export const renderPatientAssistanceMasterTableColumns = ({
  // navigatetoPatientProfile,
  // onPatientNavLinkClicked,
  viewPAFPdfOrder,
}) => {
  return [
    {
      Header: 'Patient Name',
      accessor: (obj) => obj?.order_details?.patient_name,
      // Cell: ({ row }) => {
      //   let rowData = row?.original
      //   return (
      //     // <Link
      //     //   onClick={() => {
      //     //     navigatetoPatientProfile(row?.original?.patient_id)
      //     //     onPatientNavLinkClicked('profile')
      //     //   }}
      //     //   // to={{
      //     //   //   pathname: '/patient/profile',
      //     //   //   state: 'info',
      //     //   //   key: 2,
      //     //   // }}
      //     //   className={Styles.link}
      //     // >
      //     { patientData: rowData?.patientName || 'ERROR' }
      //     // </Link>
      //   )
      // },
      width: 250,
      // sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%' }}>
      //         <input
      //           placeholder="Search"
      //           className={Styles.textInput}
      //           style={{ width: '100%' }}
      //           type="text"
      //           value={filterValue}
      //           onChange={(e) => {
      //             setFilter(e.target.value)
      //           }}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Rx Drug',
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: 'left' }}>
            {row.original.order_details?.drug?.name}
          </div>
        )
      },
    },
    {
      Header: 'Order Date',
      accessor: (obj) => {
        return moment(obj.created_at).format('MMM Do YYYY')
      },
      // Cell: (row) => (
      //   <div style={{ textAlign: 'left' }}>
      //     {moment(row.value).format('MMM Do YYYY')}
      //   </div>
      // ),
      width: 250,
      // sort: true,
    },
    {
      Header: 'Ordering Provider',
      accessor: (obj) => {
        return obj?.order_details?.prescriber
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search Provider"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Form Status',
      accessor: (obj) => {
        return 'Incomplete'
      },
      width: 300,
    },
    // TODO: Need to get this working - I think the pdf's go here
    {
      Header: 'Form',
      // accessor: (obj) => {
      //   return obj.notes ? obj.notes : '-'
      // },
      width: 300,
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              // console.log('view', row.original)
              // if (row.original?.order_type === 'Labs') {
              //   onViewClinicalReportClicked(row.original)
              // } else {
              viewPAFPdfOrder(row.original)
              // }
            }}
            className={Styles.link}
            style={{
              textDecoration: 'underline',
              color: '#20a892',
              cursor: 'pointer',
            }}
          >
            View Order
          </p>
        )
      },
    },
    {
      Header: 'Application Status',
      accessor: 'status',
      width: 250,
      Cell: ({ row }) => {
        // console.log('roworgstatus', row?.original?.status)
        let options = [
          { label: 'Not Submitted', value: 'not_submitted' },
          { label: 'Pending', value: 'pending' },
          { label: 'Approved', value: 'approved' },
          { label: 'Denied', value: 'denied' },
        ]
        let selectedOption = options.find(
          (option) => option.value === row?.original?.status,
        )

        return (
          <ReactSelect
            options={options}
            value={selectedOption}
            // isSearchable
            autoBlur
            onChange={(val) => {
              // setFilter(val.value)
              // setMemberType(val)
              // filterByPatientType(val)
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
    },
    {
      Header: 'Authorization Number',
      accessor: (obj) => {
        return obj?.authorization_number || 'Nil'
      },
      width: 250,
      // sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%' }}>
      //         <input
      //           placeholder="Search"
      //           className={Styles.textInput}
      //           style={{ width: '100%' }}
      //           type="text"
      //           value={filterValue}
      //           onChange={(e) => {
      //             setFilter(e.target.value)
      //           }}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },
  ]
}
