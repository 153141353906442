import React, { Component } from 'react'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from './styles/Careplan.module.scss'
import Constants from '../../../values.js'
import Config from '../../../config'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faPlusCircle,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Draggable from 'react-draggable'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Select from 'react-select'
import metadata, { STATUS_OPTIONS } from '../../../metadata'
import firebase from '../../../services/firebase'
import Modal from 'react-modal'
import FileViewer from '../../../components/pdf/FileViewer'
import TextInput from 'react-autocomplete-input'
const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    )
  }
}

class TaskModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modalOpen: this.props?.modalIsOpen ? this.props?.modalIsOpen : false,
      providerOptionsData: [],
      allProviderList: [],
      dueDate: new Date(),
      priority: null,
      assignedDoctor: null,
      assignedProvider: null,
      taskType: null,
      rpmApproval: null,
      rpmNotes: '',
      desc: null,
      timeSpent: 0,
      support_id: null,
      taskStatus: 'not_started',
      taskNote: '',
      support_author: '',
      taskNotesList: [],
      fileAttached: [],
      openFile: false,
      docType: '',
      docFile: '',
      docFileName: '',
      uploadingIndex: 0,
      //
      messageText: '',
      careTeamData: [
        // {
        //   name: `PC Admin`,
        //   doctor_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
        // },
      ],
    }
    this.getAllProviderData = this.getAllProviderData.bind(this)
    this.createTask = this.createTask.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getAllProviderData()
  }

  componentDidUpdate(prevProps) {
    // console.log('TESTING UPDATE: ', this.props?.supportData)

    if (
      this.props?.modalIsOpen !== prevProps?.modalIsOpen ||
      this.props?.supportData !== prevProps?.supportData
    ) {
      this.setState({ modalOpen: this.props?.modalIsOpen })
      // if(this.props?.supportData) {
      const supportData = this.props?.supportData
      let assignedData = supportData?.assigned_to
        ? this.state?.allProviderList?.find(
            (doc) => doc?.firebase_id === supportData?.assigned_to,
          )
        : null
      let assignedName =
        assignedData?.first_name + ' ' + assignedData?.last_name
      let requestedDoc = supportData?.doctor_id
        ? this.state?.allProviderList?.find(
            (doc) => doc?.firebase_id === supportData?.doctor_id,
          )
        : null
      let requestedName =
        requestedDoc?.first_name + ' ' + requestedDoc?.last_name
      console.log(assignedName, requestedName)
      this.setState(
        {
          dueDate: supportData?.due_date || new Date(),
          priority:
            { label: supportData?.priority, value: supportData?.priority } ||
            null,
          assignedDoctor: requestedDoc
            ? { label: requestedName, value: supportData?.doctor_id }
            : null,
          taskType:
            { label: supportData?.subject, value: supportData?.subject } ||
            null,
          assignedProvider: supportData?.assigned_to ? assignedName : null,
          desc: supportData?.description || null,
          timeSpent: supportData?.time_spent || 0,
          fileAttached: supportData?.file_attached || [],
          taskNotesList: supportData?.task_notes || [],
          support_id: supportData?._id || null,
          support_author: supportData?.firebase_id || null,
          taskStatus: supportData?.status
            ? supportData?.status
            : supportData?.completed_status
            ? 'completed'
            : 'not_started',
          rpmApproval: supportData?.rpm_approval || null,
          rpmNotes: supportData?.rpm_notes || '',
          dateOfService: supportData?.start || null,
        },
        () => {
          this.getProviderDetails()
        },
      )
      // }
    }
  }

  getProviderDetails() {
    console.log('Getting details')
    const { mongoUser, patient, token, supportData } = this.props
    console.log('supportData', supportData)
    const self = this
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    // this.setState({ loading: true })
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: supportData?.patient_id || supportData?.firebase_id,
      },
    })
      .then(({ data }) => {
        console.log('1')
        if (data) {
          console.log('2')
          self.setState({ careteam: data })
          this.state.careteam.forEach((care) => {
            // if (care.doctor_id != mongoUser.firebase_id) {
            this.state.careTeamData.push({
              name: `${care.first_name} ${care.last_name} (${care.speciality})`,
              doctor_id: care.doctor_id,
              // speciality: care.speciality
            })
            // this.state.careTeamTagOptions.push({
            //   display: `${care.first_name} ${care.last_name}`,
            //   id: care.doctor_id,
            // })
            // }
          })
        }

        console.log('X')
        // Add PC admin as an option to this
        this.state.careTeamData.push({
          name: `PC Admin`,
          doctor_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
        })

        // console.log('CARETEAM: ', this.state.careteam)
        // console.log('NAME OF CARETEAM: ', this.state.careTeamData)
        // console.log('IMAGES OF CARETEAM: ', this.state.careTeamImgs)
        // this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when getting careteam list from backend', err)
      })
  }

  onHandleChange(e, newValue) {
    console.log('Changed', e, newValue)
    this.setState({
      messageText: e,
      taskNote: e,
    })
  }

  getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: this.props.adminId,
        Authorization: 'JWT ' + this.props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        let providerOptionList = []
        let allProviderData = data.reduce((data, doc) => {
          data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
          data[doc.user_type || 'Other'].push({
            label: doc?.first_name + ' ' + doc?.last_name,
            value: doc?.firebase_id,
          })
          return data
        }, Object.create(null))
        Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
          providerOptionList.push({
            label: userType,
            options: doctorArray,
          })
        })
        this.setState({
          providerOptionsData: providerOptionList,
          allProviderList: data,
        })
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  // This function needs to updated to create or update task
  createTask = () => {
    const {
      desc,
      dueDate,
      priority,
      assignedDoctor,
      taskType,
      taskStatus,
      support_id,
      taskNotesList,
      taskNote,
      timeSpent,
      fileAttached,
      rpmApproval,
      rpmNotes,
    } = this.state
    if (
      !desc ||
      !dueDate ||
      !priority?.value ||
      // !assignedDoctor?.value ||
      !taskType?.value
    ) {
      toast.error('Missing information. Please fill all the fields.')
      return
    }
    console.log('TESTING Upload: ', taskNotesList)
    if (taskNote.length) {
      taskNotesList.unshift({
        author_id: this.props.adminId,
        name:
          (this.props?.mongoUser?.first_name || '') +
          ' ' +
          (this.props?.mongoUser?.last_name || ''),
        note_text: taskNote,
        created_at: new Date(),
      })
    }
    let latestNote = this.state.taskNotesList?.[0]
    let tagged_providers = null
    if (latestNote)
      console.log("The latest note is:", latestNote)
      tagged_providers = this.state.careTeamData.filter((careteam) => {
        console.log('->->', latestNote?.note_text, careteam.name)
        return latestNote?.note_text?.includes(careteam.name)
      })
    // console.log('->->', this.state.taskNote, careteam.name)
    console.log('Tagged providers: ', tagged_providers)
    console.log('TESTING Upload: ', taskNotesList)
    let data = {
      priority: priority?.value,
      subject: taskType?.value,
      description: desc,
      time_spent: timeSpent,
      doctor_id: assignedDoctor?.value,
      clinical_support: true,
      due_date: dueDate,
      status: taskStatus,
      task_notes: taskNotesList,
      file_attached: fileAttached,
      rpm_approval: rpmApproval,
      rpm_notes: rpmNotes,
    }
    const config = {
      method: 'post',
      url: Config.BACKEND_URL + 'patient/support',
      headers: {
        x_firebase_id: this.props.adminId,
        Authorization: 'JWT ' + this.props.token,
        tagged_providers,
      },
      data,
    }
    if (support_id) {
      // This is for update
      config.method = 'put'
      config.url = Config.BACKEND_URL + 'support-request'
      config.data._id = support_id
      config.data.updated_by = this.props.adminId
    } else {
      // new tasks these should not change while updating
      config.data.patient_id =
        this.props?.patientId || this?.props?.patient?.uid
      config.data.firebase_id = this.props.mongoUser?.firebase_id
      config.data.name =
        (this.props.mongoUser?.first_name || '') +
        ' ' +
        (this.props.mongoUser?.last_name || '')
      // config.data.patient_id = this?.props?.patient?.uid
      config.data.email = this.props.mongoUser.email
      config.data.userType = this.props.mongoUser.user_type
      config.data.event_id = this.props.eventId || null
      config.data.date_of_service = this.props?.dateOfService || null
    }
    console.log('upload config: ', config)
    axios(config)
      .then(({ data }) => {
        let toastText = support_id
          ? 'Task Edited'
          : 'Task Created.\n It will take 48-72 hrs for the response.'
        toast.info(toastText)
        this.setState({
          providerOptionsData: [],
          dueDate: new Date(),
          priority: null,
          assignedDoctor: null,
          taskType: null,
          desc: null,
          timeSpent: 0,
          taskStatus: 'not_started',
          rpmApproval: null,
          rpmNotes: '',
          assignedProvider: null,
          taskNote: '',
          taskNotesList: [],
          fileAttached: [],
        })
        this.props.hideTaskModal()
        this.props.getUserTask && this.props.getUserTask()
      })
      .catch((err) => {
        console.log('err while creating task: ', err)
        toast.error('Something went wrong while creating task.')
      })
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    let self = this

    // Update the state
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])
      this.setState({ loading: true })

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      let storageUrl = `TaskUploads/${mongoUser.firebase_id}/${
        self.state.uploadingIndex + '_' + file.name
      }`
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef.child(storageUrl).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.setState({ loading: false })
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // console.log(downloadURL)
            let temp = [...self.state.fileAttached]
            temp[self.state.uploadingIndex].downloadURL = downloadURL
            temp[self.state.uploadingIndex].fileName = file.name
            self.setState({ fileAttached: temp, loading: false })
          })
        },
      )
    }
  }

  render() {
    const { support_id } = this.state
    // if(this.state.loading) {
    //   return (
    //     <div className={GlobalStyles.container} style={{overflow: 'hidden', maxHeight: '100vh'}}>
    //       <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '80vh'}}>
    //         <div className={GlobalStyles.loader} />
    //       </div>
    //     </div>
    //   )
    // }

    if (this.state.openFile) {
      return (
        <Modal
          ariaHideApp={false}
          isOpen={this.state.openFile}
          onRequestClose={() => {
            this.setState({
              openFile: false,
              docFile: '',
              docType: '',
              docFileName: '',
            })
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <h3>Attachment : {this.state.docFileName}</h3>
          <Button
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 120,
              top: 10,
            }}
            variant="primary"
            href={this.state.docFile}
            color="transparent"
            target="_blank"
            download
          >
            Download
          </Button>

          <FileViewer
            fileType={this.state.docType}
            fileName={this.state.docFile}
          />
        </Modal>
      )
    }

    return (
      <BSModal
        dialogAs={DraggableModalDialog}
        show={this.state.modalOpen}
        onHide={() => this.props.hideTaskModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={Styles.taskModalContainer}
      >
        <BSModal.Header
          closeButton
          style={{
            cursor: 'pointer',
          }}
        >
          <BSModal.Title
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              width: '100%',
            }}
          >
            Assign Task
          </BSModal.Title>
        </BSModal.Header>
        <BSModal.Body>
          {this.state.loading ? (
            <div
              className={GlobalStyles.container}
              style={{ overflow: 'hidden', maxHeight: '100vh' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '80vh',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            <div
              style={{
                height: '70vh',
                width: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'scroll',
                overflow: 'auto',
              }}
            >
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Priority: </p>
                <Select
                  options={[
                    { label: 'High', value: 'HIGH' },
                    { label: 'Medium', value: 'MEDIUM' },
                    { label: 'Low', value: 'LOW' },
                  ]}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  onChange={(val) => {
                    this.setState({ priority: val })
                  }}
                  value={this.state.priority}
                  getOptionValue={(option) => option.label}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                  isOptionDisabled={(option) =>
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div>
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Provider: </p>
                <Select
                  options={this.state.providerOptionsData}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  onChange={(val) => {
                    this.setState({ assignedDoctor: val })
                  }}
                  value={this.state.assignedDoctor}
                  getOptionValue={(option) => option.label}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                  isOptionDisabled={(option) =>
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div>

              {this.state.assignedProvider ? (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>
                    Assigned Provider:{' '}
                  </p>
                  <p> {this.state.assignedProvider} </p>
                </div>
              ) : null}

              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Status: </p>
                <Select
                  options={STATUS_OPTIONS}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  isMulti={false}
                  disable
                  onChange={(val) => {
                    this.setState({ taskStatus: val.value })
                  }}
                  value={STATUS_OPTIONS.find(
                    (obj) => obj.value === this.state.taskStatus,
                  )}
                  select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>

              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Task Type: </p>
                <Select
                  options={metadata.taskTypes}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  onChange={(val) => {
                    this.setState({ taskType: val })
                  }}
                  value={this.state.taskType}
                  getOptionValue={(option) => option.label}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                  isOptionDisabled={(option) =>
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div>
              {this.state.taskType?.label === 'RPM Order' && (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>Approve RPM:</p>
                  {this.props?.mongoUser?.supervisor ? (
                    <Select
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                      ]}
                      className={Styles.addSelectInput}
                      autoBlur={true}
                      onChange={(val) => {
                        this.setState({ rpmApproval: val })
                      }}
                      value={this.state.rpmApproval}
                      getOptionValue={(option) => option.label}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  ) : (
                    <p>
                      {this.state.rpmApproval?.label
                        ? this.state.rpmApproval?.label === 'No'
                          ? 'Not Approved'
                          : 'Approved'
                        : 'Not Approved'}
                    </p>
                  )}
                </div>
              )}
              {this.state.taskType?.label === 'RPM Order' && (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>RPM Notes:</p>
                  <textarea
                    placeholder={'Please enter notes to referring provider'}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '70%',
                      // height: '40px',
                    }}
                    rows="4"
                    type="text"
                    value={this.state.rpmNotes || ''}
                    onChange={(e) =>
                      this.setState({ rpmNotes: e.target.value })
                    }
                    disabled={!this.props?.mongoUser?.supervisor}
                  />
                </div>
              )}

              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Due date: </p>
                <input
                  placeholder={'Please enter estimated or due date'}
                  className={Styles.addManualTextInput}
                  type="date"
                  min={moment(new Date()).format('YYYY-MM-DD')}
                  value={moment(this.state.dueDate).format('YYYY-MM-DD')}
                  onChange={(e) => this.setState({ dueDate: e.target.value })}
                />
              </div>

              {/* {this.state.dateOfService ? (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>Date of Service: </p>
                  <input
                    placeholder={'No Date of Service'}
                    className={Styles.textInput}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '70%',
                      // height: '40px',
                    }}
                    disabled={true}
                    type="number"
                    value={moment(this.state.dateOfService).format(
                      'MM/DD/YYYY',
                    )}
                  />
                </div>
              ) : null} */}

              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Description: </p>
                <textarea
                  placeholder={'Please enter description of task'}
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '70%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={this.state.desc}
                  onChange={(e) => this.setState({ desc: e.target.value })}
                  disabled={
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div>
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Time Spent: </p>
                <input
                  placeholder={'0 minutes'}
                  className={Styles.textInput}
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '70%',
                    // height: '40px',
                  }}
                  type="number"
                  value={this.state.timeSpent || 0 + ' minutes'}
                  onChange={(e) => {
                    let time = e.target.value
                    this.setState({
                      timeSpent: time,
                    })
                  }}
                />
              </div>
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Upload Document: </p>
                <input
                  onChange={(e) => {
                    let temp = [...this.state.fileAttached]
                    temp.push({})
                    this.setState({ fileAttached: temp })
                    this.setState({
                      uploadingIndex: this.state.fileAttached.length,
                    })
                    this.onFileChange(e)
                  }}
                  type="file"
                  id={`file-attachment`}
                  // ref={this.fileRef}
                  // accept="image/*"
                  style={{ display: 'none' }}
                  multiple={false}
                />
                <div
                  className={Styles.studyEntryRow}
                  style={{
                    width: '60%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <FontAwesomeIcon
                    id={`file`}
                    title={`file`}
                    onClick={() =>
                      document.getElementById(`file-attachment`).click()
                    }
                    icon={faPaperclip}
                    style={{
                      color: 'gray',
                      fontSize: 30,
                      marginRight: 10,
                      flexGrow: 1,
                      cursor: 'pointer',
                      // position: 'absolute',
                      // marginLeft: '200px',
                    }}
                  />
                  {this.state.fileAttached?.map((item, index) => (
                    <p
                      onClick={() => {
                        this.setState({ openFile: true })
                        this.setState({
                          docType: item?.fileName
                            ? item?.fileName.split('.').pop()
                            : 'pdf',
                        })
                        this.setState({
                          docFile: item?.downloadURL,
                          docFileName: item?.fileName,
                        })
                      }}
                      style={{
                        textDecoration: 'underline',
                        color: 'gray',
                        cursor: 'pointer',
                        margin: '2%',
                      }}
                    >
                      {index + 1} {item?.fileName}
                    </p>
                  ))}
                </div>
              </div>
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Task Notes: </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '70%',
                  }}
                >
                  {/* <textarea
                    placeholder={'Please enter notes about task'}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '90%',
                      // height: '40px',
                    }}
                    rows="2"
                    type="text"
                    value={this.state.taskNote || ''}
                    onChange={(e) =>
                      this.setState({ taskNote: e.target.value })
                    }
                  /> */}
                  <TextInput
                    // style={{
                    //   borderRadius: 10,
                    //   margin: '2',
                    //   width: '230%',
                    //   height: '100%',
                    //   resize: 'none',
                    // }}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '90%',
                      // height: '40px',
                    }}
                    placeholder="Please enter notes about task1"
                    // value={this.state.messageText}
                    value={this.state.taskNote || ''}
                    options={this.state.careTeamData.map((data) => data.name)}
                    // onSelect={(e) => {
                    //   console.log('Event', e)
                    // }}
                    // changeOnSelect={(trigger, slug) => {
                    //   console.log('Trigger, slug', trigger, slug)
                    // }}
                    onChange={this.onHandleChange.bind(this)}
                  />
                  <FontAwesomeIcon
                    className={Styles.plusButton}
                    onClick={() => {
                      if (this.state.taskNote.length) {
                        let tempNote = this.state.taskNotesList
                        tempNote.unshift({
                          author_id: this.props.adminId,
                          name:
                            (this.props?.mongoUser?.first_name || '') +
                            ' ' +
                            (this.props?.mongoUser?.last_name || ''),
                          note_text: this.state.taskNote,
                          created_at: new Date(),
                        })
                        this.setState({ taskNotesList: tempNote, taskNote: '' })
                      }
                    }}
                    icon={faPlusCircle}
                  />
                </div>
              </div>
              {this.state.taskNotesList?.map((note, index) => (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>
                    {note.name} {`(${moment(note.created_at).format('ll')})`}:{' '}
                  </p>
                  <textarea
                    placeholder={'Please enter notes about task'}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '70%',
                      // height: '40px',
                    }}
                    rows="2"
                    type="text"
                    value={note.note_text || ''}
                    onChange={(e) => {
                      let tempNote = this.state.taskNotesList
                      tempNote[index].note_text = e.target.value
                      tempNote[index].updated_at = new Date()
                      this.setState({ taskNotesList: tempNote })
                    }}
                    disabled={
                      !(
                        !support_id ||
                        note.author_id === this.props.adminId ||
                        this.props?.mongoUser?.user_type?.toLowerCase() ===
                          'administrator'
                      )
                    } // only let authors to edit their note
                  />
                </div>
              ))}
            </div>
          )}
        </BSModal.Body>
        <BSModal.Footer>
          <Button
            onClick={() => this.createTask()}
            className={GlobalStyles.button}
            // style={{width: 140, position: 'absolute', bottom: 10}}
            variant="primary"
          >
            {this.state.support_id ? 'Edit' : 'Create'}
          </Button>
        </BSModal.Footer>
      </BSModal>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TaskModal)
