/* eslint-disable */
import { toast } from 'react-toastify'
import actionTypes from './actionTypes.js'
import Config from '../config'
import dummyData from '../pages/Patients/BodySystems/dummyData.json'
const axios = require('axios')

let PATIENT_ID = null
let timer = 0
let intervalFn = null

export function testAction(payload) {
  return {
    type: actionTypes.TEST,
    payload,
  }
}

export function fromWebFlow(payload) {
  return {
    type: actionTypes.FROM_WEB_FLOW,
    payload,
  }
}

export function userLoggedIn(loggedIn) {
  return {
    type: actionTypes.USER_LOGGED_IN,
    payload: loggedIn,
  }
}

export function addPatientList(payload) {
  return {
    type: actionTypes.ADD_PATIENT_LIST,
    payload,
  }
}

export function addPatient(payload, color) {
  return {
    type: actionTypes.ADD_PATIENT,
    payload: { patient: payload, color },
  }
}

export function addPatientProgress(payload) {
  return {
    type: actionTypes.ADD_PATIENT_PROGRESS_DATA,
    payload,
  }
}

export function addVirgilE3(payload) {
  return {
    type: actionTypes.ADD_E3,
    payload,
  }
}

export function updateSideNavBar(payload) {
  return {
    type: actionTypes.UPDATE_SIDENAV_RENDER,
    payload,
  }
}

export function addNavigationObject(payload) {
  return {
    type: actionTypes.ADD_NAVIGATION_OBJECT,
    payload,
  }
}

export function addRpmPatient(payload) {
  return {
    type: actionTypes.ADD_RPM_PATIENT_PROFILE,
    payload,
  }
}

export function addPatientLP(payload) {
  return {
    type: actionTypes.ADD_PATIENT_LANDING_PAGE,
    payload,
  }
}

export function addFilledData(payload) {
  return {
    type: actionTypes.ADD_FILLED_DATA,
    payload,
  }
}

export function addSparseData(payload) {
  return {
    type: actionTypes.ADD_SPARSE_DATA,
    payload,
  }
}

export function addUserType(payload) {
  return {
    type: actionTypes.ADD_USER_TYPE,
    payload,
  }
}

export function addAuthToken(payload) {
  return {
    type: actionTypes.ADD_AUTH_TOKEN,
    payload,
  }
}

export function addCCFMPatientData(payload) {
  return {
    type: actionTypes.ADD_CCFM_PATIENT_DATA,
    payload,
  }
}

export function addPatientLabs(payload) {
  return {
    type: actionTypes.ADD_PATIENT_LABS,
    payload,
  }
}

export function addFirebaseAuthUser(payload) {
  return {
    type: actionTypes.ADD_FIREBASE_AUTH_USER,
    payload,
  }
}

export function toggleNotesModal(payload) {
  return {
    type: actionTypes.TOGGLE_NOTES_MODAL,
    payload,
  }
}

export function setAppointmentNotesData(payload) {
  return {
    type: actionTypes.APPOINTMENT_NOTES_DATA,
    payload,
  }
}

export function setPatientCarePlanData(payload) {
  return {
    type: actionTypes.ADD_CAREPLAN_DATA,
    payload,
  }
}

export function setRpmOrders(payload) {
  return {
    type: actionTypes.ADD_RPM_ORDERS,
    payload,
  }
}

export function fetchPatientCarePlanData({
  patient,
  token,
  adminId,
  patientId,
}) {
  console.log(
    'CALLED fetchPatientCarePlanData',
    patient,
    patientId,
    token,
    adminId,
  )
  return function (dispatch) {
    let url = Config.BACKEND_URL + 'careplan'
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        patient_id: patient?.uid || patientId,
        x_doctor_id: adminId,
      },
    })
      .then(({ data }) => {
        if (data) {
          dispatch(setPatientCarePlanData(data?.filter(obj => obj?.note_status !== 'archive') || []))
        }
      })
      .catch((err) => {
        console.log('error when getting user care plan', err)
      })
  }
}

export function fetchRpmOrders(
  patient,
  token,
  adminId,
  patientId,
) {
  console.log(
    'CALLED fetchRpmOrders',
    patient,
    patientId,
    token,
    adminId,
  )
  return function (dispatch) {
    let url = `${Config.BACKEND_URL}rpm/order?patient=${patient?.uid}`
    return axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: patient?.uid,
      },
    })
      .then(( {data} ) => {
        if (data) {
          console.log(data)
          dispatch(setRpmOrders(data || []))
        }
      })
      .catch((err) => {
        console.log('error when getting rpm orders', err)
      })
  }
}
export function toggleVideoModal(payload, videoTime) {
  return {
    type: actionTypes.TOGGLE_VIDEO_MODAL,
    payload,
    videoTime
  }
}

export function togglePatientVideoCall(payload) {
  return {
    type: actionTypes.TOGGLE_PATIENT_VIDEO_CALL,
    payload,
  }
}

export function addTwilioToken(payload) {
  return {
    type: actionTypes.ADD_TWILIO_TOKEN,
    payload,
  }
}

export function addFirebaseUser(payload) {
  return {
    type: actionTypes.ADD_FIREBASE_USER,
    payload,
  }
}

export function setPatientCoreData(payload) {
  return {
    type: actionTypes.ADD_PATIENT_CORE_DATA,
    payload,
  }
}

function setPatientMedicationData(payload) {
  return {
    type: actionTypes.ADD_PATIENT_MEDICATIONS,
    payload,
  }
}

function setPatientConditionsData(payload) {
  return {
    type: actionTypes.ADD_PATIENT_CONDITIONS,
    payload,
  }
}

function setPatientGoalsData(payload) {
  return {
    type: actionTypes.ADD_PATIENT_GOALS,
    payload,
  }
}

export function addAppointmentData(appointmentData, patientData) {
  return {
    type: actionTypes.ADD_APPOINTMENT_DATA,
    appointmentData,
    patientData,
  }
}

export function addAdminId(payload) {
  return {
    type: actionTypes.ADD_ADMIN_ID,
    payload,
  }
}

export function addDoctorInvites(payload) {
  return {
    type: actionTypes.ADD_DOCTOR_INVITES,
    payload,
  }
}

export function addTeamMongoUser(payload) {
  return {
    type: actionTypes.ADD_TEAM_MONGO_USER,
    payload,
  }
}

export function addMongoUser(payload) {
  return {
    type: actionTypes.ADD_MONGO_USER,
    payload,
  }
}

export function addProviderData(payload) {
  return {
    type: actionTypes.ADD_PROVIDER_DATA,
    payload,
  }
}

export function getUnreadNotification(payload) {
  return {
    type: actionTypes.GET_UNREAD_NOTIFICATION,
    payload,
  }
}

export function getAllNotification(payload) {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION,
    payload,
  }
}

export function toggleSidebar(payload) {
  console.log("Toggling sidebar", payload)
  return {
    type: actionTypes.TOGGLE_SIDE_BAR,
    payload,
  }
}

export function setCareTeamList(payload) {
  return {
    type: actionTypes.SET_PATIENT_CARETEAM,
    payload,
  }
}

export function getUnreadMessages(uid, token, mongoUser) {
  console.log('getUnreadMessages')
  //Notification is an array to store the different notifications from different chats and messages
  let Notification = []
  let tempNotification = []
  return function (dispatch) {
    const url1 = `${Config.BACKEND_URL}careteam/chat/provider`
    const url2 = `${Config.BACKEND_URL}provider/messages`
    const url3 = `${Config.BACKEND_URL}notifications`
    axios
      .all([
        // axios.get(url1, {
        //   headers: { x_doctor_id: uid, Authorization: `JWT ${token}` },
        // }),
        axios.get(url2, {
          headers: { x_doctor_id: uid, Authorization: `JWT ${token}` },
        }),
        axios.get(url3, {
          headers: {
            x_firebase_id: uid,
            Authorization: `JWT ${token}`,
            created_at: mongoUser?.created_at,
            provider: true
          },
        }),
      ])
      .then(
        axios.spread((res2, res3) => {
          // if (res1?.data?.length !== 0) {
          //   Notification.push(res1.data)
          // }
          if (res2?.data?.length !== 0) {
            res2.data.forEach((m) => {
              if (m.unread_doc != 0) {
                Notification.push(m)
              }
            })
          }
          if (res3?.data?.length !== 0) {
            let arrayNoti = res3?.data.filter(
              (notification) =>
                !notification?.read_by?.[uid] &&
                (notification?.notification_type === 'Careplan' ||
                  notification?.notification_type === 'Appointments'),
              // && moment().isAfter(notification?.created_at)
            )
            let allBackendNotification = res3?.data.filter(
              (notification) =>
                notification?.notification_type === 'Careplan' ||
                notification?.notification_type === 'Appointments',
              // && moment().isAfter(notification?.created_at)
            )
            tempNotification = [...Notification, ...allBackendNotification]
            Notification = [...Notification, ...arrayNoti]
          }
          dispatch(getAllNotification(tempNotification))
          //If there are NO Notifications in the array return NULL : Does not show RED DOT
          if (Notification.length === 0) {
            dispatch(getUnreadNotification(null))
          }
          //else return the array of Notifications : Shows up RED DOT
          else {
            dispatch(getUnreadNotification(Notification))
          }
        }),
      )
  }
}

function setPatientWellnessScreening(payload) {
  return {
    type: actionTypes.ADD_PATIENT_WELLNESS_SCREENING_DATA,
    payload,
  }
}

function setPatientCalculateProgressData(payload) {
  return {
    type: actionTypes.ADD_PATIENT_CALCULATED_PROGRESS_DATA,
    payload,
  }
}

export function fetchPatientCareteam(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    const doctorList = []
    return axios({
      method: 'get',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: uid,
      },
    })
      .then(({ data }) => {
        dispatch(setCareTeamList(data))
      })
      .catch((err) => {
        console.log('error when getting patient careteam', err)
      })
  }
}

export function fetchPatientCoreDate(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}doctor/patients`
    return axios({
      method: 'get',
      url,
      headers: { x_patient_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        dispatch(setPatientCoreData(data))
      })
      .catch((err) => {
        console.log('error when getting patient core data', err)
      })
  }
}

export function fetchPatientProgressData(uid, token, timeline) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}doctor/patient/progress?timeline=${timeline}`
    return axios({
      method: 'get',
      headers: { Authorization: `JWT ${token}`, x_patient_id: uid },
      url,
    })
      .then(({ data }) => {
        dispatch(addPatientProgress(data))
      })
      .catch((err) => {
        console.log('error when getting patient vital progress data', err)
      })
  }
}

export function fetchPatientMedications(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}user/medications`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        dispatch(setPatientMedicationData(data))
      })
      .catch((err) => {
        console.log('error when getting patient medications data', err)
      })
  }
}

export function fetchPatientConditions(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}user/conditions`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        dispatch(setPatientConditionsData(data))
      })
      .catch((err) => {
        console.log('error when getting patient conditions data', err)
      })
  }
}

export function fetchPatientGoals(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}user/goals`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        dispatch(setPatientGoalsData(data))
      })
      .catch((err) => {
        console.log('error when getting patient goals data', err)
      })
  }
}

export function fetchPatientWellnessScreening(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}screenings/wellness`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        dispatch(setPatientWellnessScreening(data))
      })
      .catch((err) => {
        console.log('error when getting patient wellness screening data', err)
      })
  }
}

export function fetchPatientCalculateProgressData(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}user/score?timeline=monthly`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        dispatch(setPatientCalculateProgressData(data))
      })
      .catch((err) => {
        console.log('error when getting patient calculated progress data', err)
      })
  }
}

export function fetchTeamMongoUser(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        dispatch(addTeamMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting fetchTeamMongoUser', err)
      })
  }
}

export function fetchDoctorMongoUser(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)
        dispatch(addMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting fetchDoctorMongoUser', err)
      })
  }
}

export function fetchSpecificDoctorMongoUser(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)
        dispatch(addProviderData(data))
      })
      .catch((err) => {
        console.log('error when getting fetchSpecificDoctorMongoUser', err)
      })
  }
}

export function updatePatientData(data, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}doctor/patients`
    return axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data,
    })
      .then((response) => {
        console.log('patient data updated')
      })
      .catch((err) => {
        console.log('error when updating patient last reviewed', err)
      })
  }
}

export function updateDoctorMongoUser(toUpdate, user, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: toUpdate,
    })
      .then(({ data }) => {
        console.log('successfully updated doctor in mongo')
        user.virgil_registered = true
        dispatch(addMongoUser(user))
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }
}

export function fetchDoctorInviteList(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}provider/invites`
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: uid, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          dispatch(addDoctorInvites({invites_sent: response.data}))
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting doctor invites', error)
      })
  }
}

export function fetchPatientsList(uid, token) {
  return function (dispatch) {
    dispatch(addPatientList(null))
    const url = `${Config.BACKEND_URL}doctor/patients/list`
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: uid, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          dispatch(addPatientList(response.data))
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }
}

export function fetchCCFMPatientData(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}users`
    axios({
      method: 'get',
      url,
      headers: { x_firebase_id: uid, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          dispatch(addCCFMPatientData(response.data))
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }
}

export function fetchPatientLabs(uid, token) {
  return function (dispatch) {
    const url = `${Config.BACKEND_URL}providers/patients/labs`
    axios({
      method: 'get',
      url,
      headers: { x_patient_id: uid, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          dispatch(addPatientLabs(response.data))
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }
}

// time tracking related actions
export function startTimer(patientId) {
  return function (dispatch) {
    PATIENT_ID = patientId
    intervalFn = setInterval(() => {
      timer += 1
      dispatch(updateTimerInStore(timer))
    }, 1000)
  }
}

export function stopTimer(doctorId, token) {
  return function (dispatch) {
    if (PATIENT_ID && timer !== 0)
      postTimeTrackingDataToBackend(PATIENT_ID, doctorId, timer, token)

    clearInterval(intervalFn)
    timer = 0
    PATIENT_ID = null
    dispatch(updateTimerInStore(0))
  }
}

export function resetTimer(patientId, doctorId, token) {
  return function (dispatch) {
    postTimeTrackingDataToBackend(PATIENT_ID, doctorId, timer, token)
    clearInterval(intervalFn)
    updateTimerInStore(0)
    timer = 0
    PATIENT_ID = patientId
    intervalFn = setInterval(() => {
      timer += 1
      dispatch(updateTimerInStore(timer))
    }, 1000)
  }
}

function updateTimerInStore(payload) {
  return {
    type: actionTypes.UPDATE_TIME,
    payload,
  }
}

function postTimeTrackingDataToBackend(patientId, doctorId, time, token) {
  const url = `${Config.BACKEND_URL}provider/patients/time-track`
  axios({
    method: 'post',
    url,
    headers: { Authorization: `JWT ${token}` },
    data: {
      doctor_id: doctorId,
      patient_id: patientId,
      time_amount: time,
    },
  })
    .then(function (response) {
      // handle success
      if (response.status === 200) {
        console.log('successfully updated time tracking info')
      }
    })
    .catch(function (error) {
      // handle error
      console.log('error when updating time tracking info', error)
    })
}

export function getDummyData(payload) {
  return {
    type: actionTypes.GET_CARDIOVASCULAR_DATA,
    payload: dummyData,
  }
}
